import { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { errorNotification, successNotification } from '../../store/actions';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Label,
	NavItem,
	NavLink,
	Popover,
	PopoverBody,
	PopoverHeader,
	Progress,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import DateInputBefor from '../../components/Fields/DateInputBefor';
import Dropzone from 'react-dropzone';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

class GDPR extends Component {
	constructor(props) {
		super(props);

		this.handleChangeOngoing = this.handleChangeOngoing.bind(this);
		this.handleChangeRadioBox = this.handleChangeRadioBox.bind(this)

		this.dropzoneAcceptedFiles = {
			accepted:
				'text/plain, , application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv',
			description: [
				`${this.props.t('')}. ${this.props.t('For more information')}, ${this.props.t('visit')}`,
				<a href='https://diss-co.tech/faq-diss-co/' target='_blank'>{` ${this.props.t('FAQ')} `}</a>,
				this.props.t('page'),
			],
		};

		this.state = {
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: this.props.t('GDPR'), link: '#' },
			],
			tabs: [
				{
					tabId: 1,
					title: 'Report',
					active: true,
				},
				{
					tabId: 2,
					title: 'Review your report',
					active: false,
				},
				{
					tabId: 3,
					title: 'Follow-Up',
					active: false,
				},
				{
					tabId: 4,
					title: 'Finalize',
					active: false,
				},
			],
			activeTab: '1',
			progressValue: 25,
			defaultValues: {
				isOngoing: null,
			},

			questions: [
				{
					id: 1800,
					title: 'Please describe the data breach incident',
					type: 'textarea',
					type_report: 'ANONYMOUSLY',
					create_at: '2023-07-14 13:37:08',
					status: 'ACTIVE',
					organization_id: 64,
					after_login: 'none',
					sort: 5,
					sub_question: [],
				},
				{
					id: 1952,
					title: 'When did it happen?',
					type: 'radiobox',
					type_report: 'ANONYMOUSLY',
					create_at: '2023-07-14 13:37:08',
					status: 'ACTIVE',
					organization_id: 64,
					after_login: 'none',
					sort: 2,
					sub_question: [
						{
							id: 726,
							question_id: 1952,
							label: 'Approximate date',
							type: 'date',
							flag: '1',
						},
						{
							id: 727,
							question_id: 1952,
							label: 'Specify a date',
							type: 'date',
							flag: '1',
						},
						{
							id: 728,
							question_id: 1952,
							label: 'I don’t remember',
							type: 'radiobox',
							flag: '1',
						},
					],
				},
				{
					id: 19521,
					title: 'Is it an ongoing breach?',
					type: 'radiobox',
					type_report: 'ANONYMOUSLY',
					create_at: '2023-07-14 13:37:08',
					status: 'ACTIVE',
					organization_id: 64,
					after_login: 'none',
					sort: 2,
					sub_question: [
						{
							id: 7261,
							question_id: 19521,
							label: 'Yes',
							type: 'radiobox',
							flag: '1',
						},
						{
							id: 7271,
							question_id: 19521,
							label: 'No',
							type: 'radiobox',
							flag: '1',
						},
					],
				},
				{
					id: 1956,
					title: 'How did you become aware of the breach?',
					type: 'text',
					type_report: 'ANONYMOUSLY',
					create_at: '2023-07-14 13:37:08',
					status: 'ACTIVE',
					organization_id: 64,
					after_login: 'none',
					sort: 7,
					sub_question: [],
				},
				
				{
					id: 1955,
					title: 'To whom did you report it?',
					type: 'text',
					type_report: 'ANONYMOUSLY',
					create_at: '2023-07-14 13:37:08',
					status: 'ACTIVE',
					organization_id: 64,
					after_login: 'none',
					sort: 5,
					sub_question: [],
				},
				{
					id: 1957,
					title: 'How can you prove the facts?',
					type: 'text',
					type_report: 'ANONYMOUSLY',
					create_at: '2023-07-14 13:37:08',
					status: 'ACTIVE',
					organization_id: 64,
					after_login: 'none',
					sort: 6,
					sub_question: [],
				},
				{
					id: 1958,
					title: 'Attachments (Optional)',
					type: 'file',
					type_report: 'ANONYMOUSLY',
					create_at: '2023-07-14 13:37:08',
					status: 'ACTIVE',
					organization_id: 64,
					after_login: 'none',
					sort: 9,
					sub_question: [],
				},
			],
			categories: [
				{
					id: 1,
					baseLabel: 'Device lost or stolen',
					title: this.props.t('Device lost or stolen'),
				},
				{
					id: 2,
					baseLabel: 'Paper lost or stolen or left in insecure location ',
					title: this.props.t('Paper lost or stolen or left in insecure location '),
				},
				{
					id: 3,
					baseLabel: 'Mail lost or opened',
					title: this.props.t('Mail lost or opened'),
				},
				{
					id: 4,
					baseLabel: 'Hacking',
					title: this.props.t('Hacking'),
				},
				{
					id: 5,
					baseLabel: 'Malware (e.g. virus, ramsonware)',
					title: this.props.t('Malware (e.g. virus, ramsonware)'),
				},
				{
					id: 6,
					baseLabel: 'Phishing',
					title: this.props.t('Phishing'),
				},
				{
					id: 7,
					baseLabel: 'Incorrect disposal of personal data (either on paper or in electronic format)',
					title: this.props.t('Incorrect disposal of personal data (either on paper or in electronic format)'),
				},
				{
					id: 8,
					baseLabel: 'Unintended publication',
					title: this.props.t('Unintended publication'),
				},
				{
					id: 9,
					baseLabel: 'Data of wrong data subject shown',
					title: this.props.t('Data of wrong data subject shown'),
				},
				{
					id: 10,
					baseLabel: 'Personal data shown to data recepient',
					title: this.props.t('Personal data shown to data recepient'),
				},
				{
					id: 11,
					baseLabel: 'Verbal unauthorized disclosure of personal data and others (please specify)',
					title: this.props.t('Verbal unauthorized disclosure of personal data and others (please specify)'),
				},
			],
			locations: [
				{
					id: 1,
					name: 'Afghanistan',
					code: 'AF',
				},
				{
					id: 3,
					name: 'Albania',
					code: 'AL',
				},
				{
					id: 4,
					name: 'Algeria',
					code: 'DZ',
				},
				{
					id: 5,
					name: 'American Samoa',
					code: 'AS',
				},
				{
					id: 6,
					name: 'Andorra',
					code: 'AD',
				},
				{
					id: 7,
					name: 'Angola',
					code: 'AO',
				},
				{
					id: 8,
					name: 'Anguilla',
					code: 'AI',
				},
				{
					id: 9,
					name: 'Antarctica',
					code: 'AQ',
				},
				{
					id: 10,
					name: 'Antigua and Barbuda',
					code: 'AG',
				},
				{
					id: 11,
					name: 'Argentina',
					code: 'AR',
				},
				{
					id: 12,
					name: 'Armenia',
					code: 'AM',
				},
				{
					id: 13,
					name: 'Aruba',
					code: 'AW',
				},
				{
					id: 14,
					name: 'Australia',
					code: 'AU',
				},
				{
					id: 15,
					name: 'Austria',
					code: 'AT',
				},
				{
					id: 16,
					name: 'Azerbaijan',
					code: 'AZ',
				},
				{
					id: 17,
					name: 'Bahamas',
					code: 'BS',
				},
				{
					id: 18,
					name: 'Bahrain',
					code: 'BH',
				},
				{
					id: 19,
					name: 'Bangladesh',
					code: 'BD',
				},
				{
					id: 20,
					name: 'Barbados',
					code: 'BB',
				},
				{
					id: 21,
					name: 'Belarus',
					code: 'BY',
				},
				{
					id: 22,
					name: 'Belgium',
					code: 'BE',
				},
				{
					id: 23,
					name: 'Belize',
					code: 'BZ',
				},
				{
					id: 24,
					name: 'Benin',
					code: 'BJ',
				},
				{
					id: 25,
					name: 'Bermuda',
					code: 'BM',
				},
				{
					id: 26,
					name: 'Bhutan',
					code: 'BT',
				},
				{
					id: 27,
					name: 'Bolivia (Plurinational State of)',
					code: 'BO',
				},
				{
					id: 28,
					name: 'Bonaire, Sint Eustatius and Saba',
					code: 'BQ',
				},
				{
					id: 29,
					name: 'Bosnia and Herzegovina',
					code: 'BA',
				},
				{
					id: 30,
					name: 'Botswana',
					code: 'BW',
				},
				{
					id: 31,
					name: 'Bouvet Island',
					code: 'BV',
				},
				{
					id: 32,
					name: 'Brazil',
					code: 'BR',
				},
				{
					id: 33,
					name: 'British Indian Ocean Territory',
					code: 'IO',
				},
				{
					id: 37,
					name: 'Brunei Darussalam',
					code: 'BN',
				},
				{
					id: 38,
					name: 'Bulgaria',
					code: 'BG',
				},
				{
					id: 39,
					name: 'Burkina Faso',
					code: 'BF',
				},
				{
					id: 40,
					name: 'Burundi',
					code: 'BI',
				},
				{
					id: 44,
					name: 'Cabo Verde',
					code: 'CV',
				},
				{
					id: 41,
					name: 'Cambodia',
					code: 'KH',
				},
				{
					id: 42,
					name: 'Cameroon',
					code: 'CM',
				},
				{
					id: 43,
					name: 'Canada',
					code: 'CA',
				},
				{
					id: 45,
					name: 'Cayman Islands',
					code: 'KY',
				},
				{
					id: 46,
					name: 'Central African Republic',
					code: 'CF',
				},
				{
					id: 47,
					name: 'Chad',
					code: 'TD',
				},
				{
					id: 48,
					name: 'Chile',
					code: 'CL',
				},
				{
					id: 49,
					name: 'China',
					code: 'CN',
				},
				{
					id: 50,
					name: 'Christmas Island',
					code: 'CX',
				},
				{
					id: 51,
					name: 'Cocos (Keeling) Islands',
					code: 'CC',
				},
				{
					id: 52,
					name: 'Colombia',
					code: 'CO',
				},
				{
					id: 53,
					name: 'Comoros',
					code: 'KM',
				},
				{
					id: 54,
					name: 'Congo',
					code: 'CG',
				},
				{
					id: 55,
					name: 'Congo (Democratic Republic of the)',
					code: 'CD',
				},
				{
					id: 56,
					name: 'Cook Islands',
					code: 'CK',
				},
				{
					id: 57,
					name: 'Costa Rica',
					code: 'CR',
				},
				{
					id: 58,
					name: 'Croatia',
					code: 'HR',
				},
				{
					id: 59,
					name: 'Cuba',
					code: 'CU',
				},
				{
					id: 60,
					name: 'CuraÃ§ao',
					code: 'CW',
				},
				{
					id: 61,
					name: 'Cyprus',
					code: 'CY',
				},
				{
					id: 62,
					name: 'Czech Republic',
					code: 'CZ',
				},
				{
					id: 107,
					name: "CÃ´te d'Ivoire",
					code: 'CI',
				},
				{
					id: 63,
					name: 'Denmark',
					code: 'DK',
				},
				{
					id: 64,
					name: 'Djibouti',
					code: 'DJ',
				},
				{
					id: 65,
					name: 'Dominica',
					code: 'DM',
				},
				{
					id: 66,
					name: 'Dominican Republic',
					code: 'DO',
				},
				{
					id: 67,
					name: 'Ecuador',
					code: 'EC',
				},
				{
					id: 68,
					name: 'Egypt',
					code: 'EG',
				},
				{
					id: 69,
					name: 'El Salvador',
					code: 'SV',
				},
				{
					id: 70,
					name: 'Equatorial Guinea',
					code: 'GQ',
				},
				{
					id: 71,
					name: 'Eritrea',
					code: 'ER',
				},
				{
					id: 72,
					name: 'Estonia',
					code: 'EE',
				},
				{
					id: 73,
					name: 'Ethiopia',
					code: 'ET',
				},
				{
					id: 74,
					name: 'Falkland Islands (Malvinas)',
					code: 'FK',
				},
				{
					id: 75,
					name: 'Faroe Islands',
					code: 'FO',
				},
				{
					id: 76,
					name: 'Fiji',
					code: 'FJ',
				},
				{
					id: 77,
					name: 'Finland',
					code: 'FI',
				},
				{
					id: 78,
					name: 'France',
					code: 'FR',
				},
				{
					id: 79,
					name: 'French Guiana',
					code: 'GF',
				},
				{
					id: 80,
					name: 'French Polynesia',
					code: 'PF',
				},
				{
					id: 81,
					name: 'French Southern Territories',
					code: 'TF',
				},
				{
					id: 82,
					name: 'Gabon',
					code: 'GA',
				},
				{
					id: 83,
					name: 'Gambia',
					code: 'GM',
				},
				{
					id: 84,
					name: 'Georgia',
					code: 'GE',
				},
				{
					id: 85,
					name: 'Germany',
					code: 'DE',
				},
				{
					id: 86,
					name: 'Ghana',
					code: 'GH',
				},
				{
					id: 87,
					name: 'Gibraltar',
					code: 'GI',
				},
				{
					id: 88,
					name: 'Greece',
					code: 'GR',
				},
				{
					id: 89,
					name: 'Greenland',
					code: 'GL',
				},
				{
					id: 90,
					name: 'Grenada',
					code: 'GD',
				},
				{
					id: 91,
					name: 'Guadeloupe',
					code: 'GP',
				},
				{
					id: 92,
					name: 'Guam',
					code: 'GU',
				},
				{
					id: 93,
					name: 'Guatemala',
					code: 'GT',
				},
				{
					id: 94,
					name: 'Guernsey',
					code: 'GG',
				},
				{
					id: 95,
					name: 'Guinea',
					code: 'GN',
				},
				{
					id: 96,
					name: 'Guinea-Bissau',
					code: 'GW',
				},
				{
					id: 97,
					name: 'Guyana',
					code: 'GY',
				},
				{
					id: 98,
					name: 'Haiti',
					code: 'HT',
				},
				{
					id: 99,
					name: 'Heard Island and McDonald Islands',
					code: 'HM',
				},
				{
					id: 100,
					name: 'Holy See',
					code: 'VA',
				},
				{
					id: 101,
					name: 'Honduras',
					code: 'HN',
				},
				{
					id: 102,
					name: 'Hong Kong',
					code: 'HK',
				},
				{
					id: 103,
					name: 'Hungary',
					code: 'HU',
				},
				{
					id: 104,
					name: 'Iceland',
					code: 'IS',
				},
				{
					id: 105,
					name: 'India',
					code: 'IN',
				},
				{
					id: 106,
					name: 'Indonesia',
					code: 'ID',
				},
				{
					id: 108,
					name: 'Iran (Islamic Republic of)',
					code: 'IR',
				},
				{
					id: 109,
					name: 'Iraq',
					code: 'IQ',
				},
				{
					id: 110,
					name: 'Ireland',
					code: 'IE',
				},
				{
					id: 111,
					name: 'Isle of Man',
					code: 'IM',
				},
				{
					id: 112,
					name: 'Israel',
					code: 'IL',
				},
				{
					id: 113,
					name: 'Italy',
					code: 'IT',
				},
				{
					id: 114,
					name: 'Jamaica',
					code: 'JM',
				},
				{
					id: 115,
					name: 'Japan',
					code: 'JP',
				},
				{
					id: 116,
					name: 'Jersey',
					code: 'JE',
				},
				{
					id: 117,
					name: 'Jordan',
					code: 'JO',
				},
				{
					id: 118,
					name: 'Kazakhstan',
					code: 'KZ',
				},
				{
					id: 119,
					name: 'Kenya',
					code: 'KE',
				},
				{
					id: 120,
					name: 'Kiribati',
					code: 'KI',
				},
				{
					id: 166,
					name: "Korea (Democratic People's Republic of)",
					code: 'KP',
				},
				{
					id: 211,
					name: 'Korea (Republic of)',
					code: 'KR',
				},
				{
					id: 121,
					name: 'Kuwait',
					code: 'KW',
				},
				{
					id: 122,
					name: 'Kyrgyzstan',
					code: 'KG',
				},
				{
					id: 123,
					name: "Lao People's Democratic Republic",
					code: 'LA',
				},
				{
					id: 124,
					name: 'Latvia',
					code: 'LV',
				},
				{
					id: 125,
					name: 'Lebanon',
					code: 'LB',
				},
				{
					id: 126,
					name: 'Lesotho',
					code: 'LS',
				},
				{
					id: 127,
					name: 'Liberia',
					code: 'LR',
				},
				{
					id: 128,
					name: 'Libya',
					code: 'LY',
				},
				{
					id: 129,
					name: 'Liechtenstein',
					code: 'LI',
				},
				{
					id: 130,
					name: 'Lithuania',
					code: 'LT',
				},
				{
					id: 131,
					name: 'Luxembourg',
					code: 'LU',
				},
				{
					id: 132,
					name: 'Macao',
					code: 'MO',
				},
				{
					id: 133,
					name: 'Macedonia (the former Yugoslav Republic of)',
					code: 'MK',
				},
				{
					id: 134,
					name: 'Madagascar',
					code: 'MG',
				},
				{
					id: 135,
					name: 'Malawi',
					code: 'MW',
				},
				{
					id: 136,
					name: 'Malaysia',
					code: 'MY',
				},
				{
					id: 137,
					name: 'Maldives',
					code: 'MV',
				},
				{
					id: 138,
					name: 'Mali',
					code: 'ML',
				},
				{
					id: 139,
					name: 'Malta',
					code: 'MT',
				},
				{
					id: 140,
					name: 'Marshall Islands',
					code: 'MH',
				},
				{
					id: 141,
					name: 'Martinique',
					code: 'MQ',
				},
				{
					id: 142,
					name: 'Mauritania',
					code: 'MR',
				},
				{
					id: 143,
					name: 'Mauritius',
					code: 'MU',
				},
				{
					id: 144,
					name: 'Mayotte',
					code: 'YT',
				},
				{
					id: 145,
					name: 'Mexico',
					code: 'MX',
				},
				{
					id: 146,
					name: 'Micronesia (Federated States of)',
					code: 'FM',
				},
				{
					id: 147,
					name: 'Moldova (Republic of)',
					code: 'MD',
				},
				{
					id: 148,
					name: 'Monaco',
					code: 'MC',
				},
				{
					id: 149,
					name: 'Mongolia',
					code: 'MN',
				},
				{
					id: 150,
					name: 'Montenegro',
					code: 'ME',
				},
				{
					id: 151,
					name: 'Montserrat',
					code: 'MS',
				},
				{
					id: 152,
					name: 'Morocco',
					code: 'MA',
				},
				{
					id: 153,
					name: 'Mozambique',
					code: 'MZ',
				},
				{
					id: 154,
					name: 'Myanmar',
					code: 'MM',
				},
				{
					id: 155,
					name: 'Namibia',
					code: 'NA',
				},
				{
					id: 156,
					name: 'Nauru',
					code: 'NR',
				},
				{
					id: 157,
					name: 'Nepal',
					code: 'NP',
				},
				{
					id: 158,
					name: 'Netherlands',
					code: 'NL',
				},
				{
					id: 159,
					name: 'New Caledonia',
					code: 'NC',
				},
				{
					id: 160,
					name: 'New Zealand',
					code: 'NZ',
				},
				{
					id: 161,
					name: 'Nicaragua',
					code: 'NI',
				},
				{
					id: 162,
					name: 'Niger',
					code: 'NE',
				},
				{
					id: 163,
					name: 'Nigeria',
					code: 'NG',
				},
				{
					id: 164,
					name: 'Niue',
					code: 'NU',
				},
				{
					id: 165,
					name: 'Norfolk Island',
					code: 'NF',
				},
				{
					id: 167,
					name: 'Northern Mariana Islands',
					code: 'MP',
				},
				{
					id: 168,
					name: 'Norway',
					code: 'NO',
				},
				{
					id: 169,
					name: 'Oman',
					code: 'OM',
				},
				{
					id: 170,
					name: 'Pakistan',
					code: 'PK',
				},
				{
					id: 171,
					name: 'Palau',
					code: 'PW',
				},
				{
					id: 172,
					name: 'Palestine, State of',
					code: 'PS',
				},
				{
					id: 173,
					name: 'Panama',
					code: 'PA',
				},
				{
					id: 174,
					name: 'Papua New Guinea',
					code: 'PG',
				},
				{
					id: 175,
					name: 'Paraguay',
					code: 'PY',
				},
				{
					id: 176,
					name: 'Peru',
					code: 'PE',
				},
				{
					id: 177,
					name: 'Philippines',
					code: 'PH',
				},
				{
					id: 178,
					name: 'Pitcairn',
					code: 'PN',
				},
				{
					id: 179,
					name: 'Poland',
					code: 'PL',
				},
				{
					id: 180,
					name: 'Portugal',
					code: 'PT',
				},
				{
					id: 181,
					name: 'Puerto Rico',
					code: 'PR',
				},
				{
					id: 182,
					name: 'Qatar',
					code: 'QA',
				},
				{
					id: 183,
					name: 'Republic of Kosovo',
					code: 'XK',
				},
				{
					id: 185,
					name: 'Romania',
					code: 'RO',
				},
				{
					id: 186,
					name: 'Russian Federation',
					code: 'RU',
				},
				{
					id: 187,
					name: 'Rwanda',
					code: 'RW',
				},
				{
					id: 184,
					name: 'RÃ©union',
					code: 'RE',
				},
				{
					id: 188,
					name: 'Saint BarthÃ©lemy',
					code: 'BL',
				},
				{
					id: 189,
					name: 'Saint Helena, Ascension and Tristan da Cunha',
					code: 'SH',
				},
				{
					id: 190,
					name: 'Saint Kitts and Nevis',
					code: 'KN',
				},
				{
					id: 191,
					name: 'Saint Lucia',
					code: 'LC',
				},
				{
					id: 192,
					name: 'Saint Martin (French part)',
					code: 'MF',
				},
				{
					id: 193,
					name: 'Saint Pierre and Miquelon',
					code: 'PM',
				},
				{
					id: 194,
					name: 'Saint Vincent and the Grenadines',
					code: 'VC',
				},
				{
					id: 195,
					name: 'Samoa',
					code: 'WS',
				},
				{
					id: 196,
					name: 'San Marino',
					code: 'SM',
				},
				{
					id: 197,
					name: 'Sao Tome and Principe',
					code: 'ST',
				},
				{
					id: 198,
					name: 'Saudi Arabia',
					code: 'SA',
				},
				{
					id: 199,
					name: 'Senegal',
					code: 'SN',
				},
				{
					id: 200,
					name: 'Serbia',
					code: 'RS',
				},
				{
					id: 201,
					name: 'Seychelles',
					code: 'SC',
				},
				{
					id: 202,
					name: 'Sierra Leone',
					code: 'SL',
				},
				{
					id: 203,
					name: 'Singapore',
					code: 'SG',
				},
				{
					id: 204,
					name: 'Sint Maarten (Dutch part)',
					code: 'SX',
				},
				{
					id: 205,
					name: 'Slovakia',
					code: 'SK',
				},
				{
					id: 206,
					name: 'Slovenia',
					code: 'SI',
				},
				{
					id: 207,
					name: 'Solomon Islands',
					code: 'SB',
				},
				{
					id: 208,
					name: 'Somalia',
					code: 'SO',
				},
				{
					id: 209,
					name: 'South Africa',
					code: 'ZA',
				},
				{
					id: 210,
					name: 'South Georgia and the South Sandwich Islands',
					code: 'GS',
				},
				{
					id: 212,
					name: 'South Sudan',
					code: 'SS',
				},
				{
					id: 213,
					name: 'Spain',
					code: 'ES',
				},
				{
					id: 214,
					name: 'Sri Lanka',
					code: 'LK',
				},
				{
					id: 215,
					name: 'Sudan',
					code: 'SD',
				},
				{
					id: 216,
					name: 'Suriname',
					code: 'SR',
				},
				{
					id: 217,
					name: 'Svalbard and Jan Mayen',
					code: 'SJ',
				},
				{
					id: 218,
					name: 'Swaziland',
					code: 'SZ',
				},
				{
					id: 219,
					name: 'Sweden',
					code: 'SE',
				},
				{
					id: 220,
					name: 'Switzerland',
					code: 'CH',
				},
				{
					id: 221,
					name: 'Syrian Arab Republic',
					code: 'SY',
				},
				{
					id: 222,
					name: 'Taiwan',
					code: 'TW',
				},
				{
					id: 223,
					name: 'Tajikistan',
					code: 'TJ',
				},
				{
					id: 224,
					name: 'Tanzania, United Republic of',
					code: 'TZ',
				},
				{
					id: 225,
					name: 'Thailand',
					code: 'TH',
				},
				{
					id: 226,
					name: 'Timor-Leste',
					code: 'TL',
				},
				{
					id: 227,
					name: 'Togo',
					code: 'TG',
				},
				{
					id: 228,
					name: 'Tokelau',
					code: 'TK',
				},
				{
					id: 229,
					name: 'Tonga',
					code: 'TO',
				},
				{
					id: 230,
					name: 'Trinidad and Tobago',
					code: 'TT',
				},
				{
					id: 231,
					name: 'Tunisia',
					code: 'TN',
				},
				{
					id: 232,
					name: 'Turkey',
					code: 'TR',
				},
				{
					id: 233,
					name: 'Turkmenistan',
					code: 'TM',
				},
				{
					id: 234,
					name: 'Turks and Caicos Islands',
					code: 'TC',
				},
				{
					id: 235,
					name: 'Tuvalu',
					code: 'TV',
				},
				{
					id: 236,
					name: 'Uganda',
					code: 'UG',
				},
				{
					id: 237,
					name: 'Ukraine',
					code: 'UA',
				},
				{
					id: 238,
					name: 'United Arab Emirates',
					code: 'AE',
				},
				{
					id: 239,
					name: 'United Kingdom of Great Britain and Northern Ireland',
					code: 'GB',
				},
				{
					id: 34,
					name: 'United States Minor Outlying Islands',
					code: 'UM',
				},
				{
					id: 240,
					name: 'United States of America',
					code: 'US',
				},
				{
					id: 241,
					name: 'Uruguay',
					code: 'UY',
				},
				{
					id: 242,
					name: 'Uzbekistan',
					code: 'UZ',
				},
				{
					id: 243,
					name: 'Vanuatu',
					code: 'VU',
				},
				{
					id: 244,
					name: 'Venezuela (Bolivarian Republic of)',
					code: 'VE',
				},
				{
					id: 245,
					name: 'Viet Nam',
					code: 'VN',
				},
				{
					id: 35,
					name: 'Virgin Islands (British)',
					code: 'VG',
				},
				{
					id: 36,
					name: 'Virgin Islands (U.S.)',
					code: 'VI',
				},
				{
					id: 246,
					name: 'Wallis and Futuna',
					code: 'WF',
				},
				{
					id: 247,
					name: 'Western Sahara',
					code: 'EH',
				},
				{
					id: 248,
					name: 'Yemen',
					code: 'YE',
				},
				{
					id: 249,
					name: 'Zambia',
					code: 'ZM',
				},
				{
					id: 250,
					name: 'Zimbabwe',
					code: 'ZW',
				},
				{
					id: 2,
					name: 'Ã…land Islands',
					code: 'AX',
				},
			],
			popover: {
				id: null,
				active: false,
			},
			uploadedFiles: [],
			selectedDay: new Date(),
		};
	}

	handleChangeOngoing(e) {
		this.setState({
			isOngoing: e.target.value,
		});
	}

	handleChangeRadioBox(e) {
		let input = document.getElementById('displayDateID-' + e.target.value);
		if (e.target.checked == true) {
			var rates = document.getElementsByName(e.target.name);
			// var rate_value;
			for (var i = 0; i < rates.length; i++) {
				if (rates[i].checked == false) {
					document.getElementById('displayDateID-' + rates[i].value).style.display = 'none';
				}
			}
			input.style.display = 'block';
		} else {
			input.style.display = 'none';
		}

		this.setState({
			modalForCalendar: false,
		});
	}

	render() {
		return (
			<div className='page-content'>
				<Container fluid>
					<Breadcrumbs title={this.props.t('GDPR')} breadcrumbItems={this.state.breadcrumbItems} />
					<Row>
						<Col lg='12'>
							<Card>
								<CardBody>
									<div id='progrss-wizard' className='twitter-bs-wizard'>
										<ul className='twitter-bs-wizard-nav nav-justified nav nav-pills'>
											{this.state.tabs.map((tab) => {
												return (
													<NavItem key={tab.tabId}>
														<NavLink className={classnames({ active: tab.active })}>
															<span className='step-number'>{tab.tabId}</span>
															<span className='step-title'>{tab.title}</span>
														</NavLink>
													</NavItem>
												);
											})}
										</ul>

										<div id='bar' className='mt-4'>
											<Progress color='success' striped animated value={this.state.progressValue} />
										</div>

										<TabContent activeTab={this.state.activeTab} className='twitter-bs-wizard-tab-content'>
											<TabPane tabId='1' title='report'>
												{/* <AvForm className='needs-validation' model={this.state.defaultValues}>
													<Row>
														<Col sm='12' lg='9'>
															<Row>
																<Col sm='12'>
																	<div className='mb-3'>
																		<Label className='form-label' htmlFor={`question-category`}>
																			{this.props.t('In your opinion, the facts of the case fall into the following category:')}
																		</Label>
																		<AvCheckboxGroup
																			name='Question_Category'
																			errorMessage={this.props.t('This field cannot be blank')}
																			validate={{
																				required: { value: true },
																			}}
																		>
																			<Row>
																				{this.state.categories.map((category, catIndex) => {
																					return (
																						<Col sm='12' md='6' key={category.id + catIndex}>
																							<AvCheckbox label={this.props.t(category.title)} value={category.id} />
																						</Col>
																					);
																				})}
																			</Row>
																		</AvCheckboxGroup>
																	</div>
																</Col>
															</Row>

															<Row>
																<Col sm='12'>
																	<div className='mb-3'>
																		<Label className='form-label' htmlFor='description'>
																			{this.props.t('Please describe the data breach incident')}
																		</Label>
																		<AvField
																			name='description'
																			placeholder=''
																			type='text'
																			errorMessage={this.props.t('This field cannot be blank')}
																			className='form-control'
																			validate={{
																				required: { value: true },
																			}}
																			id='description'
																		/>
																	</div>
																</Col>
															</Row>

															<Row>
																<Col sm='6' md="6" lg="6">
																	<div className='mb-3'>
																		<Label className='form-label' htmlFor={`question-category`}>
																			{this.props.t('When did it happen?')}
																		</Label>
																		<AvRadioGroup
																			name='dateHappen'
																			validate={{
																				required: { value: true },
																			}}
																			errorMessage={this.props.t('This field cannot be blank')}
																		>
																			<Row>
																				<Col sm='12' md='6'>
																					<AvRadio label='Approximate date' value={'approximate_date'} />
																					<DateInputBefor
																						name='date'
																						// value={this.state.selectedDay}
																						// onValueChanged={(value) =>
																						// 	this.setState({
																						// 		selectedDay: value,
																						// 	})
																						// }
																					/>
																				</Col>
																				<Col sm='12' md='6'>
																					<AvRadio label='Exact date' value={'exact_date'} />
																					<DateInputBefor
																						name='date'
																						// value={this.state.selectedDay}
																						// onValueChanged={(value) =>
																						// 	this.setState({
																						// 		selectedDay: value,
																						// 	})
																						// }
																					/>
																				</Col>
																			</Row>
																		</AvRadioGroup>
																	</div>
																</Col>
															</Row>

															<Row>
																<Col sm='2'>
																	<div className='mb-3'>
																		<Label className='form-label' htmlFor={`question-category`}>
																			{this.props.t('Is it an ongoing breach?')}
																		</Label>

																		<AvRadioGroup
																			name='isOngoing'
																			validate={{
																				required: { value: true },
																			}}
																			errorMessage={this.props.t('This field cannot be blank')}
																		>
																			<Row>
																				<Col sm='12' md='6'>
																					<AvRadio label='Yes' value={1} />
																					<AvRadio label='No' value={0} />
																				</Col>
																			</Row>
																		</AvRadioGroup>
																	</div>
																</Col>
															</Row>
														</Col>
													</Row>
												</AvForm> */}

												<AvForm
													className='needs-validation'
													onValidSubmit={this.handleSubmit}
													onInvalidSubmit={(e) => {
														const result = [];
														const invalidFields = Object.keys(e.target.elements).map((fieldName) => {
															if (e.target.elements[fieldName].validity && !e.target.elements[fieldName].validity.valid) {
																result.push(fieldName);
																return e.target.elements[fieldName].validity.valid === false;
															}
														});
														// invalidFields.map((item) => {
														//   console.log(
														//     item,
														//     e.target.elements[item].validity.valid
														//   );
														// });
														// console.log(
														//   "Invalid",
														//   invalidFields,
														//   e.target.elements
														// );
														//
														if (result.length > 0) {
															const firstInvalidField = e.target.elements[result[0]];
															console.log(firstInvalidField);
															this.scrollToInvalidField(firstInvalidField);
														}
													}}
													ref={(formRef) => {
														this.formRef = formRef;
													}}
												>
													<Row>
														<Col sm='12' lg='9'>
															{/* {
																<Row>
																	<Col sm='12'>
																		<div className='mb-3'>
																			<Label className='form-label' htmlFor='description'>
																				{this.props.t('Brief Description of Incident')}
																			</Label>
																			<AvField
																				name='description'
																				placeholder=''
																				type='text'
																				errorMessage={this.props.t('This field cannot be blank')}
																				className='form-control'
																				validate={{
																					required: { value: true },
																				}}
																				id='description'
																			/>
																		</div>
																	</Col>
																</Row>
															} */}
															{/* {this.urlUtils.getUrlParamThirdParty() !== 'third-party' && this.userUtils.isEmployee(this.props.user) === false && (
																<Row>
																	<Col sm='12' lg='12'>
																		<div className='mb-3'>
																			<Label className='form-label' htmlFor='source'>
																				{this.props.t('Choose the channel of information')}
																			</Label>
																			<AvField
																				id='source'
																				type='select'
																				placeholder={this.props.t('Choose the channel of information')}
																				name='source'
																				errorMessage={this.props.t('This field cannot be blank')}
																				className='form-control'
																				validate={{
																					required: { value: true },
																				}}
																				value={'email'}
																			>
																				<option value='web'>{this.props.t('Web')}</option>
																				<option value='email'>{this.props.t('Email')}</option>
																				<option value='letter'>{this.props.t('Letter')}</option>
																				<option value='phone'>{this.props.t('Phone')}</option>
																				<option value='meeting'>{this.props.t('Meeting')}</option>
																				<option value='live_chat'>{this.props.t('Live Chat')}</option>
																				<option value='other'>{this.props.t(`${this.props.t('Other')}`)}</option>
																			</AvField>
																		</div>
																	</Col>
																</Row>
															)} */}

															{
																<Row>
																	<Col sm='12'>
																		<div className='mb-3'>
																			<Label className='form-label' htmlFor={`question-category`}>
																				{this.props.t('In your opinion, the facts of the case fall into the following category:')}
																			</Label>
																			<AvCheckboxGroup
																				name='Question_Category'
																				errorMessage={this.props.t('This field cannot be blank')}
																				validate={{
																					required: { value: true },
																				}}
																			>
																				<Row>
																					{this.state.categories.map((category, catIndex) => {
																						return (
																							<Col sm='12' md='6' key={category.id + catIndex}>
																								<AvCheckbox label={this.props.t(category.title)} value={category.id} />
																							</Col>
																						);
																					})}
																				</Row>
																			</AvCheckboxGroup>
																		</div>
																	</Col>
																</Row>
															}

															{/* {
																<Row>
																	<Col sm='12'>
																		<div className='mb-3'>
																			<Label className='form-label' htmlFor='locations'>
																				{this.props.t('Which location(s) is / are affected?')}
																			</Label>
																			<AvField
																				id='locations'
																				type='select'
																				name='locations'
																				errorMessage={this.props.t('This field cannot be blank')}
																				className='form-control'
																				validate={{
																					required: { value: true },
																				}}
																				defaultValue={this.state.organizationCountry}
																				value={this.state.organizationCountry}
																			>
																				{this.sortLocationsByLanguage(localStorage.getItem('i18nextLng'), this.state.locations).map((location) => {
																					return (
																						<option key={location.id} value={location.id}>
																							{this.props.t(location.name)}
																						</option>
																					);
																				})}
																			</AvField>
																		</div>
																	</Col>
																</Row>
															} */}
															{this.state.questions.map((question, index) => {
																const id = question.id;
																const title = question.title || '';
																const type = question.type;
																const key = id + index;
																const afterLogin = question.after_login;

																if (type === 'textarea' || type === 'text' || type === 'date') {
																	if (afterLogin === 'none') {
																		return (
																			<Row key={key}>
																				<Col sm='12'>
																					<div className='mb-3'>
																						<Label className='form-label' htmlFor={`question-${id}`}>
																							{this.props.t(title)}
																						</Label>
																						<AvField
																							name={'Questien[' + id + ']'}
																							placeholder=''
																							type={type}
																							errorMessage={this.props.t('This field cannot be blank')}
																							className='form-control'
																							validate={{
																								required: {
																									value: false,
																								},
																							}}
																							id={`question-${id}`}
																						/>
																					</div>
																				</Col>
																			</Row>
																		);
																	}
																	if (this.state.statusLogin === true && afterLogin === 'true') {
																		return (
																			<Row key={key}>
																				<Col sm='12'>
																					<div className='mb-3'>
																						<Label className='form-label' htmlFor={`question-${id}`}>
																							{this.props.t(title)}
																						</Label>
																						<AvField
																							name={'Questien[' + id + ']'}
																							placeholder=''
																							type={type}
																							errorMessage={this.props.t('This field cannot be blank')}
																							className='form-control'
																							validate={{
																								required: { value: true },
																							}}
																							id={`question-${id}`}
																						/>
																					</div>
																				</Col>
																			</Row>
																		);
																	}
																	if (this.state.statusLogin === false && afterLogin === 'false') {
																		return (
																			<Row key={key}>
																				<Col sm='12'>
																					<div className='mb-3'>
																						<Label className='form-label' htmlFor={`question-${id}`}>
																							{this.props.t(title)}
																						</Label>
																						<AvField
																							name={'Questien[' + id + ']'}
																							placeholder=''
																							type={type}
																							errorMessage={this.props.t('This field cannot be blank')}
																							className='form-control'
																							validate={{
																								required: { value: true },
																							}}
																							id={`question-${id}`}
																						/>
																					</div>
																				</Col>
																			</Row>
																		);
																	}
																} else if (type === 'file') {
																	return (
																		<Row key={key}>
																			<Col sm='12'>
																				<div className='mb-3'>
																					<Label className='form-label' htmlFor={`question-${id}`}>
																						{`${this.props.t('Attachments')} ${this.props.t('(Optional)')}`}
																					</Label>
																					<div
																						style={{
																							position: 'relative',
																						}}
																					>
																						<Dropzone
																							onDrop={(acceptedFiles) => this.handleAcceptedFiles(acceptedFiles, id)}
																							accept={this.dropzoneAcceptedFiles.accepted}
																						>
																							{({ getRootProps, getInputProps }) => (
																								<div className='dropzone'>
																									<div className='dz-message needsclick' {...getRootProps()}>
																										<input {...getInputProps()} />
																										<div className='mb-3'>
																											<i className='display-4 text-muted ri-upload-cloud-2-line'></i>
																										</div>
																										<h4>{this.props.t('Drop files here or click to upload')}</h4>
																									</div>
																								</div>
																							)}
																						</Dropzone>
																						<Button
																							id='dropzone-popover'
																							className='button'
																							type='button'
																							color='link'
																							style={{
																								position: 'absolute',
																								right: 8,
																								bottom: 6,
																								cursor: 'pointer',
																							}}
																							onClick={() =>
																								this.setState({
																									popover: {
																										id: 'dropzone-popover',
																										active: !this.state.popover.active,
																									},
																								})
																							}
																						>
																							<i className='ri-information-line' style={{ fontSize: 18 }}></i>
																						</Button>
																						<Popover
																							placement='bottom'
																							target='dropzone-popover'
																							isOpen={this.state.popover.id === 'dropzone-popover' && this.state.popover.active}
																							toggle={() =>
																								this.setState({
																									popover: {
																										id: 'dropzone-popover',
																										active: !this.state.popover.active,
																									},
																								})
																							}
																						>
																							<PopoverHeader>{this.props.t('Allowed Formats')}</PopoverHeader>
																							<PopoverBody>
																								<span>{this.props.t('You are allowed to upload limited file types. for more information, visit')} </span>
																								<a href='https://diss-co.tech/faq-diss-co/' target='_blank'>
																									{this.props.t('FAQ page')}
																								</a>
																							</PopoverBody>
																						</Popover>
																					</div>
																					<br />
																					<Progress color='info' striped animated value={100} hidden={!this.state.showProg}>
																						{this.props.t('Please wait while the file is uploaded')}
																					</Progress>
																					<div className='dropzone-previews mt-3' id='file-previews'>
																						{this.state.uploadedFiles.map((f, i) => {
																							return (
																								<Card
																									className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
																									key={i + '-file'}
																								>
																									<div className='p-2'>
																										<Row className='align-items-center'>
																											<Col>
																												<Link to='#' className='text-muted fw-bold'>
																													{f.name}
																												</Link>
																												<p className='mb-0'>
																													<strong>{f.formattedSize}</strong>
																												</p>
																											</Col>
																											<Col
																												xs={2}
																												style={{
																													textAlign: 'right',
																												}}
																											>
																												<Button color='danger' size='sm' onClick={() => this.handleClickDeleteFiles(f)}>
																													X
																												</Button>
																											</Col>
																										</Row>
																									</div>
																								</Card>
																							);
																						})}
																						{false && (
																							<p
																								style={{
																									color: 'red',
																									fontSize: 14,
																								}}
																								className='mt-3'
																							>
																								{this.props.t(
																									'The file type is not supported by our system that removes meta data of your file to ensure your anonymity. Please see the FAQ to learn more about the supported file types',
																								)}
																							</p>
																						)}
																					</div>
																					{/* <FileUploader 
                                                                                                name="temp"
                                                                                                placeholder=""
                                                                                                errorMessage={this.props.t("This field cannot be blank")}
                                                                                                validate={{ required: { value: false } }}
                                                                                                id={`question-${id}`}
                                                                                                callbackProps={{questionId: id}}
                                                                                                translates={{ 
                                                                                                    btnTitle: this.props.t("Choose File"),
                                                                                                    noFileChoosen: this.props.t("No file chosen") 
                                                                                                }}
                                                                                                onChangeEvent={this.uploadAttachmentFile}
                                                                                            /> */}
																				</div>
																			</Col>
																		</Row>
																	);
																} else if (type === 'checkbox') {
																	return (
																		<Row key={key}>
																			<Col sm='12'>
																				<div className='mb-3'>
																					<Label className='form-label' htmlFor={`question-${id}`}>
																						{this.props.t(title)}
																					</Label>
																					<AvCheckboxGroup
																						name={'Questien[' + id + ']'}
																						errorMessage={this.props.t('This field cannot be blank')}
																						validate={{
																							required: { value: true },
																						}}
																					>
																						<Row>
																							{question.sub_question.map((subQuestion, subIndex) => {
																								return (
																									<Col sm='12' md='6' key={subQuestion.id + subIndex}>
																										<AvCheckbox label={this.props.t(subQuestion.label)} value={subQuestion.id} />
																									</Col>
																								);
																							})}
																						</Row>
																					</AvCheckboxGroup>
																				</div>
																			</Col>
																		</Row>
																	);
																} else if (type === 'radiobox') {
																	if (afterLogin === 'none') {
																		return (
																			<Row key={key}>
																				<Col sm='12'>
																					<div className='mb-3'>
																						<Label className='form-label' htmlFor={`question-${id}`}>
																							{this.props.t(title)}
																						</Label>
																						<AvRadioGroup
																							name={'Questien[' + id + ']'}
																							validate={{
																								required: { value: true },
																							}}
																							errorMessage={this.props.t('This field cannot be blank')}
																						>
																							<Row>
																								{question.sub_question.map((subQuestion, subIndex) => {
																									return (
																										<Col sm='12' md='6' key={subQuestion.id + subIndex}>
																											<AvRadio
																												label={this.props.t(subQuestion.label)}
																												value={subQuestion.id}
																												onChange={this.handleChangeRadioBox}
																											/>
																											<div
																												id={'displayDateID-' + subQuestion.id}
																												style={{
																													display: 'none',
																												}}
																											>
																												{subQuestion.type === 'date' && (
																													<DateInputBefor
																														name='date'
																														placeholder=''
																														id={'date-' + subQuestion.id}
																														value={this.state.selectedDay}
																														onValueChanged={(value) =>
																															this.setState({
																																selectedDay: value,
																															})
																														}
																														startFrom={this.state.avToDateStartFrom}
																													/>
																												)}
																											</div>
																										</Col>
																									);
																								})}
																							</Row>
																						</AvRadioGroup>
																					</div>
																				</Col>
																			</Row>
																		);
																	}
																	if (this.state.statusLogin === true && afterLogin === 'true') {
																		return (
																			<Row key={key}>
																				<Col sm='12'>
																					<div className='mb-3'>
																						<Label className='form-label' htmlFor={`question-${id}`}>
																							{this.props.t(title)}
																						</Label>
																						<AvRadioGroup
																							name={'Questien[' + id + ']'}
																							validate={{
																								required: { value: true },
																							}}
																							errorMessage={this.props.t('This field cannot be blank')}
																						>
																							<Row>
																								{question.sub_question.map((subQuestion, subIndex) => {
																									return (
																										<Col sm='12' md='6' key={subQuestion.id + subIndex}>
																											<AvRadio
																												label={this.props.t(subQuestion.label)}
																												value={subQuestion.id}
																												onChange={this.handleChangeRadioBox}
																											/>
																											<div
																												id={'displayDateID-' + subQuestion.id}
																												style={{
																													display: 'none',
																												}}
																											>
																												{subQuestion.type === 'date' && (
																													<DateInputBefor
																														name='date'
																														placeholder=''
																														id={'date-' + subQuestion.id}
																														value={this.state.selectedDay}
																														onValueChanged={(value) =>
																															this.setState({
																																selectedDay: value,
																															})
																														}
																														startFrom={this.state.avToDateStartFrom}
																													/>
																												)}
																											</div>
																										</Col>
																									);
																								})}
																							</Row>
																						</AvRadioGroup>
																					</div>
																				</Col>
																			</Row>
																		);
																	}
																	if (this.state.statusLogin === false && afterLogin === 'false') {
																		return (
																			<Row key={key}>
																				<Col sm='12'>
																					<div className='mb-3'>
																						<Label className='form-label' htmlFor={`question-${id}`}>
																							{this.props.t(title)}
																						</Label>
																						<AvRadioGroup
																							name={'Questien[' + id + ']'}
																							validate={{
																								required: { value: true },
																							}}
																							errorMessage={this.props.t('This field cannot be blank')}
																						>
																							<Row>
																								{question.sub_question.map((subQuestion, subIndex) => {
																									return (
																										<Col sm='12' md='6' key={subQuestion.id + subIndex}>
																											<AvRadio
																												label={this.props.t(subQuestion.label)}
																												value={subQuestion.id}
																												onChange={this.handleChangeRadioBox}
																											/>
																											<div
																												id={'displayDateID-' + subQuestion.id}
																												style={{
																													display: 'none',
																												}}
																											>
																												{subQuestion.type === 'date' && (
																													<DateInputBefor
																														name='date'
																														placeholder=''
																														id={'date-' + subQuestion.id}
																														value={this.state.selectedDay}
																														onValueChanged={(value) =>
																															this.setState({
																																selectedDay: value,
																															})
																														}
																														startFrom={this.state.avToDateStartFrom}
																													/>
																												)}
																											</div>
																										</Col>
																									);
																								})}
																							</Row>
																						</AvRadioGroup>
																					</div>
																				</Col>
																			</Row>
																		);
																	}
																} else {
																	return null;
																}
															})}
															<Row>
																<Col sm='12 m-t-15'>
																	<Button
																		color='btn btn-primary'
																		type='submit'
																		disabled={this.state.showProg}
																		onClick={() =>
																			this.setState({
																				popover: {
																					id: 'dropzone-popover',
																					active: false,
																				},
																			})
																		}
																	>
																		{this.props.t('Next')}
																	</Button>
																</Col>
															</Row>
														</Col>
													</Row>
												</AvForm>
											</TabPane>
										</TabContent>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App } = state;
	return { token, Organization, user, App };
};

export default withNamespaces()(
	withRouter(
		connect(mapStatetoProps, {
			errorNotification,
			successNotification,
		})(GDPR),
	),
);
