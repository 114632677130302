import { Component, Fragment } from "react";
import { Collapse, Container } from "reactstrap";
import { Link } from "react-router-dom";
import classname from "classnames";
import MenuListItems from "../../common/data/menus";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import ModuleContext from "../../contexts/ModuleContext";
import { withRouter } from "react-router-dom";

class Navbar extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);
    this.state = {
      menu: MenuListItems,
      appState: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({});
    }
  }

  componentDidMount() {
    if (this.context.selectedModule) {
      var matchingMenuItem = null;
      var ul = document.getElementById("navigation");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  filterTitle = (menu = [], value = "") => {
    menu = menu.filter((object) => {
      return object.titleKey !== value;
    });

    return menu;
  };

  onClickShowItems = (e) => {
    // console.log("e ", e);
    if (!e.items) {
      return;
    }

    this.setState({ appState: !this.state.appState });
  };

  render() {
    if (!this.context.selectedModule) return null;

    let menu = this.state.menu[this.props.Workspace.name];

    // console.log("menu");
    // console.log(menu);
    // console.log('user')
    // console.log(this.props.user)

    if (menu.constructor === Array && menu.length) {
      // !isUser
      if (!this.props.user) {
        menu = this.filterTitle(menu, "My reports");
      }

      // isChat
      if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isChat) {
        menu = this.filterTitle(menu, "Live Chat Support");
      }

      // isMeeting
      if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isMeeting) {
        menu = this.filterTitle(menu, "Arrange a meeting");
      }

      // isVoice
      if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isVoice) {
        menu = this.filterTitle(menu, "Voice report");
      }

      // isElearning
      if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isElearning) {
        menu = this.filterTitle(menu, "E-Learning");
      }

      if (!this.props.currentUser) {
        menu = this.filterTitle(menu, "Tutorial");
      }

      // All Reports
      if (
        !this.context.selectedModule || 
        (
          !this.context.settings[this.context.selectedModule].isAnonymously &&
          !this.context.settings[this.context.selectedModule].isConfidentially &&
          !this.context.settings[this.context.selectedModule].isMeeting &&
          !this.context.settings[this.context.selectedModule].isVoice
        )
      ) {
        menu = this.filterTitle(menu, "Reporting Channel");
      } else {
        if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isMeeting) {
          // isMeeting
          menu.forEach((element) => {
            if ("items" in element) {
              element.items = this.filterTitle(
                element.items,
                "Arrange a meeting"
              );
            }
          });
        }
        if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isAnonymously) {
          // isAnonymously
          menu.forEach((element) => {
            if ("items" in element) {
              element.items = this.filterTitle(
                element.items,
                "Report anonymously"
              );
            }
          });
        }
        if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isConfidentially) {
          // isConfidentially
          menu.forEach((element) => {
            if ("items" in element) {
              element.items = this.filterTitle(
                element.items,
                "Report confidentially"
              );
            }
          });
        }
        if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isVoice) {
          // isVoice
          menu.forEach((element) => {
            if ("items" in element) {
              element.items = this.filterTitle(element.items, "Voice report");
            }
          });
        }
      }
    }

    return (
      <Fragment>
        <div className='topnav'>
          <Container fluid>
            <nav className='navbar navbar-light navbar-expand-lg topnav-menu' id='navigation'>
              <Collapse isOpen={this.props.menuOpen} className='navbar-collapse' id='topnav-menu-content'>
                <ul className='navbar-nav'>
                  {menu.map((menuItem) => {
                    if (!this.props.user && menuItem.id === 44) {
                      return null;
                    }
                    const flag = (this.props.Feature || []).filter((f) => f.title == 'Documents')[0];
                    if (menuItem.titleKey.toLowerCase() === 'Documents'.toLowerCase() && (!this.props.user || !flag || !flag.flag)) {
                      return null;
                    }
                    return menuItem.titleKey === 'FAQ' ? (
                      <li key={menuItem.id} className='nav-item'>
                        <a className='nav-link' href={process.env.REACT_APP_FAQ_URL} target='_new'>
                          <i className={menuItem.icon + 'me-2'}></i>
                          <span className='ms-1'>{this.props.t(menuItem.titleKey)}</span>
                        </a>
                      </li>
                    ) : !menuItem.role ? (
                      <li key={menuItem.id} className={'nav-item' + (menuItem.items ? ' dropdown' : '')} onClick={() => this.onClickShowItems(menuItem)}>
                        <Link className={'nav-link' + (menuItem.items ? ' dropdown-toggle arrow-none' : '')} to={menuItem.path}>
                          <i className={menuItem.icon + ' me-2'}></i>
                          {this.props.t(menuItem.titleKey)}
                          {menuItem.items ? <div className='arrow-down'></div> : null}
                        </Link>
                        {menuItem.items ? (
                          <div className={classname('dropdown-menu dropdown-menu-end', { show: this.state.appState })} aria-labelledby='topnav-apps'>
                            {menuItem.items.map((subMenuItem) => {
                              return (this.props.match.path.split('/').includes('third-party') &&
                                subMenuItem.modules &&
                                this.context.selectedModule &&
                                subMenuItem.modules.includes(this.context.selectedModule)) ||
                                !this.props.match.path.split('/').includes('third-party') ? (
                                <Link key={subMenuItem.id} to={subMenuItem.path} className='dropdown-item'>
                                  {this.props.t(subMenuItem.titleKey)}
                                </Link>
                              ) : null;
                            })}
                          </div>
                        ) : null}
                      </li>
                    ) : this.props.currentUser?.roles?.includes(menuItem.role) ? (
                      <li key={menuItem.id} className={'nav-item' + (menuItem.items ? ' dropdown' : '')} onClick={() => this.onClickShowItems(menuItem)}>
                        <Link className={'nav-link' + (menuItem.items ? ' dropdown-toggle arrow-none' : '')} to={menuItem.path}>
                          <i className={menuItem.icon + ' me-2'}></i>
                          {this.props.t(menuItem.titleKey)}
                          {menuItem.items ? <div className='arrow-down'></div> : null}
                        </Link>
                        {menuItem.items ? (
                          <div className={classname('dropdown-menu dropdown-menu-end', { show: this.state.appState })} aria-labelledby='topnav-apps'>
                            {menuItem.items.map((subMenuItem) => {
                              return (
                                <Link key={subMenuItem.id} to={subMenuItem.path} className='dropdown-item'>
                                  {this.props.t(subMenuItem.titleKey)}
                                </Link>
                              );
                            })}
                          </div>
                        ) : null}
                      </li>
                    ) : null;
                  })}

                  {this.props.Organization?.modules?.filter((m) => m.hasModule)?.length > 1 &&
                  this.context.selectedModule &&
                  this.props.match.path.split('/').includes('third-party') ? (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/third-party/start'>
                        <span className='ms-1'>
                          {/* {this.context.selectedModule === 'wb' ? 'Whistleblowing' : this.context.selectedModule === 'cm' ? 'Complaint Management' : null} */}
                          {this.props.t("Landing page")}
                        </span>
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </Collapse>
            </nav>
          </Container>
        </div>
      </Fragment>
    );    
  }
}

const mapStatetoProps = (state) => {
  const { Organization, Feature, CMFeature } = state;
  const { user: currentUser } = state.Login;
  const { leftSideBarType, leftSideBarTheme } = state.Layout;

  const activeModule = localStorage.getItem("module");

  return {
    Organization,
    Feature:
      activeModule === "wb"
        ? Feature
        : activeModule === "cm"
        ? CMFeature
        : null,
    currentUser,
    leftSideBarType,
    leftSideBarTheme,
    Workspace: { ...state.Workspace },
    user: state.Login.user,
  };
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(Navbar))
);
