import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Alert,
} from "reactstrap";
import { Input } from "reactstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import i18next from "i18next";

import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import axios from "axios";
import {
  API_URL_CUSTOMER_API,
  API_URL_ADMIN_GET_USER_LOGS,
  API_URL_ADMIN_GET_USER_MANAGEMENT_LIST,
  API_URL_ADMIN_DELETE_USER_MANAGEMENT,
  API_URL_ADMIN_UPDATE_USER_MANAGEMENT,
  EMPTY_LIST,
} from "../../../common/constants";

import { successNotification, errorNotification } from "../../../store/actions";

import { post } from "../../../helpers/request_helper";
import { getFeatureStatusByTitle } from "../../../helpers/feature_helper";

import UserUtils from "../../../services/utils/UserUtils";
import { withRouter } from "react-router-dom";
import _debounce from "lodash/debounce";

class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("User Management"), link: "#" },
      ],
      page: 1,
      users: [],
      usersFetched: false,
      sizePerPage: 10,
      userIdLogs: null,
      userIdGetLogs: {},
      userIdToDelete: null,
      deletionReason: null,
      userOptions: [],
      filteredUser: null,
      roleOptions: [],
      filteredRole: [],
      filteredTaskOwnersCount: 0
    };

    this.userUtils = new UserUtils();

    this.deleteUser = this.deleteUser.bind(this);
    this.fetchRoles = this.fetchRoles.bind(this);
    // Debounce the fetchUsers function with a 1000ms delay
    this.debouncedFetchUsers = _debounce(this.fetchUsers, 1000);
  }

  render() {
    // console.log(this.state.users)
    const { filteredTaskOwnersCount } = this.state;
    const columns = [
      {
        dataField: "first_name",
        text: this.props.t("First name"),
        sort: true,
      },
      {
        dataField: "last_name",
        text: this.props.t("Last name"),
        sort: true,
      },
      {
        dataField: "email",
        text: this.props.t("Email"),
        sort: true,
      },
      {
        dataField: "position",
        text: this.props.t("Position"),
        sort: true,
      },
      {
        dataField: "roles",
        text: this.props.t("Assigned Role"),
        sort: false,
      },
      {
        dataField: "organization_name",
        text: `${this.props.t("Company")} ${this.props.t("name")}`,
        sort: false,
      },
      {
        dataField: "status",
        text: this.props.t("User status"),
        sort: false,
      },
      {
        dataField: "edit",
        text: this.props.t("Edit"),
        sort: false,
      },
      // {
      // 	dataField: "logs",
      // 	text: this.props.t("Logs"),
      // 	sort: false,
      // },
    ];

    const defaultSorted = [];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.users.length,
      custom: true,
    };

    let data = [];

    for (const key in this.state.userIdGetLogs) {
      if (this.state.userIdGetLogs[key].length) {
        data.push(<li>{`${key}: ${this.state.userIdGetLogs[key]}`}</li>);
      }
    }

    // let logs = Object.keys(this.state.userIdGetLogs);

    // {console.log("logs", taskOwnersCount)}
    // {console.log(logs)}
    // console.log("Licence", this.props.Organization.taskOwnerLicenseCount, this.props.Organization.remainingTaskOwnerLicenseCount, this.props.Organization)
    return (
      <React.Fragment>
        {this.state.userIdLogs !== null ? (
          <SweetAlert
            title={this.props.t("Logs")}
            onCancel={() =>
              this.setState({
                userIdLogs: null,
              })
            }
            onConfirm={() =>
              this.setState({
                userIdLogs: null,
              })
            }
          >
            <ul style={{ textAlign: "left" }}>{data}</ul>
          </SweetAlert>
        ) : null}
        {this.state.userIdToDelete !== null ? (
          <SweetAlert
            title={this.props.t("Are you sure")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            cancelBtnText={this.props.t("Cancel")}
            confirmBtnText={this.props.t("OK")}
            onCancel={() =>
              this.setState({
                userIdToDelete: null,
              })
            }
            onConfirm={this.deleteUser}
          >
            {this.props.t("You wont be able to revert this")}
          </SweetAlert>
        ) : null}

          <Container className="mt-4" fluid>
            <Row>
              <Col className="col-12">
                    <div
                      className="flex mb-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {this.props.Organization &&
                      this.props.Organization.taskOwnerLicenseCount ? (
                        <p>
                          {this.props.t("Task Owner")}:{" "}
                          {
                            filteredTaskOwnersCount
                          // this.props.Organization.taskOwnerLicenseCount -
                          //   this.props.Organization
                          //     .remainingTaskOwnerLicenseCount
                              }
                          /{this.props.Organization.taskOwnerLicenseCount}
                        </p>
                      ) : (
                        <span></span>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        {getFeatureStatusByTitle(
                          this.props.Feature,
                          "ad-settings"
                        ) && (
                          <div className="flex-inline text-sm-end">
                            <Link to="/admin/administration/users/ad-settings">
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded mb-2 me-2"
                              >
                                {this.props.t("AD settings")}
                              </Button>
                            </Link>
                          </div>
                        )}
                        {getFeatureStatusByTitle(
                          this.props.Feature,
                          "new-user"
                        ) && (
                          <div className="flex-inline text-sm-end">
                            <Link to="/admin/administration/users/add">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded mb-2 me-2"
                              >
                                <i className="mdi mdi-plus me-1" />{" "}
                                {this.props.t("New user")}
                              </Button>
                            </Link>
                          </div>
                        )}
                        <div
                          style={{ minWidth: "12rem" }}
                          className="mb-2 me-2"
                        >
                          <Input
                            placeholder={this.props.t(
                              "Filter by first name or last name"
                            )}
                            classNamePrefix="select2-selection"
                            options={this.state.userOptions}
                            title={this.props.t("Users")}
                            // value={props.assignedAnalyst}
                            onChange={(user) =>
                              this.setState(
                                {
                                  filteredUser: user.target.value,
                                },
                                () => this.debouncedFetchUsers()
                                // this.fetchUsers()
                              )
                            }
                            isSearchable={true}
                          />
                        </div>

                        <div
                          style={{ minWidth: "12rem" }}
                          className="mb-2 me-2"
                        >
                          <Select
                            placeholder={this.props.t("Role")}
                            classNamePrefix="select2-selection"
                            options={this.state.roleOptions}
                            title={this.props.t("Roles")}
                            // value={props.assignedAnalyst}
                            onChange={(roles) => {
                              const tempRoles = [];
                              roles.map((role) => tempRoles.push(role.value));
                              return this.setState(
                                {
                                  filteredRole: tempRoles,
                                },
                                () => this.fetchUsers()
                              );
                            }}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                    {getFeatureStatusByTitle(
                      this.props.Feature,
                      "list-users"
                    ) ? (
                      this.state.users?.length ? (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="id"
                          columns={columns}
                          data={this.state.users}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={this.state.users.map((i) => {
                                return {
                                  ...i,
                                  roles: i.roles
                                    .map((role) => this.props.t(role))
                                    .join(", "),
                                };
                              })}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      ) : (
                        <div className="alert alert-warning" role="alert">
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: 0,
                              width: "100%",
                            }}
                          >
                            {this.props.t(EMPTY_LIST)}
                          </p>
                        </div>
                      )
                    ) : (
                      <Alert color="warning">
                        {this.props.t(
                          "You do not have permission to access these pages"
                        )}
                      </Alert>
                    )}
              </Col>
            </Row>
          </Container>
      </React.Fragment>
    );
  }

  fetchRoles = () => {
    this.setState({
      roleOptions: [
        { value: "ROLE_ANALYST", label: this.props.t("Analyst") },
        { value: "ROLE_ANALYST_ADMIN", label: this.props.t("Analyst Admin") },
        // { value: "ROLE_ANALYST_EMPLOYEE", label: this.props.t("Employee") },
        { value: "ROLE_EMPLOYEE", label: this.props.t("Employee") },
        { value: "ROLE_TASK_OWNER", label: this.props.t("Task Owner") },
      ],
    });
  };

  updateUserStatus(id, status) {
    if (!id || !status) {
      return;
    }

    const url = `${API_URL_CUSTOMER_API}user/${id}/change_status`;
    const data = {
      status: status,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };

    new Promise(async (res, rej) => {
      await axios
        .post(url, data, header)
        .then(() => {
          this.props.successNotification({
            code: "saved",
            message: this.props.t("User status updated successfully"),
          });
          let newUsers = this.state.users.map((user) => {
            if (user.id === id) {
              let newStatus =
                user.status === "ACTIVE" ? (
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => this.updateUserStatus(user.id, "INACTIVE")}
                  >
                    {i18next.t("ACTIVE")}
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => this.updateUserStatus(user.id, "ACTIVE")}
                  >
                    {i18next.t("Inactive")}
                  </Button>
                );

              return { ...user, status: newStatus };
            }

            return user;
          });

          this.setState({
            users: newUsers,
          });

          this.fetchUsers();

          this.props.history.push("/admin/administration/users");
        })
        .catch((err) => {
          if (err.response) {
            const data = err.response.data;

            this.props.errorNotification({
              code: data.error,
              message: this.props.t(data.message),
            });
            console.log(data);

            rej(data.error);
          }
          rej("fatal_error");
        });
    });
  }

  renderUsersList(list) {
    return list && list?.length
      ? list.map((item) => {
          this.state.userOptions.push({
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          });

          return {
            id: item.id,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email,
            position: item.position,
            roles: item.roles.map((i) => this.userUtils.gerUserRole(i)),
            organization_name: item.organization_name,
            status:
              item.status === "ACTIVE" ? (
                <Button
                  color="success"
                  size="sm"
                  onClick={() => this.updateUserStatus(item.id, "INACTIVE")}
                >
                  {i18next.t("Active")}
                </Button>
              ) : (
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => this.updateUserStatus(item.id, "ACTIVE")}
                >
                  {i18next.t("Inactive")}
                </Button>
              ),
            edit: (
              <div>
                <Link to={`/admin/administration/users/${item.id}/edit`}>
                  <Button color="link" className="waves-effect me-1">
                    <i className="ri-edit-2-fill"></i>
                  </Button>
                </Link>
                {getFeatureStatusByTitle(this.props.Feature, "delete-user") && (
                  <Button
                    onClick={() => {
                      this.setState({
                        userIdToDelete: item.id,
                      });
                    }}
                    color="link"
                    className="waves-effect me-1 text-danger"
                  >
                    <i className="ri-delete-bin-fill"></i>
                  </Button>
                )}
              </div>
            ),
            logs: (
              <div>
                <Button
                  color="link"
                  className="waves-effect me-1"
                  onClick={() => {
                    this.getUserLogs(item.id);
                  }}
                >
                  <i className="ri-eye-fill"></i>
                </Button>
              </div>
            ),
          };
        })
      : [];
  }

  getUserLogs(id) {
    const url = API_URL_ADMIN_GET_USER_LOGS;
    const data = {
      user_id: id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };

    new Promise(async (res, rej) => {
      await axios
        .post(url, data, header)
        .then((data) => {
          const response = data.data.data;

          console.log(response);

          typeof response !== "undefined"
            ? this.setState({
                userIdLogs: id,
                userIdGetLogs: response,
              })
            : this.props.errorNotification({
                code: "",
                message: this.props.t("No logs have been recorded"),
              });

          this.props.history.push("/admin/administration/users");
        })
        .catch((err) => {
          if (err.response) {
            const data = err.response.data;

            console.log(data);

            this.props.errorNotification({
              code: data.error,
              message: this.props.t(data.message),
            });

            rej(data.error);
          }
          rej("fatal_error");
        });
    });
  }

  deleteUser() {
    const url = API_URL_ADMIN_DELETE_USER_MANAGEMENT;
    const data = {
      user_id: this.state.userIdToDelete,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };

    new Promise(async (res, rej) => {
      await axios
        .post(url, data, header)
        .then(() => {
          this.props.successNotification({
            code: "saved",
            message: this.props.t("User deleted successfully"),
          });
          this.setState({
            users: this.state.users.filter((item) => {
              return item.id !== this.state.userIdToDelete;
            }),
            userIdToDelete: null,
          });
          this.props.history.push("/admin/administration/users");
        })
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            if (err.response.data.error === "user_has_uncompleted_tasks") {
              console.log(1231231231);
              this.props.errorNotification({
                code: "saved",
                message: this.props.t(
                  "This employee has some uncompleted tasks."
                ),
              });
            } else if (err.response) {
              const data = err.response.data;
              if (
                data.error === "unknown_error" &&
                data.message === "Access Denied."
              ) {
                this.props.errorNotification({
                  code: "saved",
                  message: this.props.t("User can not be deleted"),
                });
              } else {
                console.log("1233333333333333333333333333333");
                this.props.errorNotification({
                  code: data.error,
                  message: this.props.t(data.message),
                });
              }

              rej(data.error);
            }
          }
          rej("fatal_error");
        });
    });
  }

  async fetchUsers() {
    const { filteredUser, filteredRole, ...rest } = this.state;

    const data = {};

    if (filteredUser) {
      Object.assign(data, { name: filteredUser });
    }

    if (filteredRole?.length > 0) {
      Object.assign(data, { role: filteredRole });
    }

    // console.log("Object", data)
    axios
      .post(API_URL_ADMIN_GET_USER_MANAGEMENT_LIST, data, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        
        const users = response.data.data;
        // console.log("Response", users);
        const taskOwnersCount = users.reduce((acc, user) => {
          // console.log("Acc", acc, "Current", user.roles);
          // return acc + 1;
          return user.roles.includes("ROLE_TASK_OWNER") ?  acc + 1 : acc + 0;
        }, 0)
        console.log(taskOwnersCount)
        this.setState({
          users: this.renderUsersList(response.data.data),
          filteredTaskOwnersCount: taskOwnersCount
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate() {
    if (!this.state.usersFetched) {
      this.setState({ usersFetched: true });
      this.fetchUsers();
    }

    if (!this.state.roleOptions.length) {
      this.fetchRoles();
    }
  }

  componentDidMount() {
    if (!this.state.usersFetched) {
      this.setState({ usersFetched: true });
      this.fetchUsers();
      this.fetchRoles();
    }
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App, Feature, Organization } = state;
  return { token, App, Feature, Organization };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(ListUsers)
  )
);
