import { Fragment, memo, useEffect, useState } from 'react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import lottieJson from './../../../../assets/images/animation_lmpyeh4b.json';
import axios from 'axios';

const AIBasedRiskAssessment = function (props) {
	AIBasedRiskAssessment.displayName = 'Memorized Component - AI Risk Assessment Modal';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> AI Risk Assessment Modal');

	const { t, isOpen, createRiskFromAIResult, reportId, token } = props;

	const [AIRisks, setAIRisks] = useState(null);

	const getHelpFromAI = async (reportId, token) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_CHATBOT_API_ENDPOINT}api/risks-associated.ai`,
				{
					report_case_id: reportId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);
			if (response.status === 200 && 'risks' in response.data.data) {
				setAIRisks(Object.values(response.data.data.risks));
			}
		} catch (error) {}

		return true;
	};

	const handleFlagRisk = async (riskId, reportId, token) => {
		try {
			await axios.get(`${process.env.REACT_APP_CHATBOT_API_ENDPOINT}api/risk/${riskId}/${reportId}/change-flag`,{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
		} catch (error) {}

		return true;
	};

	useEffect(() => {
		if (isOpen) {
			getHelpFromAI(reportId, token);
		} else {
			setTimeout(() => {
				setAIRisks(null);
			}, 300);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<Fragment>
			{AIRisks ? (
				<div className='ai-risks-list'>
					{AIRisks.map((risk) => {
						return (
							<div
								className='risk bg-primary'
								onClick={() => {
									handleFlagRisk(risk.riskId, reportId, token);
									createRiskFromAIResult(
										risk.title,
										risk.description,
										risk.mitigation_tasks.map((i) => i.title),
									);
								}}
							>
								<p className='risk-title'>
									<span className='text-primary'>{t('Title')}</span>: {risk.title}
								</p>

								<p className='risk-description'>
									<span className='text-primary'>{t('Description')}</span>: {risk.description}
								</p>

								<p className='risk-mitigations'>
									<span className='text-primary'>{t('Mitigation tasks')}</span>:
								</p>
								{risk.mitigation_tasks.map((task, index) => {
									return (
										<Fragment>
											<div className='task-mitigation'>
												<span className='text-primary'>
													{index + 1}
													{`. `}
												</span>
												<p className='task-mitigation-description'>{task.title}</p>
											</div>
										</Fragment>
									);
								})}
							</div>
						);
					})}
				</div>
			) : (
				<div className='lottie-animation-box'>
					<Player autoplay loop src={lottieJson} style={{ height: '200px', width: '200px' }}>
						<Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
					</Player>
				</div>
			)}
		</Fragment>
	);
};

export default memo(AIBasedRiskAssessment);
