import { Component, Fragment } from "react";
import { logoutUser, setCaseId } from "../../../store/actions";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  API_URL_FETCH_RISK_RATINGS,
  CM_API_URL_FETCH_RISK_RATINGS,
  API_URL_GET_ANALYST_REPORT_LIST,
  CM_API_URL_GET_ANALYST_REPORT_LIST,
  API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
  CM_API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
  API_URL_GET_REPORT_LIST,
  CM_API_URL_GET_REPORT_LIST,
  API_URL_REPORT_CATEGORY_LIST_CASE,
  CM_API_URL_REPORT_CATEGORY_LIST_CASE,
  INTERNATIONAL_DATE_FORMAT,
  EMPTY_LIST,
} from "../../../common/constants";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getFeatureStatusByTitle } from "../../../helpers/feature_helper";
import TableWithPagination from "../../../components/Pagination";
import anonyReportImage from "../../../assets/images/report_anonymously.svg";
import confidentlyReportImage from "../../../assets/images/report_confidently.svg";
import arrangeMeetingImage from "../../../assets/images/arrange_meeting.svg";
import voice_report from "../../../assets/images/microphone.svg";
import DateUtils from "../../../services/utils/DateUtils";
import {
  REPORT_STATUS_DELETED,
  REPORT_STATUS_LIST,
} from "../../../components/constants";
import classnames from "classnames";
import Select from "react-select";
import { TbLoader } from "react-icons/tb";
import ModuleContext from "../../../contexts/ModuleContext";
import { withRouter } from "react-router-dom";
import DataBreachIcon from '../../../assets/images/security-breach.png';
import DataDeletionIcon from '../../../assets/images/data-deletion.png';
import MeetingIcon from '../../../assets/images/arrange_meeting.svg';
import { Redirect } from 'react-router-dom';

class ReportsList extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);

		document.title = 'Reports List | SIP';
    this.handleProvideTableColumns = this.handleProvideTableColumns.bind(this);
    this.handleRenderReportType = this.handleRenderReportType.bind(this);
    this.handleRenderReportStatus = this.handleRenderReportStatus.bind(this);
    this.handlePrepareReportsList = this.handlePrepareReportsList.bind(this);
    this.handleFetchReports = this.handleFetchReports.bind(this);
    this.handleFetchCategories = this.handleFetchCategories.bind(this);
    this.handleToggleTab = this.handleToggleTab.bind(this);
    this.handleInitiateFilterStatuses =
      this.handleInitiateFilterStatuses.bind(this);
    this.handleFetchReportCounts = this.handleFetchReportCounts.bind(this);

    this.dateUtils = new DateUtils();

    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Case Management"), link: "#" },
      ],
      activeTab: "all_reports",
      reports: null,
      reportsTotalCount: 0,
      filter: {
        status: null,
        category: null,
        risk: null,
        assignedToMe: false,
        reportedByMe: false,
      },
      sort: {
        field: null,
        order: null,
      },
      pagination: {
        pageIndex: 1,
        pageSize: 25,
      },
      categories: null,
      riskRates: null,
      filterStatuses: [],
      counts: null,
      counts_loading: true,
      redirect: true,//remove it in future
    };
  }

  /**
   * Handles the toggle of a tab.
   *
   * @param {string} tabId The ID of the tab to toggle.
   * @returns {void}
   */
  handleToggleTab(tabId) {
    if (tabId !== this.state.activeTab) {
      this.setState(
        {
          activeTab: tabId,
          filter: {
            risk: null,
            status:
              tabId === "deleted_reports"
                ? this.state.filterStatuses.find(
                    (i) => i.value === REPORT_STATUS_DELETED
                  )
                : null,
            category: null,
            assignedToMe: tabId === "assigned_to_me" ? true : false,
            reportedByMe: tabId === "reported_by_me" ? true : false,
          },
          reportsTotalCount: null,
        },
        () => {
          this.handleFilterReports(
            this.state.filter.status,
            this.state.filter.category,
            this.state.filter.risk,
            this.state.filter.assignedToMe,
            this.state.filter.reportedByMe,
            1,
            this.state.pagination.pageSize,
            this.props.token,
            null
          );
        }
      );
    }
  }

  /**
   * Initiates the filter statuses dropdown items.
   *
   * @returns {void}
   */
  handleInitiateFilterStatuses() {
    const filterStatusDropDownItems = [
      {
        value: "",
        baseLabel: "Select status",
        label: this.props.t("Select status"),
      },
    ];
    REPORT_STATUS_LIST.forEach((item) => {
      filterStatusDropDownItems.push({
        value: item.id,
        baseLabel: item.titleKey,
        label: this.props.t(item.titleKey),
      });
    });

    this.setState({
      filterStatuses: filterStatusDropDownItems,
    });
  }

  /**
   * Provides the columns for the reports table.
   *
   * @returns {Array<{dataField: string, text: string, sort?: boolean}>} The columns list.
   */
  handleProvideTableColumns() {
    const columns = [
      {
        dataField: "risk_type",
        text: this.props.t("Risk"),
        sort: false,
      },
      {
        dataField: "type",
        text: this.props.t("Type"),
        sort: false,
        style: {
          maxWidth: "200px"
        },
        headerStyle: {
          maxWidth: "200px"
        }
      },
      {
        dataField: "case_id",
        text: this.props.t("Case ID"),
        sort: false,
        style: {
          display: "table-cell",
          maxWidth: "200px",
        },
        headerStyle: {
          maxWidth: "200px",
        },
      },
      {
        dataField: "category",
        text: this.props.t("Category"),
        style: { display: "table-cell", maxWidth: "260px" },
        headerStyle: { maxWidth: "260px" },
      },
      {
        dataField: "description",
        text: this.props.t("Description"),
        sort: false,
      },
      {
        dataField: "submission_date",
        text: this.props.t("Submission date"),
        sort: true,
        onSort: (field, order) => {
          this.handleFilterReports(
            this.state.filter.status,
            this.state.filter.category,
            this.state.filter.risk,
            this.state.filter.assignedToMe,
            this.state.filter.reportedByMe,
            1,
            this.state.pagination.pageSize,
            this.props.token,
            {
              field,
              order,
            }
          );
        },
      },
      {
        dataField: "closing_date",
        text: this.props.t("Closing date"),
        sort: true,
        onSort: (field, order) => {
          this.handleFilterReports(
            this.state.filter.status,
            this.state.filter.category,
            this.state.filter.risk,
            this.state.filter.assignedToMe,
            this.state.filter.reportedByMe,
            1,
            this.state.pagination.pageSize,
            this.props.token,
            {
              field,
              order,
            }
          );
        },
      },
      {
        dataField: "analyst_assigned",
        text: this.props.t("Assigned analyst"),
        sort: true,
        onSort: (field, order) => {
          this.handleFilterReports(
            this.state.filter.status,
            this.state.filter.category,
            this.state.filter.risk,
            this.state.filter.assignedToMe,
            this.state.filter.reportedByMe,
            1,
            this.state.pagination.pageSize,
            this.props.token,
            {
              field,
              order,
            }
          );
        },
      },
      {
        dataField: "status",
        text: this.props.t("Status"),
        sort: true,
        onSort: (field, order) => {
          this.handleFilterReports(
            this.state.filter.status,
            this.state.filter.category,
            this.state.filter.risk,
            this.state.filter.assignedToMe,
            this.state.filter.reportedByMe,
            1,
            this.state.pagination.pageSize,
            this.props.token,
            {
              field,
              order,
            }
          );
        },
      },
    ];

    return columns;
  }

  /**
   * Renders the report type icon.
   *
   * @param {number} type The type of report.
   * @returns {JSX.Element} The icon element.
   */
  handleRenderReportType(type) {
    switch (type?.toLowerCase()) {
        case "data breach incident": {
            return (
                <p className='dt-field'>
                  <img
                    style={{ width: '24px', height: '24px', marginRight: 9 }}
                    alt={type}
                    className='rounded-circle card-img'
                    src={DataBreachIcon}
                  />
                  <span style={{ textTransform: 'capitalize' }}>{type}</span>
                </p>
            )
        }
        case "data deletion request": {
            return (
                <p className='dt-field'>
                  <img
                    style={{ width: '24px', height: '24px', marginRight: 9 }}
                    alt={type}
                    className='rounded-circle card-img'
                    src={DataDeletionIcon}
                  />
                  <span style={{ textTransform: 'capitalize' }}>{type}</span>
                </p>
            )
        }
        case "processing activity": {
            return (
                <p className='dt-field'>
                  <img
                    style={{ width: '24px', height: '24px', marginRight: 9 }}
                    alt={type}
                    className='rounded-circle card-img'
                    src={MeetingIcon}
                  />
                  <span style={{ textTransform: 'capitalize' }}>{type}</span>
                </p>
            )
        }
      }
    }

  /**
   * Renders the report status.
   *
   * @param {number} statusId The ID of the report status.
   * @returns {JSX.Element} The rendered report status.
   */
  handleRenderReportStatus(statusId) {
    const status = REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(statusId)
    );
    return (
      <div className={status.className}>{this.props.t(status.titleKey)}</div>
    );
  }

  /**
   * Renders the report categories.
   *
   * @param {Array<number>} reportCategories The IDs of the report categories.
   * @param {Array<Category>} categories The list of categories.
   * @returns {string} The rendered report categories.
   */
  handleRenderReportCategories(reportCategories, categories) {
    const result =
      categories && categories?.length > 0
        ? categories
            .filter((i) => reportCategories?.includes(i.id))
            .map((i) => this.props.t(i.title))
        : null;
    return result.join(", ");
  }

  /**
   * Prepares the reports list for render.
   *
   * @param {Array<Report>} list The list of reports.
   * @returns {Array<Report>} Prepared list.
   */
  handlePrepareReportsList(list) {
    return list.map((item) => {
      const caseId = (
        <Link
          className="text-dark"
          to={`/admin/data-protection/report/detail`}
          onClick={() => {
            localStorage.setItem("caseId", item.case_id);
            localStorage.setItem("secondaryCaseId", item.secondary_case_id);
            this.props.onSetCaseId(item.case_id);
          }}
        >
          {item.secondary_case_id}
        </Link>
      );

      return {
        risk_type: (
          <span
            style={{ backgroundColor: `#${item.risk}` }}
            className="risk-rate"
          ></span>
        ),
        id: item.case_id,
        case_id: caseId,
        type: this.handleRenderReportType(item.questionnaire),
        // description: 'admin_description' in item ? item.admin_description : item.description,
        description:
          "admin_description" in item && item.admin_description.trim()
            ? item.admin_description.length > 70 ? `${item.admin_description.substring(0, 70)}...` : item.admin_description
            : item.description ? (item.description.length > 70 ? `${item.description.substring(0, 70)}...` : item.description) : '',
        // category: this.state.categories
        // 	? this.handleRenderReportCategories('admin_category' in item ? item.admin_category : item.category, this.state.categories)
        // 	: [],
        category: this.state.categories
          ? this.handleRenderReportCategories(
              "admin_category" in item && item.admin_category.length > 0
                ? item.admin_category
                : item.category,
              this.state.categories
            )
          : [],
        source: (
          <div
            className="badge text-bg-primary"
            style={{ textTransform: "capitalize" }}
          >
            {this.props.t(item.source)}
          </div>
        ),
        submission_date: this.dateUtils.convertTimeStampToDate(
          item.submission_date,
          INTERNATIONAL_DATE_FORMAT
        ),
        closing_date: this.dateUtils.convertTimeStampToDate(
          item.closing_date,
          INTERNATIONAL_DATE_FORMAT
        ),
        analyst_assigned: item.analyst_assigned.id
          ? `${item.analyst_assigned.first_name} ${item.analyst_assigned.last_name}`
          : "",
        status: (
          <div>
            {this.handleRenderReportStatus(item.status)}
            <div>
              <small className="text-muted">
                {this.props.t("Updated")}:{" "}
                {this.dateUtils.convertTimeStampToDate(
                  item.status_updating_date,
                  INTERNATIONAL_DATE_FORMAT
                )}
              </small>
            </div>
          </div>
        ),
        // status: 'status',
      };
    });
  }

  /**
   * Prepare filter query and fetches the reports based on prepared query.
   *
   * @param {Object} status The status of the reports to filter.
   * @param {Array<Object>} category The categories of the reports to filter.
   * @param {Object} risk The risk levels of the reports to filter.
   * @param {boolean} assignedToMe Whether to filter reports that are assigned to the current user.
   * @param {number} pageIndex The index of the target page.
   * @param {number} pageSize The number of reports per page.
   * @param {string} token The authentication token.
   */
  handleFilterReports(
    status,
    category,
    risk,
    assignedToMe,
    reportedByMe,
    pageIndex,
    pageSize,
    token,
    sort
  ) {
    this.setState({
      filter: {
        status,
        category,
        risk,
        assignedToMe,
        reportedByMe,
      },
      sort: {
        field: sort ? sort.field : null,
        order: sort ? sort.order : null,
      },
      pagination: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    });

    this.handleFetchReports(
      {
        status: status?.value,
        risk: risk?.value,
        category: category
          ? category?.map((i) => {
              return { value: i.value };
            })
          : [],
        assignedToMe: assignedToMe,
        reportedByMe: reportedByMe,
      },
      {
        pageIndex: pageIndex,
        pageSize: this.state.pagination.pageSize,
      },
      token,
      sort
    );
  }

  /**
   * Fetches the reports.
   *
   * @param {object} filter The filter object that specifies the criteria for the reports to fetch.
   * @param {object} pagination The pagination object that specifies the page index and page size.
   * @param {string} token The authentication token.
   */
  async handleFetchReports(filter, pagination, token, sort) {
    try {
      this.setState({
        reports: [],
        reportsTotalCount: null,
      });

      const data = {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      };

      if (filter) {
        if (filter.risk) data["risk"] = filter.risk;
        if (filter.status || filter.status === 0)
          data["status"] = filter.status;
        if (filter.category) data["categories"] = filter.category;
        if (filter.assignedToMe) {
          data["assignedToMe"] = true;
        } else {
          data["assignedToMe"] = false;
        }
      }

      if (sort) {
        if (sort.field) data["sortField"] = sort.field;
        if (sort.order) data["sortOrder"] = sort.order;
      }

      const url = filter.reportedByMe
        ? this.context.selectedModule === "wb"
          ? API_URL_GET_REPORT_LIST
          : CM_API_URL_GET_REPORT_LIST
        : this.context.selectedModule === "wb"
        ? API_URL_GET_ANALYST_REPORT_LIST
        : CM_API_URL_GET_ANALYST_REPORT_LIST;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const counts = Object.create(this.state.counts);

        // eslint-disable-next-line default-case
        switch (this.state.activeTab) {
          case "all_reports":
            counts["all"] = Number(response.data.data.itemsCount);
            break;
          case "assigned_to_me":
            counts["assigned_to_me"] = Number(response.data.data.itemsCount);
            break;
          case "deleted_reports":
            counts["deleted"] = Number(response.data.data.itemsCount);
            break;
          case "reported_by_me":
            counts["my_cases"] = Number(response.data.data.itemsCount);
            break;
        }

        this.setState({
          reports: response.data.data.reports,
          reportsTotalCount: Number(response.data.data.itemsCount),
          counts: counts,
        });
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          "this error only appears in the development environment:\nerror while fetching reports:",
          error
        );
      }
    }
  }

  /**
   * Fetches the categories.
   *
   * @param {string} token The authentication token.
   */
  async handleFetchCategories(token) {
    try {
      const response = await axios.post(
        this.context.selectedModule === "wb"
          ? API_URL_REPORT_CATEGORY_LIST_CASE
          : CM_API_URL_REPORT_CATEGORY_LIST_CASE,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        this.setState({
          categories: response.data.data,
        });
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          "this error only appears in the development environment:\nerror while fetching categories:",
          error
        );
      }

      // this.props.errorNotification({
      // 	message: this.props.t(FETCH_CASES_FAILED),
      // });
    }
  }

  /**
   * Fetches the risk rates.
   *
   * @param {string} token The authentication token.
   */
  async handleFetchRiskRates(token) {
    try {
      const response = await axios.get(
        this.context.selectedModule === "wb"
          ? API_URL_FETCH_RISK_RATINGS
          : CM_API_URL_FETCH_RISK_RATINGS,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const riskRates = response.data.data.map((i) => {
          return {
            value: i.id,
            baseLabel: i.name,
            label: this.props.t(i.name),
          };
        });
        this.setState({
          riskRates: [
            {
              value: "",
              baseLabel: "All Risk",
              label: this.props.t("All Risk"),
            },
            ...riskRates,
          ],
        });
      }
    } catch (error) {}
  }

  async handleFetchReportCounts(token) {
    this.setState({
      counts_loading: true,
    });

    try {
      const response = await axios.get(
        this.context.selectedModule === "wb"
          ? API_URL_GET_ANALYST_REPORT_LISTS_COUNT
          : CM_API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        this.setState({
          counts: response.data.data,
        });
      }
    } catch (error) {}

    this.setState({
      counts_loading: false,
    });
  }

  componentDidMount() {
    this.handleInitiateFilterStatuses();

    this.handleFetchReports(
      {},
      {
        pageIndex: this.state.pagination.pageIndex,
        pageSize: this.state.pagination.pageSize,
      },
      this.props.token
    );
    this.handleFetchReportCounts(this.props.token);
    this.handleFetchCategories(this.props.token);

    // risk rates
    if (getFeatureStatusByTitle(this.props.Feature, "risk-assessment")) {
      this.handleFetchRiskRates(this.props.token);
    } else {
      this.setState({
        riskRates: [
          {
            value: "",
            baseLabel:"All Risk",
            label: this.props.t("All Risk"),
          },
          {
            value: "high",
            baseLabel:"High",
            label: this.props.t("High"),
          },
          {
            value: "medium",
            baseLabel:"Medium",
            label: this.props.t("Medium"),
          },
          {
            value: "low",
            baseLabel:"Low",
            label: this.props.t("Low"),
          },
        ],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      const filterState = Object.create(this.state.filter);
      if (this.state.filter.status) {
        filterState.status = {
          ...filterState.status,
          label: this.props.t(filterState.status.baseLabel),
        };
      }
      if (this.state.filter.risk) {
        filterState.risk = {
          ...filterState.risk,
          label: this.props.t(filterState.risk.baseLabel),
        };
      }
      if (this.state.filter.category) {
        filterState.category = filterState.category.map((c) => {
          return {
            ...c,
            label: this.props.t(c.baseLabel),
          };
        });
      }
      this.setState({
        filter: filterState,
      });
    }
  }

  render() {
    //re3move it in future
    if (this.state.redirect) {
      return <Redirect to='/admin/data-protection/incident-register' />;
    }

    return (
      <Fragment>
        <div className="page-content">
          <Container fluid style={{ minHeight: "400px" }}>
            <Breadcrumbs
              title={this.props.t("Case Management")}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            {getFeatureStatusByTitle(this.props.Feature, "list-cases") ? (
              <div>
                {/* filters */}
                <Row style={{ position: "relative", zIndex: 10 }}>
                  <Col className="col-12 col-lg-5">
                    <Card>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active: this.state.activeTab === "all_reports",
                            })}
                            onClick={() => {
                              this.handleToggleTab("all_reports");
                            }}
                          >
                            <span className="d-block" style={{width: 'max-content'}}>
                              {this.props.t("All reports")} (
                              {!this.state.counts_loading ? (
                                this.state.activeTab === "all_reports" ? (
                                  this.state.reportsTotalCount !== undefined &&
                                  this.state.reportsTotalCount !== null ? (
                                    this.state.reportsTotalCount
                                  ) : (
                                    <TbLoader />
                                  )
                                ) : (
                                  this.state.counts["all"]
                                )
                              ) : (
                                <TbLoader />
                              )}
                              )
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active: this.state.activeTab === "assigned_to_me",
                            })}
                            onClick={() => {
                              this.handleToggleTab("assigned_to_me");
                            }}
                          >
                            <span className="d-block" style={{width: 'max-content'}}>
                              {this.props.t("Assigned to Me")} (
                              {!this.state.counts_loading ? (
                                this.state.activeTab === "assigned_to_me" ? (
                                  this.state.reportsTotalCount !== undefined &&
                                  this.state.reportsTotalCount !== null ? (
                                    this.state.reportsTotalCount
                                  ) : (
                                    <TbLoader />
                                  )
                                ) : (
                                  this.state.counts["assigned_to_me"]
                                )
                              ) : (
                                <TbLoader />
                              )}
                              )
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active:
                                this.state.activeTab === "deleted_reports",
                            })}
                            onClick={() => {
                              this.handleToggleTab("deleted_reports");
                            }}
                          >
                            <span className="d-block" style={{width: 'max-content'}}>
                              {this.props.t("Deleted Cases")} (
                              {!this.state.counts_loading ? (
                                this.state.activeTab === "deleted_reports" ? (
                                  this.state.reportsTotalCount !== undefined &&
                                  this.state.reportsTotalCount !== null ? (
                                    this.state.reportsTotalCount
                                  ) : (
                                    <TbLoader />
                                  )
                                ) : (
                                  this.state.counts["deleted"]
                                )
                              ) : (
                                <TbLoader />
                              )}
                              )
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classnames({
                              active: this.state.activeTab === "reported_by_me",
                            })}
                            onClick={() => {
                              this.handleToggleTab("reported_by_me");
                            }}
                          >
                            <span className="d-block" style={{width: 'max-content'}}>
                              {this.props.t("Reported By Me")} (
                              {!this.state.counts_loading ? (
                                this.state.activeTab === "reported_by_me" ? (
                                  this.state.reportsTotalCount !== undefined &&
                                  this.state.reportsTotalCount !== null ? (
                                    this.state.reportsTotalCount
                                  ) : (
                                    <TbLoader />
                                  )
                                ) : (
                                  this.state.counts["my_cases"]
                                )
                              ) : (
                                <TbLoader />
                              )}
                              )
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Card>
                  </Col>
                  <Col className="col-12 col-lg-7">
                    <Row>
                      {/* status */}
                      {getFeatureStatusByTitle(
                        this.props.Feature,
                        "fliter-status"
                      ) &&
                        this.state.activeTab !== "deleted_reports" && (
                          <Col sm="12" md="4" className="mb-2">
                            <Select
                              classNamePrefix="select2-selection"
                              options={this.state.filterStatuses.map((i) => {
                                return {
                                  value: i.value,
                                  baseLabel: i.baseLabel,
                                  label: this.props.t(i.baseLabel),
                                };
                              })}
                              value={this.state.filter.status}
                              onChange={(e) => {
                                this.handleFilterReports(
                                  e,
                                  this.state.filter.category,
                                  this.state.filter.risk,
                                  this.state.filter.assignedToMe,
                                  this.state.filter.reportedByMe,
                                  1,
                                  this.state.pagination.pageSize,
                                  this.props.token,
                                  this.state.sort
                                );
                              }}
                              placeholder={this.props.t("Select status")}
                            />
                          </Col>
                        )}

                      {/* category */}
                      {getFeatureStatusByTitle(
                        this.props.Feature,
                        "filter-category"
                      ) && (
                        <Col sm="12" md="4" className="mb-2">
                          <Select
                            className={"select--filter-categories"}
                            classNamePrefix="select2-selection"
                            options={
                              this.state.categories
                                ? this.state.categories
                                    .filter((i) => i.flag)
                                    .map((i) => {
                                      return {
                                        value: i.id,
                                        baseLabel: i.title,
                                        label: this.props.t(i.title)
                                          ? this.props.t(i.title)
                                          : i.title,
                                      };
                                    })
                                : []
                            }
                            value={this.state.filter.category}
                            onChange={(e) => {
                              this.handleFilterReports(
                                this.state.filter.status,
                                e,
                                this.state.filter.risk,
                                this.state.filter.assignedToMe,
                                this.state.filter.reportedByMe,
                                1,
                                this.state.pagination.pageSize,
                                this.props.token,
                                this.state.sort
                              );
                            }}
                            isMulti
                            style={{
                              height: 30,
                              overflowY: "auto",
                            }}
                            placeholder={this.props.t("Select categories")}
                          />
                        </Col>
                      )}

                      {/* risk */}
                      {getFeatureStatusByTitle(
                        this.props.Feature,
                        "filter-risk"
                      ) && (
                        <Col sm="12" md="4" className="mb-2">
                          <Select
                            classNamePrefix="select2-selection"
                            options={
                              this.state.riskRates
                                ? this.state.riskRates.map((i) => {
                                    return {
                                      value: i.value,
                                      baseLabel: i.baseLabel,
                                      label: this.props.t(i.baseLabel),
                                    };
                                  })
                                : []
                            }
                            value={this.state.filter.risk}
                            isClearable={true}
                            onChange={(e) => {
                              this.handleFilterReports(
                                this.state.filter.status,
                                this.state.filter.category,
                                e,
                                this.state.filter.assignedToMe,
                                this.state.filter.reportedByMe,
                                1,
                                this.state.pagination.pageSize,
                                this.props.token,
                                this.state.sort
                              );
                            }}
                            placeholder={this.props.t("Select risk")}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>

                {/* table */}
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody className="pt-0">
                        <TabContent activeTab={this.state.activeTab}>
                          {/* all_reports */}
                          <TabPane tabId="all_reports" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>

                          {/* assigned_to_me */}
                          <TabPane tabId="assigned_to_me" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>

                          {/* deleted_reports */}
                          <TabPane tabId="deleted_reports" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>

                          {/* reported_by_me */}
                          <TabPane tabId="reported_by_me" className="p-3">
                            {this.state.reports?.length > 0 ? (
                              <TableWithPagination
                                columns={this.handleProvideTableColumns()}
                                pagination={{
                                  sizePerPage: 25,
                                  totalSize: this.state.reports
                                    ? this.state.reports.length
                                    : 0,
                                  custom: true,
                                }}
                                pageIndex={this.state.pagination.pageIndex}
                                totalSize={this.state.reportsTotalCount}
                                sizePerPage={this.state.pagination.pageSize}
                                data={
                                  this.state.reports
                                    ? this.handlePrepareReportsList(
                                        this.state.reports
                                      )
                                    : []
                                }
                                fetchData={(e) => {
                                  this.handleFilterReports(
                                    this.state.filter.status,
                                    this.state.filter.category,
                                    this.state.filter.risk,
                                    this.state.filter.assignedToMe,
                                    this.state.filter.reportedByMe,
                                    e.pageIndex,
                                    this.state.pagination.pageSize,
                                    this.props.token,
                                    this.state.sort
                                  );
                                }}
                              />
                            ) : (
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {this.props.t(EMPTY_LIST)}
                                </p>
                              </div>
                            )}
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              <Alert color="warning">
                {this.props.t(
                  "You do not have permission to access these pages"
                )}
              </Alert>
            )}
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App, Feature, CMFeature } = state;

  const activeModule = localStorage.getItem("module");

  return {
    token,
    user,
    App,
    Feature:
      activeModule === "wb"
        ? Feature
        : activeModule === "cm"
        ? CMFeature
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
    logoutUser: (history) => dispatch(logoutUser(history)),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispatchToProps)(ReportsList))
);
