import React from 'react';
import axios from 'axios';
import WebFont from 'webfontloader';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getData } from 'country-list';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Dropzone from 'react-dropzone';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	Button,
	Label,
	List,
	Nav,
	NavLink,
	NavItem,
	TabPane,
	TabContent,
	Popover,
	PopoverHeader,
	PopoverBody,
	Spinner,
} from 'reactstrap';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { getFeatureStatusByTitle } from '../../../helpers/feature_helper';
import { API_URL_EDIT_INFO_ORGANIZATION } from '../../../common/constants';
import { onEditInfoOrganizationSuccess } from '../../../store/organization/actions';
import { successNotification, errorNotification } from '../../../store/actions';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ModuleContext from '../../../contexts/ModuleContext';
import { withRouter } from "react-router-dom";

class Customization extends React.Component {
	static contextType = ModuleContext;

	constructor(props) {
		super(props);
		this.state = {
			spinner: {
				id: '',
				active: false,
			},
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: this.props.t('Customisation'), link: '#' },
			],

			defaultLanguage: '',

			phone: null,
			countryList: getData(),
			activeTabJustify: '1',

			editorState: null,
			editorStateTranslations: [],

			pageTitles: [],

			introDefaultLanguage: [],
			introOtherTranslations: [],

			primaryFont: null,
			primaryColor: null,
			fontColor: null,

			title_channel_report_tp: null,

			secondaryLogo: this.props.organization.secondaryLogo
				? [
						{
							prev: true,
							name: '',
							formattedSize: '',
							preview: this.props.organization.secondaryLogo,
						},
				  ]
				: [],
			primaryLogo: this.props.organization.logo
				? [
						{
							prev: true,
							name: '',
							formattedSize: '',
							preview: this.props.organization.logo,
						},
				  ]
				: [],

			popover: {
				id: null,
				active: false,
			},
		};

		this.dropzoneMessages = {
			title: 'Please Note',
			secondaryLogo:
				'This logo is used for the views with colored menu bar. we recommend uploading a white logo with transparent background\nYou can upload image files in the following formats: .jpg, .jpeg, .png, .JPG, .JPEG, .PNG.',
			primaryLogo:
				'This logo is used for the login pages. we recommend uploading a colored logo\nYou can upload image files in the following formats: .jpg, .jpeg, .png, .JPG, .JPEG, .PNG.',
		};

		this.customFonts = [
			{
				style: 'normal',
				family: 'Inter',
				weight: 400,
			},
			{
				style: 'normal',
				family: 'Nunito',
				weight: 400,
			},
			{
				style: 'normal',
				family: 'Raleway',
				weight: 400,
			},
			{
				style: 'normal',
				family: 'Roboto Mono',
				weight: 400,
			},
			{
				style: 'normal',
				family: 'Noto Serif Toto',
				weight: 400,
			},
			{
				style: 'normal',
				family: 'Kumbh Sans',
				weight: 400,
			},
			{
				style: 'normal',
				family: 'Padyakke Expanded One',
				weight: 400,
			},
			{
				style: 'normal',
				family: 'Noto Sans Cherokee',
				weight: 400,
			},
		];

		this.handleSubmit = this.handleSubmit.bind(this);
		this.onEditorStateChange = this.onEditorStateChange.bind(this);
		this.onEditorStateTranslations = this.onEditorStateTranslations.bind(this);
		this.handleEditInfoOrganizationSuccess = this.props.handleEditInfoOrganizationSuccess.bind(this);
		this.successNotification = this.props.successNotification.bind(this);
	}

	applyThemeStyle = (primaryColor = null, primaryLogoSize = null, secondaryLogoSize = null, primaryFont = null, fontColor = null) => {
		document.documentElement.style.setProperty('--color-body', fontColor || this.state.fontColor || '#ffffff');
		document.documentElement.style.setProperty('--color-primary', primaryColor || this.state.primaryColor || '#5664d2');
		document.documentElement.style.setProperty('--navbar-primary-brand-size', (primaryLogoSize || this.state.primaryLogoSize || 25) + 'px');
		document.documentElement.style.setProperty('--navbar-secondary-brand-size', (secondaryLogoSize || this.state.secondaryLogoSize || 25) + 'px');
		document.documentElement.style.setProperty('--primary-font', primaryFont || this.state.primaryFont || '"Inter", sans-serif');
	};

	applyCustomWebFonts = (font = null) => {
		let WebFontConfig = {};
		const customFonts = ['Kumbh Sans', 'Inter', 'Noto Sans Cherokee', 'Padyakke Expanded One', 'Noto Serif Toto', 'Roboto Mono', 'Raleway', 'Nunito']; // local fonts. white list for selecting local fonts. all fonts must be load localy

		if (customFonts.includes(font)) {
			WebFontConfig = {
				custom: {
					families: [font],
				},
			};
		} else {
			WebFontConfig = {
				google: {
					families: [font],
				},
			};

			alert('Google Font!');
		}
		WebFont.load(WebFontConfig);
	};

	toggleDetailTab = (tab) => {
		if (this.state.activeTabJustify !== tab) {
			this.setState({
				activeTabJustify: tab,
			});
		}
	};

	handleAcceptedFiles = (files, type) => {
		files.map((file) => {
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: this.formatBytes(file.size),
			});
		});
		this.setState({ [type]: files });
	};

	formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	onEditorStateChange(editorState) {
		this.setState({
			editorState,
		});
	}

	onEditorStateTranslations(text, lang, module, moduleId) {
		this.setState({
			editorStateTranslations: this.state.editorStateTranslations.filter((item) => item.lang !== lang).concat({ text, lang, module, moduleId }),
		});
	}

	onChangePageTitle = (e, lang, module, moduleId) => {
		const text = e.target.value;

		if (!text) {
			return;
		}

		this.setState({
			pageTitles: this.state.pageTitles.filter((item) => item.lang !== lang).concat({ text, lang, module, moduleId }),
		});
	};

	handleSubmit(e, values) {
		const formData = new FormData();

		let logo = null;
		let secondaryLogo = null;

		// eslint-disable-next-line array-callback-return
		this.state.pageTitles.map((item, index) => {
			if (!item.text) {
				return null;
			}

			// TODO: using a large number to prevnt possible bugs. This must be fix from API first.
			formData.append(`translations[${index + 100}][type]`, 'title');
			formData.append(`translations[${index + 100}][lang]`, item.lang);
			formData.append(`translations[${index + 100}][text]`, item.text);
			formData.append(`translations[${index + 100}][module]`, this.props.organization.modules.find((m) => m.name === this.context.selectedModule).id);
		});

		this.state.editorStateTranslations.map((item, index) => {
			if (!item.text) {
				return null;
			}

			formData.append(`translations[${index}][type]`, 'intro');
			formData.append(`translations[${index}][lang]`, item.lang);
			formData.append(`translations[${index}][text]`, draftToHtml(convertToRaw(item.text.getCurrentContent())));
			formData.append(`translations[${index}][module]`, this.props.organization.modules.find((m) => m.name === this.context.selectedModule).id);
		});

		const otherActiveModules = this.props.organization.modules.filter((m) => m.name !== this.context.selectedModule && m.hasModule);

		for (const item of otherActiveModules) {
			const translations = this.props.organization.translations.filter((t) => t.module === item.name);
			console.log(translations);
			for (const [index, translation] of translations.entries()) {
				formData.append(`translations[${index + this.state.pageTitles.length + 100}][type]`, 'title');
				formData.append(`translations[${index + this.state.pageTitles.length + 100}][lang]`, translation.lang);
				formData.append(`translations[${index + this.state.pageTitles.length + 100}][text]`, translation.title ? translation.title : '');
				formData.append(`translations[${index + this.state.pageTitles.length + 100}][module]`, item.id);

				formData.append(`translations[${index + this.state.pageTitles.length + 100 * 2}][type]`, 'intro');
				formData.append(`translations[${index + this.state.pageTitles.length + 100 * 2}][lang]`, translation.lang);
				formData.append(`translations[${index + this.state.pageTitles.length + 100 * 2}][text]`, translation.intro ? translation.intro : '');
				formData.append(`translations[${index + this.state.pageTitles.length + 100 * 2}][module]`, item.id);
			}
		}

		formData.append('name', values.name);
		formData.append('setting[fontFamily]', values.primaryFont || '');

		formData.append('setting[fontColor]', values.fontColor || '');
		formData.append('setting[primaryColor]', values.primaryColor || '');
		// formData.append("setting[secondaryColor]", values.secondaryColor || "");

		formData.append('setting[primaryLogoSize]', values.primaryLogoSize || '');
		formData.append('setting[secondaryLogoSize]', values.secondaryLogoSize || '');

		this.setState({
			spinner: {
				id: 'submit-button',
				active: true,
			},
		});

		if (this.state.primaryLogo.length > 0) {
			logo = this.state.primaryLogo[0];
			if (!logo.prev) formData.append('setting[primaryLogo]', this.state.primaryLogo[0]);
		}

		if (this.state.secondaryLogo.length > 0) {
			logo = this.state.secondaryLogo[0];
			if (!logo.prev) formData.append('setting[secondaryLogo]', this.state.secondaryLogo[0]);
		}

		axios
			.post(API_URL_EDIT_INFO_ORGANIZATION, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${this.props.token}`,
				},
			})
			.then((res) => {
				console.log('res: ', res);

				let orgInfos = {
					name: values.name,
				};
				if (logo && !logo.prev) {
					orgInfos.logo = logo.preview;
				}
				if (secondaryLogo && !secondaryLogo.prev) {
					orgInfos.secondaryLogo = secondaryLogo.preview;
				}

				this.applyThemeStyle(values.primaryColor, values.primaryLogoSize, values.secondaryLogoSize, values.primaryFont, values.fontColor);
				this.applyCustomWebFonts(values.primaryFont);

				this.successNotification({
					code: 'saved',
					message: this.props.t('Data was saved successfully'),
				});

				this.setState({
					spinner: {
						id: '',
						active: false,
					},
				});
			})
			.catch((error) => {
				console.log(error);

				if (error.response) {
					const data = error.response.data;

					this.props.errorNotification({
						code: data.error || 'unknown_error',
						message: this.props.t(data.message || data.title),
					});
				}

				this.setState({
					spinner: {
						id: '',
						active: false,
					},
				});
			});
	}

	render() {
		return (
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<Breadcrumbs title={this.props.t('Customisation')} breadcrumbItems={this.state.breadcrumbItems} />
						<Row>
							<Col xl='12'>
								<AvForm className='needs-validation' onValidSubmit={this.handleSubmit}>
									<Card>
										<CardBody>
											<Nav tabs>
												<NavItem>
													<NavLink
														style={{ cursor: 'pointer' }}
														className={classnames({
															active: this.state.activeTabJustify === '1',
														})}
														onClick={() => {
															this.toggleDetailTab('1');
														}}
													>
														<span className='d-block'>{`${this.props.t('Organization')}`}</span>
													</NavLink>
												</NavItem>
												{getFeatureStatusByTitle(this.props.Feature, 'styling') && (
													<NavItem>
														<NavLink
															style={{ cursor: 'pointer' }}
															className={classnames({
																active: this.state.activeTabJustify === '2',
															})}
															onClick={() => {
																this.toggleDetailTab('2');
															}}
														>
															<span className='d-block'>{`${this.props.t('Styling')}`}</span>
														</NavLink>
													</NavItem>
												)}
											</Nav>

											<TabContent activeTab={this.state.activeTabJustify}>
												<TabPane tabId='1' className='pt-4 pb-4'>
													<div>
														<div className='mb-3'>
															<CardTitle>{this.props.t('Customise Smart Integrity Platform - Reporting Channel Landing Page')}</CardTitle>
														</div>
														{getFeatureStatusByTitle(this.props.Feature, 'name') && (
															<div className='mb-4'>
																<Row>
																	<Col md='12'>
																		<div className='mb-3'>
																			<Row className='align-items-end'>
																				<Col md='12'>
																					<div>
																						<Label className='form-label' htmlFor='name'>
																							{this.props.t('Name')}
																						</Label>
																						<AvField
																							name='name'
																							type='text'
																							errorMessage={this.props.t('This field cannot be blank')}
																							className='form-control'
																							validate={{
																								required: { value: true },
																							}}
																							value={this.props.organization.name || ''}
																							id='name'
																						/>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	</Col>
																</Row>
															</div>
														)}
														{getFeatureStatusByTitle(this.props.Feature, 'customization-custom-fonts') && (
															<div className='mb-5'>
																<hr className='pb-3' />
																<Row>
																	<Col xs='12'>
																		<Label className='form-label'>{this.props.t('Font Family')}:</Label>
																		<AvField name='primaryFont' type='select' className='form-control' value={this.props.organization.primaryFont || ''}>
																			{this.customFonts.map((item, index) => (
																				<option style={{ fontFamily: item.family }} key={`option-font-family-${index}`} value={item.family}>{`${this.props.t(item.family)}`}</option>
																			))}
																		</AvField>
																	</Col>
																</Row>
															</div>
														)}
														{getFeatureStatusByTitle(this.props.Feature, 'customization-custom-colors') && (
															<div className='mb-5'>
																<hr className='pb-3' />
																<Row>
																	<Col sm='6'>
																		<Label className='form-label'>{this.props.t('Primary color')}:</Label>
																		<AvField
																			name='primaryColor'
																			type='color'
																			defaultValue={this.props.organization.primaryColor || '#5664d2'}
																			className='form-control form-control-color mw-100'
																			style={{ width: '100%' }}
																		/>
																	</Col>
																	<Col sm='6'>
																		<Label className='form-label'>
																			{this.props.t('Font color')} ({this.props.t('This is the font color of your side/page menu bar')}
																			):
																		</Label>
																		<AvField
																			name='fontColor'
																			type='color'
																			defaultValue={this.props.organization.fontColor || '#ffffff'}
																			className='form-control form-control-color mw-100'
																			style={{ width: '100%' }}
																		/>
																	</Col>
																</Row>
															</div>
														)}
														{getFeatureStatusByTitle(this.props.Feature, 'logo') && (
															<div className='mb-3'>
																<hr className='pb-3' />
																<Row>
																	<Col lg={6} className='mb-3'>
																		<Label className='form-label'>{this.props.t('Colored Logo')}</Label>
																		<div style={{ position: 'relative' }}>
																			<Dropzone onDrop={(acceptedFiles) => this.handleAcceptedFiles(acceptedFiles, 'primaryLogo')}>
																				{({ getRootProps, getInputProps }) => (
																					<div className='dropzone'>
																						<div className='dz-message needsclick' {...getRootProps()}>
																							<input {...getInputProps()} />
																							<div className='mb-3'>
																								<i className='display-4 text-muted ri-upload-cloud-2-line'></i>
																							</div>
																							<h5>{this.props.t('Drop files here or click to upload')}</h5>
																						</div>
																					</div>
																				)}
																			</Dropzone>
																			<Button
																				id='dropzone-popover-primary-logo-note'
																				className='button'
																				type='button'
																				color='link'
																				style={{
																					position: 'absolute',
																					right: 8,
																					bottom: 6,
																					cursor: 'pointer',
																				}}
																				onClick={() =>
																					this.setState({
																						popover: {
																							id: 'dropzone-popover-primary-logo-note',
																							active: !this.state.popover.active,
																						},
																					})
																				}
																			>
																				<i className='ri-information-line' style={{ fontSize: 18 }}></i>
																			</Button>
																			<Popover
																				placement='bottom'
																				target='dropzone-popover-primary-logo-note'
																				isOpen={this.state.popover.id === 'dropzone-popover-primary-logo-note' && this.state.popover.active}
																				toggle={() =>
																					this.setState({
																						popover: {
																							id: 'dropzone-popover-primary-logo-note',
																							active: !this.state.popover.active,
																						},
																					})
																				}
																			>
																				<PopoverHeader>{this.props.t(this.dropzoneMessages.title)}</PopoverHeader>
																				<PopoverBody>
																					{this.dropzoneMessages.primaryLogo.split('\n').map((item, key) => (
																						<span key={key}>
																							{this.props.t(item)}
																							<br />
																						</span>
																					))}
																				</PopoverBody>
																			</Popover>
																		</div>
																		<div className='dropzone-previews mt-3'>
																			{this.state.primaryLogo.length > 0 && (
																				<div>
																					{this.state.primaryLogo.map((f, i) => {
																						return (
																							<Card
																								className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
																								key={`primary-logo-file-${i}`}
																							>
																								<div className='p-2'>
																									<Row className='align-items-center'>
																										<Col className='col-auto'>
																											<div
																												style={{
																													padding: 6,
																													marginLeft: 6,
																												}}
																											>
																												<img
																													className='avatar-sm rounded bg-light'
																													alt={f.name}
																													src={f.preview}
																													style={{
																														width: 'auto',
																														height: 40,
																													}}
																												/>
																											</div>
																										</Col>
																										<Col>
																											<Link to='#' className='text-muted fw-bold'>
																												{f.name}
																											</Link>
																											<p className='mb-0'>
																												<strong>{f.formattedSize}</strong>
																											</p>
																										</Col>
																									</Row>
																								</div>
																							</Card>
																						);
																					})}
																				</div>
																			)}
																		</div>

																		{this.state.primaryLogo.length > 0 && (
																			<div className='mt-3'>
																				<Label className='form-label'>{`${this.props.t('Logo Size')} (${this.props.t('Pixel')})`}</Label>
																				<AvField
																					name='primaryLogoSize'
																					type='number'
																					errorMessage={this.props.t('This field cannot be blank')}
																					className='form-control'
																					validate={{
																						required: { value: false },
																					}}
																					value={this.props.organization.primaryLogoSize || ''}
																					min={15}
																					max={120}
																				/>
																			</div>
																		)}
																	</Col>
																	<Col lg={6}>
																		<Label className='form-label'>{this.props.t('White Logo')}</Label>
																		<div style={{ position: 'relative' }}>
																			<Dropzone onDrop={(acceptedFiles) => this.handleAcceptedFiles(acceptedFiles, 'secondaryLogo')}>
																				{({ getRootProps, getInputProps }) => (
																					<div className='dropzone'>
																						<div className='dz-message needsclick' {...getRootProps()}>
																							<input {...getInputProps()} />
																							<div className='mb-3'>
																								<i className='display-4 text-muted ri-upload-cloud-2-line'></i>
																							</div>
																							<h5>{this.props.t('Drop files here or click to upload')}</h5>
																						</div>
																					</div>
																				)}
																			</Dropzone>
																			<Button
																				id='dropzone-popover-light-logo-note'
																				className='button'
																				type='button'
																				color='link'
																				style={{
																					position: 'absolute',
																					right: 8,
																					bottom: 6,
																					cursor: 'pointer',
																				}}
																				onClick={() =>
																					this.setState({
																						popover: {
																							id: 'dropzone-popover-light-logo-note',
																							active: !this.state.popover.active,
																						},
																					})
																				}
																			>
																				<i className='ri-information-line' style={{ fontSize: 18 }}></i>
																			</Button>
																			<Popover
																				placement='bottom'
																				target='dropzone-popover-light-logo-note'
																				isOpen={this.state.popover.id === 'dropzone-popover-light-logo-note' && this.state.popover.active}
																				toggle={() =>
																					this.setState({
																						popover: {
																							id: 'dropzone-popover-light-logo-note',
																							active: !this.state.popover.active,
																						},
																					})
																				}
																			>
																				<PopoverHeader>{this.props.t(this.dropzoneMessages.title)}</PopoverHeader>
																				<PopoverBody>
																					{this.dropzoneMessages.secondaryLogo.split('\n').map((item, key) => (
																						<span key={key}>
																							{this.props.t(item)}
																							<br />
																						</span>
																					))}
																				</PopoverBody>
																			</Popover>
																		</div>
																		<div className='dropzone-previews mt-3'>
																			{this.state.secondaryLogo.length > 0 && (
																				<div>
																					{this.state.secondaryLogo.map((f, i) => {
																						return (
																							<Card
																								className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
																								key={`primary-logo-file-${i}`}
																							>
																								<div className='p-2'>
																									<Row className='align-items-center'>
																										<Col className='col-auto'>
																											<div
																												style={{
																													padding: 6,
																													marginLeft: 6,
																												}}
																											>
																												<img
																													className='avatar-sm rounded bg-light'
																													alt={f.name}
																													src={f.preview}
																													style={{
																														width: 'auto',
																														height: 40,
																													}}
																												/>
																											</div>
																										</Col>
																										<Col>
																											<Link to='#' className='text-muted fw-bold'>
																												{f.name}
																											</Link>
																											<p className='mb-0'>
																												<strong>{f.formattedSize}</strong>
																											</p>
																										</Col>
																									</Row>
																								</div>
																							</Card>
																						);
																					})}
																				</div>
																			)}
																		</div>

																		{this.state.secondaryLogo.length > 0 && (
																			<div className='mt-3'>
																				<Label className='form-label'>{`${this.props.t('Logo Size')} (${this.props.t('Pixel')})`}</Label>
																				<AvField
																					name='secondaryLogoSize'
																					type='number'
																					errorMessage={this.props.t('This field cannot be blank')}
																					className='form-control'
																					validate={{
																						required: { value: false },
																					}}
																					value={this.props.organization.secondaryLogoSize || ''}
																					min={15}
																					max={70}
																				/>
																			</div>
																		)}
																	</Col>
																</Row>
															</div>
														)}
														{getFeatureStatusByTitle(this.props.Feature, 'intro') && (
															<div className='pt-3 mb-3'>
																<hr className='pt-3 pb-3' />
																<Row>
																	<Col sm='9'>
																		<div className='mb-3'>
																			<Label className='form-label'>{this.props.t('Smart Integrity Platform - Reporting Channel Intro Text')}</Label>
																			<p className='text-muted mb-3'>
																				{this.props.t(
																					'This will appear on your landing page. Provide any information you would like for internal and external parties to understand about reporting on the Smart Integrity Platform.',
																				)}
																			</p>
																		</div>
																	</Col>
																	{this.state.introOtherTranslations.length ? (
																		<Col style={{ textAlign: 'right' }}>
																			<Button
																				color='secondary'
																				type='button'
																				disabled={this.state.displayTranslations}
																				onClick={() =>
																					this.setState({
																						displayTranslations: true,
																					})
																				}
																			>
																				{this.props.t('Add new translation')}
																			</Button>
																		</Col>
																	) : (
																		''
																	)}

																	{getFeatureStatusByTitle(this.props.Feature, 'title_channel_report_tp') || (
																		<div className='mb-4'>
																			<Row>
																				<Col md='12'>
																					<div className='mb-3'>
																						<Row className='align-items-end'>
																							<Col md='12'>
																								<div>
																									<Label className='form-label' htmlFor='title_channel_report_tp'>
																										{this.props.t('Page Title')}
																									</Label>
																									<AvField
																										name='title_channel_report_tp'
																										type='text'
																										errorMessage={this.props.t('This field cannot be blank')}
																										className='form-control'
																										validate={{
																											required: {
																												value: false,
																											},
																										}}
																										value={this.props.organization.title_channel_report_tp || ''}
																										id='title_channel_report_tp'
																									/>
																								</div>
																							</Col>
																						</Row>
																					</div>
																				</Col>
																			</Row>
																		</div>
																	)}
																	{/* 
																		<Col sm="12">
																			<div className="mb-3">
																				<Editor toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" editorState={this.state.editorState} onEditorStateChange={(e) => this.onEditorStateChange(e)} />
																			</div>
																		</Col> 
																	*/}
																	<Col sm='12'>
																		<div className='mb-3'>
																			<List
																				type='unstyled'
																				style={{
																					padding: 20,
																					margin: '20px 0',
																					borderRadius: 10,
																					border: '1px solid #eee',
																				}}
																			>
																				{this.state.introDefaultLanguage.map((item, index) => (
																					<li
																						key={index}
																						style={{
																							paddingBottom: 10,
																							marginBottom: 10,
																						}}
																					>
																						<Row className='align-items-center'>
																							<Col md={2}>
																								<Label className='form-label d-flex align-items-center'>
																									<span>
																										<span
																											style={{
																												display: 'block',
																											}}
																										>
																											{this.props.t('Default language')}:
																										</span>
																										<span>{this.props.t(item.label)}:</span>
																									</span>
																								</Label>
																							</Col>
																							<Col md={10}>
																								<div className='mt-3 mb-4'>
																									<Label className='form-label'>{this.props.t('Page Title')}</Label>
																									<AvField
																										name='pageTitles[]'
																										type='text'
																										errorMessage={this.props.t('This field cannot be blank')}
																										className='form-control'
																										validate={{
																											required: {
																												value: false,
																											},
																										}}
																										value={
																											this.state.pageTitles.filter((x) => x.lang === item.value)[0] &&
																											this.state.pageTitles.filter((x) => x.lang === item.value)[0].text
																										}
																										onChange={(e) => this.onChangePageTitle(e, item.value, item.module, item.moduleId)}
																									/>
																								</div>
																								<div className='mb-3'>
																									<Label className='form-label'>{this.props.t('Intro Text')}</Label>
																									<Editor
																										toolbarClassName='toolbarClassName'
																										wrapperClassName='wrapperClassName'
																										editorClassName='editorClassName'
																										editorState={
																											this.state.editorStateTranslations.filter((x) => x.lang === item.value)[0] &&
																											this.state.editorStateTranslations.filter((x) => x.lang === item.value)[0].text
																										}
																										onEditorStateChange={(e) => this.onEditorStateTranslations(e, item.value, item.module, item.moduleId)}
																									/>
																								</div>
																							</Col>
																						</Row>
																					</li>
																				))}
																			</List>

																			{/* introDefaultLanguage */}

																			<List
																				type='unstyled'
																				style={{
																					padding: 20,
																					margin: '20px 0',
																					borderRadius: 10,
																					border: '1px solid #eee',
																				}}
																				className={!this.state.displayTranslations && 'd-none'}
																			>
																				{this.state.introOtherTranslations.map((item, index) => (
																					<li
																						key={index}
																						style={{
																							borderBottom: '1px solid #eee',
																							paddingBottom: 10,
																							marginBottom: 10,
																						}}
																					>
																						<Row className='align-items-center'>
																							<Col md={2}>
																								<Label className='form-label d-flex align-items-center'>{this.props.t(item.label)}:</Label>
																							</Col>
																							<Col md={10}>
																								<div className='mt-3 mb-4'>
																									<Label className='form-label'>{this.props.t('Page Title')}</Label>
																									<AvField
																										name='pageTitles[]'
																										type='text'
																										errorMessage={this.props.t('This field cannot be blank')}
																										className='form-control'
																										validate={{
																											required: {
																												value: false,
																											},
																										}}
																										value={
																											this.state.pageTitles.filter((x) => x.lang === item.value)[0] &&
																											this.state.pageTitles.filter((x) => x.lang === item.value)[0].text
																										}
																										onChange={(e) => this.onChangePageTitle(e, item.value, item.module, item.moduleId)}
																									/>
																								</div>
																								<div className='mb-3'>
																									<Label className='form-label'>{this.props.t('Intro Text')}</Label>
																									<Editor
																										toolbarClassName='toolbarClassName'
																										wrapperClassName='wrapperClassName'
																										editorClassName='editorClassName'
																										editorState={
																											this.state.editorStateTranslations.filter((x) => x.lang === item.value)[0] &&
																											this.state.editorStateTranslations.filter((x) => x.lang === item.value)[0].text
																										}
																										onEditorStateChange={(e) => this.onEditorStateTranslations(e, item.value, item.module, item.moduleId)}
																									/>
																								</div>
																							</Col>
																						</Row>
																					</li>
																				))}
																			</List>
																		</div>
																	</Col>
																</Row>
															</div>
														)}
														<Row>
															<Col sm='12' className='mt-3'>
																<Button color='primary' type='submit' disabled={this.state.spinner.id === 'submit-button' && this.state.spinner.active}>
																	<span>{this.props.t(this.state.spinner.id === 'submit-button' && this.state.spinner.active ? 'Saving' : 'Save')}</span>
																	{this.state.spinner.id === 'submit-button' && this.state.spinner.active && (
																		<Spinner
																			size='sm'
																			color='light'
																			style={{
																				top: 1,
																				position: 'relative',
																				marginLeft: 20,
																			}}
																		/>
																	)}
																</Button>
															</Col>
														</Row>
													</div>
												</TabPane>
												{getFeatureStatusByTitle(this.props.Feature, 'styling') && (
													<TabPane tabId='2' className='pt-4 pb-4'>
														<Row>
															<Col lg={6}>
																<div>
																	<div className='mb-3'>
																		<CardTitle>{this.props.t('Typography')}</CardTitle>
																	</div>
																</div>
															</Col>
															<Col lg={6}>
																<div>
																	<div className='mb-3'>
																		<CardTitle>{this.props.t('Color Scheme')}</CardTitle>
																	</div>
																</div>
															</Col>
														</Row>
													</TabPane>
												)}
											</TabContent>
										</CardBody>
									</Card>
								</AvForm>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	async initEditor() {
		const translations = this.props.organization.translations.filter((t) => t.module === this.context.selectedModule);

		const titles = [];
		const intros = [];

		translations.map(async (item) => {
			let intro = htmlToDraft(item.intro);
			let title = item.title;

			if (title) {
				titles.push({
					text: title,
					lang: item.lang,
					module: item.module,
					moduleId: item.moduleId,
				});
			}

			if (intro) {
				intros.push({
					text: EditorState.createWithContent(ContentState.createFromBlockArray(intro.contentBlocks)),
					lang: item.lang,
					module: item.module,
					moduleId: item.moduleId,
				});
			}
		});

		if (titles.length || intros.length) {
			this.setState({
				pageTitles: titles,
				editorStateTranslations: intros,
			});
		}
	}

	componentDidMount() {
		this.initEditor();

		let defaultLanguage = '';
		let introDefaultLanguage = [];
		let introOtherTranslations = [];

		if (this.props.organization && this.props.organization.languages && this.props.organization.languages.length) {
			// eslint-disable-next-line array-callback-return
			this.props.organization.languages.map((item) => {
				if (item.is_default) {
					defaultLanguage = item.name;

					introDefaultLanguage.push({
						value: item.language_code,
						label: item.name,
					});
				} else {
					introOtherTranslations.push({
						value: item.language_code,
						label: item.name,
					});
				}
			});
		}

		this.setState({
			defaultLanguage,
			introDefaultLanguage,
			introOtherTranslations,
		});
	}
}

const mapStatetoProps = (state) => {
	const { token } = state.Login;
	const organization = state.Organization;
	const App = state.App;
	const { Feature } = state;
	return { organization, token, App, Feature };
};

const mapDispachToProps = (dispach) => {
	return {
		errorNotification: (message) => dispach(errorNotification(message)),
		successNotification: (message) => dispach(successNotification(message)),
		handleEditInfoOrganizationSuccess: (val) => dispach(onEditInfoOrganizationSuccess(val)),
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispachToProps)(Customization)));
