import React, { useState } from "react";
import BottyAI from "src/modules/data-protection/assets/images/bottyAi.png";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const BottyPopup = ({ t, showBottyPopup }) => {
  const [isOpen, setIsOpen] = useState(showBottyPopup);

  // Function to toggle the visibility of the popup
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Popup content */}
      {isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={togglePopup}
          // className={this.props.className}
        >
          <ModalHeader toggle={togglePopup}>Modal title</ModalHeader>
          <ModalBody>
            <h5>{t("Hi, I'm Botty. The AI Assistant from DISS-CO.")}</h5>
            <img src={BottyAI} width={"15%"} height={"15%"} alt="Botty" />
            <p>
              {t(
                "The creation process may take a few minutes, so please wait..."
              )}
            </p>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default BottyPopup;
