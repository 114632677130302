import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import { changeWorkspace } from '../../../store/workspace/actions';
import { markNotificationAsRead } from '../../../store/notification/actions';
import { onGetInfoOrganizationSuccess } from '../../../store/organization/actions';
import {
	successNotification,
	errorNotification,
	loginUserSuccessful,
	apiError,
	appLoadStatusChanged,
	changePreloader,
	fetchNotificationList,
} from '../../../store/actions';
import { INTERNATIONAL_DATE_TIME_FORMAT, API_URL_NOTIFICATION_MARK_READ, CM_API_URL_NOTIFICATION_LIST, GDPR_URL_NOTIFICATION_LIST, GDPR_API_URL_NOTIFICATION_MARK_ALL_READ } from '../../../common/constants';
import {
	API_URL_NOTIFICATION_LIST,
	API_URL_GET_USER_DETAIL,
	API_URL_VERIFY_ORGANIZATION,
	API_URL_NOTIFICATION_MARK_ALL_READ,
	NOTIFICATIONS_DELETED_FAILED,
	NOTIFICATIONS_DELETED_SUCCESSFULLY,
} from '../../../common/constants';
import { TbLoader } from 'react-icons/tb';
import axios from 'axios';
import SimpleBar from 'simplebar-react';
import UrlUtils from '../../../services/utils/UrlUtils';
import UserUtils from '../../../services/utils/UserUtils';
import DateUtils from '../../../services/utils/DateUtils';
import './NotificationDropdown.scss';

class NotificationDropdown extends Component {
	constructor(props) {
		super(props);

		this.urlUtils = new UrlUtils();
		this.userUtils = new UserUtils();
		this.dateUtils = new DateUtils();
		this.module = localStorage.getItem('module');

		this.state = {
			fetchNotificationsInterval: null,
			notificationPageIndex: 1,
			notifications: [],
			clearNotificationsMotion: false,
			readedNotifications: [],
			notificationsTotalCount: null,
			fetchOperationsAreActive: false,

			menu: false,
			caseId: '',
			isLoading: false,
			headers: {
				headers: {
					Authorization: `Bearer ${this.props.token}`,
				},
			},

			url: this.urlUtils.getUrlParam(),

			orgId: this.urlUtils.getOrganizationId(),
			orgName: this.urlUtils.getOrganizationName(),
			orgUrl: this.urlUtils.getUrlParam(),

			pageIndex: this.props.notifications?.pageIndex || 1,
			itemsCount: this.props.notifications?.itemsCount || 0,
			// notifications: this.props.notifications.notifications || [],
			notifRef: null,
		};

		this.apiError = this.props.apiError.bind(this);
		this.changePreloader = this.props.changePreloader.bind(this);
		this.changeWorkspace = this.props.changeWorkspace.bind(this);
		this.loginUserSuccessful = this.props.loginUserSuccessful.bind(this);
		this.appLoadStatusChanged = this.props.appLoadStatusChanged.bind(this);
		this.markNotificationAsRead = this.props.markNotificationAsRead.bind(this);
		this.handleGetInfoOrganizationSuccess = this.props.handleGetInfoOrganizationSuccess.bind(this);
		this.fetchNotificationList = this.props.fetchNotificationList.bind(this);

		this.handleFetchUserData = this.handleFetchUserData.bind(this);
		this.handleFetchOrganizationData = this.handleFetchOrganizationData.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handleTranslateOnType = this.handleTranslateOnType.bind(this);
		this.handleRenderNotification = this.handleRenderNotification.bind(this);
		this.handleFetchNotifications = this.handleFetchNotifications.bind(this);
		this.handleLoadMoreNotifications = this.handleLoadMoreNotifications.bind(this);
		this.handleClearNotifications = this.handleClearNotifications.bind(this);
		this.handleReadNotification = this.handleReadNotification.bind(this);
	}

	handleFetchUserData = async (id, token) => {
		try {
			const response = await axios.post(
				API_URL_GET_USER_DETAIL,
				{
					organizationId: id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (response.status === 200) {
				if (response.data.data.settings?.direction) {
					localStorage.setItem('lang_dir', response.data.data.settings.direction);
				}
				this.loginUserSuccessful({ ...response.data.data });
				this.changeWorkspace(this.userUtils.getWorkspace(response.data.data));
				this.appLoadStatusChanged(true);
				this.apiError(null);
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error("this error only appears in the development environment:\nerror while fetching user's data:", error);
			}
		}
	};

	handleFetchOrganizationData = async (id, url, path, token) => {
		try {
			const response = await axios.post(
				API_URL_VERIFY_ORGANIZATION,
				{
					id: String(id),
					url,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			const org = {
				id,
				name: response.data.data.name,
				title_channel_report_tp: response.data.data.title_channel_report_tp,
				logo: response.data.data.logo,
				intro: response.data.data.intro,

				translations: response.data.data.translations,
				introTranslations: response.data.data.translations,
				isDemo: !!response.data.data.is_demo,
				isChat: response.data.data.setting ? !!response.data.data.setting.chat : false,
				isMeeting: response.data.data.setting ? !!response.data.data.setting.meeting : false,
				isAnonymously: response.data.data.setting ? !!response.data.data.setting.anonymously : false,
				isConfidentially: response.data.data.setting ? !!response.data.data.setting.confidentially : false,
				isElearning: response.data.data.setting ? !!response.data.data.setting.elearning : false,
				status: response.data.data.status,

				primaryFont: response.data.data.font_family,
				primaryColor: response.data.data.primary_color,

				primaryLogo: response.data.data.primary_logo,
				primaryLogoSize: response.data.data.primary_logo_size,
				secondaryLogo: response.data.data.secondary_logo,
				secondaryLogoSize: response.data.data.secondary_logo_size,

				languages: response.data.data.languages,

				path: path,
				url: response.data.data.orgUrl,

				modules: response.data.data.modules,
			};

			this.handleGetInfoOrganizationSuccess(org);
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error("this error only appears in the development environment:\nerror while fetching organization's data:", error);
			}

			if (error.response) {
				this.props.errorNotification({
					code: error.response.data.err || 'unknown_error',
					message: this.props.t(error.response.data.message || error.response.data.title),
				});
			}
		}
	};

	handleToggle() {
		const state = {
			menu: !this.state.menu,
		};

		if (this.state.menu) {
			state['notifications'] = this.state.notifications.map((n) => {
				return {
					...n,
					hasMotion: false,
				};
			});
		}

		this.setState(state);
	}

	handleTranslateOnType(notification) {
		const notificationType = notification.message_type;
		const secondaryTaskId = notification.data?.secondaryTaskId;
		const secondaryCaseId = notification.data?.secondaryId;
		const secondaryRiskId = notification.data?.secondaryRiskId;
		const riskRate = notification.data?.riskRate;
		const isIncident =notification.data?.questionnaire === "data_breach_incident" ? true : null ;
		const isRequest =notification.data?.questionnaire === "data_subject_request" ? true : null ;
		const isSoftware =notification.data?.software ? true : null ;
    const SoftwareID =notification.data?.software;
		// if (notification.data?.deadline) {
		// 	const dateTime = new Date(notification.data.deadline.date);
		// 	const date = dateTime.toLocaleDateString();
		// 	return date;
		// }

		switch (notificationType) {
      case "new_chat": {
        return this.props.t("You have received a new live chat request");
      }
      case "new_comment": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {isSoftware ? this.props.t("Your software has a new comment") :
               isRequest  ? this.props.t("Your DSR has a new comment") :
               isIncident ? this.props.t('Your incident has a new comment') :
              this.props.t("Your report has a new comment")}
            </span>

            <span style={{ display: 'block' }}>
                    {isRequest ? this.props.t('Request ID') :
                     isIncident ? this.props.t('Incident ID') :
                     isSoftware ? this.props.t('Software ID') :
                     this.props.t('Report ID')
                    }: {isSoftware ? SoftwareID : secondaryCaseId }
                </span>
          </Fragment>
        );
      }
      case "new_internal_comment": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
            {isSoftware ? this.props.t("Your software has a new internal comment") :
               isRequest  ? this.props.t("Your DSR has a new internal comment") :
               isIncident ? this.props.t('Your incident has a new internal comment') :
              this.props.t("Your report has a new internal comment")}
            </span>

                <span style={{ display: 'block' }}>
                    {isRequest ? this.props.t('Request ID') :
                     isIncident ? this.props.t('Incident ID') :
                     isSoftware ? this.props.t('Software ID') :
                     this.props.t('Report ID')
                    }: {isSoftware ? SoftwareID : secondaryCaseId }
                </span>
          </Fragment>
        );
      }
      // return this.props.t('Your case has a new internal comment') + (secondaryCaseId ? '\n' + this.props.t('Case ID') + ': ' + secondaryCaseId : '');
      case "open": {
        return  <>{isSoftware ? this.props.t("Your software has been received and read") :
        isRequest  ? this.props.t("Your DSR has been received and read") :
        isIncident ? this.props.t("Your incident has been received and read") :
       this.props.t("Your report has been received and read")}</>
      }
      case "in_progress": {
        return (
          <Fragment>
              {isSoftware ? this.props.t("Your software status changed to In Progress") :
               isRequest  ? this.props.t("Your DSR status changed to In Progress") :
               isIncident ? this.props.t("Your incident status changed to In Progress") :
              this.props.t("Your report status changed to In Progress")}
          </Fragment>
        );
      }
      
      case "closed": {
        return (
          <Fragment>
              {isSoftware ? this.props.t("Your software status changed to Closed") :
               isRequest  ? this.props.t("Your DSR status changed to Closed") :
               isIncident ? this.props.t("Your incident status changed to Closed") :
              this.props.t("Your report status changed to Closed")}
          </Fragment>
        );
      }
      case "deleted": {
        return (
          <Fragment>
              {isSoftware ? this.props.t("Your software has been deleted") :
               isRequest  ? this.props.t("Your DSR has been deleted") :
               isIncident ? this.props.t("Your incident has been deleted") :
              this.props.t("Your report has been deleted")}
          </Fragment>
        );
      }
      case "pending_delete": {
        return (
          <Fragment>
              {isSoftware ? this.props.t("Your software has been deleted") :
               isRequest  ? this.props.t("Your DSR has been deleted") :
               isIncident ? this.props.t("Your incident has been deleted") :
              this.props.t("Your report has been deleted")}
          </Fragment>
        );
      }
      case "new": {
        // return this.props.t('Your request or report has been submitted.') + (secondaryCaseId ? '\n' + this.props.t('ID') + ': ' + secondaryCaseId : '');
        return (
          <Fragment>
            <span style={{ display: "block" }}>
            {isSoftware ? this.props.t("Your software has been submitted") :
               isRequest  ? this.props.t("Your DSR has been submitted") :
               isIncident ? this.props.t("Your incident has been submitted") :
              this.props.t("Your request or report has been submitted.")}
            </span>
            {isSoftware ? SoftwareID : secondaryCaseId !==undefined 
              ? "\n" + this.props.t("ID") + ": " + secondaryCaseId
              : ""}
          </Fragment>
        );
      }
      case "new_case_reported": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {isSoftware ? this.props.t("You have received a new software ") :
               isRequest  ? this.props.t("You have received a new DSR") :
               isIncident ? this.props.t("You have received a new incident") :
              this.props.t("You have received a new report")}
            </span>
            <span style={{ display: 'block' }}>
                    {isRequest ? this.props.t('Request ID') :
                     isIncident ? this.props.t('Incident ID') :
                     isSoftware ? this.props.t('Software ID') :
                     this.props.t('Report ID')
                    }: {isSoftware ? SoftwareID : secondaryCaseId }
                </span>
          </Fragment>
        );
      }
      case "assigned": {
        // return this.props.t('You have a new case') + (secondaryCaseId ? '\n' + this.props.t('Case ID') + ': ' + secondaryCaseId : '');
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {isSoftware ? this.props.t("You have a new software ") :
               isRequest  ? this.props.t("You have a new DSR") :
               isIncident ? this.props.t("You have a new incident") :
              this.props.t("You have a new report")}
            </span>
          </Fragment>
        );
      }
      case "new_case_assigned": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {isSoftware ? this.props.t("A software was assigned to you ") :
               isRequest  ? this.props.t("A DSR was assigned to you") :
               isIncident ? this.props.t("An incident was assigned to you") :
              this.props.t("A report was assigned to you")}
            </span>
            <span style={{ display: 'block' }}>
                    {isRequest ? this.props.t('Request ID') :
                     isIncident ? this.props.t('Incident ID') :
                     isSoftware ? this.props.t('Software ID') :
                     this.props.t('Report ID')
                    }: {isSoftware ? SoftwareID : secondaryCaseId }
                </span>
          </Fragment>
        );
      }
      case "new_attachment": {
        return (
          <Fragment>
              {isSoftware ? this.props.t("You have received an update on your software ") :
               isRequest  ? this.props.t("You have received an update on your DSR") :
               isIncident ? this.props.t("You have received an update on your incident") :
              this.props.t("You have received an update on your report")}
          </Fragment>
        );
      }
      case "case_had_been_read": {
        return (
        <Fragment>
        {isSoftware ? this.props.t("Your software has been read ") :
         isRequest  ? this.props.t("Your DSR has been read") :
         isIncident ? this.props.t("Your incident has been read") :
        this.props.t("Your report has been read")}
       </Fragment>
      );
      }
      case "case_in_progress": {
        return (
          <Fragment>
          {isSoftware ? this.props.t("An Analyst is working on your software") :
           isRequest  ? this.props.t("An Analyst is working on your DSR") :
           isIncident ? this.props.t("An Analyst is working on your incident") :
          this.props.t("An Analyst is working on your report")}
         </Fragment>
        );
      }
      case "case_had_been_closed": {
        return (
          <Fragment>
          {isSoftware ? this.props.t("Your software has been closed") :
           isRequest  ? this.props.t("Your DSR has been closed") :
           isIncident ? this.props.t("Your incident has been closed") :
          this.props.t("Your report has been closed")}
         </Fragment>
        );
      }
      case "sip_system_update": {
        return "Smart Integrity Platform " + notification?.data?.caseId
          ? this.props.t("Case update")
          : this.props.t("System update");
      }
      case "risk_assign": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("A new risk has been assigned to you.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryRiskId}
            </span>
          </Fragment>
        );
      }
      case "risk_comment": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("A comment was added to your risk.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryRiskId}
            </span>
          </Fragment>
        );
      }
      case "new_task_created": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("A new task has been assigned to you.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
          </Fragment>
        );
      }
      case "task_status_new": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The status of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New status")}: {this.props.t("New")}
            </span>
          </Fragment>
        );
      }
      case "task_status_in_progress": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The status of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New status")}: {this.props.t("In Progress")}
            </span>
          </Fragment>
        );
      }
      case "task_status_review": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The status of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New status")}: {this.props.t("Review")}
            </span>
          </Fragment>
        );
      }
      case "task_status_delete": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The status of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New status")}: {this.props.t("Deleted")}
            </span>
          </Fragment>
        );
      }
      case "task_status_close": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The status of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New status")}: {this.props.t("Closed")}
            </span>
          </Fragment>
        );
      }
      case "task_priority_low": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The priority of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New priority")}: {this.props.t("Low")}
            </span>
          </Fragment>
        );
      }
      case "task_priority_medium":
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The priority of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New priority")}: {this.props.t("Medium")}
            </span>
          </Fragment>
        );
      case "task_priority_high":
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The priority of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New priority")}: {this.props.t("High")}
            </span>
          </Fragment>
        );
      case "task_comment":
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("A comment was added to your task")}.
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
          </Fragment>
        );
      case "task_before_overdue": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The deadline for the task is soon.")}
            </span>
            <span style={{ display: "block" }}>
              {notification?.data?.secondaryTaskId
                ? "\n" +
                  this.props.t("Task ID") +
                  ": " +
                  notification.data.secondaryTaskId
                : notification?.data?.taskId
                ? "\n" +
                  this.props.t("Task ID") +
                  ": " +
                  notification?.data?.taskId
                : ""}
            </span>
          </Fragment>
        );
      }
      case "new_task": {
        return (
          <span style={{ display: "block" }}>
            {this.props.t("You created a new task")}.
          </span>
        );
      }
      case "task_change_deadline": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("Deadline of your task was changed")}.
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
          </Fragment>
        );
      }
      case "task_change_description": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The description of your task has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("ID")}: {secondaryTaskId}
            </span>
          </Fragment>
        );
      }
      case "task_overdue": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The deadline for your task is today.")}
            </span>
            <span style={{ display: "block" }}>
              {notification?.data?.secondaryTaskId
                ? "\n" +
                  this.props.t("Task ID") +
                  ": " +
                  notification.data.secondaryTaskId
                : notification?.data?.taskId
                ? "\n" +
                  this.props.t("Task ID") +
                  ": " +
                  notification?.data?.taskId
                : ""}
            </span>
          </Fragment>
        );
      }
      case "risk_rating": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("The rating of your risk has been changed.")}
            </span>
            <span style={{ display: "block" }}>
              {this.props.t("New rating")}: {riskRate}
            </span>
          </Fragment>
        );
      }
      case "risk_change_description": {
        return (
          <span style={{ display: "block" }}>
            {this.props.t("Description of your risk was changed")}.
          </span>
        );
      }
      // case 'pending_delete':
      // 	return this.props.t('Pending Deletion');
      case "status_update": {
        return this.props.t(
          "The status of your case has been updated: {{status}}"
        );
      }
      // case 'new_chat':
      // 	return this.props.t('New Live Chat request');
      // case 'new_chat':
      // 	return this.props.t('New Live Chat request');
      case "readonly_access": {
        return (
          <Fragment>
            {isSoftware ? this.props.t("You have read-only access to a software.") :
           isRequest  ? this.props.t("You have read-only access to a DSR.") :
           isIncident ? this.props.t("You have read-only access to an incident.") :
          this.props.t("You have read-only access to a case.")}
           +
          <span style={{ display: 'block' }}>
          {
           this.props.t('ID')
          }: {isSoftware ? SoftwareID : secondaryCaseId }
      </span>
      </Fragment> );
      }
      case "comment_mention": {
        return (
          <Fragment>
            <span style={{ display: "block" }}>
              {this.props.t("You have been mentioned in a comment")}.
            </span>
            {isRequest ? this.props.t('Request ID') :
           isIncident ? this.props.t('Incident ID') :
           isSoftware ? this.props.t('Software ID') :
           this.props.t('Report ID')
          }: {isSoftware ? SoftwareID : secondaryCaseId }
          </Fragment>
        );
      }
      case "task_owner_role_removed": {
        return (
          <span style={{ display: "block" }}>
            {this.props.t("Your task owner role has been removed by the admin")}
            .
          </span>
        );
      }
      case "task_owner_role_added": {
        return (
          <span style={{ display: "block" }}>
            {this.props.t("You have been assigned to the Task Owner role")}.
          </span>
        );
      }
	  case "high_dpia_risk_created": {
        return (
			<>
			<span style={{ display: "block" }}>
			{this.props.t("The risk assessment indicates a high risk level. Please proceed to complete the Data Protection Impact Assessment")}.
		  </span>
		  {SoftwareID ? 
			  <span style={{ display: 'block' }}>
				  {this.props.t('Software ID') 
				  }: {SoftwareID}
			  </span>
			  : ''
		  }
		  </>
        );
      }
      default: {
        return this.props.t(notificationType);
      }
    }
	}

	handleRenderNotification = (notification, user, organization, index) => {
		const existOrganizationURL = organization && organization.url;
		let link = '#';
		if (typeof notification.id !== "undefined") {
      if (this.props.Workspace==="third_party_workspace") {
        switch (notification.message_type) {
          case "new_chat": {
            link = "livechat";
            break;
          }
          case "new_task_created":
          case "task_status_new":
          case "task_status_in_progress":
          case "task_status_review":
          case "task_status_close":
          case "task_status_delete":
          case "task_priority_low":
          case "task_priority_medium":
          case "task_priority_high":
          case "task_comment":
          case "task_overdue":
          case "task_change_deadline":
          case "task_change_description":
          case "task_before_overdue": {
            if (this.userUtils.isTaskOwner(this.props.user)) {
              link = `tasks/${notification.data.taskId}`;
            } else {
              link = `report/task/${notification.data.taskId}`;
            }
            break;
          }
          case "risk_assign":
          case "risk_comment":
          case "risk_rating":
          case "risk_change_description": {
            link = `report/risk/${notification.data.riskId}`;
            break;
          }
          default: {
            link = `report/detail`;
            break;
          }
        }
      } else {
        switch (notification.message_type) {
          case "new_chat": {
            link = "livechat";
            break;
          }
          case "task_owner_role_removed":
          case "task_owner_role_added":
          case "new_task_created":
          case "task_status_new":
          case "task_status_in_progress":
          case "task_status_review":
          case "task_status_close":
          case "task_status_delete":
          case "task_priority_low":
          case "task_priority_medium":
          case "task_priority_high":
          case "task_comment":
          case "task_overdue":
          case "task_change_deadline":
          case "task_change_description":
          case "task_before_overdue": {
            if (this.userUtils.isTaskOwner(this.props.user)) {
              link = `tasks/${notification.data.taskId}`;
            }
            //data_subject_request
            if (notification.data?.questionnaire === "data_subject_request") {
              if (notification.data?.taskId) {
                link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=task-management&taskId=${notification.data?.taskId}`;
              } else {
                link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=task-management`;
              }
            }
            //data_breach_incident
            else if (
              notification.data?.questionnaire === "data_breach_incident"
            ) {
              if (notification.data?.taskId) {
                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=task-management&taskId=${notification.data?.taskId}`;
              } else {
                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=task-management`;
              }
            }
            //software
            else if (notification.data?.software) {
              if (notification.data?.taskId) {
                link = `data-protection/softwares/${notification.data.software}?tab=task-management&taskId=${notification.data?.taskId}`;
              } else {
                link = `data-protection/softwares/${notification.data.software}?tab=task-management`;
              }
            } else {
              link = `report/task/${notification.data.taskId}`;
            }
            break;
          }
          case "risk_assign": {
            //data_subject_request
            if (notification.data?.questionnaire === "data_breach_incident") {
              if (notification.data?.riskId) {
                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=risk-assessment&riskId=${notification.data?.riskId}`;
              } else {
                link = `data-protection/incident-register/details/${notification.data.caseId}?tab=risk-assessment`;
              }
            }
            //software
            else if (notification.data?.software) {
              if (notification.data?.riskId) {
                link = `data-protection/softwares/${notification.data.software}?tab=risk-assessment&riskId=${notification.data?.riskId}`;
              } else
                link = `data-protection/softwares/${notification.data.software}?tab=risk-assessment`;
            } else {
              link = `report/task/${notification.data.riskId}`;
            }
            break;
          }

          case "risk_comment":
          case "risk_rating":
          case "risk_change_description": {
            //data_subject_request
            if (notification.data?.questionnaire === "data_subject_request") {
              link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=risk-assessment`;
            }
            //data_breach_incident
            else if (
              notification.data?.questionnaire === "data_breach_incident"
            ) {
              link = `data-protection/incident-register/details/${notification.data.caseId}?tab=risk-assessment`;
            }
            //software
            else if (notification.data?.software) {
              link = `data-protection/softwares/${notification.data.software}?tab=risk-assessment`;
            } else {
              link = `report/task/${notification.data.riskId}`;
            }
            break;
          }
          case "new_comment":
          case "comment_mention":
          case "new_internal_comment": {
            //data_subject_request
            if (notification.data?.questionnaire === "data_subject_request") {
              link = `data-protection/data-subject-request/details/${notification.data.caseId}?tab=internal-comments`;
            }
            //data_breach_incident
            else if (
              notification.data?.questionnaire === "data_breach_incident"
            ) {
              link = `data-protection/incident-register/details/${notification.data.caseId}?tab=internal-comments`;
            }
            //software
            else if (notification.data?.software) {
              link = `data-protection/softwares/${notification.data.software}?tab=internal-comments`;
            }
            break;
          }
          case "high_dpia_risk_created": {
            //software
            if (notification.data?.software) {
              link = `data-protection/softwares/${notification.data.software}?tab=dpia`;
            }
            break;
          }

          case "new_attachment":
          case "open":
          case "in_progress":
          case "case_had_been_closed":
          case "closed":
          case "deleted":
          case "pending_delete":
          case "case_had_been_deleted":
          case "new":
          case "new_case_reported":
          case "assigned":
          case "new_case_assigned":
          case "new_contact_request":
          case "new_demo_request":
          case "readonly_access": {
            //data_subject_request
            if (notification.data?.questionnaire === "data_subject_request") {
              link = `data-protection/data-subject-request/details/${notification.data.caseId}`;
            }
            //data_breach_incident
            else if (
              notification.data?.questionnaire === "data_breach_incident"
            ) {
              link = `data-protection/incident-register/details/${notification.data.caseId}`;
            }
            //software
            else if (notification.data?.software) {
              link = `data-protection/softwares/${notification.data.software}`;
            }
            break;
          }
          default: {
            link = `report/detail`;
            break;
          }
        }
      }
    }

		// Check if the notification is new
		const isNewNotification = !this.state.readedNotifications.includes(notification.id);
		// Add a ref to the first new notification element
		if (isNewNotification && index === 0) {
			this.firstNewNotificationRef = React.createRef();
		}

		// console.log(notification?.data?.organizationData);
		// if (notification.message_type === 'task_before_overdue') {
		// 	console.log(existOrganizationURL !== notification?.data?.organizationData?.domainUrl);
		// }

		if (
			user &&
			user.roles &&
			user.roles.includes('ROLE_PARTNER') &&
			notification &&
			notification.data &&
			notification.data.organizationData &&
			existOrganizationURL !== notification.data.organizationData.domainUrl
		) {
			let organizationID = notification.data.organizationData.id;
			let organizationURL = notification.data.organizationData.domainUrl;
			let organizationName = notification.data.organizationData.name;
			let organizationCode = organizationURL.slice(0, organizationURL.indexOf('/'));

			return (
				<div
					onClick={() => {
						this.handleReadNotification(notification.id, this.props.token);

						localStorage.setItem('taskId', notification.data.taskId ? notification.data.taskId : '');
						localStorage.setItem('riskId', notification.data.riskId ? notification.data.riskId : '');
						localStorage.setItem('caseId', notification.data.caseId);

						if (notification.data.secondaryId) localStorage.setItem('secondaryCaseId', notification.data.secondaryId);

						this.setState(
							{
								orgId: organizationID,
								orgUrl: organizationURL,
								orgName: organizationName,
							},
							() => {
								this.handleFetchUserData(organizationID).then(() => {
									localStorage.setItem('org', this.urlUtils.getAppRootPath(organizationID, organizationName.replace(/\s+/g, '-').toLowerCase()));
									this.handleFetchOrganizationData(organizationID, organizationURL, organizationCode, organizationName, link);
								});
							},
						);
					}}
					className='text-reset notification-item'
					ref={isNewNotification && index === 0 ? this.firstNewNotificationRef : null}
				>
					<div className='d-flex'>
						<div className='avatar-xs me-3'>
							<span className='avatar-title bg-info rounded-circle font-size-16'>
								<i className='ri-notification-3-line'></i>
							</span>
						</div>
						<div className='flex-1'>
							<h6 className='mt-0 mb-1'>
								Smart Integrity Platform {notification?.data?.caseId ? this.props.t('Case update') : this.props.t('System update')}:
							</h6>
							<div className='font-size-12 text-muted'>
								<p className='mb-1'>
									{this.handleTranslateOnType(notification)} {this.props.t('from')} {organizationName}
								</p>
								<p className='mb-0'>
									<i className='mdi mdi-clock-outline'></i>
									{' ' + this.dateUtils.convertTimeStampToDate(notification.created_at, INTERNATIONAL_DATE_TIME_FORMAT)}
								</p>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			// console.log(notification.message_type);
			return (
				<Link
					onClick={() => {
						this.handleReadNotification(notification.id, this.props.token);

						localStorage.setItem('taskId', notification.data.taskId ? notification.data.taskId : '');
						localStorage.setItem('riskId', notification.data.riskId ? notification.data.riskId : '');
						localStorage.setItem('caseId', notification.data.caseId);

						if (notification.data.secondaryId) {
							localStorage.setItem('secondaryCaseId', notification.data.secondaryId);
						}
					}}
					to={`/${window.location.pathname.split('/', 4)[3]}/${link}`}
					className='text-reset notification-item'
					ref={isNewNotification && index === 0 ? this.firstNewNotificationRef : null}
				>
					<div className='d-flex'>
						<div className='avatar-xs me-3'>
							<span className='avatar-title bg-info rounded-circle font-size-16'>
								<i className='ri-notification-3-line'></i>
							</span>
						</div>
						<div className='flex-1'>
							<h6 className='mt-0 mb-1'>
								Smart Integrity Platform {notification?.data?.caseId ? this.props.t('Case update') : this.props.t('System update')}:
							</h6>
							<div className='font-size-12 text-muted'>
								<p className='mb-1'>{this.handleTranslateOnType(notification)}</p>
								<p className='mb-0'>
									<i className='mdi mdi-clock-outline'></i>
									{' ' + this.dateUtils.convertTimeStampToDate(notification.created_at, INTERNATIONAL_DATE_TIME_FORMAT)}
								</p>
							</div>
						</div>
					</div>
				</Link>
			);
		}
	};

	handleFetchNotifications = async (pageIndex = 1, token, hasMotion) => {
		this.setState({ isLoading: true });

		try {
			const response = await axios.get( window.location.href.includes("data-protection") ? GDPR_URL_NOTIFICATION_LIST : this.module === 'cm' ? CM_API_URL_NOTIFICATION_LIST  : API_URL_NOTIFICATION_LIST , {
				params: {
					pageIndex: pageIndex,
				},
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (
				response.status === 200 &&
				response.data?.data?.notifications &&
				Array.isArray(response.data.data.notifications) &&
				response.data.data.notifications.length
			) {
				const currentNotificationIDs = this.state.notifications.map((n) => n.id);
				const notification = response.data.data.notifications.filter((n) => !currentNotificationIDs.includes(n.id));

				this.setState((prevState) => ({
					notificationPageIndex: pageIndex,
					notifications: notification
						.map((n) => {
							return {
								...n,
								hasMotion,
							};
						})
						.concat(prevState.notifications),
					notificationsTotalCount:
						Number(response.data.data.itemsCount) !== this.state.notificationsTotalCount
							? Number(response.data.data.itemsCount) + this.state.readedNotifications.length
							: response.data.data.itemsCount,
				}));
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while fetching notifications:', error);
			}
		}

		this.setState({ isLoading: false });
	};

	handleLoadMoreNotifications = (pageIndex, token) => {
		this.setState(
			{
				notificationPageIndex: pageIndex,
			},
			() => {
				this.handleFetchNotifications(pageIndex, token, true);
				// Scroll to the first new notification element
				if (this.firstNewNotificationRef && this.firstNewNotificationRef.current) {
					this.firstNewNotificationRef.current.scrollIntoView({ behavior: 'smooth' });
				}
			},
		);
	};

	handleClearNotifications = async (token) => {
		try {
			const response = await axios.post(
				window.location.href.includes("data-protection") ? GDPR_API_URL_NOTIFICATION_MARK_ALL_READ : API_URL_NOTIFICATION_MARK_ALL_READ,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (response.status === 200) {
				this.setState(
					{
						clearNotificationsMotion: true,
					},
					() => {
						setTimeout(() => {
							this.setState(
								(prevState) => ({
									menu: !prevState.menu,
								}),
								() => {
									this.setState({
										readedNotifications: [],
										notifications: [],
										notificationsTotalCount: 0,
									});
								},
							);
						}, 700);
					},
				);
				// this.handleFetchNotifications();
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while clearing notifications:', error);
			}

			this.props.successNotification({
				message: this.props.t('All notifications marked as readed.'),
			});
		}
	};

	handleReadNotification = async (id, token) => {
		try {
			const response = await axios.post(
				API_URL_NOTIFICATION_MARK_READ,
				{
					id: id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (response.status === 200) {
				if (!this.state.readedNotifications.includes(id)) {
					this.setState({
						readedNotifications: [...this.state.readedNotifications, id],
					});
				}
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while marking notification as readed:', error);
			}
		}
	};

	componentDidMount() {
		if (this.props.currentUser) {
			this.setState(
				{
					fetchOperationsAreActive: true,
				},
				() => {
					this.handleFetchNotifications(1, this.props.token, false);

					const fetchNotificationsInterval = setInterval(() => {
						this.handleFetchNotifications(1, this.props.token, true);
					}, 15000);

					this.setState({ fetchNotificationsInterval });
				},
			);
		}
	}

	componentDidUpdate() {
		if (this.props.currentUser && !this.state.fetchOperationsAreActive) {
			this.setState(
				{
					fetchOperationsAreActive: true,
				},
				() => {
					this.handleFetchNotifications(1, this.props.token, false);

					const fetchNotificationsInterval = setInterval(() => {
						this.handleFetchNotifications(1, this.props.token, true);
					}, 15000);

					this.setState({ fetchNotificationsInterval });
				},
			);
		}

		if (!this.props.currentUser && this.state.fetchNotificationsInterval) {
			clearInterval(this.state.fetchNotificationsInterval);
		}
	}

	componentWillUnmount() {
		if (this.state.fetchNotificationsInterval) {
			clearInterval(this.state.fetchNotificationsInterval);
		}
	}

	render() {
		 const notifications = this.state.notifications;

		return (
			<Fragment>
				<Dropdown isOpen={this.state.menu} toggle={this.handleToggle} className='d-inline-block'>
					{/* bell icon */}
					<DropdownToggle tag='button' className='btn header-item noti-icon waves-effect' id='page-header-notifications-dropdown'>
						<i className='ri-notification-3-line'></i>

						{/* notifications total count */}
						{this.state.notificationsTotalCount && this.state.notificationsTotalCount > 0 ? (
							<sup
								style={{
									display: 'inline-block',
									verticalAlign: 'super',
									fontSize: 'medium',
									padding: 0,
									marginLeft: '-0.5rem',
								}}
							>
								<span className='badge rounded-pill bg-danger' style={{ top: -7 }}>
									{this.state.notificationsTotalCount && this.state.readedNotifications.length >= 0
										? this.state.notificationsTotalCount - this.state.readedNotifications.length
										: ''}
								</span>
							</sup>
						) : null}
					</DropdownToggle>

					{/* notifications popup */}
					<DropdownMenu className='dropdown-menu-end dropdown-menu-lg p-0' aria-labelledby='page-header-notifications-dropdown'>
						<div className='p-3'>
							<Row className='align-items-center'>
								{/* title */}
								<Col>
									<h6 className='m-0'>{this.props.t('Notifications')}</h6>
								</Col>

								{/* clear notifications button */}
								{notifications.length ? (
									<Col style={{ textAlign: 'right', paddingRight: 9 }}>
										<button
											type='button'
											style={{
												outline: 0,
												border: 0,
												backgroundColor: 'transparent',
												borderRadius: '50%',
												padding: 12,
											}}
											onClick={() => this.handleClearNotifications(this.props.token)}
											title={this.props.t('Clear all notifications.')}
										>
											<i className='ri-notification-off-line' />
										</button>
									</Col>
								) : null}
							</Row>
						</div>

						{/* list of notifications */}
						<SimpleBar
							style={{
								maxHeight: '230px',
							}}
							scrollableNodeProps={{ ref: this.firstNewNotificationRef }}
						>
							{notifications.map((notification, index) => {
								return (
									<div
										key={notification.id}
										style={{
											animationName: notification.hasMotion ? 'newNotificationAnimation' : '',
											animationDuration: '0.6s',
											animationIterationCount: 1,
											overflow: 'hidden',
											transition: 'opacity 0.3s ease 0s, max-height 0.3s ease 0.3s',
											maxHeight: this.state.readedNotifications.includes(notification.id) ? '0px' : '1000px',
											opacity: this.state.readedNotifications.includes(notification.id) ? '0' : '1',
										}}
									>
										{this.handleRenderNotification(notification, this.props.user, this.props.Organization, index)}
									</div>
								);
							})}
						</SimpleBar>

						{/* load more notifications */}
						{notifications.length ? (
							<div className='p-2 border-top'>
								<button
									type='button'
									className='btn btn-sm btn-link btn-block text-center'
									style={{
										fontSize: '.9em',
										textTransform: 'capitalize',
										textDecoration: 'none',
									}}
									onClick={() => this.handleLoadMoreNotifications(this.state.notificationPageIndex + 1, this.props.token)}
								>
									<i className='ri-add-line' style={{ position: 'relative', top: 3, marginRight: 4 }}></i>
									{this.props.t('View more')}
									{this.state.isLoading ? <TbLoader style={{ marginLeft: '6px' }} /> : null}
								</button>
							</div>
						) : null}
					</DropdownMenu>
				</Dropdown>
			</Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	return {
		user: state.Login.user,
		notifications: state.AppNotification,
		token: state.Login.token,
		Organization: state.Organization,
		App: state.App,
		currentUser: state.Login.user,
		Workspace: state.Workspace.name
	};
};

const mapDispachToProps = (dispatch) => {
	return {
		markNotificationAsRead: (id) => dispatch(markNotificationAsRead(id)),
		apiError: (error) => dispatch(apiError(error)),
		appLoadStatusChanged: (status) => dispatch(appLoadStatusChanged(status)),
		errorNotification: (message) => dispatch(errorNotification(message)),
		successNotification: (message) => dispatch(successNotification(message)),
		fetchNotificationList: () => dispatch(fetchNotificationList()),
		changePreloader: (val) => dispatch(changePreloader(val)),
		changeWorkspace: (workspace) => dispatch(changeWorkspace(workspace)),
		loginUserSuccessful: (user) => dispatch(loginUserSuccessful(user)),
		handleGetInfoOrganizationSuccess: (val) => dispatch(onGetInfoOrganizationSuccess(val)),
	};
};

export default connect(mapStatetoProps, mapDispachToProps)(withNamespaces()(NotificationDropdown));
