import { ACTION_EDIT_INFO_ORGANIZATION_FAILURE, ACTION_GET_INFO_ORGANIZATION_SUCCESS, ACTION_EDIT_INFO_ORGANIZATION_SUCCESS } from './actionTypes';

const initialState = {
	id: '',
	name: '',
	logo: '',
	intro: '',
	status: '',
	isChat: false,
	isDemo: false,
	isMeeting: false,
	isElearning: false,
	isAnonymously: false,
	isConfidentially: false,
	isVoice: false,

	translations: [],
	introTranslations: [],

	primaryFont: null,
	path: null,
	url: null,

	primaryColor: null,
	fontColor: null,
	// secondaryColor: null,

	azureLogin: null,

	primaryLogo: '',
	primaryLogoSize: null,
	secondaryLogo: '',
	secondaryLogoSize: null,

	taskOwnerLicenseCount: null,
	remainingTaskOwnerLicenseCount: null,

	countryId: null,
	customerId:	null,

	languages: [],
};

export const Organization = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_GET_INFO_ORGANIZATION_SUCCESS:
			if (!action || !action.payload) {
				return state;
			}
			return {
				...state,
				id: action.payload.id || state.id,
				logo: action.payload.logo || '',
				intro: action.payload.intro || '',
				name: action.payload.name || '',
				title_channel_report_tp: action.payload.title_channel_report_tp || '',
				isDemo: !!action.payload.isDemo || !!action.payload.is_demo,
				isChat: action.payload.isChat || state.isChat,
				isMeeting: action.payload.isMeeting || state.isMeeting,
				isVoice: action.payload.isVoice || state.isVoice,
				isAnonymously: action.payload.isAnonymously || state.isAnonymously,
				isConfidentially: action.payload.isConfidentially || state.isConfidentially,
				isElearning: action.payload.isElearning || state.isElearning,

				translations: action.payload.translations || state.translations,
				introTranslations: action.payload.introTranslations || state.introTranslations,

				status: action.payload.status,

				primaryFont: action.payload.primaryFont || '',
				path: action.payload.path || '',
				url: action.payload.url || '',

				fontColor: action.payload.fontColor || '',
				primaryColor: action.payload.primaryColor || '',
				// secondaryColor: action.payload.secondaryColor || "",

				azureLogin: action.payload.azureLogin || '',

				primaryLogo: action.payload.primaryLogo || '',
				primaryLogoSize: action.payload.primaryLogoSize || '',
				secondaryLogo: action.payload.secondaryLogo || '',
				secondaryLogoSize: action.payload.secondaryLogoSize || '',

				taskOwnerLicenseCount: action.payload.taskOwnerLicenseCount,
				remainingTaskOwnerLicenseCount: action.payload.remainingTaskOwnerLicenseCount,

				languages: action.payload.languages || state.languages,
				
				modules: action.payload.modules || null,

				countryId: action.payload.countryId,
				
				customerId:	action.payload.customerId
			};
		case ACTION_EDIT_INFO_ORGANIZATION_FAILURE:
			return {
				...state,
				id: action.payload.id || state.id,
				logo: '',
				name: '',
				title_channel_report_tp: action.payload.title_channel_report_tp || state.title_channel_report_tp,
				intro: '',
				isDemo: false,
				isChat: action.payload.isChat || state.isChat,
				isMeeting: action.payload.isMeeting || state.isMeeting,
				isVoice: action.payload.isVoice || state.isVoice,
				isAnonymously: action.payload.isAnonymously || state.isAnonymously,
				isConfidentially: action.payload.isConfidentially || state.isConfidentially,
				isElearning: action.payload.isElearning || state.isElearning,

				translations: action.payload.translations || state.translations,
				introTranslations: action.payload.introTranslations || state.introTranslations,

				status: action.payload.status || '',

				primaryFont: '',
				path: '',
				url: '',

				fontColor: '',
				primaryColor: '',
				// secondaryColor: "",

				azureLogin: '',

				primaryLogo: '',
				primaryLogoSize: '',
				secondaryLogo: '',
				secondaryLogoSize: '',

				languages: action.payload.languages || state.languages,
				
				modules: action.payload.modules || state.modules,
			};
		case ACTION_EDIT_INFO_ORGANIZATION_SUCCESS:
			return {
				...state,
				logo: action.payload.logo ? action.payload.logo : state.logo,
				intro: action.payload.intro,

				translations: action.payload.translations,
				introTranslations: action.payload.introTranslations,

				name: action.payload.name,
				title_channel_report_tp: action.payload.title_channel_report_tp ? action.payload.title_channel_report_tp : state.title_channel_report_tp,

				primaryFont: action.payload.primaryFont ? action.payload.primaryFont : state.primaryFont,
				path: action.payload.path ? action.payload.path : state.path,
				url: action.payload.url ? action.payload.url : state.url,

				fontColor: action.payload.fontColor ? action.payload.fontColor : state.fontColor,
				primaryColor: action.payload.primaryColor ? action.payload.primaryColor : state.primaryColor,
				// secondaryColor: action.payload.secondaryColor ? action.payload.secondaryColor : state.secondaryColor,

				azureLogin: action.payload.azureLogin ? action.payload.azureLogin : state.azureLogin,

				primaryLogo: action.payload.primaryLogo ? action.payload.primaryLogo : state.primaryLogo,
				primaryLogoSize: action.payload.primaryLogoSize ? action.payload.primaryLogoSize : state.primaryLogoSize,
				secondaryLogo: action.payload.secondaryLogo ? action.payload.secondaryLogo : state.secondaryLogo,
				secondaryLogoSize: action.payload.secondaryLogoSize ? action.payload.secondaryLogoSize : state.secondaryLogoSize,

				languages: action.payload.languages,
				
				modules: action.payload.modules,
			};
		default:
			return state;
	}
};

