import { Component, Fragment } from 'react';
import { logoutUser, setCaseId } from '../../store/actions';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableWithPagination from '../../components/Pagination';
import { withRouter } from "react-router-dom";

class SoftwareAssetsList extends Component {
	constructor(props) {
		super(props);

		this.handleProvideTableColumns = this.handleProvideTableColumns.bind(this);
		this.state = {
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: this.props.t('Assets list - Software'), link: '#' },
			],
			softwares: [
				{
					name: 'Website',
					provider_name: 'Google Cloud Platform',
					provider_address: '1600 Amphitheatre Parkway, Mountain View, CA 94043',
					admin: 'John Doe',
					os: 'Linux',
					type: 'IaaS',
					subscription: 'Monthly',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'Database',
					provider_name: 'Amazon Web Services',
					provider_address: '410 Terry Ave N, Seattle, WA 98109',
					admin: 'Jane Doe',
					os: 'Windows',
					type: 'PaaS',
					subscription: 'Yearly',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'Email',
					provider_name: 'Microsoft Azure',
					provider_address: 'One Microsoft Way, Redmond, WA 98052',
					admin: 'Mary Smith',
					os: 'macOS',
					type: 'SaaS',
					subscription: 'Quarterly',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'File storage',
					provider_name: 'Rackspace',
					provider_address: '1211 W. Sixth Street, Suite 1000, Austin, TX 78703',
					admin: 'Peter Jones',
					os: 'FreeBSD',
					type: 'IaaS',
					subscription: 'Monthly',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'Content delivery network',
					provider_name: 'Cloudflare',
					provider_address: '101 Townsend St, San Francisco, CA 94107',
					admin: 'Susan Brown',
					os: 'Linux',
					type: 'CDN',
					subscription: 'Annually',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'Disaster recovery',
					provider_name: 'Zerto',
					provider_address: '1111 E. Hillsdale Blvd, Foster City, CA 94404',
					admin: 'David Green',
					os: 'Windows',
					type: 'DRaaS',
					subscription: 'Monthly',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'Security information and event management (SIEM)',
					provider_name: 'Splunk',
					provider_address: '4000 Bridge Parkway, San Francisco, CA 94103',
					admin: 'Michael White',
					os: 'macOS',
					type: 'SIEM',
					subscription: 'Annually',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'Identity and access management (IAM)',
					provider_name: 'Okta',
					provider_address: '500 Oracle Parkway, Redwood Shores, CA 94065',
					admin: 'Sarah Black',
					os: 'Linux',
					type: 'IAM',
					subscription: 'Quarterly',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
				{
					name: 'Threat intelligence',
					provider_name: 'Recorded Future',
					provider_address: '131 Main Street, Cambridge, MA 02142',
					admin: 'John Doe',
					os: 'Windows',
					type: 'Threat intelligence',
					subscription: 'Monthly',
					notice: 'Data may be transferred outside of the EU',
					hosting_location: 'United States',
					data_lost_location_outside_eu: 'Yes',
				},
			],
		};
	}

	/**
	 * Provides the columns for the reports table.
	 *
	 * @returns {Array<{dataField: string, text: string, sort?: boolean}>} The columns list.
	 */
	handleProvideTableColumns() {
		const columns = [
			{
				dataField: 'name',
				text: this.props.t('Name'),
				sort: false,
			},
			{
				dataField: 'provider_name',
				text: this.props.t('Provider Name'),
				sort: true,
			},
			{
				dataField: 'provider_address',
				text: this.props.t('Provider Address'),
				sort: false,
			},
			{
				dataField: 'admin',
				text: this.props.t('Admin'),
				sort: true,
			},
			{
				dataField: 'os',
				text: this.props.t('Operatin System'),
				sort: true,
			},
			{
				dataField: 'type',
				text: this.props.t('Type'),
				sort: true,
			},
			{
				dataField: 'subscription',
				text: this.props.t('Subscription'),
				sort: true,
			},
			{
				dataField: 'notice',
				text: this.props.t('Notice'),
				sort: false,
			},
			{
				dataField: 'hosting_location',
				text: this.props.t('Hosting Location'),
				sort: false,
			},
			{
				dataField: 'data_lost_location_outside_eu',
				text: this.props.t('Data host location outside EU'),
				sort: false,
			},
		];

		return columns;
	}

	render() {
		return (
			<Fragment>
				<div className='page-content'>
					<Container fluid>
						<Breadcrumbs title={this.props.t('Assets list - Software')} breadcrumbItems={this.state.breadcrumbItems} />
						<div>
							{/* table */}
							<Row>
								<Col className='col-12'>
									<Card>
										<CardBody className='pt-0'>
											<TableWithPagination
												columns={this.handleProvideTableColumns()}
												pagination={{
													sizePerPage: 50,
													totalSize: 8,
												}}
												pageIndex={1}
												totalSize={8}
												sizePerPage={50}
												data={this.state.softwares ? this.state.softwares : []}
											/>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { user, token } = state.Login;
	const { App, Feature } = state;
	return { token, user, App, Feature };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
		logoutUser: (history) => dispatch(logoutUser(history)),
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispatchToProps)(SoftwareAssetsList)));
