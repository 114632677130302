export default class Config {
    getConfigValue(key, params) {
        let value = key;

        for (const i in params) {
            value = value.replace(`{${i}}`, params[i]);
        }

        return value;
    }
}
