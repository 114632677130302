import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import Board from '@asseinfo/react-kanban';
import TaskCardItem from "./components/TaskCardItem";
import { useQuery } from "@tanstack/react-query";
import KanbanBoardService from "src/modules/data-protection/apis/KanbanBoardService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ColumnHeader from "./components/ColumnHeader";
import CommonService from "src/modules/data-protection/apis/CommonService";
import moment from 'moment';
import PageHeader from "src/modules/data-protection/components/page-header";
import './kanban-style.scss';

const KanbanBoard = ({
    t
}) => {

    const [ columns, setColumns ] = useState(null);
    const [ boardDataIsLoading, setBoardDataIsLoading ] = useState(true);
    const [ analystAdminsList, setAnalystAdminsList ] = useState([]);
    const [ ownersList, setOwnersList ] = useState([]);
    const [ filters, setFilters ] = useState({
        fromDate    :   null,
        toDate      :   null,
        owner       :   null,
        manager     :   null,
        sortBy      :   null
    });

    const sortOptions = [
        {
            value   :   'priority',
            label   :   (
                <span>
                    { t('Priority') }
                </span>
            ),
        },
        {
            value   :   'deadline',
            label   :   (
                <span>
                    { t('Deadline') }
                </span>
            )
        },
    ];

    const handleFetchTasksListQuery = useQuery({
		queryKey                :   ["fetch-kanboard-tasks-list-query"],
		queryFn                 :   async () => {
            setBoardDataIsLoading(true);
            
            const service = KanbanBoardService.getInstance();

            return await service.fetchList({
                ...filters,
                fromDate    :   filters.fromDate ? moment(filters.fromDate).format('yyyy-MM-DD') : undefined,
                toDate      :   filters.toDate ? moment(filters.toDate).format('yyyy-MM-DD') : undefined,
            });
		},
		cacheTime               :   0,
        refetchOnWindowFocus    :   false,
		onError                 :   (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching tasks list."), {
                type: "error",
            });
		}
	});

    const handleFetchAnalystsAndAnalystAdmins = useQuery({
		queryKey                :   ["fetch-analysts-and-admins-list-query"],
		queryFn                 :   async () => {
            const service = CommonService.getInstance();

            return await service.fetchAnalystAndAdminAnalystList({});
		},
		cacheTime               :   0,
        refetchOnWindowFocus    :   false,
		onError                 :   (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching analyst admins list."), {
                type: "error",
            });
		}
	});

    const handleFetchAnalystsAndOwnersListQuery = useQuery({
		queryKey                :   ["fetch-analysts-and-owners-list-query"],
		queryFn                 :   async () => {
            const service = CommonService.getInstance();

            return await service.fetchTaskOwners({});
		},
		cacheTime               :   0,
        refetchOnWindowFocus    :   false,
		onError                 :   (error) => {
            if (process.env.NODE_ENV === 'development') console.error(error);

            toast(t("An error occurred while fetching owners list."), {
                type: "error",
            });
		},
	});

    useEffect(() => {
        if(handleFetchAnalystsAndAnalystAdmins.data && Array.isArray(handleFetchAnalystsAndAnalystAdmins.data)){
            const newList = handleFetchAnalystsAndAnalystAdmins.data.map((analyst) => {
                return {
                    value   :   analyst.id,
                    label   :   `${analyst.first_name} ${analyst.last_name}`
                };
            }); 

            setAnalystAdminsList(newList);
        }
    }, [ handleFetchAnalystsAndAnalystAdmins.data ]);

    useEffect(() => {
        if(handleFetchAnalystsAndOwnersListQuery.data && Array.isArray(handleFetchAnalystsAndOwnersListQuery.data)){
            const newList = handleFetchAnalystsAndOwnersListQuery.data.map((owner) => {
                return {
                    value   :   owner.id,
                    label   :   `${owner.first_name} ${owner.last_name}`
                };
            }); 

            setOwnersList(newList);
        }
    }, [ handleFetchAnalystsAndOwnersListQuery.data ]);

    useEffect(() => {
        handleFetchTasksListQuery.refetch();
    }, [ filters ]);

    useEffect(() => {
        const { data } = handleFetchTasksListQuery;

        if(data && Array.isArray(data)){
            const resultColumns = [];

            data.forEach((item, index) => {
                const itemTasks = item?.data || [];

                const obj = {
                    id              :   index,
                    title           :   item.title,
                    cards           :   itemTasks.map((task) => {
                        return {
                            id      :   task.id,
                            content :   {
                                id              :   task.id,
                                secondaryId     :   task.task_secondary_id,
                                title           :   task.name,
                                status          :   task.status,
                                subtitle        :   'subtitle',
                                endDate         :   task.ended_at,
                                priority        :   task.priority,
                                progressValue   :   100,
                                caseId          :   task.report_case.secondary_case_id,
								primaryCaseId   :   task.report_case.case_id,
								caseTitle       :   task.report_case.description,
                                team: [
                                    {
                                        id      :   `task-owner-${task.report_case.secondary_case_id}-${task.ended_at}`,
                                        type    :   'Task owner',
                                        name    :   task.owner_info?.name,
                                        avatar  :   task.owner_info?.avatar,
                                    },
                                    {
                                        id      :   `task-manager-${task.report_case.secondary_case_id}-${task.ended_at}`,
                                        type    :   'Task Manager',
                                        name    :   task.manager_info?.name,
                                        avatar  :   task.manager_info?.avatar,
                                    },
                                ],
                                questionnaire   :   task?.questionnaire, // Add questionnaire field
                                caseSecondaryId :   task?.case_secondary_id, // Add case secondary ID field
                                reportCase      :   { // Add report case field
                                    caseId          :   task?.report_case.case_id,
                                    secondaryCaseId :   task?.report_case.secondary_case_id,
                                    isUnreal        :   task?.report_case.is_unreal,
                                    reportType      :   task?.report_case.report_type,
                                },
                                is_mitigation : task?.is_mitigation,
                                task_secondary_id : task?.task_secondary_id,
                                asset_id: task?.asset_id
                            }
                        }
                    }),
                    columnsubtitle  :   `${itemTasks.length}`,
                };

                resultColumns.push(obj);
            });

            setColumns(resultColumns);
        }
        
    }, [ handleFetchTasksListQuery.dataUpdatedAt ]);

    const renderLoadingSection = () => {
        return (
            <Row>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton mb-2" style={{width: '50%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '500px'}}></div>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if(columns?.length > 0){
            setBoardDataIsLoading(false);
        }
    }, [ columns ]);

    const renderBoard = () => {
        return (
            <div className='d-flex'>
            <Board initialBoard={{
                    sortBy      :   filters.sortBy,
                    columns     :   columns,
                    placeholder :   null
                }}
                allowAddCard={ false }
                disableColumnDrag={ true }
                disableCardDrag={ true }
                renderColumnHeader={({ title, columnsubtitle }) => {
                    return (
                        <ColumnHeader title={ title } subtitle={ columnsubtitle } />
                    );
                }}
                renderCard={({ content }, { dragging }) => {
                    return(
                        <TaskCardItem data={ content } dragging={ false } />
                    );
                }}
                onNewCardConfirm={(draftCard) => ({
                    id: new Date().getTime(),
                    ...draftCard,
                })}
            />
            </div>
        );
    }

    const analystAdminsListIsLoading = handleFetchAnalystsAndAnalystAdmins.isFetching || handleFetchAnalystsAndAnalystAdmins.isLoading;

    const ownersListIsLoading = handleFetchAnalystsAndOwnersListQuery.isFetching || handleFetchAnalystsAndOwnersListQuery.isLoading;

    return (
        <div className='page-content supplier-kanban-board-page'>
            <Container fluid>
                <PageHeader title={t('Kanban Board')} />

                <Row className="mb-4">
                    <Col sm="6" md="4" lg="2">
                        <Select
                            isClearable={ true }
                            isDisabled={ boardDataIsLoading }
                            name='sortBy'
                            classNamePrefix='select2-selection'
                            options={ sortOptions }
                            onChange={(e) => {
                                setFilters((filters) => {
                                    return {
                                        ...filters,
                                        sortBy  :   e?.value || undefined
                                    };
                                });
                            }}
                            placeholder={ t('Sort by') }
                        />
                    </Col>

                    <Col sm="6" md="4" lg="2">
                        <Select
                            isLoading={ ownersListIsLoading }
                            isDisabled={ ownersListIsLoading || boardDataIsLoading }
                            isClearable={ true }
                            name='analysts and analystAdmins'
                            classNamePrefix='select2-selection'
                            options={ ownersList }
                            onChange={(e) => {
                                setFilters((filters) => {
                                    return {
                                        ...filters,
                                        owner   :   e?.value || undefined
                                    };
                                });
                            }}
                            placeholder={ t('Task Owner') }
                        />
                    </Col>

                    <Col sm="6" md="4" lg="2">
                        <Select
                            isLoading={ analystAdminsListIsLoading }
                            isDisabled={ analystAdminsListIsLoading || boardDataIsLoading }
                            isClearable={ true }
                            name='managers'
                            classNamePrefix='select2-selection'
                            options={ analystAdminsList }
                            onChange={(e) => {
                                setFilters((filters) => {
                                    return {
                                        ...filters,
                                        manager     :   e?.value || undefined
                                    };
                                });
                            }}
                            placeholder={ t('Task Manager') }
                        />
                    </Col>

                    <Col sm="6" md="6" lg="2">
                        <ReactDatePicker
                            disabled={ boardDataIsLoading }
                            className='dt-datepicker'
                            value={ filters.fromDate }
                            selected={ filters.fromDate }
                            placeholderText={ t('Creation Date') }
                            dateFormat='dd/MM/yyyy'
                            onChange={(e) => {
                                setFilters((filters) => {
                                    return {
                                        ...filters,
                                        fromDate    :   e || undefined
                                    };
                                });
                            }}
                        />
                    </Col>
                    
                    <Col sm="6" md="6" lg="2">
                        <ReactDatePicker
                            disabled={ boardDataIsLoading }
                            className='dt-datepicker'
                            value={ filters.toDate }
                            selected={ filters.toDate }
                            placeholderText={ t('Deadline') }
                            dateFormat='dd/MM/yyyy'
                            onChange={(e) => {
                                setFilters((filters) => {
                                    return {
                                        ...filters,
                                        toDate  :   e || undefined
                                    };
                                });
                            }}
                        />
                    </Col>
                </Row>
                
                <Row>
                    <Col sm="12">
                        {
                            boardDataIsLoading ? (
                                <>{ renderLoadingSection() }</>
                            ) : (
                                <>{ renderBoard() }</>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { Organization } = state;

    return {
        user            :   state.Login.user,
        organization    :   Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(KanbanBoard))
);