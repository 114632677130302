import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { CgLink } from "react-icons/cg";
import { TbLoader } from "react-icons/tb";
import { convertToHTML } from "draft-convert";
import {
  API_URL_TASK_UPLOAD_FILE,
  CM_API_URL_TASK_UPLOAD_FILE,
  API_URL_TASK_UPLOAD_FILES,
  CM_API_URL_TASK_UPLOAD_FILES,
  FETCH_RISKS_CATEGORIES_FAILED,
  UPLOAD_ATTACHMENT_FAILED,
  CREATE_TASK_SUCCESSFUL,
  CREATE_TASK_FAILED,
} from "../../../../common/constants";
import DateUtils from "../../../../services/utils/DateUtils";
import Select from "react-select";
import axios from "axios";
import UploadedFiles from "../../CommonForTaskAndRisk/uploadFiles";

const CreateTaskModal = function (props) {
  CreateTaskModal.displayName = "Memorized Component - Create Task Modal";
  if (process.env.NODE_ENV === 'development')
    console.log("---- rendering memorized component >>> CreateTaskModal");

  const dateUtils = new DateUtils();
  const {
    t,
    errorNotification,
    successNotification,
    authToken,
    isOpen,
    currentUser,
    owners,
    initialTaskStatus,
    priorities,
    riskId,
    reportId,
    successCreation,
    close,
    module,
  } = props;

  const [categories, setCategories] = useState(null);
  const categoryHTMLRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const titleHTMLRef = useRef(null);
  const [title, setTitle] = useState(null);
  const [titleError, setTitleError] = useState(null);

  const startDateHTMLRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState(null);

  const deadlineDateHTMLRef = useRef(null);
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [deadlineDateError, setDeadlineDateError] = useState(null);

  const ownerHTMLRef = useRef(null);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [ownerError, setOwnerError] = useState(null);

  const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);

  const priorityHTMLRef = useRef(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [priorityError, setPriorityError] = useState(null);

  const descriptionHTMLRef = useRef(null);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [descriptionError, setDescriptionError] = useState(null);

  const [attachmentsList, setAttachmentsList] = useState([]);
  const [showAttachmentsList, setShowAttachmentsList] = useState(false);
  const [showAttachmentsProgress, setShowAttachmentsProgress] = useState(false);

  const [comment, setComment] = useState(EditorState.createEmpty());

  const [showCommentAttachmentsList, setShowCommentAttachmentsList] =
    useState(false);
  const [showCommentAttachmentsProgress, setShowCommentAttachmentsProgress] =
    useState(false);
  const [commentAttachmentsList, setCommentAttachmentsList] = useState([]);

  const [showSubmitProgress, setShowSubmitProgress] = useState(false);

  /**
   * this method validates the create task form.
   * @returns returns true if the validation result was currect, otherwise returns false.
   */
  const handleFormValidations = () => {
    let isValid = true;

    if (!selectedCategory) {
      isValid = false;
      setCategoryError("Please Select Category");
    }

    if (!title.trim()) {
      isValid = false;
      setTitleError("Please Select Title");
    }

    if (!startDate) {
      isValid = false;
      setStartDateError("Please Select Start Date");
    }

    if (!deadlineDate) {
      isValid = false;
      setDeadlineDateError("Please Select Deadline");
    }

    if (!selectedOwner) {
      isValid = false;
      setOwnerError("Please Select Owner");
    }

    if (!selectedPriority) {
      isValid = false;
      setPriorityError("Please Select Priority");
    }

    if (!description.getCurrentContent().hasText()) {
      isValid = false;
      setDescriptionError("Please type description");
    }

    if (!isValid) categoryHTMLRef.current.scrollIntoView();

    return isValid;
  };

  /**
   * this method submits create task request to the server.
   * @param {String} caseId the ID of the case you want to create task for.
   * @param {String} riskId the ID of the risk you want to create task for. set null if you don't want to assign the created task to a risk.
   * @param {String} token authorization token.
   */
  const handleSubmit = async (caseId, riskId, token) => {
    setShowSubmitProgress(true);

    try {
      if (handleFormValidations()) {
        const data = {
          name: title,
          description: convertToHTML(description.getCurrentContent()),
          startedAt: startDate,
          descriptionAttachments: attachmentsList.map((f) => String(f.file.id)),
          analyst: selectedOwner.value,
          endedAt: deadlineDate,
          priority: selectedPriority.value,
          relatedTasks: [],
          reportCaseId: caseId,
          comments: !comment.getCurrentContent().hasText()
            ? []
            : [
                {
                  content: convertToHTML(comment.getCurrentContent()),
                  attachments:
                    commentAttachmentsList.length > 0
                      ? commentAttachmentsList.map((f) => String(f.file.id))
                      : [],
                },
              ],
          category:
            t(selectedCategory.label) === t("Other")
              ? null
              : selectedCategory.value,
          otherCategory:
            t(selectedCategory.label) === t("Other")
              ? selectedSubCategory
              : null,
          riskRate: riskId,
        };

        const result = await axios.post(
          module === "wb"
            ? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/task/create`
            : `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/task/create`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status === 200) {
          successNotification({
            message: t(CREATE_TASK_SUCCESSFUL),
          });
          handleCloseModal();
          successCreation();
        } else {
          errorNotification({
            message: t(CREATE_TASK_FAILED),
          });
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          "this error only appears in the development environment:\nerror while submitting task:",
          error
        );
      }

      errorNotification({
        message: t(CREATE_TASK_FAILED),
      });
    }

    setShowSubmitProgress(false);
  };

  /**
   * this method resets the form and calls the close prop.
   */
  const handleCloseModal = () => {
    setSelectedCategory(null);
    setCategoryError(null);
    setSelectedSubCategory(null);
    setTitle(null);
    setTitleError(null);
    setStartDate(null);
    setStartDateError(null);
    setDeadlineDate(null);
    setDeadlineDateError(null);
    setSelectedOwner(null);
    setOwnerError(null);
    setSelectedTaskStatus(null);
    setSelectedPriority(null);
    setPriorityError(null);
    setDescription(EditorState.createEmpty());
    setDescriptionError(null);
    setAttachmentsList([]);
    setShowAttachmentsList(false);
    setShowAttachmentsProgress(false);
    setComment(EditorState.createEmpty());
    setCommentAttachmentsList([]);
    setShowCommentAttachmentsList(false);
    setShowCommentAttachmentsProgress(false);
    setShowSubmitProgress(false);

    close();
  };

  /**
   * this method uploads attachments to the server.
   * @param {Object[]} fs files to upload.
   * @param {String} token authorization token.
   */
  const handleUploadAttachments = (fs, token) => {
    try {
      setShowAttachmentsProgress(true);
      const files = [];
      const formData = new FormData();
      for (const f in fs.target.files) {
        if (fs.target.files.hasOwnProperty(f)) {
          files.push(fs.target.files[f]);
        }
      }
      files.map(async (file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        });
        formData.append("file", file);
        const result = await axios.post(
          module === "wb"
            ? API_URL_TASK_UPLOAD_FILE
            : CM_API_URL_TASK_UPLOAD_FILE,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          const fileData = result.data.data;
          setAttachmentsList((oldArray) => [
            ...oldArray,
            {
              id: fileData.id,
              file: fileData,
              name: file.name,
              preview: file.preview,
              formattedSize: file.formattedSize,
            },
          ]);
          if (file === files[files.length - 1])
            setShowAttachmentsProgress(false);
        } else {
          setShowAttachmentsProgress(false);
          errorNotification({
            message: t(UPLOAD_ATTACHMENT_FAILED),
          });
        }
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          "this error only appears in the development environment:\nerror while uploading attachments:",
          error
        );
      }

      errorNotification({
        message: t(UPLOAD_ATTACHMENT_FAILED),
      });
    }
  };

  /**
   * this method removes attachment from the uploading attachments.
   * @param {String} id the ID of the attachment you want to delete.
   */
  const handleRemoveAttachment = (id) => {
    const index = attachmentsList.findIndex((i) => i.id === id);
    if (index > -1) {
      const oldArray = Array.from(attachmentsList);
      oldArray.splice(index, 1);
      setAttachmentsList(oldArray);
    }
  };

  /**
   * this method uploads comment attachments to the server.
   * @param {Object[]} fs files to upload.
   * @param {String} token authorization token.
   */
  const handleUploadCommentAttachments = async (fs, token) => {
    console.log(fs);
    try {
      setShowCommentAttachmentsProgress(true);
      const files = [];
      const formData = new FormData();
      for (const f in fs.target.files) {
        if (fs.target.files.hasOwnProperty(f)) {
          files.push(fs.target.files[f]);
        }
      }

      files.map(async (file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        });
        formData.append("files[]", file);
      });

      const result = await axios.post(
        module === "wb"
          ? API_URL_TASK_UPLOAD_FILES
          : CM_API_URL_TASK_UPLOAD_FILES,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        const fileData = result.data.data;

        // eslint-disable-next-line array-callback-return
        fileData.map((fd, i) => {
          setCommentAttachmentsList((oldArray) => [
            ...oldArray,
            {
              id: fd.id,
              file: fd,
              name: files[i].name,
              preview: files[i].preview,
              formattedSize: files[i].formattedSize,
            },
          ]);
        });
        setShowCommentAttachmentsProgress(false);
      } else {
        setShowCommentAttachmentsProgress(false);
        errorNotification({
          message: t(UPLOAD_ATTACHMENT_FAILED),
        });
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          "this error only appears in the development environment:\nerror while uploading comment attachments:",
          error
        );
      }

      errorNotification({
        message: t(UPLOAD_ATTACHMENT_FAILED),
      });
    }
  };

  /**
   * this method removes comment's attachment from the uploading attachments.
   * @param {String} id the ID of the attachment you want to delete.
   */
  const handleRemoveCommentAttachment = (id) => {
    const index = commentAttachmentsList.findIndex((i) => i.id === id);
    if (index > -1) {
      const oldArray = Array.from(commentAttachmentsList);
      oldArray.splice(index, 1);
      setCommentAttachmentsList(oldArray);
    }
  };

  /**
   * this method fetches tasks categories.
   * @param {String} token authorization token.
   */
  const handleFetchCategories = useCallback(
    async (token) => {
      try {
        const result = await axios.get(
          module === "wb"
            ? `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/task/categories`
            : `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/cm/task/categories`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status === 200) {
          const categories = result.data.data.map((item) => {
            return {
              value: item.id,
              baseLabel: item.name,
              label: item.name,
            };
          });
          setCategories(categories);
        } else {
          errorNotification({
            message: t(FETCH_RISKS_CATEGORIES_FAILED),
          });
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            "this error only appears in the development environment:\nerror while fetching categories:",
            error
          );
        }

        errorNotification({
          message: t(FETCH_RISKS_CATEGORIES_FAILED),
        });
      }
    },
    [t, errorNotification]
  );

  useEffect(() => {
    if (isOpen && !categories) handleFetchCategories(authToken);
  }, [isOpen, authToken, categories, handleFetchCategories]);

  useEffect(() => {
    if (isOpen) setSelectedTaskStatus(initialTaskStatus);
  }, [isOpen, initialTaskStatus]);

  return (
    <Modal
      size="lg"
      scrollable={true}
      isOpen={isOpen}
      toggle={handleCloseModal}
      backdrop="static"
    >
      {/* header */}
      <ModalHeader toggle={handleCloseModal}>{t("Create a task")}</ModalHeader>

      {/* body */}
      <ModalBody>
        <AvForm
          className="needs-validation"
          onValidSubmit={() =>
            handleFormValidations()
              ? handleSubmit(reportId, riskId, authToken)
              : null
          }
        >
          <Row className="mb-3" style={{ zIndex: 10, position: "relative" }}>
            {/* creation date */}
            <Col sm="12" md="3" lg="3">
              <Label className="form-label text-dark">{`${t(
                "Creation Date"
              )}: `}</Label>
              <Input
                name="creationDate"
                type="date"
                value={dateUtils.getCurrentDate()}
                readOnly
                disabled
              />
            </Col>

            {/* category */}
            <Col
              sm="12"
              md={
                !selectedCategory ||
                (selectedCategory && t(selectedCategory.label) !== t("Other"))
                  ? "5"
                  : "3"
              }
              lg={
                !selectedCategory ||
                (selectedCategory && t(selectedCategory.label) !== t("Other"))
                  ? "5"
                  : "3"
              }
            >
              <span ref={categoryHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Category"
              )}: `}</Label>
              {categories ? (
                <>
                  <Select
                    name="category"
                    classNamePrefix="select2-selection"
                    options={categories.map((c) => {
                      return {
                        value: c.value,
                        baseLabel: c.baseLabel,
                        label: t(c.baseLabel),
                      };
                    })}
                    placeholder={t("Select")}
                    onChange={(e) => setSelectedCategory(e)}
                  />
                  <p className="text-danger">
                    {!selectedCategory ? categoryError : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>

            {/* sub category */}
            <Col
              sm="12"
              md="3"
              lg="3"
              hidden={
                !selectedCategory || selectedCategory.baseLabel !== "Other"
              }
            >
              <Label className="form-label text-dark">{`${t(
                "Subcategory"
              )}: `}</Label>
              <AvField
                name="sub-cat"
                type="text"
                errorMessage={t("This field cannot be blank")}
                className="form-control"
                validate={{
                  required: {
                    value:
                      selectedCategory &&
                      selectedCategory.baseLabel === "Other",
                  },
                }}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
              />
            </Col>

            {/* title */}
            <Col
              sm="12"
              md={
                !selectedCategory ||
                (selectedCategory && selectedCategory.label !== "Other")
                  ? "4"
                  : "3"
              }
              lg={
                !selectedCategory ||
                (selectedCategory && selectedCategory.label !== "Other")
                  ? "4"
                  : "3"
              }
            >
              <span ref={titleHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Task Title"
              )}: `}</Label>
              <AvField
                name="title"
                type="text"
                errorMessage={t("This field cannot be blank")}
                className="form-control"
                onChange={(e) => setTitle(e.target.value)}
                validate={{
                  required: {
                    value: title,
                  },
                }}
              />
              <p className="text-danger">{!title ? titleError : ""}</p>
            </Col>
          </Row>

          <Row className="mb-3" style={{ zIndex: 9, position: "relative" }}>
            {/* start date */}
            <span ref={startDateHTMLRef}></span>
            <Col sm="12" md="6" lg="6">
              <Label className="form-label text-dark">{`${t(
                "Start Date"
              )}: `}</Label>
              <Input
                name="startDate"
                type="date"
                min={dateUtils.getCurrentDate()}
                max={deadlineDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
              <p className="text-danger">{!startDate ? startDateError : ""}</p>
            </Col>

            {/* deadline date */}
            <Col sm="12" md="6" lg="6">
              <span ref={deadlineDateHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Deadline"
              )}: `}</Label>
              <Input
                name="deadlineDate"
                min={startDate}
                type="date"
                onChange={(e) => setDeadlineDate(e.target.value)}
                disabled={startDate ? false : true}
              />
              <p className="text-danger">
                {!deadlineDate ? deadlineDateError : ""}
              </p>
            </Col>
          </Row>

          <Row className="mb-3" style={{ zIndex: 8, position: "relative" }}>
            {/* manager */}
            <Col sm="12" md="6" lg="6">
              <Label className="form-label text-dark">{`${t(
                "Task Manager"
              )}: `}</Label>
              <Input
                name="manager"
                type="text"
                defaultValue={`${currentUser.first_name} ${currentUser.last_name}`}
                readOnly
                disabled
              />
            </Col>

            {/* owner */}
            <Col sm="12" md="6" lg="6">
              <span ref={ownerHTMLRef}></span>
              <Label className="form-label text-dark">
                {t("Task owner")}:{" "}
              </Label>
              {owners ? (
                <>
                  <Select
                    required
                    name="owner"
                    classNamePrefix="select2-selection"
                    options={owners}
                    value={selectedOwner}
                    onChange={(e) => setSelectedOwner(e)}
                    placeholder={t("Select")}
                  />
                  <p className="text-danger">
                    {!selectedOwner ? ownerError : ""}
                  </p>
                </>
              ) : (
                <div
                  className="dt-field dt-skeleton dt-select-list"
                  style={{ marginBottom: 16 }}
                ></div>
              )}
            </Col>
          </Row>

          <Row className="mb-3" style={{ zIndex: 7, position: "relative" }}>
            {/* task status */}
            <Col sm="12" md="6" lg="6">
              <Label className="form-label text-dark">{`${t(
                "Status"
              )}: `}</Label>
              <Select
                name="status"
                value={selectedTaskStatus}
                classNamePrefix="select2-selection"
                options={[initialTaskStatus]}
                placeholder={t("Select")}
                readOnly
                isDisabled
              />
            </Col>

            {/* priority */}
            <Col sm="12" md="6" lg="6">
              <span ref={priorityHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Priority"
              )}: `}</Label>
              <Select
                required
                name="priority"
                classNamePrefix="select2-selection"
                options={priorities}
                value={selectedPriority}
                onChange={(e) => {
                  setSelectedPriority(e);
                }}
                placeholder={t("Select")}
              />
              <p className="text-danger">
                {!selectedPriority ? priorityError : ""}
              </p>
            </Col>
          </Row>

          <Row className="mb-3">
            {/* description */}
            <Col sm="12" md="12">
              <span ref={descriptionHTMLRef}></span>
              <Label className="form-label text-dark">{`${t(
                "Description"
              )}: `}</Label>
              <Editor
                editorState={description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => setDescription(e)}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                    bold: { className: 'bordered-option-classname' },
                    italic: { className: 'bordered-option-classname' },
                    underline: { className: 'bordered-option-classname' },
                    strikethrough: { className: 'bordered-option-classname' },
                    code: { className: 'bordered-option-classname' },
                  },
                  blockType: {
                    className: 'bordered-option-classname',
                  },
                  fontSize: {
                    className: 'bordered-option-classname',
                  },
                }}
              />
              <p className="text-danger">
                {!description.getCurrentContent().hasText()
                  ? descriptionError
                  : ""}
              </p>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Button
                color="primary"
                onClick={() => setShowAttachmentsList(!showAttachmentsList)}
                outline
              >
                <CgLink />
                {t("Attach")}
              </Button>
            </Col>
          </Row>

          <Row className="mb-3" hidden={!showAttachmentsList}>
            <Col>
              <UploadedFiles
                t={t}
                uploadedFiles={attachmentsList}
                handleAcceptedFiles={(e) =>
                  handleUploadAttachments(e, props.authToken)
                }
                showProg={showAttachmentsProgress}
                handleClickDeleteFiles={(e) => handleRemoveAttachment(e.id)}
              />
            </Col>
          </Row>

          <br />

          <Row className="mb-3">
            <Col sm="12" md="12">
              <Label className="form-label text-dark">{`${t(
                "Comments"
              )}: `}</Label>
              <Editor
                editorState={comment}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => setComment(e)}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Button
                color="primary"
                onClick={() =>
                  setShowCommentAttachmentsList(!showCommentAttachmentsList)
                }
                outline
              >
                <CgLink />
                {t("Attach")}
              </Button>
            </Col>
          </Row>

          <Row hidden={!showCommentAttachmentsList}>
            <Col>
              <UploadedFiles
                t={t}
                uploadedFiles={commentAttachmentsList}
                handleAcceptedFiles={(e) =>
                  handleUploadCommentAttachments(e, props.authToken)
                }
                showProg={showCommentAttachmentsProgress}
                handleClickDeleteFiles={(e) =>
                  handleRemoveCommentAttachment(e.id)
                }
              />
            </Col>
          </Row>

          <ModalFooter>
            <Button
              color="primary"
              className="waves-effect waves-light"
              type="submit"
            >
              {showSubmitProgress ? <TbLoader /> : t("Save")}
            </Button>
          </ModalFooter>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default memo(CreateTaskModal);
