import { memo, useEffect, useState } from "react";
import CustomTableHeader from "./CustomTableHeader";
import CustomTableRow from "./CustomTableRow";
import { uniqueKey } from "./utils";
import "./style.scss";

const CustomTable = (props) => {
  const { headerData = [], data = [] } = props;

  const [columnWidths, setColumnWidths] = useState([]);
  const [width, setWidth] = useState([]);
  const [dataWidth, setDataWidth] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const totalWidth = window.innerWidth;
      const newColumnWidths = headerData[0].map((col, index) => {
		width.push()
		width.push((parseFloat(col.width) / totalWidth) * 100);
        return {
          value: col.value,
          width: `${(parseFloat(col.width) / totalWidth) * 100}%`,
        };
      });

	  setColumnWidths([newColumnWidths]);

	  const newDataWidths = data.map((d) => {
		return d.map((col, index) => {
			// console.log("COL", col)
			return {
			// index: index,
			value: col.value,
			width: `${width[index]}%`,
			isRecentlyAdded: col?.isRecentlyAdded
			};
		})
		
      });

	  setDataWidth(newDataWidths);
      
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [headerData, data]);

  
  return (
	<div className="table-scroll-container">
		<div className="custom-table">
		{/* table header */}
		{columnWidths.length > 0
			? columnWidths.map((row, index) => {
				return (
				<CustomTableHeader
					key={uniqueKey(row[index].value)}
					rowData={row}
				/>
				);
			})
			: null}

		{/* table body */}
		{dataWidth?.map((row, index) => {
			return <CustomTableRow key={index} rowData={row} bold={row?.isRecentlyAdded} />;
		})}
		</div>
	</div>
  );
};

export default memo(CustomTable);
