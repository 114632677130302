import { withNamespaces } from "react-i18next";
import { Row, Col, CardBody, Card, CardTitle } from "reactstrap";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { useQuery } from "@tanstack/react-query";
import DashboardService from "../../../../../apis/DashboardService";

import RevenueByLocations from '../../components/RevenueByLocations';
import DownloadStatistics from '../../components/DownloadStatistics';
import Widget from "../../components/Widget";
import { RadialBar, DonutChart, LineChart, BarChart } from "../../components/ApexChart";
import Knob from "../../components/Knob";
import LatestCases from "../../components/LatestCases";
import { toast } from "react-toastify";

import { taskStatuses } from "../../../../reporting/TaskManager/constants";

const TabIncidentContent = (props) => {
    const {
        t, 
		type
    } = props;


	const [ widgetsList, setWidgetsList ] = useState([]);
	const [ statusesList, setStatusesList ] = useState([]);
	const [ investigationTime, setInvestigationTime ] = useState(null);
	const [ channelsList, setChannelsList] = useState([]);
	const [ dpiaRisksList, setDpiaRisksList] = useState([]);
	const [ typesByDate , setTypesByDate ] = useState([]);
	const [ categoriesList,  setCategoriesList] = useState([]); 
	const [ riskRateList, setRiskRateList ] = useState([]);
	const [ myTasks, setMyTasks ] = useState([]);
	const [ casesAssignedToMe, setCasesAssignedToMe ] = useState(null);
	const [ countriesList, setCountriesList ] = useState([]);
	const [ topCountriesList, setTopCountriesList ] = useState([]);
	const [ latestCasesList, setLatestCasesList ] = useState([]);


	const [dateFilter, setDateFilter] = useState(null);

    const handleFetchDashboardQuery = useQuery({
		queryKey: ["data-protection-admin-incidents-dashboard"],
		queryFn: async () => {
		  const service = DashboardService.getInstance();
	
		  return await service.list({
			date				: 	dateFilter?.value,
			questionnaire_name	:	"data_breach_incident"	
		  });
		},
		cacheTime: 0,
		onError: (error) => {
		  if (process.env.NODE_ENV === 'development') console.error(error);
	
		  toast(t("An error occurred while fetching dashboard data."), {
			type: "error",
		  });
		},
	});

    const rendeDateFilterOptions = () => {
		const filterOptions = [
			{
				value: 'last_year',
				label: t('Last year'),
			},
			{
				value: 'this_year',
				label: t('This year'),
			},
			{
				value: 'last_three_month',
				label: t('Last three month'),
			},
			{
				value: 'this_month',
				label: t('This month'),
			},
		];

		return (
			<Col lg={4}>
				<Select
					required
					isClearable={true}
					name='sortByDate'
					classNamePrefix='select2-selection'
					options={filterOptions}
					value={dateFilter}
					onChange={(e) => setDateFilter(e)}
					placeholder={t('Filter by date')}
				/>
			</Col>
		);
	}

    useEffect(() => {
		if(dateFilter){
			handleFetchDashboardQuery.refetch()
		}
	}, [dateFilter]);

	useEffect(() => {
		const { data } = handleFetchDashboardQuery;
		if(data){
			const {
				statuses : gotStatuses,
				totalCases,
				investigationTime : gotInvestigationTime,
				sources,
				DPIARisks : gotDPIARisks,
				typesByDate : gotTypesByDate,
				categories : gotCategories,
				riskRate,
				myTasks : gotMyTasks,
				assignedToMe,
				allCountries,
				topCountries
			} = handleFetchDashboardQuery.data;

			const widgets = [];
			const statuses = [];
			const channels = [];
			const dpiaRisks = [];
			const categories = [];
			const riskRates = [];
			const availableTopCountries = {};
			const myTasksResult = [];

			/**
			 * widgets list
			 */
			widgets.push({
				amount	:	totalCases,
				percent	: 	100,
				title	: 	'Total',
				icon	: 	'ri-stack-line',
				displayChart	: false,
			});
			/**-------------------------- */

			/**
			 * statuses list and widgets list
			 */
			for (const item in gotStatuses) {
				statuses.push({
					amount	:	gotStatuses[item].amount,
					percent	:	gotStatuses[item].percent,
					title	:	gotStatuses[item].title,
				});

				if (!['assigned', 'deleted'].includes(gotStatuses[item].title)) {
					widgets.push({
						amount	:	gotStatuses[item].amount,
						title	: 	gotStatuses[item].title,
						icon	: 	null,
						chart: {
							show	:	true,
							percent : 	gotStatuses[item].percent
						}
					});
				}
			}
			/**--------------------------- */

			/**
			 * channels list
			 */
			for (const item in (sources || [])) {
				channels.push({
					amount	: 	sources[item].amount,
					title	: 	sources[item].title,
					percent	:	sources[item].percent,
				});
			}
			/**----------------------------- */

			/**
			 * dpia risks 
			 */
			for (const item in (gotDPIARisks || [])) {
				dpiaRisks.push({
					color	: 	gotDPIARisks[item].color,
					amount	: 	gotDPIARisks[item].amount,
					title	: 	gotDPIARisks[item].title,
					percent	:	gotDPIARisks[item].percent,
				});
			}
			/**------------------------------ */

			/**
			 * categories list
			 */
			for (const item in (gotCategories || [])) {
				categories.push({
					amount	:	gotCategories[item].amount,
					title	:	gotCategories[item].title,
					percent	:	gotCategories[item].percent,
				});
			}
			/**------------------------------ */

			/**
			 * risk rates
			 */
			for (const item in (riskRate || [])) {
				riskRates.push({
					color	: 	riskRate[item].color,
					amount	: 	riskRate[item].amount,
					title	: 	riskRate[item].title,
					percent	:	riskRate[item].percent,
				});
			}
			/**-------------------------------- */

			/**
			 * my tasks
			 */
			for (const item in (gotMyTasks || [])) {
				if(!['DELETED'].includes(item)){
					myTasksResult.push({
						color	: 	gotMyTasks[item]?.color,
						amount	: 	gotMyTasks[item]?.amount,
						title	: 	t((taskStatuses.find((s) => s.value === item))?.label),
						percent	:	gotMyTasks[item]?.percent,
					});
				}
			}
			/**-------------------------------- */

			/**
			 * top countires
			 */
			for (const [key, value] of Object.entries(topCountries || [])) {
				if (Number(value) !== 0) {
					availableTopCountries[key] = value;
				}
			}
			/**-------------------------------- */

			setWidgetsList(widgets);
			setStatusesList(statuses);
			setInvestigationTime(gotInvestigationTime);
			setChannelsList(channels);
			setDpiaRisksList(dpiaRisks);
			setTypesByDate(gotTypesByDate);
			setCategoriesList(categories);
			setRiskRateList(riskRates);
			setMyTasks(myTasksResult);
			setCasesAssignedToMe(assignedToMe);
			setCountriesList(allCountries);
			setTopCountriesList(availableTopCountries);
		}
	}, [handleFetchDashboardQuery.data]);

    return (
        <>
            <Row className="mb-4 mt-4">
                <Col lg={8}>
                    <Row>
                        { rendeDateFilterOptions() }
                    </Row>
                </Col>
                <Col lg={4}>
                    <DownloadStatistics filterQuery={null} />
                </Col>
            </Row>

			<Row>
				<Col lg={8}>
					<Row>
						{
							widgetsList.map((widgetData) => {
								return (
									<Col lg={4}>
										<Widget {...widgetData} />
									</Col>
								)
							})
						}
					</Row>
					
					<Row>
						<Col lg={12}>
							<BarChart title="Processing time in hours" data={investigationTime}/>
						</Col>
					</Row>

					<Row>
						<Col lg={6} className="d-flex align-items-stretch">
							<RadialBar title={'Channels'} data={ channelsList } />
						</Col>
						

						<Col lg={6} className="d-flex align-items-stretch">
							<DonutChart title={'DPIA risks'} tooltipText="" categories={ dpiaRisksList } />
						</Col>
					</Row>

					<Row>
						<Col lg={12}>
							<LineChart title="Amount of incidents" data={ typesByDate } />
						</Col>
					</Row>
				</Col>

				<Col lg={4}>
					<DonutChart title={t('Top 5 Categories')} categories={ categoriesList } />

					<DonutChart title={'My Tasks'} categories={ myTasks } />

					<DonutChart title={'My risks'} categories={ riskRateList } />
				</Col>
			</Row>

			<Row>
				<Col lg={4} className="d-flex align-items-stretch">
					<Card className="flex-grow-1 card_box_Shadow">
						<CardBody>
							<div className="mb-5">
								<CardTitle>
									{t("Incidents assigned to me")}
								</CardTitle>
							</div>
							<div className="text-center" style={{ marginBottom: 84 }}>
								<Knob value={ casesAssignedToMe?.percent } width={254} height={255} fgColor="#5664d2" lineCap="butt" readOnly={true} thickness={0.2} />
							</div>
						</CardBody>
					</Card>
				</Col>

				<Col lg={8} className="d-flex align-items-stretch">
					<RevenueByLocations countries={countriesList} topCountries={topCountriesList}/>
				</Col>
			</Row>

			<Row>
				<Col lg={12}>
					<LatestCases orgIsAll={false} type={"data_breach_incident"} />
				</Col>
			</Row>
        </>
    );
}

export default withNamespaces()(TabIncidentContent);