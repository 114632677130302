import axios from "axios";
import { GDPR_DOMAIN_URL, SOFTWARE_GENERATE_BY_AI } from "../../../common/constants";

export default class DataProtectionSoftwareService {
  static instance;
  resource = `${GDPR_DOMAIN_URL}software/`;

  constructor() {}

  static getInstance() {
    if (!DataProtectionSoftwareService.instance) {
      DataProtectionSoftwareService.instance =
        new DataProtectionSoftwareService();
    }

    return DataProtectionSoftwareService.instance;
  }

  async list(pageIndex, pageSize, filters) {
    const response = await axios.get(`${this.resource}list`, {
      params: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        ...filters,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async getCount() {
    const response = await axios.get(`${this.resource}count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data;
  }

  async providers(pageSize, pageIndex, sortOrder, sortField) {
    const response = await axios.get(`${this.resource}providers`, {
      params: {
        pageSize,
        pageIndex,
        sortOrder,
        sortField,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async modules() {
    const response = await axios.get(`${this.resource}modules`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async addModule(payload) {
    const response = await axios.post(`${this.resource}add_module`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async removeModule(payload) {
    const response = await axios.post(
      `${this.resource}remove_module`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  async operatingSystems(pageSize, pageIndex, sortOrder, sortField) {
    const response = await axios.get(`${this.resource}operating_systems`, {
      params: {
        pageSize,
        pageIndex,
        sortOrder,
        sortField,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async types(pageSize, pageIndex, sortOrder, sortField) {
    const response = await axios.get(`${this.resource}types`, {
      params: {
        pageSize,
        pageIndex,
        sortOrder,
        sortField,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async create(items) {
    const response = await axios.post(`${this.resource}create`, items, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async generateByAI(items) {
    const response = await axios.post(`${SOFTWARE_GENERATE_BY_AI}`, items, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
        Accept: "application/json", 
      },
    });

    return response?.data?.message;
  }

  async downloadCSV() {
    const response = await axios.get(`${this.resource}download`, {
      maxBodyLength: Infinity,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data;
  }

  async changeStatus(data) {
    const response = await axios.put(`${this.resource}change_status`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async changeAssignedAnalyst(data) {
    const response = await axios.post(`${this.resource}change_analyst`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }

  async changePriority(data) {
    const response = await axios.put(`${this.resource}change_priority`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async details(softwareId) {
    const response = await axios.get(`${this.resource}${softwareId}/show`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async updateDetails(softwareId, payload) {
    const response = await axios.post(
      `${this.resource}${softwareId}/edit`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  async uploadCSV(formData) {
    const response = await axios.post(`${this.resource}upload_csv`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async delete(id, reason, erasedBy, erasedAt) {
    if (!id) {
      return;
    }

    const data = {
      reason: reason,
      erasedBy: erasedBy,
      erasedAt: erasedAt,
    };
    const response = await axios.post(`${this.resource}${id}/delete`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async filledQuestionnaires(softwareId, questionnaireType) {
    const response = await axios.get(
      `${this.resource}${softwareId}/questionnaire_type/${questionnaireType}/list`,
      {
        params: {},
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    // console.log("RES", response, `${this.resource}${softwareId}/questionnaire_type/${questionnaireType}/list`)
    return response.data.data;
  }

  async toggleFlag(data) {
    const response = await axios.put(`${this.resource}toggle_flag`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async changeDepartment(payload) {
    const response = await axios.post(
      `${this.resource}change_responsible_department`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  async changePhoneNumber(payload) {
    const response = await axios.post(
      `${this.resource}change_phone_number`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  async changeEmail(payload) {
    const response = await axios.post(`${this.resource}change_email`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async changePosition(payload) {
    const response = await axios.post(
      `${this.resource}change_position`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  async changeCompany(payload) {
    const response = await axios.post(
      `${this.resource}change_company`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  async changeResponsiblePerson(payload) {
    const response = await axios.post(
      `${this.resource}change_responsible_person`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // DPA
  async listDPA(id) {
    const response = await axios.get(`${GDPR_DOMAIN_URL}dpa/${id}/detail`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  // Consultation
  async toggleConsultation(id) {
    const response = await axios.post(
      `${GDPR_DOMAIN_URL}dpa/${id}/toggle_consultation_required`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Reason
  async toggleReason(payload) {
    const response = await axios.post(
      `${GDPR_DOMAIN_URL}dpa/update_reason`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Date
  async updateDate(payload) {
    const response = await axios.post(
      `${GDPR_DOMAIN_URL}dpa/update_date`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Result
  async updateResult(payload) {
    const response = await axios.post(
      `${GDPR_DOMAIN_URL}dpa/update_result`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // History Logs
  async fetchLogs(assetId, params = {}) {
    const response = await axios.get(
      `${GDPR_DOMAIN_URL}logs?softwareId=${assetId}`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

  async fetchLogDetails(logId, params = {}) {
    const response = await axios.get(
      `${GDPR_DOMAIN_URL}log/${logId}/show`,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );
    return response.data.data;
  }

  // Approve Selection
  async approveSelection(payload) {
    const response = await axios.post(`${this.resource}multi_approve`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async mentionCommentSearch(caseId){
    const response = await axios.get(
        `${GDPR_DOMAIN_URL}software/${caseId}/mentionCommentSearch`, 
        {
            headers :   {
              Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`
            }
        }
    );

    return response.data?.data;
  }
}
