import { Route } from 'react-router-dom';
import BeforeEachRoute from './beforeEach';

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, type: Type,settings,selectedModule, modules: Modules, ...rest }) => {
	return <Route
		{...rest}
		render={(props) => (
			<BeforeEachRoute layout={Layout} isAuthProtected={isAuthProtected} component={Component} componentProps={props} type={Type} modules={Modules} settings={settings} selectedModule={selectedModule} />
		)}
	/>
};

export default AppRoute;