import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import Microchip from "src/assets/images/microchip.png";
import { useHistory, useLocation } from 'react-router-dom';
import DateUtils from "src/services/utils/DateUtils";
import { successNotification, errorNotification } from "src/store/actions.js";

import { Button, Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import RiskMatrix from "src/modules/data-protection/components/RisksAndTasks/risks/matrix";

import CreateRiskModal from "src/modules/data-protection/pages/reporting/Components/RiskComponents/CreateRiskModal";

import AIRiskAssessmentModal from "src/modules/data-protection/components/RisksAndTasks/risks/ai/AssessmentModal";

import QuestionarService from 'src/components/Questionar/service.js';

import {
    EMPTY_LIST,
    INTERNATIONAL_DATE_FORMAT
} from "src/common/constants";

import {
    CHAT_BOT_DOMAIN,
    API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
    API_BASE_URL
} from 'src/modules/data-protection/constants';

import { AvForm } from "availity-reactstrap-validation";

import RiskAssessmentService from "src/modules/data-protection/apis/RiskAssessmentService";

import { formatTaskAndRiskId } from "src/modules/data-protection/constants/Common"

const RiskAssessment = (props) => {
  const { t, assetId, onRiskSelected ,organization } = props;
  const location = useLocation();
  const [ pageIndex, setPageIndex ] = useState(1);
  const [ pageSize, setPageSize ] = useState(12);
  const [ risks, setRisks ] = useState([]);
  const [ matrix, setMatrix ] = useState([]);
  const [ consequences, setConsequences ] = useState(null);
  const [ ratings, setRatings ] = useState([]);
  const [ likelihoods, setLikelihoods ] = useState(null);
  const [ currency, setCurrency ] = useState(null);
  const [ damages, setDamages ] = useState(null);
  const [ owners, setOwners ] = useState(null);
  const [ managers, setManagers ] = useState(null);
  const [ tableColors, setTableColors ] = useState(null);
  const [ categories, setCategories]= useState([]);
  const [ locations, setLocations ] = useState([]);
  const [ deleteRiskId, setDeleteRiskId ] = useState(false);

  const [createRiskModalStatus, setCreateRiskModalStatus] = useState(false);
  const [deleteRiskModalStatus, setDeleteRiskModalStatus] = useState(false);
  const [
    aiBasedRiskAssessmentModalStatus,
    setAiBasedRiskAssessmentModalStatus,
  ] = useState(false);

  const [searchFilters, setSearchFilters] = useState({
    rating: null,
    owner: null,
    manager: null,
  });

  const dateUtils = new DateUtils();

  const handleFetchRisksQuery = useQuery({
    queryKey: ["data-protection-fetch-risks", pageIndex, pageSize],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      let filtersToSend = {};

      for (const filterKey in searchFilters) {
        if (searchFilters[filterKey]) {
          filtersToSend[filterKey] = searchFilters[filterKey]?.value;
        }
      }

      return await service.fetchRisks(assetId, {
        ...filtersToSend,
        pageIndex: pageIndex,
        pageSize: pageSize,
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching risks."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksMatrix = useQuery({
    queryKey: ["data-protection-fetch-risks-matrix"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchSoftwareMatrix( assetId );
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching matrix."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksOwners = useQuery({
    queryKey: ["data-protection-fetch-risks-owners"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchOwners(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksManagers = useQuery({
    queryKey: ["data-protection-fetch-risks-managers"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchManagers(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching managers."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksCategories = useQuery({
    queryKey: ["data-protection-fetch-risks-categories"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchCategories(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching categories."), {
        type: "error",
      });
    },
  });

  const handleFetchRisksLocations = useQuery({
    queryKey: ["data-protection-fetch-risks-locations"],
    queryFn: async () => {
      const service = RiskAssessmentService.getInstance();

      return await service.fetchLocations(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching locations."), {
        type: "error",
      });
    },
  });

  const handleDeleteRiskMutation = useMutation({
    mutationFn: async (riskId) => {
      const service = RiskAssessmentService.getInstance();

      return await service.deleteRisk(assetId, riskId);
    },
    onSuccess: () => {
      setDeleteRiskModalStatus(false);
      handleFetchRisksQuery.refetch();
      handleFetchRisksMatrix.refetch();

      toast(t("Risk deleted successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to delete risk."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (handleFetchRisksQuery.data) {
      setRisks(handleFetchRisksQuery.data.risks);
    }
  }, [handleFetchRisksQuery.data]);

  useEffect(() => {
    if (handleFetchRisksMatrix.data) {
      setMatrix(handleFetchRisksMatrix.data.matrix);
      setConsequences(handleFetchRisksMatrix.data.consequences);
      setRatings(handleFetchRisksMatrix.data.ratings);
      setLikelihoods(handleFetchRisksMatrix.data.likelihoods);
      setCurrency(handleFetchRisksMatrix.data.currency);
      setDamages(
        handleFetchRisksMatrix.data.consequences.map((item) => {
          return {
            value: item.id,
            label:
              handleFetchRisksMatrix.data.currency +
              " " +
              Number(item.title).toLocaleString(
                localStorage.getItem("i18nextLng")
              ),
          };
        })
      );

      // calculating matrix colors for caption
      if (
        handleFetchRisksMatrix.data.matrix &&
        handleFetchRisksMatrix.data.ratings
      ) {
        const flattedMatrix = handleFetchRisksMatrix.data.matrix.flat();
        const items = [];

        for (const item of handleFetchRisksMatrix.data.ratings) {
          items.push({
            label: item.name,
            color: flattedMatrix.find((i) => i.name === item.name).color,
          });
        }

        setTableColors(items);
      }
    }
  }, [handleFetchRisksMatrix.data]);

  useEffect(() => {
    if (handleFetchRisksOwners.data) {
      setOwners(
        handleFetchRisksOwners.data.map((item) => {
          return {
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          };
        })
      );
    }
  }, [handleFetchRisksOwners.data]);

  useEffect(() => {
    if (handleFetchRisksOwners.data) {
      setOwners(
        handleFetchRisksOwners.data.map((item) => {
          return {
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          };
        })
      );
    }
  }, [handleFetchRisksOwners.data]);

  useEffect(() => {
    if (handleFetchRisksManagers.data) {
      setManagers(
        handleFetchRisksManagers.data.map((item) => {
          return {
            value: item.id,
            label: item.full_name,
          };
        })
      );
    }
  }, [handleFetchRisksManagers.data]);

  useEffect(() => {
    if (handleFetchRisksCategories.data) {
      setCategories(
        handleFetchRisksCategories.data.map((item) => {
          return {
            value: item.id,
            label: t(item.name),
            baseLabel: item.name,
            subCategories: item.sub_categories.map((sub) => {
              return {
                value: sub.id,
                baseLabel: sub.name,
                label: t(sub.name),
              };
            }),
          };
        })
      );
    }
  }, [handleFetchRisksCategories.data]);

  useEffect(() => {
    if (handleFetchRisksLocations.data) {
      setLocations(
        handleFetchRisksLocations.data.map((item) => {
          return {
            value: item.id,
            baseLabel: item.name,
            label: t(item.name),
          };
        })
      );
    }
  }, [handleFetchRisksLocations.data]);

  useEffect(() => {
    handleFetchRisksQuery.refetch();
  }, [searchFilters]);

  const isLoading = ((handleFetchRisksMatrix.isFetching || handleFetchRisksMatrix.isLoading) ||
  (handleFetchRisksOwners.isFetching || handleFetchRisksOwners.isLoading) ||
  (handleFetchRisksManagers.isFetching || handleFetchRisksManagers.isLoading) || 
  (handleFetchRisksQuery.isFetching || handleFetchRisksQuery.isLoading) || !props.user);


  const riskSelectedToShow = (id) => {
    const l = likelihoods
      ? likelihoods.map((l) => {
          return {
            value: l.id,
            label: l.title,
          };
        })
      : null;
    onRiskSelected &&
      onRiskSelected({
        riskId: id,
        categories: categories,
        locations: locations,
        damages: damages,
        owners: owners,
        currency: currency,
        likelihoods: l,
        authToken: props.token,
        t: t,
        successNotification: props.successNotification,
        errorNotification: props.errorNotification,
        reportIdKey: "software",
        reportId: assetId ?? id,
        fetchRisks: () => {
          handleFetchRisksQuery.refetch();
        },
        secondaryId: assetId ?? id,
        currentUser: props.user,
        tableColors: tableColors,
        handleSetReportRiskField: () => {},
        module: "gdpr",
        taskUrl:window?.location?.pathname+'?tab=task-management&taskId='
      });
  };

  useEffect(() => {
    const riskId = new URLSearchParams(location.search).get("riskId");
    
    if (riskId && locations?.length >0 && categories?.length >0  && damages && owners && currency && tableColors) {
      riskSelectedToShow(riskId);
    }
  }, [categories,locations,damages,owners,currency,tableColors,  location.search]);

    const makeQuestionReadyForAI = (questions) => {
        let result = [];

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];

            const questionResult = {
                question    :   question.title,
                answer      :   null
            };

            switch(question.type){
                case "checkbox":
                case "category":
                    questionResult.answer = (question?.answer || []).map((ch) => {
                        return ch?.title;
                    });
                    break;

                case "file_upload":
                        questionResult.answer = (question?.answer || []).length > 0 ? 'Yes' : 'No'
                    break;

                default :
                        questionResult.answer = (question?.answer || [])[0]?.title;
                    break;
            }

            result.push(questionResult);
        }

        return result;
    }


  const decodeHTMLEntities = (text) => {
    const parser = new DOMParser();
    const decodedText = parser.parseFromString(
      `<!doctype html><body>${text}`,
      "text/html"
    ).body.textContent;
    return decodedText;
  };

  const renderRiskMatrix = () => {
    return (
      <div className="mb-4">
      <RiskMatrix
          matrix={matrix}
          consequences={consequences}
          currency={currency}
          likelihoods={likelihoods}
          risks={null}
          ratings={ratings}
          isLoading={ handleFetchRisksMatrix.isFetching || handleFetchRisksMatrix.isLoading }
          onRiskSelectedToShow={(riskId) => {
            riskSelectedToShow(riskId);
          }}
      />
  </div>
    );
  };

  const renderModals = () => {
    const closeDeleteModal = () => {
        setDeleteRiskId(null);
        setDeleteRiskModalStatus(false);
    }

    return (
        <React.Fragment>
             <CreateRiskModal
          t={t}
          isOpen={createRiskModalStatus}
          close={() => setCreateRiskModalStatus(false)}
          successNotification={props.successNotification}
          errorNotification={props.errorNotification}
          authToken={props.token}
          reportId={assetId}
          reportIdKey={"software"}
          updateRisksList={() => {
            handleFetchRisksQuery.refetch();
            handleFetchRisksMatrix.refetch();
          }}
          currency={currency}
          categories={categories}
          locations={locations}
          damages={damages}
          likelihoods={
            likelihoods
              ? likelihoods.map((l) => {
                  return {
                    value: l.id,
                    label: l.title,
                  };
                    }) : null
          }
          owners={owners}
          handleSetReportRiskField={() => console.log("ok")}
          // module={ module }
          module={"gdpr"}
          aiBasedRiskAssessmentModalStatus={aiBasedRiskAssessmentModalStatus}
          setAiBasedRiskAssessmentModalStatus={
            setAiBasedRiskAssessmentModalStatus
          }
          reportLocation={null}
          reportCategories={[]}
          route={null}
          assetId={assetId}
        />
        
            <Modal size='lg' scrollable={true} isOpen={deleteRiskModalStatus} backdrop='static'>

                <ModalHeader toggle={closeDeleteModal}>
                    {t('Delete Risk')}
                </ModalHeader>

                <ModalBody>
                    <AvForm className='needs-validation'>
                        <Row>
                            <Label>
                                {t('Are you sure?')}
                            </Label>
                        </Row>

                        <ModalFooter>
                            <Button
                                color='danger'
                                className='waves-effect waves-light'
                                type='submit'
                                onClick={() => {
                                    handleDeleteRiskMutation.mutate(deleteRiskId);
                                }}>
                                    {t('Delete')}
                            </Button>

                            <Button color='secondary'
                                className='waves-effect waves-light'
                                type='button'
                                onClick={closeDeleteModal}>
                                    {t('Cancel')}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {
                aiBasedRiskAssessmentModalStatus && (
                    <AIRiskAssessmentModal
                        closeModal={ () => setAiBasedRiskAssessmentModalStatus(false) }
                        onRisksCreated={ () => {
                            handleFetchRisksQuery.refetch();
                            handleFetchRisksMatrix.refetch();
                        } }
                        isOpen={ aiBasedRiskAssessmentModalStatus }
                        translateResult={ true }
                        aiRequestOptions={{
                            requestUrl      :   `${CHAT_BOT_DOMAIN}api/gdpr/risks-associated-software-asset.ai`,
                            payload    :   {
                                module          :   'GDPR_SOFTWARE_ASSET',
                                myLocation      :   organization.countryId,
                                organizationId  :   organization.id,
                                // software        :    assetId,
                                // typeAsset       :   'software',
                                // reportCaseId    :   assetId,
                                //questions       :   makeQuestionReadyForAI([]),
                                asset           :   assetId,
                                // regenerate_risk_ai  :   undefined
                            }
                        }}
                        aiTranlateRequestOptions={{
                            requestUrl      :   `${CHAT_BOT_DOMAIN}api/gdpr/risks-associated-questioner-translate.ai`,
                            payload         :   {
                                module          : "GDPR_SOFTWARE_ASSET",
                                organizationId  : organization.id,
                                assetId    : assetId
                            }
                        }}
                        additionnalDetailsRequestOptions={{
                            requestUrl  :   API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
                            payload     :   {}
                        }}
                        createRisksRequestOptions={{
                            requestUrl      :   `${API_BASE_URL}/risk_rate/create_ai`,
                            payload         :   {software        :    assetId}
                        }}>
                        {(result) => {
                            return (
                                <Modal className="ai-risk-result-modal mw-100" style={{width: '90%'}} isOpen={aiBasedRiskAssessmentModalStatus} size="xl">
                                    <ModalHeader toggle={() => setAiBasedRiskAssessmentModalStatus(!aiBasedRiskAssessmentModalStatus)}>
                                        {t('Risk Assesment Support by AI')}
                                    </ModalHeader>

                                    <ModalBody>
                                        <result.type {...result.props} />
                                    </ModalBody>
                                </Modal>
                            )
                        }}
                    </AIRiskAssessmentModal>
                )
            }
        </React.Fragment>
    );
}

const renderFilters = () => {
  return (
      <Row className="d-flex">
          {/* ratings */}
          <Col sm="12" md="3" lg="2" className="mb-2">
              {!isLoading ? (
                  <Select
                      classNamePrefix="select2-selection"
                      options={ratings.map((r) => {
                          return {
                              value: r.name,
                              label: t(r.name),
                          };
                      })}
                      value={searchFilters.rating}
                      isClearable={true}
                      onChange={(e) => {
                          setSearchFilters((filters) => {
                              return {
                                  ...filters,
                                  rating  :   e
                              }
                          })
                      }}
                      placeholder={t("Risk rating")}
                  />
              ) : (
                  <div className="dt-field dt-skeleton dt-select-list" style={{ marginBottom: 16 }}></div>
              )}
          </Col>

          {/* owners */}
          <Col sm="12" md="3" lg="3" className="mb-2 ">
              {!isLoading ? (
                  <Select
                      classNamePrefix="select2-selection"
                      options={owners}
                      value={searchFilters.owner}
                      isClearable={true}
                      onChange={(e) => {
                          setSearchFilters((filters) => {
                              return {
                                  ...filters,
                                  owner  :   e
                              }
                          })
                      }}
                      placeholder={t("Risk owner")}
                  />
              ) : (
                  <div className="dt-field dt-skeleton dt-select-list" style={{ marginBottom: 16 }}></div>
              )}
          </Col>

          {/* managers */}
          <Col sm="12" md="3" lg="3" className="mb-2">
              {!isLoading ? (
                  <Select
                      classNamePrefix="select2-selection"
                      options={managers}
                      value={searchFilters.manager}
                      isClearable={true}
                      onChange={(e) => {
                          setSearchFilters((filters) => {
                              return {
                                  ...filters,
                                  manager  :   e
                              }
                          })
                      }}
                      placeholder={t("Risk Manager")}
                  />
          ) : (
              <div className="dt-field dt-skeleton dt-select-list" style={{ marginBottom: 16 }}></div>
          )}
          </Col>

          <Col sm="12" md="3" lg="3" className="mb-3">
              <Button color="primary"
                  className="me-2 "
                  onClick={() => {
                      setCreateRiskModalStatus(true);
                  }}
                  outline>
                      {t("Create Risk")}
              </Button>
              
              <Button color="primary" className="me-2 ai-base-risk-assessment-button"
                  onClick={ () => setAiBasedRiskAssessmentModalStatus(true) }
                  outline>
                      <img alt="ai icon" src={Microchip} />
                      {t("Get help from AI")}
              </Button>
          </Col>
      </Row>
  );
}

const renderRisksTable = () => {
  return (
      <Row>
          <Col sm="12">
              <table className="dt-list dt-risks-list">
                  <thead>
                      <tr>
                          {/* rating */}
                          <td style={{ width: "6%" }}>{t("Rating")}</td>

                          {/* id */}
                          <td style={{ width: "10%" }}>{t("Risk ID")}</td>

                          {/* description */}
                          <td style={{ width: '15%' }}>{t('Description')}</td>

                          {/* owner */}
                          <td style={{ width: '18%' }}>{t('Risk owner')}</td>
                          
                          {/* manager */}
                          <td style={{ width: '18%' }}>{t('Risk Manager')}</td>

                          {/* risks */}
                          <td style={{ width: '18%' }}>{t('Mitigation')}</td>

                          {/* date */}
                          <td style={{ width: '9%' }}>{t('Creation Date')}</td>

                          {/* actions */}
                          <td style={{ width: "10%" }}></td>
                      </tr>
                  </thead>

                  <tbody>
                      {!isLoading ? (
                          risks.length > 0 ? (
                              risks.map((r) => {
                                  return (
                                      <tr key={r.id}>
                                          <td style={{ width: "6%" }}>
                                              <div>
                                                  <span className="dt-risk-rating-bullet"
                                                      style={{
                                                          backgroundColor: `#${r.result_color}`,
                                                          opacity: r.is_deleted ? 0.15 : 1,
                                                          cursor: !r.is_deleted
                                                              ? "pointer"
                                                              : "default",
                                                      }}
                                                      onClick={() => !r.is_deleted && riskSelectedToShow(r.id) }
                                                      ></span>
                                              </div>
                                          </td>
                                          
                                          <td style={{ width: "10%" }}>
                                              <div className="dt-list-col-result-id"
                                                  style={{
                                                      cursor: !r.is_deleted
                                                      ? "pointer"
                                                      : "default",
                                                  }}
                                                  onClick={() => !r.is_deleted && riskSelectedToShow(r.id) }
                                                  >
                                                  
                                                 {formatTaskAndRiskId('risk',r?.questionnaire === "software" ? r?.asset_id : r?.case_secondary_id, r.secondary_id, r?.is_mitigation, r.questionnaire, null )}
                                              </div>
                                          </td>

                                          <td style={{ width: '15%' }}>
                                              <div className="dt-list-col-result-description"
                                                  style={{
                                                      cursor: !r.is_deleted
                                                      ? "pointer"
                                                      : "default",
                                                  }}
                                                  onClick={() => !r.is_deleted && riskSelectedToShow(r.id) }
                                                  >
                                                  { decodeHTMLEntities(r.description).replace(/<[^>]+>/g, "") }
                                              </div>
                                          </td>

                                          <td style={{ width: '18%' }}>
                                              {r.owner_name ? (
                                                  <div>
                                                      <span className="dt-list-col-bullet">{r.owner_name[0]}</span>{" "}
                                                      <span className="dt-list-col-bullet-text">
                                                          {r.owner_name}
                                                      </span>
                                                  </div>
                                              ) : null}
                                          </td>

                                          <td style={{ width: '18%' }}>
                                              <div>
                                                  {r.manager ? (
                                                      <>
                                                          <span className="dt-list-col-bullet">
                                                              {r.manager[0]}
                                                          </span>{" "}
                                                          <span className="dt-list-col-bullet-text">
                                                              {r.manager}
                                                          </span>
                                                      </>
                                                  ) : null}
                                              </div>
                                          </td>

                                          <td style={{ width: '18%' }}>
                                              <div>
                                                  {r.tasks && r.tasks.length ? (
                                                      r.tasks.map((t, i) => {
                                                          return (
                                                              <Link to={`/admin/data-protection/softwares/${assetId}?tab=task-management&taskId=${t.id}`}>
                                                                  {`T${t.secondary_id}`}
                                                                  <span hidden={i === r.tasks.length - 1}>{', '}</span>
                                                              </Link>
                                                          );
                                                      })
                                                  ) : (
                                                      null
                                                  )}
                                              </div>
                                          </td>

                                          <td style={{ width: '9%' }}>
                                              <div>
                                                  {dateUtils.convertTimeStampToDate(
                                                      r.created_at,
                                                      INTERNATIONAL_DATE_FORMAT
                                                  )}
                                              </div>
                                          </td>

                                          <td style={{ width: "10%" }}>
                                              {!r.is_deleted ? (
                                                  <div style={{
                                                          fontSize: 20,
                                                          textAlign: "center",
                                                          display: "block",
                                                          cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                          setDeleteRiskId(r.id);
                                                          setDeleteRiskModalStatus(true);
                                                      }}>
                                                      <i className="ri-delete-bin-line text-danger"></i>
                                                  </div>
                                              ) : (
                                                  <div style={{
                                                          fontWeight: 300,
                                                          textAlign: "center",
                                                          display: "block",
                                                          color: "red",
                                                      }}>
                                                      {t("DELETED")}
                                                  </div>
                                              )}
                                          </td>
                                      </tr>
                                  );
                              })
                          ) : (
                              <tr>
                                  <td colSpan={8}>
                                      <div className="alert alert-warning" role="alert">
                                          <p style={{ 
                                                  textAlign: "center",
                                                  marginBottom: 0,
                                                  width: "100%",
                                              }}>
                                              {t(EMPTY_LIST)}
                                          </p>
                                      </div>
                                  </td>
                              </tr>
                          )
                      ) : (
                      <>
                          <tr>
                              <td style={{ width: "6%" }}>
                                  <div>
                                      <span className="dt-risk-rating-bullet dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: "6%" }}>
                                  <div>
                                      <p className="dt-list-col-result-id dt-skeleton"></p>
                                  </div>
                              </td>

                              <td style={{ width: '15%' }}>
                                  <div>
                                      <p className="dt-list-col-result-id dt-skeleton"></p>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <span className="dt-list-col-bullet dt-skeleton"></span>
                                      <span className="dt-list-col-bullet-text dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <span className="dt-list-col-bullet dt-skeleton"></span>
                                      <span className="dt-list-col-bullet-text dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <p className='dt-list-col-result-id dt-skeleton'></p>
                                  </div>
                              </td>

                              <td style={{ width: '9%' }}>
                                  <div>
                                      <p className="dt-list-col-date dt-skeleton"></p>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td style={{ width: "6%" }}>
                                  <div>
                                      <span className="dt-risk-rating-bullet dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: "6%" }}>
                                  <div>
                                  <p className="dt-list-col-result-id dt-skeleton"></p>
                                  </div>
                              </td>

                              <td style={{ width: '15%' }}>
                                  <div>
                                      <p className="dt-list-col-result-id dt-skeleton"></p>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <span className="dt-list-col-bullet dt-skeleton"></span>
                                      <span className="dt-list-col-bullet-text dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <span className="dt-list-col-bullet dt-skeleton"></span>
                                      <span className="dt-list-col-bullet-text dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <p className='dt-list-col-result-id dt-skeleton'></p>
                                  </div>
                              </td>

                              <td style={{ width: '9%' }}>
                                  <div>
                                      <p className="dt-list-col-date dt-skeleton"></p>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td style={{ width: "6%" }}>
                                  <div>
                                      <span className="dt-risk-rating-bullet dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: "6%" }}>
                                  <div>
                                      <p className="dt-list-col-result-id dt-skeleton"></p>
                                  </div>
                              </td>

                              <td style={{ width: '15%' }}>
                                  <div>
                                      <p className="dt-list-col-result-id dt-skeleton"></p>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <span className="dt-list-col-bullet dt-skeleton"></span>
                                      <span className="dt-list-col-bullet-text dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <span className="dt-list-col-bullet dt-skeleton"></span>
                                      <span className="dt-list-col-bullet-text dt-skeleton"></span>
                                  </div>
                              </td>

                              <td style={{ width: '18%' }}>
                                  <div>
                                      <p className='dt-list-col-result-id dt-skeleton'></p>
                                  </div>
                              </td>

                              <td style={{ width: '9%' }}>
                                  <div>
                                      <p className="dt-list-col-date dt-skeleton"></p>
                                  </div>
                              </td>
                          </tr>
                      </>
                      )}
                  </tbody>
              </table>
          </Col>
      </Row>
  )
}
  return (
    <div className="p-4 d-flex flex-column gap-4">
      {renderModals()}
      <Row>
        <Col sm="12">{renderRiskMatrix()}</Col>

        <Col sm="12" className="mt-4 mb-4">
          <Container>
            {renderFilters()}

            {renderRisksTable()}
          </Container>
        </Col>
      </Row>
      </div> 
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(memo(RiskAssessment))
  )
);
