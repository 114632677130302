const AUTH_DOMAIN = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;
const AI_DOMAIN = process.env.REACT_APP_AI + "api/";
const RISK_AI_DOMAIN = process.env.RISK_AI_DOMAIN;
const AUTH_API_DOMAIN = AUTH_DOMAIN + "api/";
const SOFTWARE_AI_API_DOMAIN = process.env.SOFTWARE_GENERATE_BY_AI +"api/";
const GDPR_DOMAIN = `${process.env.REACT_APP_GDPR_API}/api/`;

export const GDPR_DOMAIN_URL = GDPR_DOMAIN;
export const AUTH_API_DOMAIN_URL = AUTH_API_DOMAIN;
export const API_URL_SIGN_IN = AUTH_API_DOMAIN + "auth.signin";
export const API_URL_LOG_OUT = AUTH_API_DOMAIN + "auth.signout";
export const API_URL_REGISTER = AUTH_API_DOMAIN + "register";
export const API_URL_CREATE_THIRD_PARTY_ACCOUNT = GDPR_DOMAIN + "thirdParty.register";
export const API_URL_GET_USER_DETAIL = AUTH_API_DOMAIN + "auth.current";
export const CM_API_URL_GET_USER_DETAIL = AUTH_API_DOMAIN + "cm/auth.current";



export const API_URL_REPORT_THIRD_PARTY_CATEGORY_LIST = GDPR_DOMAIN + "thirdParty.category.list";
export const GDPR_API_URL_REPORT_THIRD_PARTY_CATEGORY_LIST = GDPR_DOMAIN + "thirdParty.category.list";

export const API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION = GDPR_DOMAIN + "thirdParty.questions.list";
export const GDPR_API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION = GDPR_DOMAIN + "thirdParty.questions.list";
export const CM_API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION = GDPR_DOMAIN + "cm/thirdParty.questions.list";

export const API_URL_SET_PASSWORD = AUTH_API_DOMAIN + "user.setPassword";
export const API_URL_CHECK_PASSWORD_TOKEN =
  AUTH_API_DOMAIN + "user.verifyPasswordToken";
export const API_URL_VERIFY_OTP = AUTH_API_DOMAIN + "auth.exchangeToken";
export const API_URL_RESEND_OTP = AUTH_API_DOMAIN + "auth.resendOtp";

export const API_URL_REQUEST_RESET_PASSWORD =
  AUTH_API_DOMAIN + "user.forgetPassword";
export const API_URL_EXCHANGE_RESET_PASSWORD_TOKEN =
  AUTH_API_DOMAIN + "user.exchangeResetPasswordToken";
export const API_URL_VERIFY_RESET_PASSWORD_TOKEN =
  AUTH_API_DOMAIN + "user.verifyResetPasswordToken";
export const API_URL_RESET_PASSWORD = AUTH_API_DOMAIN + "user.resetPassword";
export const API_URL_CHANGE_LANGUAGE = AUTH_API_DOMAIN + "user.switchLanguage";
export const API_URL_CHANGE_PASSWORD = AUTH_API_DOMAIN + "user.changePassword";

export const API_URL_AZURE_LOGIN = GDPR_DOMAIN + "azure_login";
export const API_URL_AZURE_CALLBACK = GDPR_DOMAIN + "azure_callback";

// for testing purpose
export const API_URL_TRANSLATION_LIST = process.env.REACT_APP_TRANSLATION_API;

// Report routes
export const API_URL_CREATE_REPORT = GDPR_DOMAIN + "report.create";
export const GDPR_API_URL_CREATE_REPORT = GDPR_DOMAIN + "report.create";
export const CM_API_URL_CREATE_REPORT = GDPR_DOMAIN + "cm/report.create";

export const API_URL_GET_ANALYST_REPORT_LIST = GDPR_DOMAIN + "report.list";
export const GDPR_API_URL_GET_ANALYST_REPORT_LIST = GDPR_DOMAIN + "report.list";
export const CM_API_URL_GET_ANALYST_REPORT_LIST = GDPR_DOMAIN + "cm/report.list";

export const API_URL_GET_ANALYST_REPORT_LISTS_COUNT = GDPR_DOMAIN + "reports/count";
export const GDPR_API_URL_GET_ANALYST_REPORT_LISTS_COUNT = GDPR_DOMAIN + "reports/count";
export const CM_API_URL_GET_ANALYST_REPORT_LISTS_COUNT = GDPR_DOMAIN + "cm/reports/count";

export const API_URL_GET_REPORT_DELETED_LIST = GDPR_DOMAIN + "report.listDeletedCases";
export const GDPR_API_URL_GET_REPORT_DELETED_LIST = GDPR_DOMAIN + "report.listDeletedCases";
export const GDPR_API_URL_GET_INCIDENT_REPORT_LISTS_COUNT = GDPR_DOMAIN + "reports/count";


export const API_URL_GET_REPORT_LIST = GDPR_DOMAIN + "report.listMyCases";
export const GDPR_API_URL_GET_REPORT_LIST = GDPR_DOMAIN + "report.listMyCases";
export const CM_API_URL_GET_REPORT_LIST =GDPR_DOMAIN + "cm/report.listMyCases";

export const API_URL_GET_REPORT_DETAIL = GDPR_DOMAIN + "report.view";
export const GDPR_API_URL_GET_REPORT_DETAIL = GDPR_DOMAIN + "report.view"
export const CM_API_URL_GET_REPORT_DETAIL = GDPR_DOMAIN + "cm/report.view";

export const API_URL_REPORT_UPLOAD_FILE =  GDPR_DOMAIN + "report.uploadAttachment";
export const GDPR_API_URL_REPORT_UPLOAD_FILE = GDPR_DOMAIN + "report.uploadAttachment";
export const CM_API_URL_REPORT_UPLOAD_FILE = GDPR_DOMAIN + "cm/report.uploadAttachment";

export const API_URL_REPORT_UPLOAD_MULTIPLE_FILE =  GDPR_DOMAIN + "report.uploadAttachments";
export const GDPR_API_URL_REPORT_UPLOAD_MULTIPLE_FILE = GDPR_DOMAIN + "report.uploadAttachments";
export const CM_API_URL_REPORT_UPLOAD_MULTIPLE_FILE = GDPR_DOMAIN + "cm/report.uploadAttachments";

export const API_URL_ADD_COMMENT = GDPR_DOMAIN + "report.addComment";
export const GDPR_API_URL_ADD_COMMENT = GDPR_DOMAIN + "report.addComment";
export const CM_API_URL_ADD_COMMENT = GDPR_DOMAIN + "cm/report.addComment";

export const API_URL_ADD_INTERNAL_COMMENT = GDPR_DOMAIN + "report.addCommentInternal";
export const GDPR_API_URL_ADD_INTERNAL_COMMENT = GDPR_DOMAIN + "report.addCommentInternal";
export const CM_API_URL_ADD_INTERNAL_COMMENT =  GDPR_DOMAIN + "cm/report.addCommentInternal";

export const API_URL_GET_COMMENT_LIST = GDPR_DOMAIN + "report.listComment";
export const GDPR_API_URL_GET_COMMENT_LIST = GDPR_DOMAIN + "report.listComment";
export const CM_API_URL_GET_COMMENT_LIST =  GDPR_DOMAIN + "cm/report.listComment";

export const API_URL_GET_INTERNAL_COMMENT_LIST = GDPR_DOMAIN + "report.listCommentInternal";
export const GDPR_API_URL_GET_INTERNAL_COMMENT_LIST = GDPR_DOMAIN + "report.listCommentInternal";
export const CM_API_URL_GET_INTERNAL_COMMENT_LIST = GDPR_DOMAIN + "cm/report.listCommentInternal";

// export const API_URL_REPORT_LINK_CASE = AUTH_API_DOMAIN + "report.linkCase";
export const API_URL_REPORT_ADD_LINK_CASE = GDPR_DOMAIN + "report/add_link_case";
export const GDPR_API_URL_REPORT_ADD_LINK_CASE = GDPR_DOMAIN + "report/add_link_case";
export const CM_API_URL_REPORT_ADD_LINK_CASE = GDPR_DOMAIN + "cm/report/add_link_case";


export const API_URL_REPORT_REMOVE_LINK_CASE = GDPR_DOMAIN + "report/remove_link_case";
export const GDPR_API_URL_REPORT_REMOVE_LINK_CASE = GDPR_DOMAIN + "report/remove_link_case";
export const CM_API_URL_REPORT_REMOVE_LINK_CASE = GDPR_DOMAIN + "cm/report/remove_link_case";

// export const CM_API_URL_REPORT_LINK_CASE =
//   AUTH_API_DOMAIN + "cm/report.linkCase";

export const API_URL_UPDATE_REPORT_STATUS = GDPR_DOMAIN + "report.updateStatus";
export const GDPR_API_URL_UPDATE_REPORT_STATUS = GDPR_DOMAIN + "report.updateStatus";
export const CM_API_URL_UPDATE_REPORT_STATUS = GDPR_DOMAIN + "cm/report.updateStatus";

export const API_URL_REPORT_ASSIGNED_ANALYST = GDPR_DOMAIN + "report.changeAnalyst";
export const GDPR_API_URL_REPORT_ASSIGNED_ANALYST = GDPR_DOMAIN + "report.changeAnalyst";
export const CM_API_URL_REPORT_ASSIGNED_ANALYST = GDPR_DOMAIN + "cm/report.changeAnalyst";

export const API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS = GDPR_DOMAIN + "report.downloadUserAttachment";
export const GDPR_API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS = GDPR_DOMAIN + "report.downloadUserAttachment";

export const API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS = GDPR_DOMAIN + "report.downloadAnalystAttachment";
export const GDPR_API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS = GDPR_DOMAIN + "report.downloadAnalystAttachment";

export const API_URL_REPORT_CONFIRM_OPEN = GDPR_DOMAIN + "report.markAsRead";
export const GDPR_API_URL_REPORT_CONFIRM_OPEN = GDPR_DOMAIN + "report.markAsRead";

export const API_URL_REPORT_MARK_AS_DELETE = GDPR_DOMAIN + "report.markAsDelete";
export const GDPR_API_URL_REPORT_MARK_AS_DELETE = GDPR_DOMAIN + "report.markAsDelete";
export const CM_API_URL_REPORT_MARK_AS_DELETE = GDPR_DOMAIN + "cm/report.markAsDelete";

export const API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION = GDPR_DOMAIN + "report.changeAdminDescription";
export const GDPR_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION = GDPR_DOMAIN + "report.changeAdminDescription";
export const CM_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION = GDPR_DOMAIN + "cm/report.changeAdminDescription";

export const API_URL_REPORT_UPDATE_ADMIN_CATEGORIES = GDPR_DOMAIN + "report.changeAdminCategory";
export const GDPR_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES = GDPR_DOMAIN + "report.changeAdminCategory";
export const CM_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES = GDPR_DOMAIN + "cm/report.changeAdminCategory";

export const API_URL_REPORT_DELETE = GDPR_DOMAIN + "report.delete";
export const GDPR_API_URL_REPORT_DELETE = GDPR_DOMAIN + "report.delete";
export const CM_API_URL_REPORT_DELETE = GDPR_DOMAIN + "cm/report.delete";

export const API_URL_REPORT_CATEGORY_LIST = GDPR_DOMAIN + "report-category.list";
export const GDPR_API_URL_REPORT_CATEGORY_LIST = GDPR_DOMAIN + "report-category.list";
export const CM_API_URL_REPORT_CATEGORY_LIST = GDPR_DOMAIN + "report-category.list";

export const API_URL_REPORT_CATEGORY_LIST_CASE = GDPR_DOMAIN + "report-category-case.list";
export const GDPR_API_URL_REPORT_CATEGORY_LIST_CASE = GDPR_DOMAIN + "report-category-case.list";
export const CM_API_URL_REPORT_CATEGORY_LIST_CASE = GDPR_DOMAIN + "cm/report-category-case.list";

export const API_URL_UPDATE_REPORT_RISK_RATE = GDPR_DOMAIN + "report.updateRiskRate";
export const GDPR_API_URL_UPDATE_REPORT_RISK_RATE = GDPR_DOMAIN + "report.updateRiskRate";
export const CM_API_URL_UPDATE_REPORT_RISK_RATE = GDPR_DOMAIN + "cm/report.updateRiskRate";

export const API_URL_REPORT_TRANSLATOR_CONTECNT = GDPR_DOMAIN + "translate-content.json";
export const GDPR_API_URL_REPORT_TRANSLATOR_CONTECNT = GDPR_DOMAIN + "translate-content.json";
export const CM_API_URL_REPORT_TRANSLATOR_CONTECNT = GDPR_DOMAIN + "cm/translate-content.json";

export const API_URL_LANGUAGE_ORGANIZATION = AUTH_API_DOMAIN + "language.organization.list";
export const API_URL_UPDATE_DESCRIPTION = AUTH_API_DOMAIN + "voice_report/edit_description";
export const API_URL_ANALYST_LIST = AUTH_API_DOMAIN + "analyst.list";
export const API_URL_GENERATE_RANDOM_USERNAME =
GDPR_DOMAIN + "generateUsername?language=";

export const API_URL_REPORT_INTRNAL_COMMENTS_SEARCH = GDPR_DOMAIN + "report.mentionCommentSearch?query";
export const GDPR_API_URL_REPORT_INTRNAL_COMMENTS_SEARCH = GDPR_DOMAIN + "report.mentionCommentSearch?query";
export const CM_API_URL_REPORT_INTRNAL_COMMENTS_SEARCH = GDPR_DOMAIN + "cm/report.mentionCommentSearch?query";

// Risk Assesment
export const API_URL_FETCH_RISK_MATRIX = GDPR_DOMAIN + "risk_rate/matrix";
export const GDPR_API_URL_FETCH_RISK_MATRIX = GDPR_DOMAIN + "risk_rate/matrix";
export const CM_API_URL_FETCH_RISK_MATRIX = GDPR_DOMAIN + "cm/risk_rate/matrix";

export const API_URL_FETCH_RISK_RATINGS = GDPR_DOMAIN + "risk_rate/ratings";
export const GDPR_API_URL_FETCH_RISK_RATINGS = GDPR_DOMAIN + "risk_rate/ratings";
export const CM_API_URL_FETCH_RISK_RATINGS = GDPR_DOMAIN + "cm/risk_rate/ratings";

export const API_URL_FETCH_RISK_CATEGORIES = GDPR_DOMAIN + "risk_rate_category/index";
export const GDPR_API_URL_FETCH_RISK_CATEGORIES = GDPR_DOMAIN + "risk_rate_category/index";
export const CM_API_URL_FETCH_RISK_CATEGORIES = GDPR_DOMAIN + "cm/risk_rate_category/index";

export const API_URL_FETCH_RISK_LIKELIHOODS = GDPR_DOMAIN + "risk_rate/likelihoods";
export const GDPR_API_URL_FETCH_RISK_LIKELIHOODS = GDPR_DOMAIN + "risk_rate/likelihoods";
export const CM_API_URL_FETCH_RISK_LIKELIHOODS = GDPR_DOMAIN + "risk_rate/likelihoods";

export const API_URL_FETCH_RISK_LEGAL_ENTETIES = GDPR_DOMAIN + "legal_entity/index";
export const GDPR_API_URL_FETCH_RISK_LEGAL_ENTETIES = GDPR_DOMAIN + "legal_entity/index"; 

export const API_URL_CREATE_RISK = GDPR_DOMAIN + "risk_rate/create";
export const GDPR_API_URL_CREATE_RISK = GDPR_DOMAIN + "risk_rate/create";
export const CM_API_URL_CREATE_RISK = GDPR_DOMAIN + "cm/risk_rate/create";

export const API_URL_RISK_UPLOAD_FILE = GDPR_DOMAIN + "risk_rate/upload_file";
export const GDPR_API_URL_RISK_UPLOAD_FILE = GDPR_DOMAIN + "risk_rate/upload_file";
export const CM_API_URL_RISK_UPLOAD_FILE = GDPR_DOMAIN + "cm/risk_rate/upload_file";

export const API_URL_FETCH_RISK_LOCATIONS = GDPR_DOMAIN + "report.country.list";
export const GDPR_API_URL_FETCH_RISK_LOCATIONS = GDPR_DOMAIN + "report.country.list";
export const CM_API_URL_FETCH_RISK_LOCATIONS = GDPR_DOMAIN + "cm/report.country.list";

export const API_URL_RISK_UPLOAD_FILES = GDPR_DOMAIN + "risk_rate/upload_files";
export const GDPR_API_URL_RISK_UPLOAD_FILES = GDPR_DOMAIN + "risk_rate/upload_files";
export const CM_API_URL_RISK_UPLOAD_FILES =  GDPR_DOMAIN + "cm/risk_rate/upload_files";

export const API_URL_FETCH_MANAGERS = GDPR_DOMAIN + "manager.list";
export const GDPR_API_URL_FETCH_MANAGERS = GDPR_DOMAIN + "manager.list";
export const CM_API_URL_FETCH_MANAGERS = GDPR_DOMAIN + "cm/manager.list";

export const API_URL_FETCH_DAMAGES = GDPR_DOMAIN + "risk_rate/consequences";
export const GDPR_API_URL_FETCH_DAMAGES = GDPR_DOMAIN + "risk_rate/consequences";

export const API_URL_RISK_COSTOMIZATION = GDPR_DOMAIN + "risk_rate/customization";
export const GDPR_API_URL_RISK_COSTOMIZATION = GDPR_DOMAIN + "risk_rate/customization";
export const CM_API_URL_RISK_COSTOMIZATION = GDPR_DOMAIN + "risk_rate/customization";

export const API_URL_RISK_RESTORE_COSTOMIZATION = GDPR_DOMAIN + "risk_rate/customization_restore";
export const GDPR_API_URL_RISK_RESTORE_COSTOMIZATION = GDPR_DOMAIN + "risk_rate/customization_restore";
export const CM_API_URL_RISK_RESTORE_COSTOMIZATION = GDPR_DOMAIN + "risk_rate/customization_restore";
// export const API_URL_RISK_POST_COMMENT =
// AUTH_API_DOMAIN + "task_comment/create";

// Task Manager
export const API_URL_GET_TASK_MANAGER = GDPR_DOMAIN + "tasks";
export const GDPR_API_URL_GET_TASK_MANAGER = GDPR_DOMAIN + "tasks";

export const API_URL_BASE_TASK_MANAGER = GDPR_DOMAIN + "task";
export const GDPR_API_URL_BASE_TASK_MANAGER = GDPR_DOMAIN + "task";

export const API_URL_TASK_UPLOAD_FILES = GDPR_DOMAIN + "task/upload_files";
export const GDPR_API_URL_TASK_UPLOAD_FILES = GDPR_DOMAIN + "task/upload_files";
export const CM_API_URL_TASK_UPLOAD_FILES = GDPR_DOMAIN + "cm/task/upload_files";

export const API_URL_TASK_UPLOAD_FILE = GDPR_DOMAIN + "task/upload_file";
export const GDPR_API_URL_TASK_UPLOAD_FILE = GDPR_DOMAIN + "task/upload_file";
export const CM_API_URL_TASK_UPLOAD_FILE = GDPR_DOMAIN + "cm/task/upload_file";

export const API_URL_TASK_POST_COMMENT = GDPR_DOMAIN + "task_comment/create";
export const GDPR_API_URL_TASK_POST_COMMENT = GDPR_DOMAIN + "task_comment/create";
export const CM_API_URL_TASK_POST_COMMENT = GDPR_DOMAIN + "cm/task_comment/create";

export const API_URL_TASK_GET_COMMENTS = GDPR_DOMAIN;
export const API_URL_TASK_DOWNLOAD_COMMENT_FILES = GDPR_DOMAIN;
export const API_URL_TASK_DETAILS = GDPR_DOMAIN;

export const API_URL_GET_KANBAN_BOARD = GDPR_DOMAIN + "task/kanban_board";
export const GDPR_API_URL_GET_KANBAN_BOARD = GDPR_DOMAIN + "task/kanban_board";
export const CM_API_URL_GET_KANBAN_BOARD = GDPR_DOMAIN + "cm/task/kanban_board";

// Organization
export const API_URL_VERIFY_ORGANIZATION =
AUTH_API_DOMAIN + "organization.verify";
export const API_URL_GDPR_VERIFY_ORGANIZATION =
GDPR_DOMAIN + "organization.verify";
export const API_URL_EDIT_INFO_ORGANIZATION =
  AUTH_API_DOMAIN + "organization.edit";
export const API_URL_GDPR_EDIT_INFO_ORGANIZATION =
  GDPR_DOMAIN + "organization.edit";
export const API_URL_GET_INFO_ORGANIZATION =
  AUTH_API_DOMAIN + "organization.info";
export const API_URL_GDPR_GET_INFO_ORGANIZATION =
GDPR_DOMAIN + "organization.info";

// QuestionnaireDesigner
export const API_URL_QUESTION_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "questions.list";
export const GDPR_API_URL_QUESTION_FORM_ORGANIZATION =
GDPR_DOMAIN + "questions.list";
export const CM_API_URL_QUESTION_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "cm/questions.list";

export const API_URL_QUESTION_DETAILS_FORM_ORGANIZATION = GDPR_DOMAIN + "questions-details.list";

export const CM_API_URL_QUESTION_DETAILS_FORM_ORGANIZATION =
  AUTH_API_DOMAIN + "cm/questions-details.list";

export const API_URL_QUESTION_GENERATOR_GET_LIST =
  AUTH_API_DOMAIN + "questions-analyst-admin.list";
export const CM_API_URL_QUESTION_GENERATOR_GET_LIST =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.list";
export const API_URL_QUESTION_GENERATOR_UPDATE_LIST =
  AUTH_API_DOMAIN + "questions-analyst-admin.add";
export const CM_API_URL_QUESTION_GENERATOR_UPDATE_LIST =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.add";
export const API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS =
  AUTH_API_DOMAIN + "questions-analyst-admin.remove";
export const CM_API_URL_QUESTION_GENERATOR_DELETE_QUESTIONS =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.remove";
export const API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS =
  AUTH_API_DOMAIN + "questions-analyst-admin.subquestions.remove";
export const CM_API_URL_QUESTION_GENERATOR_DELETE_SUB_QUESTIONS =
  AUTH_API_DOMAIN + "cm/questions-analyst-admin.subquestions.remove";

// Admin User Management
export const API_URL_ADMIN_GET_USER_LOGS =
  AUTH_API_DOMAIN + "employee.edit.log";
export const API_URL_ADMIN_GET_USER_MANAGEMENT_LIST =
  AUTH_API_DOMAIN + "employee.list";
export const API_URL_PARTNER_GET_CLIENT_MANAGEMENT_LIST =
  AUTH_API_DOMAIN + "partner.clients.list";
export const API_URL_ADMIN_GET_USER_MANAGEMENT_DETAIL =
  AUTH_API_DOMAIN + "employee.detail";
export const API_URL_ADMIN_UPDATE_USER_MANAGEMENT =
  // AUTH_API_DOMAIN + "employee.edit";
  AUTH_API_DOMAIN + "profile/edit";
export const API_URL_ADMIN_CREATE_USER_MANAGEMENT =
  AUTH_API_DOMAIN + "employee.create";
export const API_URL_ADMIN_DELETE_USER_MANAGEMENT =
  AUTH_API_DOMAIN + "employee.delete";
export const API_URL_ACTIVE_DIRECTORY =
  AUTH_API_DOMAIN + "organization/active_directory/edit";
export const API_URL_ACTIVE_DIRECTORY_FETCH =
  AUTH_API_DOMAIN + "organization/active_directory/index";

// Chat Live
export const API_URL_GET_STARTED_CHAT = AUTH_API_DOMAIN + "chat.getStarted";
export const API_URL_SEND_MESSAGE_CHAT = AUTH_API_DOMAIN + "chat.sendMessage";
export const API_URL_END_CHAT = AUTH_API_DOMAIN + "chat.finish";
export const API_URL_ANALYST_REPLY_CHAT = AUTH_API_DOMAIN + "chat.replyMessage";
export const API_URL_ANALYST_GET_LIST_CHAT = AUTH_API_DOMAIN + "chat.list";
export const API_URL_ANALYST_GET_CONVERSATION_CHAT =
  AUTH_API_DOMAIN + "chat.getConversation";
export const API_URL_ANALYST_DELETE_CONVERSATION_CHAT =
  AUTH_API_DOMAIN + "chat.deleteConversation";
export const API_URL_REMIND_UNANSWERED_CHAT =
  AUTH_API_DOMAIN + "chat.remindUnansweredChat";

// Notification
export const API_URL_NOTIFICATION_LIST = AUTH_API_DOMAIN + "notification.list";
export const CM_API_URL_NOTIFICATION_LIST = AUTH_API_DOMAIN + "cm/notification.list";
export const GDPR_URL_NOTIFICATION_LIST = GDPR_DOMAIN + "notification.list";
export const API_URL_GET_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notificationSetting.list";
export const CM_API_URL_GET_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notificationSetting.list";
export const API_URL_UPDATE_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notification.edit";
export const CM_API_URL_UPDATE_NOTIFICATION_LIST =
  AUTH_API_DOMAIN + "notification.edit";
export const API_URL_NOTIFICATION_MARK_READ =
GDPR_DOMAIN + "notification.markAsRead";
export const CM_API_URL_NOTIFICATION_MARK_READ =
  AUTH_API_DOMAIN + "notification.markAsRead";
export const GDPR_API_URL_NOTIFICATION_MARK_READ =
GDPR_DOMAIN + "notification.markAsRead";
export const API_URL_NOTIFICATION_MARK_ALL_READ =
  AUTH_API_DOMAIN + "notification.markAllAsRead";
export const GDPR_API_URL_NOTIFICATION_MARK_ALL_READ =
  GDPR_DOMAIN + "notification.markAllAsRead";

// e-learning
export const API_URL_GET_E_LEARNING_VIDEOS = AUTH_API_DOMAIN + "elearning.list";
export const API_URL_GET_E_LEARNING_LESSONS = `${process.env.REACT_APP_ELEARNING_API}api/getall`;
export const API_URL_GET_E_LEARNING_DETAILS = `${process.env.REACT_APP_ELEARNING_API}api/getdetails`;
export const API_URL_GET_E_LEARNING_AGENDA = `${process.env.REACT_APP_ELEARNING_API}api/getagenda`;
export const API_URL_GET_E_LEARNING_COUNTER = `${process.env.REACT_APP_ELEARNING_API}api/counter`;

export const API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST =
  AUTH_API_DOMAIN + "analystAndAdminAnalyst.list";
export const API_URL_ADMIN_AND_TASK_OWNERS_LIST  =  AUTH_API_DOMAIN + "analystAndAdminAnalystAndTaskOwners.list";
// Dashboard
export const API_URL_ADMIN_DASHBOARD = AUTH_API_DOMAIN + "dashboard";
export const CM_API_URL_ADMIN_DASHBOARD = AUTH_API_DOMAIN + "cm/dashboard";
export const API_URL_ADMIN_DASHBOARD_PARTNER_LIST =
  AUTH_API_DOMAIN + "organization/partners";
export const CM_API_URL_ADMIN_DASHBOARD_PARTNER_LIST =
  AUTH_API_DOMAIN + "cm/organization/partners";
export const API_URL_ADMIN_DASHBOARD_LAST_CASES =
GDPR_DOMAIN + "last_cases";
export const CM_API_URL_ADMIN_DASHBOARD_LAST_CASES =
  AUTH_API_DOMAIN + "cm/last_cases";
export const API_URL_ADMIN_DOWNLOAD_DASHBOARD_STATISTICS =
  AUTH_API_DOMAIN + "dashboard/download";

// Feature Flagging
export const API_URL_FEATURE_FLAGGING =
  AUTH_API_DOMAIN + "user.featureFlagging";

export const API_URL_CM_FEATURE_FLAGGING =
  AUTH_API_DOMAIN + "cm/user.featureFlagging";

// Customer API
export const API_URL_CUSTOMER_API = GDPR_DOMAIN;

// Documents/User Manuals
export const API_URL_GET_DOCUMENTS = AUTH_API_DOMAIN + "user_manual/list";
export const API_URL_DOWNLOAD_DOCUMENTS = AUTH_API_DOMAIN + "user_manual";
export const API_URL_UPLOAD_DOCUMENTS = AUTH_API_DOMAIN + "user_manual/create";

// QR
export const API_URL_GET_QR_CODE = AUTH_API_DOMAIN + "download.qrcode";

// Languages
export const API_URL_GET_LANGUAGES = AUTH_API_DOMAIN + "language/list";

export const API_URL_GET_TUTORIAL_LESSONS = `${process.env.REACT_APP_ELEARNING_API}api/getalltutorials`;
export const API_URL_GET_TUTORIAL_DETAILS = `${process.env.REACT_APP_ELEARNING_API}api/gettutorialdetails`;
export const API_URL_GET_TUTORIAL_ADMIN_LIST = `${process.env.REACT_APP_ELEARNING_API}api/gettutorialsforadmin`;

// Administration
export const API_URL_GET_HISTORY_LOGS = GDPR_DOMAIN_URL + "logs";
export const API_URL_GET_USERS = AUTH_API_DOMAIN + "users";

// GRC Dashboard
export const API_URL_GRC_DASHBOARD_CUSTOMIZATION = process.env.REACT_APP_GRC_API + "dashboard/customization.get";
export const API_URL_GRC_DASHBOARD_STATUS = process.env.REACT_APP_GRC_API + "dashboard/status.get";
export const API_URL_GRC_DASHBOARD_RISKS_TASKS = process.env.REACT_APP_GRC_API + "dashboard/risks-tasks.get";
export const API_URL_GRC_DASHBOARD_TASKS_STATUS = process.env.REACT_APP_GRC_API + "dashboard/tasks-status.get";
export const API_URL_GRC_DASHBOARD_SUBMITTED_CASES = process.env.REACT_APP_GRC_API + "dashboard/submitted-cases.get";
// Voices
export const API_URL_DELETE_VOICE_BY_ANALYST_ADMIN =
  AUTH_API_DOMAIN + "voice_report/delete_voice";

// AI
export const API_URL_SPEECH_CREATE = AI_DOMAIN + "voice-report/";
export const API_URL_SPEECH_UPLOAD_VOICE = AI_DOMAIN + "upload_file/";
export const API_URL_GET_VOICE_REPORT_DETAIL = AI_DOMAIN + "voice_reports/";
export const CM_API_URL_GET_VOICE_REPORT_DETAIL = AI_DOMAIN + "cm/voice_reports/";
export const API_URL_GET_SPEECH_TO_TEXT =
  AI_DOMAIN + "transcription/transcribe/";
export const API_URL_GET_VOICE_FILES = AI_DOMAIN + "voice-files/";
export const API_URL_SAVE_AI_TRANSCRIPTION =
  AUTH_API_DOMAIN + "voice_report/edit_transcription";
export const API_USER_UPDATE_MANUAL_TRANSCRIPTION =
  AUTH_API_DOMAIN + "voice_report/edit_manual_transcription";
export const API_DOWNLOAD_VOICE_FILES =
  AUTH_API_DOMAIN + "voice_report/download_voice";
export const API_URL_MY_TASKS =
  AUTH_API_DOMAIN + "task/my-tasks";
export const API_URL_GET_RISK_AI_CLASS = RISK_AI_DOMAIN + "promts";

//software 
export const SOFTWARE_GENERATE_BY_AI = SOFTWARE_AI_API_DOMAIN + "getsoftwareinfo";