
import React, { Component } from "react";

import { Row, Col, Button, Container, Label } from "reactstrap";

import { connect } from "react-redux";

import { AvForm } from "availity-reactstrap-validation";

import { withNamespaces } from "react-i18next";

import { post } from "../../../helpers/request_helper";

import { 
    API_URL_RESET_PASSWORD,
    API_URL_VERIFY_RESET_PASSWORD_TOKEN
} from '../../../common/constants';

import { 
    successNotification, 
    errorNotification
} from "../../../store/actions";
import Password from "../../../components/Fields/Password";
import OrganizationHeader from "../components/OrganizationHeader";
import { withRouter } from "react-router-dom";

class ResetPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.match.params.token
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.successNotification = this.props.successNotification.bind(this);
        this.errorNotification = this.props.errorNotification.bind(this);
    }

    handleValidSubmit(event, values) {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;
        if (!passwordRegex.test(values.password)) {
            this.errorNotification({
                code: "passwords-not-valid",
                message: this.props.t(
                "Password should be at least 10 charachters and should contain atleast one number and one special character"
                ),
            });
        }
        else if(values.password !== values.repassword){
            this.errorNotification({
                code: 'passwords-not-equal',
                message: this.props.t("Password and repeat password are not the same")
            });
        }
        else{
            post(API_URL_RESET_PASSWORD, {
                token: this.state.token,
                password: values.password
            },{})
            .then((response) => {
                this.successNotification({
                    code: 'password-reset-ok',
                    message: this.props.t("Your password was updated successfully")
                });
                this.props.history.push('/signin')
            }).catch(error=>{
                if(error === 'password_duplicate'){
                    this.errorNotification({
                        message: this.props.t("Password is same to current password.")
                    });

                }
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <OrganizationHeader />
                                                    <div className="p-2 mt-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="password">
                                                                    {this.props.t('Password')}
                                                                </Label>
                                                                <Password 
                                                                    name="password" 
                                                                    validate={{ required: true }} 
                                                                    errorMessage={this.props.t("This field cannot be blank")}
                                                                    id="password" 
                                                                    placeholder=""
                                                                    />
                                                            </div>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="repassword">
                                                                    {this.props.t('Repeat password')}
                                                                </Label>
                                                                <Password 
                                                                    name="repassword" 
                                                                    validate={{ required: true }} 
                                                                    errorMessage={this.props.t("This field cannot be blank")}
                                                                    id="repassword" 
                                                                    placeholder=""
                                                                    />
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">
                                                                    {
                                                                        this.props.loading ? 
                                                                            "Loading..." : 
                                                                            this.props.t("Reset password")
                                                                    }
                                                                </Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <p>
                                                            {this.props.t("© 2023 SMART INTEGRITY PLATFORM. All rights reserved.")}
                                                        </p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }

    componentDidMount(){
        post(API_URL_VERIFY_RESET_PASSWORD_TOKEN, {
            token: this.state.token
        },{})
        .catch((err) => {
            this.props.history.push("/404")
        });
    }
}

const mapStatetoProps = state => {
    return {};
}

const mapDispachToProps = (dispach) => {
    return {
        successNotification: (message) => dispach(successNotification(message)),
        errorNotification: (error) => dispach(errorNotification(error))
    }
}

export default withNamespaces()(withRouter(
    connect(mapStatetoProps, mapDispachToProps)(ResetPage)
));
