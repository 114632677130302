import { combineReducers } from 'redux';

import Layout from './layout/reducer';

import Workspace from './workspace/reducer';

import Login from './auth/login/reducer';

import chat from "./chat/reducer"

import { Organization } from "./organization/reducer"

import { AppNotification } from "./notification/reducer"

import { SystemNotification } from "./systemNotification/reducer"

import { App } from "./app/reducer"

import { Feature, CMFeature } from "./feature/reducer"

import { CaseId } from './cases/reducer';
import { GDPROrganization } from './gdpr-organization/reducer';

const rootReducer = combineReducers({
    Layout,
    Workspace,
    Organization,
    GDPROrganization,
    Login,
    chat,
    AppNotification,
    SystemNotification,
    Feature,
    CMFeature,
    CaseId,
    App
});

export default rootReducer;