import { withNamespaces } from "react-i18next";

import DateUtils from "src/services/utils/DateUtils";

import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import ReactApexChart from "react-apexcharts";

import { useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import {
  AvatarGroup,
  AvatarItem,
} from "src/modules/data-protection/components/avatar-group";

import Score from "src/modules/data-protection/components/score";

import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";

import React, { useEffect, useState } from "react";

import { Col, Row } from "reactstrap";

import OverviewHeaderContent from "src/modules/data-protection/components/overview-header";

import { createSVGFromText } from "src/modules/data-protection/helpers/common";

import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiMenuAlt4,
} from "react-icons/hi";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import CommonService from "src/modules/data-protection/apis/CommonService";

const InfoCards = ({
  t,
  assetId,
  handleFetchSoftwareDetailsQuery,
  // Analysts
  setSelectedAssignedAnalyst,
  selectedAssignedAnalyst,
  // Statuses
  selectedStatus,
  setSelectedStatus,
  statuses,
  // Priorities
  priorities,
  selectedPriority,
  setSelectedPriority,
  // Providers,
  selectedProvider,
  setSelectedProvider,
  // Types
  setSelectedAssetType,
  selectedAssetType,
  // OS
  selectedOperatingSystem,
  setSelectedOperatingSystem,
  // Version
  version,
  // Service
  service,
  // Modules
  selectedModules,
  // Attachments
  attachments,
  // Company
  selectedCompany,
  // Position
  selectedPosition,
  // Phone Number
  selectedPhoneNumber,
  // Email
  selectedEmail,
  // Department
  selectedDepartment,
  // Responsible Person
  selectedResponsiblePerson,
}) => {
  const [asset, setAsset] = useState(null);
  const [users, setUsers] = useState([]);
  const dateUtils = new DateUtils();

  const handleFetchAssetBasicInformation = useQuery({
    queryKey: ["data-protection-supplier-overview-contnet"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.details(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching overview content."), {
        type: "error",
      });
    },
  });

  const handleFetchUsersListQuery = useQuery({
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

  // const renderLoadingSection = () => {
  //   return (
  //     <>
  //       {/* First One */}
  //       <FirstRow
  //         assetId={assetId}
  //         setSelectedAssignedAnalyst={setSelectedAssignedAnalyst}
  //         selectedAssignedAnalyst={selectedAssignedAnalyst}
  //         handleFetchSoftwareDetailsQuery={handleFetchSoftwareDetailsQuery}
  //       />
  //     </>
  //     // <Row>
  //     //   <Col sm="12">
  //     //     <div className="loading-section p-5">
  //     //       <Row className="mb-5">
  //     //         <Col sm="12" md="3">
  //     //           <div
  //     //             className="dt-field dt-skeleton mb-1"
  //     //             style={{ width: "40%" }}
  //     //           ></div>
  //     //           <div
  //     //             className="dt-field dt-skeleton"
  //     //             style={{ width: "70%" }}
  //     //           ></div>
  //     //         </Col>
  //     //         <Col sm="12" md="3">
  //     //           <div
  //     //             className="dt-field dt-skeleton mb-1"
  //     //             style={{ width: "40%" }}
  //     //           ></div>
  //     //           <div
  //     //             className="dt-field dt-skeleton"
  //     //             style={{ width: "70%" }}
  //     //           ></div>
  //     //         </Col>
  //     //         <Col sm="12" md="3">
  //     //           <div
  //     //             className="dt-field dt-skeleton mb-1"
  //     //             style={{ width: "40%" }}
  //     //           ></div>
  //     //           <div
  //     //             className="dt-field dt-skeleton"
  //     //             style={{ width: "70%" }}
  //     //           ></div>
  //     //         </Col>
  //     //         <Col sm="12" md="3">
  //     //           <div
  //     //             className="dt-field dt-skeleton mb-1"
  //     //             style={{ width: "40%" }}
  //     //           ></div>
  //     //           <div
  //     //             className="dt-field dt-skeleton"
  //     //             style={{ width: "70%" }}
  //     //           ></div>
  //     //         </Col>
  //     //       </Row>
  //     //       <Row>
  //     //         <Col sm="12" md="4">
  //     //           <div
  //     //             className="dt-field dt-skeleton mb-1"
  //     //             style={{ width: "100%" }}
  //     //           ></div>
  //     //         </Col>
  //     //         <Col sm="12" md="4">
  //     //           <div
  //     //             className="dt-field dt-skeleton mb-1"
  //     //             style={{ width: "100%" }}
  //     //           ></div>
  //     //         </Col>
  //     //         <Col sm="12" md="4">
  //     //           <div
  //     //             className="dt-field dt-skeleton mb-1"
  //     //             style={{ width: "100%" }}
  //     //           ></div>
  //     //         </Col>
  //     //       </Row>
  //     //     </div>
  //     //   </Col>
  //     // </Row>
  //   );
  // };

  const renderAssignedAnalysts = () => {
    const analysts = asset?.analyst_assigned_object || {};
    const result = [];

    // for (const index in analysts) {
    //   const analyst = analysts[index];
    const analyst = analysts;

    const initials = analyst?.name
      .split(" ")
      .map((part) => part.charAt(0))
      .join("");

    var xml = new XMLSerializer().serializeToString(
      // createSVGFromText(
      //   `${(analyst?.first_name || "").charAt(0)}${(
      //     analyst?.last_name || ""
      //   ).charAt(0)}`
      // )
      createSVGFromText(initials)
    );

    result.push(
      <AvatarItem
        //   id={`overview-analyst-avatar-${index}`}
        id={`overview-analyst-avatar`}
        title={`${analyst?.first_name} ${analyst?.last_name}`}
        src={`data:image/svg+xml;charset=utf-8,${xml}`}
        alt=""
      />
    );
    // }

    return <AvatarGroup max={4}>{result}</AvatarGroup>;
  };

  useEffect(() => {
    if (handleFetchAssetBasicInformation.data) {
      setAsset(handleFetchAssetBasicInformation.data);
    }
  }, [handleFetchAssetBasicInformation.data]);

  useEffect(() => {
    if (
      handleFetchUsersListQuery.data &&
      handleFetchUsersListQuery.data?.length
    ) {
      const users = handleFetchUsersListQuery.data.map((u) => {
        return {
          value: u.id,
          label: u.first_name + " " + u.last_name,
        };
      });
      setUsers(users);
    }
  }, [handleFetchUsersListQuery.data]);

  //   console.log("Asset", asset);
  return (
    <React.Fragment>
      <FirstRow
        // General
        handleFetchSoftwareDetailsQuery={handleFetchSoftwareDetailsQuery}
        assetId={assetId}
        // Analysts
        setSelectedAssignedAnalyst={setSelectedAssignedAnalyst}
        selectedAssignedAnalyst={selectedAssignedAnalyst}
        users={users}
        // Statuses
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        statuses={statuses}
        // Priorities
        selectedPriority={selectedPriority}
        setSelectedPriority={setSelectedPriority}
        priorities={priorities}
      />

      <SecondRow
        // General
        assetId={assetId}
        handleFetchSoftwareDetailsQuery={handleFetchSoftwareDetailsQuery}
        // Providers
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        // Asset Type
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setSelectedAssetType}
        // OS
        selectedOperatingSystem={selectedOperatingSystem}
        setSelectedOperatingSystem={setSelectedOperatingSystem}
        // Version
        version={version}
        // Service
        service={service}
        // Selected Modules
        selectedModules={selectedModules}
        // Responsible Person
        users={users}
        // Attachments
        attachments={attachments}
        // Company
        selectedCompany={selectedCompany}
        // Position
        selectedPosition={selectedPosition}
        // Phone Number
        selectedPhoneNumber={selectedPhoneNumber}
        // Email
        selectedEmail={selectedEmail}
        // Department
        selectedDepartment={selectedDepartment}
        // Responsible Person
        selectedResponsiblePerson={selectedResponsiblePerson}
      />
    </React.Fragment>
  );
};

export default withNamespaces()(InfoCards);
