import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useRouteMatch, Route, Switch, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
// styles
import "./style.scss";
// components
import PageBody from "../../../../components/page-body";
import PageHeader from "../../../../components/page-header";
import PageContent from "../../../../components/page-content";
import QuestionaireTypesTabs from "./components/questionaire-types-tabs";
import DataProtectionEditQuestionnaire from "../../administration/questionaire/edit";

import QuestionarService from "../../../../../../components/Questionar/service";

import { toast } from 'react-toastify';

import { useQuery } from "@tanstack/react-query";

const DataProtectionQuestionnairesList = (props) => {
  const [questionaireList, setQuestionaireList] = useState([]);
  const [selectedQuestionaire, setSelectedQuestionaire] = useState(null);
  const { t } = props;
  const { path } = useRouteMatch();

  const handleFetchQuestionaireList = useQuery({
    queryKey: ["data-protection-questionaire-list"],
    queryFn: async () => {
      const service = QuestionarService.getInstance();

      return await service.list();
    },
    cacheTime: 0,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching questionnaires list."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    setQuestionaireList(handleFetchQuestionaireList?.data || []);
  }, [handleFetchQuestionaireList.data]);

  const activeQuestionaireChanged = (id) => {
    if(id){
      id = Number.parseInt(id);
      setSelectedQuestionaire(questionaireList.find((q) => {
        return Number.parseInt(q.id) === id;
      }));
    }
  };

  return (
    <PageContent classNames="data-protection-software-details-page">
      <Container fluid>
        {/* page header */}
        <PageHeader title={'Questionnaire Designer'}>
          <Link
            to="/admin/data-protection/softwares"
            className="btn btn-primary">
            {t("Back to list")}
          </Link>
        </PageHeader>

        {/* page body */}
        <PageBody classNames="p-4">
            <QuestionaireTypesTabs t={t} questionaires={ questionaireList } onActiveQuestionaireChanged={ activeQuestionaireChanged } />

            <Switch>
                <Route exact path={path}>
                    <DataProtectionEditQuestionnaire selectedQuestionaire={selectedQuestionaire}/>
                </Route>
            </Switch>
        </PageBody>
      </Container>
    </PageContent>
  );
};

export default withNamespaces()(
  withRouter(DataProtectionQuestionnairesList)
);
