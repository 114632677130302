import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuListItems from "../../common/data/menus";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import UserUtils from "../../services/utils/UserUtils";
import ModuleContext from "../../contexts/ModuleContext";
import { withRouter } from "react-router-dom";

class SidebarContent extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);
    this.state = {
      menu: MenuListItems,
      dropdown: {
        id: null,
        active: false,
      },
    };
  }

  filterTitle = (menu = [], value = "") => {
    menu = menu.filter((object) => {
      return object.titleKey !== value;
    });

    return menu;
  };

  render() {
    let menu = this.state.menu[this.props.Workspace.name];

    // console.log(menu)

    if (menu.constructor === Array && menu.length) {
      // isChat
      if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isChat) {
        menu = this.filterTitle(menu, "Live Chat Support");
      }

      // isMeeting
      if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isMeeting) {
        menu = this.filterTitle(menu, "Arrange a meeting");
      }

      // isElearning
      if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isElearning) {
        menu = this.filterTitle(menu, "E-Learning");
      }

      const userUtils = new UserUtils();
      if (!userUtils.isAnalystAdmin(this.props.currentUser)) {
        menu = this.filterTitle(menu, "TutorialAdmin");
      }

      // // isElearning
      // if (!this.props.Organization.isElearning) {
      //   menu = this.filterTitle(menu, "Tutorial");
      // }

      // All Reports
      if (
        !this.context.selectedModule ||
        (
          !this.context.settings[this.context.selectedModule].isAnonymously &&
          !this.context.settings[this.context.selectedModule].isConfidentially &&
          !this.context.settings[this.context.selectedModule].isMeeting
        )
      ) {
        menu = this.filterTitle(menu, "Reporting Channel");
      } else {
        if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isMeeting) {
          // isMeeting
          menu.forEach((element) => {
            if ("items" in element) {
              element.items = this.filterTitle(
                element.items,
                "Arrange a meeting"
              );
            }
          });
        }
        if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isAnonymously) {
          // isAnonymously
          menu.forEach((element) => {
            if ("items" in element) {
              element.items = this.filterTitle(
                element.items,
                "Report anonymously"
              );
            }
          });
        }
        if (!this.context.selectedModule || !this.context.settings[this.context.selectedModule].isConfidentially) {
          // isConfidentially
          menu.forEach((element) => {
            if ("items" in element) {
              element.items = this.filterTitle(
                element.items,
                "Report confidentially"
              );
            }
          });
        }
      }
    }

    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menu.map((menuItem) => {
              return menuItem.titleKey === "FAQ" ? (
                <li key={menuItem.id}>
                  <a
                    href={process.env.REACT_APP_FAQ_URL}
                    target="_new"
                  >
                    <i className={menuItem.icon}></i>
                    <span className="ms-1">
                      {this.props.t(menuItem.titleKey)}
                    </span>
                  </a>
                </li>
              ) : (
                <li
                  key={menuItem.id}
                  onClick={() => {
                    // console.log(this.state.dropdown);
                    menuItem.items && menuItem.id == this.state.dropdown.id
                      ? this.setState({
                          dropdown: {
                            id: menuItem.id,
                            active: !this.state.dropdown.active,
                          },
                        })
                      : this.setState({
                          dropdown: {
                            id: menuItem.id,
                            active: true,
                          },
                        });
                  }}
                  className={
                    this.state.dropdown.id === menuItem.id &&
                    this.state.dropdown.active
                      ? "afc-dropdown active"
                      : "afc-dropdown"
                  }
                >
                  {/* check if it has subMenu dont Start with Link Component */}
                  {menuItem?.items ? (
                    <>
                      <a className={`has-arrow waves-effect`}>
                        <i className={menuItem.icon}></i>
                        <span className="ms-1">
                          {this.props.t(menuItem.titleKey)}
                        </span>
                      </a>

                      <ul className="sub-menu">
                        {menuItem.items.map((subMenuItem) => {
                          return (
                            <li key={subMenuItem.id}>
                              <Link to={subMenuItem.path}>
                                {this.props.t(subMenuItem.titleKey)}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <Link to={menuItem.path} className={`waves-effect`}>
                      <i className={menuItem.icon}></i>
                      <span className="ms-1">
                        {this.props.t(menuItem.titleKey)}
                      </span>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Organization } = state;
	const { user: currentUser } = state.Login;

  return {
    Organization,
    currentUser,
    ...state.Layout,
    Workspace: { ...state.Workspace },
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
