import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../../../../../components/page-header";
import PageContent from "../../../../../../components/page-content";
import PageBody from "../../../../../../components/page-body";
import Breadcrumbs from "../../../../../../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, CardText, Col, Row } from "reactstrap";
import DataProtectionNewsService from "../../../../../../apis/NewsService";
import { useQuery } from "@tanstack/react-query";
import defaultImage from "src/modules/assets/images/defaultNewsImage.svg";
import "../components/style.css";
import { toast } from "react-toastify";
import Search from "../components/top-menu/search";
import StrongImage from "src/assets/images/RiskAI/Strong.svg";
import Botty from "src/modules/data-protection/assets/images/bottyAi-2.png";

const LatestNewsDetails = ({ t }) => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);

  const {
    body = "",
    created_at = "",
    flag = false,
    lead_text = "",
    news_agency = "",
    release_date = "",
    source_link = "",
    tags = [],
    title = "",
  } = details || {};

  const [breadcrumbs, _] = useState([
    { title: "SIP", link: "/" },
    { title: t("Data Protection"), link: "#" },
    { title: t("News Monitoring"), link: "/admin/data-protection/latest-news" },
    { title: t("Details"), link: "#" },
  ]);

  // Functions
  // Fetch
  const getDetails = useQuery({
    queryKey: ["latestNews", id],
    queryFn: async () => {
      const service = DataProtectionNewsService.getInstance();
      const response = await service.details(id);
      return response || [];
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("error while fetchinig news"), {
        type: "error",
      });
    },
  });

  // Fetch Main Url
  const getMainLink = (url) => {
    if (!url) {
      return;
    }
    var parsedUrl = new URL(url);
    return parsedUrl.origin; // This will return the protocol + hostname (base URL)
  };

  useEffect(() => {
    if (getDetails.isLoading) return;

    setDetails(getDetails?.data || []);
  }, [getDetails?.data]);

  // Sample
  const source = [
    {
      title: "Source A",
      description: "Brief description...",
      source: "https://source-a.com",
    },
    // {
    //   title: "Source B",
    //   description: "Another description...",
    //   source: "https://source-b.net",
    // },
  ];

  return (
    <div className="p-4">
      <PageContent>
        {/* PAGE HEADER */}
        <PageHeader title={t("News Monitoring")}>
          <div className="mt-4">
            <Breadcrumbs breadcrumbItems={breadcrumbs} />
          </div>
        </PageHeader>

        <PageBody>
          {details && (
            <Card>
              <Row className={`p-5`}>
                <Col md="8" sm="12">
                  {/* Title */}
                  <div>
                    <div className="redCircle"></div>
                    <h4>{title}</h4>
                  </div>

                  {/* Translate to English */}
                  <Row>
                    <Col
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        color="primary"
                        outline
                        className="br-4 mt-2 mb-2"
                        size="md"
                      >
                        {t("Translate To English")}
                      </Button>
                    </Col>
                  </Row>

                  {/* Image */}
                  <Row
                    className="mt-2"
                    style={{ marginLeft: "0px", paddingTop: "0px" }}
                  >
                    <Col>
                      <img
                        src={defaultImage}
                        style={{
                          maxWidth: "581px",
                          maxHeight: "290px",
                          marginLeft: "0px",
                        }}
                        alt={t("News Monitoring")}
                      />
                    </Col>
                  </Row>

                  {/* Body */}
                  <Row className="mt-4">
                    <p>{body}</p>
                  </Row>

                  {/* Link */}
                  <Row>
                    <a
                      className="text-secondary"
                      href={source_link}
                      target="_blank"
                    >
                      {source_link}
                    </a>
                  </Row>

                  {/* Flag + Search */}
                  <div
                    className="mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* Search */}
                    <Search t={t} />
                    {/* Flag */}
                    <Button
                      className={`${
                        flag ? "bg-primary" : "bg-secondary"
                      } br2 ms-3`}
                      size="sm"
                    >
                      {t("Flag")}
                    </Button>
                  </div>
                </Col>
                <Col md="4" sm="12">
                  <Card
                    className="my-2"
                    outline
                    style={{
                      width: "26rem",
                      border: "1px solid #5664D2",
                      overflowY: "scroll",
                      // maxHeight: `80%`, // Using modalHeight here
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column wrap",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Row>
                      <h4 className="p-2 mt-3" style={{ fontWeight: "bold" }}>
                        {t("Risk Assessment Support by AI")}
                      </h4>
                      </Row>
                      <Row className="mb-2">
                        <img
                          style={{
                            maxWidth: "120px",
                            maxHeight: "120px",
                            display: "block",
                          }}
                          src={Botty}
                        />
                      </Row>
                      <Row className="mb-3">
                      <Button color="primary">{t("Get Help From AI")}</Button>
                      </Row>
                    </div>
                  </Card>

                  <Card
                    className="my-2"
                    outline
                    style={{
                      width: "26rem",
                      border: "1px solid #5664D2",
                      overflowY: "scroll",
                      maxHeight: `70%`, // Using modalHeight here
                    }}
                  >
                    <img
                      alt="Risk Level"
                      // src={selectedClass === "A" ? StrongImage : selectedClass === "B" ? ModerateImage : WeakImage}
                      src={StrongImage}
                    />
                    <CardBody>
                      <CardText>
                        <h3
                          style={{
                            color: "#1A2052",
                            textAlign: "center",
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          {t("Source")}
                        </h3>
                        {source.map((s) => {
                          return (
                            <div className="mb-4">
                              <h4 style={{ textAlign: "center" }}>{s.title}</h4>
                              <p>{s.description}</p>
                              <li>
                                <a href={getMainLink(s.source)} target="_blank">
                                  {getMainLink(s.source)}
                                </a>
                              </li>
                              <hr></hr>
                            </div>
                          );
                        })}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
          )}
        </PageBody>
      </PageContent>
    </div>
  );
};

export default withNamespaces()(withRouter(memo(LatestNewsDetails)));
