import { memo, useEffect, useState } from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';


const Tabs = memo(({t,onTabChange}) => {
    const [activeTab, setActiveTab] = useState("");
    const history = useHistory();
    const location = useLocation();

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    const tabsLinks = [
        {
          key: "incident",
          title: "Incident",
        },
        {
          key: "messages",
          title: "Messages",
        },
        {
          key: "internal-comments",
          title: "Internal Comments",
        },
        {
          key: "risk-assessment",
          title: "Risk Assessment",
        },
        {
          key: "task-management",
          title: "Task Management",
        },
        {
          key: "authority-reporting",
          title: "Authority Reporting",
        },
      ];
    useEffect(() => {
        setActiveTab((new URLSearchParams(location.search)).get('tab'));
        onTabChange();
    }, [ location.search ]);

    useEffect(() => {
        if(!(new URLSearchParams(location.search)).get('tab')){
            history.push('?tab=incident');
        }
    }, [location.search]);

    return (
        <div style={{
            backgroundColor: '#D1D2D6'
        }}>
            <Nav tabs className="custom-style-1">
                {
                    tabsLinks.map((link) => {
                        return (
                            <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                                <NavLink
                                    className={isActiveTab(activeTab, link.key) ? 'active': ''}
                                    onClick={() => history.push(`?tab=${link.key}`)}
                                    >

                                    {t(link.title)}
                                </NavLink>
                            </NavItem>
                        )
                    })
                }
            </Nav>
        </div>
	);
});

export default Tabs;
