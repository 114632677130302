import OrganizationApiProvider from '../../apis/OrganizationApiProvider';
import {map, mergeMap} from 'rxjs/operators';
import StorageManager from './StorageManager';
import {of} from 'rxjs';
import ResponseError from '../../models/ResponseError';
import UrlUtils from '../../services/utils/UrlUtils';

export default class GDPROrganizationManager {
    urlUtils = new UrlUtils();
    constructor() {
        this.organizationApi = new OrganizationApiProvider();
        this.storageManager = new StorageManager();
    }
   
    verifyOrganization(id, url) {
        const data = {
            id: id,
            url: decodeURI(url)
        };
        
        return this.organizationApi.verifyGDPROrganization(data).pipe(
            map((response) => {                
                return response;
            })
        );
    }

    editInfoOrganization(logo, intro, name) {
        const accessToken = this.storageManager.getAccessTokenFromLocalStorage();
        const formData = new FormData();
        if (typeof logo !== 'string') {
            formData.append("logo", logo);
        }
        formData.append("intro", intro);
        formData.append("name", name);

        return this.organizationApi.editInfoGDPROrganization(formData, accessToken).pipe(
            mergeMap((response) => {
                if (response instanceof ResponseError) {
                    return of(response);
                }
                return this.organizationApi.getInfoGDPROrganization(accessToken).pipe(
                    map(response => {
                        if (response instanceof ResponseError) {
                            return of(response);
                        }
                        return response
                    })
                );
            })
        );
    }
    
    getInfoOrganization() {
        const accessToken = this.storageManager.getAccessTokenFromLocalStorage();
        return this.organizationApi.getInfoGDPROrganization(accessToken).pipe(
            map((response) => {
                if (response instanceof ResponseError) {
                    return of(response);
                }
                return response;
            })
        );
    }

    getQuestionFormOrganization(typeReport){ 
        const  orgId = this.urlUtils.getOrganizationId();      
        const accessToken = this.storageManager.getAccessTokenFromLocalStorage();       
        return this.organizationApi.getQuestionFormGDPROrganization(orgId ,typeReport, accessToken).pipe(
            map((response)=>{
                                      
                if(response instanceof ResponseError){
                    return of(response)
                }
                return response;
            })
        )
    }
}
