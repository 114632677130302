import { all } from 'redux-saga/effects'

import loginSaga from './auth/login/saga';
import LayoutSaga from './layout/saga';
import WorkspaceSaga from './workspace/saga';
import NotificationSaga from './notification/saga';
import chatSaga from "./chat/saga"

export default function* rootSaga() {
    yield all([
        loginSaga(),
        LayoutSaga(),
        chatSaga(),
        WorkspaceSaga(),
        NotificationSaga()
    ])
}