import anti_corruption from 'src/assets/images/questionnaire/sections/anti-corruption.svg';
import compliance_and_corporate_policy from 'src/assets/images/questionnaire/sections/compliance_and_corporate_policy.svg';
import environmental_standards from 'src/assets/images/questionnaire/sections/environmental_standards.svg';
import human_rights_due_diligence from 'src/assets/images/questionnaire/sections/human_rights_due_diligence.svg';
import relevant_certificates_and_audit_reports from 'src/assets/images/questionnaire/sections/relevant_certificates_and_audit_reports.svg';
import risk_management from 'src/assets/images/questionnaire/sections/risk_management.svg';
import transparency_and_reporting from 'src/assets/images/questionnaire/sections/transparency_and_reporting.svg';
import working_conditions from 'src/assets/images/questionnaire/sections/working_conditions.svg';
import final_declarations from 'src/assets/images/questionnaire/sections/final_declarations.svg';


export const QuestionnaireSectionsImages = {
    'anti-corruption'                           :   anti_corruption,
    'compliance_and_corporate_policy'           :   compliance_and_corporate_policy,
    'environmental_standards'                   :   environmental_standards,
    'human_rights_due_diligence'                :   human_rights_due_diligence,
    'relevant_certificates_and_audit_reports'   :   relevant_certificates_and_audit_reports,
    'risk_management'                           :   risk_management,
    'transparency_and_reporting'                :   transparency_and_reporting,
    'working_conditions'                        :   working_conditions,
    'final_declarations'                        :   final_declarations
}

export const HistoryLogActions = {
  case: {
    create: "New case created",
    update_analyst: "Update Analyst",
    update_status: "Update Status",
    mark_as_delete: "Marked as delete",
    delete: "Deleted",
    upload_attachment: "Uploaded attachment",
    download_user_attachment: "Download user attachment",
    download_analyst_attachment: "Download analyst attachment",
    delete_attachment: "Delete Attachment",
    add_comment: "Add Comment",
    add_internal_comment: "Add Internal Comment",
    update_analyst_readonly: "Read-Only Analysts",
    update_risk: "Risk updated",
    link_case: "Linked case updated",
    change_admin_description: "Admin description updated",
    change_admin_category: "Admin category updated",
    add_link_case: "Linked case added",
    remove_link_case: "Linked case removed",
  },
  risk: {
    upload_files: "Uploaded files",
    upload_file: "Uploaded file",
    download_files: "Download files",
    download_file: "Download file",
    update_file: "Upload file",
    delete_file: "Delete file",
    create_comment: "New comment created",
    create: "New risk created",
    update_description: "Description updated",
    delete: "Deleted",
    assign_analyst: "Assigned to analyst",
    update_damage: "Damage updated",
    update_likelihood: "Likelihood updated",
    update_category: "Category updated",
    change_description: "Description updated by admin",
    change_category: "Category updated by admin",
    create_ai: "New Ai risk created",
  },
  task: {
    upload_files: "Uploaded files",
    upload_file: "Uploaded file",
    download_files: "Download files",
    download_file: "Download file",
    update_file: "Upload file",
    delete_file: "Delete file",
    create_comment: "New comment created",
    create: "New task created",
    assign_analyst: "Assigned to analyst",
    update_description: "Description updated",
    update_status: "Status updated",
    update_priority: "Priority updated",
    update_deadline: "Deadline updated",
    update_category: "Category updated",
    delete: "Deleted",
  },
  admin: {
    change_active_directory_settings: "Active directory settings",
    edit_risk_matrix: "Update matrix",
    restore_risk_matrix: "Restore matrix",
    edit_user: "Edit user",
    create_user: "Created user",
    delete_user: "Deleted user",
    change_user_status: "Changed user status",
    edit_organization: "Edited organization",
    update_question: "Updated question",
    remove_question: "Removed question",
    remove_category: "Removed category",
    change_default_owner: "Default owner has been changed",
    login: "Login",
  },
  document: {
    create: "Created",
    remove: "Removed",
  },
  user: {
    approveConsent: "approve_consent",
  },
  software: {
    create: "Created",
    toggle_flag: "Change flag",
    add_owner: "Added a new owner",
    change_analyst: "Change Analyst Assigned",
    change_priority :"Change Priority",
    change_status :"Change Status",
    upload_csv: "Upload software data from Excel file",
    delete_owner: "Deleted an owner",
    download: "Downloaded",
    delete: "Deleted",
    edit: "Edit detail",
    dpia: "",
    add_module: "Added a new module",
    remove_module: "Removed a module",
    change_responsible_person: "Change Responsible Person",
    change_company: "Change Company",
    change_position: "Change Position",
    change_email: "Change Email",
    change_phone_number: "Change Phone Number",
    change_responsible_department: "Change Responsible Department"
    
  },
  hardware: {
    create: "Created",
    download: "Downloaded",
    delete: "Deleted",
    toggle_flag: "Change flag",
    handover: "Deliver the hardware to the new user",
    change_status :"Change Status",
    change_analyst: "Change Analyst Assigned",
    change_priority :"Change Priority",
    upload_csv: "Upload hardware data from Excel file",
    edit: "Edit detail",
  }
};

export const HistoryLogSections = {
  risk: "Risk Management",
  task: "Task Management",
  admin: "Administration",
  case: "Case Management",
  document: "Document Management",
  user: "User",
  software:"Software Managment",
  hardware:"Hardware Managment"
};

export const HistoryLogRoles = {
  ROLE_ANALYST_ADMIN: "Analyst admin",
  ROLE_ANALYST: "Analyst",
  ROLE_TASK_OWNER: "Task Owner",
  ROLE_THIRD_PARTY: "Third Patry",
  ROLE_EMPLOYEE: "Employee",
};

export const TasksStatuses = {
  NEW: {
    title: "New",
    color: "#FFEE93",
  },
  IN_PROGRESS: {
    title: "In Progress",
    color: "#8792E8",
  },
  REVIEW: {
    title: "Review",
    color: "#85FF8A",
  },
  CLOSE: {
    title: "Closed",
    color: "#EDECEC",
  },
  CLOSED: {
    title: "Closed",
    color: "#EDECEC",
  },
  DELETE: {
    title: "Deleted",
    color: "#FFDADA",
  },
  DELETED: {
    title: "Deleted",
    color: "#FFDADA",
  },
};

export const ReportsStatuses = {
  0: {
    title: "New",
    color: "#FFEE93",
  },
  1: {
    title: "Open",
    color: "#85FF8A",
  },
  2: {
    title: "Assigned",
    color: "#F693FF",
  },
  3: {
    title: "In Progress",
    color: "#8792E8",
  },
  4: {
    title: "Closed",
    color: "#EDECEC",
  },
  5: {
    title: "Pending Deletion",
    color: "#FFDADA",
  },
  6: {
    title: "Deleted",
    color: "#FFDADA",
  },
};

export const SuppliersStatuses = {
  0: {
    title: "Review",
    color: "#74B8FF",
  },
  1: {
    title: "Approved",
    color: "#5DFFCF",
  },
  2: {
    title: "Completed",
    color: "#2D9B4C",
  },
  3: {
    title: "Rejected",
    color: "#BE4F59",
  },
  4: {
    title: "In Progress",
    color: "#8792E8",
  },
};

// Utility function to format the ID string
export const formatTaskAndRiskId = (type, Id ,secondaryId, isMitigation, questionnaire, taskId) => {

  const mainId = Id ?? secondaryId;
if (questionnaire === 'software') {
  if (isMitigation) {
      return `A${mainId}/R${secondaryId}/T${taskId}`;
  } else if (type === 'task') {
      return `A${mainId}/T${taskId}`;
  } else if (type === 'risk') {
      return `A${mainId}/R${secondaryId}`;
  } else {
      return `R${mainId}`; // Default format for software
  }
} else if (questionnaire === 'data_breach_incident') {
  if (isMitigation) {
      return `IN${mainId}/R${secondaryId}/T${taskId}`;
  } else if (type === 'task') {
      return `IN${mainId}/T${taskId}`;
  } else if (type === 'risk') {
      return `IN${mainId}/R${secondaryId}`;
  } else {
      return `R${mainId}`; // Default format for incident
  }
} else if (questionnaire === 'data_subject_request') {
  if (isMitigation) {
      return `D${mainId}/R${secondaryId}/T${taskId}`;
  } else if (type === 'task') {
      return `D${mainId}/T${taskId}`;
  } else {
      return `R${mainId}`; // Default format for DSR
  }
} else {
  return `R${mainId}`; // Fallback for unknown types
}
};
