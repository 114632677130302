import { uniqueKey } from './utils';

const CustomTableHeader = ({ rowData, bold }) => {
	return (
		<div className='d-flex flex-row table-header-container'>
			{rowData.map((col, index) => (
				<div
					key={uniqueKey(`${col.value}-${index}`)}
					className='table-header-item'
					style={{
						width: `${col.width}`,
					}}>
					{col.value}
				</div>
			))}
		</div>
	);
};

export default CustomTableHeader;
