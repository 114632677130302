import { Redirect } from 'react-router-dom';

import HorizontalLayout from '../components/HorizontalLayout/';
import Profile from '../pages/Authentication/Profile';
import ChangePassword from '../pages/Authentication/ChangePassword';
import ThirdpartyHome from '../pages/Thirdparty/home/index';
import ReportAnonymously from '../pages/Reporting/ReportAnonymously';
import ReportConfidently from '../pages/Reporting/ReportConfidently';
import ArrangeMeeting from '../pages/Reporting/ArrangeMeeting';
import VoiceReport from '../pages/Reporting/VoiceReport/VoiceReport';
import ReportDetails from '../pages/Reporting/ReportDetails';
import ReportList from '../pages/Reporting/ReportList';
import ElearningHome from '../pages/E-learning';
import ElearningDetail from '../pages/E-learning/ElearningDetail';
import TutorialHome from '../pages/Tutorial';
import TutorialDetail from '../pages/Tutorial/TutorialDetail';
import Documents from '../pages/Documents';
import GDPR from '../pages/Reporting/GDPR';
import FullPageLayout from '../components/FullPageLayout';
import Login from '../pages/Authentication/Login';
import Register from '../pages/Authentication/Register';
import moduleSelection from '../pages/Authentication/module-selection';
import dataProtectionRoutes from '../modules/data-protection/router/thirdparty';
import FAQ from '../pages/FAQ';
import UserManual from '../pages/UserManual'

const thirdPartyRoutes = [
	{
		path: '/third-party/documents',
		component: Documents,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/report/detail',
		component: ReportDetails,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/gdpr',
		component: GDPR,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/report-anonymously',
		component: ReportAnonymously,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/report-confidentially',
		component: ReportConfidently,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/arrange-meeting',
		component: ArrangeMeeting,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/voice-report',
		component: VoiceReport,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/reports',
		component: ReportList,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/home',
		component: ThirdpartyHome,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/start',
		component: moduleSelection,
		layout: HorizontalLayout,
		type: 'third-party',
		modules: ['wb', 'cm'],
	},
	{
		path: '/third-party/e-learning/:id/detail',
		component: ElearningDetail,
		layout: HorizontalLayout,
		type: 'third-party',
	},
	{
		path: '/third-party/e-learning',
		component: ElearningHome,
		layout: HorizontalLayout,
		type: 'third-party',
	},
	{
		path: '/third-party/tutorial/:id/detail',
		component: TutorialDetail,
		layout: HorizontalLayout,
		type: 'third-party',
	},
	{
		path: '/third-party/tutorial',
		component: TutorialHome,
		layout: HorizontalLayout,
		type: 'third-party',
	},
	...dataProtectionRoutes,
	{
		path: '/third-party/profile/change-password',
		component: ChangePassword,
		layout: HorizontalLayout,
		type: 'third-party',
	},
	{
		path: '/third-party/profile',
		component: Profile,
		layout: HorizontalLayout,
		type: 'third-party',
	},
	{
		path: '/third-party/signin',
		component: Login,
		layout: FullPageLayout,
	},
	{
		path: '/third-party/register',
		component: Register,
		layout: FullPageLayout,
	},
  {
    path: '/third-party/faq',
    component: FAQ,
    layout: HorizontalLayout,
    type: 'third-party',
  },
  {
    path: '/third-party/user_manual',
    component: UserManual,
    layout: HorizontalLayout,
    type: 'third-party',
  },
  {
        path: ['/third-party', '/third-party/*'],
        component: () => <Redirect to='/third-party/home' />,
        layout: HorizontalLayout,
        type: 'third-party',
  },
];

export default thirdPartyRoutes;
