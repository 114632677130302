import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";

const Footer = (props) => {
    return (
        <React.Fragment>
             <footer className="footer">
                    <Container fluid>
                        <Row>
                            <Col md={8}>
                                { props.t("© 2023 SMART INTEGRITY PLATFORM. All rights reserved.") }
                            </Col>
                            <Col md={4} className="text-sm-left text-md-end">
                                {`${props.t("Version")}: ${process.env.REACT_APP_SIP_VERSION}`}
                            </Col>
                        </Row>
                    </Container>
                </footer>
        </React.Fragment>
    );
};

export default withNamespaces()(Footer);
