import React, { useState, useEffect, Fragment } from "react";
import { Button, ButtonGroup, Label, Tooltip } from "reactstrap";
import { FaMicrophone, FaStop, FaTrash } from "react-icons/fa";
import "./RecordingComponent.scss";
import { errorNotification, successNotification } from "../../../store/actions";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  API_URL_SPEECH_UPLOAD_VOICE,
  TASK_FILE_NOT_DELETED_SUCCESSFULLY,
} from "../../../common/constants";
import DeleteConfirmationModal from "../Common/DeleteConfirmationModal";
import AudioVisualiser from "./AudioVisualizer";
import CircularProgressBarModal from "../Common/CircularProgressBarModal";
import { withRouter } from "react-router-dom";

const MAX_RECORDING_TIME = 1 * 30; // 2 minutes in seconds
const RecordingComponent = ({
  onComplete,
  t,
  user,
  voiceIds,
  errorNotification,
  successNotification,
  isAnonymizing,
  setGivenState,
  isRecordingInProgress,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [latestRecording, setLatestRecording] = useState(null);
  const [stopCounter, setStopCounter] = useState(0);
  const [recordings, setRecordings] = useState([]);
  const [waitingRecordings, setWaitingRecordings] = useState([]);
  const [modalActivated, setModalActivated] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [infoTooltipOpen, setInfoTooltipOpen] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [remainingTime, setRemainingTime] = useState(MAX_RECORDING_TIME);
  const [recordingTimer, setRecordingTimer] = useState(null);
  const [sTTTooltipOpen, setSTTTooltipOpen] = useState(false);
  const recordingIcon = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 100"
    width="300"
    height="50"
  >
    <!-- Top wave -->
    <path
      d="M0 50
      Q 50 25, 100 50
      Q 150 75, 200 50
      Q 250 25, 300 50
      Q 350 75, 400 50
      T 500 50"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
    >
      <animate
        attributeName="d"
        dur="3s"
        repeatCount="indefinite"
        values="
          M0 50
          Q 50 75, 100 50
          Q 150 25, 200 50
          Q 250 75, 300 50
          Q 350 25, 400 50
          T 500 50;

          M0 50
          Q 50 25, 100 50
          Q 150 75, 200 50
          Q 250 25, 300 50
          Q 350 75, 400 50
          T 500 50;
        "
      />
    </path>
  </svg>
`;

  useEffect(() => {
    if (updateState) {
      setRecordings(waitingRecordings);
    }
  }, [updateState]);

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      return;
    }

    let stream;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((mediaStream) => {
        setMediaStream(mediaStream); // Set the mediaStream state here

        const recorder = new MediaRecorder(mediaStream);
        setMediaRecorder(recorder);

        const chunks = [];
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      if (mediaRecorder && mediaRecorder.state === "recording") {
        mediaRecorder.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (recordedChunks.length > 0) {
      onComplete(); // Notify the parent component that recording is complete
    }
  }, [recordedChunks, onComplete]);
  useEffect(() => {
    if (recordedChunks.length > 0) {
      handleSaveRecording(recordedChunks); // Pass recordedChunks as a parameter
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (isRecording) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isRecording]);

  useEffect(() => {
    if (remainingTime <= 0) {
      handleRecordStop();
      return;
    }
  }, [remainingTime]);

  const handleRecordStart = () => {
    if (!isRecording && !isPaused) {
      if (remainingTime <= 0) {
        return;
      }
      setRecordedChunks([]);
      const newRecorder = new MediaRecorder(mediaStream);
      setMediaRecorder(newRecorder);
      newRecorder.addEventListener("dataavailable", handleDataAvailable);
      newRecorder.addEventListener("stop", handleStop);

      newRecorder.start();
      const timer = setTimeout(() => {
        if (mediaRecorder && mediaRecorder.state === "recording") {
          mediaRecorder.stop();
          setRemainingTime(0);
        }
      }, remainingTime * 1000);
      setRecordingTimer(timer); // Save the timer in state
      setIsRecording(true);
      setIsPaused(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0) {
      setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
    }
  };

  const handleStop = () => {
    setGivenState("isRecordingInProgress", false);
    setIsRecording(false);
    setIsPaused(false);
  };

  const handleRecordPause = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      setIsRecording(false);
    }
  };

  const handleRecordStop = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      setRemainingTime(MAX_RECORDING_TIME);
      handleStop();
    }
  };

  const handleRecordResume = () => {
    if (mediaRecorder && mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsRecording(true);
      setIsPaused(false);
    }
  };

  async function uploadFile(file, user_id, report_id, name) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", user_id);
    formData.append("name", name);

    try {
      const response = await fetch(API_URL_SPEECH_UPLOAD_VOICE, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const fileData = await response.json();
        return fileData;
      } else {
        const errorData = await response.json();
        return errorData;
      }
    } catch (error) {
      console.error("Upload File API error:", error);
      throw error;
    }
  }

  function decodeBase64Response(responseContentBase64) {
    const byteCharacters = atob(responseContentBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "audio/webm" });
  }

  const handleSaveRecording = async (chunks) => {
    setUpdateState(false);
    if (chunks.length > 0) {
      const recordedBlob = new Blob(chunks, {
        type: "audio/webm",
      });
      const recordingUrl = URL.createObjectURL(recordedBlob);
      const timestamp = new Date().toLocaleString();
      const recording = { timestamp, recordedBlob, recordingUrl };
      setWaitingRecordings((prevRecordings) => [...prevRecordings, recording]);
      setGivenState("isAnonymizing", true);
      try {
        const formData = new FormData();
        formData.append("file", recording.recordedBlob);
        formData.append("name", recording.timestamp); // Use the timestamp as the "name" field

        const response = await axios.post(
          API_URL_SPEECH_UPLOAD_VOICE,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const jsonResponse = response.data;
        const responseContentBase64 = jsonResponse.response_content;

        const decodedBlob = decodeBase64Response(responseContentBase64);

        const anonymizedVoiceUrl = URL.createObjectURL(decodedBlob);

        setWaitingRecordings((prevRecordings) =>
          prevRecordings.map((rec) =>
            rec.timestamp === recording.timestamp
              ? { ...rec, recordingUrl: anonymizedVoiceUrl }
              : rec
          )
        );

        setUpdateState(true);
        if (jsonResponse.voice_id) {
          voiceIds.push(jsonResponse.voice_id);
        }
      } catch (error) {
        console.error("Error uploading and anonymizing recording:", error);
      }
      setGivenState("isAnonymizing", false);
    }
  };

  const onModalClosed = () => {
    setModalActivated(false);
    setDeleteIndex(-1);
  };

  const onModalOpen = (index) => {
    setDeleteIndex(index);
    setModalActivated(true);
  };

  const handleRemoveRecording = async () => {
    console.log("Delete Clicked", voiceIds)
    if (deleteIndex >= 0 && deleteIndex < recordings.length) {
      axios
        .delete(
          `${process.env.REACT_APP_AI}api/delete_file/${voiceIds[deleteIndex]}/`
        )
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            recordings.splice(deleteIndex, 1);
            voiceIds.splice(deleteIndex, 1);
          } else {
            errorNotification({
              code: t(TASK_FILE_NOT_DELETED_SUCCESSFULLY),
              message: t(TASK_FILE_NOT_DELETED_SUCCESSFULLY),
            });
          }

          return onModalClosed();
        })
        .catch((error) => {
          console.error("Error deleting file:", error);
          errorNotification({
            code: this.props.t(TASK_FILE_NOT_DELETED_SUCCESSFULLY),
            message: this.props.t(TASK_FILE_NOT_DELETED_SUCCESSFULLY),
          });
        });
    }
  };

  return (
    <div className="recording-component mt-4">
      <div className="message-container">
        <label>
          {t(
            "Please record your voice message by clicking on the start recording button. Don't hold the button"
          ) + ":"}
          <span className="tooltip-container" style={{ marginLeft: "10px" }}>
            <i className="fa fa-info-circle" aria-hidden="true" id="sTTIconU" />
            <Tooltip
              placement="right"
              isOpen={sTTTooltipOpen}
              target="sTTIconU"
              toggle={() => setSTTTooltipOpen(!sTTTooltipOpen)}
            >
              <p>
                {t(
                  "Your voice message is automatically converted to text. Once a transcript is created and confirmed by the responsible analyst, the corresponding voice file will be deleted in accordance with the applicable laws. You can view the transcription of your voice message at any time under 'My Reports'."
                )}
              </p>
            </Tooltip>
          </span>
        </label>
      </div>
      <div className="button-container">
        <ButtonGroup className="button-group">
          {!isRecording && !isPaused && (
            <Button
              className="record-button me-3"
              color="primary"
              onClick={handleRecordStart}
              disabled={isRecording}
            >
              <FaMicrophone className="me-1" />
              {t("Start Recording")}
            </Button>
          )}
          {isRecording && (
            <Button
              className="record-button me-3"
              color="warning"
              onClick={handleRecordPause}
              disabled={!isRecording}
            >
              {t("Pause Recording")}
            </Button>
          )}
          {isPaused && (
            <Button
              className="record-button me-3"
              color="primary"
              onClick={handleRecordResume}
              disabled={isRecording}
            >
              <FaMicrophone className="me-1" />
              {t("Resume Recording")}
            </Button>
          )}
          <Button
            className="record-button"
            color="danger"
            onClick={handleRecordStop}
            disabled={!isRecording && !isPaused}
          >
            <FaStop className="me-1" />
            {t("Stop Recording")}
          </Button>
          <span className="ms-2 mt-2">
            <i className="fa fa-info-circle" aria-hidden="true" id="infoIcon" />
            <Tooltip
              placement="top"
              isOpen={infoTooltipOpen}
              target="infoIcon"
              toggle={() => setInfoTooltipOpen(!infoTooltipOpen)}
            >
              {t(
                "Your voice  will be processed with a voice distorter. The original voice is not saved. Your voice message is automatically converted to text. Once a transcript is created by the responsible analyst, the corresponding voice file will be deleted in accordance with the applicable laws. You can view the transcription of your voicemail at any time under 'My Reports'."
              )}
            </Tooltip>
          </span>
          {isRecordingInProgress ? (
            <Label className="pt-2 ps-5">
              {t("Recording in progress") + "..."}
            </Label>
          ) : isAnonymizing ? (
            <Fragment>
              {/* <Label className="pt-2 ps-5">
                 {t("Please wait, we are anonymizing your voice file.")}
              </Label> */}
              <CircularProgressBarModal isOpen={isAnonymizing} t={t} />
            </Fragment>
          ) : (
            <>
              <div className="remaining-time">
                <Label className="pt-2 ps-5">
                  {Math.max(remainingTime, 0)} {t("seconds remaining")}
                </Label>
              </div>
            </>
          )}
        </ButtonGroup>
        <AudioVisualiser
          isRecording={isRecording}
          onStop={() => setIsRecording(false)}
        />
      </div>

      {recordings.length > 0 && (
        <div className="saved-recordings">
          <div className="label">{t("Saved recordings")}</div>
          {recordings.map((recording, index) => (
            <div key={index} className="recording-item">
              <Label className="timestamp">{recording.timestamp}</Label>
              <audio controls src={recording.recordingUrl} />

              <Button
                className="remove-button"
                color="danger"
                onClick={() => onModalOpen(index)}
              >
                <FaTrash className="me-1" />
                {t("Remove")}
              </Button>
            </div>
          ))}
        </div>
      )}

      <DeleteConfirmationModal
        t={t}
        title={t("voice")}
        modalActivated={modalActivated}
        onModalClosed={onModalClosed}
        onDeleteSubmit={handleRemoveRecording}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStateToProps, { errorNotification, successNotification })(
      RecordingComponent
    )
  )
);
