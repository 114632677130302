import {
    ACTION_EDIT_INFO_ORGANIZATION,
    ACTION_EDIT_INFO_ORGANIZATION_SUCCESS,
    ACTION_EDIT_INFO_ORGANIZATION_FAILURE,
    ACTION_GET_INFO_ORGANIZATION_FAILURE,
    ACTION_GET_INFO_ORGANIZATION_SUCCESS,
    ACTION_GET_INFO_ORGANIZATION
} from './actionTypes';

export const getInfoOrganization = () => ({
    type: ACTION_GET_INFO_ORGANIZATION
});

export const editInfoOrganization = (logo, intro, name) => ({
    type: ACTION_EDIT_INFO_ORGANIZATION,
    payload: {logo, intro, name}
});

export const onEditInfoOrganizationSuccess = (response) => ({
    type: ACTION_EDIT_INFO_ORGANIZATION_SUCCESS,
    payload: response
});

export const onEditInfoOrganizationFailure = (error) => ({
    type: ACTION_EDIT_INFO_ORGANIZATION_FAILURE,
    payload: error
});

export const onGetInfoOrganizationFailure = (error) => ({
    type: ACTION_GET_INFO_ORGANIZATION_FAILURE,
    payload: error
});

export const onGetInfoOrganizationSuccess = (response) => ({
    type: ACTION_GET_INFO_ORGANIZATION_SUCCESS,
    payload: response
});