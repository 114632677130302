import React from "react";
import { AvField, AvRadioGroup, AvRadio, AvForm } from "availity-reactstrap-validation";
import { FormGroup, Row, Col, Label } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount = () => {
  //   console.log("Props", this.props)
  // }


  renderContactForm = () => {
    const { t, contactForm, handleInputChange, userSelection, user } = this.props;
    // console.log("User", contactForm);
    const defaultValues = {
      title: this.props?.contactForm?.title
    }
    return (
      <div>

        <FormGroup>
          <Label>{t("What is your relation to the company?")}</Label>
          <AvRadioGroup
            name="relation"
            required={userSelection === "yes" && !contactForm.relation}
          >
            <Row className="mb-3">
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("Employee")}
                  value="Employee"
                  name="relation"
                  onChange={handleInputChange}
                  checked={contactForm.relation === "Employee"}
                />
              </Col>
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("External")}
                  value="External"
                  name="relation"
                  onChange={handleInputChange}
                  checked={contactForm.relation === "External"}
                />
              </Col>
            </Row>
          </AvRadioGroup>
        </FormGroup>
        
        <AvForm  model={defaultValues} >
          <Label>{t("Title (Optional)")}</Label>
          <AvRadioGroup name="title">
            <Row className="mb-3">
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("Mr.")}
                  value="Mr."
                  name="title"
                  onChange={handleInputChange}
                  checked={user?.gender === "Mr." || contactForm.title === "Mr."}
                />
              </Col>
              <Col xs="6" sm="3" md="3" lg="3">
                <AvRadio
                  label={t("Mrs.")}
                  value="Mrs."
                  name="title"
                  onChange={handleInputChange}
                  checked={contactForm.title === "Mrs." || user?.gender === "Mrs."}
                />
              </Col>
            </Row>
          </AvRadioGroup>
        </AvForm>

        <Row className="mb-3">
          <Col xs="4" sm="4" md="4" lg="3">
            <AvField
              name="firstName"
              label={t("First name")}
              type="text"
              required={userSelection === "yes" && !contactForm.firstName}
              value={contactForm.firstName}
              // defaultValue={user?.first_name}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs="4" sm="4" md="4" lg="3">
            <AvField
              name="lastName"
              label={t("Last name")}
              type="text"
              value={contactForm.lastName}
              // defaultValue={user?.last_name}
              required={userSelection === "yes" && !contactForm.lastName}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs="4" sm="4" md="4" lg="3">
            <AvField
              name="organization"
              label={t("Organization")}
              // required={userSelection === "yes" && !contactForm.organization}
              type="text"
              value={contactForm.organization}
              // defaultValue={user?.organization?.name}
              onChange={handleInputChange}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs="9" sm="9" md="9" lg="9">
            <AvField
              name="position"
              label={t("Position")}
              // required={userSelection === "yes" && !contactForm.position}
              type="text"
              value={contactForm.position}
              // defaultValue={user?.position}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return <div>{this.renderContactForm()}</div>;
  }
}

// export default ContactForm;
const mapStateToProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStateToProps, { })(
      ContactForm
    )
  )
);
