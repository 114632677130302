import axios from "axios";
import { GDPR_DOMAIN_URL } from "../../../common/constants";

export default class ReportsService {
  static instance;
  resource = GDPR_DOMAIN_URL
  constructor() {}

  static getInstance() {
    if (!ReportsService.instance) {
        ReportsService.instance = new ReportsService();
    }

    return ReportsService.instance;
  }

  async list(pageIndex, pageSize, filters) {
    const response = await axios.post(`${this.resource}report.list`, {
        pageSize: pageSize,
        pageIndex: pageIndex,
        ...filters,
    } , {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
        }
    });

    return response.data.data;
  }
}
