import axios from 'axios';
import {
    GDPR_DOMAIN_URL,
    GDPR_API_URL_FETCH_RISK_RATINGS,
    GDPR_API_URL_REPORT_CATEGORY_LIST_CASE,
    GDPR_API_URL_GET_REPORT_DETAIL,
    GDPR_API_URL_REPORT_ASSIGNED_ANALYST
    ,API_URL_CUSTOMER_API,
    GDPR_API_URL_REPORT_REMOVE_LINK_CASE,
    GDPR_API_URL_REPORT_ADD_LINK_CASE,
    GDPR_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION,
    GDPR_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES,
    GDPR_API_URL_REPORT_DELETE,
    API_URL_UPDATE_REPORT_STATUS,
    CM_API_URL_UPDATE_REPORT_STATUS,
    GDPR_API_URL_REPORT_MARK_AS_DELETE
  } from "../../../../../../../common/constants";

export default class ReportIncidenService {
    static instance;

    static getInstance() {
        if (!ReportIncidenService.instance) {
            ReportIncidenService.instance = new ReportIncidenService();
        }
        return ReportIncidenService.instance;
    }

    async FetchCategories() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(GDPR_API_URL_REPORT_CATEGORY_LIST_CASE,{} ,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async FetchCategoriesRequest() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.get(`${GDPR_DOMAIN_URL}data_subject_request/categories`,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async UpdateAdminCategories(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.put(`${GDPR_API_URL_REPORT_UPDATE_ADMIN_CATEGORIES}` ,
        {
            reportCaseId: payload.reportCaseId,
            category: payload.category,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }
    async FetchRelatedAssets() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.get(`${GDPR_DOMAIN_URL}asset/index` ,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async RemoveAssets(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_DOMAIN_URL}report/remove_related_asset` ,
        {
            reportCaseId: payload.caseId,
            assetId: payload.assetId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok || false; 
    }

    async AddAssets(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_DOMAIN_URL}report/add_related_asset` ,
        {
            reportCaseId: payload.caseId,
            assetId: payload.assetId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok|| false; 
    }


    async FetchCasess(caseId) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.get(`${API_URL_CUSTOMER_API}report/${caseId}/list_select_dsr` ,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async RemoveLinkedIncident(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_API_URL_REPORT_REMOVE_LINK_CASE}` ,
        {
            reportCase: payload.caseId,
            removedLinkedCase: payload.linkCaseId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok || false; 
    }

    async AddLinkedIncident(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_API_URL_REPORT_ADD_LINK_CASE}` ,
        {
            reportCase: payload.caseId,
            addedLinkedCase: payload.linkCaseId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok|| false; 
    }

    async FetchRiskRating() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.get(GDPR_API_URL_FETCH_RISK_RATINGS ,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async FetchAnalystsAndAnalystAdmins() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_DOMAIN_URL}analystAndAdminAnalyst.list` ,{},{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async ChangeAnalystsAndAnalystAdmins(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_DOMAIN_URL}report/${payload.caseId}/changeReadonlyAnalysts` ,
        {
            readonlyAnalysts: payload.readonlyAnalysts,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok|| false; 
    }

    async UpdateAdminDescription(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.put(`${GDPR_API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION}` ,
        {
            description: payload.description,
            reportCaseId: payload.caseId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok|| false; 
    }

    async FetchIncidentReportOverView(caseId) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_API_URL_GET_REPORT_DETAIL}` ,
        {
            report_case_id: caseId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }

    async updateAssignedAnalyst(payload){
        const response = await axios.post(`${GDPR_API_URL_REPORT_ASSIGNED_ANALYST}`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async mentionCommentSearch(caseId, params = {}){
        const response = await axios.get(
            `${GDPR_DOMAIN_URL}report/${caseId}/mentionCommentSearch`, 
            {
                params  :   params,
                headers :   {
                  Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`
                }
            }
        );
    
        return response.data?.data;
      }
    
      async fetchTasks(caseId, params = {}){
        const response = await axios.post(`${GDPR_DOMAIN_URL}report/${caseId}/tasks`, params, {
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data
    }

    async DeleteReport(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_API_URL_REPORT_DELETE}` ,
        {
            report_case_id: payload.caseId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok || false; 
    }

    async UpdateStatus(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const activeModule = localStorage.getItem('module');
        const response = await axios.post(`${activeModule === 'wb' ? API_URL_UPDATE_REPORT_STATUS :CM_API_URL_UPDATE_REPORT_STATUS}` ,
        {
            status: payload.status,
            report_case_id: payload.caseId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok || false; 
    }

    async UpdatePriority(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.put(`${GDPR_DOMAIN_URL}report/change_priority` ,
        {
            report_case_id: payload.caseId,
            priority: payload.priority,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.data?.ok || false; 
    }
    async PendingDeletionStatus(payload) {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(`${GDPR_API_URL_REPORT_MARK_AS_DELETE}` ,
        {
            reason: payload.reason,
            report_case_id: payload.caseId,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response?.ok || false; 
    }
}
