import { withNamespaces } from 'react-i18next';
import { memo, useState, useEffect } from 'react';
import { Col, Row, TabPane} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { 
	errorNotification, 
	successNotification
} from '../../../../../store/actions';
import { connect } from 'react-redux';
import axios from 'axios';

import QuestionarService from '../../../../../components/Questionar/service';
import Questionar from '../../../../../components/Questionar';
import { GDPR_DOMAIN_URL } from '../../../../../common/constants';

const AuthorityReportQuestionnaire = (props) => {
    const {
        reportCaseId
    } = props;
    const [isCreateNewFormSubmiting, setIsCreateNewFormSubmiting] = useState(false);

	const [resetCreateNewForm, setResetCreateNewForm] = useState(false);

	const handleFetchQuestions = useQuery({
		queryKey: ['data-protection-report-authority-questions-list'],
		queryFn: async () => {
			const service = QuestionarService.getInstance();

			return await service.questions('authority_reporting', props.Organization.id, {
				reportCase  :   reportCaseId
			});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(props.t('An error occurred while fetching questions.'), {
				type: 'error',
			});
		},
	});

	const handleFetchOldAnswers = useQuery({
		queryKey: ['data-protection-report-authority-questions-answers'],
		queryFn: async () => {
			const service = QuestionarService.getInstance();

			return await service.fetchPrevAnswers('authority_reporting', {
				reportCase  :   reportCaseId
			});
		},
		cacheTime: 0,
		enabled: false,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(props.t('An error occurred while fetching questions.'), {
				type: 'error',
			});
		},
	});

	const handleSubmit = async (data) => {
		const payload = {
			...data,
			software	    : 	null,
			hardware	    :	null,
            reportCase      :   reportCaseId
		}

		setIsCreateNewFormSubmiting(true);

		axios.post(`${GDPR_DOMAIN_URL}report.create`, payload, {
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		})
		.then((res) => {
			props.successNotification({
				code: props.t("Report Submitted"),
				message: props.t("Report Submitted"),
			});

			setResetCreateNewForm(true);
		})
		.catch((error) => {
			this.props.errorNotification({
				code: 'validation_error',
				message: props.t('Error!'),
			});
		})
		.finally(() => {
			setIsCreateNewFormSubmiting(false);
		});
	}

	const questionareSettings = {
		confirmFilledInputsForm	:	{
			enabled	:	false
		},
		buttons	:	{
			submit	:	{
				enabled	:	true,
				title	:	"Send"
			}
		}
	};

	useEffect(() => {
		handleFetchOldAnswers.refetch();
	}, []);

	useEffect(() => {
		if(handleFetchOldAnswers.data){
			handleFetchQuestions.refetch();
		}
	}, [handleFetchOldAnswers.data]);

	return (
		<TabPane tabId='authority_reporting' className='pt-4'>
			<Row>
				<Col sm="12">
					<div className="p-2">
						<Questionar 
							questionnaireId={`new-report`}
							questionnaireType={'authority_reporting'}
							loading={handleFetchQuestions.isPaused || handleFetchQuestions.isLoading || handleFetchQuestions.isFetching}
							questions={handleFetchQuestions.data || []}
							onValidSubmit={handleSubmit}
							isFormSubmiting={isCreateNewFormSubmiting}
							user={{
								token: props.token,
								...props.user
							}}
							preFilledAnswers={handleFetchOldAnswers.data || []}
							resetFormModel={resetCreateNewForm}
							settings={questionareSettings}
						/>
					</div>
				</Col>
			</Row>
		</TabPane>
	);
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const {Organization} = state;
	
	return {
		token,
		user,
		Organization
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {
		errorNotification,
		successNotification
	})(
		memo(AuthorityReportQuestionnaire)
	)
);
