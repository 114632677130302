import { reactI18nextModule } from 'react-i18next';
import { API_URL_TRANSLATION_LIST } from './common/constants';
import i18n from 'i18next';
import axios from 'axios';
import detector from 'i18next-browser-languagedetector';
import UrlUtils from './services/utils/UrlUtils';

export const getCurrentLanguage = () => {
	return localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').replace('_', '-') : null;
};

const resources = {};
const storedLang = localStorage.getItem('i18nextLng') || 'en';

export const fetch = async (lng = localStorage.getItem('i18nextLng'), dir) => {
	let urlUtils = new UrlUtils();

	axios
		.post(API_URL_TRANSLATION_LIST, {
			code_lang: lng,
			organization_id: urlUtils.getOrganizationId(),
			// module: window?.location.href?.includes("data-protection") ? "gd" : "wb"
			module: "gd"
		})
		.then((res) => {
			const data = {
				[lng.replace('-', '_')]: {
					translation: res.data.data,
				},
			};

			for (var key in data[`${lng.replace('-', '_')}`].translation) {
				let letter = key ? key[0] : '';
				if (
					data[`${lng.replace('-', '_')}`].translation.hasOwnProperty(key) &&
					letter === letter.toUpperCase() &&
					!(key.charAt(0).toLowerCase() + key.slice(1) in data[`${lng.replace('-', '_')}`].translation)
				) {
					Object.assign(data[`${lng.replace('-', '_')}`].translation, {
						[key.toLowerCase()]: data[`${lng.replace('-', '_')}`].translation[key].toLowerCase(),
					});
				}
			}

			if (resources) {
				Object.assign(resources, data);
			}

			localStorage.setItem('lang_dir', dir);
			i18n.changeLanguage(lng.replace('-', '_'));
			localStorage.setItem('i18nextLng', lng);

			const HTMLElement = document.getElementsByTagName('html');

			switch (dir) {
				case 'ltr': {
					HTMLElement[0].classList.remove('direction-rtl');
					HTMLElement[0].classList.add('direction-ltr');
					HTMLElement[0].setAttribute('dir', 'ltr');
					break;
				}
				case 'rtl': {
					HTMLElement[0].classList.remove('direction-ltr');
					HTMLElement[0].classList.add('direction-rtl');
					HTMLElement[0].setAttribute('dir', 'rtl');
					break;
				}
				default:
					break;
			}

			return data;
		});
};

i18n
	.use(detector)
	.use(reactI18nextModule)
	.init({
		resources,
		storedLang,
		// lng: localStorage.getItem('i18nextLng')?.includes('-') ? localStorage.getItem('i18nextLng').split('-')[0] : localStorage.getItem('i18nextLng'),
		lng: localStorage.getItem('i18nextLng'),
		fallbackLng: 'de',
		dir: localStorage.getItem('lang_dir') === 'ltr' ? 'ltr' : 'rtl',
		keySeparator: false,
		nsSeparator: false,
		returnObjects: true,
		interpolation: {
			escapeValue: false,
		},
	});
export default i18n;
