import { Component, Fragment } from "react";
import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import "toastr/build/toastr.min.css";
import DateUtils from "../../services/utils/DateUtils";
import axios from "axios";
import {
  API_URL_GET_REPORT_LIST,
  CM_API_URL_GET_REPORT_LIST,
  API_URL_REPORT_CATEGORY_LIST,
  CM_API_URL_REPORT_CATEGORY_LIST,
  INTERNATIONAL_DATE_FORMAT,
  API_URL_REPORT_CATEGORY_LIST_CASE,
  CM_API_URL_REPORT_CATEGORY_LIST_CASE
} from "../../common/constants";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import anonyReportImage from "../../assets/images/report_anonymously.svg";
import confidentlyReportImage from "../../assets/images/report_confidently.svg";
import voiceReportIcon from "../../assets/images/microphone.svg";
import arrangeMeetingImage from "../../assets/images/arrange_meeting.svg";
import { REPORT_STATUS_LIST } from "../../components/constants";
import UserUtils from "../../services/utils/UserUtils";
import { setCaseId } from "../../store/actions";
import TableWithPagination from "../../components/Pagination";
import { checkExistModule } from "../../helpers/module_helper";
import ModuleContext from "../../contexts/ModuleContext";
import { withRouter } from "react-router-dom";

class ReportList extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("My reports"), link: "#" },
      ],
      page: 1,
      sizePerPage: 10,
      totalSize: 0,
      reports: [],
      categories: [],
      user: props.user,
      isAdmin: false,

      filterStatuses: [],
      selectedFilterStatus: [],

      filtersCategories: [],
      selectedFiltersCategories: [],

      // selectbox type changed to multiselct
      selectedFilterCategory: [],

      filtersRiskRates: [],
      selectedFilterRiskRate: [],

      displayNotFoundError: false,
    };
    this.dateUtils = new DateUtils();
    this.userUtils = new UserUtils();
    this.filterByStatus = this.filterByStatus.bind(this);
    this.filterByCategory = this.filterByCategory.bind(this);
    this.filterByRiskRate = this.filterByRiskRate.bind(this);
  }

  getFilters() {
    const filters = {};

    if (this.state.selectedFilterStatus.value !== -1) {
      filters.status = this.state.selectedFilterStatus.value;
    }

    if (
      typeof this.state.selectedFiltersCategories !== "undefined" &&
      this.state.selectedFiltersCategories.length
    ) {
      let cats = [];
      this.state.selectedFiltersCategories.forEach((number) =>
        cats.push({ value: number.value })
      );

      filters.categories = cats;
    }

    if (this.state.isAdmin && this.state.selectedFilterRiskRate.value !== -1) {
      filters.risk = this.state.selectedFilterRiskRate.value;
    }

    return filters;
  }

  filterByStatus(val) {
    console.log("filterByStatus");
    console.log(val);

    this.setState(
      {
        selectedFilterStatus: val,
      },
      () => {
        this.fetchReports(this.getFilters());
      }
    );
  }

  filterByCategory(val) {
    console.log("filterByCategory");
    console.log(val);

    this.setState(
      {
        selectedFiltersCategories: val,
      },
      () => {
        this.fetchReports(this.getFilters());
      }
    );
  }

  filterByRiskRate(val) {
    console.log("filterByRiskRate");
    console.log(val);

    this.setState(
      {
        selectedFilterRiskRate: val,
      },
      () => {
        this.fetchReports(this.getFilters());
      }
    );
  }

  render() {
    let columns = [];

    if (this.state.isAdmin) {
      columns.push({
        dataField: "risk_type",
        text: this.props.t("Risk"),
        sort: false,
      });
    }

    columns = columns.concat([
      {
        dataField: "type",
        text: this.props.t("Type"),
        sort: false,
      },
      {
        dataField: "case_id",
        text: this.props.t("Case ID"),
        sort: false,
        style: { display: "table-cell", maxWidth: "200px" },
        headerStyle: { maxWidth: "200px" },
      },
      {
        dataField: "description",
        text: this.props.t("Description"),
        sort: false,
      },
      {
        dataField: "category",
        text: this.props.t("Category"),
        sort: true,
        style: { display: "table-cell", maxWidth: "260px" },
        headerStyle: { maxWidth: "260px" },
      },
      {
        dataField: "source",
        text: this.props.t("Channel"),
        sort: true,
      },
      {
        dataField: "submission_date",
        text: this.props.t("Submission date"),
        sort: true,
      },
      {
        dataField: "closing_date",
        text: this.props.t("Closing date"),
        sort: true,
      },
      {
        dataField: "analyst_assigned",
        text: this.props.t("Assigned analyst"),
        sort: true,
      },
      {
        dataField: "status",
        text: this.props.t("Status"),
        sort: true,
      },
    ]);

    return (
      <Fragment>
        <div className="page-content">
          <Container fluid style={{ minHeight: '400px' }}>
            <Breadcrumbs
              title={this.props.t("My reports")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col className="col-12">
                <Row>
                  <Col sm="12" md="4" className="mb-2">
                    <Select
                      classNamePrefix="select2-selection"
                      options={this.state.filterStatuses}
                      value={this.state.selectedFilterStatus}
                      onChange={this.filterByStatus}
                      placeholder={this.props.t("Select status")}
                    />
                  </Col>
                  <Col sm="12" md="4" className="mb-2">
                    {/* <Select
                                            classNamePrefix="select2-selection"
                                            options={this.state.filtersCategories}
                                            value = {this.state.selectedFiltersCategories}
                                            onChange={this.filterByCategory}
                                            placeholder={this.props.t('Select categories')}
                                        /> */}
                    <Select
                      className={"select--filter-categories"}
                      classNamePrefix="select2-selection"
                      options={this.state.filtersCategories}
                      value={this.state.selectedFiltersCategories}
                      onChange={this.filterByCategory}
                      isMulti
                      style={{ height: 30, overflowY: "auto" }}
                      placeholder={this.props.t("Select categories")}
                    />
                  </Col>
                  {this.state.isAdmin ? (
                    <Col sm="12" md="4" className="mb-2">
                      <Select
                        classNamePrefix="select2-selection"
                        options={this.state.filtersRiskRates}
                        value={this.state.selectedFilterRiskRate}
                        onChange={this.filterByRiskRate}
                        placeholder={this.props.t("Select risk")}
                      />
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="pt-0">
                    {this.state.displayNotFoundError ? (
                      <Alert color="warning" style={{ marginTop: 30 }}>
                        {this.props.t("No Data Found")}
                      </Alert>
                    ) : (
                      <TableWithPagination
                        columns={columns}
                        // pagination={pageOptions}
                        totalSize={this.state.totalSize}
                        sizePerPage={this.state.sizePerPage}
                        data={this.state.reports}
                        fetchData={(e) => this.fetchReports(e)}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }

  getReportCategories(reportCategories) {
    let result = [];
    reportCategories.map((reportCategory) => {
      for (let i = 0; i < this.state.categories.length; i++) {
        if (
          parseInt(reportCategory) === parseInt(this.state.categories[i].id)
        ) {
          result.push(this.props.t(this.state.categories[i].title));

          break;
        }
      }
    });
    return result.join(", ");
  }

  getReportAnalystAssigned(assigneds) {
    let result = [];

    if (!assigneds) {
      assigneds = [];
    } else if (!Array.isArray(assigneds) && typeof assigneds === "object") {
      assigneds = [assigneds];
    }

    if (assigneds.length > 0) {
      assigneds.map((item) => {
        result.push(`${item.first_name} ${item.last_name}`);
      });
    }

    return result;
  }

  renderReportRisk(report) {
    let riskIcon = "risk-rate ";
    if (report.risk_rate === "high") {
      riskIcon += "bg-danger";
    } else if (report.risk_rate === "medium") {
      riskIcon += "bg-warning";
    } else {
      riskIcon += "bg-success";
    }
    return <span className={riskIcon}></span>;
  }

  renderReportStatus(report) {
    const status = REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(report.status)
    );
    return (
      <div className={status.className}>{this.props.t(status.titleKey)}</div>
    );
  }

  renderReportType(item) {
    if (item.type === 1) {
      return (
        <img
          alt="Anonymously"
          className="rounded-circle"
          style={{ width: "36px" }}
          src={anonyReportImage}
        />
      );
    } else if (item.type === 2) {
      return (
        <img
          alt="Confidential"
          className="rounded-circle"
          style={{ width: "36px" }}
          src={confidentlyReportImage}
        />
      );
    } else if (item.type === 4) {
      return (
        <img
          alt="Voice report"
          // className="rounded-circle"
          style={{ width: "36px" }}
          src={voiceReportIcon}
        />
      );
    } else {
      return (
        <img
          alt="Meeting"
          className="rounded-circle"
          style={{ width: "36px" }}
          src={arrangeMeetingImage}
        />
      );
    }
  }

  renderReportsList(list) {
    return list.map((item) => {
      const caseId = (
        <Link
          className="text-dark"
          to={`report/detail`}
          onClick={() => {
            this.props.onSetCaseId(item.case_id);
            localStorage.setItem("caseId", item.case_id);
          }}
        >
          {item.secondary_case_id}
        </Link>
      );

      const categoryList = this.getReportCategories(item.category);
      return {
        risk_type: this.state.isAdmin ? this.renderReportRisk(item) : null,
        id: item.case_id,
        case_id: caseId,
        secondaryCaseId: item.secondary_case_id,
        type: this.renderReportType(item),
				description: item.description ? (item.description.length > 70 ? `${item.description.substring(0, 70)}...` : item.description) : '',
        category: categoryList,
        source: <div className="badge text-bg-primary">{item.source}</div>,
        submission_date: this.dateUtils.convertTimeStampToDate(
          item.submission_date,
          INTERNATIONAL_DATE_FORMAT
        ),
        closing_date: this.dateUtils.convertTimeStampToDate(
          item.closing_date,
          INTERNATIONAL_DATE_FORMAT
        ),
        analyst_assigned: this.getReportAnalystAssigned(
          item.analyst_assigned
        ).join(", "),
        status: (
          <div>
            {this.renderReportStatus(item)}
            <div>
              <small className="text-muted">
                {this.props.t("Updated")}:{" "}
                {this.dateUtils.convertTimeStampToDate(
                  item.status_updating_date,
                  INTERNATIONAL_DATE_FORMAT
                )}
              </small>
            </div>
          </div>
        ),
      };
    });
  }

  fetchCategoryList() {
    if (this.props.token) {
      return axios.post(
				this.context.selectedModule === 'wb' ? API_URL_REPORT_CATEGORY_LIST_CASE : CM_API_URL_REPORT_CATEGORY_LIST_CASE,
				{},
				{
					headers: {
						Authorization: `Bearer ${this.props.token}`,
					},
				},
			);
    } else {
      return axios.post(
        this.context.selectedModule === 'wb' ? API_URL_REPORT_CATEGORY_LIST : CM_API_URL_REPORT_CATEGORY_LIST,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      );
    }
  }

  makeFiltersReady() {
    const filterStatuses = [
      {
        value: "",
        label: this.props.t("Select status"),
      },
    ].concat(
      REPORT_STATUS_LIST.map((item) => {
        return {
          value: item.id,
          label: this.props.t(item.titleKey),
        };
      })
    );

    const categoriesFilters = this.state.categories.map((category) => {
      return {
        value: category.id,
        label: this.props.t(category.title),
      };
    });

    this.setState({
      filterStatuses: filterStatuses,
      filtersCategories: categoriesFilters,
      filtersRiskRates: [
        {
          value: "",
          label: this.props.t("All Risk"),
        },
        {
          value: "high",
          label: this.props.t("High"),
        },
        {
          value: "medium",
          label: this.props.t("Medium"),
        },
        {
          value: "low",
          label: this.props.t("Low"),
        },
      ],
    });
  }

  async fetchReports(payload) {
    Object.assign(payload, {
      pageSize: this.state.sizePerPage,
      pageIndex: payload && payload.pageIndex ? payload.pageIndex : 1,
    });
    try {
      const res = await axios.post(this.context.selectedModule === 'wb' ? API_URL_GET_REPORT_LIST : CM_API_URL_GET_REPORT_LIST, payload, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      });
      if (
        typeof res.data.data !== "undefined"
      ) {
        this.setState({
          reports: this.renderReportsList(res.data.data.reports),
          displayNotFoundError: false,
          totalSize: res.data.data.itemsCount,
        });
      } else {
        this.setState({
          reports: [],
          displayNotFoundError: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  makeListReady() {
    this.fetchCategoryList().then((response) => {
      this.setState({
        categories: response.data.data.filter((cat) => cat.flag === true),
      });
      this.fetchReports({}).then(() => {
        this.makeFiltersReady();
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this.setState(
        {
          isAdmin: this.userUtils.isAnalystOrAnalystAdmin(this.props.user),
        },
        () => {
          // SPIK-1010
          this.makeListReady();
          this.makeFiltersReady();
        }
      );
    }
  }

  componentDidMount() {
		checkExistModule(this);

    this.setState(
      {
        isAdmin: this.userUtils.isAnalystOrAnalystAdmin(this.props.user),
      },
      () => {
        this.makeListReady();
      }
    );
    // }

    if (this.props.App.loaded) {
      this.makeFiltersReady();
    }
  }
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  return { token, user, App };
};

const mapDispatchToProps = (dispatch) => ({
  onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
});

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispatchToProps)(ReportList))
);
