import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import i18n, { fetch } from "../../../i18n";
import UrlUtils from "../../../services/utils/UrlUtils";
import { post } from "../../../helpers/request_helper";
import { authCurrentUser } from "../../../store/auth/login/actions";
import {
  API_URL_CHANGE_LANGUAGE,
} from "../../../common/constants";

class LanguageDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.urlUtils = new UrlUtils();
    this.state = {
      selectedLng: localStorage.getItem("i18nextLng"),
      authLanguages: [],
      organizationId: this.urlUtils.getOrganizationId(),
    };
    this.toggle = this.toggle.bind(this);
    this.changeLanguageAction.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    this.setState({
      authLanguages: this.props.organization.languages,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.organization !== this.props.organization) {
      this.setState({
        authLanguages: this.props.organization.languages,
      });
    }
  }

  changeLanguageAction = (lng) => {
    const lang_dir = lng.direction === "ltr" ? "ltr" : "rtl";
    // const lang_code = lng.language_code.includes("-")
    //   ? lng.language_code.split("-")[0]
    //   : lng.language_code;
    const lang_code = lng.language_code;
    if (this.props.user) {
      if (
        localStorage.getItem("lang_dir") &&
        lang_dir !== localStorage.getItem("lang_dir")
      ) {
        // i18n.changeLanguage(lang_code);
        // localStorage.setItem("lang_dir", lang_dir);
        // localStorage.setItem("reload", "true");
        this.props.authCurrentUser();
        this.setState({
          selectedLng: lang_code,
        });
      }
      
      // i18n.changeLanguage(lang_code);
      // localStorage.setItem("lang_dir", lang_dir);
      // localStorage.setItem("i18nextLng", lang_code);

      this.props.authCurrentUser();
      this.setState({
        selectedLng: lang_code,
      });

      fetch(lang_code, lang_dir);
      post(
        API_URL_CHANGE_LANGUAGE,
        {
          lang: lng,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      ).then(() => {
        i18n.changeLanguage(lng.language_code);
        this.props.authCurrentUser();
        this.setState({
          selectedLng: lng,
        });
      });

      // ----------- testing
      // post(
      // 	API_URL_TRANSLATION_LIST,
      // 	{
      // 		code_lang: lang_code,
      // 		organization_id: this.state.organizationId,
      // 	},
      // 	{
      // 		headers: {
      // 			Authorization: `Bearer ${this.props.token}`,
      // 		},
      // 	}
      // ).then((res) => {
      // 	if (localStorage.getItem("lang_dir") && lang_dir !== localStorage.getItem("lang_dir")) {
      // 		i18n.changeLanguage(lang_code);

      // 		localStorage.setItem("lang_dir", lang_dir);
      // 		localStorage.setItem("reload", true);

      // 		this.props.authCurrentUser();
      // 		this.setState({
      // 			selectedLng: lang_code,
      // 		});
      // 	}

      // 	i18n.changeLanguage(lang_code);
      // 	localStorage.setItem("lang_dir", lang_dir);

      // 	this.props.authCurrentUser();
      // 	this.setState({
      // 		selectedLng: lang_code,
      // 	});
      // });
    } else {
      // i18n.changeLanguage(lang_code);
      // localStorage.setItem("i18nextLng", lang_code);
      // localStorage.setItem("lang_dir", lang_dir);
      this.setState({
        selectedLng: lang_code,
      });

      fetch(lang_code,lang_dir);
      i18n.changeLanguage(lng.language_code);
      this.setState({
        selectedLng: lng.language_code,
      });
      // localStorage.setItem("reload", true);
    }

    // if (this.props.user) {
    // 	post(
    // 		API_URL_CHANGE_LANGUAGE,
    // 		{
    // 			lang: lng
    // 		},
    // 		{
    // 			headers: {
    // 				Authorization: `Bearer ${this.props.token}`
    // 			}
    // 		}
    // 	).then(() => {
    // 		i18n.changeLanguage(lng);
    // 		this.props.authCurrentUser();
    // 		this.setState({
    // 			selectedLng: lng
    // 		});
    // 	});
    // } else {
    // 	i18n.changeLanguage(lng.language_code);
    // 	this.setState({
    // 		selectedLng: lng.language_code,
    // 	});
    // 	localStorage.setItem("reload", true);
    // }
  };

  render() {
    let languages =
      this.props.organization && this.props.organization.languages;

    return (
      <React.Fragment>
        {languages && languages.length > 1 ? (
          <Dropdown
            isOpen={this.state.menu}
            toggle={this.toggle}
            className="d-sm-inline-block"
          >
            <DropdownToggle
              tag="button"
              className="btn header-item waves-effect"
              // style={{ paddingTop: "1.3rem" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17 17"
                // style={{ position: "absolute" }}
                // fill= "#ffffff"
              >
                <g></g>
                <path d="M8.516 0c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5-3.814-8.5-8.5-8.5zM1.041 9h2.937c0.044 1.024 0.211 2.031 0.513 3h-2.603c-0.481-0.906-0.776-1.923-0.847-3zM3.978 8h-2.937c0.071-1.077 0.366-2.094 0.847-3h2.6c-0.301 0.969-0.467 1.976-0.51 3zM5.547 5h5.896c0.33 0.965 0.522 1.972 0.569 3h-7.034c0.046-1.028 0.239-2.035 0.569-3zM4.978 9h7.035c-0.049 1.028-0.241 2.035-0.572 3h-5.891c-0.331-0.965-0.524-1.972-0.572-3zM13.013 9h2.978c-0.071 1.077-0.366 2.094-0.847 3h-2.644c0.302-0.969 0.469-1.976 0.513-3zM13.013 8c-0.043-1.024-0.209-2.031-0.51-3h2.641c0.48 0.906 0.775 1.923 0.847 3h-2.978zM14.502 4h-2.354c-0.392-0.955-0.916-1.858-1.55-2.7 1.578 0.457 2.938 1.42 3.904 2.7zM9.074 1.028c0.824 0.897 1.484 1.9 1.972 2.972h-5.102c0.487-1.071 1.146-2.073 1.97-2.97 0.199-0.015 0.398-0.030 0.602-0.030 0.188 0 0.373 0.015 0.558 0.028zM6.383 1.313c-0.629 0.838-1.151 1.737-1.54 2.687h-2.314c0.955-1.267 2.297-2.224 3.854-2.687zM2.529 13h2.317c0.391 0.951 0.915 1.851 1.547 2.689-1.561-0.461-2.907-1.419-3.864-2.689zM7.926 15.97c-0.826-0.897-1.488-1.899-1.978-2.97h5.094c-0.49 1.072-1.152 2.075-1.979 2.972-0.181 0.013-0.363 0.028-0.547 0.028-0.2 0-0.395-0.015-0.59-0.030zM10.587 15.703c0.636-0.842 1.164-1.747 1.557-2.703h2.358c-0.968 1.283-2.332 2.247-3.915 2.703z" />
              </svg>

              <sup
                style={{
                  display: "inline-block",
                  verticalAlign: "super",
                  fontSize: "medium",
                  padding: 0,
                  marginLeft: "-0.5rem",
                  // position: "relative",
                  // top: -10,
                  // right: -10,
                  // fontSize: "medium",
                  // padding: 0,
                  // marginTop: "-0.2rem",
                  // marginRight: "-0.1rem",
                }}
              >
                <span className="badge rounded-pill bg-danger">
									{localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').replace('_', '-') : ''}
                </span>
              </sup>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {languages &&
                languages.map((lang, index) => {
                  return (
                    <DropdownItem
                      onClick={() => {
                        this.changeLanguageAction(lang);
                      }}
                      className="notify-item"
                      key={index}
                    >
                      {lang.name}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>
        ) : (
          <div className="mt-4">
            {languages &&
              languages.map((lang) => {
                return (
                  <div style={{ marginBottom: 4, marginRight: 9 }}>
                    {lang.name}
                  </div>
                );
              })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const organization = state.Organization;
  return { user, token, App, organization };
};

export default connect(mapStatetoProps, {
  authCurrentUser,
})(withNamespaces()(LanguageDropdown));
