import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { successNotification, errorNotification } from '../../../../store/actions';

import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import { FETCH_TASKS_CATEGORIES_FAILED } from '../../../../common/constants';
import DateUtils from '../../../../services/utils/DateUtils';
import { HiChevronDoubleDown, HiChevronDoubleUp, HiMenuAlt4 } from 'react-icons/hi';
import axios from 'axios';
import TaskDetails from '../../../Reporting/Components/TaskComponents/TaskDetails';
import UserUtils from '../../../../services/utils/UserUtils';
import { withRouter } from "react-router-dom";

class EmployeeMyTaskDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: 'My Tasks', link: '/employee/tasks' },
				{ title: 'Details', link: '#' },
			],
			selectedLanguage: localStorage.getItem('i18nextLng'),
			categories: null,
		};
		this.priorities = [
			{
				value: 'low',
				baseLabel: 'Low',
				label: (
					<>
						<HiChevronDoubleDown className='text-info' />
						<span>{this.props.t('Low')}</span>
					</>
				),
			},
			{
				value: 'medium',
				baseLabel: 'Medium',
				label: (
					<>
						<HiMenuAlt4 className='text-success' />
						<span>{this.props.t('Medium')}</span>
					</>
				),
			},
			{
				value: 'high',
				baseLabel: 'High',
				label: (
					<>
						<HiChevronDoubleUp className='text-danger' />
						<span>{this.props.t('High')}</span>
					</>
				),
			},
		];
		this.statuses = [
			{ value: 'NEW', label: this.props.t('New'), taskOwner: false },
			{ value: 'IN_PROGRESS', label: this.props.t('In Progress'), taskOwner: true },
			{ value: 'REVIEW', label: this.props.t('Review'), taskOwner: true },
			{ value: 'CLOSED', label: this.props.t('Closed'), taskOwner: false },
			{ value: 'DELETED', label: this.props.t('Deleted'), taskOwner: false },
		];
		this.dateUtils = new DateUtils();
		this.userUtils = new UserUtils();
		this.handleFetchCategories = this.handleFetchCategories.bind(this);
	}

	/**
	 * this method fetches the list of tasks categories.
	 * @param {String} token authorization token.
	 */
	handleFetchCategories = async (token) => {
		try {
			const result = await axios.get(`${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/task/categories`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (result.status === 200) {
				const categories = result.data.data.map((item) => {
					return {
						value: item.id,
						baseLabel: item.name,
						label: this.props.t(item.name),
					};
				});

				this.setState({
					categories: categories,
				});
			} else {
				this.props.errorNotification({
					message: this.props.t(FETCH_TASKS_CATEGORIES_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while fetching categories:', error);
			}

			this.props.errorNotification({
				message: this.props.t(FETCH_TASKS_CATEGORIES_FAILED),
			});
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<Breadcrumbs 
                        title={`${this.props.t('Task details')}`}
                        breadcrumbItems={this.state.breadcrumbItems} />

						<div style={{ background: '#ffffff' }}>
							{this.state.categories ? (
								<TaskDetails
									priorities={this.priorities}
									categories={this.state.categories}
									t={this.props.t}
									taskId={this.props.match.params.id}
									authToken={this.props.token}
									taskStatuses={this.statuses}
									isTaskOwnerEmployee={this.userUtils.isTaskOwner(this.props.user)}
									successNotification={this.props.successNotification}
									errorNotification={this.props.errorNotification}
								/>
							) : null}
						</div>
					</Container>
				</div>
                <br />
                <br />
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.handleFetchCategories(this.props.token);
	}

	componentDidUpdate(prevProps) {
		if(prevProps.t !== this.props.t) {
			this.statuses = [
					{ value: 'NEW', label: this.props.t('New'), taskOwner: false },
					{ value: 'IN_PROGRESS', label: this.props.t('In Progress'), taskOwner: true },
					{ value: 'REVIEW', label: this.props.t('Review'), taskOwner: true },
					{ value: 'CLOSED', label: this.props.t('Closed'), taskOwner: false },
					{ value: 'DELETED', label: this.props.t('Deleted'), taskOwner: false },
				];
		}
	}
}

const mapStatetoProps = (state) => {
	const { user, token } = state.Login;
	const { App, Organization } = state;
	return { App, user, token, Organization };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, { successNotification, errorNotification })(EmployeeMyTaskDetails)));
