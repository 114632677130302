import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ReactDragListView from "react-drag-listview";
import { AvForm } from "availity-reactstrap-validation";

import { successNotification, errorNotification } from "../../../store/actions";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import QuestionsLoadingPlaceholder from "../Components/QuestionsLoadingPlaceholder";
import QuestionnaireItem from "./Components/QuestionnaireItem";
import { useMutation } from "@tanstack/react-query";
import QuestionarService from "../service";
import { toast } from 'react-toastify';

const QuestionnaireDesigner = (props) => {
	const {
		t,
		isLoading,
		questions : defaultQuestions,
		questionnaire,
		lng,
		onQuestionnaireUpdated
	} = props;

	const [questions, setQuestions] = useState([]);

	const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

	const deleteQuestionMutation = useMutation({
		mutationFn: async ({
			questionId
		}) => {
			const service = QuestionarService.getInstance();
			return await service.deleteQuestion(questionId);
		},
		onSuccess: () => {
			toast(t("Data removed successfully"), {
				type: "success",
			});
		},
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(t("An error occurred while deleting the question."), {
				type: "error",
			});
		}
	});

	const updateQuestionnaireMutation = useMutation({
		mutationFn: async (payload) => {
			const service = QuestionarService.getInstance();

			return await service.updateQuestionnaire(payload);
		},
		onSuccess: () => {
			toast(t("Questions list updated successfully."), {
				type: "success",
			});
		},
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(t("An error occurred while updating the questions list."), {
				type: "error",
			});
		}
	});

	const deleteQuestionOptionMutation = useMutation({
		mutationFn: async ({
			optionId
		}) => {
			const service = QuestionarService.getInstance();

			return await service.deleteQuestionOption(optionId, {
				questionType : 'option'
			});
		},
		onSuccess: () => {
			toast(t("Question option removed successfully."), {
				type: "success",
			});
		},
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);

			toast(t("An error occurred while deleting question option."), {
				type: "error",
			});
		}
	});

	useEffect(() => {
		if(defaultQuestions && defaultQuestions.length > 0){
			setQuestions([
				...defaultQuestions.map((question) => {
					return {
						id : question.id,
						title : question.title,
						isDeletable : question.is_deletable,
						isMandatory : question.is_mandatory,
						type : question.type,
						options : question?.options
					}
				})
			]);
		}
	}, [defaultQuestions]);

	const handleSubmit = () => {
		let newQuestionsList = [...questions].map((question, index) => {
			question.sortIndex = index;
			question.isUpdated = undefined;

			if(question.id === 'new'){
				question.id = undefined;
			}

			if(question.options && Array.isArray(question.options)){
				question.options.map((option) => {
					
					const next =  delete option?.deleted ;
					console.log(option, next)
					option.isUpdated = undefined;
					if(option.id === 'new'){
						option.id = undefined;
					}
				});
			}

			return question;
		});

		updateQuestionnaireMutation.mutate({
			questions		:	newQuestionsList,
			questionnaire	:	questionnaire.id
		}, {
			onSuccess: () => {
				onQuestionnaireUpdated && onQuestionnaireUpdated();
			}
		});
	}

	const handleClickAddOption = (id) => {
		const newQuestions = [...questions].map((question) => {
			if (question.id === id) {
				if(!question.options){
					question.options = [];
				}
				question.options.push({ id: "new", title: "", type: "" });
			}

			return question;
		});

		setQuestions(newQuestions);
	};

	const handleClickAddQuestion = () => {
		setQuestions([
			...questions,
			{ 
				id: "new", 
				type: "text", 
				title: t("Title"), 
				isMandatory: false, 
				isDeletable: true
			}
		]);
	}

	const isQuestionDraggable = (question) => {
		return true;
	}

	const isQuestionDeletable = (question) => {
		return question.isDeletable;
	}

	const isQuestionEditable = (question) => {
		return true;
	}

	const handleChangeQuestionField = (value, questionIndex, keyName) => {
		const nonEn = lng !== "en";

		const newQuestionsList = [...questions].map((question, index) => {
			if (questionIndex === index) {
				return { ...question, [keyName]: value, isUpdated: nonEn };
			} else {
				question.isUpdated = false;

				return question;
			}
		});

		setQuestions(newQuestionsList);
	};

	const handleChangeMultipleChoiceOption = (newValue, optionIndex, parentIndex, key) => {
		// const nonEn = localStorage.getItem("i18nextLng") === "en"; // check this one
		const nonEn = true; // check this one

		const newQuestionsList = [...questions].map((c, i) => {
			if (i === parentIndex) {
				let nc = c;

				nc.options = c.options.map((cc, ii) => {
					if (ii === optionIndex) {
						let value;
						let newcc = cc;

						value = newValue;
						newcc[key] = value;
						newcc.isUpdated = nonEn;
						return newcc;
					} else {
						let cxxc = cc;
						return cxxc;
					}
				});

				return nc;
			} else {
				return c;
			}
		});

		setQuestions(newQuestionsList);
	};

	const deleteQuestionFromList = (index) => {
		const newQuestions = [...questions];
		newQuestions.splice(index, 1);
		setQuestions(newQuestions);
	}

	const handleQuestionDeleteButtonClick = (questionIndex) => {
		const question = questions[questionIndex];

		if(question){

			if(question.id !== 'new'){
				deleteQuestionMutation.mutate({
					questionId : question.id
				}, {
					onSuccess: () => {
						deleteQuestionFromList(questionIndex);
					}
				});
			}
			else{
				deleteQuestionFromList(questionIndex);
			}
		}
	}

	const removeMultipleChoiceOptionFromList = (optionId, parentId, optionIndex, parentIndex) => {
		let newQuestionsList = questions.map((x, i) => {
			if ((x.id && x.id === parentId) || i === parentIndex) {
				let nx = x;

				nx.options = x.options.filter((xx, xxi) => {
					if (xx.id) {
						return xx.id !== optionId;
					} else {
						return xxi !== optionIndex;
					}
				});

				return nx;
			} else {
				return x;
			}
		});

		setQuestions(newQuestionsList);
	}

	const handleClickRemoveMultipleChoiceOption = (optionId, parentId, optionIndex, parentIndex) => {
		if (lng !== "en") {
			// TODO: add message why user cannot remove multiple choice option
			// show message: you can't do hat
			return;
		}

		if(optionId !== 'new'){
			// run mutation
			deleteQuestionOptionMutation.mutate({
				optionId
			}, {
				onSuccess: () => {
					removeMultipleChoiceOptionFromList(optionId, parentId, optionIndex, parentIndex);
				}
			});
		}
		else{
			removeMultipleChoiceOptionFromList(optionId, parentId, optionIndex, parentIndex);
		}
	};

	const dragProps = {
		nodeSelector: "li",
		handleSelector: "button.btn--drag-and-drop",

		onDragEnd(fromIndex, toIndex) {
			if (lng !== "en") {
				return;
			}

			if(!isQuestionDraggable(questions[fromIndex]) || !isQuestionDraggable(questions[toIndex])){
				return;
			}

			const data = [...questions];

			const item = data.splice(fromIndex, 1)[0];

			data.splice(toIndex, 0, item);

			setQuestions(data);
		},
	};

	return (
		isLoading || !questionnaire ? (
			<QuestionsLoadingPlaceholder className="mt-4"/>
		) : (
			<Row>
				<Col xl="12">
					<Card>
						<CardBody>
							<AvForm onValidSubmit={ handleSubmit }>
								<div className="mb-3">
									{/* Default Questions */}
									<ReactDragListView {...dragProps}>
										<ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
											{
												questions && questions.length > 0 && (
													questions.map((question, index) => {
														return (
															<QuestionnaireItem key={index} 
																question={ question } 
																index={ index }
																isDraggable={ isQuestionDraggable(question) }
																isDeletable={ isQuestionDeletable(question) }
																isEditable={ isQuestionEditable(question) } 
																isActive={ activeQuestionIndex === index }
																selectedLanguage={lng}

																onEditButtonClicked={ () => setActiveQuestionIndex(index) }

																onQuestionTitleChange={ (newValue) => handleChangeQuestionField(newValue, index, 'title') }

																onQuestionMandatoryStatusChange={ (newStatus) => handleChangeQuestionField(newStatus, index, 'isMandatory') }

																onQuestionTypeChange={ (newType) => handleChangeQuestionField(newType, index, 'type') } 

																onMultipleChoiceOptionChange={handleChangeMultipleChoiceOption}

																onMultipleChoiceOptionDeleteButtonClicked={handleClickRemoveMultipleChoiceOption}

																onQuestionDeleteButtonClicked={handleQuestionDeleteButtonClick}

																onAddNewOption={ handleClickAddOption } />
														)
													})
												)
											}
										</ul>
									</ReactDragListView>
								</div>

								{/* Add Questions */}
								{lng === "en" && (
									<div>
										<Button block style={{ width: "100%" }} size="xl" onClick={ handleClickAddQuestion } className="dissco--question-genrator--button dissco--question-genrator--button--lg">
											<span style={{ color: "rgba(26, 29, 48, 0.7" }}>{`+ ${t("Add Question")}`}</span>
										</Button>
									</div>
								)}

								{/* Submit */}
								<div className="pt-3">
									<Button type="submit" color="primary" disabled={ updateQuestionnaireMutation.isPending }>
										{
											updateQuestionnaireMutation.isPending ? (
												<>
													<Spinner size="sm" color="primary" />{' '}
													<span>
														{t("Saving...")}
													</span>
												</>
											) : (
												<>
													<span>
														{t("Save")}
													</span>
												</>
											)
										}
										
									</Button>
								</div>
							</AvForm>
						</CardBody>
					</Card>
				</Col>
			</Row>
		)
	);
}

const mapStatetoProps = (state) => {
	const App = state.App;
	const { token, user } = state.Login;

	return {
		App,
		token,
		user,
	};
};

const mapDispachToProps = (dispach) => {
	return {
		errorNotification: (message) => dispach(errorNotification(message)),
		successNotification: (message) => dispach(successNotification(message)),
	};
};

export default withNamespaces()(connect(mapStatetoProps, mapDispachToProps)(QuestionnaireDesigner));