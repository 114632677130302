import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Progress,
  Container,
  Alert,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
} from "reactstrap";
import classnames from "classnames";
import { changeWorkspace } from "../../store/workspace/actions";
import Dropzone from "react-dropzone";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import axios from "axios";

import {
  API_URL_QUESTION_FORM_ORGANIZATION,
  API_URL_CREATE_REPORT,
  API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION,
  API_URL_REPORT_UPLOAD_FILE,
  API_URL_CREATE_THIRD_PARTY_ACCOUNT,
  API_URL_SIGN_IN,
  INTERNATIONAL_DATE_FORMAT,
  AUTH_API_DOMAIN_URL,
  API_URL_GENERATE_RANDOM_USERNAME,
} from "../../common/constants";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserUtils from "../../services/utils/UserUtils";
import UrlUtils from "../../services/utils/UrlUtils";
import "./Reports.css";

import {
  errorNotification,
  successNotification,
  checkLogin,
  setUserAccessToken,
  appError,
  appLoadStatusChanged,
} from "../../store/actions";
import Password from "../../components/Fields/Password";
import DateInput from "../../components/Fields/DateInput";
import DateUtils from "../../services/utils/DateUtils";
import InputPhoneNumber from "../../components/Fields/InputPhoneNumber";
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../services/constants/LocalStorage";
import {
  authCurrentUser,
  logoutUser,
  loginUserSuccessful,
} from "../../store/auth/login/actions";
import { withRouter } from "react-router-dom";

class ArrangeMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Arrange a meeting"), link: "#" },
      ],
      activeTab: 1,
      statusLogin: false,
      isThirdPartyUser: true,
      activeTabProgress: 1,
      progressValue: 25,
      questions: [],
      categories: [],
      locations: [],
      uploadedFiles: [],
      submitData: null,
      phoneAvailableTimes: [],
      selectedAuthType: "register",
      authUserInfos: null,
      userCustomAvaTime: "",
      showCustomAvaTime: false,
      avFromDate: new Date(),
      avToDate: new Date(),
      avFromDateStartFrom: new Date(),
      avToDateStartFrom: new Date(),
      isThirdPartyUserLogin: false,
      checkFirstName: false,
      inputPhoneNumber: null,
      showProg: false,
      popover: {
        id: null,
        active: false,
      },
      displayButton: true,
      reload: false,
      usernameTooltipOpen: false,
      passwordTooltipOpen: false,
      showSuccessAlert: false,
      randomGeneratedUsername: "",
      username: null,
      password: "",
      report_passwor: "",
      consentChecked: true,
    };
    this.toggleTabProgress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finalizeReport = this.finalizeReport.bind(this);
    this.handleFollowUpForm = this.handleFollowUpForm.bind(this);
    this.handleAvaTimeChange = this.handleAvaTimeChange.bind(this);
    this.uploadAttachmentFile = this.uploadAttachmentFile.bind(this);

    this.userUtils = new UserUtils();
    this.dateUtils = new DateUtils();
    this.urlUtils = new UrlUtils();

    this.dropzoneAcceptedFiles = {
      accepted:
        "text/plain, , application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv",
      description: [
        `${this.props.t(
          "You are allowed to upload limited file types"
        )}. ${this.props.t("For more information")}, ${this.props.t("visit")}`,
        <a
          href="https://diss-co.tech/faq-diss-co/"
          target="_blank" rel="noreferrer"
        >{` ${this.props.t("FAQ")} `}</a>,
        this.props.t("page"),
      ],
    };
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        });

        if (tab === 1) {
          this.setState({ progressValue: 25 });
        }
        if (tab === 2) {
          this.setState({ progressValue: 50 });
        }
        if (tab === 3) {
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          this.setState({ progressValue: 100 });
        }
      }
    }
  }

  statusLogin() {
    if (this.userUtils.isThirdParty(this.props.user) === true) {
      this.setState({
        isThirdPartyUserLogin: true,
      });
    } else {
      this.setState({
        isThirdPartyUserLogin: false,
      });
    }

    if (this.userUtils.isEmployee(this.props.user) === true) {
      this.setState({
        isEmployeeUserLogin: true,
      });
    } else {
      this.setState({
        isEmployeeUserLogin: false,
      });
    }

    if (this.urlUtils.getUrlParamThirdParty() === "third-party") {
      this.setState({
        isThirdPartyUser: true,
      });
    } else {
      this.setState({
        isThirdPartyUser: false,
      });
    }

    if (
      this.userUtils.isAnalystOrAnalystAdmin(this.props.user) === true ||
      this.userUtils.isThirdParty(this.props.user) === true ||
      this.userUtils.isEmployee(this.props.user) === true
    ) {
      this.setState({
        statusLogin: true,
      });
    } else {
      this.setState({
        statusLogin: false,
      });
    }
  }

  fetchReportCaseAfterLoginTherdParty() {
    if (this.userUtils.isThirdParty(this.props.user) === true) {
      console.log(this.state.checkFirstName);
      if (this.props.user.first_name) {
        this.setState({
          checkFirstName: true,
        });
      } else {
        this.setState({
          checkFirstName: false,
        });
      }

      if (this.props.user.last_name) {
        this.setState({
          checkLastName: true,
        });
      } else {
        this.setState({
          checkLastName: false,
        });
      }

      if (this.props.user.email) {
        this.setState({
          checkEmail: true,
        });
      } else {
        this.setState({
          checkEmail: false,
        });
      }

      if (this.props.user.position) {
        this.setState({
          checkPosition: true,
        });
      } else {
        this.setState({
          checkPosition: false,
        });
      }

      if (this.props.user.gender) {
        this.setState({
          checkTitle: true,
        });
      } else {
        this.setState({
          checkTitle: false,
        });
      }
    }
  }

  getQuestionsAnswers(answers) {
    let result = [];
    answers.map((answer, id) => {
      result.push({
        id: id,
        answer: answer,
      });
    });
    return result;
  }

  handleAcceptedFiles = (files, id) => {
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};

    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      });

      const formData = new FormData();

      formData.append("report_type", 1);
      formData.append("file", file);

      axios
        .post(API_URL_REPORT_UPLOAD_FILE, formData, headers)
        .then((response) => {
          const fileData = response.data.data;

          this.setState({
            uploadedFiles: [
              ...this.state.uploadedFiles,
              {
                questionId: id,
                id: fileData.id,
                size: fileData.size,
                file: fileData,
                name: file.name,
                preview: file.preview,
                formattedSize: file.formattedSize,
              },
            ],
          });
        })
        .catch((error) => {
          this.setState({ showProg: false });

          this.props.errorNotification({
            code: error.response.data.error,
            message: this.props.t(error.response.data.message),
          });
        });
    });

    this.setState({ selectedFiles: files });
  };

  handleClickDeleteFiles = (file) => {
    const url = AUTH_API_DOMAIN_URL + `attachment/${file.id}/delete`;

    let newSelectedFiles = this.state.selectedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });
    let newUploadedFiles = this.state.uploadedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });

    const deletedFile = {
      file: file.name,
      report_case_id: file.id,
    };

    axios
      .post(url, deletedFile, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((data) => {
        this.setState({
          selectedFiles: newSelectedFiles,
          uploadedFiles: newUploadedFiles,
        });

        this.props.successNotification({
          code: this.props.t("Data was deleted successfully"),
          message: this.props.t("Data was deleted successfully"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  findUserAnswer(question) {
    if (question.type === "file") {
      const answer = this.state.uploadedFiles.find((file) => {
        return file.questionId === question.id;
      });
      if (answer) {
        return answer.file.name;
      }
    } else {
      const answers = this.state.submitData.question_answer;
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].id === question.id) {
          return answers[i].answer;
        }
      }
    }
    return null;
  }

  handleSubmit(e, values) {
    const files = this.state.uploadedFiles.map((file) => {
      return file.id;
    });

    this.setState({
      submitData: {
        files: files,
        category: values.Question_Category,
        description: values.description,
        email: values.email || null,
        first_name: values.first_name || this.props?.user?.first_name || null,
        last_name: values.last_name || this.props?.user?.last_name || null,
        organization: values.organization || this.urlUtils.getOrganizationId(),
        phone: this.state.inputPhoneNumber || null,
        phone_available_time: null,
        title: values.title,
        position: values.position,
        relation_company: values.relation_company,
        salutation: values.salutation,
        type: 3,
        locations: [values.locations],
        question_answer: this.getQuestionsAnswers(values.Questien),
      },
    });
    this.toggleTabProgress(this.state.activeTabProgress + 1);
  }

  handleFollowUpForm(e, values) {
    if (!this.state.avToDate || !this.state.avFromDate) {
      return;
    }
    if (!this.props.user) {
      if (
        values.auth_type === "register" &&
        values.password !== values.repeat_pasword
      ) {
        this.setState({ displayButton: true, showProg: false });
        this.props.errorNotification({
          code: "password_not_equal",
          message: this.props.t(
            "Password and repeat password are not the same"
          ),
        });
        return;
      }
      if (values.password.length < 10) {
        this.setState({ displayButton: true, showProg: false });
        this.props.errorNotification({
          code: "invalid_password",
          message: this.props.t(
            "Password should be longer than 10 characters and contain lowercase, uppercase, number and special character"
          ),
        });
        return;
      }
      this.setState({
        authUserInfos: values,
      });
    }

    let userSelectedAvaTimes =
      values.phone_available_time && values.phone_available_time.length > 0
        ? values.phone_available_time
        : [];

    let userAvaTimesResult = userSelectedAvaTimes.map((selectedTime) => {
      if (selectedTime === "custom") {
        return {
          optionId: "custom",
          value: this.state.userCustomAvaTime,
        };
      } else {
        return {
          optionId: selectedTime,
          value: "",
        };
      }
    });

    this.setState(
      {
        submitData: {
          ...this.state.submitData,
          phone: this.state.inputPhoneNumber,
          phone_available_time: userAvaTimesResult,
          date_available_time_from: this.state.avFromDate,
          date_available_time_to: this.state.avToDate,
          email: values.email,
          user_policy_accepted: this.state.consentChecked,
          privacy_policy_accepted: this.state.consentChecked,
        },
      },
      () => this.finalizeReport()
    );
  }

  getToken = async () => {
    let userToken = null;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;
    if (!this.props.user && !this.state.registerStatus) {
      try {
        userToken = await new Promise(async (nonAuthRes, nonAuthRej) => {
          try {
            if (
              this.state.authUserInfos.auth_type === "register" ||
              !this.state.authUserInfos.auth_type
            ) {
              var currentEmail;
              currentEmail = this.state.authUserInfos.email;
              if (this.state.authUserInfos.email === "") {
                currentEmail = null;
              }
              if (!passwordRegex.test(this.state.authUserInfos.password)) {
                this.setState({
                  displayButton: true,
                  showProg: false,
                  consentChecked: false,
                });
                return this.props.errorNotification({
                  code: "passwords-not-valid",
                  message: this.props.t(
                    "Password should be at least 10 characters and should contain at least one number and one special character"
                  ),
                });
              } else if (
                this.state.authUserInfos.password !==
                this.state.authUserInfos.repeat_pasword
              ) {
                this.setState({
                  displayButton: true,
                  showProg: false,
                  displayFollowUp: true,
                  consentChecked: false,
                });
                return this.props.errorNotification({
                  code: "password_not_equal",
                  message: this.props.t(
                    "Password and repeat password are not the same"
                  ),
                });
              } else {
                await axios
                  .post(API_URL_CREATE_THIRD_PARTY_ACCOUNT, {
                    organization: this.props.Organization.id,
                    username: this.state.authUserInfos.username,
                    password: this.state.authUserInfos.password,
                    email: currentEmail,
                    isThirdPartyUser: this.state.isThirdPartyUser,
                  })
                  .then(async (data) => {
                    this.setState({ registerStatus: true });

                    try {
                      const response = await axios.post(API_URL_SIGN_IN, {
                        organization: this.props.Organization.id,
                        username: this.state.authUserInfos.username,
                        password: this.state.authUserInfos.password,
                        isThirdPartyUser: this.state.isThirdPartyUser,
                        "2fa": false,
                      });
                      nonAuthRes(response.data.data.token);
                    } catch (error) {
                      console.log("login faild. try catch error");
                      this.setState({
                        displayButton: true,
                        showProg: false,
                        consentChecked: false,
                      });
                      console.log(error);
                      nonAuthRej(
                        this.props.t(
                          "Your email and password combination is not correct"
                        )
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("error ->>>>>>>>>>>>>>>.");
                    console.log(error.response.data.message);
                    this.setState({
                      displayButton: true,
                      showProg: false,
                      consentChecked: false,
                    });

                    for (var key in error.response.data.message) {
                      if (error.response.data.message.hasOwnProperty(key)) {
                        this.props.errorNotification({
                          code: "validation_error",
                          message: this.props.t(
                            error.response.data.message[key]
                          ),
                        });
                      }
                    }
                  });
              }
            } else {
              try {
                const response = await axios.post(API_URL_SIGN_IN, {
                  organization: this.props.Organization.id,
                  username: this.state.authUserInfos.username,
                  password: this.state.authUserInfos.password,
                  isThirdPartyUser: this.state.isThirdPartyUser,
                  "2fa": false,
                });
                nonAuthRes(response.data.data.token);
              } catch (error) {
                this.setState({
                  displayButton: true,
                  showProg: false,
                  consentChecked: false,
                });
                // console.log(error);
                nonAuthRej(
                  this.props.t(
                    "Your email and password combination is not correct"
                  )
                );
              }
            }
          } catch (error) {
            console.log("Err", error);
            this.setState({
              displayButton: true,
              showProg: false,
              consentChecked: false,
            });
            if (!this.state.tabs[3].active) {
              nonAuthRej(this.props.t("Username already used"));
            }
          }
        });
      } catch (error) {
        console.log("CATCH ERROR", error);
        this.setState({ displayButton: true, showProg: false });
        this.props.errorNotification({
          code: "fatal_error",
          message: error,
        });
        return;
      }
    } else {
      userToken = this.props.token;
    }
    this.props.setUserAccessToken(userToken);
    return localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, userToken);
  };

  makeAuth = async () => {
    await this.getToken();

    if (this.props.token) {
      await this.props.authCurrentUser(this.state.authUserInfos);

      await this.props.loginUserSuccessful(this.state.authUserInfos);
      await this.statusLogin();
    }
  };

  async finalizeReport() {
    this.setState({ displayButton: false, showProg: true });
    const nex = !this.props.user ? await this.makeAuth() : null;

    localStorage.setItem("org", this.urlUtils.getOrganizationName());
    new Promise(async (res, reject) => {
      let userToken = this.props.token;
      axios
        .post(API_URL_CREATE_REPORT, this.state.submitData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          //   console.log(res);
          this.setState({ showSuccessAlert: true });
          this.toggleTabProgress(this.state.activeTabProgress + 1);
        })
        .catch((reject) => {
          for (var key in reject.response.data.message) {
						switch (key) {
							case 'email': {
								if (reject.response.data.message.email === 'validation.email.already_used')
									this.props.errorNotification({
										code: 'validation_error',
										message: this.props.t('Email already used.'),
									});
								break;
							}
							case 'description': {
								if (reject.response.data.message.description === 'This value is too long. It should have 1000 characters or less.')
									this.props.errorNotification({
										code: 'validation_error',
										message: this.props.t('Description must have 1000 characters or less.'),
									});
								break;
							}
							default: {
								this.props.errorNotification({
									code: 'validation_error',
									message: this.props.t(reject.response.data.message[key]),
								});
								break;
							}
						}
					}
        });
    })
      .then(() => {
        this.toggleTabProgress(this.state.activeTabProgress + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  uploadAttachmentFile(e, { questionId }) {
    const formData = new FormData();
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};
    formData.append("report_type", 2);
    formData.append("file", e.target.files[0]);

    axios
      .post(API_URL_REPORT_UPLOAD_FILE, formData, headers)
      .then((response) => {
        const file = response.data.data;
        this.setState({
          uploadedFiles: [
            ...this.state.uploadedFiles,
            {
              questionId: questionId,
              id: file.id,
              size: file.size,
              file: e.target.files[0],
            },
          ],
        });
      });
  }

  findSubQuestion(questionId, subId) {
    const questions = this.state.questions;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].id === questionId) {
        for (let j = 0; j < questions[i].sub_question.length; j++) {
          if (questions[i].sub_question[j].id === subId) {
            return questions[i].sub_question[j];
          }
        }
      }
    }
    return null;
  }

  handleAvaTimeChange(e, avaTime) {
    if (avaTime.optionId === "custom") {
      this.setState({
        showCustomAvaTime: e.target.checked,
      });
    }
  }

  renderUserInfosFields() {
    if (!this.props.user || this.userUtils.isThirdParty(this.props.user)) {
      return (
        <Row>
          <Col sm="12" lg="9">
            {!this.props.token && (
              <>
                <Row className="mb-3">
                  <Col sm="12" lg="12">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {this.props.t("Title")} {this.props.t("(Optional)")}
                      </Label>
                      <AvRadioGroup inline name="title">
                        <Row>
                          <Col sm="12" lg="6">
                            <AvRadio label={this.props.t("Mr.")} value="Mr." />
                          </Col>
                          <Col sm="12" lg="6">
                            <AvRadio
                              label={this.props.t("Mrs.")}
                              value="Mrs."
                            />
                          </Col>
                        </Row>
                      </AvRadioGroup>
                    </div>
                  </Col>
                  <Col sm="12" lg="12">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="relation_company">
                        {this.props.t("What is your relation to the company?")}
                      </Label>
                      <AvRadioGroup
                        inline
                        name="relation_company"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        validate={{ required: { value: true } }}
                      >
                        <Row>
                          <Col sm="12" lg="6">
                            <AvRadio
                              label={this.props.t("Employee")}
                              value="Employee"
                            />
                          </Col>
                          <Col sm="12" lg="6">
                            <AvRadio
                              label={this.props.t("External")}
                              value="External"
                            />
                          </Col>
                        </Row>
                      </AvRadioGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12" lg="4">
                    <Label className="form-label" htmlFor="first_name">
                      {this.props.t("First name")}
                    </Label>
                    <AvField
                      name="first_name"
                      placeholder=""
                      type="text"
                      className="form-control"
                      errorMessage={this.props.t("This field cannot be blank")}
                      validate={{ required: { value: true } }}
                      id="first_name"
                      value={this.props.user && this.props.user.first_name}
                    />
                  </Col>
                  <Col sm="12" lg="4">
                    <Label className="form-label" htmlFor="last_name">
                      {this.props.t("Last name")}
                    </Label>
                    <AvField
                      name="last_name"
                      placeholder=""
                      type="text"
                      errorMessage={this.props.t("This field cannot be blank")}
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="last_name"
                      value={this.props.user && this.props.user.last_name}
                    />
                  </Col>
                  <Col sm="12" lg="4">
                    <Label className="form-label" htmlFor="organization">
                      {this.props.t("Organization")}
                    </Label>
                    <AvField
                      name="organization"
                      placeholder=""
                      type="text"
                      errorMessage={this.props.t("This field cannot be blank")}
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="organization"
                      value={this.props.user && this.props.user.position}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="12" lg="12">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="Position">
                        {this.props.t("Position")}
                      </Label>
                      <AvField
                        name="position"
                        placeholder=""
                        type="text"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="Position"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              {!this.state.checkTitle && this.state.isThirdPartyUserLogin && (
                <>
                  <Col sm="12" md="4">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="title">
                        {this.props.t("Title")} {this.props.t("(Optional)")}
                      </Label>
                      <AvField
                        id="title"
                        type="select"
                        name="title"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        value={!this.props.user ? "" : this.props.user.gender}
                        validate={{ required: { value: false } }}
                      >
                        <option></option>
                        <option value={"Mr."}>{this.props.t("Mr.")}</option>
                        <option value={"Mrs."}>{this.props.t("Mrs.")}</option>
                      </AvField>
                    </div>
                  </Col>
                </>
              )}

              {!this.state.checkFirstName &&
                this.state.isThirdPartyUserLogin && (
                  <>
                    <Col sm="12" lg="4">
                      <Label className="form-label" htmlFor="first_name">
                        {this.props.t("First name")}
                      </Label>
                      <AvField
                        name="first_name"
                        placeholder=""
                        type="text"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="first_name"
                        value={this.props.user && this.props.user.first_name}
                      />
                    </Col>
                  </>
                )}
              {!this.state.checkLastName &&
                this.state.isThirdPartyUserLogin && (
                  <>
                    <Col sm="12" lg="4">
                      <Label className="form-label" htmlFor="last_name">
                        {this.props.t("Last name")}
                      </Label>
                      <AvField
                        name="last_name"
                        placeholder=""
                        type="text"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="last_name"
                        value={this.props.user && this.props.user.last_name}
                      />
                    </Col>
                  </>
                )}

              {!this.state.checkPosition &&
                this.state.isThirdPartyUserLogin && (
                  <>
                    <Col sm="12" lg="4">
                      <Label className="form-label" htmlFor="position">
                        {this.props.t("Position")}
                      </Label>
                      <AvField
                        name="position"
                        placeholder=""
                        type="text"
                        errorMessage={this.props.t(
                          "This field cannot be blank"
                        )}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="position"
                        value={this.props.user && this.props.user.position}
                      />
                    </Col>
                  </>
                )}
            </Row>
          </Col>
        </Row>
      );
    }
    return null;
  }

  displayUserInfosFieldsValues() {
    if (!this.props.user || this.userUtils.isThirdParty(this.props.user)) {
      return (
        <Row>
          <Col sm="12">
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("What is your relation to the company?")}
                </Label>
                <p className="text-dark">
                  <i className="fa fa-check"></i>
                  {this.state.submitData.relation_company === "Employee"
                    ? " Employee"
                    : " External"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("Title")} {this.props.t("(Optional)")}
                </Label>
                <p className="text-dark">
                  <i className="fa fa-check"></i>
                  {this.props.user && this.props.user.gender
                    ? this.props.t(this.props.user.gender)
                    : this.state.submitData &&
                      this.state.submitData.title &&
                      this.props.t(this.state.submitData.title)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("First name")}
                </Label>
                <p className="text-answers">
                  {this.state.submitData.first_name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("Last name")}
                </Label>
                <p className="text-answers">
                  {this.state.submitData.last_name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">
                  {this.props.t("Organization")}
                </Label>
                <p className="text-answers">
                  {this.state.submitData.organization}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Label className="form-label">{this.props.t("Position")}</Label>
                <p className="text-answers">{this.state.submitData.position}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return null;
  }

  getUserHome() {
    return this.userUtils.getUserHomeRoute(this.props.user);
  }

  generateRandomUsername = async () => {
    this.setState({ username: null });
    const api =
      API_URL_GENERATE_RANDOM_USERNAME + localStorage.getItem("i18nextLng");
    axios.get(api).then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        this.setState({
          randomGeneratedUsername: data,
          randomGenerated: true,
          authUserInfos: {
            ...this.state.authUserInfos,
            username: data,
          },
        });
      }
    });
  };

  renderRegisterForm() {
    if (!this.props.user) {
      return (
        <Row>
          <Col sm="12" className="mb-3">
            <AvRadioGroup
              defaultValue={this.state.selectedAuthType}
              onChange={(e) =>
                this.setState({
                  selectedAuthType: e.target.value,
                  randomGeneratedUsername: "",
                })
              }
              name="auth_type"
              validate={{ required: { value: true } }}
              errorMessage={this.props.t("This field cannot be blank")}
            >
              <Row>
                <Col sm="12" md="6">
                  <AvRadio label={this.props.t("Log In")} value="login" />
                </Col>
                <Col sm="12" md="6">
                  <AvRadio
                    label={this.props.t("Create account")}
                    value="register"
                  />
                </Col>
              </Row>
            </AvRadioGroup>
          </Col>
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="email">
              {`${this.props.t("Username")}`}
              <span className="ms-2">
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  id="infoIconU"
                />
                <Tooltip
                  placement="top"
                  isOpen={this.state.usernameTooltipOpen}
                  target="infoIconU"
                  toggle={() =>
                    this.setState({
                      usernameTooltipOpen: !this.state.usernameTooltipOpen,
                    })
                  }
                >
                  {this.props.t(
                    "Please choose a username that doesnt contain your name or any kind of email address"
                  )}
                </Tooltip>
              </span>
              <Button
                outline
                hidden={this.state.selectedAuthType === "login"}
                className="btn-sm color-primary ms-2"
                onClick={this.generateRandomUsername}
              >
                {this.props.t("Random")}
              </Button>
            </Label>
            <AvField
              name="username"
              placeholder=""
              type="text"
              className="form-control"
              errorMessage={this.props.t("This field cannot be blank")}
              value={this.state.username || this.state.randomGeneratedUsername}
              onChange={(e) =>
                this.setState({
                  username: e.target.value,
                  authUserInfos: {
                    ...this.state.authUserInfos,
                    username: e.target.value,
                  },
                })
              }
              validate={{
                required: { value: true },
                pattern: {
                  value: /^[^@\s]+$/, // regular expression to disallow emails
                  errorMessage: this.props.t("Please enter a valid username"),
                },
              }}
              id="username"
            />
          </Col>
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="email">
              {`${this.props.t("Password")}`}
              <span className="ms-2">
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  id="infoIcon"
                />
                <Tooltip
                  placement="top"
                  isOpen={this.state.passwordTooltipOpen}
                  target="infoIcon"
                  toggle={() =>
                    this.setState({
                      passwordTooltipOpen: !this.state.passwordTooltipOpen,
                    })
                  }
                >
                  {this.props.t(
                    "Please choose a password at least 10 characters contains 1 capital letter,1 digit, and 1 special character contains"
                  ) +
                    ": ! @ # $ % ^ & * ( ) _ [ ]+={ } ' \" ; : / ? . , < > |  -"}
                </Tooltip>
              </span>
            </Label>
            <Password
              name="password"
              placeholder=""
              onChange={(e) =>
                this.setState({
                  password: e.target.value,
                  authUserInfos: {
                    ...this.state.authUserInfos,
                    password: e.target.value,
                  },
                })
              }
              errorMessage={this.props.t("This field cannot be blank")}
              validate={{ required: { value: true } }}
              id="password"
              value={this.state.password}
            />
          </Col>
          {this.state.selectedAuthType === "register" ? (
            <Col sm="12" className="mb-3">
              <Label className="form-label" htmlFor="repeatpasword">
                {`${this.props.t("Repeat password")}`}
              </Label>
              <Password
                name="repeat_pasword"
                placeholder=""
                className="form-control"
                errorMessage={this.props.t("This field cannot be blank")}
                validate={{ required: { value: true } }}
                id="repeatpasword"
                value={this.state.repeat_pasword}
                onChange={(e) =>
                  this.setState({
                    repeat_pasword: e.target.value,
                    authUserInfos: {
                      ...this.state.authUserInfos,
                      repeat_pasword: e.target.value,
                    },
                  })
                }
              />
            </Col>
          ) : null}
        </Row>
      );
    }
    return null;
  }

  scrollToInvalidField(fieldRef) {
    const fieldRect = fieldRef.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const targetTop = fieldRect.top + scrollTop - 100; // Adjust the scroll offset as needed

    window.scrollTo({
      top: targetTop,
      behavior: "smooth",
    });
  }

  handleClickViewFiles = (type) => {
    var xhr = new XMLHttpRequest();
    const selectedLang = localStorage.getItem("i18nextLng");
    const orgId = Number(localStorage.getItem("orgId"));
    console.log(this.props.Organization);
    const languageId = Number(
      this.props?.Organization?.languages.filter(
        (lang) => lang.language_code === selectedLang
      )[0]?.language?.id
    );

    const queryParams = {
      language: languageId,
      organization: orgId,
      type: type,
    };

    // Serialize the query parameters into a string
    const queryString = Object.keys(queryParams)
      .map((key) => key + "=" + queryParams[key])
      .join("&");

    const token = `Bearer ${this.props.token}`;
    const url = `${AUTH_API_DOMAIN_URL}user_manual/download?${queryString}`;
    xhr.open("GET", url, true);
    // xhr.setRequestHeader("Authorization", token);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        // link.download = name;
        // link.click();
        window.open(link);
      }
    };
    xhr.send();
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Arrange a meeting")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 1,
                            })}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">
                              {this.props.t("Report")}
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 2,
                            })}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">
                              {this.props.t("Review your report")}
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 3,
                            })}
                          >
                            <span className="step-number">03</span>
                            <span className="step-title">
                              {this.props.t("Follow-Up")}
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 4,
                            })}
                          >
                            <span className="step-number">04</span>
                            <span className="step-title">
                              {this.props.t("Finalize")}
                            </span>
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                      </div>
                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.handleSubmit}
                            onInvalidSubmit={(e) => {
                              const result = [];
                              const invalidFields = Object.keys(
                                e.target.elements
                              ).map((fieldName) => {
                                if (
                                  e.target.elements[fieldName].validity &&
                                  !e.target.elements[fieldName].validity.valid
                                ) {
                                  result.push(fieldName);
                                  return (
                                    e.target.elements[fieldName].validity
                                      .valid === false
                                  );
                                }
                              });
                              if (result.length > 0) {
                                const firstInvalidField =
                                  e.target.elements[result[0]];
                                console.log(firstInvalidField);
                                this.scrollToInvalidField(firstInvalidField);
                              }
                            }}
                            ref={(formRef) => {
                              this.formRef = formRef;
                            }}
                          >
                            {this.renderUserInfosFields()}

                            {
                              <Row>
                                <Col sm="12" lg="9">
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="description"
                                    >
                                      {this.props.t(
                                        "Brief Description of Incident"
                                      )}
                                    </Label>
                                    <AvField
                                      name="description"
                                      placeholder=""
                                      type="text"
                                      errorMessage={this.props.t(
                                        "This field cannot be blank"
                                      )}
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="description"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            }
                            {
                              // this.urlUtils.getUrlParamThirdParty() !==
                              //       "third-party" &&
                              //       this.userUtils.isEmployee(this.props.user) ===
                              //         false && (
                              <Row>
                                <Col sm="12" lg="12">
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="source"
                                    >
                                      {this.props.t(
                                        "Choose the channel of information"
                                      )}
                                    </Label>
                                    <AvField
                                      id="source"
                                      type="select"
                                      placeholder={this.props.t(
                                        "Choose the channel of information"
                                      )}
                                      name="source"
                                      errorMessage={this.props.t(
                                        "This field cannot be blank"
                                      )}
                                      className="form-control"
                                      validate={{
                                        required: { value: true },
                                      }}
                                      value={"email"}
                                    >
                                      <option value="web">
                                        {this.props.t("Web")}
                                      </option>
                                      <option value="email">
                                        {this.props.t("Email")}
                                      </option>
                                      <option value="letter">
                                        {this.props.t("Letter")}
                                      </option>
                                      <option value="phone">
                                        {this.props.t("Phone")}
                                      </option>
                                      <option value="meeting">
                                        {this.props.t("Meeting")}
                                      </option>
                                      <option value="live_chat">
                                        {this.props.t("Live Chat")}
                                      </option>
                                      <option value="other">
                                        {this.props.t(
                                          `${this.props.t("Other")}`
                                        )}
                                      </option>
                                    </AvField>
                                  </div>
                                </Col>
                              </Row>
                              // )
                            }
                            {
                              <Row>
                                <Col sm="12" lg="9">
                                  <div className="mb-3">
                                    <Label
                                      className="form-check-label"
                                      htmlFor={`question-category`}
                                    >
                                      {this.props.t(
                                        "In your opinion, the facts of the case fall into the following category:"
                                      )}
                                    </Label>
                                    <AvCheckboxGroup
                                      name="Question_Category"
                                      errorMessage={this.props.t(
                                        "This field cannot be blank"
                                      )}
                                      validate={{ required: true }}
                                    >
                                      <Row>
                                        {this.state.categories.map(
                                          (category, catIndex) => {
                                            return (
                                              <Col
                                                sm="12"
                                                md="6"
                                                key={category.id + catIndex}
                                              >
                                                <AvCheckbox
                                                  label={this.props.t(
                                                    category.title
                                                  )}
                                                  value={category.id}
                                                />
                                              </Col>
                                            );
                                          }
                                        )}
                                      </Row>
                                    </AvCheckboxGroup>
                                  </div>
                                </Col>
                              </Row>
                            }

                            {
                              <Row>
                                <Col sm="12">
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="locations"
                                    >
                                      {this.props.t(
                                        "Which location(s) is / are affected?"
                                      )}
                                    </Label>
                                    <AvField
                                      id="locations"
                                      type="select"
                                      name="locations"
                                      errorMessage={this.props.t(
                                        "This field cannot be blank"
                                      )}
                                      className="form-control"
                                      style={{ width: "74.5%" }}
                                      validate={{ required: { value: true } }}
                                      defaultValue={
                                        this.state.organizationCountry
                                      }
                                      value={this.state.organizationCountry}
                                    >
                                      {this.sortLocationsByLanguage(
                                        localStorage.getItem("i18nextLng"),
                                        this.state.locations
                                      ).map((location) => {
                                        return (
                                          <option
                                            key={location.id}
                                            value={location.id}
                                          >
                                            {this.props.t(location.name)}
                                          </option>
                                        );
                                      })}
                                    </AvField>
                                  </div>
                                </Col>
                              </Row>
                            }

                            {this.state.questions.map((question, index) => {
                              const id = question.id;
                              const title = question.title || "";
                              const type = question.type;
                              const key = id + index;
                              const afterLogin = question.after_login;

                              if (
                                type === "textarea" ||
                                type === "text" ||
                                type === "date"
                              ) {
                                if (afterLogin === "none") {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvField
                                            name={"Questien[" + id + "]"}
                                            placeholder=""
                                            type={type}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            id={`question-${id}`}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === true &&
                                  afterLogin === "true"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvField
                                            name={"Questien[" + id + "]"}
                                            placeholder=""
                                            type={type}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            id={`question-${id}`}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === false &&
                                  afterLogin === "false"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvField
                                            name={"Questien[" + id + "]"}
                                            placeholder=""
                                            type={type}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            id={`question-${id}`}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              } else if (type === "file") {
                                if (afterLogin === "none") {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <div style={{ position: "relative" }}>
                                            <Dropzone
                                              onDrop={(acceptedFiles) =>
                                                this.handleAcceptedFiles(
                                                  acceptedFiles,
                                                  id
                                                )
                                              }
                                              accept={
                                                this.dropzoneAcceptedFiles
                                                  .accepted
                                              }
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div className="dropzone">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input
                                                      {...getInputProps()}
                                                    />
                                                    <div className="mb-3">
                                                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                    </div>
                                                    <h4>
                                                      {this.props.t(
                                                        "Drop files here or click to upload"
                                                      )}
                                                    </h4>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            <Button
                                              id="dropzone-popover"
                                              className="button"
                                              type="button"
                                              color="link"
                                              style={{
                                                position: "absolute",
                                                right: 8,
                                                bottom: 6,
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  popover: {
                                                    id: "dropzone-popover",
                                                    active:
                                                      !this.state.popover
                                                        .active,
                                                  },
                                                })
                                              }
                                            >
                                              <i
                                                className="ri-information-line"
                                                style={{ fontSize: 18 }}
                                              ></i>
                                            </Button>
                                            <Popover
                                              placement="bottom"
                                              target="dropzone-popover"
                                              isOpen={
                                                this.state.popover.id ===
                                                  "dropzone-popover" &&
                                                this.state.popover.active
                                              }
                                              toggle={() =>
                                                this.setState({
                                                  popover: {
                                                    id: "dropzone-popover",
                                                    active:
                                                      !this.state.popover
                                                        .active,
                                                  },
                                                })
                                              }
                                            >
                                              <PopoverHeader>
                                                {this.props.t(
                                                  "Allowed Formats"
                                                )}
                                              </PopoverHeader>
                                              <PopoverBody>
                                                <span>
                                                  {this.props.t(
                                                    "You are allowed to upload limited file types. for more information, visit"
                                                  )}{" "}
                                                </span>
                                                <a
                                                  href="https://diss-co.tech/faq-diss-co/"
                                                  target="_blank"
                                                >
                                                  {this.props.t("FAQ page")}
                                                </a>
                                              </PopoverBody>
                                            </Popover>
                                          </div>
                                          <div
                                            className="dropzone-previews mt-3"
                                            id="file-previews"
                                          >
                                            {this.state.uploadedFiles.map(
                                              (f, i) => {
                                                return (
                                                  <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                  >
                                                    <div className="p-2">
                                                      <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                          <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f.name}
                                                            src={f.preview}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Link
                                                            to="#"
                                                            className="text-muted fw-bold"
                                                          >
                                                            {f.name}
                                                          </Link>
                                                          <p className="mb-0">
                                                            <strong>
                                                              {f.formattedSize}
                                                            </strong>
                                                          </p>
                                                        </Col>
                                                        <Col
                                                          xs={2}
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          <Button
                                                            color="danger"
                                                            size="sm"
                                                            onClick={() =>
                                                              this.handleClickDeleteFiles(
                                                                f
                                                              )
                                                            }
                                                          >
                                                            X
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Card>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === true &&
                                  afterLogin === "true"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <div style={{ position: "relative" }}>
                                            <Dropzone
                                              onDrop={(acceptedFiles) =>
                                                this.handleAcceptedFiles(
                                                  acceptedFiles,
                                                  id
                                                )
                                              }
                                              accept={
                                                this.dropzoneAcceptedFiles
                                                  .accepted
                                              }
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div className="dropzone">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input
                                                      {...getInputProps()}
                                                    />
                                                    <div className="mb-3">
                                                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                    </div>
                                                    <h4>
                                                      {this.props.t(
                                                        "Drop files here or click to upload"
                                                      )}
                                                    </h4>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            <Button
                                              id="dropzone-popover"
                                              className="button"
                                              type="button"
                                              color="link"
                                              style={{
                                                position: "absolute",
                                                right: 8,
                                                bottom: 6,
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  popover: {
                                                    id: "dropzone-popover",
                                                    active:
                                                      !this.state.popover
                                                        .active,
                                                  },
                                                })
                                              }
                                            >
                                              <i
                                                className="ri-information-line"
                                                style={{ fontSize: 18 }}
                                              ></i>
                                            </Button>
                                            <Popover
                                              placement="bottom"
                                              target="dropzone-popover"
                                              isOpen={
                                                this.state.popover.id ===
                                                  "dropzone-popover" &&
                                                this.state.popover.active
                                              }
                                              toggle={() =>
                                                this.setState({
                                                  popover: {
                                                    id: "dropzone-popover",
                                                    active:
                                                      !this.state.popover
                                                        .active,
                                                  },
                                                })
                                              }
                                            >
                                              <PopoverHeader>
                                                {this.props.t(
                                                  "Allowed Formats"
                                                )}
                                              </PopoverHeader>
                                              <PopoverBody>
                                                <span>
                                                  {this.props.t(
                                                    "You are allowed to upload limited file types. for more information, visit"
                                                  )}{" "}
                                                </span>
                                                <a
                                                  href="https://diss-co.tech/faq-diss-co/"
                                                  target="_blank"
                                                >
                                                  {this.props.t("FAQ page")}
                                                </a>
                                              </PopoverBody>
                                            </Popover>
                                          </div>
                                          <div
                                            className="dropzone-previews mt-3"
                                            id="file-previews"
                                          >
                                            {this.state.uploadedFiles.map(
                                              (f, i) => {
                                                return (
                                                  <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                  >
                                                    <div className="p-2">
                                                      <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                          <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f.name}
                                                            src={f.preview}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Link
                                                            to="#"
                                                            className="text-muted fw-bold"
                                                          >
                                                            {f.name}
                                                          </Link>
                                                          <p className="mb-0">
                                                            <strong>
                                                              {f.formattedSize}
                                                            </strong>
                                                          </p>
                                                        </Col>
                                                        <Col
                                                          xs={2}
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          <Button
                                                            color="danger"
                                                            size="sm"
                                                            onClick={() =>
                                                              this.handleClickDeleteFiles(
                                                                f
                                                              )
                                                            }
                                                          >
                                                            X
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Card>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === false &&
                                  afterLogin === "false"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <div style={{ position: "relative" }}>
                                            <Dropzone
                                              onDrop={(acceptedFiles) =>
                                                this.handleAcceptedFiles(
                                                  acceptedFiles,
                                                  id
                                                )
                                              }
                                              accept={
                                                this.dropzoneAcceptedFiles
                                                  .accepted
                                              }
                                            >
                                              {({
                                                getRootProps,
                                                getInputProps,
                                              }) => (
                                                <div className="dropzone">
                                                  <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                  >
                                                    <input
                                                      {...getInputProps()}
                                                    />
                                                    <div className="mb-3">
                                                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                    </div>
                                                    <h4>
                                                      {this.props.t(
                                                        "Drop files here or click to upload"
                                                      )}
                                                    </h4>
                                                  </div>
                                                </div>
                                              )}
                                            </Dropzone>
                                            <Button
                                              id="dropzone-popover"
                                              className="button"
                                              type="button"
                                              color="link"
                                              style={{
                                                position: "absolute",
                                                right: 8,
                                                bottom: 6,
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  popover: {
                                                    id: "dropzone-popover",
                                                    active:
                                                      !this.state.popover
                                                        .active,
                                                  },
                                                })
                                              }
                                            >
                                              <i
                                                className="ri-information-line"
                                                style={{ fontSize: 18 }}
                                              ></i>
                                            </Button>
                                            <Popover
                                              placement="bottom"
                                              target="dropzone-popover"
                                              isOpen={
                                                this.state.popover.id ===
                                                  "dropzone-popover" &&
                                                this.state.popover.active
                                              }
                                              toggle={() =>
                                                this.setState({
                                                  popover: {
                                                    id: "dropzone-popover",
                                                    active:
                                                      !this.state.popover
                                                        .active,
                                                  },
                                                })
                                              }
                                            >
                                              <PopoverHeader>
                                                {this.props.t(
                                                  "Allowed Formats"
                                                )}
                                              </PopoverHeader>
                                              <PopoverBody>
                                                <span>
                                                  {this.props.t(
                                                    "You are allowed to upload limited file types. for more information, visit"
                                                  )}{" "}
                                                </span>
                                                <a
                                                  href="https://diss-co.tech/faq-diss-co/"
                                                  target="_blank"
                                                >
                                                  {this.props.t("FAQ page")}
                                                </a>
                                              </PopoverBody>
                                            </Popover>
                                          </div>
                                          <div
                                            className="dropzone-previews mt-3"
                                            id="file-previews"
                                          >
                                            {this.state.uploadedFiles.map(
                                              (f, i) => {
                                                return (
                                                  <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                  >
                                                    <div className="p-2">
                                                      <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                          <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f.name}
                                                            src={f.preview}
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Link
                                                            to="#"
                                                            className="text-muted fw-bold"
                                                          >
                                                            {f.name}
                                                          </Link>
                                                          <p className="mb-0">
                                                            <strong>
                                                              {f.formattedSize}
                                                            </strong>
                                                          </p>
                                                        </Col>
                                                        <Col
                                                          xs={2}
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          <Button
                                                            color="danger"
                                                            size="sm"
                                                            onClick={() =>
                                                              this.handleClickDeleteFiles(
                                                                f
                                                              )
                                                            }
                                                          >
                                                            X
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Card>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              } else if (type === "checkbox") {
                                if (afterLogin === "none") {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvCheckboxGroup
                                            name={"Questien[" + id + "]"}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                          >
                                            <Row>
                                              {question.sub_question.map(
                                                (subQuestion, subIndex) => {
                                                  return (
                                                    <Col
                                                      sm="12"
                                                      md="6"
                                                      key={
                                                        subQuestion.id +
                                                        subIndex
                                                      }
                                                    >
                                                      <AvCheckbox
                                                        label={this.props.t(
                                                          subQuestion.label
                                                        )}
                                                        value={subQuestion.id}
                                                      />
                                                    </Col>
                                                  );
                                                }
                                              )}
                                            </Row>
                                          </AvCheckboxGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === true &&
                                  afterLogin === "true"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvCheckboxGroup
                                            name={"Questien[" + id + "]"}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                          >
                                            <Row>
                                              {question.sub_question.map(
                                                (subQuestion, subIndex) => {
                                                  return (
                                                    <Col
                                                      sm="12"
                                                      md="6"
                                                      key={
                                                        subQuestion.id +
                                                        subIndex
                                                      }
                                                    >
                                                      <AvCheckbox
                                                        label={this.props.t(
                                                          subQuestion.label
                                                        )}
                                                        value={subQuestion.id}
                                                      />
                                                    </Col>
                                                  );
                                                }
                                              )}
                                            </Row>
                                          </AvCheckboxGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === false &&
                                  afterLogin === "false"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" lg="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvCheckboxGroup
                                            name={"Questien[" + id + "]"}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                          >
                                            <Row>
                                              {question.sub_question.map(
                                                (subQuestion, subIndex) => {
                                                  return (
                                                    <Col
                                                      sm="12"
                                                      md="6"
                                                      key={
                                                        subQuestion.id +
                                                        subIndex
                                                      }
                                                    >
                                                      <AvCheckbox
                                                        label={this.props.t(
                                                          subQuestion.label
                                                        )}
                                                        value={subQuestion.id}
                                                      />
                                                    </Col>
                                                  );
                                                }
                                              )}
                                            </Row>
                                          </AvCheckboxGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              } else if (type === "radiobox") {
                                if (afterLogin === "none") {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" ld="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvRadioGroup
                                            name={"Questien[" + id + "]"}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                          >
                                            <Row>
                                              {question.sub_question.map(
                                                (subQuestion, subIndex) => {
                                                  return (
                                                    <Col
                                                      sm="12"
                                                      md="6"
                                                      key={
                                                        subQuestion.id +
                                                        subIndex
                                                      }
                                                    >
                                                      <AvRadio
                                                        label={this.props.t(
                                                          subQuestion.label
                                                        )}
                                                        value={subQuestion.id}
                                                      />
                                                    </Col>
                                                  );
                                                }
                                              )}
                                            </Row>
                                          </AvRadioGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === true &&
                                  afterLogin === "true"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" ld="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvRadioGroup
                                            name={"Questien[" + id + "]"}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                          >
                                            <Row>
                                              {question.sub_question.map(
                                                (subQuestion, subIndex) => {
                                                  return (
                                                    <Col
                                                      sm="12"
                                                      md="6"
                                                      key={
                                                        subQuestion.id +
                                                        subIndex
                                                      }
                                                    >
                                                      <AvRadio
                                                        label={this.props.t(
                                                          subQuestion.label
                                                        )}
                                                        value={subQuestion.id}
                                                      />
                                                    </Col>
                                                  );
                                                }
                                              )}
                                            </Row>
                                          </AvRadioGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                                if (
                                  this.state.statusLogin === false &&
                                  afterLogin === "false"
                                ) {
                                  return (
                                    <Row key={key}>
                                      <Col sm="12" ld="9">
                                        <div className="mb-3">
                                          <Label
                                            className="form-check-label"
                                            htmlFor={`question-${id}`}
                                          >
                                            {this.props.t(title)}
                                          </Label>
                                          <AvRadioGroup
                                            name={"Questien[" + id + "]"}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                          >
                                            <Row>
                                              {question.sub_question.map(
                                                (subQuestion, subIndex) => {
                                                  return (
                                                    <Col
                                                      sm="12"
                                                      md="6"
                                                      key={
                                                        subQuestion.id +
                                                        subIndex
                                                      }
                                                    >
                                                      <AvRadio
                                                        label={this.props.t(
                                                          subQuestion.label
                                                        )}
                                                        value={subQuestion.id}
                                                      />
                                                    </Col>
                                                  );
                                                }
                                              )}
                                            </Row>
                                          </AvRadioGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              } else {
                                return null;
                              }
                            })}

                                {/* <Row className="mt-3 mb-3" hidden={this.userUtils.isAnalystOrAnalystAdmin(this.props.user)}>
                                  <FormGroup
                                    row
                                    display="flex"
                                    flexDirection="row"
                                  >
                                    <Col
                                      sm="12"
                                      md="6"
                                      lg="1"
                                      className="consCol"
                                    >
                                      <Input
                                        type="checkbox"
                                        name="consent"
                                        id="consent"
                                        onChange={() =>
                                          this.setState({
                                            consentChecked:
                                              !this.state.consentChecked,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col sm="12" md="6" lg="11">
                                      <Label
                                        className="consLabel"
                                        // for="consent"
                                      >
                                        <span>
                                          {this.props.t("I consent")}{" "}
                                        </span>
                                        <span
                                          className="text-primary"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.handleClickViewFiles(
                                              "user_policy"
                                            )
                                          }
                                        >
                                          {this.props.t("User policy")}{" "}
                                        </span>
                                        <span>{this.props.t("and")} </span>
                                        <span
                                          className="text-primary"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.handleClickViewFiles(
                                              "privacy_policy"
                                            )
                                          }
                                        >
                                          {this.props.t("Privacy policy")}{" "}
                                        </span>
                                      </Label>
                                    </Col>
                                  </FormGroup>
                                </Row> */}

                            <Row>
                              <Col sm="12 m-t-15">
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={this.state.showProg || (!this.state.consentChecked && (!this.userUtils.isAnalystOrAnalystAdmin(this.props.user)))}
                                  onClick={() =>
                                    this.setState({
                                      popover: {
                                        id: "dropzone-popover",
                                        active: false,
                                      },
                                    })
                                  }
                                >
                                  {this.props.t("Next")}
                                </Button>
                              </Col>
                            </Row>
                          </AvForm>
                        </TabPane>
                        <TabPane tabId={2}>
                          {this.state.submitData ? (
                            <div>
                              {this.displayUserInfosFieldsValues()}

                              <Row>
                                <Col sm="12">
                                  <div>
                                    <Label className="form-label">
                                      {this.props.t(
                                        "Brief Description of Incident"
                                      )}
                                    </Label>
                                    <p className="text-answers">
                                      {this.state.submitData.description}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              {/* {this.urlUtils.getUrlParamThirdParty() !==
                                  "third-party" &&
                                  this.userUtils.isEmployee(this.props.user) ===
                                    false && (
                                    <Row>
                                      <Col sm="12" lg="12">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="source"
                                          >
                                            {this.props.t(
                                              "Choose the channel of information"
                                            )}
                                          </Label>
                                          <AvField
                                            id="source"
                                            type="select"
                                            placeholder={this.props.t(
                                              "Choose the channel of information"
                                            )}
                                            name="source"
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={"email"}
                                          >
                                            <option value="web">
                                              {this.props.t("Web")}
                                            </option>
                                            <option value="email">
                                              {this.props.t("Email")}
                                            </option>
                                            <option value="letter">
                                              {this.props.t("Letter")}
                                            </option>
                                            <option value="phone">
                                              {this.props.t("Phone")}
                                            </option>
                                            <option value="meeting">
                                              {this.props.t("Meeting")}
                                            </option>
                                            <option value="live_chat">
                                              {this.props.t("Live Chat")}
                                            </option>
                                            <option value="other">
                                              {this.props.t(
                                                `${this.props.t("Other")}`
                                              )}
                                            </option>
                                          </AvField>
                                        </div>
                                      </Col>
                                    </Row>
                                  )
                              } */}

                              <Row>
                                <Col sm="12">
                                  <div>
                                    <Label className="form-label">
                                      {this.props.t(
                                        "In your opinion, the facts of the case fall into the following category:"
                                      )}
                                    </Label>
                                    <Row>
                                      {this.state.submitData.category.map(
                                        (categoryId, catIndex) => {
                                          const category =
                                            this.state.categories[
                                              this.state.categories.findIndex(
                                                (item) => item.id === categoryId
                                              )
                                            ];
                                          return (
                                            <Col
                                              sm="12"
                                              md="6"
                                              key={categoryId + catIndex}
                                            >
                                              <p className="text-dark">
                                                <i className="fa fa-check"></i>
                                                {" " +
                                                  this.props.t(category.title)}
                                              </p>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              {this.displayEnteredWhitchLocations()}

                              <Row>
                                <Col sm="12">
                                  {this.state.questions.map(
                                    (question, index) => {
                                      const id = question.id;
                                      const title = question.title || "";
                                      const type = question.type;
                                      const key = id + index;
                                      const afterLogin = question.after_login;

                                      const userAnswers =
                                        this.findUserAnswer(question);
                                      if (type === "file") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (
                                        type === "textarea" ||
                                        type === "text"
                                      ) {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "date") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "checkbox") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "radiobox") {
                                        const subQuestionInfos =
                                          this.findSubQuestion(id, userAnswers);
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <p className="text-dark">
                                                        <i className="fa fa-check"></i>
                                                        {" " +
                                                          this.props.t(
                                                            subQuestionInfos.label
                                                          )}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <p className="text-dark">
                                                        <i className="fa fa-check"></i>
                                                        {" " +
                                                          this.props.t(
                                                            subQuestionInfos.label
                                                          )}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <p className="text-dark">
                                                        <i className="fa fa-check"></i>
                                                        {" " +
                                                          this.props.t(
                                                            subQuestionInfos.label
                                                          )}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="12 m-t-15">
                                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li className="previous">
                                      <Button
                                        color="light"
                                        outline
                                        onClick={() =>
                                          this.toggleTabProgress(
                                            this.state.activeTabProgress - 1
                                          )
                                        }
                                      >
                                        {this.props.t("Back")}
                                      </Button>
                                    </li>
                                    <li className="next">
                                      <Button
                                        color="primary"
                                        onClick={() => {
                                          this.toggleTabProgress(
                                            this.state.activeTabProgress + 1
                                          );
                                        }}
                                      >
                                        {this.props.t("Next")}
                                      </Button>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                        </TabPane>
                        {!this.state.showProg ? (
                          <TabPane tabId={3}>
                            <AvForm
                              hidden={!this.state.displayButton}
                              className="needs-validation"
                              onValidSubmit={this.handleFollowUpForm}
                            >
                              {
                                <>
                                  <Row>
                                    <Col sm="12" md="6" lg="4">
                                      <h5>{this.props.t("Follow-Up")}</h5>
                                      <p>
                                        {this.props.t(
                                          "If you would like a follow-up about your report in addition to being notified by your email, input your available times for a callback. Please make sure that the correct phone number is saved in your account settings."
                                        )}
                                      </p>
                                    </Col>

                                    <Col sm="12" md="6" lg="8">
                                      {this.renderRegisterForm()}
                                      <Row>
                                        {!this.state.statusLogin && (
                                          <Col sm="12" className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="email"
                                            >
                                              {this.props.t("Email address")}{" "}
                                              {this.props.t("(Optional)")}
                                            </Label>
                                            <AvField
                                              name="email"
                                              placeholder=""
                                              type="email"
                                              className="form-control"
                                              validate={{
                                                required: { value: false },
                                                email: true,
                                              }}
                                              errorMessage={this.props.t(
                                                "This field is invalid"
                                              )}
                                              id="email"
                                            />
                                          </Col>
                                        )}
                                        {this.state.statusLogin &&
                                          !this.props.user.email && (
                                            <Col sm="12" className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor="email"
                                              >
                                                {this.props.t("Email address")}{" "}
                                                {this.props.t("(Optional)")}
                                              </Label>
                                              <AvField
                                                name="email"
                                                placeholder=""
                                                type="email"
                                                className="form-control"
                                                validate={{
                                                  required: { value: false },
                                                  email: true,
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field is invalid"
                                                )}
                                                id="email"
                                              />
                                            </Col>
                                          )}

                                        {!this.props.user && (
                                          <Col sm="12" className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="phone"
                                            >
                                              {this.props.t("Phone number")}{" "}
                                              {this.props.t("(Optional)")}
                                            </Label>
                                            <InputPhoneNumber
                                              id="phone"
                                              name="phone"
                                              required={true}
                                              placeholder=""
                                              onChange={(phone) =>
                                                this.setState({
                                                  inputPhoneNumber: phone,
                                                })
                                              }
                                              value={
                                                this.props.user
                                                  ? this.props.user.phone_number
                                                  : ""
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                              errorMessage={this.props.t(
                                                "This field cannot be blank"
                                              )}
                                            />
                                            {/* <PhoneNumber name="phone" placeholder="" errorMessage={this.props.t("This field cannot be blank")} className="form-control" validate={{ required: { value: true } }} id="phone" /> */}
                                          </Col>
                                        )}
                                        {this.props.user &&
                                          !this.props.user.phone_number && (
                                            <Col sm="12" className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor="phone"
                                              >
                                                {this.props.t("Phone number")}{" "}
                                                {this.props.t("(Optional)")}
                                              </Label>
                                              <InputPhoneNumber
                                                id="phone"
                                                name="phone"
                                                required={true}
                                                placeholder=""
                                                onChange={(phone) =>
                                                  this.setState({
                                                    inputPhoneNumber: phone,
                                                  })
                                                }
                                                value={
                                                  this.props.user
                                                    ? this.props.user
                                                        .phone_number
                                                    : ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              />
                                            </Col>
                                          )}
                                      </Row>
                                      <Row>
                                        <Col sm="12" md="6" className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="ava-from-date"
                                          >
                                            {this.props.t(
                                              "I am available for a callback as of the following date"
                                            )}
                                          </Label>
                                          <DateInput
                                            name="av_from_date"
                                            placeholder=""
                                            id="ava-from-date"
                                            value={this.state.avFromDate}
                                            onValueChanged={(value) => {
                                              this.setState({
                                                avFromDate: value,
                                              });
                                              if (value) {
                                                if (
                                                  this.state.avToDateStartFrom.valueOf() <
                                                  value.valueOf()
                                                ) {
                                                  this.setState({
                                                    avToDateStartFrom: value,
                                                    avToDate: value,
                                                  });
                                                } else {
                                                  if (
                                                    value.valueOf() <
                                                    this.state.avToDateStartFrom.valueOf()
                                                  ) {
                                                    this.setState({
                                                      avToDateStartFrom: value,
                                                    });
                                                  }
                                                }
                                              }
                                            }}
                                            startFrom={
                                              this.state.avFromDateStartFrom
                                            }
                                          />
                                          {!this.state.avFromDate ? (
                                            <div className="invalid-feedback d-block">
                                              {this.props.t(
                                                "This field cannot be blank"
                                              )}
                                            </div>
                                          ) : null}
                                        </Col>
                                        <Col sm="12" md="6" className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="ava-to-date"
                                          >
                                            {this.props.t(
                                              "I am available for a callback until the following date"
                                            )}
                                          </Label>
                                          <DateInput
                                            name="av_to_date"
                                            placeholder=""
                                            id="ava-to-date"
                                            value={this.state.avToDate}
                                            onValueChanged={(value) =>
                                              this.setState({ avToDate: value })
                                            }
                                            startFrom={
                                              this.state.avToDateStartFrom
                                            }
                                          />
                                          {!this.state.avToDate ? (
                                            <div className="invalid-feedback d-block">
                                              {this.props.t(
                                                "This field cannot be blank"
                                              )}
                                            </div>
                                          ) : null}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="12">
                                          <Label className="form-check-label">
                                            {this.props.t(
                                              "I am available during the following times for a callback"
                                            )}{" "}
                                            {this.props.t("(Optional)")}
                                          </Label>
                                          <AvCheckboxGroup name="phone_available_time">
                                            <Row>
                                              {this.state.phoneAvailableTimes.map(
                                                (phoneAvTime) => {
                                                  return (
                                                    <Col
                                                      key={phoneAvTime.optionId}
                                                      sm="12"
                                                      md="6"
                                                    >
                                                      <AvCheckbox
                                                        label={
                                                          phoneAvTime.value
                                                        }
                                                        value={
                                                          phoneAvTime.optionId
                                                        }
                                                        onChange={(e) =>
                                                          this.handleAvaTimeChange(
                                                            e,
                                                            phoneAvTime
                                                          )
                                                        }
                                                      />
                                                    </Col>
                                                  );
                                                }
                                              )}
                                            </Row>
                                          </AvCheckboxGroup>
                                        </Col>
                                        {/* <Row> */}
                                        <Col sm="12">
                                          {this.state.showCustomAvaTime ? (
                                            <AvField
                                              type="text"
                                              name="custom"
                                              required
                                              className="form-control"
                                              validate={{
                                                required: {
                                                  value:
                                                    this.state
                                                      .showCustomAvaTime,
                                                },
                                              }}
                                              onChange={(e) =>
                                                this.setState({
                                                  userCustomAvaTime:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          ) : null}
                                        </Col>
                                        {/* </Row> */}
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              }

                              <Progress
                                bar
                                animated
                                color="info"
                                value="100"
                                hidden={!this.state.showProg}
                              >
                                {this.props.t(
                                  "Please wait. We are processing your request."
                                )}
                              </Progress>

                              <Row>
                                <Col sm="12 m-t-15">
                                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li className="previous">
                                      <Button
                                        color="light"
                                        outline
                                        onClick={() =>
                                          this.toggleTabProgress(
                                            this.state.activeTabProgress - 1
                                          )
                                        }
                                      >
                                        {this.props.t("Back")}
                                      </Button>
                                    </li>
                                    <li className="next">
                                      <Button
                                        disabled={
                                          !this.state.displayButton
                                          // ||
                                          // !this.state.consentChecked
                                        }
                                        type="submit"
                                        color="primary"
                                        onClick={() => {
                                          // console.log("HANDLE");
                                          // this.setState({
                                          //   showProg: true,
                                          // });
                                        }}
                                      >
                                        {this.props.t("Submit")}
                                      </Button>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </AvForm>
                          </TabPane>
                        ) : (
                          <p hidden={this.state.showSuccessAlert}>
                            {this.props.t(
                              "Thank you for your patience while your request is being processed."
                            )}
                          </p>
                        )}
                        <TabPane tabId={4}>
                          <Alert
                            hidden={!this.state.showSuccessAlert}
                            color="success"
                            className="alert-dismissible fade show"
                            role="alert"
                          >
                            <i className="mdi mdi-check-all me-2"></i>
                            <b>{this.props.t("Meeting request submitted")}</b>
                            <p>
                              {this.props.t(
                                "We have received your message and will contact you soon."
                              )}
                            </p>
                          </Alert>

                          <Row>
                            <Col sm="12 m-t-15">
                              <Link to={this.getUserHome()}>
                                <Button color="secondary">
                                  {this.props.t("Back To reports")}
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  displayEnteredWhitchLocations() {
    const location = this.state.locations.find(
      (location) =>
        parseInt(this.state.submitData.locations) === parseInt(location.id)
    );
    if (location) {
      return (
        <Row>
          <Col sm="12">
            <Label className="form-label">
              {this.props.t("Which location(s) is / are affected?")}
            </Label>
            <p className="text-answers">
              {}
              {location.name}
            </p>
          </Col>
        </Row>
      );
    }
    return null;
  }

  fetchQuestions() {
    const url =
      !this.props.user || this.userUtils.isThirdParty(this.props.user)
        ? API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION
        : API_URL_QUESTION_FORM_ORGANIZATION;

    return axios
      .post(
        url,
        {
          organization: this.urlUtils.getOrganizationId(),
          typeReport: 3,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      )
      .then((response) => {
        const {
          questions,
          location,
          report_category: categories,
          organizationCountry: organizationCountry,
        } = response.data.data;
        this.setState({
          questions: questions && Array.isArray(questions) ? questions : [],
          categories: categories && Array.isArray(categories) ? categories : [],
          locations: location && Array.isArray(location) ? location : [],
          organizationCountry: organizationCountry,
        });
      });
  }

  // for fixing translation bugs
  phoneAvailableTimes() {
    this.setState({
      phoneAvailableTimes: [
        {
          optionId: "7_9",
          value: "07:00 - 09:00",
        },
        {
          optionId: "9_12",
          value: "09:00 - 12:00",
        },
        {
          optionId: "12_3",
          value: "12:00 - 15:00",
        },
        {
          optionId: "3_6",
          value: "15:00 - 18:00",
        },
        {
          optionId: "6_8",
          value: "18:00 - 20:00",
        },
        {
          optionId: "custom",
          value: this.props.t("Customize"),
        },
      ],
    });
  }

  sortLocationsByLanguage(language, locations) {
    // const locations = this.state.locations;

    if (locations && Array.isArray(locations)) {
      const collator = new Intl.Collator(language, {
        sensitivity: "base",
      });
      return locations.sort((a, b) =>
        collator.compare(this.props.t(a.name), this.props.t(b.name))
      );
    } else {
      return [];
    }
  }

  componentDidUpdate(prevProps) {
    // when language changed
    // if (prevProps.t !== this.props.t) {
    // 	console.log("locations: ", this.state.locations);

    // 	const sortedLocations = this.sortLocationsByLanguage(localStorage.getItem("i18nextLng"));

    // 	this.setState({
    // 		locations: sortedLocations,
    // 	});
    // }

    if (this.state.reload) {
      this.setState({ displayButton: true, showProg: false, reload: false });
    }
    if (!prevProps.App.loaded && this.props.App.loaded) {
      this.fetchQuestions();
      this.statusLogin();
      this.fetchReportCaseAfterLoginTherdParty();
      this.phoneAvailableTimes();
    }

    // for fixing translation bugs
    if (this.props.t !== prevProps.t) {
      this.phoneAvailableTimes();
      this.fetchReportCaseAfterLoginTherdParty();
      this.setState({
        popover: {
          id: null,
          active: false,
        },
      });
    }
  }

  componentDidMount() {
    if (this.props.App.loaded) {
      this.fetchQuestions();
      this.statusLogin();
    } else {
      this.fetchQuestions();
    }
    this.phoneAvailableTimes();
    // for fixing translation bugs
    if (this.props.App.loaded) {
      this.phoneAvailableTimes();
    }
  }
}

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      logoutUser,
      errorNotification,
      successNotification,
      checkLogin,
      loginUserSuccessful,
      authCurrentUser,
      changeWorkspace,
      appError,
      appLoadStatusChanged,
      setUserAccessToken,
    })(ArrangeMeeting)
  )
);
