import React, {
  memo,
  useMemo,
  useState,
  Fragment,
  useCallback,
  useEffect,
} from "react";
import PageBody from "../../../components/page-body";
import PageHeader from "../../../components/page-header";
import PageContent from "../../../components/page-content";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { Row, Col } from "reactstrap";
import CommonServices from "../../../apis/CommonService";
import DateUtils from "../../../../../services/utils/DateUtils";
import useFilters, { useFilterQuery } from "../../../hooks/useFilters";
import DataProtectionPorcessingDirectoryService from "../../../apis/ProcessingDirectoryService";
import { useQuery } from "@tanstack/react-query";
import CustomTable from "../../../../../components/CustomTable";
import Pagination from "../../../components/pagination";
import { useLocation } from "react-router-dom";
import { userRoll } from "../../../../../pages/Admin/administration/history-logs/constants";
import ViewProcessingDirectoryModal from "../modal/view";
import ClockOutlineIcon from "../../../../../assets/images/clock-outline.svg";
import EyeBlackOutline from "../../../../../assets/images/eye-black-outline.svg";
import StringUtils from "../../../../../services/utils/StringUtils";
import { toast } from "react-toastify";
import "./Style.css";

const DataProtectionProcessingDirectoryList = (props) => {
  const dateUtils = new DateUtils();
  const stringUtils = new StringUtils();
  const { t } = props;
  const location = useLocation();
  const [selectedActivity, setSelectedActivity] = useState(undefined);
  const [modal, setModal] = useState(false);

  const [breadcrumbs, _] = useState([
    { title: "SIP", link: "/" },
    { title: t("Data Protection"), link: "#" },
    { title: t("Processing Activity"), link: "#" },
  ]);

  const userFilterQuery = useFilterQuery({
    Service: CommonServices,
    method: "analystAdminsAndAnalysts",
    params: {},
    filterKey: ["user"],
  });

  const userOptions = useMemo(
    () =>
      userFilterQuery.data?.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      })) ?? [],
    [userFilterQuery.data]
  );

  const filtersSchema = useMemo(
    () => ({
      action: {
        options: [
          { label: t("Select"), value: undefined },
          { label: t("Handover"), value: "handover" },
          {
            label: t("Upload software data from Excel file"),
            value: "upload_csv",
          },
          { label: t("Added a new owner"), value: "add_owner" },
          { label: t("Deleted an owner"), value: "delete_owner" },
          { label: t("Created"), value: "create" },
          { label: t("Download"), value: "download" },
          { label: t("Deleted"), value: "delete" },
          { label: t("Change flag"), value: "toggle_flag" },
          { label: t("Change Status"), value: "change_status" },
          { label: t("Change Priority"), value: "change_priority" },
          { label: t("Change Analyst Assigned"), value: "change_analyst" },
          { label: t("Edit detail"), value: "edit" },
          { label: t("DPIA"), value: "dpia" },
        ],
        type: "select",
        baseLabel: t("Action"),
      },
      section: {
        options: [
          { label: t("Select"), value: undefined },
          { label: t("Hardware"), value: "hardware" },
          { label: t("Software"), value: "software" },
        ],
        type: "select",
        baseLabel: t("Section"),
      },
      user: {
        options: [{ label: t("Select"), value: undefined }, ...userOptions],
        type: "select",
        baseLabel: t("User"),
        query: userFilterQuery,
      },
      fromDate: {
        value: undefined,
        type: "date",
        baseLabel: t("From Date"),
      },
      toDate: {
        value: undefined,
        type: "date",
        baseLabel: t("To Date"),
      },
      pageSize: {
        value: 25,
      },
      pageIndex: {
        value: "1",
      },
    }),
    [t, userOptions]
  );

  const { filterForm, filterValues } = useFilters(filtersSchema);

  const processingActivitiesQuery = useQuery({
    queryKey: ["processingActivities", filterValues],
    queryFn: async () => {
      const service = DataProtectionPorcessingDirectoryService.getInstance();
      const response = await service.list(filterValues);
      return response.data;
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("error while fetchinig processing activities"), {
        type: "error",
      });
    },
  });

  // Table Header
  const tableHeader = [
    [
      {
        value: (
          <p className="text-truncate text-capitalize text-left">{t("#")}</p>
        ),
        width: "5%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {t("Activity")}
          </p>
        ),
        width: "35%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {t("Asset Type")}
          </p>
        ),
        width: "35%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {t("Description")}
          </p>
        ),
        width: "35%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {t("Asset Owner")}
          </p>
        ),
        width: "15%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">{t("Role")}</p>
        ),
        width: "15%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">{t("Date")}</p>
        ),
        width: "15%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {t("Action")}
          </p>
        ),
        width: "5%",
      },
    ],
  ];

  // Generating the description
  const generateDescription = (action, section, hardwareType) => {
    let desc = "";
    switch (action) {
      case "create":
        desc = `${t("A new")} ${t(hardwareType?.toLowerCase())} ${t(
          "was added to the records."
        )}`;
        break;
      case "delete":
        desc = `Remove ${section.toLowerCase()} devices from the system's records.`;
        break;
      case "handover":
        desc = `Document the transfer of ownership or responsibility for data or equipment.`;
        break;
      case "add_owner":
        desc = `Register new owners for specific devices or software.`;
        break;
      case "delete_owner":
        desc = `Remove ownership records from the system.`;
        break;
      case "edit":
        desc = `Modify and refresh existing ${section.toLowerCase()} information.`;
        break;
      case "add_module":
        desc = `Module is added`;
        break;
      case "remove_module":
        desc = `Module is removed`;
        break;
      case "change_responsible_person":
        desc = `Responsible person has been changed`;
        break;
      case "change_company":
        desc = "Company has been changed";
        break;
      case "change_position":
        desc = "Position has been changed";
        break;
      case "change_email":
        desc = "Email has been changed";
        break;
      case "change_phone_number":
        desc = "Phone number has been changed";
        break;
      case "change_responsible_department":
        desc = "Responsible department has been changed";
        break;
      case "change_phone_number":
        desc = "Phone number has been changed";
        break;
      case "dpia":
        desc = `Revise and update Data Protection Impact Assessment (DPIA) records for ${section.toLowerCase()}.`;
        break;
    }

    return t(desc);
  };

  const onViewClick = useCallback((activity) => {
    setSelectedActivity(activity);
    setModal(true);
  }, []);

  const activityData = useMemo(() => {
    if (
      processingActivitiesQuery.isLoading ||
      !processingActivitiesQuery?.data.data?.length
    )
      return [];

    return processingActivitiesQuery.data.data.map((activity) => {
      return [
        {
          value: (
            <p
              className="text-truncate text-capitalize text-left"
              style={{ cursor: "pointer" }}
              onClick={() => onViewClick(activity)}
            >
              {activity.id}
            </p>
          ),
          width: "5%",
        },
        {
          value: (
            <p
              className="font-size-14 text-capitalize text-truncate fw-bold"
              style={{ cursor: "pointer" }}
              onClick={() => onViewClick(activity)}
            >
              {t(activity.action)} {t(activity.section)}
            </p>
          ),
          width: "35%",
        },
        {
          value: activity?.assetType ? (
            <div>
              <p
                className="text-truncate text-capitalize text-left"
                style={{ margin: "0px" }}
              >
                {t(stringUtils.capitalizeFirstLetter(activity?.assetType))}
              </p>
              <span className="subItem">
                {t(stringUtils.capitalizeFirstLetter(activity.section))}
              </span>
            </div>
          ) : (
            ""
          ),
          width: "35%",
        },
        {
          value: (
            <div>
              <p className="text-truncate text-left">
                {t(
                  generateDescription(
                    activity?.action,
                    activity?.section,
                    activity?.assetType
                  )
                )}
              </p>
            </div>
          ),
          width: "35%",
        },
        {
          value: (
            <p className="font-size-14 text-capitalize text-truncate">
              {activity.user_name}
            </p>
          ),
          width: "15%",
        },
        {
          value: (
            <p className="font-size-14 text-capitalize text-truncate">
              {userRoll[activity.user_role]}
            </p>
          ),
          width: "15%",
        },
        {
          value: (
            <p
              style={{ color: "#888AAB" }}
              className="font-size-14 text-capitalize"
            >
              <span className="pe-2">
                <img src={ClockOutlineIcon} width={24} height={24} />
              </span>
              {activity.created_at}
            </p>
          ),
          width: "15%",
        },
        {
          value: (
            <div className="text-truncate text-capitalize text-center d-flex justify-content-center align-items-center remove">
              <a
                style={{ cursor: "pointer" }}
                onClick={() => onViewClick(activity)}
              >
                <img width={24} height={24} src={EyeBlackOutline} />
                <span className="px-2">{t("View")}</span>
              </a>
            </div>
          ),
          width: "5%",
        },
      ];
    });
  }, [processingActivitiesQuery.data, t]);

  // useEffect(() => {
  //   activityData();
  // }, [t])

  return (
    <PageContent>
      <PageHeader title={t("Processing Directory")}>
        <div className="mt-4">
          <Breadcrumbs breadcrumbItems={breadcrumbs} />
        </div>
      </PageHeader>
      <PageBody classNames="box-shadow">
        <div className="container-fluid py-3 px-5">
          <Row className="">{filterForm ?? []}</Row>
        </div>
        {!processingActivitiesQuery.isLoading ||
        !processingActivitiesQuery.isFetching ? (
          !processingActivitiesQuery.isError ? (
            processingActivitiesQuery.data &&
            processingActivitiesQuery.data?.data?.length ? (
              // Table Data
              <Fragment>
                <Row className="px-5">
                  <Col sm="12" md="12" lg="12">
                    <CustomTable headerData={tableHeader} data={activityData} />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Pagination
                      pageIndex={filterValues.pageIndex}
                      pageSize={filterValues.pageSize}
                      totalCount={processingActivitiesQuery.data?.itemsCount}
                      link={location.pathname}
                    />
                  </Col>
                </Row>
              </Fragment>
            ) : (
              // empty
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Fragment>
                    <CustomTable headerData={tableHeader} data={[]} />
                    <div className="p-2">
                      <div className="alert alert-secondary text-center">
                        {t("This List Is Empty")}
                      </div>
                    </div>
                  </Fragment>
                </Col>
              </Row>
            )
          ) : (
            // error
            <Row>
              <Col sm="12" md="12" lg="12">
                <Fragment>
                  <CustomTable headerData={tableHeader} data={[]} />
                  <div className="p-2">
                    <div className="alert alert-danger text-center">
                      {t("Failed To Fetch Softwares.")}
                    </div>
                  </div>
                </Fragment>
              </Col>
            </Row>
          )
        ) : (
          // loading
          <Row>
            <Col sm="12" md="12" lg="12">
              <Fragment>
                <CustomTable headerData={tableHeader} data={[]} />
                <div className="p-2">
                  <div className="alert alert-info text-center">
                    {t("Loading")} ...
                  </div>
                </div>
              </Fragment>
            </Col>
          </Row>
        )}
      </PageBody>
      <ViewProcessingDirectoryModal
        t={t}
        modal={modal}
        setModal={setModal}
        activity={selectedActivity}
      />
    </PageContent>
  );
};

export default withNamespaces()(memo(DataProtectionProcessingDirectoryList));
