import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import axios from "axios";

import {
  ACTION_FETCH_NOTIFICATION_LIST_SAGA,
  ACTION_NOTIFICATION_MARK_AS_READ_SAGA,
} from "./actionTypes";
import {
  updateNotificationListAction,
  markNotificationAsReadAction,
} from "./actions";

import {
  API_URL_NOTIFICATION_LIST,
  API_URL_NOTIFICATION_MARK_READ,
  CM_API_URL_NOTIFICATION_LIST,
  CM_API_URL_NOTIFICATION_MARK_READ,
  GDPR_API_URL_NOTIFICATION_MARK_READ,
} from "../../common/constants";

import store from "../";

const fetchNotificationListFromRemote = async () => {
  const { token } = store.getState().Login;

  if (token === null) {
    console.log(
      "NO TOKEN AVAILABLE! Fetching notifications was blocked to prevent 401 errors."
    );

    return;
  }

  try {
    const response = await axios.get(
			localStorage.getItem('module') === 'wb' ? API_URL_NOTIFICATION_LIST + '?pageIndex=1' : CM_API_URL_NOTIFICATION_LIST + '?pageIndex=1',
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);

    if (response.status !== 200) {
      throw new Error("Unexpected response status");
    }

    if (!response.data || !response.data.data) {
      throw new Error("Invalid response data");
    }

    return response.data.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      throw new Error(err.response.data.error);
    } else {
      throw new Error("An error occurred while fetching notifications.");
    }
  }
};

const markNotificationAsReadOnRemote = async (id) => {
  const { token } = store.getState().Login;
  try {
    const response = await axios.post(
			window.location.href.includes("data-protection") ? GDPR_API_URL_NOTIFICATION_MARK_READ : localStorage.getItem('module') === 'wb' ? API_URL_NOTIFICATION_MARK_READ : CM_API_URL_NOTIFICATION_MARK_READ,
			{
				id: id,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
    return response.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

function* fetchNotifications() {
  try {
    const response = yield call(fetchNotificationListFromRemote);
    yield put(updateNotificationListAction(response ? response : []));
  } catch (error) {}
}

function* markNotificationAsRead(action) {
  try {
    yield call(markNotificationAsReadOnRemote, action.payload);
    yield put(markNotificationAsReadAction(action.payload));
  } catch (error) {}
}

export function* watchFetchNotificationList() {
  yield takeEvery(ACTION_FETCH_NOTIFICATION_LIST_SAGA, fetchNotifications);
}

export function* watchMarkNotificationAsRead() {
  yield takeEvery(
    ACTION_NOTIFICATION_MARK_AS_READ_SAGA,
    markNotificationAsRead
  );
}

function* notificationSaga() {
  yield all([
    fork(watchFetchNotificationList),
    fork(watchMarkNotificationAsRead),
  ]);
}

export default notificationSaga;
