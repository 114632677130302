import React from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  Button,
  CardBody,
  Progress,
  Input,
  Popover,
  PopoverBody,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import UserUtils from "../../services/utils/UserUtils";
import { getFeatureStatusByTitle } from "../../helpers/feature_helper";
import { successNotification, errorNotification } from "../../store/actions";
import {
  AUTH_API_DOMAIN_URL,
  API_URL_UPLOAD_DOCUMENTS,
  API_URL_GET_DOCUMENTS,
} from "../../common/constants";
import { withRouter } from "react-router-dom";
import DeleteConfirmationModal from "../Reporting/Common/DeleteConfirmationModal";

class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Documents"), link: "#" },
      ],
      headers: {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      },

      title: "",

      files: [],
      selectedFiles: [],
      uploadedFiles: [],
      roles: [],
      languages: [],
      isDeleting: false,
      displayErrorIsRequired: false,
      role: false,
      popoverOpen: false,
      typeRequiredError: false,
      showProg: false,
      isModalActive: false,
      selectedDeletionId: "",
    };

    this.userUtils = new UserUtils();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.successNotification = this.props.successNotification.bind(this);
  }


  // // Check box in TP view
  // checkboxFormatter = (cell, row, rowIndex, extraData) => {
  //   // You can use `row` to customize the checkbox based on row data
  //   const handleChange = (e) => {
  //     // Handle checkbox change
  //     console.log(e, e.target.value, row, cell)
  //     console.log(`Checkbox for row ${rowIndex} is now ${e.target.checked}`);
  //     // You may want to update something in your component's state here
  //   };
  
  //   return (
  //     <input
  //       style={{display: "flex", justifyContent: "center"}}
  //       type="checkbox"
  //       checked={cell} // Assuming the cell data is true/false
  //       onChange={handleChange}
  //     />
  //   );
  // };

  handleAcceptedFiles = (files) => {
    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      });

      const formData = new FormData();

      formData.append("file", file);
    });

    this.setState({ selectedFiles: files });
  };

  handleClickViewFiles = (id) => {
    var xhr = new XMLHttpRequest();
    const token = `Bearer ${this.props.token}`;
    const url = `${AUTH_API_DOMAIN_URL}user_manual/${id}/download`;
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Authorization", token);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        // link.download = name;
        // link.click();
        window.open(link);
      }
    };
    xhr.send();
  };

  handleClickDeleteLocalFiles = (file) => {
    const newSelectedFiles = this.state.selectedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });

    const newUploadedFiles = this.state.uploadedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });

    this.setState({
      selectedFiles: newSelectedFiles,
      uploadedFiles: newUploadedFiles,
    });
  };

  handleSubmit(e, values) {
    e.preventDefault();
    const formData = new FormData();


    if (values.title === "") {
      return this.props.errorNotification({
        // code: "fatal_error",
        message: this.props.t("Title can not be blank"),
      });
    }

    if (!this.state.selectedFiles.length) {
      return this.props.errorNotification({
        code: "fatal_error",
        message: this.props.t("You must upload a file"),
      });
    }

    this.setState({
      showProg: true,
      displayErrorIsRequired: false,
    });

    if (!this.state.selectedFiles[0].prev) {
      formData.append("src", this.state.selectedFiles[0]);
    }

    // console.log("VALUES", values)
    formData.append("title", values.title);
    formData.append("language", values.language);
    if (this.state.role) {
      formData.append("role", "ROLE_THIRD_PARTY");
    }
    formData.append("type", "regular");

    axios
      .post(API_URL_UPLOAD_DOCUMENTS, formData, this.state.headers)
      .then((response) => {
        const res = response.data.data;

        if (response.data.ok) {
          this.props.successNotification({
            code: "success",
            message: this.props.t("Data was saved successfully"),
          });

          this.fetchFiles();
          this.setState({ showProg: false,selectedFiles: [], uploadedFiles: [],  type: "", languages: []});
        } else {
          this.props.errorNotification({
            code: "unknown_error",
            message: this.props.t(
              "Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support."
            ),
          });
        }

        this.setState({ title: "" });
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(data.message || data.title),
          });
        }
        this.setState({ showProg: false });
      });
  }

  downloadFile = (id, title) => {
    const url = `${AUTH_API_DOMAIN_URL}user_manual/${id}/download`;
    const token = `Bearer ${localStorage.getItem(
      "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
    )}`;
    console.log(this.props.token);
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Authorization", token);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: "*" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = title + ".pdf";
        link.click();
      }
    };
    xhr.send();
  };

  onModalClosed = () => {
    this.setState({ isModalActive: false });
  };

  handleClickDeleteFiles = (id) => {
    this.setState({ isModalActive: true, selectedDeletionId: id });
  };

  onDeleteSubmit = () => {
    this.setState({isDeleting: true})
    const id = this.state.selectedDeletionId;
    axios
      .delete(`${AUTH_API_DOMAIN_URL}user_manual/${id}/delete`, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        if (response.data.ok) {
          this.fetchFiles();

          this.props.successNotification({
            code: "success",
            message: this.props.t("Item deleted successfully."),
          });
        } else {
          this.props.successNotification({
            code: response.data.error,
            message: this.props.t(response.data.message),
          });
        }
      })
      .catch((error) => {
        this.props.errorNotification({
          code: error.response.data.error,
          message: this.props.t(error.response.data.message),
        });
      })
      .finally(() => this.setState({ isModalActive: false, isDeleting: false }));
  };

  onThirdPartyEdit = (id, checked) => {
    let role= null;
    if(checked) {
      role = "ROLE_THIRD_PARTY";
    }

    axios
      .post(`${AUTH_API_DOMAIN_URL}user_manual/${id}/edit`, {
        role: role
      } , {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        if (response.data.ok) {
          // this.fetchFiles();

          this.props.successNotification({
            code: "success",
            message: this.props.t("Item updated successfully."),
          });
        } else {
          this.props.successNotification({
            code: response.data.error,
            message: this.props.t(response.data.message),
          });
        }
      })
      .catch((error) => {
        this.props.errorNotification({
          code: error.response.data.error,
          message: this.props.t(error.response.data.message),
        });
      })
      .finally(() => this.setState({ isModalActive: false, isDeleting: false }));
  };

  onTypeChange = (e) => {
    this.setState({
      type: e.target.value,
      typeRequiredError: false,
    });
  };

  renderUploadData() {
    if (!this.userUtils.isAnalystAdmin(this.props.user)) {
      return;
    }

    return (
      <AvForm className="needs-validation" onValidSubmit={this.handleSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Row>
                <Col xs={6} lg={4}>
                  <div className="mb-3">
                    <Label className="form-label">{`${this.props.t(
                      "Title"
                    )}`}</Label>
                    <AvField
                      type="text"
                      name="title"
                      value={this.state.title || ""}
                      placeholder=""
                      // validate={{ required: { value: true } }}
                      className="form-control"
                      errorMessage={this.props.t("This field cannot be blank")}
                      onChange={(e) => {
                        this.setState({ title: e.target.value });
                      }}
                    />
                  </div>
                </Col>
                <Col xs={6} lg={4}>
                  <div className="mb-3">
                    <Label className="form-label">{`${this.props.t(
                      "Language"
                    )}`}</Label>

                    <AvField
                      type="select"
                      name="language"
                      errorMessage={this.props.t("This field cannot be blank")}
                      className="form-control"
                      value={null}
                      validate={{ required: { value: true } }}
                    >
                      <option value="" />
                      {this.props.organization.languages.map((language) => {
                        return (
                          <option
                            key={language.language.id}
                            value={language.language.id}
                          >
                            {this.props.t(language.name)}
                          </option>
                        );
                      })}
                    </AvField>
                  </div>
                </Col>
                
                <Col xs={6} lg={3}>
                  <div className="mt-4">
                     <div  className=" d-flex gap-1 align-items-center pt-2 ">
                        <Input 
                         type="checkbox" 
                         checked={this.state.role} 
                         onChange={() => this.setState({role: !this.state.role})} 
                         className="mb-1 mx-1" 
                        />{
                        `${this.props.t("Show in third party view")}`}
                        	<Button
														id='dropzone-popover-primary-logo-note'
														className='button mx-0 p-0'
														type='button'
														color='link'
                            style={{ fontSize: 18 , marginTop:"1px"}}
														onClick={() =>
														this.setState({
														popoverOpen: !this.state.popoverOpen,
														})
														}
														>
														<i className='ri-information-line' style={{ fontSize: 18 }}></i>
														</Button>
                             <Popover
														placement='bottom'
														target='dropzone-popover-primary-logo-note'
                                isOpen={this.state.popoverOpen}
                                 toggle={() =>
                                  this.setState({
                                    popoverOpen: !this.state.popoverOpen,
                                      })
                                          }
                                        >
																				
														<PopoverBody>
                              <span>
														{this.props.t('Please select this box if you would like to show this document in the third party view. If not selected the document will be available for employees only via the admin and employee view.')}
														<br />
														</span>
												</PopoverBody>
											</Popover>
                    </div>
                  </div>
                </Col>
              </Row>
              <Col xs={12}>
                <div className="mb-3">
                  <Label className="form-label">{`${this.props.t(
                    "Upload a new document"
                  )} (PDF)`}</Label>
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      this.handleAcceptedFiles(acceptedFiles)
                    }
                    maxFiles={1}
                    accept=".pdf"
                    multiple={false}
                    required
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                          </div>
                          <h4>
                            {this.props.t("Drop files here or click to upload")}
                          </h4>
                          {!this.state.selectedFiles.length &&
                          this.state.displayErrorIsRequired ? (
                            <p style={{ color: "red", fontSize: 14 }}>
                              {this.props.t("This field cannot be blank")}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <Progress
                    color="info"
                    striped
                    animated
                    value={100}
                    hidden={!this.state.showProg}
                    className="mt-3"
                  >
                    {this.props.t("Please wait while the file is uploaded")}
                  </Progress>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {this.state.selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-3">
                            <Row className="align-items-center">
                              <Col>
                                <Link to="#" className="text-muted fw-bold">
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col xs={2} style={{ textAlign: "right" }}>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() =>
                                    this.handleClickDeleteLocalFiles(f)
                                  }
                                >
                                  X
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() =>
                      !this.state.selectedFiles.length
                        ? this.setState({ displayErrorIsRequired: true })
                        : this.setState({ displayErrorIsRequired: false })
                    }
                  >
                    {this.props.t("Upload")}
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </AvForm>
    );
  }

  renderList = (list) => {
    return list.map((item) => {
      return {
        id: item.id,
        title: item.title,
        language: item.language.name,
        role: item?.role,
        type:
          !("type" in item) || item?.type === "regular"
            ? this.props.t("Other")
            : item?.type === "user_policy"
            ? this.props.t("User policy")
            : item?.type === "privacy_policy"
            ? this.props.t("Privacy policy")
            : "",
        download: (
          <Button
            color="btn bt-primary"
            size="sm"
            onClick={() => this.downloadFile(item.id, item.title)}
          >
            {this.props.t("Download")}
          </Button>
        ),
        actions: (
          <>
            {this.userUtils.isAnalystOrAnalystAdmin(this.props.user) && (
              <Button
                onClick={() => this.handleClickDeleteFiles(item.id)}
                color="link"
                className="waves-effect me-1 text-danger"
              >
                <i className="ri-delete-bin-fill"></i>
              </Button>
            )}
            <Button
              onClick={() => this.handleClickViewFiles(item.id)}
              color="link"
              className="waves-effect me-1"
            >
              <i className="ri-eye-fill"></i>
            </Button>
          </>
        ),
      };
    });
  };

  renderUploadedFiles = () => {
    const columns = [
      // {
      // 	dataField: "id",
      // 	text: this.props.t("ID"),
      // 	sort: true,
      // },
      {
        dataField: "title",
        text: this.props.t("Title"),
        sort: true,
      },
      {
        dataField: "language",
        text: this.props.t("Language"),
        sort: true,
      },
      {
        dataField: "role",
        text: this.props.t("Role"),
        sort: true,
        formatter: (cell , row) => {
          switch(row?.role){
            case 'ROLE_USER':
              return this.props.t("User");
            case 'ROLE_EMPLOYEE':
              return this.props.t("Employee");
            case 'ROLE_THIRD_PARTY':
              return this.props.t("Third Party");
            case 'ROLE_ANALYST':
              return this.props.t("Analyst");
            case 'ROLE_ANALYST_ADMIN':
              return this.props.t("Analyst Admin");
            case 'ROLE_PARTNER':
              return this.props.t("Partner");
            case 'ROLE_RESELLER':
              return this.props.t("Reseller");
            case 'ROLE_TASK_OWNER':
              return this.props.t("Task Owner");
            default:
              return this.props.t("Analyst/Employee");
          }
        }
      },
      {
        dataField: "type",
        text: this.props.t("Type"),
        sort: true,
      },
      {
        dataField: "download",
        text: this.props.t("Download"),
        sort: false,
      },
      {
        dataField: "tpCheckBox",
        text: this.props.t("Show in third party view"),
        sort: false,
        hidden: !this.props.user?.roles.includes("ROLE_ANALYST_ADMIN"),
        formatter: (cell, row) => {
          const checked = row?.role === 'ROLE_THIRD_PARTY';

          return <input
            style={{display: "flex", justifyContent: "center"}}
            type="checkbox"
            defaultChecked={checked}
            onChange={(e) => this.onThirdPartyEdit(row?.id, e.target.checked)}
          />
        } 
      },
      {
        dataField: "actions",
        text: this.props.t("Actions"),
        sort: false,
      },
    ];

    const defaultSorted = [];

    const pageOptions = {
      sizePerPage: 100,
      totalSize: this.state.files.length,
      custom: true,
    };
    return (
      <Card>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={columns}
            data={this.state.files}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={this.state.files}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    );
  };

  fetchFiles = () => {
    axios
      .get(API_URL_GET_DOCUMENTS, this.state.headers)
      .then((response) => {
        if (response.data.data) {
          this.setState({
            files: this.renderList(response.data.data),
          });
        } else {
          this.setState({
            files: this.renderList([]),
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;

          this.props.errorNotification({
            code: data.error || "unknown_error",
            message: this.props.t(data.message || data.title),
          });
        }
      });
  };

  fetchLanguages = () => {
    const languages = this.props.organization.languages;
    this.setState({
      languages,
    });
  };

  render() {
    if (!getFeatureStatusByTitle(this.props.Feature, "Documents")) {
      return (
        <div className="page-content">
          {this.props.t("Please upgrade your plan to access this feature")}.
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Documents")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl="12">
                {this.renderUploadData()}
                {this.renderUploadedFiles()}
              </Col>
            </Row>

            <DeleteConfirmationModal
              modalActivated={this.state.isModalActive}
              t={this.props.t}
              isLoading={this.state.isDeleting}
              title={"document"}
              onModalClosed={this.onModalClosed}
              onDeleteSubmit={this.onDeleteSubmit}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
  

  componentDidMount() {
    this.fetchLanguages();
    this.fetchFiles();

  }

  componentDidUpdate() {
    if (this.state.lang !== localStorage.getItem("i18nextLng")) {
      this.setState(
        {
          lang: localStorage.getItem("i18nextLng"),
        },
        () => setTimeout(() => this.fetchFiles(), 200)
      );
    }
  }
}

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const { Feature } = state;
  return { organization, token, App, user, Feature };
};

const mapDispachToProps = (dispach) => {
  return {
    errorNotification: (message) => dispach(errorNotification(message)),
    successNotification: (message) => dispach(successNotification(message)),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispachToProps)(Documents))
);
// This component was moved from /pages/Admin/administration/Documents to /pages/Documents (SPIK-1109).
