import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  API_URL_ACTIVE_DIRECTORY,
  API_URL_ACTIVE_DIRECTORY_FETCH,
  API_URL_ADMIN_CREATE_USER_MANAGEMENT,
} from "../../../common/constants";
import { post } from "../../../helpers/request_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { successNotification, errorNotification } from "../../../store/actions";
import {
  DATA_NOT_SAVED_SUCCESSFULLY,
} from "../../../common/constants/Messages";
import Papa from "papaparse";
import axios from "axios";
import { withRouter } from "react-router-dom";

class ADSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first: "",
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("AD settings"), link: "#" },
      ],
      items: [
        {
          first_name: "",
          last_name: "",
          email: "",
          position: "",
        },
      ],
      header: {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      },
      azure: [],
      ldap: [],
      clientId: "",
      tenantId: "",
      clientSecret: "",
      azureEnable: false,
      ldapServer: "",
      ldapPort: "",
      ldapBaseDN: "",
      ldapSecurity: "",
      ldapEnable: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addUser = this.addUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.uploadCSV = this.uploadCSV.bind(this);
  }

  fetchData = () => {
    const url = API_URL_ACTIVE_DIRECTORY_FETCH;
    axios
      .get(url, this.state.header)
      .then((res) => {
        const data = res.data.data;
        const { azure, ldap } = data;
        if (azure.tenant_id || azure.client_secret || azure.tenant_id) {
          Object.assign(azure, { azureEnable: true });
          this.setState({ azure: azure, first: "Azure AD" });
        }
        if (ldap.base_domain_name || ldap.port_number || ldap.server_address) {
          Object.assign(ldap, { ldapEnable: true });
          this.setState({ ldap: ldap, first: "LDAP" });
        }
      })
      .catch((err) => console.log(err));
  };

  handleSubmit() {
    post(
      API_URL_ADMIN_CREATE_USER_MANAGEMENT,
      this.state.items,
      this.state.header
    ).then(() => {
      this.props.successNotification({
        code: "saved",
        message: this.props.t("Data was saved successfully"),
      });
      this.props.history.push("/admin/administration/users");
    });
  }

  fieldValueChanged(index, key, value) {
    this.setState({
      items: this.state.items.map((item, itemIndex) => {
        if (index === itemIndex) {
          let newValue = item;
          newValue[key] = value;
          return newValue;
        }
        return item;
      }),
    });
  }

  addUser() {
    const items = this.state.items;
    items.push({
      email: "",
      position: "",
      first_name: "",
      last_name: "",
    });
    this.setState({
      items: items,
    });
  }

  deleteUser(index) {
    if (this.state.items.length > 1) {
      this.setState({
        items: this.state.items.filter((item, itemIndex) => {
          return index !== itemIndex;
        }),
      });
    }
  }

  uploadCSV(e) {
    const files = e.target.files;
    if (files && files.length > 0) {
      Papa.parse(files[0], {
        complete: (results, file) => {
          const data = results.data;
          const errors = results.errors;

          if (errors && errors.length > 0) {
            this.props.errorNotification({
              code: "CAN_NOT_PARSE_CSV",
              message: this.props.t("Error to parse the csv file"),
            });
            return;
          }

          if (data && data.length > 0) {
            let firstNameIndex = -1;
            let lastNameIndex = -1;
            let emailIndex = -1;
            let companyPositionIndex = -1;
            let headerIndex = -1;
            const newRows = [];
            for (let i = 0; i < data.length; i++) {
              const items = data[i];
              if (items && items.length > 0 && headerIndex === -1) {
                firstNameIndex = items.findIndex(
                  (label) => label.toLowerCase() === "first_name"
                );
                lastNameIndex = items.findIndex(
                  (label) => label.toLowerCase() === "last_name"
                );
                emailIndex = items.findIndex(
                  (label) => label.toLowerCase() === "email"
                );
                companyPositionIndex = items.findIndex(
                  (label) => label.toLowerCase() === "position"
                );

                if (
                  firstNameIndex !== -1 &&
                  lastNameIndex !== -1 &&
                  emailIndex !== -1 &&
                  companyPositionIndex !== -1
                ) {
                  headerIndex = i;
                }
              }

              if (
                headerIndex > -1 &&
                i > headerIndex &&
                items.length === data[headerIndex].length
              ) {
                newRows.push({
                  first_name: items[firstNameIndex] || "",
                  last_name: items[lastNameIndex] || "",
                  email: items[emailIndex] || "",
                  position: items[companyPositionIndex] || "",
                });
              }
            }

            if (headerIndex === -1) {
              this.props.errorNotification({
                code: "CAN_NOT_PARSE_CSV",
                message: this.props.t(
                  "Invalid format, please check the template for details"
                ),
              });
              return;
            }
            if (newRows.length > 0) {
              this.setState({
                items: newRows,
              });
            }
          }
        },
      });
    }
  }

  handleChange = (e, value) => {
    if (value === "Azure AD" || value === "LDAP") {
      this.setState({
        first: value,
      });
    }
    if (value === "clientId") {
      this.setState({ clientId: e.target.value });
    }

    if (value === "tenantId") {
      this.setState({ tenantId: e.target.value });
    }

    if (value === "clientSecret") {
      this.setState({ clientSecret: e.target.value });
    }

    if (value === "azureEnable") {
      this.setState({ azureEnable: !this.state.azureEnable });
    }

    if (value === "ldapServer") {
      this.setState({ ldapServer: e.target.value });
    }

    if (value === "ldapPort") {
      this.setState({ ldapPort: e.target.value });
    }

    if (value === "ldapBaseDN") {
      this.setState({ ldapBaseDN: e.target.value });
    }

    if (value === "ldapSecurity") {
      this.setState({ ldapBaseDN: e.target.value });
    }

    if (value === "ldapEnable") {
      this.setState({ ldapEnable: !this.state.ldapEnable });
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    const url = API_URL_ACTIVE_DIRECTORY;
    const data = {};
    const {
      clientId,
      tenantId,
      clientSecret,
      azureEnable,
      ldapServer,
      ldapPort,
      ldapBaseDN,
      ldapSecurity,
      ldapEnable,
    } = this.state;
    if (this.state.first === "Azure AD") {
      Object.assign(data, {
        azure: {
          clientId: clientId,
          tenantId: tenantId,
          clientSecret: clientSecret,
          enable: azureEnable,
        },
      });
    } else if (this.state.first === "LDAP") {
      Object.assign(data, {
        ldap: {
          serverAddress: ldapServer,
          portNumber: ldapPort,
          baseDomainName: ldapBaseDN,
          security: ldapSecurity,
          enable: ldapEnable,
        },
      });
    }
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        this.props.successNotification({
          code: "saved",
          message: this.props.t("Data was saved successfully"),
        });

        this.setState({
          first: "",
          clientId: "",
          tenantId: "",
          clientSecret: "",
          ldapServer: "",
          ldapPort: "",
          ldapBaseDN: "",
          ldapSecurity: "",
          ldapEnable: false,
          azureEnable: false,
        });
      })
      .catch((error) =>
        this.props.errorNotification({
          DATA_NOT_SAVED_SUCCESSFULLY,
        })
      );
    // let formData = {};

    // formData.selectOption = this.state.first;

    // if (this.state.first === "Azure AD") {
    //   formData.clientId = e.target["azure-1"].value;
    //   formData.tenantId = e.target["azure-2"].value;
    //   formData.clientSecret = e.target["azure-3"].value;
    // }

    // if (this.state.first === "LDAP") {
    //   formData.server = e.target["ldap-1"].value;
    //   formData.port = e.target["ldap-2"].value;
    //   formData.baseDn = e.target["ldap-3"].value;
    //   formData.Security = e.target["ldap-4"].value;
    // }

    // console.log(formData);
  }
  render() {
    const { azure, ldap } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("AD settings")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <div className="p-4">
                        <div className="mb-2">
                          <Input
                            style={{ marginRight: 6 }}
                            id="field-1"
                            name="first"
                            type="radio"
                            onChange={(e) => this.handleChange(e, "Azure AD")}
                            defaultChecked={azure.azureEnable}
                          />
                          <Label htmlFor="field-1" check>
                            {this.props.t("Azure AD")}
                          </Label>
                        </div>
                        <div className="mb-2">
                          <Input
                            style={{ marginRight: 6 }}
                            id="field-2"
                            name="first"
                            type="radio"
                            onChange={(e) => this.handleChange(e, "LDAP")}
                            defaultChecked={ldap.ldapEnable}
                          />
                          <Label htmlFor="field-2" check>
                            {this.props.t("LDAP")}
                          </Label>
                        </div>
                      </div>
                      <div className="p-4">
                        {this.state.first === "Azure AD" && (
                          <div>
                            <hr />
                            <div className="mb-3">
                              <label htmlFor="azure-1">
                                {this.props.t("Client ID")}
                              </label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  this.handleChange(e, "clientId")
                                }
                                defaultValue={azure.client_id}
                                id="azure-1"
                                name="azure-1"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="azure-2">
                                {this.props.t("Tenant ID")}
                              </label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  this.handleChange(e, "tenantId")
                                }
                                defaultValue={azure.tenant_id}
                                id="azure-2"
                                name="azure-2"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="azure-3">
                                {this.props.t("Client Secret")}
                              </label>
                              <Input
                                type="text"
                                id="azure-3"
                                onChange={(e) =>
                                  this.handleChange(e, "clientSecret")
                                }
                                defaultValue={azure.client_secret}
                                name="azure-3"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="azure-3">
                                {" " + this.props.t("Enable") + " "}
                              </label>
                              <Input
                                type="checkbox"
                                id="azure-3"
                                onChange={(e) =>
                                  this.handleChange(e, "azureEnable")
                                }
                                defaultChecked={azure.azureEnable}
                                name="azure-3"
                              />
                            </div>
                          </div>
                        )}
                        {this.state.first === "LDAP" && (
                          <div>
                            <hr />
                            <div className="mb-3">
                              <label htmlFor="ldap-1">
                                {this.props.t("Server")}
                              </label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  this.handleChange(e, "ldapServer")
                                }
                                id="ldap-1"
                                name="ldap-1"
                                defaultValue={ldap.server_address}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-2">
                                {this.props.t("Port")}
                              </label>
                              <Input
                                type="text"
                                id="ldap-2"
                                name="ldap-2"
                                onChange={(e) =>
                                  this.handleChange(e, "ldapPort")
                                }
                                defaultValue={ldap.port_number}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-3">
                                {this.props.t("Base DN")}
                              </label>
                              <Input
                                type="text"
                                id="ldap-3"
                                name="ldap-3"
                                defaultValue={ldap.base_domain_name}
                                onChange={(e) =>
                                  this.handleChange(e, "ldapBaseDN")
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-4">
                                {this.props.t("Security")}
                              </label>
                              <Input
                                type="select"
                                id="ldap-4"
                                name="ldap-4"
                                onChange={(e) =>
                                  this.handleChange(e, "ldapSecurity")
                                }
                              >
                                <option value="NONE">
                                  {this.props.t("NONE")}
                                </option>
                                <option value="TLS">
                                  {this.props.t("TLS")}
                                </option>
                                <option value="STARTTLS">
                                  {this.props.t("STARTTLS")}
                                </option>
                                <option value="INSECURETLS">
                                  {this.props.t("INSECURETLS")}
                                </option>
                                <option value="INSECURESTARTTLS">
                                  {this.props.t("INSECURESTARTTLS")}
                                </option>
                              </Input>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="ldap-3">
                                {" " + this.props.t("Enable") + " "}
                              </label>
                              <Input
                                type="checkbox"
                                id="ldap-3"
                                onChange={(e) =>
                                  this.handleChange(e, "ldapEnable")
                                }
                                defaultChecked={ldap.ldapEnable}
                                name="ldap-3"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <Button color="primary" type="submit">
                        {this.props.t("Save")}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.fetchData();
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Feature } = state;
  return { token, state, Feature };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(ADSettings)
  )
);
