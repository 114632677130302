import axios from "axios";
import { GDPR_DOMAIN_URL } from "../../../common/constants";

export default class OwnersService {
  static instance;
  resource = `${GDPR_DOMAIN_URL}software/`;
  owner_resource = `${GDPR_DOMAIN_URL}software_owner/`;
  constructor() {}

  static getInstance() {
    if (!OwnersService.instance) {
      OwnersService.instance = new OwnersService();
    }

    return OwnersService.instance;
  }

  async list(pageIndex, pageSize, softwareId, filters) {
    const response = await axios.get(
      `${this.resource}${softwareId}/owner_list`,
      {
        params: {
          pageSize: pageSize,
          pageIndex: pageIndex,
          ...filters,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  async detail(id) {
    try {
      const response = await axios.get(`${this.owner_resource}${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      });

      return response.data.data;
    } catch (e) {
      throw e;
    }
  }

  async create(softwareId, data) {
    const response = await axios.post(
      `${this.resource}${softwareId}/add_owner`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data;
  }

  async delete(id) {
    try {
      const response = await axios.delete(
        `${this.owner_resource}${id}/delete`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async updateDefaultOwner(data) {
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_GDPR_API
        }/api/organization/default_owner`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
          },
        }
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}
