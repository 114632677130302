import React, { Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { withNamespaces } from "react-i18next";

import { changeLayout, changeTopbarTheme, toggleRightSidebar, changeLayoutWidth } from "../../store/actions";

// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";

import { loginUserSuccessful, logoutUser } from "../../store/auth/login/actions";
import { changeWorkspace } from "../../store/workspace/actions";
import { Button } from "reactstrap";

import ChatBox from "../Chatbox";
import UserUtils from "../../services/utils/UserUtils";
import ModuleContext from "../../contexts/ModuleContext";
import { withRouter } from "react-router-dom";

class Layout extends Component {
  static contextType = ModuleContext;

  constructor(props) {
		super(props);
		this.state = {
			isMenuOpened: false,
			chatboxOpened: false,
			isChatStarted: false,
			exitChatConfirmed: false,
			confirmExitChat: false,
		};
		this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
		this.toggleChatbox = this.toggleChatbox.bind(this);
		this.chatStarted = this.chatStarted.bind(this);
		this.userUtils = new UserUtils();
	}

	/**
	 * Open/close right sidebar
	 */
	toggleRightSidebar() {
		this.props.toggleRightSidebar();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.App.error.status && this.props.App.error.status) {
			this.props.history.push("/fatal-error");
		}

		if (this.state.exitChatConfirmed && this.state.exitChatConfirmed !== prevState.exitChatConfirmed) {
			this.setState({
				chatboxOpened: false,
				isChatStarted: false,
			});
		}
	}

	componentDidMount() {
		// Scrollto 0,0
		window.scrollTo(0, 0);

		const title = this.props.location.pathname;
		let currentage = title.charAt(1).toUpperCase() + title.slice(2);
		currentage = currentage.replaceAll("-", " ");

		document.title = currentage + " | SIP";

		this.props.changeLayout("horizontal");
		if (this.props.topbarTheme) {
			// this.props.changeTopbarTheme(this.props.topbarTheme);
			this.props.changeTopbarTheme("dark");
		}
		if (this.props.layoutWidth) {
			this.props.changeLayoutWidth(this.props.layoutWidth);
		}
		if (this.props.showRightSidebar) {
			this.toggleRightSidebar();
		}
	}

	toggleChatbox() {
		if (!this.state.chatboxOpened) {
			this.setState({
				chatboxOpened: true,
			});
		} else {
			if (this.state.isChatStarted) {
				this.setState({
					confirmExitChat: true,
				});
			} else {
				this.setState({
					chatboxOpened: false,
				});
			}
		}
	}

	/**
	 * Opens the menu - mobile
	 */
	openMenu = (e) => {
		this.setState({ isMenuOpened: !this.state.isMenuOpened });
	};

	chatStarted() {
		this.setState({
			isChatStarted: true,
		});
	}

	userCanChat() {
		const user = this.props.user;
		if (user && (this.userUtils.isThirdParty(user) || this.userUtils.isEmployee(user))) {
			return true;
		}
		return false;
	}

	render() {
		return (
			<React.Fragment>
				{this.state.confirmExitChat && (
					<SweetAlert
						title="Are you sure?"
						warning
						showCancel
						confirmBtnBsStyle="success"
						cancelBtnBsStyle="danger"
						onConfirm={() =>
							this.setState({
								chatboxOpened: false,
								exitChatConfirmed: true,
								confirmExitChat: false,
								dynamic_title: "Confirm",
							})
						}
						onCancel={() =>
							this.setState({
								confirmExitChat: false,
							})
						}
					>
						{this.props.t("Confirm the end of the chat")}
					</SweetAlert>
				)}

				<div id="layout-wrapper" style={{display:'flow-root'}}>
					<Header theme={this.props.topbarTheme} isMenuOpened={this.state.isMenuOpened} toggleRightSidebar={this.toggleRightSidebar} openLeftMenuCallBack={this.openMenu} />
					<Navbar menuOpen={this.state.isMenuOpened} />
					<div className="main-content">
						{this.userCanChat() && this.context.selectedModule && this.context.settings[this.context.selectedModule].isChat ? (
							<div className="chatbox-container">
								<Button color="primary" onClick={this.toggleChatbox}>
									{this.state.chatboxOpened ? <i className="ri-close-line"></i> : <i className="ri-chat-3-line"></i>}
								</Button>
								{this.state.chatboxOpened && <ChatBox chatStarted={this.chatStarted} />}
							</div>
						):null}
						{this.props.children}
						<Footer />
					</div>
				</div>
				<Rightbar />
			</React.Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { App, Organization } = state;
	return {
		...state.Layout,
		token,
		user,
		App,
		Organization,
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {
		changeTopbarTheme,
		toggleRightSidebar,
		changeLayout,
		changeLayoutWidth,
		loginUserSuccessful,
		changeWorkspace,
		logoutUser,
	})(withRouter(Layout))
)



