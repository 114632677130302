import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { CircularProgress } from "@material-ui/core";
// import "./ModalNoBackground.css";

const CircularProgressBarModal = ({ isOpen, t }) => {
  return (
    <Modal isOpen={isOpen} centered className="modal-transparent">
      <ModalHeader>
        <Label className="pt-2 ps-5">
          {t("Please wait, we are anonymizing your voice file.")}
        </Label>
      </ModalHeader>
      <ModalBody className="text-center">
        <CircularProgress size={80} thickness={4} />
      </ModalBody>
    </Modal>
  );
};

CircularProgressBarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default CircularProgressBarModal;
