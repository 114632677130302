import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Tooltip,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";

import { setCaseId } from "../../../store/actions";
import DateUtils from "../../../services/utils/DateUtils";

// import i18next from "i18next";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  API_URL_ADMIN_DASHBOARD_LAST_CASES,
  API_URL_REPORT_CATEGORY_LIST_CASE,
  CM_API_URL_ADMIN_DASHBOARD_LAST_CASES,
  CM_API_URL_REPORT_CATEGORY_LIST_CASE,
  INTERNATIONAL_DATE_FORMAT,
} from "../../../common/constants";
import { REPORT_STATUS_LIST } from "../../../components/constants";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { withRouter } from "react-router-dom";

// const expandRow = {
//   renderer: row => (
//     <>
//       Action :
//       <Link to="#" className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
//       <Link to="#" className="text-danger" ><i className="mdi mdi-trash-can font-size-18"></i></Link>
//     </>
//   ),
//   showExpandColumn: true,
//   expandByColumnOnly: true
// };

class LatestTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      categories: [],
      latestCases: [],
      latestTooltip: false,
      module: localStorage.getItem("module"),
    };
    this.dateUtils = new DateUtils();
  }

  render() {
    const data = {
      columns: [
        {
          dataField: "secondaryCaseId",
          text: this.props.t("Case ID"),
          formatter: (cell, row) => (
            <Link
              className="text-dark"
              to={`/admin/report/detail`}
              onClick={() => {
                localStorage.setItem("caseId", row.caseId);
                localStorage.setItem("secondaryCaseId", row.secondary_case_id);
                this.props.onSetCaseId(row.caseId);
              }}
            >
              {/* {console.log("row: ", row)} */}
              {cell}
            </Link>
          ),
          style: {
            // Add style to ensure case_id is in one line without line breaks
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          dataField: "description",
          text: this.props.t("Description"),
          formatter: (cell, row) => (
            <Link
              className="text-dark"
              to={`/admin/report/detail`}
              // style={{
              //   whiteSpace: "nowrap",
              //   overflow: "hidden",
              //   textOverflow: "ellipsis",
              //   maxWidth: "200px",
              // }}
              onClick={() => {
                localStorage.setItem("caseId", row.caseId);
                localStorage.setItem("secondaryCaseId", row.secondary_case_id);
                this.props.onSetCaseId(row.caseId);
              }}
            >
              {/* {console.log("row: ", row)} */}
              {cell}
            </Link>
          ),
          style: {
            maxWidth: "50rem", // Adjust the width based on your preference
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          dataField: "category",
          text: this.props.t("Category"),
        },
        {
          dataField: "createdAt",
          text: this.props.t("Created At"),
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        {
          dataField: "analyst_assigned",
          text: this.props.t("Assigned analyst"),
        },
        {
          dataField: "status",
          text: this.props.t("Status"),
        },
      ],
      rows: [],
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 5,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: data.rows.length,
        },
      ],
    };

    // const selectRow = {
    //   mode: 'checkbox',
    //   clickToSelect: true
    // };
    // console.log("DATA", this.state.latestCases)
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {/*
							Forgot the task number.
							
							<Dropdown isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })} className="float-end">
								<DropdownToggle tag="i" className="arrow-none card-drop">
								<i className="mdi mdi-dots-vertical"></i>
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-end">
								<DropdownItem>Sales Report</DropdownItem>
								<DropdownItem>Export Report</DropdownItem>
								<DropdownItem>Profit</DropdownItem>
								<DropdownItem>Action</DropdownItem>
								</DropdownMenu>
							</Dropdown> 
						*/}

            <h4 className="card-title mb-4">
              {
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{this.props.t("Latest Cases")}</div>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIconLatest"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.latestTooltip}
                    target="infoIconLatest"
                    toggle={() =>
                      this.setState({
                        latestTooltip: !this.state.latestTooltip,
                      })
                    }
                  >
                    {this.props.t(
                      "With these tickets, you can see the progression of cases that were reported"
                    )}
                  </Tooltip>
                </div>
              }
            </h4>
            <div
              className="table-responsive dissco-latest-cases"
              style={{ overflowX: "hidden", maxWidth: "100%" }}
            >
              <BootstrapTable
                keyField="id"
                data={this.state.latestCases}
                columns={data.columns}
                // expandRow={expandRow}
                // selectRow={selectRow}
                pagination={paginationFactory(options)}
                options={{
                  hideSizePerPage: true,
                }}
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }

  renderReportStatus(report) {
    const status = REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(report)
    );
    return (
      <div className={status?.className}>{this.props.t(status?.titleKey)}</div>
    );
  }

  fetchCategoryList() {
    return axios.post(
      this.state.module === "cm"
        ? CM_API_URL_REPORT_CATEGORY_LIST_CASE
        : API_URL_REPORT_CATEGORY_LIST_CASE,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      }
    );
  }

  makeListReady() {
    this.fetchCategoryList()
      .then((response) => {
        // console.log(response.data.data);
        this.setState({
          categories: response.data.data,
        });
        // this.fetchReports({})
        //     .then(() => {
        //         this.makeFiltersReady();
        //     });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getReportCategories(reportCategories) {
    let result = [];

    // console.log('this.state.categories')
    // console.log(this.state.categories)
    if(reportCategories) {
      reportCategories.map((reportCategory) => {
        for (let i = 0; i < this.state.categories.length; i++) {
          if (
            parseInt(reportCategory) === parseInt(this.state.categories[i].id)
          ) {
            result.push(this.props.t(this.state.categories[i].title));
            break;
          }
        }
      });
    }
    
    return result.join(", ");
  }

  getReportAnalystAssigned(assigneds) {
    let result = [];

    if (!assigneds) {
      assigneds = [];
    } else if (!Array.isArray(assigneds) && typeof assigneds === "object") {
      assigneds = [assigneds];
    }

    if (assigneds.length > 0) {
      assigneds.map((item) => {
        result.push(`${item.first_name} ${item.last_name}`);
      });
    }

    return result;
  }

  async fetchLatestCases() {
    try {
      const response = await axios.get(
        `${
          this.state.module === "cm"
            ? CM_API_URL_ADMIN_DASHBOARD_LAST_CASES
            : API_URL_ADMIN_DASHBOARD_LAST_CASES
        }${this.props.orgIsAll ? "?partner=all" : ""}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      );
      const lastCases = response.data.data;
      const newListCases = [];

      // console.log("---- ", lastCases);

      for (const item in lastCases) {
        newListCases.push({
          description: lastCases[item].description,
          category: this.getReportCategories(lastCases[item]?.category),
          createdAt: this.dateUtils.convertTimeStampToDate(
            lastCases[item].createdAt,
            INTERNATIONAL_DATE_FORMAT
          ),
          analyst_assigned:
            this.getReportAnalystAssigned(
              lastCases[item].analyst_assigned
            ).join(", ") || this.props.t("Unassigned"),
          status: this.renderReportStatus(lastCases[item].status),
          caseId: lastCases[item].caseId,
          secondaryCaseId: lastCases[item].secondary_case_id,
        });
      }

      this.setState({
        latestCases: newListCases,
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this.makeListReady();
      this.fetchLatestCases();
    }

    if (prevProps.orgIsAll !== this.props.orgIsAll) this.fetchLatestCases();
  }

  componentDidMount() {
    this.makeListReady();
    this.fetchLatestCases();
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
  };
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App } = state;
  return { token, App };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispatchToProps)(LatestTransactions))
);
