import {
    ACTION_FETCH_NOTIFICATION_LIST_SAGA,
    ACTION_UPDATE_NOTIFICATION_LIST,
    ACTION_NOTIFICATION_MARK_AS_READ_SAGA,
    ACTION_NOTIFICATION_MARK_AS_READ
} from './actionTypes';

export const fetchNotificationList = () => ({
    type: ACTION_FETCH_NOTIFICATION_LIST_SAGA
});
export const updateNotificationListAction = (list) => ({
    type: ACTION_UPDATE_NOTIFICATION_LIST,
    payload: list
});

export const markNotificationAsRead = (id) => ({
    type: ACTION_NOTIFICATION_MARK_AS_READ_SAGA,
    payload: id
});
export const markNotificationAsReadAction = (id) => ({
    type: ACTION_NOTIFICATION_MARK_AS_READ,
    payload: id
});