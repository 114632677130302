import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import { withNamespaces } from 'react-i18next';
import { toggleRightSidebar } from '../../store/actions';
import defaultLogo from '../../assets/images/logo.svg';
import axios from 'axios';
import { AUTH_API_DOMAIN_URL } from '../../common/constants';
import UserUtils from '../../services/utils/UserUtils';
import UrlUtils from '../../services/utils/UrlUtils';
import ModuleContext from '../../contexts/ModuleContext';
import FAQDropdown from '../CommonForBoth/TopbarDropdown/FAQDropdown';

class Header extends Component {
	static contextType = ModuleContext;

	constructor(props) {
		super(props);
		this.urlUtils = new UrlUtils();
		this.userUtils = new UserUtils();

		this.state = {
			isSearch: false,
			isSocialPf: false,

			disscoSupportEmail: 'support@diss-co.tech',
			module: localStorage.getItem('module'),
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleRightbar = this.toggleRightbar.bind(this);
		this.toggleFullscreen = this.toggleFullscreen.bind(this);

		this.handleSelectModule = this.handleSelectModule.bind(this);
		this.handleRenderModuleBox = this.handleRenderModuleBox.bind(this);
		this.handleFetchModule = this.handleFetchModule.bind(this);
	}

	handleSelectModule = (module) => {
		this.setState({ module: module });
		localStorage.setItem('module', module);
		this.context.setSelectedModule(module);
		this.handleFetchModule(module, this.props.token);
	};

	handleRenderModuleBox = (moduleKey, moduleStatus) => {
		let route = '';
		if (window.location.pathname.split('/').includes('third-party')) {
			route = '/third-party/home';
		} else {
			route =
				this.userUtils.getUserHomeRoute(this.props.user) === '/admin'
					? `${this.userUtils.getUserHomeRoute(this.props.user)}/home`
					: this.userUtils.getUserHomeRoute(this.props.user);
		}

		switch (moduleKey) {
			case 'wb': {
				if (moduleStatus) {
					return (
						<Link to={route} className='text-reset notification-item' onClick={() => this.handleSelectModule('wb')} key={moduleKey}>
							<div className='d-flex'>
								<div className='avatar-xs me-3'>
									<div style={{ backgroundColor: 'var(--ds-background-brand-bold, #0052CC)' }} className='css-4uzw5i'>
										<span style={{ fontWeight: 'bold', color: '#ffffff' }}>WB</span>
									</div>
								</div>
								<div className='flex-1'>
									<h6 className='mt-2 mb-0'>{this.props.t('Whistleblowing')}</h6>
								</div>
							</div>
						</Link>
					);
				} else {
					return (
						<div className='text-reset notification-item' key={moduleKey}>
							<div className='d-flex'>
								<div className='avatar-xs me-3'>
									<div style={{ backgroundColor: 'var(--ds-background-brand-bold, #0052CC)' }} className='css-4uzw5i'>
										<span style={{ fontWeight: 'bold', color: '#ffffff' }}>WB</span>
									</div>
								</div>
								<div className='flex-1'>
									<h6 className='mt-2 mb-0'>{this.props.t('Whistleblowing')}</h6>
								</div>
							</div>
						</div>
					);
				}
			}
			case 'cm': {
				if (moduleStatus) {
					return (
						<Link to={route} className='text-reset notification-item' onClick={() => this.handleSelectModule('cm')} key={moduleKey}>
							<div className='d-flex'>
								<div className='avatar-xs me-3'>
									<div style={{ backgroundColor: 'var(--ds-background-brand-bold, #0052CC)' }} className='css-4uzw5i'>
										<span style={{ fontWeight: 'bold', color: '#ffffff' }}>CM</span>
									</div>
								</div>
								<div className='flex-1'>
									<h6 className='mt-2 mb-0'>{this.props.t('Complaint Management')}</h6>
								</div>
							</div>
						</Link>
					);
				} else {
					return (
						<div className='text-reset notification-item' key={moduleKey}>
							<div className='d-flex'>
								<div className='avatar-xs me-3'>
									<div style={{ backgroundColor: 'var(--ds-background-brand-bold, #0052CC)' }} className='css-4uzw5i'>
										<span style={{ fontWeight: 'bold', color: '#ffffff' }}>CM</span>
									</div>
								</div>
								<div className='flex-1'>
									<h6 className='mt-2 mb-0'>{this.props.t('Complaint Management')}</h6>
								</div>
							</div>
						</div>
					);
				}
			}
			default:
				return null;
		}
	};

	handleFetchModule = async (module, token) => {
		console.log(module, token);
		try {
			await axios.post(
				`${AUTH_API_DOMAIN_URL}module.save`,
				{
					module: module,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			window.location.reload();
		} catch (error) {
			console.log('error while setting module:', error);
		}
	};

	/**
	 * Toggle sidebar
	 */
	toggleMenu() {
		this.props.toggleMenuCallback();
	}

	/**
	 * Toggles the sidebar
	 */
	toggleRightbar() {
		this.props.toggleRightSidebar();
	}

	toggleFullscreen() {
		if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	render() {
		const organization = this.props.organization;
		const logo = organization.secondaryLogo ? organization.secondaryLogo : defaultLogo;
		return (
			<Fragment>
				<header id='page-topbar'>
					<div className='navbar-header'>
						<div className='d-flex'>
							<div className='navbar-brand-box'>
								<div to='#' className='logo logo-dark'>
									<span className='logo-sm'>
										<img src={logo} alt='' height='22' />
									</span>
									<span className='logo-lg'>
										<img src={logo} alt='' height='20' />
									</span>
								</div>

								<div to='#' className='logo logo-light'>
									<span className='logo-sm'>
										<img src={logo} alt='' height='22' />
									</span>
									<span className='logo-lg'>
										<img src={logo} alt='' height='20' />
									</span>
								</div>
							</div>

							<Button
								size='sm'
								color='none'
								type='button'
								onClick={this.toggleMenu}
								className='px-3 font-size-24 header-item waves-effect'
								id='vertical-menu-btn'
							>
								<i className='ri-menu-2-line align-middle'></i>
							</Button>

							{this.state.module ? (
								<div style={{ display: 'inline-flex', alignItems: 'center', cursor: 'default' }}>
									<div className='px-1 font-size-20 text-primary waves-effect' style={{ cursor: 'default' }}>
										{this.state.module === 'wb' ? 'GDPR' : null}
									</div>
								</div>
							) : null}
						</div>

						<div className='d-flex'>
							<LanguageDropdown />
							<NotificationDropdown />
							<div className='dropdown d-none d-lg-inline-block ms-1'>
								<Button color='none' type='button' className='header-item noti-icon waves-effect' onClick={this.toggleFullscreen}>
									<i className='ri-fullscreen-line'></i>
								</Button>
							</div>
              <FAQDropdown />
							{/* {this.props.organization.modules && this.props.organization.modules.filter((m) => m.hasModule).length > 1 ? (
								<Dropdown
									isOpen={this.state.isSocialPf}
									toggle={() => this.setState({ isSocialPf: !this.state.isSocialPf })}
									className='d-none d-lg-inline-block ms-1'
								>
									<DropdownToggle tag='button' className='btn header-item noti-icon waves-effect'>
										<i className='ri-apps-2-line'></i>
									</DropdownToggle>
									<DropdownMenu className='dropdown-menu-lg dropdown-menu-end'>
										<div className='px-lg-2'>
											<div className='p-3'>
												<Row className='align-items-center'>
													<Col>
														<div className='switch-to-modules'>
															<div className='switch-to-modules-item-left'>
																<b>Switch to</b>
															</div>
															<div className='switch-to-modules-item-right'>
																<Link to='admin/start'>
																	<b>
																		SIP Start <i className=' ri-external-link-line'></i>
																	</b>
																</Link>
															</div>
														</div>
													</Col>
												</Row>
											</div>
											{this.props.organization.modules &&
												this.props.organization.modules.map((i) => {
													return this.handleRenderModuleBox(i.name, i.hasModule);
												})}
										</div>
									</DropdownMenu>
								</Dropdown>
							) : null} */}

							<ProfileMenu />
						</div>
					</div>
				</header>
			</Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	return {
		token: state.Login.token,
		layoutType: state.Layout.layoutType,
		organization: state.Organization,
		user: state.Login.user,
	};
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(Header));
