import axios from "axios";
import React from "react";
import ReactPlayer from "react-player/file";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { errorNotification, successNotification } from "../../store/actions";
import {
  API_URL_GET_E_LEARNING_DETAILS,
  API_URL_GET_E_LEARNING_AGENDA,
  API_URL_GET_E_LEARNING_COUNTER,
} from "../../common/constants";
import "../ReactPlayer.css";
import { withRouter } from "react-router-dom";

const DESIRED_TIME = 5 * 60; // 5min

class ElearningDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("E-Learning"), link: "#" },
      ],

      chapters: [],
      chaptersError: null,

      lesson_id: localStorage.getItem("lessonId") || null,
      lesson_name: null,
      lesson_error: null,
      lesson_created_at: null,
      lesson_language_id: null,
      lesson_description: null,
      lesson_video_address: null,

      seek: 2,
      onPlay: true,
      seekChanged: false,

      elapsedTime: 0,
      counterApiCalled: false, // Track if API has been called

      viewCount: 0,
    };

    this.myRef = React.createRef();
  }

  convertToSeconds(str) {
    // const [seconds, minutes, hours] = str.split(':').reverse();
    const [minutes, seconds] = str.split(":");

    // console.log(Number(minutes) * 60 + Number(seconds))

    return Number(minutes) * 60 + Number(seconds);
  }

  handleProgress = (progress) => {
    if (progress.playedSeconds > 1) {
      this.setState(
        (prevState) => ({
          viewCount: prevState.viewCount + 1,
        }),
        () => {
          console.log("view: ", this.state.viewCount);
        }
      );
    }
  };

  render() {
    const check = this.myRef;

    const { lesson_name } =
      this.props.location || localStorage.getItem("lessonId");

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("E-Learning")}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div style={{ marginBottom: 20 }}>
                      <CardHeader tag={"h1"}>
                        {lesson_name ? this.props.t(lesson_name) : "..."}
                      </CardHeader>
                    </div>
                    {this.state.lesson_error ? (
                      <Alert color="warning">
                        {this.props.t(this.state.lesson_error)}
                      </Alert>
                    ) : (
                      <div>
                        <div style={{ marginBottom: 20 }}>
                          <ReactPlayer
                            controls
                            // playing={this.state.onPlay}
                            ref={check}
                            className="react-player"
                            width="100%"
                            height="50%"
                            playsinline
                            onProgress={this.handleProgress}
                            url={this.state.lesson_video_address}
                          />
                        </div>
                        <div>
                          {this.state.lesson_description ? (
                            <CardText>
                              <span>{this.props.t("Description")}: </span>
                              {this.state.lesson_description}
                            </CardText>
                          ) : (
                            ""
                          )}
                          {this.state.lesson_language_id ? (
                            <CardText>
                              <span>{this.props.t("Language")}: </span>
                              {this.state.lesson_language_id}
                            </CardText>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div style={{ marginBottom: 20 }}>
                      <CardHeader tag={"h3"}>
                        {this.props.t("Chapters")}
                      </CardHeader>
                    </div>
                    <ListGroup>
                      {this.state.chapters.length ? (
                        this.state.chapters.map((item, index) => (
                          <ListGroupItem
                            action
                            tag="button"
                            key={index}
                            onClick={() => {
                              check.current.seekTo(
                                this.convertToSeconds(item.time),
                                "seconds"
                              );
                            }}
                          >
                            {item.agenda_name}
                          </ListGroupItem>
                        ))
                      ) : this.state.chapters ? (
                        <Alert color="warning">
                          {this.props.t(this.state.chaptersError)}
                        </Alert>
                      ) : (
                        this.props.t("Loading")
                      )}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  fetchDetails() {
    const url = API_URL_GET_E_LEARNING_DETAILS;
    const data = {
      lesson_id:
        Number(this.props.location.pathname.split("/")[3]) ||
        Number(localStorage.getItem("lessonId")),
      // lesson_id: 1,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };

    new Promise(async (res, rej) => {
      await axios
        .post(url, data, header)
        .then((res) => {
          if (res.data.status) {
            const data = res.data.message;

            if (typeof data === "object" && data !== null) {
              this.setState({
                lesson_id: data.id,
                lesson_name: data.name,
                lesson_created_at: data.created_at,
                lesson_language_id: data.language_id,
                lesson_description: data.lesson_description,
                lesson_video_address: data.video_address,
              });
            }
          }
        })
        .catch((err) => {
          console.log("Error in the system, details");
          console.log(err);

          this.setState({
            lesson_error: "No Lesson Available",
          });

          this.props.errorNotification({
            code: "fatal_error",
            message: this.props.t("No Lesson Available"),
          });
        });
    });
  }

  fetchAgenda() {
    const url = API_URL_GET_E_LEARNING_AGENDA;
    const data = {
      lesson_id:
        Number(this.props.location.pathname.split("/")[3]) ||
        Number(localStorage.getItem("lessonId")),
      // lesson_id: 1,
      endpoint_id: 2,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };

    new Promise(async (res, rej) => {
      await axios
        .post(url, data, header)
        .then((res) => {
          const data = res.data.data;
          console.log(data);
          this.setState({
            chapters: data,
          });
        })
        .catch((err) => {
          console.log("Error in the system, fetchAgenda");
          console.log(err);

          this.setState({
            chaptersError: "No Agenda Available",
          });

          this.props.errorNotification({
            code: "fatal_error",
            message: this.props.t("No Agenda Available"),
          });
        });
    });
  }

  fetchCounterApi = () => {
    axios
      .get(API_URL_GET_E_LEARNING_COUNTER, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        // Handle the API response
        console.log("counter__response: ", response);
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        console.log("counter__error: ", error);
      });

    this.setState({ counterApiCalled: true }); // Update state to mark API call as made
  };

  resizePlayer = () => {
    const width = window.innerWidth;
    if (width <= 768) {
      document.querySelector(".react-player").style.width = "100%";
    } else {
      document.querySelector(".react-player").style.width = "60%";
    }
  };

  componentDidMount() {
    this.fetchDetails();
    this.fetchAgenda();
    window.addEventListener("resize", this.resizePlayer);
    this.resizePlayer();
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewCount, counterApiCalled } = this.state;

    if (
      viewCount >= DESIRED_TIME &&
      viewCount !== prevState.viewCount &&
      !counterApiCalled
    ) {
      this.fetchCounterApi();
    }
  }
}

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App } = state;
  return { token, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, { errorNotification, successNotification })(
      ElearningDetail
    )
  )
);
