import { memo, useState, useEffect, useCallback } from "react";
import { TabPane, Container, Row, Col, Button } from "reactstrap";
import {
  INTERNATIONAL_DATE_FORMAT,
  FETCH_MATRIX_FAILED,
  FETCH_RISKS_FAILED,
  DELETE_RISK_SUCCESSFUL,
  DELETE_RISK_FAILED,
  FETCH_RISKS_CATEGORIES_FAILED,
  FETCH_LOCATIONS_FAILED,
  FETCH_OWNERS_FAILED,
  FETCH_MANAGERS_FAILED,
  EMPTY_LIST,
  GDPR_DOMAIN_URL,
  GDPR_API_URL_FETCH_MANAGERS,
  GDPR_API_URL_FETCH_RISK_LOCATIONS,
  GDPR_API_URL_FETCH_RISK_CATEGORIES,
  GDPR_API_URL_FETCH_RISK_MATRIX,
} from "../../../../../../common/constants";
import Select from "react-select";
import axios from "axios";
import DateUtils from "../../../../../../services/utils/DateUtils";
import MemorizedCreateRiskModal from "./CreateRiskModal";
import MemorizedDeleteRiskModal from "./DeleteRiskModal";
import MemorizedRiskMatrix from "./RiskMatrix";
import MemorizedRiskDetails from "./RiskDetails";
import "./RiskAssessment.scss";
import Microchip from "../../../../../../assets/images/microchip.png";
import { getFeatureStatusByTitle } from "../../../../../../helpers/feature_helper";
import { useLocation } from 'react-router-dom';

const RiskAssessment = function (props) {
  RiskAssessment.displayName = "Memorized Component - Risk Assessment";
  if (process.env.NODE_ENV === 'development')
    console.log("---- rendering memorized component >>> RiskAssessment");

  const {
    isActive,
    authToken,
    reportId,
    errorNotification,
    successNotification,
    t,
    handleSetReportRiskField,
    isDeleted,
    deletedByName,
    module,
    reportLocation,
    reportCategories,
  } = props;

  const location = useLocation();

  const [matrix, setMatrix] = useState(null);
  const [consequences, setConsequences] = useState(null);
  const [ratings, setRatings] = useState(null);
  const [likelihoods, setLikelihoods] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [risks, setRisks] = useState(null);
  const [tableColors, setTableColors] = useState(null);
  const [createRiskModalStatus, setCreateRiskModalStatus] = useState(false);
  const [deleteRiskModalStatus, setDeleteRiskModalStatus] = useState(false);
  const [
    aiBasedRiskAssessmentModalStatus,
    setAiBasedRiskAssessmentModalStatus,
  ] = useState(false);
  const [deleteRiskId, setDeleteRiskId] = useState(false);

  const [categories, setCategories] = useState(null);
  const [locations, setLocations] = useState(null);
  const [damages, setDamages] = useState(null);
  const [owners, setOwners] = useState(null);
  const [managers, setManagers] = useState(null);
  const [selectedRiskId, setSelectedRiskId] = useState(null);

  const [loadingRisks, setLoadingRisks] = useState(false);
  const [loadingMatrix, setLoadingMatrix] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingOwners, setLoadingOwners] = useState(false);
  const [loadingManagers, setLoadingManagers] = useState(false);

  const [selectedRatingFilter, setSelectedRatingFilter] = useState(null);
  const [selectedManagerFilter, setSelectedManagerFilter] = useState(null);
  const [selectedOwnerFilter, setSelectedOwnerFilter] = useState(null);

  const [route, setRoute] = useState("");

  const dateUtils = new DateUtils();

  const handleFilterRisks = async (rating, manager, owner) => {
    let shouldFetch = false;

    // rating
    if (!rating) {
      shouldFetch = true;
      setSelectedRatingFilter(null);
    } else if (
      !selectedRatingFilter ||
      (selectedRatingFilter && !rating) ||
      selectedRatingFilter.value !== rating.value
    ) {
      shouldFetch = true;
      setSelectedRatingFilter({
        label: t(rating.label),
        value: rating.value,
        baseLabel: rating.baseLabel,
      });
    }

    // manager
    if (!manager) {
      shouldFetch = true;
      setSelectedManagerFilter(null);
    } else if (
      !selectedManagerFilter ||
      (selectedManagerFilter && !manager) ||
      selectedManagerFilter.value !== manager.value
    ) {
      shouldFetch = true;
      setSelectedManagerFilter(manager);
    }

    // owner
    if (!owner) {
      shouldFetch = true;
      setSelectedOwnerFilter(null);
    } else if (
      !selectedOwnerFilter ||
      (selectedOwnerFilter && !owner) ||
      selectedOwnerFilter.value !== owner.value
    ) {
      shouldFetch = true;
      setSelectedOwnerFilter(owner);
    }

    if (shouldFetch) {
      handleFetchRisks(
        rating?.value,
        manager?.value,
        owner?.value,
        reportId,
        authToken
      );
    }
  };

  /**
   * this method fetches the matrix informations of the organization.
   * @param {String} token authorization token.
   */
  const handleFetchMatrix = useCallback(
    async (token) => {
      setLoadingMatrix(true);
      try {
        const result = await axios.get(GDPR_API_URL_FETCH_RISK_MATRIX, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          setConsequences(result.data.data.consequences);
          setMatrix(result.data.data.matrix);
          setRatings(result.data.data.ratings);
          setLikelihoods(result.data.data.likelihoods);
          setCurrency(result.data.data.currency);
          setDamages(
            result.data.data.consequences.map((item) => {
              return {
                value: item.id,
                label:
                  result.data.data.currency +
                  " " +
                  Number(item.title).toLocaleString(
                    localStorage.getItem("i18nextLng")
                  ),
              };
            })
          );

          // calculating matrix colors for caption
          if (result.data.data.matrix && result.data.data.ratings) {
            const flattedMatrix = result.data.data.matrix.flat();
            const items = [];
            for (const item of result.data.data.ratings) {
              items.push({
                label: item.name,
                color: flattedMatrix.find((i) => i.name === item.name).color,
              });
            }

            setTableColors(items);
          }
        } else {
          errorNotification({
            message: t(FETCH_MATRIX_FAILED),
          });
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            "this error only appears in the development environment:\nerror while fetching matrix",
            error
          );
        }

        errorNotification({
          message: t(FETCH_MATRIX_FAILED),
        });
      }
      setLoadingMatrix(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotification, t]
  );

  /**
   * this method fetches risks of a case.
   * @param {String} caseId the ID of the case you want to fetch risks for.
   * @param {String|Number} rating the name of the selected rating for filter.
   * @param {String|Number} manager the ID of the case you want to fetch risks for.
   * @param {String|Number} owner the ID of the case you want to fetch risks for.
   * @param {String} token authorization token.
   */
  const handleFetchRisks = useCallback(
    async (rating, manager, owner, caseId, token) => {
      setLoadingRisks(true);
      setRisks(null);

      const requestParams = {
        pageSize: 30,
      };
      if (rating) requestParams["rating"] = rating;
      if (manager) requestParams["manager"] = manager;
      if (owner) requestParams["owner"] = owner;

      try {
        const result = await axios.get(
          `${GDPR_DOMAIN_URL}report/${caseId}/risks`,
          {
            params: requestParams,
            // signal: abortController.signal,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status === 200) {
          setRisks(result.data.data.risks);
        } else {
          errorNotification({
            message: t(FETCH_RISKS_FAILED),
          });
        }
      } catch (error) {
        if (
          (process.env.NODE_ENV && !axios.isAxiosError(error)) ||
          (axios.isAxiosError(error) && error.name !== "CanceledError")
        ) {
          console.error(
            "this error only appears in the development environment:\nerror while fetching risks",
            error
          );
        }

        if (
          !axios.isAxiosError(error) ||
          (axios.isAxiosError(error) && error.name !== "CanceledError")
        ) {
          errorNotification({
            message: t(FETCH_RISKS_FAILED),
          });
        }
      }
      setLoadingRisks(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotification, t]
  );

  /**
   * this method deletes a risk.
   * @param {String} riskId the ID of the risk you want to delete.
   * @param {String} caseId the ID of the case you want to delete risk for.
   * @param {String} token authorization token
   */
  const handleDeleteRisk = async (riskId, caseId, token) => {
    try {
      const result = await axios.delete(
        `${GDPR_DOMAIN_URL}risk_rate/${riskId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        successNotification({
          message: t(DELETE_RISK_SUCCESSFUL),
        });

        handleSetReportRiskField(result.data.data.reportRiskRate);

        handleFetchRisks(
          selectedRatingFilter?.value,
          selectedManagerFilter?.value,
          selectedOwnerFilter?.value,
          caseId,
          token
        );
      } else {
        errorNotification({
          message: t(DELETE_RISK_FAILED),
        });
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          "this error only appears in the development environment:\nerror while deleting a risk",
          error
        );
      }

      errorNotification({
        message: t(DELETE_RISK_FAILED),
      });
    }

    setDeleteRiskModalStatus(false);
    setDeleteRiskId(null);
  };

  /**
   * this method fetches risks categories.
   * @param {String} token authorization token.
   */
  const handleFetchCategories = useCallback(
    async (token) => {
      setLoadingCategories(true);
      try {
        const result = await axios.get(GDPR_API_URL_FETCH_RISK_CATEGORIES, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          const categories = (result.data?.data || []).map((item) => {
            return {
              value: item.id,
              label: t(item.name),
              baseLabel: item.name,
              subCategories: item.sub_categories.map((sub) => {
                return {
                  value: sub.id,
                  baseLabel: sub.name,
                  label: t(sub.name),
                };
              }),
            };
          });

          setCategories(categories);
        } else {
          errorNotification({
            message: t(FETCH_RISKS_CATEGORIES_FAILED),
          });
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            "this error only appears in the development environment:\nerror while fetching categories",
            error
          );
        }

        errorNotification({
          message: t(FETCH_RISKS_CATEGORIES_FAILED),
        });
      }
      setLoadingCategories(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotification, t]
  );

  /**
   * this method fetches locations.
   * @param {String} token authorization token.
   */
  const handleFetchLocations = useCallback(
    async (token) => {
      setLoadingLocations(true);
      try {
        const result = await axios.post(GDPR_API_URL_FETCH_RISK_LOCATIONS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          const locations = result.data.data.map((item) => {
            return {
              value: item.id,
              baseLabel: item.name,
              label: t(item.name),
            };
          });

          setLocations(locations);
        } else {
          errorNotification({
            message: t(FETCH_LOCATIONS_FAILED),
          });
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            "this error only appears in the development environment:\nerror while fetching locations",
            error
          );
        }

        errorNotification({
          message: t(FETCH_LOCATIONS_FAILED),
        });
      }
      setLoadingLocations(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotification, t]
  );

  /**
   * this method fetches owners.
   * @param {String} token authorization token.
   */
  const handleFetchOwners = useCallback(
    async (token) => {
      setLoadingOwners(true);
      try {
        const result = await axios.post(
          `${GDPR_DOMAIN_URL}analystAndAdminAnalyst.list`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status === 200) {
          const owners = result.data.data.map((item) => {
            return {
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
            };
          });

          setOwners(owners);
        } else {
          errorNotification({
            message: t(FETCH_OWNERS_FAILED),
          });
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            "this error only appears in the development environment:\nerror while fetching owners",
            error
          );
        }

        errorNotification({
          message: t(FETCH_OWNERS_FAILED),
        });
      }
      setLoadingOwners(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotification, t]
  );

  /**
   * this method fetches managers.
   * @param {String} token authorization token.
   */
  const handleFetchManagers = useCallback(
    async (token) => {
      setLoadingManagers(true);
      try {
        const result = await axios.get(GDPR_API_URL_FETCH_MANAGERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          const managers = result.data.data.map((item) => {
            return {
              value: item.id,
              label: item.full_name,
            };
          });

          setManagers(managers);
        } else {
          errorNotification({
            message: t(FETCH_MANAGERS_FAILED),
          });
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            "this error only appears in the development environment:\nerror while fetching managers",
            error
          );
        }

        errorNotification({
          message: t(FETCH_MANAGERS_FAILED),
        });
      }
      setLoadingManagers(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorNotification, t]
  );

  const decodeHTMLEntities = (text) => {
    const parser = new DOMParser();
    const decodedText = parser.parseFromString(
      `<!doctype html><body>${text}`,
      "text/html"
    ).body.textContent;
    return decodedText;
  };

  useEffect(() => {
    if (isActive) {
      if (!risks && !loadingRisks)
        handleFetchRisks(null, null, null, reportId, authToken);
      if (!matrix && !loadingMatrix) handleFetchMatrix(authToken);
      if (!categories && !loadingCategories) handleFetchCategories(authToken);
      if (!locations && !loadingLocations) handleFetchLocations(authToken);
      if (!owners && !loadingOwners) handleFetchOwners(authToken);
      if (!managers && !loadingManagers) handleFetchManagers(authToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isActive,
    authToken,
    reportId,
    risks,
    matrix,
    categories,
    locations,
    owners,
    managers,
    loadingRisks,
    loadingMatrix,
    loadingCategories,
    loadingLocations,
    loadingOwners,
    loadingManagers,
    handleFetchRisks,
    handleFetchMatrix,
    handleFetchCategories,
    handleFetchLocations,
    handleFetchOwners,
    handleFetchManagers,
  ]);

  useEffect(() => {
    if (selectedRatingFilter) {
      setSelectedRatingFilter({
        value: selectedRatingFilter.value,
        label: t(selectedRatingFilter.value),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    if (window.location.pathname.includes("risk")) {
      if (isActive) {
        if (!matrix && !loadingMatrix) handleFetchMatrix(authToken);
        if (!categories && !loadingCategories) handleFetchCategories(authToken);
        if (!locations && !loadingLocations) handleFetchLocations(authToken);
        if (!owners && !loadingOwners) handleFetchOwners(authToken);
        if (!managers && !loadingManagers) handleFetchManagers(authToken);
      }

      const str = window.location.pathname.split("/");
      const riskId = Number(str.slice(str.length - 1, str.length)[0]);
      setSelectedRiskId(riskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const riskId = new URLSearchParams(location.search).get("riskId");
    
    if (riskId && categories && locations && damages && owners && currency && tableColors) {
      setSelectedRiskId(riskId);
    }
  }, [categories,locations,damages,owners,currency,tableColors,  location.search]);


  useEffect(() => {
    if (createRiskModalStatus) {
      setLocations(
        locations.map((item) => {
          return {
            value: item.value,
            baseLabel: item.baseLabel,
            label: t(item.baseLabel),
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRiskModalStatus]);

  const onGetHelpFromAISubmit = () => {
    if (
      (props.reportType === 4 &&
        props.adminDescription &&
        props.adminCategory &&
        Array.isArray(props.adminCategory) &&
        props.adminCategory.length > 0) ||
      (props.reportType !== 4 && props.adminDescription)
    ) {
      setAiBasedRiskAssessmentModalStatus(true);
      setCreateRiskModalStatus(true);
      setRoute("riskAi");
    } else {
      errorNotification({
        message: t("The description by the admin has not been filled."),
      });
    }
  };

  const back = () => {
    setAiBasedRiskAssessmentModalStatus(false);
    setCreateRiskModalStatus(false);
    setRoute("");
    handleFetchRisks(null, null, null, props.reportId, props.authToken);
  };

  useEffect(() => {
    if (isActive) document.title = "Report Details - Risk Assessment | SIP";
  }, [isActive]);

  useEffect(() => {
    if (props?.riskDetailPage()) {
      props?.riskDetailPage(selectedRiskId)
    }
  }, [selectedRiskId]);



  return (
    <TabPane tabId="risk_assessment">
      {!selectedRiskId ? (
        route === "riskAi" ? (
          <MemorizedCreateRiskModal
            t={props.t}
            isOpen={createRiskModalStatus}
            close={() => setCreateRiskModalStatus(false)}
            successNotification={props.successNotification}
            errorNotification={props.errorNotification}
            authToken={props.authToken}
            reportId={props.reportId}
            updateRisksList={() => {
              setSelectedRatingFilter(null);
              setSelectedOwnerFilter(null);
              setSelectedManagerFilter(null);
              handleFetchRisks(
                null,
                null,
                null,
                props.reportId,
                props.authToken
              );
            }}
            currency={currency}
            categories={categories}
            locations={locations}
            damages={damages}
            likelihoods={
              likelihoods
                ? likelihoods.map((l) => {
                    return {
                      value: l.id,
                      label: l.title,
                    };
                  })
                : null
            }
            owners={owners}
            handleSetReportRiskField={handleSetReportRiskField}
            module={module}
            aiBasedRiskAssessmentModalStatus={aiBasedRiskAssessmentModalStatus}
            setAiBasedRiskAssessmentModalStatus={
              setAiBasedRiskAssessmentModalStatus
            }
            reportLocation={reportLocation}
            reportCategories={reportCategories}
            route={route}
            back={back}
          />
        ) : (
          <>
            {/* risk matrix */}
            <MemorizedRiskMatrix
              t={props.t}
              matrix={matrix}
              consequences={consequences}
              currency={currency}
              likelihoods={likelihoods}
              risks={risks}
              ratings={ratings}
              tableColors={tableColors}
              selectRisk={(id) => {
                setSelectedRiskId(id);
              }}
              filterRisks={(e) => {
                handleFilterRisks(
                  e,
                  selectedManagerFilter,
                  selectedOwnerFilter
                );
              }}
            />

            <br />
            <br />
            <br />

            {/* risks list */}
            <Container>
              <Row>
                <Col>
                  {/* create risk modal */}
                  <MemorizedCreateRiskModal
                    t={props.t}
                    isOpen={createRiskModalStatus}
                    close={() => setCreateRiskModalStatus(false)}
                    successNotification={props.successNotification}
                    errorNotification={props.errorNotification}
                    authToken={props.authToken}
                    reportId={props.reportId}
                    updateRisksList={() => {
                      setSelectedRatingFilter(null);
                      setSelectedOwnerFilter(null);
                      setSelectedManagerFilter(null);
                      handleFetchRisks(
                        null,
                        null,
                        null,
                        props.reportId,
                        props.authToken
                      );
                    }}
                    currency={currency}
                    categories={categories}
                    locations={locations}
                    damages={damages}
                    likelihoods={
                      likelihoods
                        ? likelihoods.map((l) => {
                            return {
                              value: l.id,
                              label: l.title,
                            };
                          })
                        : null
                    }
                    owners={owners}
                    handleSetReportRiskField={handleSetReportRiskField}
                    module={module}
                    aiBasedRiskAssessmentModalStatus={
                      aiBasedRiskAssessmentModalStatus
                    }
                    setAiBasedRiskAssessmentModalStatus={
                      setAiBasedRiskAssessmentModalStatus
                    }
                    reportLocation={reportLocation}
                    reportCategories={reportCategories}
                    route={route}
                  />

                  {/* delete risk modal */}
                  <MemorizedDeleteRiskModal
                    t={props.t}
                    isOpen={deleteRiskModalStatus}
                    riskId={deleteRiskId}
                    close={() => {
                      setDeleteRiskId(null);
                      setDeleteRiskModalStatus(false);
                    }}
                    acceptDeletion={(id) => {
                      handleDeleteRisk(id, props.reportId, props.authToken);
                    }}
                  />

                  <div style={{ width: "100%" }}>
                    {/* filters */}
                    <Row className="d-flex">
                      {/* ratings */}
                      <Col sm="12" md="3" lg="2" className="mb-2 ">
                        {!loadingMatrix &&
                        !loadingOwners &&
                        !loadingManagers &&
                        !loadingRisks &&
                        matrix ? (
                          <Select
                            classNamePrefix="select2-selection"
                            options={ratings.map((r) => {
                              return {
                                value: r.name,
                                label: t(r.name),
                              };
                            })}
                            value={selectedRatingFilter}
                            isClearable={true}
                            onChange={(e) => {
                              handleFilterRisks(
                                e,
                                selectedManagerFilter,
                                selectedOwnerFilter
                              );
                            }}
                            placeholder={t("Risk rating")}
                          />
                        ) : (
                          <div
                            className="dt-field dt-skeleton dt-select-list"
                            style={{ marginBottom: 16 }}
                          ></div>
                        )}
                      </Col>

                      {/* owners */}
                      <Col sm="12" md="3" lg="3" className="mb-2 ">
                        {!loadingMatrix &&
                        !loadingOwners &&
                        !loadingManagers &&
                        !loadingRisks &&
                        owners ? (
                          <Select
                            classNamePrefix="select2-selection"
                            options={owners}
                            value={selectedOwnerFilter}
                            isClearable={true}
                            onChange={(e) => {
                              handleFilterRisks(
                                selectedRatingFilter,
                                selectedManagerFilter,
                                e
                              );
                            }}
                            placeholder={t("Risk owner")}
                          />
                        ) : (
                          <div
                            className="dt-field dt-skeleton dt-select-list"
                            style={{ marginBottom: 16 }}
                          ></div>
                        )}
                      </Col>

                      {/* managers */}
                      <Col sm="12" md="3" lg="3" className="mb-2">
                        {!loadingMatrix &&
                        !loadingOwners &&
                        !loadingManagers &&
                        !loadingRisks &&
                        managers ? (
                          <Select
                            classNamePrefix="select2-selection"
                            options={managers}
                            value={selectedManagerFilter}
                            isClearable={true}
                            onChange={(e) => {
                              handleFilterRisks(
                                selectedRatingFilter,
                                e,
                                selectedOwnerFilter
                              );
                            }}
                            placeholder={t("Risk Manager")}
                          />
                        ) : (
                          <div
                            className="dt-field dt-skeleton dt-select-list"
                            style={{ marginBottom: 16 }}
                          ></div>
                        )}
                      </Col>

                      {/* create risk button */}
                      <Col
                        sm="12"
                        md="6"
                        lg={
                          getFeatureStatusByTitle(
                            props.Feature,
                            "ai_based_risk"
                          )
                            ? "2"
                            : "4"
                        }
                      >
                        <Button
                          color="primary"
                          className="mb-2"
                          style={{
                            marginLeft: "auto",
                            marginRight: 0,
                            display: "block",
                            width: "100%",
                          }}
                          onClick={() => {
                            setAiBasedRiskAssessmentModalStatus(false);
                            setCreateRiskModalStatus(true);
                          }}
                          outline
                          disabled={isDeleted || deletedByName || !locations}
                        >
                          {t("Create Risk")}
                        </Button>
                      </Col>

                      {/* ai based risk assessment */}
                      {getFeatureStatusByTitle(
                        props.Feature,
                        "ai_based_risk"
                      ) && (
                        <Col sm="12" md="3" lg="2">
                          <Button
                            color="primary"
                            className="mb-2 ai-base-risk-assessment-button"
                            style={{
                              marginLeft: "auto",
                              marginRight: 0,
                              display: "block",
                              width: "100%",
                            }}
                            onClick={onGetHelpFromAISubmit}
                            outline
                            disabled={isDeleted || deletedByName || !locations}
                          >
                            <img alt="ai icon" src={Microchip} />
                            {t("Get help from AI")}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>

                  <table className="dt-list dt-risks-list">
                    <thead>
                      <tr>
                        {/* rating */}
                        <td style={{ width: "6%" }}>{t("Rating")}</td>

                        {/* id */}
                        <td style={{ width: "6%" }}>{t("Risk ID")}</td>

                        {/* description */}
                        <td style={{ width: "18%" }}>{t("Description")}</td>

                        {/* owner */}
                        <td style={{ width: "25%" }}>{t("Risk owner")}</td>

                        {/* manager */}
                        <td style={{ width: "25%" }}>{t("Risk Manager")}</td>

                        {/* date */}
                        <td style={{ width: "10%" }}>{t("Creation Date")}</td>

                        {/* actions */}
                        <td style={{ width: "10%" }}></td>
                      </tr>
                    </thead>

                    <tbody>
                      {!loadingRisks ? (
                        risks && risks.length > 0 ? (
                          risks.map((r) => {
                            return (
                              <tr key={r.id}>
                                {/* rating */}
                                <td style={{ width: "6%" }}>
                                  <div>
                                    <span
                                      className="dt-risk-rating-bullet"
                                      style={{
                                        backgroundColor: `#${r.result_color}`,
                                        opacity: r.is_deleted ? 0.15 : 1,
                                        cursor: !r.is_deleted
                                          ? "pointer"
                                          : "default",
                                      }}
                                      onClick={() =>
                                        !r.is_deleted
                                          ? (setSelectedRiskId(r.id))
                                          : null
                                      }
                                    ></span>
                                  </div>
                                </td>

                                {/* id */}
                                <td style={{ width: "6%" }}>
                                  <div
                                    className="dt-list-col-result-id"
                                    style={{
                                      cursor: !r.is_deleted
                                        ? "pointer"
                                        : "default",
                                    }}
                                    onClick={() =>
                                      !r.is_deleted
                                        ? (setSelectedRiskId(r.id))
                                        : null
                                    }
                                  >
                                    {"R" + r.secondary_id}
                                  </div>
                                </td>

                                {/* description */}
                                <td style={{ width: "18%" }}>
                                  <div
                                    className="dt-list-col-result-description"
                                    style={{
                                      cursor: !r.is_deleted
                                        ? "pointer"
                                        : "default",
                                    }}
                                    onClick={() =>
                                      !r.is_deleted
                                        ? (setSelectedRiskId(r.id))
                                        : null
                                    }
                                  >
                                    {/* {r.description.replace(/<[^>]+>/g, '')} */}
                                    {decodeHTMLEntities(r.description).replace(
                                      /<[^>]+>/g,
                                      ""
                                    )}
                                  </div>
                                </td>

                                {/* owner */}
                                <td style={{ width: "25%" }}>
                                  {r.owner_name ? (
                                    <div>
                                      <span className="dt-list-col-bullet">
                                        {r.owner_name[0]}
                                      </span>{" "}
                                      <span className="dt-list-col-bullet-text">
                                        {r.owner_name}
                                      </span>
                                    </div>
                                  ) : null}
                                </td>

                                {/* manager */}
                                <td style={{ width: "25%" }}>
                                  <div>
                                    {r.manager ? (
                                      <>
                                        <span className="dt-list-col-bullet">
                                          {r.manager[0]}
                                        </span>{" "}
                                        <span className="dt-list-col-bullet-text">
                                          {r.manager}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                </td>

                                {/* date */}
                                <td style={{ width: "10%" }}>
                                  <div>
                                    {dateUtils.convertTimeStampToDate(
                                      r.created_at,
                                      INTERNATIONAL_DATE_FORMAT
                                    )}
                                  </div>
                                </td>

                                {/* actions */}
                                <td style={{ width: "10%" }}>
                                  {!r.is_deleted ? (
                                    <div
                                      style={{
                                        fontSize: 20,
                                        textAlign: "center",
                                        display: "block",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setDeleteRiskId(r.id);
                                        setDeleteRiskModalStatus(true);
                                      }}
                                    >
                                      <i className="ri-delete-bin-line text-danger"></i>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        fontWeight: 300,
                                        textAlign: "center",
                                        display: "block",
                                        color: "red",
                                      }}
                                    >
                                      {t("DELETED")}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <div className="alert alert-warning" role="alert">
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    width: "100%",
                                  }}
                                >
                                  {t(EMPTY_LIST)}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )
                      ) : (
                        <>
                          <tr>
                            {/* rating */}
                            <td style={{ width: "6%" }}>
                              <div>
                                <span className="dt-risk-rating-bullet dt-skeleton"></span>
                              </div>
                            </td>

                            {/* id */}
                            <td style={{ width: "6%" }}>
                              <div>
                                <p className="dt-list-col-result-id dt-skeleton"></p>
                              </div>
                            </td>

                            {/* description */}
                            <td style={{ width: "18%" }}>
                              <div>
                                <p className="dt-list-col-result-id dt-skeleton"></p>
                              </div>
                            </td>

                            {/* owner */}
                            <td style={{ width: "25%" }}>
                              <div>
                                <span className="dt-list-col-bullet dt-skeleton"></span>
                                <span className="dt-list-col-bullet-text dt-skeleton"></span>
                              </div>
                            </td>

                            {/* manager */}
                            <td style={{ width: "25%" }}>
                              <div>
                                <span className="dt-list-col-bullet dt-skeleton"></span>
                                <span className="dt-list-col-bullet-text dt-skeleton"></span>
                              </div>
                            </td>

                            {/* date */}
                            <td style={{ width: "10%" }}>
                              <div>
                                <p className="dt-list-col-date dt-skeleton"></p>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            {/* rating */}
                            <td style={{ width: "6%" }}>
                              <div>
                                <span className="dt-risk-rating-bullet dt-skeleton"></span>
                              </div>
                            </td>

                            {/* id */}
                            <td style={{ width: "6%" }}>
                              <div>
                                <p className="dt-list-col-result-id dt-skeleton"></p>
                              </div>
                            </td>

                            {/* description */}
                            <td style={{ width: "18%" }}>
                              <div>
                                <p className="dt-list-col-result-id dt-skeleton"></p>
                              </div>
                            </td>

                            {/* owner */}
                            <td style={{ width: "25%" }}>
                              <div>
                                <span className="dt-list-col-bullet dt-skeleton"></span>
                                <span className="dt-list-col-bullet-text dt-skeleton"></span>
                              </div>
                            </td>

                            {/* manager */}
                            <td style={{ width: "25%" }}>
                              <div>
                                <span className="dt-list-col-bullet dt-skeleton"></span>
                                <span className="dt-list-col-bullet-text dt-skeleton"></span>
                              </div>
                            </td>

                            {/* date */}
                            <td style={{ width: "10%" }}>
                              <div>
                                <p className="dt-list-col-date dt-skeleton"></p>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            {/* rating */}
                            <td style={{ width: "6%" }}>
                              <div>
                                <span className="dt-risk-rating-bullet dt-skeleton"></span>
                              </div>
                            </td>

                            {/* id */}
                            <td style={{ width: "6%" }}>
                              <div>
                                <p className="dt-list-col-result-id dt-skeleton"></p>
                              </div>
                            </td>

                            {/* description */}
                            <td style={{ width: "18%" }}>
                              <div>
                                <p className="dt-list-col-result-id dt-skeleton"></p>
                              </div>
                            </td>

                            {/* owner */}
                            <td style={{ width: "25%" }}>
                              <div>
                                <span className="dt-list-col-bullet dt-skeleton"></span>
                                <span className="dt-list-col-bullet-text dt-skeleton"></span>
                              </div>
                            </td>

                            {/* manager */}
                            <td style={{ width: "25%" }}>
                              <div>
                                <span className="dt-list-col-bullet dt-skeleton"></span>
                                <span className="dt-list-col-bullet-text dt-skeleton"></span>
                              </div>
                            </td>

                            {/* date */}
                            <td style={{ width: "10%" }}>
                              <div>
                                <p className="dt-list-col-date dt-skeleton"></p>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Container>
          </>
        )
      ) : (
        // risk details
        <MemorizedRiskDetails
          categories={categories}
          locations={locations}
          damages={damages}
          owners={owners}
          currency={currency}
          likelihoods={
            likelihoods
              ? likelihoods.map((l) => {
                  return {
                    value: l.id,
                    label: l.title,
                  };
                })
              : null
          }
          authToken={props.authToken}
          riskId={selectedRiskId}
          t={props.t}
          successNotification={props.successNotification}
          errorNotification={props.errorNotification}
          reportId={props.reportId}
          fetchRisks={handleFetchRisks}
          back={() => {
            handleFetchRisks(null, null, null, reportId, authToken);
            setSelectedRiskId(null);
          }}
          secondaryId={props.secondaryId}
          currentUser={props.currentUser}
          tableColors={tableColors}
          handleSetReportRiskField={handleSetReportRiskField}
          module={module}
          taskUrl={props?.taskUrl ?? null}
        />
      )}
    </TabPane>
  );
};

export default memo(RiskAssessment);
