import axios from "axios";
import { GDPR_DOMAIN_URL } from "../../../common/constants";

export default class DataProtectionADService {
  static instance;
  resource = `${GDPR_DOMAIN_URL}ad/`;

  constructor() {}

  static getInstance() {
    
    if (!DataProtectionADService.instance) {
      DataProtectionADService.instance =
        new DataProtectionADService();
    }

    return DataProtectionADService.instance;
  }

  async syncAssetsListWithAD(){
    const response = await axios.get(`${this.resource}fetch`, {
			params: {},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
		});
    return response.data.data
  }
}
