import React, { useState, useMemo, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Container,
  Button,
} from "reactstrap";
import Select from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import CommonService from "../../../modules/data-protection/apis/CommonService";
import OwnerService from "../../../modules/data-protection/apis/OwnersService";

const DefaultOwnerForm = (props) => {
  const { t, Organization } = props;
  const [selectedUser, setSelectedUser] = useState(undefined);

  const fetchOrganizationVerifyQuery = useQuery({
    queryKey: ["organizationVerify"],
    queryFn: async () => {
      const service = CommonService.getInstance();
      const data = {id: props.Organization.id, url: props.Organization.url}
      return await service.organizationVerify(data);
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occured while fetching organization."), {
        type: "error",
      });
    },
  });
  const fetchUsersQuery = useQuery({
    queryKey: ["users", fetchOrganizationVerifyQuery.data?.id],
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    enabled: !!fetchOrganizationVerifyQuery?.data?.id,
    refetchOnWindowFocus: true,
    onSuccess(data) {
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occured while fetching users."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if(fetchOrganizationVerifyQuery.isLoading || fetchUsersQuery.isLoading) return;

      if (fetchOrganizationVerifyQuery.data?.default_owner && fetchUsersQuery.data) {
        const user = fetchUsersQuery.data?.find(
          (user) => user.id === fetchOrganizationVerifyQuery.data.default_owner
        );
        setSelectedUser({
          value: user?.id,
          label: `${user?.first_name} ${user?.last_name}`,
        });
      }
  }, [fetchOrganizationVerifyQuery.data?.default_owner, fetchUsersQuery.data])

  const defaultOwnerMutation = useMutation({
    mutationKey: ["defaultOwner"],
    mutationFn: async (data) => {
      const service = OwnerService.getInstance();
      return await service.updateDefaultOwner(data);
    },
    onSuccess: (data) => {
      toast(t("Default owner updated successfully."), {
        type: "success",
      });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occured while updating default owner."), {
        type: "error",
      });
    },
  });

  const userOptions = useMemo(() => {
    if (!fetchUsersQuery.data) return [];
    return fetchUsersQuery.data.map((user) => ({
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
    }));
  }, [fetchUsersQuery.data]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    defaultOwnerMutation.mutate({ owner: selectedUser.value });
  };

  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col className="col-4">
          <Form>
            <FormGroup>
              <Label for="users">{t("Users")}</Label>
              <Select
                className="mt-1 text-capitalize"
                placeholder={t("Select") + "..."}
                id="users"
                name="users"
                onChange={handleUserSelect}
                options={userOptions}
                value={selectedUser}
                menuPortalTarget={document.body}
                isLoading={fetchUsersQuery.isLoading}
                isDisabled={
                  fetchUsersQuery.isLoading || fetchUsersQuery.isError
                }
              />
            </FormGroup>
            <FormGroup>
              <Button
                color="primary"
                className="mt-4 w-25"
                onClick={handleSubmit}
                disabled={!selectedUser}
              >
                {t("Save")}
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App, Feature, Organization } = state;
  return { token, App, Feature, Organization };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(DefaultOwnerForm))
);
