import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { successNotification, errorNotification } from "../../store/actions";
import {
  API_USER_UPDATE_MANUAL_TRANSCRIPTION,
  DATA_NOT_SAVED_SUCCESSFULLY,
  DATA_SAVED_SUCCESSFULLY,
} from "../../common/constants";
import axios from "axios";
import {
  AiTranscriptEditor,
  ManualTranscriptEditor,
} from "./TranscriptEditors";
import AiIcon from "../../assets/images/microchip.png";
import ManualIcon from "../../assets/images/writing.png";
import { FaMicrophone } from "react-icons/fa";
import UserUtils from "../../services/utils/UserUtils";
import { withRouter } from "react-router-dom";
// import "./ReportVoiceContent.css";

class ReportVoiceContents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      aiEditorState: EditorState.createEmpty(),
      transcriptEditorState: EditorState.createEmpty(),
      voices: [],
      loadingTranscribe: false,
      transcriptions: [],
      manualTranscriptEditorStates: {}, // Initialize as an empty object
      isTPOrEmployee: null,
    };

    this.userUtils = new UserUtils();
  }

  async componentDidMount() {
    await this.getTranscripts(this.props.report.voices);
    this.setState({
      voices: this.props.report.voices,
      isTPOrEmployee: this.isThirdParty() || this.isEmployee(),
    });
  }

  isThirdParty() {
    return this.userUtils.isThirdParty(this.props.user);
  }

  isEmployee() {
    return this.userUtils.isEmployee(this.props.user);
  }

  fetchVoiceFileUrl = async (voiceId) => {
    const apiUrl = `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/voice_report/${voiceId}/download_file`;

    try {
      const response = await axios.get(apiUrl, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);
        return audioUrl;
      } else {
        console.error("Failed to fetch voice file URL");
        return null; // Return null or handle the error as needed
      }
    } catch (error) {
      console.error("Error fetching voice file URL:", error);
      return null; // Return null or handle the error as needed
    }
  };

  getTranscripts = async (voices) => {
    const { transcriptions } = this.state;
    const headers = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };
    if (this.state.loadingTranscribe) {
      return;
    }
    this.setState({ loadingTranscribe: true });
    const responsePromises = voices.map(async (voice, index) => {
      const voiceId = voice.id;
      const url = `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/voice_report/${voiceId}/detail`;

      try {
        const response = await axios.get(url, headers);
        if (response.status === 200) {
          const result = response.data.data;
          const voiceFileUrl = await this.fetchVoiceFileUrl(voiceId); // Implement this function to fetch the URL
          const secondaryId = `${localStorage.getItem("secondaryCaseId")}-v${
            index + 1
          }`;
          return {
            ...result,
            ...{
              secondaryId: secondaryId,
              voiceFileUrl: voiceFileUrl, // Add the voice file URL to the result
            },
          };
        }
      } catch (error) {
        console.error("Error converting to text:", error);
      }
    });

    const results = await Promise.all(responsePromises);
    const updatedTranscriptions = results.map((result, index) => {
      const recognizedText = result.ai_transcript;
      const manualText = result.manual_transcript;

      const editorState = EditorState.createWithContent(
        convertFromRaw({
          entityMap: {},
          blocks: [
            {
              text: recognizedText,
            },
          ],
        })
      );

      const manualEditorState = manualText
        ? EditorState.createWithContent(
            convertFromRaw({
              entityMap: {},
              blocks: [
                {
                  text: manualText,
                },
              ],
            })
          )
        : null;
      if (recognizedText) this.props.onSetAITranscript(recognizedText);
      if (manualText) this.props.onSetManualTranscript(manualText);
      return {
        ...result,
        ...{
          secondaryId: result?.secondaryId,
          editorState: editorState, // Initialize editor state for each voice
          manualEditorState: manualEditorState,
        },
      };
    });

    this.setState({
      loadingTranscribe: false,
      transcriptions: updatedTranscriptions,
    });
  };

  onAiEditorStateChange = (newEditorState) => {
    this.setState({ aiEditorState: newEditorState });
  };

  handleCopyContent = async (e) => {
    try {
      await navigator.clipboard.writeText(e.getCurrentContent().getPlainText());
      this.props.successNotification({message: "Copied"})
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  handlePasteContent = async (voiceId, editorState) => {
    try {
      const text = await navigator.clipboard.readText();
      if (!editorState) {
        const newContentState = convertToRaw(
          EditorState.createWithContent(
            ContentState.createFromText(text)
          ).getCurrentContent()
        );

        const newEditorState = EditorState.createWithContent(
          convertFromRaw(newContentState)
        );

        const updatedEditorStates = {
          ...this.state.manualTranscriptEditorStates,
          [voiceId]: newEditorState,
        };

        this.setState({ manualTranscriptEditorStates: updatedEditorStates });
      } else {
        const newContentState = ContentState.createFromText(text);

        const newEditorState = EditorState.createWithContent(newContentState);

        const updatedEditorStates = {
          ...this.state.manualTranscriptEditorStates,
          [voiceId]: newEditorState,
        };

        this.setState({ manualTranscriptEditorStates: updatedEditorStates });
      }
    } catch (error) {
      console.error("Error pasting content:", error);
    }
  };

  handleSave = (row) => {
    const transcriptEditorState =
      this.state.manualTranscriptEditorStates[row.voice_id];
    if (!transcriptEditorState) {
      console.error(
        "Transcript editor state not found for voice ID:",
        row.voice_id
      );
      return;
    }

    const contentState = transcriptEditorState.getCurrentContent();
    const transcriptionText = contentState.getPlainText();

    const data = {
      voiceId: row.voice_id,
      transcription: transcriptionText,
    };

    const apiUrl = API_USER_UPDATE_MANUAL_TRANSCRIPTION;
    const headers = {
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    };

    axios
      .put(apiUrl, data, headers) // You can use axios.post() if it's a POST request
      .then(async(response) => {
        if (response.status === 200) {
          await this.getTranscripts(this.props.report.voices)
          this.props.successNotification({
            message: this.props.t(DATA_SAVED_SUCCESSFULLY),
          });
          this.getTranscripts(this.props.report.voices);
        } else {
          this.props.errorNotification({
            message: DATA_NOT_SAVED_SUCCESSFULLY,
          });
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        this.props.errorNotification({
          message: DATA_NOT_SAVED_SUCCESSFULLY,
        });
      });
  };

  handleManualTranscriptChange = (voiceId, newEditorState) => {
    const updatedManualTranscriptEditorStates = Object.assign(
      {},
      this.state.manualTranscriptEditorStates
    );
    updatedManualTranscriptEditorStates[voiceId] = newEditorState;

    this.setState({
      manualTranscriptEditorStates: updatedManualTranscriptEditorStates,
    });
  };

  customToolbarOptions = {
    options: ["inline", "list"],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    list: {
      options: ["unordered"],
    },
  };

  render() {
    const { isOpen, transcriptions, isTPOrEmployee } = this.state;
    const { t } = this.props;
    return (
      <Row>
        <Col sm="12">
          <Card className="mb-1 shadow-none">
            <Link
              to="#"
              onClick={() => {
                this.setState({ isOpen: !isOpen });
              }}
              style={{ cursor: "pointer" }}
              className="text-dark"
            >
              <CardHeader id="headingOne" className="bg-primary">
                <h6 className="m-0 text-white font-14">
                  {this.props.t("Contents")}
                  <i
                    className={`float-end accor-plus-icon mdi ${
                      isOpen ? "mdi-minus" : "mdi-plus"
                    }`}
                  ></i>
                </h6>
              </CardHeader>
            </Link>

            <Collapse isOpen={isOpen}>
              <CardBody>
                {
                  <div className="d-flex flex-wrap">
                    {transcriptions.map((row, index) => (
                      <Card
                        key={index}
                        className="m-2"
                        style={{
                          flex: "1",
                          border: "1px solid #ccc", // Add a border for clarity
                          borderRadius: "10px", // Add border radius for rounded corners
                        }}
                      >
                        <CardBody>
                          {/* ID above the box */}
                          <div className="text-center mb-2">
                            <strong>
                              {t("ID")}: {row.secondaryId}
                            </strong>
                          </div>
                          {/* First row with audio player */}
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <audio controls>
                              <source
                                src={row.voiceFileUrl}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio element.
                            </audio>
                            <FaMicrophone />
                          </div>

                          {/* Second row with AI-based Transcript */}
                          <div className="mb-3 text-center">
                            <div>
                              <img
                                src={AiIcon}
                                width={"30px"}
                                height={"30px"}
                                alt="AI Icon"
                              />
                            </div>
                            <AiTranscriptEditor
                              editorState={row.editorState} // Pass editorState for the specific row
                              onEditorStateChange={this.onAiEditorStateChange}
                              onClick={this.onAiEditorStateChange}
                              toolbarOptions={this.customToolbarOptions}
                              placeholder={this.props.t("AI based transcript")}
                              onCopyContent={this.handleCopyContent}
                              onSave={this.saveManulTranscript}
                              isTPOrEmployee={isTPOrEmployee}
                            />
                          </div>

                          {/* Third row with Manual Transcript and Save button */}
                          <div className="mb-3 text-center">
                            <div>
                              <img
                                src={ManualIcon}
                                width={"30px"}
                                height={"30px"}
                                alt="AI Icon"
                              />
                            </div>
                            <ManualTranscriptEditor
                              voiceId={row.voice_id}
                              manualEditorState={this.state.transcriptions}
                              editorState={
                                this.state.manualTranscriptEditorStates[
                                  row.voice_id
                                ]
                              }
                              onManualTranscriptChange={
                                this.handleManualTranscriptChange
                              }
                              placeholder={
                                t("Please add your manual transcript here") +
                                " ..."
                              }
                              onPasteContent={() =>
                                this.handlePasteContent(
                                  row.voice_id,
                                  this.state.manualTranscriptEditorStates[
                                    row.voice_id
                                  ]
                                )
                              }
                              isTPOrEmployee={isTPOrEmployee}
                              t={t}
                              toolbarOptions={{
                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                                inline: {
                                  options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "monospace",
                                  ],
                                  bold: { className: "bordered-option-classname" },
                                  italic: { className: "bordered-option-classname" },
                                  underline: { className: "bordered-option-classname" },
                                  strikethrough: { className: "bordered-option-classname" },
                                  code: { className: "bordered-option-classname" },
                                },
                                blockType: {
                                  className: "bordered-option-classname",
                                },
                                fontSize: {
                                  className: "bordered-option-classname",
                                },
                              }}
                            />
                          </div>

                          <div className="d-flex justify-content-end">
                            <Button
                              color="primary"
                              onClick={() => this.handleSave(row)}
                              hidden={isTPOrEmployee}
                            >
                              {t("Save")}
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                  </div>
                }
              </CardBody>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStateToProps, { errorNotification, successNotification })(
      ReportVoiceContents
    )
  )
);
