import { useState } from 'react';
import calenderIcon from '../../../src/assets/icons/calender.png';
import downIcon from '../../../src/assets/icons/downIcon.png';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './style.scss';

const CustomRangeDatePicker = ({ selectionDate, setSelectionDate }) => {
	const [toggle, setToggle] = useState(false);
	return (
		<div>
			<div className='select-box-container-component'>
				<img onClick={() => setToggle((prev) => !prev)} className='calender-icon' src={calenderIcon} alt='Calendar Icon' />
				<img onClick={() => setToggle((prev) => !prev)} className='down-icon' src={downIcon} alt='Down Icon' />
				<p onClick={() => setToggle((prev) => !prev)} className='select-box-label'>Date to date</p>
				<div onClick={() => setToggle((prev) => !prev)} className='select-box-options'></div>
				{toggle ? (
					<div className='option-box'>
						<DateRange
							editableDateInputs={true}
							onChange={(item) => setSelectionDate([item.selection])}
							moveRangeOnFirstSelection={false}
							ranges={
								selectionDate[0].startDate
									? selectionDate
									: [
											{
												startDate: new Date(),
												endDate: new Date(),
												key: 'selection',
											},
									  ]
							}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default CustomRangeDatePicker;
