import React, { memo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import RequestTab from "./request";
import MessageComponent from "./message";
import TaskManagment from "./taskManagment";
import TaskDetails from "src/modules/data-protection/pages/reporting/Components/TaskComponents/TaskDetails";
import Tabs from "./tabs";
import { useQuery } from "@tanstack/react-query";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ReportIncidenService from "../components/api/ReportIncidenService";
import Navigator from "../../../../components/navigator";
import OverViewHeader from "../components/overview-header";
import { toast } from "react-toastify";

const SubjectDetails = (props) => {
  const { t } = props;
  const [taskDetailPage, setTaskDetailPage] = useState(null);
  const [analystAdminsList, setAnalystAdminsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const { caseId } = useParams();
  const [report, setReport] = useState(null);
  const history = useHistory();
  const location = useLocation()

  const handleFetchCategories = useQuery({
    queryKey: ["report-subject-fetch-categories"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchCategoriesRequest();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });

  const handleFetchAssetBasicInformation = useQuery({
    queryKey: ["data-protection-overview-contnet"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();
      return await service.FetchIncidentReportOverView(caseId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching overview content."), {
        type: "error",
      });
    },
  });

  const handleFetchAnalystAndAdminAnalystList = useQuery({
    queryKey: ["fetch-analyst-and-admin-analyst-list"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();

      return await service.FetchAnalystsAndAnalystAdmins();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching analyst admins list."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (handleFetchCategories.data) {
      setCategories(handleFetchCategories.data);
    }
  }, [handleFetchCategories.data]);

  useEffect(() => {
    if (handleFetchAssetBasicInformation.data) {
      setReport(handleFetchAssetBasicInformation.data);
    }
  }, [handleFetchAssetBasicInformation.data]);


  useEffect(() => {
    if (
      handleFetchAnalystAndAdminAnalystList.data &&
      Array.isArray(handleFetchAnalystAndAdminAnalystList.data)
    ) {
      const newList = handleFetchAnalystAndAdminAnalystList.data.map(
        (analyst) => {
          return {
            value: analyst.id,
            label: `${analyst.first_name} ${analyst.last_name}`,
          };
        }
      );

      setAnalystAdminsList(newList);
    }
  }, [handleFetchAnalystAndAdminAnalystList.data]);


  const renderSelectedTabContent = () => {
    const searchParams = new URLSearchParams(location.search);

    if (
      handleFetchCategories.isLoading ||
      handleFetchCategories.isFetching ||
      !caseId
    ) {
      return (
        <div
          className="dt-field dt-skeleton"
          style={{ width: "100%", height: "320px" }}
        ></div>
      );
    }

    switch (searchParams.get("tab")) {
      case "request":
        return (
          <React.Fragment>
            <RequestTab
              categories={categories}
              caseId={caseId}
              report={report}
              reportRefetch={() => handleFetchAssetBasicInformation.refetch()}
              reportLoading={
                handleFetchAssetBasicInformation.isFetching ||
                handleFetchAssetBasicInformation.isLoading
              }
              analystAdminsList={analystAdminsList}
              adminsListIsLoading={
                handleFetchAnalystAndAdminAnalystList.isFetching ||
                handleFetchAnalystAndAdminAnalystList.isLoading
              }
              
            />
          </React.Fragment>
        );
      case "messages":
        return (
          <React.Fragment>
            <MessageComponent type="message" caseId={caseId} />
          </React.Fragment>
        );
      case "internal-comments":
        return (
          <React.Fragment>
            <MessageComponent type="internal" caseId={caseId} />
          </React.Fragment>
        );
      case "task-management":
        return (
          <React.Fragment>
            {taskDetailPage ? (
              <TaskDetails
                {...taskDetailPage?.data}
                back={() => {
                    history.push(
                    `/admin/data-protection/data-subject-request/details/${caseId}?tab=task-management`
                  );
                  setTaskDetailPage(null);
                }}
              />
            ) : (
              <TaskManagment
                caseId={caseId}
                onTaskSelected={(selectedTaskData) =>
                  setTaskDetailPage({
                    type: "task",
                    data: selectedTaskData,
                  })
                }
              />
            )}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <RequestTab categories={categories} caseId={caseId} />
          </React.Fragment>
        );
    }
  };

  return (
    <div className="page-content ">
      <Container fluid>
        <Row>
          <Col lg="12" className="mb-4">
            <Navigator
              backButtonOptions={() => {
                return {
                  enable: true,
                  onClick: () =>
                    history.push("/admin/data-protection/data-subject-request"),
                  title: props.t("Back"),
                  icon: <i className="ri-arrow-left-fill"></i>,
                };
              }}
              breadCrumbs={[
                { title: "SIP", link: "/" },
                {
                  title: "Data Subject Request",
                  link: `/admin/data-protection/data-subject-request`,
                },
              ]}
            />
          </Col>

          <Col lg="12" className="mb-4">
            <div className="details-content-wrapper">
              <>
                <Tabs t={t} onTabChange={() => setTaskDetailPage(null)} />
                <React.Fragment>
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody className="tabOverview mt-2">
                          <div className="d-flex flex-column gap-4 mb-4 mt-1">
                            {taskDetailPage ? null : (
                              <OverViewHeader
                                categories={categories}
                                caseId={caseId}
                                report={report}
                                loading={
                                  handleFetchAssetBasicInformation.isFetching ||
                                  handleFetchAssetBasicInformation.isLoading
                                }
                                analystAdminsList={analystAdminsList}
                                adminsListIsLoading={
                                  handleFetchAnalystAndAdminAnalystList.isFetching ||
                                  handleFetchAnalystAndAdminAnalystList.isLoading
                                }
                                reportRefetch={() => handleFetchAssetBasicInformation.refetch()}
                              />
                            )}
                            {renderSelectedTabContent()}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              </>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(SubjectDetails)));
