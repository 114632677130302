
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import UserUtils from "../../services/utils/UserUtils";
import axios from 'axios';
import { AUTH_API_DOMAIN_URL } from "../../common/constants";

const Policy = (props) => {
  const userUtils = new UserUtils();
  const type = window.location.href.includes('user_policy') ? 'user' : window.location.href.includes('data_privacy') ? "privacy" : "";
//   const [langId, setLangId] = useState(null);
  const {lng, token} = props;
  const organization = props?.organization?.id;
  const langId = props?.organization?.languages.find(l => l.language_code === lng)?.language?.id;
  const [content, setContent] = useState("");


    const fetchDoc = async () => {
        const response = await axios.get(`${AUTH_API_DOMAIN_URL}app/policy_type/${type}/language/${langId}/company/${organization}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.data.data) {
            setContent(response.data.data.policy);
        }
    }

    useEffect(()=> {
        fetchDoc();
    }, [langId])

  return (
    <div className="ps-3 pt-4">
      {/* Your other components go here */}
      <div className="mt-5 pt-5 pb-5 mb-5"  dangerouslySetInnerHTML={{ __html: content }} />

    </div>
  );
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const organization = state.Organization;
  return { user, token, App, organization };
};

export default connect(mapStatetoProps, {})(withNamespaces()(Policy));
