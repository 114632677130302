import { Component, Fragment } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { authProtectedRoutes, publicRoutes } from './routes';
import { WindowContext } from './contexts/WindowContext';
import { onGetInfoOrganizationSuccess } from './store/organization/actions';
import { onGetInfoGDPROrganizationSuccess } from './store/gdpr-organization/actions';
import { apiError, authCurrentUser, logoutUser, loginUserSuccessful } from './store/auth/login/actions';
import { changeWorkspace } from './store/workspace/actions';
import { post } from './helpers/request_helper';
import { appError, appLoadStatusChanged, changePreloader, fetchCMFeatureList, fetchFeatureList } from './store/actions';
import { fetchNotificationList } from './store/notification/actions';
import { API_URL_GET_USER_DETAIL, API_URL_VERIFY_ORGANIZATION, API_URL_GDPR_VERIFY_ORGANIZATION, API_URL_FEATURE_FLAGGING, API_URL_CM_FEATURE_FLAGGING } from './common/constants';

import { errorNotification } from './store/actions';
import { getBuildDate } from './utils/utils';
import AppRoute from './routes/route';
import UrlUtils from './services/utils/UrlUtils';
import OrganizationManager from './services/manager/OrganizationManager';
import GDPROrganizationManager from './services/manager/GDPROrganizationManager';
import data from './locales/en/translation.json';
import toastr from 'toastr';
import UserUtils from './services/utils/UserUtils';
import axios from 'axios';
import i18n, { fetch } from './i18n';
import packageJson from '../package.json';
import withClearCache from './ClearCache';
import Error404 from './pages/Error404';
// import './modules/assets/scss/app.scss';

import '@smartintegrity/risk-management-module/dist/index.css';
import 'toastr/build/toastr.min.css';
// import './theme.css';
import './assets/scss/theme.scss';
import './modules/data-protection/assets/scss/app.scss';
import ModuleContext from './contexts/ModuleContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class App extends Component {
	static contextType = WindowContext;
	queryClient = new QueryClient();

	ClearCacheComponent = withClearCache(App);

	constructor(props) {
		if (process.env.NODE_ENV === 'development') {
			const cookies = document.cookie.split('; ').map((i) => i.split('='));
			if (cookies.length < 1 || cookies.find((c) => c[0] === 'route') !== undefined) {
				document.cookie = 'route=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			}
		}

		super(props);

		this.urlUtils = new UrlUtils();
		this.organizationManager = new OrganizationManager();
		this.gdprOrganizationManager = new GDPROrganizationManager();
		this.userUtils = new UserUtils();
		this.url = this.urlUtils.getUrlParam();
		this.orgId = this.urlUtils.getOrganizationId();
		this.orgName = this.urlUtils.getOrganizationName();

		this.handleSetLanguage = this.handleSetLanguage.bind(this);
		this.loadWBFeatures = this.loadWBFeatures.bind(this);
		this.changePreloader = this.props.changePreloader.bind(this);
		this.changeWorkspace = this.props.changeWorkspace.bind(this);
		this.appLoadStatusChanged = this.props.appLoadStatusChanged.bind(this);
		this.loginUserSuccessful = this.props.loginUserSuccessful.bind(this);
		this.apiError = this.props.apiError.bind(this);
		this.fetchNotificationList = this.props.fetchNotificationList.bind(this);
		this.fetchFeatureList = this.props.fetchFeatureList.bind(this);
		this.fetchCMFeatureList = this.props.fetchCMFeatureList.bind(this);
		this.authCurrentUser = this.props.authCurrentUser.bind(this);
		this.appError = this.props.appError.bind(this);
		this.logoutUser = this.props.logoutUser.bind(this);
		this.interval = null;
		this.handleGetInfoOrganizationSuccess = this.props.handleGetInfoOrganizationSuccess.bind(this);
		this.handleGetInfoGDPROrganizationSuccess = this.props.handleGetInfoGDPROrganizationSuccess.bind(this);
		this.setSelectedModule = this.setSelectedModule.bind(this);

		this.state = {
			baseUrl: this.urlUtils.getAppRootPath(this.orgId, this.url),
			windowIsActive: false,
			primaryFont: null,
			primaryColor: '',
			fontColor: '',
			primaryLogoSize: null,
			secondaryLogoSize: null,
			isCompanyValid: false,
			render: false,
			appLanguages: [],
			is404: true,
			selectedModule: localStorage.getItem('module'),
			settings: {},
		};
	}

	setSelectedModule(selectedModule) {
		localStorage.setItem('module', selectedModule);
		this.setState({
			selectedModule: selectedModule,
		});
		// document.documentElement.setAttribute('data-module', 'gdpr');
	}

	handleSetLanguage(direction) {
		if (direction) {
			const HTMLElement = document.getElementsByTagName('html');
			if (HTMLElement[0]) {
				switch (direction) {
					case 'ltr': {
						HTMLElement[0].classList.remove('direction-rtl');
						HTMLElement[0].classList.add('direction-ltr');
						HTMLElement[0].setAttribute('dir', 'ltr');
						localStorage.setItem('lang_dir', direction);
						break;
					}
					case 'rtl': {
						HTMLElement[0].classList.remove('direction-ltr');
						HTMLElement[0].classList.add('direction-rtl');
						HTMLElement[0].setAttribute('dir', 'rtl');
						localStorage.setItem('lang_dir', direction);
						break;
					}
					default: {
						break;
					}
				}
			}
		}
	}

	async loadWBFeatures() {
		try {
			const response = await axios.post(API_URL_FEATURE_FLAGGING, {
				orgId: this.orgId,
			});

			if (response.status === 200 && response.data.data) this.fetchFeatureList(response.data.data);
		} catch (error) {
			console.error('An error occurred in the loadFeatures.');
		}

		this.setState({ render: !this.state.render });
	}

	async loadCMFeatures() {
		try {
			const response = await axios.post(API_URL_CM_FEATURE_FLAGGING, {
				orgId: this.orgId,
			});

			if (response.status === 200 && response.data.data) this.fetchCMFeatureList(response.data.data);
		} catch (error) {
			console.error('An error occurred in the loadCMFeatures.');
		}

		this.setState({ render: !this.state.render });
	}

	applyThemeStyle = (primaryColor = null, primaryLogoSize = null, secondaryLogoSize = null, primaryFont = null, fontColor = null) => {
		document.documentElement.style.setProperty('--color-body', fontColor || this.state.fontColor || '#ffffff');
		document.documentElement.style.setProperty('--color-primary', primaryColor || this.state.primaryColor || '#5664d2');
		document.documentElement.style.setProperty('--navbar-primary-brand-size', (primaryLogoSize || this.state.primaryLogoSize || 25) + 'px');
		document.documentElement.style.setProperty('--navbar-secondary-brand-size', (secondaryLogoSize || this.state.secondaryLogoSize || 25) + 'px');
		document.documentElement.style.setProperty('--primary-font', primaryFont || this.state.primaryFont || '"Inter", sans-serif');
	};

	showToast(options) {
		toastr.options = {
			positionClass: 'toast-top-right',
			timeOut: 3000,
			extendedTimeOut: 1000,
			closeButton: false,
			debug: false,
			progressBar: true,
			preventDuplicates: true,
			newestOnTop: true,
			showEasing: 'swing',
			hideEasing: 'linear',
			showMethod: 'fadeIn',
			hideMethod: 'fadeOut',
			showDuration: 300,
			hideDuration: 1000,
		};
		if (options.func === 'success') {
			toastr.success(options.message);
		} else {
			toastr.error(options.message);
		}
	}

	sendEnFile = async () => {
		try {
			await axios.post(
				`${process.env.REACT_APP_GDPR_API}/api/get.translation.en.json`,
				{
					data: data,
					organization_id: this.orgId,
					code_lang: 'en',
				},
				{},
			);
		} catch (error) {
			console.error(error);
		}
	};

	async loadDefaultLanguage(userLanguage = null, dir) {
		let data = this.state.appLanguages || [];
		let defaultLanguage = 'en';
		if (userLanguage) {
			defaultLanguage = userLanguage;
		} else if (data.length) {
			// eslint-disable-next-line array-callback-return
			data.map((item) => {
				if (item.is_default) {
					defaultLanguage = item.language_code;
				}
			});
		}

		fetch(defaultLanguage, dir);

		if (defaultLanguage !== localStorage.getItem('i18nextLng')) i18n.changeLanguage(defaultLanguage.replace('-', '_'));
	}

	authAndLoadCurrentUser() {
		// let userLanguage = null;
		const path = window.location.pathname.split('/');

		if (this.props.token !== null) {
			axios
				.post(
					API_URL_GET_USER_DETAIL,
					{
						organizationId: this.orgId,
					},
					{
						headers: {
							Authorization: `Bearer ${this.props.token}`,
						},
					},
				)
				.then((res) => {
					if (res.ok === false) {
					} else {
						let clients = [];

						const data = res.data.data;
						const orgId = parseInt(this.orgId);
						const userOrganizationId = data.organization && parseInt(data.organization.id);

						this.handleSetLanguage(res.data.data.settings?.direction);

						if (data.ok === false) {
							this.props.errorNotification({
								code: data.error || 'fatal_error',
								message: data.message && this.props.t(data.message),
							});
						}

						// if (data?.roles?.includes(ROLE_EMPLOYEE)) {
						// 	localStorage.setItem('module', 'wb');
						// 	this.setState({
						// 		selectedModule: 'wb',
						// 	});
						// }

						// userLanguage = data.settings.lang;

						// this.loadDefaultLanguage(userLanguage, data.settings.direction);

						if (data.settings.lang) {
							// Set user's language preference in local storage
							localStorage.setItem('i18nextLng', data.settings.lang);
							this.loadDefaultLanguage(data.settings.lang);
						}

						if (data.clients.length) {
							clients = data.clients.map((item) => item.id);
						}

						// Check if the user's organization ID matches the orgId or if the orgId is in the clients array
						if (orgId === userOrganizationId || clients.includes(orgId)) {
							// User authentication successful
							this.loginUserSuccessful({
								...data,
							});
							this.changeWorkspace(this.userUtils.getWorkspace(data));
							this.appLoadStatusChanged(true);
							this.apiError(null);
						} else {
							// User does not have access to the organization
							this.logoutUser();
						}
					}
				})
				.catch((err) => {
					if (err.response) {
						// Display error notification
						this.props.errorNotification({
							code: (err.response.data && err.response.data.error) || 'fatal_error',
							message: err.response.data && this.props.t(err.response.data.message),
						});
					}

					if (path.length >= 3 && path[3] === 'third-party') {
						// Update app load status
						this.appLoadStatusChanged(true);
					}

					setTimeout(() => {
						this.changePreloader(true);
					}, 500);
				});
		}

		setTimeout(() => {
			this.changePreloader(true);
		}, 500);
	}

	async componentDidMount() {
		this.changePreloader(false);
		if (this.url && this.orgId) {
			try {
				const response = await post(
					API_URL_VERIFY_ORGANIZATION,
					{
						id: this.orgId,
						url: decodeURI(this.url),
					},
					[],
				);

				// set organization's default language and id in the local storage
				localStorage.setItem('i18nextLng', response.data.data.languages.find((i) => i.is_default)?.language_code);
				localStorage.setItem('orgId', response.data.data.id);

				// set organization's default language
				this.handleSetLanguage(response.data.data.languages.find((i) => i.is_default)?.direction);

				// fetch the details of the organization's default language
				fetch();

				this.applyThemeStyle(
					response.data.data.primary_color,
					response.data.data.primary_logo_size,
					response.data.data.secondary_logo_size,
					response.data.data.font_family,
					response.data.data.font_color,
				);

				const settings = {};
				for (const module of Object.keys(response.data.data.setting)) {
					settings[module] = {
						isChat: response.data.data.setting ? !!response.data.data.setting[module].chat : false,
						isMeeting: response.data.data.setting ? !!response.data.data.setting[module].meeting : false,
						isVoice: response.data.data.setting ? !!response.data.data.setting[module].voice : false,
						isAnonymously: response.data.data.setting ? !!response.data.data.setting[module].anonymously : false,
						isConfidentially: response.data.data.setting ? !!response.data.data.setting[module].confidentially : false,
						isElearning: response.data.data.setting ? !!response.data.data.setting[module].elearning : false,
					};
				}

				if (response.data.data.modules?.filter((m) => m.hasModule).length === 1) {
					const module = response.data.data.modules.find((m) => m.hasModule).name;
					localStorage.setItem('module', module);
					this.setState({ selectedModule: module });
				}

				this.setState(
					{
						settings,
						isChat: response.data.data.setting ? !!response.data.data.setting.chat : false,
						isMeeting: response.data.data.setting ? !!response.data.data.setting.meeting : false,
						isVoice: response.data.data.setting ? !!response.data.data.setting.voice : false,
						isAnonymously: response.data.data.setting ? !!response.data.data.setting.anonymously : false,
						isConfidentially: response.data.data.setting ? !!response.data.data.setting.confidentially : false,
						isElearning: response.data.data.setting ? !!response.data.data.setting.elearning : false,
						isCompanyValid: true,
						primaryFont: response.data.data.setting ? response.data.data.font_family : '',
						primaryColor: response.data.data.setting ? response.data.data.primary_color : '',
						fontColor: response.data.data.setting ? response.data.data.font_color : '',
						primaryLogo: response.data.data.setting ? response.data.data.primary_logo : '',
						primaryLogoSize: response.data.data.setting ? response.data.data.primary_logo_size : '',
						secondaryLogo: response.data.data.setting ? response.data.data.secondary_logo : '',
						secondaryLogoSize: response.data.data.setting ? response.data.data.secondary_logo_size : '',
					},
					() => {
						this.handleGetInfoOrganizationSuccess({
							id: this.orgId,
							name: response.data.data.name,
							title_channel_report_tp: response.data.data.title_channel_report_tp,
							logo: response.data.data.logo,
							intro: response.data.data.intro,
							modules: response.data.data.modules,
							translations: response.data.data.translations,
							introTranslations: response.data.data.translations,
							isDemo: !!response.data.data.is_demo,
							isChat: response.data.data.setting ? !!response.data.data.setting.chat : false,
							isMeeting: response.data.data.setting ? !!response.data.data.setting.meeting : false,
							isVoice: response.data.data.setting ? !!response.data.data.setting.voice : false,
							isAnonymously: response.data.data.setting ? !!response.data.data.setting.anonymously : false,
							isConfidentially: response.data.data.setting ? !!response.data.data.setting.confidentially : false,
							isElearning: response.data.data.setting ? !!response.data.data.setting.elearning : false,
							status: response.data.data.status,
							primaryFont: response.data.data.font_family,
							primaryColor: response.data.data.primary_color,
							fontColor: response.data.data.font_color,
							azureLogin: response.data.data.isAdSet,
							primaryLogo: response.data.data.primary_logo,
							primaryLogoSize: response.data.data.primary_logo_size,
							secondaryLogo: response.data.data.secondary_logo,
							secondaryLogoSize: response.data.data.secondary_logo_size,
							taskOwnerLicenseCount: response.data.data.taskOwnerLicenseCount,
							remainingTaskOwnerLicenseCount: response.data.data.remainingTaskOwnerLicenseCount,
							languages: response.data.data.languages,
							path: null,
							url: response.data.data.orgUrl,
							countryId: response.data.data.country,
							customerId:	response.data.data.customerId
						});

						const currentSelectedModule = localStorage.getItem('module');
						if (
							!response.data.data.modules?.find((m) => m.name === currentSelectedModule) ||
							response.data.data.modules?.find((m) => m.name === currentSelectedModule).hasModule === false
						) {
							localStorage.removeItem('module');
						}

						this.setState(
							{
								baseUrl: this.urlUtils.getAppRootPath(this.orgId, this.url),
								appLanguages: response.data.data.languages,
							},
							async () => {
								const promises = [];
								if (response.data.data.modules?.find((i) => i.name === 'wb').hasModule) promises.push(this.loadWBFeatures());
								if (response.data.data.modules?.find((i) => i.name === 'cm').hasModule) promises.push(this.loadCMFeatures());
								await Promise.all(promises);
								this.sendEnFile();
								await this.authAndLoadCurrentUser();
							},
						);
					},
				);
			} catch (error) {
        if (process.env.NODE_ENV === 'development') { console.error(error); }
				this.changePreloader(true);
				this.appError({
					status: true,
					code: error?.message,
					message: '',
				});
			}
		}

		if(window.location.href.includes("data-protection") || window.location.href.includes("third-party")) {
			if (this.url && this.orgId) {
				try {
					const response = await post(
						API_URL_GDPR_VERIFY_ORGANIZATION,
						{
							id: this.orgId,
							url: decodeURI(this.url),
						},
						[],
					);
	
					// set organization's default language and id in the local storage
					localStorage.setItem('i18nextLng', response.data.data.languages.find((i) => i.is_default)?.language_code);
					localStorage.setItem('orgId', response.data.data.id);
	
					// set organization's default language
					this.handleSetLanguage(response.data.data.languages.find((i) => i.is_default)?.direction);
	
					// fetch the details of the organization's default language
					fetch();
	
					this.applyThemeStyle(
						response.data.data.primary_color,
						response.data.data.primary_logo_size,
						response.data.data.secondary_logo_size,
						response.data.data.font_family,
						response.data.data.font_color,
					);
	
					const settings = {};
					for (const module of Object.keys(response.data.data.setting)) {
						settings[module] = {
							isChat: response.data.data.setting ? !!response.data.data.setting[module].chat : false,
							isMeeting: response.data.data.setting ? !!response.data.data.setting[module].meeting : false,
							isVoice: response.data.data.setting ? !!response.data.data.setting[module].voice : false,
							isAnonymously: response.data.data.setting ? !!response.data.data.setting[module].anonymously : false,
							isConfidentially: response.data.data.setting ? !!response.data.data.setting[module].confidentially : false,
							isElearning: response.data.data.setting ? !!response.data.data.setting[module].elearning : false,
						};
					}
	
					if (response.data.data.modules?.filter((m) => m.hasModule).length === 1) {
						const module = response.data.data.modules.find((m) => m.hasModule).name;
						localStorage.setItem('module', module);
						this.setState({ selectedModule: module });
					}
					this.setState(
						// {
						// 	settings,
						// 	isChat: response.data.data.setting ? !!response.data.data.setting.chat : false,
						// 	isMeeting: response.data.data.setting ? !!response.data.data.setting.meeting : false,
						// 	isVoice: response.data.data.setting ? !!response.data.data.setting.voice : false,
						// 	isAnonymously: response.data.data.setting ? !!response.data.data.setting.anonymously : false,
						// 	isConfidentially: response.data.data.setting ? !!response.data.data.setting.confidentially : false,
						// 	isElearning: response.data.data.setting ? !!response.data.data.setting.elearning : false,
						// 	isCompanyValid: true,
						// 	primaryFont: response.data.data.setting ? response.data.data.font_family : '',
						// 	primaryColor: response.data.data.setting ? response.data.data.primary_color : '',
						// 	fontColor: response.data.data.setting ? response.data.data.font_color : '',
						// 	primaryLogo: response.data.data.setting ? response.data.data.primary_logo : '',
						// 	primaryLogoSize: response.data.data.setting ? response.data.data.primary_logo_size : '',
						// 	secondaryLogo: response.data.data.setting ? response.data.data.secondary_logo : '',
						// 	secondaryLogoSize: response.data.data.setting ? response.data.data.secondary_logo_size : '',
						// },
						() => {
							this.handleGetInfoGDPROrganizationSuccess({
								id: this.orgId,
								name: response.data.data.name,
								title_channel_report_tp: response.data.data.title_channel_report_tp,
								logo: response.data.data.logo,
								intro: response.data.data.intro,
								modules: response.data.data.modules,
								translations: response.data.data.translations,
								introTranslations: response.data.data.translations,
								isDemo: !!response.data.data.is_demo,
								isChat: response.data.data.setting ? !!response.data.data.setting.chat : false,
								isMeeting: response.data.data.setting ? !!response.data.data.setting.meeting : false,
								isVoice: response.data.data.setting ? !!response.data.data.setting.voice : false,
								isAnonymously: response.data.data.setting ? !!response.data.data.setting.anonymously : false,
								isConfidentially: response.data.data.setting ? !!response.data.data.setting.confidentially : false,
								isElearning: response.data.data.setting ? !!response.data.data.setting.elearning : false,
								status: response.data.data.status,
								primaryFont: response.data.data.font_family,
								primaryColor: response.data.data.primary_color,
								fontColor: response.data.data.font_color,
								azureLogin: response.data.data.isAdSet,
								primaryLogo: response.data.data.primary_logo,
								primaryLogoSize: response.data.data.primary_logo_size,
								secondaryLogo: response.data.data.secondary_logo,
								secondaryLogoSize: response.data.data.secondary_logo_size,
								taskOwnerLicenseCount: response.data.data.taskOwnerLicenseCount,
								remainingTaskOwnerLicenseCount: response.data.data.remainingTaskOwnerLicenseCount,
								languages: response.data.data.languages,
								path: null,
								url: response.data.data.orgUrl,
                                countryId: response.data.data.country,
                                customerId:	response.data.data.customerId
							});
	
							const currentSelectedModule = localStorage.getItem('module');
							if (
								!response.data.data.modules?.find((m) => m.name === currentSelectedModule) ||
								response.data.data.modules?.find((m) => m.name === currentSelectedModule).hasModule === false
							) {
								localStorage.removeItem('module');
							}
	
							this.setState(
								{
									baseUrl: this.urlUtils.getAppRootPath(this.orgId, this.url),
									appLanguages: response.data.data.languages,
								},
								async () => {
									const promises = [];
									if (response.data.data.modules?.find((i) => i.name === 'wb').hasModule) promises.push(this.loadWBFeatures());
									if (response.data.data.modules?.find((i) => i.name === 'cm').hasModule) promises.push(this.loadCMFeatures());
									await Promise.all(promises);
									this.sendEnFile();
									await this.authAndLoadCurrentUser();
								},
							);
						},
					);
				} catch (error) {
			if (process.env.NODE_ENV === 'development') { console.error(error); }
					this.changePreloader(true);
					this.appError({
						status: true,
						code: error?.message,
						message: '',
					});
				}
			}
			document.documentElement.setAttribute('data-module', 'gdpr');
		}
	}
	

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user && this.props.user && prevProps.token !== this.props.token) {
			this.loadDefaultLanguage(this.props.user.settings.lang, this.props.user.settings.direction);
		}

		// this block implemented to address SPIK-1507
		if (prevProps.organization !== this.props.organization) {
			if (this.props.organization.id !== this.orgId && this.props.organization.name !== this.orgName) {
				const newBaseUrl = this.urlUtils.getAppRootPath(this.props.organization.id, this.props.organization.url);

				if (newBaseUrl !== this.state.baseUrl) {
					this.setState({ baseUrl: newBaseUrl }, () => {
						window.location.replace(`${this.state.baseUrl}/${window.location.pathname.split('/', 4)[3]}/${this.props.organization.path}dashboard`);
					});
				}
			}
		}

		// If the RTL or LTR status is changed it founds it from the localstorage
		if (localStorage.getItem('reload') === 'true') {
			setTimeout(() => {
				window.location.reload(false);
				localStorage.setItem('reload', 'false');
			}, 2500);
		}

		if (this.props.App.loaded) {
			if (prevProps.token !== this.props.token) {
				this.loadWBFeatures();
				this.loadCMFeatures();
			}

			if (
				(prevProps.organization.fontColor !== this.props.organization.fontColor ||
					prevProps.organization.primaryColor !== this.props.organization.primaryColor ||
					prevProps.organization.primaryLogoSize !== this.props.organization.primaryLogoSize ||
					prevProps.organization.secondaryLogoSize !== this.props.organization.secondaryLogoSize,
				prevProps.organization.primaryFont !== this.props.organization.primaryFont)
			) {
				this.applyThemeStyle();
			}
		}

		if (prevProps.SystemNotification !== this.props.SystemNotification) {
			const toastOptions = {
				func: !this.props.SystemNotification.ok ? 'danger' : 'success',
				message: this.props.SystemNotification.message,
			};
			this.showToast(toastOptions);
		}
	}

	render() {
		return (
			<Fragment>
				<ToastContainer position='bottom-left' />
				<QueryClientProvider client={this.queryClient}>
					<ModuleContext.Provider
						value={{
							selectedModule: this.state.selectedModule,
							settings: this.state.settings,
							setSelectedModule: this.setSelectedModule,
						}}
					>
						<Router>
                            <Switch>
                                <Route path={this.state.baseUrl}>
                                    {!this.state.is404 || !this.props.layout || !this.props.layout.isPreloader ? (
                                        <div id='preloader'>
                                            <div id='status'>
                                                <div className='spinner'>
                                                    <i className='ri-loader-line spin-icon'></i>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    <Router basename={this.state.baseUrl}>
                                    
                                        <Switch>
                                            {this.state.isCompanyValid
                                                ? publicRoutes.map((route, idx) => {
                                                        return (
                                                            <AppRoute
                                                                path={route.path}
                                                                layout={route.layout}
                                                                component={route.component}
                                                                key={idx}
                                                                isAuthProtected={false}
                                                                type={route.type}
                                                                modules={route.modules}
                                                                settings={this.state.settings}
                                                                selectedModule={this.state.selectedModule}
                                                            />
                                                        );
                                                })
                                                : null}

                                            {this.state.isCompanyValid
                                                ? authProtectedRoutes.map((route, index) => {
                                                        return (
                                                            <AppRoute
                                                                path={route.path}
                                                                layout={route.layout}
                                                                component={route.component}
                                                                key={index}
                                                                isAuthProtected={true}
                                                                type={route.type}
                                                                modules={route.modules}
                                                                settings={this.state.settings}
                                                                selectedModule={this.state.selectedModule}
                                                                getUserConfirmation={ route?.getUserConfirmation }
                                                            />
                                                        );
                                                })
                                                : null}
                                        </Switch>
                                    </Router>
                                </Route>

                                {
                                    !this.props.App.loaded && (
                                        <Route>
                                            <Error404 from='mainRouter' />
                                        </Route>
                                    )
                                }
                            </Switch>
                        </Router>
					</ModuleContext.Provider>
				</QueryClientProvider>
				<p hidden>Build date: {getBuildDate(packageJson.buildDate)}</p>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		App: state.App,
		layout: state.Layout,
		user: state.Login.user,
		loginError: state.Login,
		token: state.Login.token,
		organization: state.Organization,
		SystemNotification: state.SystemNotification,
	};
};

const mapDispachToProps = (dispatch) => {
	return {
		errorNotification: (message) => dispatch(errorNotification(message)),
		logoutUser: (history) => dispatch(logoutUser(history)),
		handleGetInfoOrganizationSuccess: (val) => dispatch(onGetInfoOrganizationSuccess(val)),
		handleGetInfoGDPROrganizationSuccess: (val) => dispatch(onGetInfoGDPROrganizationSuccess(val)),
		changePreloader: (val) => dispatch(changePreloader(val)),
		fetchNotificationList: () => dispatch(fetchNotificationList()),
		authCurrentUser: () => dispatch(authCurrentUser()),
		changeWorkspace: (workspace) => dispatch(changeWorkspace(workspace)),
		loginUserSuccessful: (user) => dispatch(loginUserSuccessful(user)),
		apiError: (error) => dispatch(apiError(error)),
		appLoadStatusChanged: (status) => dispatch(appLoadStatusChanged(status)),
		appError: (error) => dispatch(appError(error)),
		fetchFeatureList: (val) => dispatch(fetchFeatureList(val)),
		fetchCMFeatureList: (val) => dispatch(fetchCMFeatureList(val)),
	};
};

export default connect(mapStateToProps, mapDispachToProps)(App);
