import { useState, useCallback, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import Breadcrumb from "../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import {
  FormGroup,
  Input,
  InputGroupText,
  Col,
  Row,
  InputGroup,
  Container,
  Alert,
} from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";

import { AUTH_API_DOMAIN_URL } from "../common/constants";

const styles = {
  input: {
    backgroundColor: "#f9f8fa",
    border: "1px solid #fff",
  },
  addon: {
    backgroundColor: "#f9f8fa",
    border: "1px solid #fff",
  },
  inputGroup: {
    height: "48px",
  },
};

const FAQ = (props) => {
  const location = useLocation();
  const { t, token } = props;
  const [searchValue, setSearch] = useState("");
  const [faqs, setFAQ] = useState(undefined);
  const moduleName = localStorage.getItem("module");
  const language = localStorage.getItem("i18nextLng");
  const [isLoading, setIsLoading] = useState(false);

  const fetchFAQ = useCallback(
    (moduleId, language_code) => {
      const headers = token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {};

      return axios.get(
        `${AUTH_API_DOMAIN_URL}faq/list?module=3&language=${language_code}`,
        {
          headers,
        }
      );
    },
    [token]
  );

  useEffect(() => {
    if (!moduleName || !language) return;
    setIsLoading(true);
    const moduleId = props.organization.modules.find(
      (m) => m.name === moduleName
    ).id;
    fetchFAQ(moduleId, language)
      .then((res) => {
        if (res.data.ok) {
          setFAQ(res.data.data.faqs);
        } else {
          setFAQ([]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchFAQ, moduleName, language, props.organization.modules]);

  const handleSearchChange = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  return (
    <div className="page-content mb-4">
      <Container fluid>
        <Breadcrumb
          title={t("FAQ")}
          breadcrumbItems={[
            { title: "SIP", link: "/" },
            { title: t("FAQ"), link: location.pathname },
          ]}
        />
      </Container>
      <Col>
        <Container fluid>
          <FormGroup className="w-25">
            <InputGroup style={styles.inputGroup}>
              <InputGroupText style={styles.addon}>
                <i className="fa fa-search" />
              </InputGroupText>
              <Input
                type="text"
                autoComplete="off"
                name="search"
                placeholder={t("Search")}
                onChange={handleSearchChange}
                id="search"
                style={styles.input}
              />
              <InputGroupText style={styles.addon}>
                <i className="fa fa-info-circle" />
              </InputGroupText>
            </InputGroup>
          </FormGroup>
        </Container>
      </Col>
      <Col>
        <Container
          fluid
          className="bg-white mt-3"
          style={{ minHeight: "400px" }}
        >
          <div className="d-flex flex-row justify-content-between pt-3">
            <span style={{ color: "#8A8DA3" }}>{t("Question")}</span>
            <span style={{ color: "#8A8DA3" }}>{t("Action")}</span>
          </div>
          <hr />
          {!isLoading ? (
            <Accordion defaultActiveKey="0" flush>
              {faqs?.length ? (
                faqs?.map((faq) => (
                  <Accordion.Item eventKey={faq.id}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>{faq.answer}</Accordion.Body>
                  </Accordion.Item>
                ))
              ) : (
                <Alert color="warning" className="text-center">{t("This list is empty")}</Alert>
              )}
            </Accordion>
          ) : (
            <Alert color="info" className="text-center">
              {t("Loading") + "..."}
            </Alert>
          )}
        </Container>
      </Col>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const organization = state.Organization;
  return { user, token, App, organization };
};

export default connect(mapStatetoProps, {})(withNamespaces()(FAQ));
