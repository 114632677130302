import { ROLE_ANALYST, ROLE_ANALYST_ADMIN, ROLE_EMPLOYEE, ROLE_TASK_OWNER, ROLE_THIRD_PARTY } from "../../components/constants";
import UrlUtils from "./UrlUtils";

export default class UserUtils {
	constructor() {
		this.urlUtils = new UrlUtils();
	}

	getName(user) {
		if (user) {
			const firstName = user.first_name || "";
			const lastName = user.last_name || "";

			if (firstName) {
				return firstName + " " + lastName;
			}

			return lastName;
		}

		return "";
	}

	belongOrganization(user) {
		if (user) {
			const orgId = this.urlUtils.getOrganizationId();
			const userOrg = user.organization;

			if (orgId && userOrg) {
				const id = parseInt(orgId);
				const userOrgId = userOrg.id;

				return id === userOrgId;
			}
		}

		return false;
	}

	isThirdParty(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_THIRD_PARTY);
		}

		return false;
	}

	isOrganizationThirdParty(user) {
		return this.belongOrganization(user) && this.isThirdParty(user);
	}

	notThirdParty(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_EMPLOYEE || role === ROLE_ANALYST_ADMIN || role === ROLE_ANALYST);
		}

		return false;
	}

	notOrganizationThirdParty(user) {
		return this.belongOrganization(user) && this.notThirdParty(user);
	}

	isAnalystAdmin(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_ANALYST_ADMIN);
		}

		return false;
	}

	isOrganizationAnalystAdmin(user) {
		if (user) {
			return this.belongOrganization(user) && this.isAnalystAdmin(user);
		}

		return false;
	}

	isAnalyst(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_ANALYST);
		}

		return false;
	}

	isOrganizationAnalyst(user) {
		if (user) {
			return this.belongOrganization(user) && this.isAnalyst(user);
		}

		return false;
	}

	isAnalystOrAnalystAdmin(user) {
		return this.isAnalyst(user) || this.isAnalystAdmin(user);
	}

	isEmployee(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_EMPLOYEE);
		}

		return false;
	}

	isTaskOwner(user) {
		if (user) {
			const roles = user.roles || [];

			return !!roles.find((role) => role === ROLE_TASK_OWNER);
		}

		return false;
	}

	isOrganizationEmployee(user) {
		if (user) {
			return this.belongOrganization(user) && this.isEmployee(user);
		}

		return false;
	}

	getInitUser() {
		return {
			id: "",
			email: "",
			name: "",
			roles: [],
			status: 0,
			reportsCount: "0",
			position: "",
			first_name: "",
			last_name: "",
			organization: null,
			title: "",
			is_first_admin: false,
			last_login: null,
			settings: {
				lang: null,
			},
			phone_number: "",
			analyst: {
				is_enabled_live_chat: false,
			},
		};
	}

	mapDataToUser(newUser) {
		const user = this.getInitUser();

		if (newUser) {
			const keys = Object.keys(user);

			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];

				if (newUser[key]) {
					user[key] = newUser[key];
				}
			}
		}

		return user;
	}

	getHiddenEmail(email) {
		const splitWord = "@";
		const splitEmails = email.split(splitWord);

		if (splitEmails && splitEmails.length > 0) {
			const emailName = splitEmails[0];
			const emailDomain = splitEmails[1];

			if (emailName) {
				let newEmailName = "";

				if (emailName.length <= 1) {
					newEmailName = "*";
				}

				if (emailName.length > 1) {
					newEmailName = emailName[0];

					for (let i = 1; i < emailName.length; i++) {
						newEmailName += "*";
					}
				}

				return newEmailName + splitWord + emailDomain;
			}
		}

		return "*";
	}

	getWorkspace(user) {
		if (!user) {
			return;
		}

		// console.log("getWorkspace.user: ", user)

		const role = user.roles[0];
		if (role === "ROLE_ANALYST") return "analyst_workspace";
		else if (role === "ROLE_EMPLOYEE") return "employee_workspace";
		else if (role === "ROLE_ANALYST_ADMIN" || role === "ROLE_PARTNER") return "admin_workspace";
		else return "third_party_workspace";
	}

	getUserTypeBaseRoute(user) {
		if (this.isAnalystOrAnalystAdmin(user)) return "/admin/home";
		else if (this.isEmployee(user)) return "/employee";
		else return "/third-party";
	}

	getUserHomeRoute(user) {
		if (this.isAnalystOrAnalystAdmin(user)) {
			return '/admin' ;
		} else if (this.isEmployee(user)) {
			if (parseInt(user.reportsCount) <= 0) {
				return '/employee/home'
			} else {
				return '/employee/reports'
			}
		} else {
			return '/third-party/home'
		}
	}

	gerUserRole(role) {
		let output = "";

		switch (role) {
			case "ROLE_USER":
				output = "User";
				break;
			case "ROLE_ADMIN":
				output = "Admin";
				break;
			case "ROLE_ANALYST":
				output = "Analyst";
				break;
			case "ROLE_ANALYST_ADMIN":
				output = "Analyst Admin";
				break;
			case "ROLE_EMPLOYEE":
				output = "Employee";
				break;
			case "ROLE_THIRD_PARTY":
				output = "Third Party";
				break;
			case "ROLE_TASK_OWNER":
				output = "Task Owner";
				break;
		}

		return output;
	}
}
