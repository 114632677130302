import { ACTION_FETCH_CM_FEATURE_LIST, ACTION_FETCH_FEATURE_LIST } from './actionTypes';

export const fetchFeatureList = (response) => ({
	type: ACTION_FETCH_FEATURE_LIST,
	payload: response,
});

export const fetchCMFeatureList = (response) => ({
	type: ACTION_FETCH_CM_FEATURE_LIST,
	payload: response,
});

// export const getFeatureStatus = (title) => ({
//     type: ACTION_GET_FEATURE_STATUS_SAGA,
//     payload: title
// });

// export const getFeatureStatusAction = (title) => ({
//     type: ACTION_GET_FEATURE_STATUS,
//     payload: title
// });
