import { Component, Fragment } from 'react';
import { logoutUser, setCaseId } from '../../store/actions';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableWithPagination from '../../components/Pagination';
import { withRouter } from "react-router-dom";

class HardwareAssetsList extends Component {
	constructor(props) {
		super(props);

		this.handleProvideTableColumns = this.handleProvideTableColumns.bind(this);
		this.state = {
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: this.props.t('Assets list - Hardware'), link: '#' },
			],
			hardwares: [
				{
					hardware_no: 'H1',
					manufacturer: 'Dell',
					model: 'Latitude E7450',
					type: 'Laptop',
					serial_no: 'DELL1234567890',
					os: 'W10',
					handover_to: 'John Smith',
					position: 'Software Engineer',
					role: 'Lead Developer',
					purchased_on: '2017-03-08',
					purchased_price: '$1,500',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
				{
					hardware_no: 'H2',
					manufacturer: 'Lenovo',
					model: 'ThinkPad X1 Carbon',
					type: 'Laptop',
					serial_no: 'LENOVO9876543210',
					os: 'W10',
					handover_to: 'Jane Doe',
					position: 'Technical Writer',
					role: 'Content Manager',
					purchased_on: '2018-06-15',
					purchased_price: '$2,000',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
				{
					hardware_no: 'H11',
					manufacturer: 'Asus',
					model: 'ZenBook S13',
					type: 'Laptop',
					serial_no: 'ASUS0987654321',
					os: 'Android 11',
					handover_to: 'Mike Jones',
					position: 'Sales Representative',
					role: 'Account Manager',
					purchased_on: '2021-09-22',
					purchased_price: '$1,200',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
				{
					hardware_no: 'H12',
					manufacturer: 'Acer',
					model: 'Swift 3',
					type: 'Laptop',
					serial_no: 'ACER9876543210',
					os: 'ChromeOS 92',
					handover_to: 'Sarah Brown',
					position: 'Marketing Manager',
					role: 'Brand Manager',
					purchased_on: '2022-03-08',
					purchased_price: '$1,000',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
				{
					hardware_no: 'H13',
					manufacturer: 'HP',
					model: 'Spectre x360',
					type: 'Laptop',
					serial_no: 'HP9876543210',
					os: 'Windows 11 22H2',
					handover_to: 'James White',
					position: 'IT Support Specialist',
					role: 'Level 2 Tech Support',
					purchased_on: '2023-06-15',
					purchased_price: '$1,300',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
				{
					hardware_no: 'H14',
					manufacturer: 'Microsoft',
					model: 'Surface Laptop 4',
					type: 'Laptop',
					serial_no: 'Windows 11 22H2',
					os: 'C02IJKL123456',
					handover_to: 'Emily Green',
					position: 'Accountant',
					role: 'Financial Analyst',
					purchased_on: '2023-09-22',
					purchased_price: '$1,400',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
				{
					hardware_no: 'H15',
					manufacturer: 'Apple',
					model: 'MacBook Air M1',
					type: 'Laptop',
					serial_no: 'macOS Monterey',
					os: 'C02EFGH123456',
					handover_to: 'David Blue',
					position: 'IT Manager',
					role: 'Systems Administrator',
					purchased_on: '2024-03-08',
					purchased_price: '$1,600',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
				{
					hardware_no: 'H16',
					manufacturer: 'Samsung',
					model: 'Galaxy Tab S8',
					type: 'Tablet',
					serial_no: 'SM-G998B1234567',
					os: 'Android 12',
					handover_to: 'Susan Green',
					position: 'Customer Support Representative',
					role: 'Level 2 Tech Support',
					purchased_on: '2024-06-15',
					purchased_price: '$600',
					protocol_signed: 'Yes',
					return_on: 'N/A',
					deletion_routine_by: 'N/A',
					comment: '',
				},
			],
		};
	}

	/**
	 * Provides the columns for the reports table.
	 *
	 * @returns {Array<{dataField: string, text: string, sort?: boolean}>} The columns list.
	 */
	handleProvideTableColumns() {
		const columns = [
			{
				dataField: 'hardware_no',
				text: this.props.t('Hardware No'),
				sort: false,
			},
			{
				dataField: 'manufacturer',
				text: this.props.t('Manufacturer'),
				sort: true,
			},
			{
				dataField: 'model',
				text: this.props.t('Model'),
				sort: true,
			},
			{
				dataField: 'type',
				text: this.props.t('Type'),
				sort: true,
			},
			{
				dataField: 'serial_no',
				text: this.props.t('Serial No'),
				sort: false,
			},
			{
				dataField: 'os',
				text: this.props.t('Operation System'),
				sort: true,
			},
			{
				dataField: 'purchased_on',
				text: this.props.t('Purchased On'),
				sort: true,
			},
			{
				dataField: 'purchased_price',
				text: this.props.t('Purchased Price'),
				sort: true,
			},
			{
				dataField: 'handover_to',
				text: this.props.t('Handover To'),
				sort: true,
			},
			{
				dataField: 'position',
				text: this.props.t('Position'),
				sort: false,
			},
			{
				dataField: 'role',
				text: this.props.t('Role'),
				sort: false,
			},
			{
				dataField: 'date',
				text: this.props.t('Date'),
				sort: true,
			},
			{
				dataField: 'protocol_signed',
				text: this.props.t('Protocol Signed'),
				sort: true,
			},
			{
				dataField: 'return_on',
				text: this.props.t('Return On'),
				sort: false,
			},
			{
				dataField: 'deletion_routine_by',
				text: this.props.t('Deletion Routine By'),
				sort: false,
			},
			{
				dataField: 'comment',
				text: this.props.t('Comment'),
				sort: false,
			},
		];

		return columns;
	}

	render() {
		return (
			<Fragment>
				<div className='page-content'>
					<Container fluid>
						<Breadcrumbs title={this.props.t('Assets list - Hardware')} breadcrumbItems={this.state.breadcrumbItems} />
						<div>
							{/* table */}
							<Row>
								<Col className='col-12'>
									<Card>
										<CardBody className='pt-0'>
											<TableWithPagination
												columns={this.handleProvideTableColumns()}
												pagination={{
													sizePerPage: 50,
													totalSize: 8,
												}}
												pageIndex={1}
												totalSize={8}
												sizePerPage={50}
												data={this.state.hardwares ? this.state.hardwares : []}
											/>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { user, token } = state.Login;
	const { App, Feature } = state;
	return { token, user, App, Feature };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
		logoutUser: (history) => dispatch(logoutUser(history)),
	};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, mapDispatchToProps)(HardwareAssetsList)));
