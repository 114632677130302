import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Input,
  Label,
  FormGroup,
  Form,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import DataProtectionSoftwareService from "../../../apis/DataProtectionSoftwareService";
import CreatableSelect from "react-select/creatable";
import CommonService from "../../../apis/CommonService";
// styles
import "./style.scss";
// components
import PageBody from "../../../components/page-body";
import PageHeader from "../../../components/page-header";
import PageContent from "../../../components/page-content";
import BottyPopup from "./BottyPopup";
import AILoading from "./component/loding";
import AIFailed from "./component/failed";
import GeneratedByAI from "./component/generatedByAI";

const DataProtectionSoftwareCreate = (props) => {
  const history = useHistory();
  const { assetId } = useParams();

  const { createModalOpen, toggleModal, t } = props;

  const [submitingFormStatus, setSubmitingFormStatus] = useState(false);

  const [serviceName, setServiceName] = useState("");
  const [serviceName_errors, setServiceName_errors] = useState([]);

  const [selectedAssetType, setSelectedAssetType] = useState(undefined);
  const [selectedAssetType_errors, setSelectedAssetType_errors] = useState([]);

  const [version, setVersion] = useState("");
  const [version_errors, setVersion_errors] = useState([]);

  const [providersList, setProvidersList] = useState([]);
  const [customProvider, setCustomProvider] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [provider_errors, setProvider_errors] = useState([]);

  const [operatingSystemsList, setOperatingSystemsList] = useState([]);
  const [customOperatingSystem, setCustomOperatingSystem] = useState("");
  const [selectedOperatingSystem, setSelectedOperatingSystem] = useState(null);
  const [operatingSystem_errors, setOperatingSystem_errors] = useState([]);

  const [moduleOptions, setModuleOptions] = useState([]);
  const [module, setModule] = useState("");
  const [selectedModule, setSelectedModule] = useState(null);
  const [module_errors, setModule_errors] = useState([]);

  const [countriesList, setCountriesList] = useState([]);
  const [selectedHostingLocation, setSelectedHostingLocation] = useState([]);
  const [selectedHostingLocation_errors, setSelectedHostingLocation_errors] =
    useState([]);

  const [selectedNoneEUHostingLocation, setSelectedNoneEUHostingLocation] =
    useState("");
  const [
    selectedNoneEUHostingLocation_errors,
    setSelectedNoneEUHostingLocation_errors,
  ] = useState([]);

  const [notice, setNotice] = useState("");
  const [notice_errors, setNotice_errors] = useState([]);
  const [priority, setPriority] = useState(undefined);
  const [priority_errors, setPriority_errors] = useState([]);
  const [subscription, setSubscription] = useState(false);
  const [subscription_errors, setSubscription_errors] = useState([]);
  const [owner, setOwner] = useState(undefined);
  const [owner_errors, setOwner_errors] = useState([]);

  // Define state variable to represent current state
  const [currentState, setCurrentState] = useState("generatedByAI"); // Possible values: generatedByAI, loading, success, failed, manuallyEditted
  const handleGetSelectedProviderName = (provider) => {
    if (provider.value === "-custom-") return customProvider;
    return provider.label;
  };

  const handleGetSelectedOperatingSystemsName = (operatingSystem) => {
    if (operatingSystem.value === "-custom-") return customOperatingSystem;
    return operatingSystem.label;
  };

  const handleResetFormErrors = () => {
    setServiceName_errors([]);
    setSelectedAssetType_errors([]);
    setVersion_errors([]);
    setProvider_errors([]);
    setOperatingSystem_errors([]);
    setSelectedHostingLocation_errors([]);
    setSelectedNoneEUHostingLocation_errors([]);
    setNotice_errors([]);
    setPriority_errors([]);
    setSubscription_errors([]);
    setOwner_errors([]);
  };

  const handleValidateForm = () => {
    let isValid = true;
    handleResetFormErrors();

    if (!serviceName) {
      setServiceName_errors([props.t("Service name is required.")]);
      isValid = false;
    }

    if (!selectedAssetType) {
      setSelectedAssetType_errors([props.t("Asset type is required.")]);
      isValid = false;
    }

    // if (!version) {
    //   setVersion_errors([props.t("Version is required.")]);
    //   isValid = false;
    // }

    if (!selectedProvider) {
      setProvider_errors([props.t("Provider is required.")]);
      isValid = false;
    } else if (selectedProvider.value === "-custom-" && !customProvider) {
      setProvider_errors([props.t("Please write a custom provider.")]);
      isValid = false;
    }

    return isValid;
  };

  const DataGeneratedByAI = (AIData) => {
    // Validate AIData before setting values
    if (!AIData || typeof AIData !== "object") {
      console.error("Invalid AIData provided.");
      props.changeCurrentState("failed");
      return;
    }

    // Validate and set values
    if (AIData?.Type) {
      setSelectedAssetType(AIData?.Type);
    }
    if (AIData?.softwareName) {
      setServiceName(AIData?.softwareName);
    }
    if (AIData?.Provider) {
      setSelectedProvider({ label: AIData.Provider, value: AIData.Provider });
    }
    if (AIData?.Modules && Array.isArray(AIData?.Modules)) {
      setSelectedModule(
        AIData.Modules.map((module) => ({ label: module, value: module }))
      );
    }
    if (AIData?.hosting_location && AIData?.hosting_location?.length >0) {
      const hostingLocationOptions = AIData.hosting_location.map(location => ({
        label: location.title,
        value: location.value
      }));
    
      // Add all items to setSelectedHostingLocation
      setSelectedHostingLocation(hostingLocationOptions);
    }
    if (AIData?.hosting_location_non_eu && AIData?.hosting_location_non_eu?.length >0) {
      const nonEUHostingLocationOptions = AIData.hosting_location_non_eu.map(location => ({
        label: location.title,
        value: location.value
      }));
    
      // Add all items to setSelectedNoneEUHostingLocation
      setSelectedNoneEUHostingLocation(nonEUHostingLocationOptions);
    }
  };

  const handleSubmit = () => {
    const modulesToSend = selectedModule?.map((m) => m.label);
    const hostingLocationValues = selectedHostingLocation?.map((location) => location?.value.toString());
    const noneEUHostingLocationsValues = selectedNoneEUHostingLocation?.map((location) => location?.value.toString());
    if (handleValidateForm()) {
      addSoftwareMutation.mutate({
        service: serviceName,
        type: selectedAssetType,
        version: version,
        provider: handleGetSelectedProviderName(selectedProvider),
        operatingSystem: handleGetSelectedOperatingSystemsName(
          selectedOperatingSystem
        ),
        hostingLocations: hostingLocationValues,
        noneEUHostingLocations: noneEUHostingLocationsValues,
        notice: notice,
        modules: modulesToSend,
        subscription: true,
        owner: owner,
      });
    }
  };

  const handleFetchProvidersListQuery = useQuery({
    queryKey: ["data-protection-providers-list"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      return await service.providers(1000, 1);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching providers."), {
        type: "error",
      });
    },
  });

  const handleFetchOperatingSystemsListQuery = useQuery({
    queryKey: ["data-protection-operating-systems-list"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      return await service.operatingSystems(1000, 1);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching operating systems."), {
        type: "error",
      });
    },
  });

  // Fetch Modules
  const handelFetchModulesListQuery = useQuery({
    queryKey: ["data-protection-modules-list", moduleOptions],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      const response = await service.modules();
      const data =
        response && response.length
          ? response.map((r) => ({
              label: r.name,
              value: r.id,
            }))
          : [];
      setModuleOptions(data);
      return data;
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching asset types."), {
        type: "error",
      });
    },
  });

  const handleFetchAssetTypesListQuery = useQuery({
    queryKey: ["data-protection-asset-types-list"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      return await service.types(1000, 1);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching asset types."), {
        type: "error",
      });
    },
  });

  const handleFetchCountriesListQuery = useQuery({
    queryKey: ["data-protection-countries-list"],
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.countries();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching countries."), {
        type: "error",
      });
    },
  });

  const addSoftwareMutation = useMutation({
    mutationFn: async (software) => {
      setCurrentState("loading");
      const service = DataProtectionSoftwareService.getInstance();
      return await service.create({
        assets: [software],
      });
    },
    onSuccess: () => {
      setCurrentState("manuallyEditted");

      toast(props.t("Software added successfully."), {
        type: "success",
      });
      //   history.push("/admin/data-protection/softwares");
      toggleModal();
      props?.fetchData();
    },
    onError: (error) => {
      const errorMessage = error?.response?.data;
      if (errorMessage?.error === 'validation.software.already_used') {
        setCurrentState("failed");
        toast(props.t('This software has already been used.'), {
            type: 'error',
        });
        return;
    } else {
      setCurrentState("failed");
      toast(props.t("An error occurred while adding software."), {
        type: "error",
      });
    }
    },
    onMutate: () => {
      setSubmitingFormStatus(true);
    },
    onSettled: () => {
      setSubmitingFormStatus(false);
    },
  });

  const fetchUsersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

  // Add to mdules
  const handleCreateModuleMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.addModule(payload);
    },
    onSuccess: () => {
      handelFetchModulesListQuery.refetch();
      toast(t("New module added successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while adding new module."), {
        type: "error",
      });
    },
  });

  const onCreateModuleOption = (label) => {
    handleCreateModuleMutation.mutate({
      software: Number(assetId),
      module: label,
    });
  };

  // Remove Module
  const handleRemoveModuleMutation = useMutation({
    mutationFn: async (payload) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.removeModule(payload);
    },
    onSuccess: () => {
      handelFetchModulesListQuery.refetch();
      toast(t("New module removed successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while removing new module."), {
        type: "error",
      });
    },
  });

  const handleModuleChange = (selectedOptions, actionMeta) => {
    if (actionMeta.action === "remove-value") {
      const { removedValue } = actionMeta;
      handleRemoveModuleMutation.mutate({
        software: Number(assetId),
        module: Number(removedValue?.value),
      });
    } else {
      const { option } = actionMeta;
      handleCreateModuleMutation.mutate({
        software: Number(assetId),
        module: option?.label,
      });
    }
  };

  //reset all form values to their default state
  useEffect(() => {
    if(createModalOpen){
    setCurrentState("generatedByAI");
    setSelectedAssetType(null);
    setServiceName("");
    setVersion("");
    setSelectedProvider(null);
    setSelectedHostingLocation([]);
    setSelectedNoneEUHostingLocation([]);
    setSelectedModule([]);
    setSelectedOperatingSystem([]);
    setOwner(null);
    setSubscription(false);
    setNotice("");}
  }, [createModalOpen]);

  useEffect(() => {
    if (handelFetchModulesListQuery.data) {
      const gotModules = handelFetchModulesListQuery.data;
    }
  }, [handelFetchModulesListQuery.data]);

  useEffect(() => {
    const providers = handleFetchProvidersListQuery?.data?.providers.map(
      (provider) => {
        return {
          label: provider.name,
          value: provider.id,
        };
      }
    );

    setProvidersList([
      {
        label: props.t("Custom"),
        value: "-custom-",
      },
      ...(providers || []),
    ]);
  }, [handleFetchProvidersListQuery.data]);

  useEffect(() => {
    const countries = handleFetchCountriesListQuery?.data?.map((country) => {
      return {
        label: country.name,
        value: country.id,
      };
    });

    setCountriesList(countries || []);
  }, [handleFetchCountriesListQuery.data]);

  useEffect(() => {
    const operatings =
      handleFetchOperatingSystemsListQuery?.data?.operationSystems.map((os) => {
        return {
          label: os.name,
          value: os.id,
        };
      });

    setOperatingSystemsList([
      {
        label: props.t("Custom"),
        value: "-custom-",
      },
      ...(operatings || []),
    ]);
  }, [handleFetchOperatingSystemsListQuery.data, createModalOpen]);

  return (
    <>
      <Modal
        size={currentState === "manuallyEditted" ? "xl" : "lg"}
        isOpen={createModalOpen}
        toggle={toggleModal}
        className="modal-main-content-Ai-software"
      >
        {currentState !== "loading" && <ModalHeader toggle={toggleModal} />}
        <ModalBody>
          {currentState === "generatedByAI" && (
            <GeneratedByAI
              t={props.t}
              toggleModal={toggleModal}
              generate={(e) => DataGeneratedByAI(e)}
              changeCurrentState={(e) => setCurrentState(e)}
            />
          )}

          {currentState === "loading" && <AILoading t={props.t} />}

          {currentState === "success" && <div>{/* Success message */}</div>}

          {currentState === "failed" && <AIFailed t={props.t} />}

          {currentState === "manuallyEditted" && (
            <div>
              <PageHeader title={props.t("Add New Softwares")} />
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Row className="mb-4">
                  {/* Asset Type */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="asset-type"
                        className={
                          selectedAssetType_errors.length ? "text-danger" : ""
                        }
                      >
                        {props.t("Asset Type")}
                      </Label>
 
                      <Input
                        className="mt-1 text-capitalize"
                        id="asset-type"
                        name="asset-type"
                        type="select"
                        value={selectedAssetType}
                        onChange={(e) => {
                          setSelectedAssetType(e.target.value);
                          setSelectedAssetType_errors([]);
                        }}
                        invalid={selectedAssetType_errors.length ? true : false}
                      >
                        <option value={null}>{props.t("Select")}</option>
                        {handleFetchAssetTypesListQuery?.data?.map((type) => {
                          return (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          );
                        })}
                      </Input>
                      {selectedAssetType_errors.length
                        ? selectedAssetType_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>

                  {/* Service Name */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="service-name"
                        className={
                          serviceName_errors.length ? "text-danger" : ""
                        }
                      >
                        {props.t("Service Name")}
                      </Label>
                      <Input
                        className="mt-1 text-capitalize"
                        id="service-name"
                        name="service-name"
                        type="text"
                        value={serviceName}
                        onChange={(e) => {
                          setServiceName(e.target.value);
                          setServiceName_errors([]);
                        }}
                        invalid={serviceName_errors.length ? true : false}
                      />
                      {serviceName_errors.length
                        ? serviceName_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>

                  {/* Version */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="version"
                        className={version_errors.length ? "text-danger" : ""}
                      >
                        {props.t("Version")}
                      </Label>
                      <Input
                        className="mt-1 text-capitalize"
                        id="version"
                        name="version"
                        type="text"
                        value={version}
                        onChange={(e) => {
                          setVersion(e.target.value);
                          setVersion_errors([]);
                        }}
                        invalid={version_errors.length ? true : false}
                      />
                      {version_errors.length
                        ? version_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-4">
                  {/* Provider */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="provider"
                        className={provider_errors.length ? "text-danger" : ""}
                      >
                        {props.t("Provider")}
                      </Label>
                      <CreatableSelect
                        className="mt-1 text-capitalize"
                        placeholder={props.t("Select") + "..."}
                        id="select-provider"
                        name="select-provider"
                        value={selectedProvider}
                        onChange={(newProvider) => {
                          setSelectedProvider(newProvider);
                          setProvider_errors([]);
                        }}
                        options={providersList}
                        // menuPortalTarget={document.body}
                        isLoading={
                          handleFetchProvidersListQuery.isLoading ||
                          handleFetchProvidersListQuery.isFetching
                        }
                        isDisabled={
                          handleFetchProvidersListQuery.isLoading ||
                          handleFetchProvidersListQuery.isFetching
                        }
                      />
                    </FormGroup>
                  </Col>

                  {/* Hosting Location */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="selected-hosting-location"
                        className={
                          selectedHostingLocation_errors.length
                            ? "text-danger"
                            : ""
                        }
                      >
                        {props.t("Hosting Location")}
                      </Label>
                      <CreatableSelect
                     className="mt-1 text-capitalize"
                     placeholder={props.t("Select") + "..."}
                     id="selected-hosting-location"
                     name="selected-hosting-location"
                        isMulti
                        value={selectedHostingLocation}
                        onChange={(newLocation) => {
                          
                          setSelectedHostingLocation(newLocation);
                          setSelectedHostingLocation_errors([]);
                        }}
                        options={countriesList}
                        // menuPortalTarget={document.body}
                        isLoading={
                          handleFetchCountriesListQuery.isLoading ||
                          handleFetchCountriesListQuery.isFetching
                        }
                        isDisabled={
                          handleFetchCountriesListQuery.isLoading ||
                          handleFetchCountriesListQuery.isFetching
                        }
                      />
                    
                      {selectedHostingLocation_errors.length
                        ? selectedHostingLocation_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>

                  {/* None EU Hosting Location */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="none-eu-hosting-location"
                        className={
                          selectedNoneEUHostingLocation_errors.length
                            ? "text-danger"
                            : ""
                        }
                      >
                        {props.t("None EU Hosting Location")}
                      </Label>
                      <CreatableSelect
                        className="mt-1 text-capitalize"
                        placeholder={props.t("Select") + "..."}
                        id="none-eu-hosting-location"
                        name="none-eu-hosting-location"
                        isMulti
                        value={selectedNoneEUHostingLocation}
                        onChange={(newLocation) => {
                          setSelectedNoneEUHostingLocation(newLocation);
                          setSelectedNoneEUHostingLocation_errors([]);
                        }}
                        options={countriesList}
                        // menuPortalTarget={document.body}
                        isLoading={
                          handleFetchCountriesListQuery.isLoading ||
                          handleFetchCountriesListQuery.isFetching
                        }
                        isDisabled={
                          handleFetchCountriesListQuery.isLoading ||
                          handleFetchCountriesListQuery.isFetching
                        }
                      />
                      {selectedNoneEUHostingLocation_errors.length
                        ? selectedNoneEUHostingLocation_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {/* Module */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="selected-module"
                        className={module_errors.length ? "text-danger" : ""}
                      >
                        {props.t("Module")}
                      </Label>
                      <CreatableSelect
                        className="mt-1 text-capitalize"
                        placeholder={props.t("Select") + "..."}
                        id="selected-module"
                        name="selected-module"
                        value={selectedModule}
                        isMulti
                        onChange={(newModule) => {
                          setSelectedModule(newModule);
                          setModule_errors([]);
                        }}
                        options={moduleOptions}
                        // menuPortalTarget={document.body}
                        isLoading={
                          handleFetchOperatingSystemsListQuery.isLoading ||
                          handleFetchOperatingSystemsListQuery.isFetching
                        }
                        isDisabled={
                          handleFetchOperatingSystemsListQuery.isLoading ||
                          handleFetchOperatingSystemsListQuery.isFetching
                        }
                      />
                      {/* <Label className="form-label">{props.t("Software module")}:</Label>
                <CreatableSelect
                  className="mt-1 text-capitalize"
                  placeholder={props.t("Select") + "..."}
                  options={moduleOptions}
                  // menuPortalTarget={document.body}
                  isMulti
                  onCreateOption={onCreateModuleOption}
                  onChange={handleModuleChange}
                  value={selectedModule}
                /> */}
                    </FormGroup>
                  </Col>

                  {/* Operating System */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="operating-system"
                        className={
                          operatingSystem_errors.length ? "text-danger" : ""
                        }
                      >
                        {props.t("Operating System")}
                      </Label>
                      <CreatableSelect
                        className="mt-1 text-capitalize"
                        placeholder={props.t("Select") + "..."}
                        id="selected-operating-system"
                        name="selected-operating-system"
                        isMulti
                        value={selectedOperatingSystem}
                        onChange={(newOP) => {
                          setSelectedOperatingSystem(newOP);
                          setOperatingSystem_errors([]);
                        }}
                        options={operatingSystemsList}
                        // menuPortalTarget={document.body}
                        isLoading={
                          handleFetchOperatingSystemsListQuery.isLoading ||
                          handleFetchOperatingSystemsListQuery.isFetching
                        }
                        isDisabled={
                          handleFetchOperatingSystemsListQuery.isLoading ||
                          handleFetchOperatingSystemsListQuery.isFetching
                        }
                      />
                    </FormGroup>
                  </Col>

                  {/* Owner */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label
                        for="owner"
                        className={owner_errors.length ? "text-danger" : ""}
                      >
                        {props.t("Owner")}
                      </Label>
                      <Input
                        className="mt-1 text-capitalize"
                        id="owner"
                        name="owner"
                        type="select"
                        value={owner}
                        onChange={(e) => {
                          setOwner(e.target.value);
                          setOwner_errors([]);
                        }}
                        invalid={owner_errors.length ? true : false}
                        defaultValue={undefined}
                      >
                        <option value={null}>{props.t("Select")}</option>
                        {fetchUsersQuery.data?.map((user) => {
                          return (
                            <option key={user.id} value={user.id}>
                              {user.first_name} {user.last_name}
                            </option>
                          );
                        })}
                      </Input>
                      {owner_errors.length
                        ? owner_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {/* Subscription */}
                  <Col sm="12" md="6" lg="4">
                    <FormGroup className="d-flex align-items-start flex-column">
                      <Label
                        for="subscription"
                        className={
                          subscription_errors.length
                            ? "text-danger d-flex"
                            : "d-flex"
                        }
                      >
                        {props.t("Subscription")}
                      </Label>
                      <FormGroup
                        className="d-flex align-items-center"
                        style={{ height: "38px" }}
                      >
                        <Input
                          className="mt-1 text-capitalize"
                          id="subscription"
                          name="subscription"
                          type="checkbox"
                          value={subscription}
                          onChange={(e) => {
                            setSubscription(e.target.value);
                            setSubscription_errors([]);
                          }}
                          invalid={subscription_errors.length ? true : false}
                          defaultValue={undefined}
                        />
                        <Label
                          check
                          for="subscription"
                          style={{ marginLeft: "10px" }}
                        >
                          {props.t("Has Subscription")}
                        </Label>
                      </FormGroup>
                      {subscription_errors.length
                        ? subscription_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-4">
                  {/* Notice */}
                  <Col sm="12" md="12" lg="12">
                    <FormGroup>
                      <Label
                        for="notice"
                        className={notice_errors.length ? "text-danger" : ""}
                      >
                        {props.t("Notice")}
                      </Label>
                      <Input
                        className="mt-1 text-capitalize"
                        id="notice"
                        name="notice"
                        type="textarea"
                        rows="3"
                        value={notice}
                        onChange={(e) => {
                          setNotice(e.target.value);
                          setNotice_errors([]);
                        }}
                        invalid={notice_errors.length ? true : false}
                        defaultValue={undefined}
                      />
                      {notice_errors.length
                        ? notice_errors.map((e) => {
                            return (
                              <small key={e} className="text-danger">
                                {e}
                              </small>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6" lg="4">
                    <Button
                      disabled={submitingFormStatus}
                      className="btn-primary"
                      type="submit"
                    >
                      {props.t("Save")}
                    </Button>
                    <Button
                      // to={"/admin/data-protection/softwares"}
                      onClick={() => toggleModal()}
                      className="btn btn-danger mx-2"
                    >
                      {props.t("Back to List")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default withNamespaces()(withRouter(DataProtectionSoftwareCreate));
