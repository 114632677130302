import { Redirect } from 'react-router-dom';
import HorizontalLayout from '../components/HorizontalLayout/';
import Profile from '../pages/Authentication/Profile';
import ChangePassword from '../pages/Authentication/ChangePassword';
import EmployeeHome from '../pages/Employee/home/index';
import EmployeeMyTasks from '../pages/Employee/my-tasks';
import EmployeeMyTaskDetails from '../pages/Employee/my-tasks/details';
import ReportAnonymously from '../pages/Reporting/ReportAnonymously';
import ReportConfidently from '../pages/Reporting/ReportConfidently';
import ArrangeMeeting from '../pages/Reporting/ArrangeMeeting';
import VoiceReport from '../pages/Reporting/VoiceReport/VoiceReport';
import ReportDetails from '../pages/Reporting/ReportDetails';
import ReportList from '../pages/Reporting/ReportList';
import ElearningHome from '../pages/E-learning';
import ElearningDetail from '../pages/E-learning/ElearningDetail';
import TutorialHome from '../pages/Tutorial';
import TutorialDetail from '../pages/Tutorial/TutorialDetail';
import Documents from '../pages/Documents';
import FAQ from '../pages/FAQ';
import UserManual from '../pages/UserManual'

const routes = [
	{
		path: '/employee/report/detail',
		component: ReportDetails,
		layout: HorizontalLayout,
		type: '/employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/report-anonymously',
		component: ReportAnonymously,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/report-confidentially',
		component: ReportConfidently,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/arrange-meeting',
		component: ArrangeMeeting,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/voice-report',
		component: VoiceReport,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/home',
		component: EmployeeHome,
		layout: HorizontalLayout,
		type: 'employee',
	},
	{
		path: '/employee/reports',
		component: ReportList,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/tasks/:id',
		component: EmployeeMyTaskDetails,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/tasks',
		component: EmployeeMyTasks,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ['wb', 'cm'],
	},
	{
		path: '/employee/profile/change-password',
		component: ChangePassword,
		layout: HorizontalLayout,
		type: 'employee',
	},
	{
		path: '/employee/profile',
		component: Profile,
		layout: HorizontalLayout,
		type: '/employee',
	},
	{
		path: '/employee/e-learning/:id/detail',
		component: ElearningDetail,
		layout: HorizontalLayout,
		type: 'employee',
	},
	{
		path: '/employee/e-learning',
		component: ElearningHome,
		layout: HorizontalLayout,
		type: 'employee',
	},
	{
		path: '/employee/tutorial/:id/detail',
		component: TutorialDetail,
		layout: HorizontalLayout,
		type: 'employee',
	},
	{
		path: '/employee/tutorial',
		component: TutorialHome,
		layout: HorizontalLayout,
		type: 'employee',
	},
  {
    path: '/employee/faq',
    component: FAQ,
    layout: HorizontalLayout,
    type: 'employee',
  },
  {
    path: '/employee/user_manual',
    component: UserManual,
    layout: HorizontalLayout,
    type: 'employee',
  },
	{
		path: '/employee/documents',
		component: Documents,
		layout: HorizontalLayout,
		type: 'employee',
	},
	{
		path: ['/employee', '/employee/*'],
		component: () => <Redirect to='/employee/home' />,
		layout: HorizontalLayout,
		type: 'employee',
	},
];

export default routes;
