import { ACTION_FETCH_CM_FEATURE_LIST, ACTION_FETCH_FEATURE_LIST} from "./actionTypes";

const initialState = [];

export const Feature = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_FETCH_FEATURE_LIST:
			return action.payload;
		// case ACTION_GET_FEATURE_STATUS:
		// 	return state.filter((item) => item.title === action.payload)
		default:
			return state;
	}
};

export const CMFeature = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_FETCH_CM_FEATURE_LIST:
			return action.payload;
		// case ACTION_GET_FEATURE_STATUS:
		// 	return state.filter((item) => item.title === action.payload)
		default:
			return state;
	}
};
