import { Component, Fragment } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { logoutUser } from "../../../store/auth/login/actions";
import { connect } from "react-redux";
import "./style.scss";
import axios from "axios";
import UrlUtils from "../../../services/utils/UrlUtils";
import UserUtils from "../../../services/utils/UserUtils";
import { AUTH_API_DOMAIN_URL } from "../../../common/constants";
import feedback from "../../../../src/assets/images/feedback.png";
import balance from "../../../../src/assets/images/balance.png";
import gdpr from "../../../../src/assets/images/gdpr.png";
import secondCanvas from "../../../../src/assets/images/canva2.gif";
import logo from "../../../assets/images/Logo-new.png";
import DataSecurity from "../../../assets/images/Data Security.png";
import CustomerSupport from "../../../assets/images/Support.png";
import Contact from "../../../assets/images/Contact.png";
import SecurityFAQ from "../../../assets/pdf/DISS-CO_SIP_Data Protection and Security FAQ_20231102.pdf";
import ISOCertificate from "../../../assets/pdf/ISO27001_DISS-CO_44 121 222498 englisch.pdf";
import TOM from "../../../assets/pdf/DISS-CO_TOM_20230820.pdf";
import PenTest from "../../../assets/pdf/Pen Test-letter-attestation_DISS-CO_20231006.pdf";
import ModuleContext from "../../../contexts/ModuleContext";
import LanguageDropdown from "../../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import { withRouter } from "react-router-dom";
import JusticeIcon from "../../../assets/images/justice.svg"
import ShieldIcon from "../../../assets/images/shield.svg"
import ComplaintIcon from "../../../assets/images/complaint.svg"

class ModuleSelection extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);
    this.urlUtils = new UrlUtils();
    this.userUtils = new UserUtils();

    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      url: this.urlUtils.getUrlParam(),
      orgId: this.urlUtils.getOrganizationId(),
      orgName: this.urlUtils.getOrganizationName(),
      orgUrl: this.urlUtils.getUrlParam(),
      isThirdParty: window.location.pathname.split("/").includes("third-party"),
      isDesktop: false,
    };

    this.handleModule = this.handleModule.bind(this);
    this.handleRenderModuleBox = this.handleRenderModuleBox.bind(this);
    this.handleSetModuleOnTheServer =
      this.handleSetModuleOnTheServer.bind(this);
  }

  handleModule = (module, token) => {
    localStorage.setItem("module", module);
    this.context.setSelectedModule(module);
    if (token) this.handleSetModuleOnTheServer(module, token);
    this.props.history.push("/third-party/home")
  };

  handleRenderModuleBox = (moduleKey, type) => {
    let route = "";
    if (this.props.match.path.split("/").includes("third-party")) {
      route = "/third-party/home";
    } else {
      route =
        this.userUtils.getUserHomeRoute(this.props.user) === "/admin"
          ? `${this.userUtils.getUserHomeRoute(this.props.user)}/home`
          : this.userUtils.getUserHomeRoute(this.props.user);
    }
    switch (moduleKey) {
      case "gd":
        if(type == "third-party") {
        return (
          <div style={{maxWidth: '306px'}} className="d-flex flex-column justify-content-center align-items-center">
            <div className="mb-5">
              <img src={ShieldIcon} width={120} height={120} />
            </div>
            <div className="mb-5">
              <Button onClick={() => this.handleModule('wb', this.props.token)} color="primary" size="lg" className="text-white fw-bolder">
                {this.props.t("Data Protection")}
              </Button>
            </div>
            <div>
              <p>
                {this.props.t("Report a data breach incident or send other requests related to your personal data. A data breach is a security event where sensitive, protected, or confidential data is accessed, disclosed, or stolen without proper authorization.")}
              </p>
            </div>
          </div>
        )
        } else {
          return (
              <Link
                to={route}
                className="d-flex mt-5 option z-3"
                key={"wb"}
                onClick={() => this.handleModule("wb", this.props.token)}
              >
                <div>
                  <div className="circle">
                    <div className="second-circle">
                      <p className="mb-0">GDPR</p>
                    </div>
                  </div>
                </div>{" "}
              </Link>
            );
        }
      case "wb":
        if(type == "third-party") {
          return (
            <div style={{maxWidth: '306px'}} className="d-flex flex-column justify-content-center align-items-center">
            <div className="mb-5">
              <img src={JusticeIcon} width={120} height={120} />
            </div>
            <div className="mb-5">
              <Button disabled={true} onClick={() => this.handleModule(moduleKey, this.props.token)} color="primary" size="lg" className="text-white fw-bolder">
                {this.props.t("Misconduct")}
              </Button>
            </div>
            <div>
              <p>
                {this.props.t("Misconduct refers to improper or unacceptable behavior or actions, especially by someone in a position of authority or trust. This could include activities that break the law, violate company policies, or could be unethical behaviour.")}
              </p>
            </div>
          </div>
          )
        } else {
              return (<Link
                to={route}
                className="d-flex mt-5 option"
                key={moduleKey}
                onClick={() => this.handleModule(moduleKey, this.props.token)}
              >
                <div>
                  <div className="circle">
                    <div className="second-circle">
                      <p className="mb-0">Whistleblowing</p>
                    </div>
                  </div>
                </div>{" "}
              </Link>)
        }
      case "cm":
          if(type == "third-party") {
            return (
            <div style={{maxWidth: '306px'}} className="d-flex flex-column justify-content-center align-items-center">
            <div className="mb-5">
              <img src={ComplaintIcon} width={120} height={120} />
            </div>
            <div className="mb-5">
              <Button disabled={true} onClick={() => this.handleModule(moduleKey, this.props.authToken)} color="primary" size="lg" className="text-white fw-bolder">
                {this.props.t("Complaint")}
              </Button>
            </div>
            <div>
              <p>
                {this.props.t("A complaint is a statement or expression of dissatisfaction or a grievance. In a workplace, a complaint might be about poor quality of products and services, unfair treatment, unsafe work conditions, or poor management.")}
              </p>
            </div>
          </div>
            )
          } else {
              return (<Link
                to={route}
                className="d-flex mt-5 option"
                key={moduleKey}
                onClick={() => this.handleModule(moduleKey, this.props.token)}
              >
                <div>
                  <div className="circle">
                    <div className="second-circle">
                      <p className="mb-0">Complaint</p>
                    </div>
                  </div>
                </div>{" "}
              </Link>)
          }
      default:
        return null;   
  };
}

  handleSetModuleOnTheServer = async (module, token) => {
    try {
      await axios.post(
        `${AUTH_API_DOMAIN_URL}module.save`,
        {
          module: module,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log("error while setting module:", error);
    }
  };

  renderThirdPartyView() {
    return this.state.isDesktop ? (
      <Container fluid>
        <div className="start-page-third-party">
          <p className="start-page-title text-primary">
            {this.props.t("What would you like to report?")}
          </p>

          <div className="options" style={{marginBottom:'8rem', flexWrap:'wrap'}}>
            {this.props.organization.modules &&
              this.props.organization.modules.filter(module => module.hasModule || module.name === 'gdpr').map((i) => {
                return this.handleRenderModuleBox(
                  i.name,
                  "third-party"
                );
              })}
          </div>
        </div>
      </Container>
    ) : (
      <Container fluid>
        <div className="start-page-third-party">
          <p className="start-page-title">
            {this.props.t("What would you like to report?")}
          </p>

          <Row className="options" style={{margin: "0 1rem"}}>
            {this.props.organization.modules &&
              this.props.organization.modules.filter(module => module.hasModule || module.name === 'gdpr').map((i) => (
                <Col key={i.name} xs={12} md={6} >
                  {this.handleRenderModuleBox(
                    i.name,
                    "third-party"
                  )}
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    );
  }

  renderAdminView() {
    const { t } = this.props;
    const route =   this.userUtils.getUserHomeRoute(this.props.user) === "/admin"
          ? `${this.userUtils.getUserHomeRoute(this.props.user)}/data-protection/incident-register?tab=all`
          : this.userUtils.getUserHomeRoute(this.props.user);
    return (
      <Fragment>
        <div className="pt-sm-5 background">
          <Container className="start-page-admin">
            <Row className="logo-container">
              <Col lg={10}>
                <Row>
                  <Col lg={11} className="logos">
                    <img src={logo} className="logo diss-co-logo" alt="logo" />
                    {this.props.organization.secondaryLogo ? (
                      <img
                        src={this.props.organization.secondaryLogo}
                        className="logo"
                        alt="logo"
                      />
                    ) : (
                      <p
                        className="ms-4 pt-3"
                        style={{ color: "#fff", fontSize: "2em" }}
                      >
                        {t("Logo")}
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col  style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                <LanguageDropdown />
                <i className="ri-shut-down-line align-middle me-1" style={{fontSize:'36px',marginLeft:'20px', cursor: 'pointer'}} onClick={()=>(this.props.logoutUser())}></i>
              </Col>
            </Row>
            {/* section one */}
            <Row className="justify-content-between section-one">
              <Col xs={6}>
                <div>
                  <p className="title">
                    {t("Welcome To The")} <br /> {t("Smart Integrity Platform")}
                  </p>

                  <p className="sub-title">
                    {this.props.t("Access to your modules!")}
                  </p>
                </div>
                <div className="d-flex mt-5 options">
                  {/* {this.props.organization.modules &&
                    this.props.organization.modules.filter(module => module.hasModule || module.name === 'gdpr').map((i) => {
                      return this.handleRenderModuleBox(i.name, "admin");
                    })} */}
            <Link
                to={route}
                className="d-flex mt-5 option"
                key={"wb"}
                onClick={() => this.handleModule("wb", this.props.token)}
              >
                <div>
                  <div className="circle">
                    <div className="second-circle">
                      <p className="mb-0">GDPR</p>
                    </div>
                  </div>
                </div>{" "}
              </Link>
                </div>
              </Col>
              <Col xs={6} className="d-flex justify-content-end relative">
                <div className="gif-container z-0">
                  <img
                    style={{
                      width: "200px",
                      transform: "rotate(120deg)",
                      top: 0,
                    }}
                    alt="section img"
                    src={secondCanvas}
                  />
                </div>
              </Col>
            </Row>
            {/* section two */}
            {/* <Row className="d-flex justify-content-between mt-10">
              <Col xs={6} className="d-flex flex-column ">
                <p
                  className=""
                  style={{
                    color: "#d73cbe",
                    fontSize: "32px ",
                    fontWeight: "700",
                  }}
                >
                  Pursue the challenges of compliance!
                </p>

                <div className="second-section">
                  <img src={thirdCanva} className="third-canva" />
                  <img src={fourthCanva} className="fourth-canva" />
                </div>
              </Col>
              <Col xs={6} className=" align-items-center mt-5">
                <p className="text-section-2">
                  Explore the innovation with more features and modules!
                </p>
                <div className="d-flex mt-5">
                  <div className="circle">
                    <div className="second-circle text-center">
                      <p className="text-center">
                        Whisper for
                        <br /> AI based voice report processing
                      </p>
                    </div>
                  </div>
                  <div className="circle ml-4">
                    <div className="second-circle">
                      <p className="text-center">
                        AI for <br />
                        Risk Management
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}

            {/* Second Section */}
            {/* <Row className='justify-content-between mt-10 align-items-center thirt-section'>
							<Col xs={6} className='d-flex flex-column justify-content-end'>
								<p className='title'>Software News!</p>
								<p className='sub-title' style={{maxWidth: "85%"}}>
									We are continuously working on our products and would like to present the latest updates and products to you.
								</p>

								<Row>
									<Col sx={6} md={6} lg={6}>
										<a
											className='button d-flex align-items-center justify-content-center w-100'
											// href={SecurityFAQ}
											href='#'
											// download='Security-FAQ-PDF-document'
											target='_blank'
											rel='noreferrer'
										>
											More Information
										</a>
									</Col>
								</Row>
							</Col>
							<Col sx={6}>
								<img className='fifth-canva' src={SoftwareNews} alt='section img' />
							</Col>
						</Row> */}

            {/* section three */}
            <Row className="justify-content-between mt-10 align-items-center thirt-section">
              <Col xs={6} className="d-flex flex-column justify-content-end">
                <p className="title"></p>
                <p className="sub-title" style={{ maxWidth: "80%" }}>
                  {t(
                    "Please download here the latest security documents. We ensure the safety and security of your data."
                  )}
                </p>

                <Row>
                  <Col sx={6} lg={4}>
                    <a
                      className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                      href={SecurityFAQ}
                      download="Security-FAQ-PDF-document"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Security FAQ")}
                    </a>
                    <a
                      className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                      // href={ISOCertificate}
                      href="https://www.hetzner.com/de/unternehmen/zertifizierung"
                      download="ISO-Certificate-PDF-document"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        {t("Data Center")} <br></br> {t("ISO 27001")}
                      </span>
                    </a>
                  </Col>

                  <Col sx={6} lg={4}>
                    <a
                      className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                      href={ISOCertificate}
                      download="ISO-Certificate-PDF-document"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("ISO 27001")}
                    </a>
                    <a
                      className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                      // href={ISOCertificate}
                      href="https://www.hetzner.com/assets/downloads/SoA.pdf"
                      download="Data-Center-SoA-document"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{t("Data Center SoA")}</span>
                    </a>
                  </Col>

                  <Col sx={6} lg={4}>
                    <a
                      className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                      href={PenTest}
                      download="PEN-TEST-PDF-document"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Pen Test")}
                    </a>
                    <a
                      className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                      href={TOM}
                      download="TOM-PDF-document"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("TOM")}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col sx={6}>
                <img
                  className="fifth-canva ms-5 ps-5"
                  src={DataSecurity}
                  alt="section img"
                />
              </Col>
            </Row>

            {/* section four */}
            {/* <Row className='justify-content-between mt-10 section-four'>
							<Col xs={12} className='d-flex flex-column justify-content-end'>
								<p className='title'>Discover the Future of Compliance: Join Our Webinars and Events!</p>
								<p className='sub-title'>
									In today's rapidly evolving business landscape, staying compliant with ever-changing regulations is more critical than ever. Join our webinars
									and meet us on the next events to explore the latest tech trends in compliance and gain invaluable insights into how technology is reshaping
									the compliance landscape.
								</p>

								<Row>
									<Col xs={4}>
										<div className='box-container'>
											<p className='title'>Valencia tech summit</p>
											<p className='sub-title'>26 - 27 October in Valencia, Spain</p>
											<Button className='button'>Learn More</Button>
										</div>
									</Col>

									<Col xs={4}>
										<div className='box-container'>
											<p className='title'>Venture.log logistic hub</p>
											<p className='sub-title'>9 November in Hamburg, Germany</p>

											<Button className='button'>Learn More</Button>
										</div>
									</Col>

									<Col xs={4}>
										<div className='box-container'>
											<p className='title'>Web summit german park</p>
											<p className='sub-title '>14 - 16 November in Lissabon, Portugal</p>

											<Button className='button'>Learn More</Button>
										</div>
									</Col>
								</Row>
							</Col>
						</Row> */}

            {/* section five */}

            {/*section six*/}
            <Row className="justify-content-between mt-10 align-items-center thirt-section">
              <Col xs={6} className="d-flex flex-column justify-content-end">
                <p className="title"></p>
                <p className="sub-title" style={{ maxWidth: "81%" }}>
                  {t(
                    "We understand that technology issues can be frustrating, but you're in good hands. Our team of dedicated technical experts is here to assist you in resolving any tech-related challenges you may encounter."
                  )}
                </p>
                <a
                  className="button d-flex align-items-center justify-content-center"
                  href="mailto: support@diss-co.tech"
                >
                  {t("Contact Support")}
                </a>
              </Col>
              <Col sx={6}>
                <img
                  className="ms-5 ps-5"
                  src={CustomerSupport}
                  alt="section img"
                />
              </Col>
            </Row>

            <Row className="justify-content-between align-items-center thirt-section">
              <Col xs={8} className="d-flex flex-column justify-content-end">
                <p className="title">{t("Contact Us")}</p>
                <p className="sub-title fw-700">
                  {t(
                    "DISS-CO® GmbH (Digital Solutions & Services for Compliance)"
                  )}
                </p>
                <p className="fw-700 fs-18">
                  {t("Winterhuder Weg 29")} <br /> {t("22085 Hamburg")} <br />
                  Germany
                </p>

                <p className="fw-700 fs-28 m-0">{t("Phone")}</p>
                <p className="fs-18">+49 (0)40-226 392 51-0</p>
                <p className="fw-700 fs-28 m-0">{t("Email")}</p>
                <p className="fs-18">info@diss-co.tech</p>
                <p className="fw-700 fs-28 m-0">{t("Technical Support")}</p>
                <p className="fs-18">support@diss-co.tech</p>
                <a
                  className="fs-20"
                  href="https://diss-co.tech/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("General Terms and Conditions")}
                </a>
                <a
                  className="fs-20"
                  href="https://diss-co.tech/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Data Processing Agreement")}
                </a>
                <a
                  className="fs-20"
                  href="https://diss-co.tech/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Privacy Policies")}
                </a>
                <p className="fw-700 sub-title mt-5"> </p>
                <div className="d-flex">
                  <a
                    className="follow-links"
                    href="https://www.linkedin.com/company/diss-co"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                  <a
                    className="follow-links"
                    href="https://www.xing.com/pages/diss-co"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Xing
                  </a>
                  <a
                    className="follow-links"
                    href="https://www.youtube.com/channel/UCH65RaR2Kbn_-djmrIlE-xQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Youtube
                  </a>
                  <a
                    className="follow-links"
                    href="https://www.facebook.com/profile.php?id=100089887982972"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </div>
              </Col>
              <Col sx={4}>
                <div className="second-section">
                  {/* <img src={thirdCanva} className='seven-canva' alt='section img' />
									<img src={fourthCanva} className='eight-canva' alt='section img' /> */}
                  <img
                    src={Contact}
                    className=""
                    style={{ width: "80%", height: "80%" }}
                    alt="section img"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }

  render() {
    return this.state.isThirdParty
      ? this.renderThirdPartyView()
      : this.renderAdminView();
  }

//   componentDidUpdate = () => {
// 	const screenWidth = window.innerWidth;
// 	console.log(screenWidth, this.state.isDesktop)
// 	// if(screenWidth > 768 && !this.state.isDesktop) {
// 	// 	this.setState({isDesktop: true})
// 	// }
//   }

  componentDidMount = async () => {
    document.title = "Start Page | SIP";
    const activeModules = this.props.organization.modules.filter(
      (m) => m.hasModule
    );
    if (activeModules.length === 1) {
      this.handleModule(activeModules[0].name, this.props.token);

      if (this.props.match.path.split("/").includes("third-party")) {
        this.props.history.push("/third-party/home");
      }
    }
	const screenWidth = window.innerWidth;
	if(screenWidth > 768 && !this.state.isDesktop) {
		this.setState({isDesktop: true})
	}
  };
}

const mapStatetoProps = (state) => {
  return {
    user: state.Login.user,
    token: state.Login.token,
    organization: state.Organization,
    loginErrFlag: state.Login.loginErrFlag,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {
    logoutUser
  })(ModuleSelection))
);
