import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Container, Row, Col } from "reactstrap";

import UrlUtils from "../../../services/utils/UrlUtils";
import UserUtils from "../../../services/utils/UserUtils";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Components
import { onGetInfoOrganizationSuccess } from "../../../store/organization/actions";
import {  errorNotification, loginUserSuccessful, apiError, appLoadStatusChanged, changePreloader } from "../../../store/actions";
import { changeWorkspace } from "../../../store/workspace/actions";
import { API_URL_GRC_DASHBOARD_CUSTOMIZATION, API_URL_GRC_DASHBOARD_SUBMITTED_CASES, API_URL_GRC_DASHBOARD_TASKS_STATUS, API_URL_GRC_DASHBOARD_RISKS_TASKS, API_URL_GRC_DASHBOARD_STATUS, API_URL_ADMIN_DASHBOARD, API_URL_ADMIN_DASHBOARD_PARTNER_LIST, API_URL_GET_USER_DETAIL, API_URL_VERIFY_ORGANIZATION, CM_API_URL_ADMIN_DASHBOARD, CM_API_URL_ADMIN_DASHBOARD_PARTNER_LIST, CM_API_URL_GET_USER_DETAIL } from "../../../common/constants";

import { checkExistModule } from "../../../helpers/module_helper";

import { Card, CardBody } from "reactstrap";

import PieChart from "./Charts/PieChart";
import Apaexline from "./Charts/Apaexline";
import DashedLine from "./Charts/DashedLine";
import Chart4 from "./Chart4";
import Chart5 from "./Chart5";
import RadarChart from "./Charts/RadarChart";
import ModuleContext from "../../../contexts/ModuleContext";

class Cockpit extends Component {
  static contextType = ModuleContext;

  constructor(props) {
		super(props);

		this.urlUtils = new UrlUtils();
		this.userUtils = new UserUtils();

		this.state = {
			headers: {
				headers: {
					Authorization: `Bearer ${this.props.token}`,
				},
			},
			breadcrumbItems: [
				{ title: "SIP", link: "/" },
				{ title: this.props.t("Cockpit Dashboard"), link: "#" },
			],

			data1: [],
			data2: [],
			data3: {},
			data4: [],
			data5: [],

			filterQuery: "",
			totalCases: [],
			channels: [],
			lastChanges: [],
			listCases: [],
			statuesCases: [],
			categories: [],
			dashboard: [],
			countries: [],
			newListTypes: {},
			topCountries: null,
			riskRate: [],
			typesByDate: [], // This is related to DamageAnalytics chart
			tasksByDate: [],
			processingTime: [],
			casesAssignedToMe: [],
			knobChart: [],

			partnerId: null,
			filterByDate: null,
			filterByPartner: null,
			filterByOrganization: null,
			filterByOrganizationOptions: [],

			partnerOptions: [
				{
					value: "all",
					label: (
						<>
							<span>{this.props.t("All")}</span>
						</>
					),
				},
				{
					value: "own",
					label: (
						<>
							<span>{this.props.t("My own company")}</span>
						</>
					),
				},
			],

			url: this.urlUtils.getUrlParam(),

			orgId: this.urlUtils.getOrganizationId(),
			orgName: this.urlUtils.getOrganizationName(),
			orgUrl: this.urlUtils.getUrlParam(),
			date: null,
			partner: null,
			organization: null,
		};
		this.apiError = this.props.apiError.bind(this);
		this.changePreloader = this.props.changePreloader.bind(this);
		this.changeWorkspace = this.props.changeWorkspace.bind(this);
		this.loginUserSuccessful = this.props.loginUserSuccessful.bind(this);
		this.appLoadStatusChanged = this.props.appLoadStatusChanged.bind(this);
		this.handleGetInfoOrganizationSuccess = this.props.handleGetInfoOrganizationSuccess.bind(this);
	}

	getKeyByValue(object, value) {
		return Object.keys(object).find((key) => object[key] === value);
	}

	fetchUserData = async (id) => {
		if (this.props.token) {
			return await axios
				.post(
					this.context.selectedModule === "wb" ? API_URL_GET_USER_DETAIL : CM_API_URL_GET_USER_DETAIL,
					{
						organizationId: id,
					},
					{
						headers: {
							Authorization: `Bearer ${this.props.token}`,
						},
					}
				)
				.then((response) => {
					const data = response.data.data;
					if (response.data.data.settings?.direction) {
						localStorage.setItem("lang_dir", response.data.data.settings.direction);
					}
					if (true) {
						this.loginUserSuccessful({
							...data,
						});

						this.changeWorkspace(this.userUtils.getWorkspace(data));

						this.appLoadStatusChanged(true);

						this.apiError(null);
					}
				})
				.catch((err) => {
					console.log("fetchUserData___err: ", err);
				});
		} else {
			console.log("No token avalaibe");
		}
	};

	fetchOrganizationData = async (id, url) => {
		await axios
			.post(
				API_URL_VERIFY_ORGANIZATION,
				{
					id,
					url: decodeURI(url),
				},
				this.state.headers
			)
			.then((res) => {
				const data = res.data.data;

				const org = {
					id,
					url: decodeURI(url),
					name: data.name,
					title_channel_report_tp: data.title_channel_report_tp,
					logo: data.logo,
					intro: data.intro,
					modules: data.modules,
					translations: data.translations,
					introTranslations: data.translations,
					isDemo: !!data.is_demo,
					isChat: data.setting ? !!data.setting.chat : false,
					isMeeting: data.setting ? !!data.setting.meeting : false,
					isAnonymously: data.setting ? !!data.setting.anonymously : false,
					isConfidentially: data.setting ? !!data.setting.confidentially : false,
					isElearning: data.setting ? !!data.setting.elearning : false,
					status: data.status,

					primaryFont: data.font_family,
					primaryColor: data.primary_color,
					fontColor: data.font_color,

					azureLogin: data.isAdSet, // related to microsoft azure login

					path: null,
					url: data.orgUrl,

					primaryLogo: data.primary_logo,
					primaryLogoSize: data.primary_logo_size,
					secondaryLogo: data.secondary_logo,
					secondaryLogoSize: data.secondary_logo_size,

					languages: data.languages,
				};

				this.handleGetInfoOrganizationSuccess(org);
			})
			.catch((err) => {
				console.log("fetchOrganizationData___err: ", err);

				if (err.response) {
					const data = err.response.data;

					this.props.errorNotification({
						code: data.err || "unknown_error",
						message: this.props.t(data.message || data.title),
					});
				}
			});
	};

	onChangeFilterOptions = (e, key) => {
		const { date, partner, organization, orgIsAll } = this.state;

		this.setState(
			{
				...this.state,
				date: key === "filterByDate" && e ? e.value : date,
				partner: key === "filterByPartner" ? e.value : partner,
				organization: key === "filterByOrganization" ? e.value : organization,
				orgIsAll: key === "filterByOrganization" ? (e.value === "all" ? true : false) : orgIsAll,
			},
			() => {
				this.fetchDashboardList(this.state.date, this.state.partner, this.state.organization);
				if (key === "filterByOrganization") {
					if (e.value === "all") {
						// this.setState({ orgIsAll: true });
						localStorage.setItem("filterBy", "all");
					} else if (e.value === "own") {
						localStorage.setItem("filterBy", "own");
					} else {
						// this.setState({ orgIsAll: false });
						localStorage.removeItem("filterBy");
					}

					let orgId = e.id;
					let orgUrl = e.domain;
					let orgName = e.name.replace(/\s+/g, "-").toLowerCase();

					this.setState(
						{
							orgId,
							orgUrl,
							orgName,
							partnerId: organization,
						},
						() => {
							this.fetchUserData(orgId).then(() => {
								localStorage.setItem("org", this.urlUtils.getAppRootPath(orgId, orgName));
								this.fetchOrganizationData(orgId, orgUrl);
							});
						}
					);
				}

				this.setState(
					{
						[key]: e,
					},
					() => {
						// this.fetchDashboardList(date, partner, organization);
					}
				);
			}
		);
	};

	onChangeRedirectPage = (e) => {
		console.log("will redirect to: ", e);

		let organization = e.value;

		this.setState({
			partnerId: organization,
		});
	};

	rendeDateFilterOptions = () => {
		// TODO: add filter by date selectbox
		const filterOptions = [
			{
				value: "last_year",
				label: this.props.t("Last year"),
			},
			{
				value: "this_year",
				label: this.props.t("This year"),
			},
			{
				value: "last_three_month",
				label: this.props.t("Last three month"),
			},
			{
				value: "this_month",
				label: this.props.t("This month"),
			},
		];

		return (
			<Col lg={4}>
				<Select required isClearable={true} name="sortByDate" classNamePrefix="select2-selection" options={filterOptions} onChange={(e) => this.onChangeFilterOptions(e, "filterByDate")} placeholder={this.props.t("Filter by date")} />
			</Col>
		);
	};

	renderPartnerFilterOptions = () => {
		if (this.props.user && !this.props.user.roles.includes("ROLE_PARTNER")) {
			return;
		}

		const partnerOptions = [
			{
				value: "all",
				label: (
					<>
						<span>{this.props.t("All")}</span>
					</>
				),
			},
			{
				value: "own",
				label: (
					<>
						<span>{this.props.t("My own company")}</span>
					</>
				),
			},
		];

		return (
			<Col lg={4}>
				<Select name="sortByPartner" classNamePrefix="select2-selection" options={partnerOptions} onChange={(e) => this.onChangeFilterOptions(e, "filterByPartner")} placeholder={this.props.t("Filter by")} value={this.state.filterByPartner} />
			</Col>
		);
	};

	renderOrganizationFilterOptions = () => {
		if (this.props.user && !this.props.user.roles.includes("ROLE_PARTNER")) {
			return;
		}

		let options = [];

		// console.log(" this.props.user.organization ************ ", this.props.user.org);

		let orgUrl = this.props.user && this.props.user.orgUrl;

		if (this.props.user) {
			options = [
				{
					label: this.props.t("All"),
					value: "all",

					id: this.props.user.organization.id,
					name: this.props.user.organization.name,
					code: this.props.user.organization.country_code,
					domain: orgUrl,
				},
				{
					label: this.props.t("My own company"),
					value: "own",

					id: this.props.user.organization.id,
					name: this.props.user.organization.name,
					code: this.props.user.organization.country_code,
					domain: orgUrl,
				},
			];
		}

		if (this.state.filterByOrganizationOptions.length) {
			this.state.filterByOrganizationOptions.map((item) => {
				options.push({
					label: item.label,
					value: item.value,

					id: item.id,
					name: item.name,
					code: item.code,
					domain: item.domain,
				});
			});
		}

		// console.log(options);

		// console.log("//// ", null === localStorage.getItem("filterBy"));

		let selectedOption = options.filter((option) => option.value === localStorage.getItem("filterBy") || (option.id === parseInt(this.urlUtils.getOrgId()) && this.props.user.organization?.id !== parseInt(this.urlUtils.getOrgId())));

		return (
			<Col lg={4}>
				<Select name="sortByOrganization" classNamePrefix="select2-selection" options={options} onChange={(e) => this.onChangeFilterOptions(e, "filterByOrganization")} placeholder={this.props.t("Filter by organization")} value={selectedOption} />
			</Col>
		);
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
						<Row>
							<Col xl={8}>
								<Card>
									<CardBody>
										<h4 className="card-title mb-4">Some titles goes here</h4>
										<DashedLine data={this.state.data1} />
									</CardBody>
								</Card>

								<Card>
									<CardBody>
										<h4 className="card-title mb-4">Some titles goes here</h4>
										<Apaexline data={this.state.data2} />
									</CardBody>
								</Card>

								<Card>
									<CardBody>
										<h4 className="card-title mb-4">SPIK-1896</h4>
										<Chart5 />
									</CardBody>
								</Card>
							</Col>

							<Col xl={4}>
								<Card>
									<CardBody>
										<h4 className="card-title mb-4">Some titles goes here</h4>
										<PieChart data={this.state.data4} />
									</CardBody>
								</Card>

								<Card>
									<CardBody>
										<h4 className="card-title mb-4">Some title goes here</h4>

										<Chart4 data={this.state.data3} />
									</CardBody>
								</Card>

								<Card>
									<CardBody>
										<h4 className="card-title mb-4">SPIK-1972</h4>
										<RadarChart data={this.state.data5} />
									</CardBody>
								</Card>
							</Col>

							<Col xl={12}>
								<Card>
									<CardBody>
										<h4 className="card-title mb-4">SPIK-1897</h4>
										<p>List of all risks</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	fetchPartnerList = () => {
		if (this.props.user && this.props.user.roles.includes("ROLE_PARTNER")) {
			axios
				.get(this.context.selectedModule === "wb" ? API_URL_ADMIN_DASHBOARD_PARTNER_LIST : CM_API_URL_ADMIN_DASHBOARD_PARTNER_LIST, this.state.headers)
				.then((response) => {
					// console.log("fetch partner list response: ", response);
					const data = response.data.data;

					if (data.length) {
						const filterByOrganizationOptions = [];

						data.map((item) => {
							filterByOrganizationOptions.push({
								value: item.id,
								label: item.name,

								id: item.id,
								name: item.name,
								code: item.country_code,
								domain: item.domain,
							});
						});

						this.setState({
							filterByOrganizationOptions,
						});
					}
				})
				.catch((error) => {
					console.log("error: ", error);

					if (error.response) {
						const data = error.response.data;

						this.props.errorNotification({
							code: data.error || "unknown_error",
							message: this.props.t(data.message || data.title),
						});
					} else if (error.error) {
					}
				});
		}
	};

	fetchDashboardList(date = null, partner = null, organization = null) {
		if (this.props.user && this.props.user.roles.includes("ROLE_PARTNER")) {
			if (this.props.user.organization.id === parseInt(this.urlUtils.getOrgId())) {
				if (organization === "own") {
					partner = "own";
				} else if (localStorage.getItem("filterBy") !== "own") {
					partner = "all";
				}
				// partner = organization === "all" ? "all" : null || organization === "own" ? "own" : null;
			}
		}

		// console.log("--------------- ", partner);
		// console.log("Date", date, "Patner", partner)
		this.setState({
			filterQuery: `date=${date}&partner=${partner}`,
		});

		return axios
			.get(`${this.context.selectedModule === "wb" ? API_URL_ADMIN_DASHBOARD : CM_API_URL_ADMIN_DASHBOARD}?date=${date}&partner=${partner}`, this.state.headers)
			.then((response) => {
				const data = response.data.data;
				const { statuses, lastCases, changes, categories, types, countries, allCountries, topCountries, riskRate, sources, typesByDate, tasksByDate, investigationTime, assignedToMe } = data;
				const availableTopCountries = {};

				for (const [key, value] of Object.entries(topCountries)) {
					if (Number(value) !== 0) {
						availableTopCountries[key] = value;
					}
				}

				const newChannels = [];
				const newRiskRate = [];
				const newMiniWidgetsList = [];
				const newListlastChanges = [];
				const newListCases = [];
				const newListStatusesCases = [];
				const newListcategories = [];
				const newListTypes = {
					percents: [],
					titles: [],
				};

				// types

				for (const item in types) {
					newListTypes.percents.push(parseInt(types[item].percent));
					newListTypes.titles.push(this.props.t(types[item].title));
				}

				// TODO: just for testing. This must update for new dashboard.
				newMiniWidgetsList.push({
					amount: data.totalCases,
					percent: 100,
					title: "Total",
					icon: "ri-stack-line",
					displayChart: false,
				});

				// statuses cases
				for (const item in statuses) {
					newListStatusesCases.push({
						amount: statuses[item].amount,
						percent: statuses[item].percent,
						title: this.props.t(statuses[item].title),
					});

					// TODO: just for testing. This must update for new dashboard. ["open", "new", "pending delete", "assign analyst", "delete"]
					if (!["assigned", "deleted"].includes(statuses[item].title)) {
						let title = "";
						// console.log(statuses[item].title);
						if (statuses[item].title === "closed") {
							title = "closed";
						} else if (statuses[item].title === "in progress") {
							title = "In Progress";
						} else if (statuses[item].title === "new") {
							title = "New";
						} else if (statuses[item].title === "pending deletion") {
							title = "Pending Deletion";
						} else {
							title = statuses[item].title;
						}

						newMiniWidgetsList.push({
							amount: statuses[item].amount,
							percent: statuses[item].percent,
							title: this.props.t(title),
							icon: null,
							displayChart: true,
						});
					}
				}

				// Last Changes
				for (const item in changes) {
					newListlastChanges.push({
						date: data.changes[item],
						name: data.changes[item],
						caseId: data.changes[item],
					});
				}

				// last Cases
				for (const item in lastCases) {
					newListCases.push({
						description: lastCases[item].description,
						category: this.props.t(lastCases[item].category),
						created_at: lastCases[item].created_at,
						analyst_assigned: lastCases[item].analyst_assigned_name,
						status: this.props.t(lastCases[item].status),
						case_id: lastCases[item].case_id,
					});
				}

				for (const item in categories) {
					newListcategories.push({
						amount: categories[item].amount,
						title: this.props.t(categories[item].title),
						percent: categories[item].percent,
					});
				}

				// console.log("riskRate: ", riskRate)

				for (const item in riskRate) {
					newRiskRate.push({
						color: riskRate[item].color,
						amount: riskRate[item].amount,
						title: riskRate[item].title,
						percent: riskRate[item].percent,
					});
				}

				// Channels
				for (const item in sources) {
					const title = this.props.t(sources[item].title);
					newChannels.push({
						amount: sources[item].amount,
						title: title,
						percent: sources[item].percent,
					});
				}

				// typesByDate
				for (const item in typesByDate) {
					let data = [];
					let categories = [];

					for (const i in typesByDate[item]) {
						data.push(typesByDate[item][i]);
						categories.push(i);
					}
				}

				// tasksByDate
				for (const item in tasksByDate) {
					let data = [];
					let categories = [];

					for (const i in tasksByDate[item]) {
						data.push(tasksByDate[item][i]);
						categories.push(i);
					}
				}

				this.setState(
					{
						statuesCases: newListStatusesCases,
						lastChanges: newListlastChanges,
						listCases: newListCases,
						totalCases: newMiniWidgetsList,
						categories: newListcategories,
						newListTypes: newListTypes,
						countries: allCountries,
						topCountries: availableTopCountries,
						riskRate: newRiskRate,
						channels: newChannels,
						processingTime: investigationTime,
						casesAssignedToMe: assignedToMe,
						typesByDate,
						tasksByDate: tasksByDate,
					},
					() => {
						if (this.props.user && this.props.user.roles.includes("ROLE_PARTNER")) {
							if (this.props.user.organization.id === parseInt(this.urlUtils.getOrgId())) {
								if (localStorage.getItem("filterBy") === "own") {
									localStorage.removeItem("filterBy");
								}
							}
						}
					}
				);
			})
			.catch((error) => {
				if (error.response) {
					const data = error.response.data;

					// this.props.errorNotification({
					// 	code: data.error || "unknown_error",
					// 	message: this.props.t(data.message || data.title || data.error),
					// });

					if (data.error === "you can't access to partner organizations.") {
						this.setState({
							filterByPartner: null,
						});
					}
				} else if (true) {
					console.log("error: no response");
				}
			});
	}

	fetchDashboardWidgets = () => {
		// this.fetchDashboardWidgetsList();
		this.fetchDashboardWidget1();
		this.fetchDashboardWidget2();
		this.fetchDashboardWidget3();
		this.fetchDashboardWidget4();
	};

	fetchDashboardWidgetsList = () => {
		// SPIK-1962

		return axios
			.get(API_URL_GRC_DASHBOARD_CUSTOMIZATION, this.state.headers)
			.then((response) => {
				const data = response.data.data[this.context.selectedModule];

				console.log("Orders and Conditions: ", data);
			})
			.catch((error) => {
				console.log("catch.error: ", error);
			});
	};

	fetchDashboardWidget1 = () => {
		return axios
			.get(API_URL_GRC_DASHBOARD_SUBMITTED_CASES, this.state.headers)
			.then((response) => {
				const data = response.data.data.data[this.context.selectedModule];

				this.setState({ data1: data });
			})
			.catch((error) => {
				console.log("catch.error: ", error);
			});
	};

	fetchDashboardWidget2 = () => {
		return (
			axios
				.get(API_URL_GRC_DASHBOARD_TASKS_STATUS, this.state.headers)
				// .then((response) => {

				// 	let data = response.data.data[this.context.selectedModule];
				// 	let { moduleName, totalTask, ...outputData } = data;

				// 	this.setState({
				// 		data3: outputData,
				// 	});
				// })
				.then((response) => {
					let data = response.data.data[this.context.selectedModule];
					let { moduleName, totalTask, ...outputData } = data;

					this.setState({
						data3: outputData,
					});
					// if (Array.isArray(outputData)) {
					// 	let mappedData = outputData.map((item) => {
					// 		return item;
					// 	});

					// 	this.setState({
					// 		data3: mappedData,
					// 	});
					// } else {
					// 	console.error("Data is not an array:", outputData);
					// }
				})
				.catch((error) => {
					console.log("catch.error: ", error);
				})
		);
	};

	fetchDashboardWidget3 = () => {
		return axios
			.get(API_URL_GRC_DASHBOARD_RISKS_TASKS, this.state.headers)
			.then((response) => {
				const data = response.data.data[this.context.selectedModule];

				const { moduleName, ...outputData } = data;

				this.setState({
					data2: outputData,
				});
			})
			.catch((error) => {
				console.log("catch.error: ", error);
			});
	};

	fetchDashboardWidget4 = () => {
		return axios
			.get(API_URL_GRC_DASHBOARD_STATUS, this.state.headers)
			.then((response) => {
				const data = response.data.data;
				const { moduleName, totalCases, ...outputData } = data.status[this.context.selectedModule];

				this.setState({
					data4: outputData,
				});
			})
			.catch((error) => {
				console.log("catch.error: ", error);
			});
	};

	componentDidMount() {
		checkExistModule(this);

		// this.fetchDashboardList();
		// this.fetchPartnerList();

		this.fetchDashboardWidgets();
	}

	componentDidUpdate(prevProps) {
		if (this.props.t !== prevProps.t) {
			// this.fetchPartnerList();
			// this.fetchDashboardList();
			// this.fetchDashboardWidgets();
		}
	}
}

const mapStatetoProps = (state) => {
	const { user, token } = state.Login;
	const { App, Feature, CMFeature } = state;
	const organization = state.Organization;
	const activeModule = localStorage.getItem("module");
	return {
		token,
		user,
		App,
		Feature: activeModule === "wb" ? Feature : activeModule === "cm" ? CMFeature : null,

		organization,
	};
};

const mapDispachToProps = (dispatch) => {
	return {
		apiError: (error) => dispatch(apiError(error)),
		appLoadStatusChanged: (status) => dispatch(appLoadStatusChanged(status)),
		errorNotification: (message) => dispatch(errorNotification(message)),
		changePreloader: (val) => dispatch(changePreloader(val)),
		changeWorkspace: (workspace) => dispatch(changeWorkspace(workspace)),
		loginUserSuccessful: (user) => dispatch(loginUserSuccessful(user)),
		handleGetInfoOrganizationSuccess: (val) => dispatch(onGetInfoOrganizationSuccess(val)),
	};
};

export default withNamespaces()(connect(mapStatetoProps, mapDispachToProps)(Cockpit));
