import { withNamespaces } from "react-i18next";
import SearchNews from "./search";
import { Button, Col, Row } from "reactstrap";
import Filter from "./Filter";
import ManageKeywordsModal from "../modals/manage-keywords-modal/ManageKeywordsModal";
import CreatingRiskModal from "../modals/create-risk-modal/create-risk-modal";
import { useState } from "react";
import CreateRiskModal from "../../../../../../reporting/Components/RiskComponents/CreateRiskModal";
const TopNewsMenu = ({ t, assetId }) => {
  // Manage Keywords Modal
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // Creating Risk Modal
  const [createRiskModal, setCreateRiskModal] = useState(false);
  const toggleCreateRiskModal = () => setCreateRiskModal(!createRiskModal);


  return (
    <>
      {/* Manage Keywords Modal */}
      <ManageKeywordsModal
        t={t}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
      />

      {/* Create Risk Modal */}
      <CreatingRiskModal
        t={t}
        modal={createRiskModal}
        setModal={setCreateRiskModal}
        toggle={toggleCreateRiskModal}
        assetId={assetId}
      />

      <Row className="mb-2">
        {/* Empty Space */}
        <Col md="6"></Col>

        {/* Components */}
        <Col md="6" sm="12">
          <Row className="pt-3">
            {/* Search */}
            <Col md="3">
              <SearchNews t={t} />
            </Col>
            {/* Filter */}
            <Col md="3">
              <Filter t={t} />
            </Col>
            {/* Manage Keywords */}
            <Col
              md="3"
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "flex-end",
              }}
            >
              <Button
                // className="me-3"
                color="primary"
                onClick={() => setModal(true)}
              >
                {t("Manage Keywords")}
              </Button>
            </Col>

            {/* Create risk */}
            <Col
              md="3"
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "flex-start",
              }}
            >
              <Button
                className="me-3"
                color="primary"
                onClick={() => setCreateRiskModal(true)}
              >
                {t("Create Risk")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default withNamespaces()(TopNewsMenu);
