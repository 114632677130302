import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class FullPageLayout extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }
    
    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
      };

    // componentDidMount(){
    //     let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
    //     currentage = currentage.replaceAll("-" , " ");

    //     document.title =
    //       currentage + " | SIP";
    // }

    componentDidUpdate(prevProps) {
        if(!prevProps.App.error.status && this.props.App.error.status){
          this.props.history.push('/fatal-error')
        }
    }

    render() {
        return <React.Fragment>
            {this.props.children}
        </React.Fragment>;
    }
}

const mapStatetoProps = state => {
    const { App } = state;
    return {
        App
    };
};

export default connect(mapStatetoProps,null)((withRouter(FullPageLayout)));