import React, { memo, useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import {  useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Navigator from "../../../components/navigator";
import { withRouter } from "react-router-dom";
import Tabs from "./components/tabs";
import { useQuery } from "@tanstack/react-query";
import RequestTable from "./RequestTable";
import AddNew from "../data-breach-incident-register"
import {
  GDPR_API_URL_GET_REPORT_LIST,
  GDPR_API_URL_GET_ANALYST_REPORT_LIST,
  GDPR_API_URL_GET_INCIDENT_REPORT_LISTS_COUNT
} from "../../../../../common/constants";
import Details from "./details";
import axios from "axios";

const DataSubjectRequest = (props) => {
  const { t } = props;
  const location = useLocation();
  const history = useHistory();
  const [showDetails, setShowDetails] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);


  const handleFetchCount = useQuery({
    queryKey: ["report-count-data-subject"],
    queryFn: async () => {
      const url = GDPR_API_URL_GET_INCIDENT_REPORT_LISTS_COUNT;
      try {
        const response = await axios.get(`${url}`, {
          params: {
            questionnaire: "data_subject_request"
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        return response.data || [];
      } catch (error) {
        throw new Error("An error occurred while fetching counts.");
      }
    },
  });

  
  useEffect(() => {
    const counts = handleFetchCount?.data?.data;
    setTotalCount(counts?.all);
    setDeletedCount(counts?.deleted);
  }, [handleFetchCount.data]);


  const renderSelectedTabContent = () => {
    const searchParams = new URLSearchParams(location.search);

    switch (searchParams.get("tab")) {
      case "all":
        return (
          <RequestTable 
          onSelected={(selectedData) => setShowDetails(selectedData)}
          type="all"
          assignedToMe={false}
          url={GDPR_API_URL_GET_ANALYST_REPORT_LIST}
          />);
      // case "assigned-to-me":
      //   return (
      //     <RequestTable 
      //     onSelected={(selectedData) => setShowDetails(selectedData)}
      //     type="assigned-to-me"
      //     assignedToMe={true}
      //     url={GDPR_API_URL_GET_ANALYST_REPORT_LIST}
      //     />);
        case "deleted-reports":
          return (
            <RequestTable 
            onSelected={(selectedData) => setShowDetails(selectedData)}
            type="deleted-reports"
            assignedToMe={true}
            status={5}
            url={GDPR_API_URL_GET_ANALYST_REPORT_LIST}
            />);
          // case "reported-by-me":
          //   return ( 
          //   <RequestTable 
          //   onSelected={(selectedData) => setShowDetails(selectedData)}
          //   type="deleted-reports"
          //   assignedToMe={true}
          //   url={GDPR_API_URL_GET_REPORT_LIST}
          //   />);
              
      default:
        return (
          <RequestTable 
          onSelected={(selectedData) => setShowDetails(selectedData)}
          type="all"
          assignedToMe={false}
          url={GDPR_API_URL_GET_ANALYST_REPORT_LIST}
          />);
        
    }
  };

  const handleBack = () => {
    // if(softwareId){
    //     history.goBack()
    //     return;
    // }
   setShowDetails(null);
   return;
  };

  // If it's a details page
  if ( showDetails) {
    return (
      <div className="page-content ">
        <Container fluid>
          <Row>
            <Col lg="12" className="mb-4">
              <Navigator
                backButtonOptions={() => {
                  return {
                    enable: true,
                    onClick: () => handleBack(),
                    title: props.t("Back"),
                    icon: <i className="ri-arrow-left-fill"></i>,
                  };
                }}
                breadCrumbs={[
                  { title: "SIP", link: "/" },
                  {
                    title: "Data Subject Request",
                    link: `/admin/data-protection/data-subject-request`,
                  },
                ]}
              />
            </Col>

            <Col lg="12" className="mb-4">
              <div className="details-content-wrapper">
                {showDetails && showDetails.type!=='newIncident' ? (<Details {...showDetails}/> ):(
                <AddNew />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
     <div className="page-content ">
     <Container fluid>
       <Row>
         <Col lg="12" className="mb-4">
           <Navigator
             backButtonOptions={() => {
               return {
                 //enable: true,
                 onClick: () => handleBack(),
                 title: props.t("Back"),
                 icon: <i className="ri-arrow-left-fill"></i>,
               };
             }}
             breadCrumbs={[
              { title: "SIP", link: "/" },
               {
                 title: "Data Subject Request",
                 link: `/admin/data-protection/data-subject-request`,
               },
             ]}
           />
         </Col>

         <Col lg="12" className="mb-4">
            <div
              className="bg-white"
              style={{
                borderRadius: "7px",
                boxShadow: "0px 0px 4px 0px #4F666E40",
                marginBottom: "40px",
              }}
            >
              <Tabs
                t={t}
                totalCount={totalCount}
                deletedCount={deletedCount}
              />
               {renderSelectedTabContent()}
               
            </div>
          </Col>
       </Row>
     </Container>
   </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {})(memo(DataSubjectRequest)))
);
