import React from "react";

import "./style.scss";

const Switcher = ({isToggled, onToggle, className}) => {
    return (
        <label className={`toggle-switch ${className || ''}`}>
           <input type="checkbox" checked={isToggled} onChange={onToggle} />
            <span className="switch" />
        </label>
    );
};

export default Switcher;