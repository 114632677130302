import LocalStorage from "../LocalStorage";
import {
  LOCAL_STORAGE_KEY_ACCESS_TOKEN,
  LOCAL_STORAGE_KEY_LOCALE,
  LOCAL_STORAGE_KEY_LOGIN_ATTEMPTS_EXPIRED,
  LOCAL_STORAGE_KEY_LOGIN_THIRD_PARTY_ATTEMPTS_EXPIRED,
} from "../constants/LocalStorage";

export default class StorageManager {
  constructor() {
    this.localStorage = new LocalStorage();
  }

  removeAllStorageForLogOut() {
    this.removeAccessTokenFromLocalStorage();
  }

  saveAccessTokenToLocalStorage(accessToken) {
    this.localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, accessToken);
  }

  getAccessTokenFromLocalStorage() {
    return this.localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN) || "";
  }

  removeAccessTokenFromLocalStorage() {
    this.localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
  }

  saveLocaleToLocalStorage(locale) {
    this.localStorage.setItem(LOCAL_STORAGE_KEY_LOCALE, locale);
  }

  getLocaleFromLocalStorage() {
    return this.localStorage.getItem(LOCAL_STORAGE_KEY_LOCALE) || "";
  }

  removeLocaleFromLocalStorage() {
    this.localStorage.removeItem(LOCAL_STORAGE_KEY_LOCALE);
  }

  saveLoginAttemptsExpiredToLocalStorage(attemptsExpired) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEY_LOGIN_ATTEMPTS_EXPIRED,
      attemptsExpired
    );
  }

  getLoginAttemptsExpiredFromLocalStorage() {
    return (
      this.localStorage.getItem(LOCAL_STORAGE_KEY_LOGIN_ATTEMPTS_EXPIRED) || ""
    );
  }

  saveLoginThirdPartyAttemptsExpiredToLocalStorage(attemptsExpired) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEY_LOGIN_THIRD_PARTY_ATTEMPTS_EXPIRED,
      attemptsExpired
    );
  }

  getLoginThirdPartyAttemptsExpiredFromLocalStorage() {
    return (
      this.localStorage.getItem(
        LOCAL_STORAGE_KEY_LOGIN_THIRD_PARTY_ATTEMPTS_EXPIRED
      ) || ""
    );
  }
}
