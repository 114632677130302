import { memo, useEffect, useState } from "react";
import {
  Col,
  Row,
  Label,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Card,
  Tooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import {
  errorNotification,
  successNotification,
} from "../../../../store/actions";
import { connect } from "react-redux";
import "./style.scss";
import { AvField } from "availity-reactstrap-validation";

const FileUploader = (props) => {
  const {
    id,
    title,
    key,
    acceptedFiles,
    userToken,
    defaultValue,
    elRefIdPrefix,
    helpText,
    handleChange,
    isAIFilled
  } = props;

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [dropzonePopoverDisplay, setDropzonePopoverDisplay] = useState(false);

  const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

  const handleAcceptedFiles = (files, id) => {
    const headers = {
      headers: {},
    };

    if (userToken) {
      headers.headers.Authorization = `Bearer ${userToken}`;
    }

    const rawAxios = axios.create();

    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      });

      const formData = new FormData();

      formData.append("file", file);

      rawAxios
        .post(
          `${
            process.env.REACT_APP_GDPR_API
          }/api/report.uploadAttachment`,
          formData,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            const fileData = response.data.data;
            // setUploadedFiles([
            //     ...uploadedFiles,
            //     {
            //         questionId: id,
            //         id: fileData.id,
            //         size: fileData.size,
            //         file: fileData,
            //         name: file.name,
            //         preview: file.preview,
            //         formattedSize: file.formattedSize,
            //     }
            // ]);
            setUploadedFiles((currentFiles) => [
              ...currentFiles,
              {
                questionId: id,
                id: fileData.id,
                size: fileData.size,
                file: fileData,
                name: file.name,
                preview: file.preview,
                formattedSize: file.formattedSize,
              },
            ]);
          } else {
            props.errorNotification({
              code: response.data.error,
              message: props.t(
                "Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support."
              ),
            });
          }
        })
        .catch((error) => {
          props.errorNotification({
            code: error.response.data.error,
            message: props.t(error.response.data.message),
          });
        });
    });
  };

  const handleClickDeleteFiles = (file) => {
    const url = `${process.env.REACT_APP_GDPR_API}/api/attachment/${
      file.id
    }/delete`;

    const headers = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    const deletedFile = {
      file: file.name,
      report_case_id: file.id,
    };

    axios
      .post(url, deletedFile, headers)
      .then(() => {
        setUploadedFiles([
          ...uploadedFiles.filter((f) => {
            return f.id !== file.id;
          }),
        ]);

        props.successNotification({
          code: props.t("Data was deleted successfully"),
          message: props.t("Data was deleted successfully"),
        });
      })
      .catch((error) => {
        props.errorNotification({
          code: error.response.data.error,
          message: props.t(error.response.data.message),
        });
      });
  };

  useEffect(() => {
    if (defaultValue && Array.isArray(defaultValue)) {
      setUploadedFiles([
        ...defaultValue.map((preUploadedFile) => {
          return {
            questionId: id,
            id: preUploadedFile.title,
            name: preUploadedFile.name,
          };
        }),
      ]);
    }
  }, [defaultValue]);

  return (
    <Row key={key}>
      <Col sm="12">
        <div className="mb-3">
          <Label className="form-label" htmlFor={`question-${id}`}>
            {props.t(title)}

            {helpText && (
              <span className="ms-2 tooltip-container">
                <i
                  className="fa fa-info-circle help-text-icon"
                  aria-hidden="true"
                  id={`${elRefIdPrefix}-question-${id}-help-text`}
                />
                <Tooltip
                  style={{
                    backgroundColor: "#899ba1",
                    fontWeight: "bold",
                  }}
                  placement="top"
                  isOpen={helpTooltipTextStatus}
                  target={`${elRefIdPrefix}-question-${id}-help-text`}
                  toggle={() =>
                    setHelpTooltipTextStatus(!helpTooltipTextStatus)
                  }
                >
                  {<p>{props.t(helpText)}</p>}
                </Tooltip>
              </span>
            )}
          </Label>
          <div style={{ position: "relative" }}>
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleChange(acceptedFiles);
                handleAcceptedFiles(acceptedFiles, id);
              }}
              accept={acceptedFiles.accepted}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                    </div>
                    <h4>{props.t("Drop files here or click to upload")}</h4>
                  </div>
                </div>
              )}
            </Dropzone>

            <Button
              id="dropzone-popover"
              className="button"
              type="button"
              color="link"
              style={{
                position: "absolute",
                right: 8,
                bottom: 6,
                cursor: "pointer",
              }}
              onClick={() => setDropzonePopoverDisplay(!dropzonePopoverDisplay)}
            >
              <i className="ri-information-line" style={{ fontSize: 18 }}></i>
            </Button>

            <Popover
              placement="bottom"
              target="dropzone-popover"
              isOpen={dropzonePopoverDisplay}
            >
              <PopoverHeader>{props.t("Allowed Formats")}</PopoverHeader>
              <PopoverBody>
                <span>
                  {props.t(
                    "You are allowed to upload limited file types. for more information, visit"
                  )}{" "}
                </span>
                <a href="https://diss-co.tech/faq-diss-co/" target="_blank">
                  {props.t("FAQ page")}
                </a>
              </PopoverBody>
            </Popover>
          </div>
          <div className="dropzone-previews mt-3" id="file-previews">
            {uploadedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <AvField
                        name={`question[${id}][${i}][id]`}
                        type="text"
                        className="d-none"
                        value={f.id}
                      />
                      <AvField
                        name={`question[${id}][${i}][name]`}
                        type="text"
                        className="d-none"
                        value={f.name}
                      />
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted fw-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col
                        xs={2}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => handleClickDeleteFiles(f)}
                        >
                          X
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default withNamespaces()(
  connect(null, {
    errorNotification,
    successNotification,
  })(memo(FileUploader))
);
