import React, { Component, Fragment } from "react";

import { Container, Row, Col, Card, CardBody, InputGroup ,InputGroupAddon,Button,Label,Input } from "reactstrap";

import { connect } from 'react-redux';

import { withNamespaces } from "react-i18next";

import { AvForm, AvField } from "availity-reactstrap-validation";

import InputPhoneNumber from "../../../components/Fields/InputPhoneNumber";

import { post } from "../../../helpers/request_helper";

import 'toastr/build/toastr.min.css'

import { API_URL_ADMIN_GET_USER_MANAGEMENT_DETAIL,API_URL_ADMIN_UPDATE_USER_MANAGEMENT } from '../../../common/constants';

import Breadcrumbs from '../../../components/Common/Breadcrumb';

import { successNotification,errorNotification } from "../../../store/actions";
import UserUtils from "../../../services/utils/UserUtils";
import { ROLE_EMPLOYEE, ROLE_TASK_OWNER } from "../../../components/constants";
import axios from "axios";
import { withRouter } from "react-router-dom";

class EditUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputPhoneNumber: null,
			breadcrumbItems: [
				{ title: 'SIP', link: '/' },
				{ title: this.props.t('Edit user'), link: '#' },
			],
			user: {},
			hasTaskOwnerRole: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.userUtil = new UserUtils();
	}

	async handleSubmit(e, values) {
		try {
			const response = await axios.post(
				API_URL_ADMIN_UPDATE_USER_MANAGEMENT,
				{
					...values,
					gender: 1,
					roles: this.state.user.roles,
					user_id: this.state.user.id,
					phone_number: this.state.inputPhoneNumber,
				},
				{
					headers: {
						Authorization: `Bearer ${this.props.token}`,
					},
				},
			);

			if (response.status === 200) {
				this.props.successNotification({
					code: 'saved',
					message: this.props.t('Data was saved successfully'),
				});
				this.props.history.push('/admin/administration/users');
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response.data.error === 'user_has_uncompleted_tasks') {
					this.props.errorNotification({
						code: 'saved',
						message: this.props.t('This employee has some uncompleted tasks.'),
					});
				} else if (error.response.data.error === 'organization_task_owner_limitation') {
					this.props.errorNotification({
						code: 'saved',
						message: this.props.t('Task ownership licence is over.'),
					});
				}
			} else {
				this.props.errorNotification({
					code: 'saved',
					message: this.props.t('Failed to edit user.'),
				});
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<Breadcrumbs title={this.props.t('Edit user')} breadcrumbItems={this.state.breadcrumbItems} />
						<Row>
							<Col xl='12'>
								<Card>
									<CardBody>
										<h4 className='card-title'>{this.props.t('User Profile')}</h4>
										<AvForm className='needs-validation' onValidSubmit={this.handleSubmit}>
											<Row>
												<Col md='6'>
													<div className='mb-3'>
														<Label className='form-label' htmlFor='first-name'>
															{this.props.t('First name')}
														</Label>
														<AvField
															name='first_name'
															placeholder=''
															type='text'
															errorMessage={this.props.t('This field cannot be blank')}
															className='form-control'
															validate={{ required: { value: true } }}
															value={this.state.user.first_name || ''}
															id='first-name'
														/>
													</div>
												</Col>
												<Col md='6'>
													<div className='mb-3'>
														<Label className='form-label' htmlFor='lastname'>
															{this.props.t('Last name')}
														</Label>
														<AvField
															name='last_name'
															placeholder=''
															type='text'
															errorMessage={this.props.t('This field cannot be blank')}
															className='form-control'
															validate={{ required: { value: true } }}
															value={this.state.user.last_name || ''}
															id='lastname'
														/>
													</div>
												</Col>
											</Row>
											<Row>
												<Col md='6'>
													<div className='mb-3 position-relative'>
														<Label className='form-label' htmlFor='validationTooltipUsername'>
															{this.props.t('Email')}
														</Label>
														<InputGroup>
															<InputGroupAddon addonType='prepend'>
																<span className='input-group-text' id='validationTooltipUsernamePrepend'>
																	@
																</span>
															</InputGroupAddon>
															<Input
																value={this.state.user.email || ''}
																disabled={true}
																type='text'
																className='form-control'
																id='validationTooltipUsername'
																placeholder='Username'
															/>
														</InputGroup>
													</div>
												</Col>
												{this.state.user && this.userUtil.isEmployee(this.state.user) ? (
													<Col md='6'>
														<div className='mb-3 position-relative'>
															<Label className='form-label' htmlFor='validationTooltipUsername'>
																{this.props.t('Task Owner Role')}
															</Label>
															<InputGroup style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
																<Input
																	id='hastaskownerrole'
																	checked={this.userUtil.isTaskOwner(this.state.user)}
																	type='checkbox'
																	onChange={() => {
																		this.setState({
																			user: {
																				...this.state.user,
																				roles: this.userUtil.isTaskOwner(this.state.user) ? [ROLE_EMPLOYEE] : [ROLE_EMPLOYEE, ROLE_TASK_OWNER],
																			},
																		});
																	}}
																/>
																<Label className='form-label mb-0' style={{ marginLeft: '5px', paddingTop: '1px' }} htmlFor='hastaskownerrole'>
																	{this.props.t('Active')}
																</Label>
															</InputGroup>
														</div>
													</Col>
												) : null}
											</Row>
											<Row>
												<Col md='6'>
													<div className='mb-3'>
														<Label className='form-label' htmlFor='company-position'>
															{`${this.props.t('Company Position')} ${this.props.t('(Optional)')}`}
														</Label>
														<AvField
															name='position'
															placeholder=''
															type='text'
															className='form-control'
															id='company-position'
															value={this.state.user.position || ''}
														/>
													</div>
												</Col>
												<Col md='6'>
													<div className='mb-3'>
														<Label className='form-label' htmlFor='phone-number'>
															{`${this.props.t('Phone number')} ${this.props.t('(Optional)')}`}
														</Label>
														<div className='sip-phone-input'>
															<InputPhoneNumber
																id='phone-number'
																name='phone_number'
																required={false}
																// className="form-control"
																style={{ width: '100%' }}
																placeholder=''
																errorMessage=''
																onChange={(phone) => this.setState({ inputPhoneNumber: phone })}
																value={this.state.user.phone_number || ''}
															/>
														</div>
													</div>
												</Col>
											</Row>
											<Button color='primary' type='submit'>
												{this.props.t('Save')}
											</Button>
										</AvForm>
										{this.state.taskOwnerRoleDurationHistory &&
										Array.isArray(this.state.taskOwnerRoleDurationHistory) &&
										this.state.taskOwnerRoleDurationHistory.length > 0 ? (
											<Fragment>
												<br />
												<br />
												<br />
												<p className="card-title" style={{ fontWeight:'bold'}}>Task Owner Role Duration Logs:</p>
												{this.state.taskOwnerRoleDurationHistory.map((i) => {
													return (
														<div style={{ marginBottom: '10px' }}>
															<div style={{ padding: '10px', border: '1px solid #dadada', display: 'inline-block', borderRadius: '5px' }}>
																<span>
																	<span style={{ fontWeight: 'bold' }}>From</span>: {i.createdAt}
																</span>{' '}
																<span style={{ fontWeight: 'bold', padding: '0 10px' }}>&gt;&gt;&gt;&gt;&gt;</span>{' '}
																<span>
																	<span style={{ fontWeight: 'bold' }}>To</span>: {i.updatedAt ? i.updatedAt : 'Present'}
																</span>
															</div>
														</div>
													);
												})}
											</Fragment>
										) : null}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	fetchUserInfos() {
		post(
			API_URL_ADMIN_GET_USER_MANAGEMENT_DETAIL,
			{
				user_id: this.props.match.params.id,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.token}`,
				},
			},
		).then((response) => {
			const state = {
				user: response.data.data.employeeInfo,
			};

			if (response.data.data.taskOwnerRoleDurationHistory) {
				state['taskOwnerRoleDurationHistory'] = response.data.data.taskOwnerRoleDurationHistory
			}

			this.setState(state);
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.App.loaded && this.props.App.loaded) {
			this.fetchUserInfos();
		}
	}

	componentDidMount() {
		if (this.props.App.loaded) {
			this.fetchUserInfos();
		}
	}
}

const mapStatetoProps = state => {
    const {token} = state.Login;
    const {App} = state;
    return { token, App };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    successNotification,
    errorNotification
})(EditUser)));
