export const getFeatureStatusByTitle = (data, title) => {
  const result = data.filter((obj) => {
    return obj.title === title;
  });

  // console.log(result[0].hasOwnProperty('flag'))

  return result[0] && result[0].flag === 1 ? true : false;
  // return result[0].flag === 1 ? true : false;
};
