import { memo, useEffect, useRef, useState } from 'react';
import { Row, Col, TabPane, Button } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { TbLoader } from 'react-icons/tb';
import {
	API_URL_ADD_COMMENT,
	CM_API_URL_ADD_COMMENT,
	EMPTY_LIST,
	API_URL_REPORT_UPLOAD_MULTIPLE_FILE,
	CM_API_URL_REPORT_UPLOAD_MULTIPLE_FILE,
} from '../../../common/constants';
import { INTERNATIONAL_DATE_TIME_FORMAT, SUBMIT_MESSAGE_FAILED, UPLOAD_ATTACHMENT_FAILED } from '../../../common/constants';
import draftToHtml from 'draftjs-to-html';
import axios from 'axios';
import defaultUserAvatar from '../../../assets/images/users/def.svg';
import HTMLContent from '../../../components/Common/HTMLContent';
import DateUtils from '../../../services/utils/DateUtils';

const Messages = function (props) {
	Messages.displayName = 'Memorized Component - Messages';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> Messages');

	const { addItem, isDeleted, isReadOnlyAnalyst, t, messages, errorNotification, isLoading, isFetched, module, currentUser, isActive } = props;

	const dateUtils = new DateUtils();
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [isUploading, setIsUploading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [attachments, setAttachments] = useState([]);
	const messagesWrapperHTMLRef = useRef(null);

	/**
	 * this method sets new state to editor's state.
	 * @param {Object} state the state to set.
	 */
	const handleEditorStateChanges = (state) => {
		setEditorState(state);
	};

	/**
	 * this method removes attachment from the uploading attachments.
	 * @param {String} id the ID of the attachment you want to delete.
	 */
	const handleRemoveAttachment = (id) => {
		const index = attachments.findIndex((i) => i.id === id);
		if (index > -1) {
			const oldArray = Array.from(attachments);
			oldArray.splice(index, 1);
			setAttachments(oldArray);
		}
	};

	/**
	 * this method updates and uploads the attachments list.
	 * @param {Object[]} fs files to upload.
	 * @param {String} caseType the type of the case.
	 * @param {String} token authorization token.
	 */
	const handleUpdateAttachments = async (files, caseType, token) => {
		if (files.length > 0) {
			setIsUploading(true);
			const attachment = await handleUploadAttachments(files, caseType, token);
			console.log('Atachment', attachment);

			if (attachment.length > 0) {
				attachment.map((item) => {
					return setAttachments((oldArray) => [
						...oldArray,
						{
							id: item.id,
							//   size: item.size,
							file: item.name,
						},
					]);
				});
			}

			setIsUploading(false);
		}
	};

	/**
	 * this method uploads attachment to the server.
	 * @param {Object[]} file file to upload.
	 * @param {String} caseType the type of the case.
	 * @param {String} token authorization token.
	 */
	const handleUploadAttachments = async (files, caseType, token) => {
		const attachments = [];
		const formData = new FormData();
		formData.append('reportType', caseType);

		for (let i = 0; i < files.length; i++) {
			formData.append(`files[${i}]`, files[i]);
		}

		try {
			const result = await axios.post(module === 'wb' ? API_URL_REPORT_UPLOAD_MULTIPLE_FILE : CM_API_URL_REPORT_UPLOAD_MULTIPLE_FILE, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (result.status === 200) {
				console.log('Response', result);
				attachments.push(...result.data.data);
			} else {
				errorNotification({
					message: t(UPLOAD_ATTACHMENT_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('This error only appears in the development environment:\nerror while uploading files:', error);
			}

			errorNotification({
				message: t(UPLOAD_ATTACHMENT_FAILED),
			});
		}

		return attachments;
	};

	/**
	 * this method submits a message to the server for selected case.
	 * @param {String} caseId the ID of the case you want to submit message for.
	 * @param {String[]} filesIds uploaded files IDs.
	 * @param {String} token authorization token.
	 * @returns {Boolean} false if validation failed.
	 */
	const handleSubmit = async (caseId, filesIds, token) => {
		try {
			const commentContent = convertToRaw(editorState.getCurrentContent());
			const plainText = EditorState.createWithContent(convertFromRaw(commentContent)).getCurrentContent().getPlainText();
			let isEmpty = true;

			for (let i = 0; i < commentContent.blocks.length; i++) {
				if (commentContent.blocks[i].text.trim() !== '') {
					isEmpty = false;
					break;
				}
			}

			if (isEmpty) {
				// setErrorMessage(props.t('This field cannot be blank'));
				return false;
			}

			if(plainText?.length >= 2048) {
				errorNotification({
					message: t("Your comment should not be more than 2048 characters"),
				});
				return false;
			}


			setIsSubmitting(true);
			setIsUploading(true);
			const result = await axios.post(
				module === 'wb' ? API_URL_ADD_COMMENT : CM_API_URL_ADD_COMMENT,
				{
					comment_content: draftToHtml(commentContent),
					report_case_id: caseId,
					files: filesIds,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				setEditorState(EditorState.createEmpty());
				setAttachments([]);
				setIsSubmitting(false);
				setIsUploading(false);
				addItem(result.data.data);
			} else {
				props.errorNotification({
					message: props.t(SUBMIT_MESSAGE_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while submitting comment:', error);
			}

			props.errorNotification({
				message: props.t(SUBMIT_MESSAGE_FAILED),
			});
		}
	};

	/**
	 * this method renders the attachments of the comment.
	 * @param {Object} comment comment.
	 * @returns returns DOM nodes if the comment attachments was not empty, otherwise returns null.
	 */
	const handleRenderCommentAttachments = (comment) => {
		if (comment.attachments && comment.attachments.length > 0) {
			return comment.attachments.map((attachment) => {
				return (
					<div className='bg-secondary me-1 badge badge-secondary' key={attachment.id}>
						<i className='ri-attachment-line'></i> {attachment.name}
					</div>
				);
			});
		}
		return null;
	};

	/**
	 * this method return comment's owner's information.
	 * @param {Object} comment the comments you want to get owner's informations.
	 * @param {Object} currentUser current authorized user.
	 * @returns {String} comment's owner's full name.
	 */
	const handleGetCommentOwnerFullName = (comment, currentUser) => {
		// console.log(comment, currentUser)
		if (comment.owner === undefined || comment.owner.length === 0) {
			return props.t('Anonymous');
		} else if (!comment.owner || Array.isArray(comment.owner)) {
			return `${currentUser.first_name} ${currentUser.last_name}`;
		}
		// return  comment?.owner?.username ? `${comment.owner.username}` : `${comment?.owner?.first_name} ${comment?.owner?.last_name}`;
		return `${comment?.owner?.first_name} ${comment?.owner?.last_name}`;
	};

	useEffect(() => {
		setTimeout(() => {
			if (messagesWrapperHTMLRef && messagesWrapperHTMLRef.current) {
				messagesWrapperHTMLRef.current.scrollIntoView(false);
			}
		}, 500);
	}, [messages]);

	useEffect(() => {
		if(isActive) document.title = 'Report Details - Messages | SIP';
	}, [isActive]);

	return (
		<TabPane tabId='messages' className='pt-4'>
			{/* messages */}
			{!isFetched && isLoading ? (
				<div className='alert alert-info' role='alert'>
					<p style={{ textAlign: 'center', marginBottom: 0 }}>{t('Loading')}...</p>
				</div>
			) : props.messages ? (
				<Row>
					<Col sm='12'>
						<div className='px-lg-2'>
							<div
								className='chat-conversation p-3'
								style={{
									maxHeight: '300px',
									overflow: 'auto',
								}}
							>
								<ul className='list-unstyled mb-0 pe-3' ref={messagesWrapperHTMLRef}>
									{props.messages.map((message, key) => (
										<li
											key={message.id}
											className={''}
											style={{
												paddingRight: '20px',
											}}
										>
											<div className={`conversation-list ${message.owner.id === currentUser.id ? 'reverse' : ''}`}>
												<div className='chat-avatar'>
													<img
														src={
															message.owner.avatar
																? message.owner.avatar
																: message.owner.id === currentUser.id && props.currentUser.avatar
																? props.currentUser.avatar
																: defaultUserAvatar
														}
														alt=''
													/>
												</div>

												<div className='ctext-wrap'>
													<div className='conversation-name'>{handleGetCommentOwnerFullName(message, props.currentUser)}</div>
													<div
														style={{
															backgroundColor: '#eff2f7',
															color: '#74788d',
														}}
														className='ctext-wrap-content mb-1'
													>
														<div>
															<HTMLContent content={message.content} />
														</div>
													</div>
													{handleRenderCommentAttachments(message)}
													<p className='chat-time mb-0'>{dateUtils.convertTimeStampToDate(message.created_at, INTERNATIONAL_DATE_TIME_FORMAT)}</p>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					</Col>
				</Row>
			) : (
				<div className='alert alert-warning' role='alert'>
					<p style={{ textAlign: 'center', marginBottom: 0 }}>{t(EMPTY_LIST)}</p>
				</div>
			)}

			{/* editor */}
			{isDeleted ? null : (
				<Row>
					<Col sm='12' lg='12' style={isReadOnlyAnalyst ? { opacity: 0.5 } : null}>
						<Editor
							editorState={editorState}
							onEditorStateChange={handleEditorStateChanges}
							readOnly={isReadOnlyAnalyst ? true : false}
							toolbar={{
								options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
								inline: {
									options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
									bold: { className: 'bordered-option-classname' },
									italic: { className: 'bordered-option-classname' },
									underline: { className: 'bordered-option-classname' },
									strikethrough: { className: 'bordered-option-classname' },
									code: { className: 'bordered-option-classname' },
								},
								blockType: {
									className: 'bordered-option-classname',
								},
								fontSize: {
									className: 'bordered-option-classname',
								},
							}}
						/>
					</Col>
					<Col sm='12' md='12' lg='12'>
						<br />
						{isReadOnlyAnalyst ? <p className='text-danger'>{t('You have read-only access to a case.')}</p> : null}
					</Col>
				</Row>
			)}

			{/* attachments */}
			{!isDeleted && attachments.length > 0 ? (
				<Row>
					<Col sm='12' lg='12'>
						<div className='attachments-list'>
							{attachments.map((item) => {
								return (
									<span key={item.id} className='bg-success me-1 badge badge-secondary'>
										{item.file}
										<b onClick={() => handleRemoveAttachment(item.id)} className='delete-attachment'>
											x
										</b>
									</span>
								);
							})}
						</div>
					</Col>
				</Row>
			) : null}

			{/* actions */}
			{isReadOnlyAnalyst || isDeleted ? null : (
				<Row>
					<Col sm='12' lg='12'>
						{/* submit button */}
						<Button
							className='me-1'
							onClick={() =>
								handleSubmit(
									props.reportId,
									attachments.map((i) => i.id),
									props.authToken,
								)
							}
							color='secondary'
							disabled={isSubmitting}
						>
							{!isSubmitting ? (
								<>
									<i className='fa fa-comment'></i>
									{` ${props.t('Post')}`}
								</>
							) : (
								<TbLoader />
							)}
						</Button>

						{/* upload button */}
						<Button color='primary' outline className='waves-effect waves-light'>
							<input
								style={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									left: '0px',
									top: '0px',
									right: '0px',
									bottom: '0px',
									zIndex: '1',
									opacity: '0',
									cursor: 'pointer',
								}}
								type='file'
								multiple // Add the 'multiple' attribute to allow selecting multiple files
								onChange={(e) => handleUpdateAttachments(e.target.files, props.reportType, props.authToken)}
							/>

							{!isUploading ? (
								<>
									<i className='fa fa-link'></i>
									{` ${t('Attach')}`}
								</>
							) : (
								<TbLoader />
							)}
						</Button>
					</Col>
				</Row>
			)}
		</TabPane>
	);
};

export default memo(Messages);
