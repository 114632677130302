import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ReportFeilds from "./reportFiled/reportFiled";
import ReportIncidenService from "../../components/api/ReportIncidenService";
import UserUtils from "src/services/utils/UserUtils";
import { useQuery } from "@tanstack/react-query";
import MemorizedReportContents from "src/modules/data-protection/pages/reporting/Components/ReportComponents/ReportContents";
import MemorizedReportTranslations from "src/modules/data-protection/pages/reporting/Components/ReportComponents/ReportTranslations";
import {
  API_URL_TRANSLATION_LIST,
  GDPR_DOMAIN_URL,
} from "src/common/constants";
import { toast } from "react-toastify";
import axios from "axios";

const RequestTab = (props) => {
  const {
    t,
    categories,
    caseId,
    token,
    currentUser,
    organization,
    report,
    reportLoading,
    analystAdminsList,
    adminsListIsLoading,
  } = props;

  const [translatedAnswers, setTranslatedAnswers] = useState([]);
  const [translatedAnswersLoading, setTranslatedAnswersLoading] =
    useState(false);
  const [mainTranslation, setMainTranslation] = useState([]);
  const userUtils = new UserUtils();
  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };



  const organizationLanguages = organization?.languages.map((lang) => {
    return {
      id: lang.id,
      label: lang.name,
      value: lang.language_code,
      direction: lang.direction,
      isDefault: lang.is_default,
    };
  });
  const handleGetOrganizationTranslations = async (
    organizationId,
    languageCode,
    token
  ) => {
    try {
      const result = await axios.post(
        API_URL_TRANSLATION_LIST,
        {
          code_lang: languageCode,
          organization_id: organizationId,
          module: "gd"
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        if (result.data.data) {
          setMainTranslation(result.data.data);
        }
      } else {
        toast(t("Failed to fetch organization's translations."), {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      if (process.env.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching organization's translations:",
          error
        );
      }

      if (error.message !== "parsed is undefined") {
        toast(t("Failed to fetch organization's translations."), {
          type: "error",
        });
      }
    }
  };

  const handleChangeTranslation = async (
    caseId,
    languageCode,
    organizationId,
    token
  ) => {
    try {
      setTranslatedAnswersLoading(true);
      handleGetOrganizationTranslations(organizationId, languageCode, token);
      const result = await axios.get(
        `${GDPR_DOMAIN_URL}dsr/${caseId}/content_translation`,
        {
          params: {
            language: languageCode,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.status === 200) {
        if (result.data.data) {
          // const report = Object.create(this.state.report);
          // report.question_answer = result.data.data.Questions;
          // report.category = result.data.data.Category;

          setTranslatedAnswers([
            {
              title: "Describtion",
              answer: result.data.data,
              type: "textarea",
            },
          ]);
          setTranslatedAnswersLoading(false);
        }
      }
    } catch (error) {
      console.log("error while fetching translation content:", error);
    }
  };

  return (
    <div className="d-flex flex-column gap-4 mb-4 mt-1">
      <ReportFeilds
        report={report}
        reportLoading={reportLoading}
        caseId={caseId}
        reportRefetch={() => props.reportRefetch()}
        categories={categories}
        analystAdminsList={analystAdminsList}
        adminsListIsLoading={adminsListIsLoading}
      />

      <MemorizedReportContents
        t={t}
        questions={report?.question_answers}
        reportLoading={reportLoading}
      />

      <MemorizedReportTranslations
        t={t}
        isDeleted={report?.is_deleted_content}
        isAnalystOrAnalystAdmin={isAnalystOrAnalystAdmin()}
        organizationLanguages={organizationLanguages}
        organizationLanguagesLoading={false}
        organizationId={organization.id}
        translatedAnswers={translatedAnswers}
        mainTranslation={mainTranslation}
        id={caseId}
        handleChangeTranslation={handleChangeTranslation}
        authToken={token}
        translatedAnswersLoading={translatedAnswersLoading}
      />
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, user: currentUser } = state.Login;
  const { Feature, CMFeature, Organization } = state;

  const activeModule = localStorage.getItem("module");
  return {
    currentUser,
    token,
    organization: Organization,
    Feature:
      activeModule === "wb"
        ? Feature
        : activeModule === "cm"
        ? CMFeature
        : null,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(RequestTab)));
