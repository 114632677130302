import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { Col, Row, TabPane, Button } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  errorNotification,
  successNotification,
} from "../../../../../store/actions";
import { connect } from "react-redux";
import DataProtectionDPIAService from "../../../apis/DataProtectionDPIAService";
import Questionar from "../../../../../components/Questionar";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { debounce } from 'lodash';
import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";
import CreateModalOptions from "../Common/CreateModalOptions";

const DPIAQuestionnaire = (props) => {
  const { t } = props;
  const { softwareId } = useParams();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [
    activeAccordionHasUnsavedChanges,
    setActiveAccordionHasUnsavedChanges,
  ] = useState(false);
  const [isAnyFormSubmiting, setIsAnyFormSubmiting] = useState(false);
  const [sectionProgress, setSectionProgress] = useState({});
  const [filledQuestionnaires, setFilledQuestionnaires] = useState([]);

  // Create by AI modal
  const [isCreateAIOpen, setIsCreateAIOpen] = useState(false);
  const [isCreateByAISubmiting, setIsCreateByAISubmiting] = useState(false);
  const [childrenAnswers, setChildrenAnswers] = useState([]);
  const [yesAnswers, setYesAnswers] = useState([]);

  const handleFetchQuestionsQuery = useQuery({
    queryKey: ["data-protection-report-dpia-questions-list"],
    queryFn: async () => {
      const service = DataProtectionDPIAService.getInstance();

      const result = await service.questions(activeAccordion, softwareId ,{
        reportCase: props.reportCaseId,
      });
      const newSectionProgress = Object.assign({}, sectionProgress, {
        [activeAccordion]: {
          completed: 0,
          total: result?.length || 0,
          answeredQuestions: new Set(),
        },
      });

      setSectionProgress(newSectionProgress);
      return result;
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching questions."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (activeAccordion) {
      handleFetchQuestionsQuery.refetch();
    }
  }, [activeAccordion]);

  const handleChangeActiveAccordion = (key) => {
    // if (activeAccordion !== newAccordionToOpen) {
    //   if (activeAccordionHasUnsavedChanges) {
    //     alert(props.t("Please save changes..."));
    //   } else {
    //     setActiveAccordion(newAccordionToOpen);
    //   }
    // }
    setActiveAccordion((prev) => (prev === key ? null : key));
  };

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      reportCase: props.reportCaseId,
      software: softwareId,
      hardware: null,
    };

    setIsAnyFormSubmiting(true);

    axios
      .post(
        `${process.env.REACT_APP_GDPR_API}/api/dpia/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => {
        props.successNotification({
          code: props.t("Report Submitted"),
          message: props.t("Report Submitted"),
        });
      })
      .catch((error) => {
        this.props.errorNotification({
          code: "validation_error",
          message: props.t("Error!"),
        });
      })
      .finally(() => {
        setIsAnyFormSubmiting(false);
      });
  };
  
  const createByAI = () => {
    const payload = {
      softwareId: Number(softwareId),
    };

    setIsCreateByAISubmiting(true);
    axios
      .post(
        `${process.env.REACT_APP_GDPR_API}/api/dpia/ai_generate`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => {
        setIsCreateAIOpen(false);
        handleFetchQuestionsQuery.refetch();
      })
      .catch((error) => {
        toast(props.t("An error occurred while generating by AI."), {
          type: "error",
        });
      })
      .finally(() => {
        setIsCreateByAISubmiting(false);
      });
  };

  const questionareSettings = {
    confirmFilledInputsForm: {
      enabled: false,
    },
    buttons: {
      submit: {
        enabled: true,
        title: "Save Changes",
      },
    },
  };

  const handleQuestionComplete = (sectionKey, questionId) => {
    setSectionProgress((prev) => {
      const updated = { ...prev };
      const section = updated[sectionKey];

      // Check if the question has already been answered
      if (!section.answeredQuestions.has(questionId)) {
        section.completed += 1; // Increment the count of completed questions only if new
        section.answeredQuestions.add(questionId); // Mark the question as answered

        // Optional: If you want to ensure the completed count never exceeds total
        section.completed = Math.min(section.completed, section.total);
      }

      return updated;
    });
  };

  const handleChildrenChange = debounce((questionId, childData, value) => {
    setChildrenAnswers((prevAnswers) => {
      const index = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );
  
      const transformedChildData = !Array.isArray(childData) ? 
        [{title: childData?.label, value: childData.value}]
      : childData.map(child => {
          const { label, ...rest } = child;
          return {
              ...rest,
              title: label,
              value: value
          };
      });
  
      if (index >= 0) {
        return prevAnswers.map((answer, idx) =>
          idx === index ? { ...answer, children: transformedChildData } : answer
        );
      } else {
        return [
            ...prevAnswers,
            { questionId: questionId, children: transformedChildData },
        ];
      }
    });
  }, 300); // Adjust debounce time as needed
  
  const handleExtendedChange = debounce((questionId, e, value) => {
    setYesAnswers((prevAnswers) => {
      const index = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );
  
      if (index >= 0) {
        return prevAnswers.map((answer, idx) =>
          idx === index ? { ...answer, title: e, value: value } : answer
        );
      } else {
        return [
            ...prevAnswers,
            { question: questionId, title: e, value: value },
        ];
      }
    });
  }, 300);

  const handleFetchFilledItems = useQuery({
    queryKey: ["data-protection-softwares-filled-items"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      const result = await service.filledQuestionnaires(
        softwareId,
        "dpia"
      );

      return result
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching questions."), {
        type: "error",
      });
    },
  });
  

  const renderAccordionHeaderStyle = (sectionKey) => {
    const progress = sectionProgress[sectionKey];
    const widthPercentage = (progress?.completed / progress?.total) * 100;
    return {
      background: `linear-gradient(to right, #59C94F4D ${widthPercentage}%, transparent ${widthPercentage}%, transparent 30%)`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    };
  };

  useEffect(() => {
    // console.log(sectionProgress);
  }, [sectionProgress]);

  useEffect(() => {
    const reports =
      handleFetchFilledItems.data && handleFetchFilledItems.data.reports
        ? handleFetchFilledItems.data.reports
        : [];

    setFilledQuestionnaires(reports);
  }, [handleFetchFilledItems.data]);


  return (
    <TabPane tabId="dpia" className="pt-4">
      <CreateModalOptions
        modalActivated={isCreateAIOpen}
        setIsCreateAIOpen={setIsCreateAIOpen}
        createByAI={createByAI}
        isCreateByAISubmiting={isCreateByAISubmiting}
      />
      <Row>
        <Col sm="12">
          <div className="p-2">
            <Accordion activeKey={activeAccordion}>
              <Accordion.Item
                className="border-none mb-2"
                eventKey={"risk_assessment"}
              >
                <Accordion.Header onClick={() => handleChangeActiveAccordion("risk_assessment")} className="section-header">
                  {/* {props.t("Risk assessment")} */}
                  <div
                    className="w-100 h-100 d-flex "
                    style={renderAccordionHeaderStyle("risk_assessment")}
                  >
                    <span className="p-3">{props.t("Risk assessment")}</span>

                    <Button 
                      color="primary" 
                      outline 
                      style={{height: "50%", alignItems: "center", alignContent: "center"}} 
                      className="m-2" 
                      size="sm"
                      onClick={() => setIsCreateAIOpen(true)}
                      >
                        {t("Create risk by AI")}
                    </Button>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {activeAccordion === "risk_assessment" && (
                    <Questionar
                      questionnaireId={`risk_assessment`}
                      questionnaireType={"risk_assessment"}
                      loading={
                        handleFetchQuestionsQuery.isLoading ||
                        handleFetchQuestionsQuery.isFetching
                      }
                      questions={handleFetchQuestionsQuery.data || []}
                      preFilledAnswers={handleFetchQuestionsQuery.data || []}
                      onValidSubmit={handleSubmit}
                      isFormSubmiting={isAnyFormSubmiting}
                      user={{
                        token: props.token,
                        ...props.user,
                      }}
                      resetFormModel={false}
                      settings={questionareSettings}
                      onQuestionComplete={(id) =>
                        handleQuestionComplete("risk_assessment", id)
                      }
                      onChildrenChange={(id, e, value) => handleChildrenChange(id, e, value)}
                      onExtendedChange={(id, e, value) => handleExtendedChange(id, e, value)}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                className={"border-none mb-2"}
                eventKey={"data_protection_impact_assessment"}
                onClick={() =>
                  !props?.showImpactAssessment ? 
                  null 
                  :
                  handleChangeActiveAccordion(
                    "data_protection_impact_assessment"
                  )
                }
                disabled={!props?.showImpactAssessment}
                style={{
                  opacity: !props?.showImpactAssessment ? "0.5" : "1",
                  cursor: !props?.showImpactAssessment ? "not-allowed" : "pointer"
                }}
              >
                <Accordion.Header className="section-header">
                  {/* {props.t(`Data protection impact assessment`)} */}
                  <div
                    className="w-100 h-100"
                    style={renderAccordionHeaderStyle(
                      "data_protection_impact_assessment"
                    )}
                  >
                    <span className="p-3" title={props.t("Available for high risk assets")}>
                      {props.t("Data protection impact assessment")}
                      {/* {props.t("Available for high risk assets")} */}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {activeAccordion === "data_protection_impact_assessment" && (
                    <Questionar
                      questionnaireId={`data_protection_impact_assessment`}
                      questionnaireType={"data_protection_impact_assessment"}
                      loading={
                        handleFetchQuestionsQuery.isLoading ||
                        handleFetchQuestionsQuery.isFetching
                      }
                      questions={handleFetchQuestionsQuery.data || []}
                      preFilledAnswers={handleFetchQuestionsQuery.data || []}
                      onValidSubmit={handleSubmit}
                      isFormSubmiting={isAnyFormSubmiting}
                      user={{
                        token: props.token,
                        ...props.user,
                      }}
                      resetFormModel={false}
                      settings={questionareSettings}
                      onQuestionComplete={(id) =>
                        handleQuestionComplete(
                          "data_protection_impact_assessment",
                          id
                        )
                      }
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization } = state;

  return {
    token,
    user,
    Organization,
  };
};

export default withNamespaces()(
  connect(mapStatetoProps, {
    errorNotification,
    successNotification,
  })(memo(DPIAQuestionnaire))
);
