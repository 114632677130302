import React, { Component } from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {  Link } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Progress,
  Container,
  Alert,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
} from "reactstrap";
import {
  API_URL_QUESTION_FORM_ORGANIZATION,
  CM_API_URL_QUESTION_FORM_ORGANIZATION,
  API_URL_CREATE_REPORT,
  CM_API_URL_CREATE_REPORT,
  API_URL_REPORT_UPLOAD_FILE,
  CM_API_URL_REPORT_UPLOAD_FILE,
  API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION,
  CM_API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION,
  API_URL_CREATE_THIRD_PARTY_ACCOUNT,
  API_URL_SIGN_IN,
  AUTH_API_DOMAIN_URL,
  API_URL_GENERATE_RANDOM_USERNAME,
  INTERNATIONAL_DATE_FORMAT,
  API_URL_GET_USER_DETAIL,
} from "../../common/constants";
import UrlUtils from "../../services/utils/UrlUtils";
import {
  authCurrentUser,
  logoutUser,
  loginUserSuccessful,
} from "../../store/auth/login/actions";
import UserUtils from "../../services/utils/UserUtils";
import DateUtils from "../../services/utils/DateUtils";
import Password from "../../components/Fields/Password";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DateInputBefor from "../../components/Fields/DateInputBefor";
import InputPhoneNumber from "../../components/Fields/InputPhoneNumber";
import {
  errorNotification,
  successNotification,
  checkLogin,
  setUserAccessToken,
  appError,
  appLoadStatusChanged,
} from "../../store/actions";
import { changeWorkspace } from "../../store/workspace/actions";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../services/constants/LocalStorage";
import "./Reports.css";
import ModuleContext from "../../contexts/ModuleContext";
import { withRouter } from "react-router-dom";

class ReportAnonymously extends Component {
  static contextType = ModuleContext;

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Report anonymously"), link: "#" },
      ],
      inputPhoneNumber: null,
      statusLogin: false,
      avToDateStartFrom: new Date(),
      // privateEmail: this.props.user?.private_email,
      privateEmail: "",
      defaultPrivateEmail: "",
      privateEmailChanged: false,
      avToDate: new Date(),
      tabsCount: 3,
      activeTabProgress: 1,
      isThirdPartyUser: true,
      statusCreateReport: false,
      progressValue: 0,
      questions: [],
      categories: [],
      locations: [],
      selectedFiles: [],
      uploadedFiles: [],
      submitData: null,
      selectedAuthType: "register",
      authUserInfos: { auth_type: "register" },
      username: "",
      password: "",
      tabs: [],
      isDisabled: false,
      modalForCalendar: false,
      calendarValue: null,
      selectedDay: new Date(),
      loginStatus: false,
      registerStatus: false,
      showProg: false,
      displayFollowup: false,
      displayFollowupFound: false,
      needsPrivateEmailFollowup: false,
      displayPrivateEmailFollowUp: false,
      popover: {
        id: null,
        active: false,
      },
      showSuccessForm: false,
      displayButton: true,
      reload: false,
      showFollowUpProg: false,

      emailTooltipOpen: false,
      usernameTooltipOpen: false,
      passwordTooltipOpen: false,
      randomGeneratedUsername: "",
      username: null,
      password: "",
      repeat_pasword: "",
      consentChecked: true,
      // consentError: null
    };

    this.goToNextTab = this.goToNextTab.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finalizeReport = this.finalizeReport.bind(this);
    this.sortCountryList = this.sortCountryList.bind(this);
    this.handleFollowUpForm = this.handleFollowUpForm.bind(this);
    this.uploadAttachmentFile = this.uploadAttachmentFile.bind(this);
    this.handleChangeRadioBox = this.handleChangeRadioBox.bind(this);
    this.handleChangeCalendar = this.handleChangeCalendar.bind(this);
    this.renderEmailInput = this.renderEmailInput.bind(this);
    this.renderPhoneNumberInput = this.renderPhoneNumberInput.bind(this);
    this.authCurrentUser = this.props.authCurrentUser.bind(this);
    this.formRef = React.createRef();
    this.urlUtils = new UrlUtils();
    this.userUtils = new UserUtils();
    this.dateUtils = new DateUtils();

    this.dropzoneAcceptedFiles = {
			accepted:
				'text/plain, , application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv',
			description: [
				`${this.props.t('')}. ${this.props.t('For more information')}, ${this.props.t('visit')}`,
				<a href='https://diss-co.tech/faq-diss-co/' target='_blank' rel='noreferrer'>{` ${this.props.t('FAQ')} `}</a>,
				this.props.t('page'),
			],
		};
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  statusLogin() {
    if (this.urlUtils.getUrlParamThirdParty() === "third-party") {
      this.setState({
        isThirdPartyUser: true,
      });
    } else {
      this.setState({
        isThirdPartyUser: false,
      });
    }

    if (
      this.userUtils.isAnalystOrAnalystAdmin(this.props.user) === true ||
      this.userUtils.isThirdParty(this.props.user) === true ||
      this.userUtils.isEmployee(this.props.user) === true
    ) {
      this.setState({
        statusLogin: true,
      });
    } else {
      this.setState({
        statusLogin: false,
      });
    }
  }

  logoutCurrentUser() {
    this.props.logoutUser(this.props.history);
  }

  handleAcceptedFiles = (files, id) => {
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};

    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      });

      const formData = new FormData();

      formData.append("report_type", 1);
      formData.append("file", file);
      this.setState({ showProg: true });
      axios
				.post(this.context.selectedModule === 'wb' ? API_URL_REPORT_UPLOAD_FILE : CM_API_URL_REPORT_UPLOAD_FILE, formData, headers)
				.then((response) => {
					if (response.status === 200) {
						const fileData = response.data.data;
						this.setState({ showProg: false });
						this.setState({
							uploadedFiles: [
								...this.state.uploadedFiles,
								{
									questionId: id,
									id: fileData.id,
									size: fileData.size,
									file: fileData,
									name: file.name,
									preview: file.preview,
									formattedSize: file.formattedSize,
								},
							],
						});
					} else {
						this.props.errorNotification({
							code: response.data.error,
							message: this.props.t(
								'Your file could not be uploaded. The file is corrupted or the size is bigger than 1 GB. Please check your file or contact the support.',
							),
						});
					}
				})
				.catch((error) => {
					this.setState({ showProg: false });

					for (var key in error.response.data.message) {
						if (error.response.data.message.hasOwnProperty(key)) {
							this.props.errorNotification({
								code: 'unkown_error',
								message: this.props.t(error.response.data.message[key]),
							});
						}
					}
				});
    });

    this.setState({ selectedFiles: files });
  };

  handleClickDeleteFiles = (file) => {
    const url = this.context.selectedModule === 'wb' ? AUTH_API_DOMAIN_URL + `attachment/${file.id}/delete` : AUTH_API_DOMAIN_URL + `cm/attachment/${file.id}/delete`;

    const headers = null;

    let newSelectedFiles = this.state.selectedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });
    let newUploadedFiles = this.state.uploadedFiles.filter(function (obj) {
      return obj.name !== file.name;
    });

    const deletedFile = {
      // file: file.name,
      report_case_id: file.id,
    };

    axios
      .post(url, deletedFile, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((data) => {
        this.setState({
          selectedFiles: newSelectedFiles,
          uploadedFiles: newUploadedFiles,
        });

        this.props.successNotification({
          code: this.props.t("Data was deleted successfully"),
          message: this.props.t("Data was deleted successfully"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getQuestionsAnswers(answers) {
    //let getDate = document.getElementById("ava-to-date");

    let result = [];
    answers.map((answer, id) => {
      let getDate = document.getElementById("date-" + answer);

      // When did it happen?
      if (getDate !== null) {
        result.push({
          id: id,
          answer: [
            {
              id: answer,
              date: getDate.value,
            },
          ],
        });
      } else {
        result.push({
          id: id,
          answer: answer,
        });
      }
    });
    return result;
  }

  findUserAnswer(question) {
    if (question.type === "file") {
      const answer = this.state.uploadedFiles.find((file) => {
        return file.questionId === question.id;
      });
      if (answer) {
        return answer.file.name;
      }
    } else {
      const answers = this.state.submitData.question_answer;
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].id === question.id) {
          return answers[i].answer;
        }
      }
    }
    return null;
  }

  handleSubmit(e, values) {
    console.log("_________________________________________", values);
    const files = this.state.uploadedFiles.map((file) => {
      return file.id;
    });
    this.setState(
      {
        submitData: {
          files: files,
          category: values.Question_Category,
          description: values.description,
          email: null,
          first_name: "",
          last_name: "",
          organization: this.urlUtils.getOrganizationId(),
          phone: "",
          phone_available_time: null,
          position: "",
          relation_company: "",
          salutation: "",
          type: 1,
          source: values.source || "web",
          locations: [values.locations],
          question_answer: this.getQuestionsAnswers(values.Questien),
          user_policy_accepted: this.state.consentChecked,
          privacy_policy_accepted: this.state.consentChecked,
        },
        showProg: false,
      },
      () => {
        this.goToNextTab();
      }
    );
  }

  getToken = async () => {
    let userToken = null;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\[\]+={}'";:/?.,<>|\\-]).{10,100}$/;
    if (!this.props.user && !this.state.registerStatus) {
      try {
        userToken = await new Promise(async (nonAuthRes, nonAuthRej) => {
          try {
            if (this.state.authUserInfos.auth_type === "register") {
              var currentEmail;
              /* if (this.state.authUserInfos.email === "") {
								currentEmail = null;
							} else  */ if (!passwordRegex.test(this.state.authUserInfos.password)) {
                this.setState({
                  displayButton: true,
                  showProg: false,
                  displayFollowup: true,
                  consentChecked: false,
                });
                this.props.errorNotification({
                  code: "passwords-not-valid",
                  message: this.props.t(
                    "Password should be at least 10 characters and should contain at least one number and one special character"
                  ),
                });
              } else if (
                this.state.authUserInfos.password !==
                this.state.authUserInfos.repeat_pasword
              ) {
                this.setState({ displayFollowup: true, consentChecked: false });
                this.props.errorNotification({
                  code: "password_not_equal",
                  message: this.props.t(
                    "Password and repeat password are not the same"
                  ),
                });
              } else {
                const responseCreate = await axios
                  .post(API_URL_CREATE_THIRD_PARTY_ACCOUNT, {
                    organization: this.props.Organization.id,
                    username: this.state.authUserInfos.username,
                    password: this.state.authUserInfos.password,
                    email:
                      currentEmail || this.state.authUserInfos.email || null,
                    isThirdPartyUser: this.state.isThirdPartyUser,
                  })
                  .then(async (data) => {
                    this.setState({ registerStatus: true });

                    try {
                      const response = await axios.post(API_URL_SIGN_IN, {
                        organization: this.props.Organization.id,
                        username: this.state.authUserInfos.username,
                        password: this.state.authUserInfos.password,
                        isThirdPartyUser: this.state.isThirdPartyUser,
                        "2fa": false,
                      });
                      nonAuthRes(response.data.data.token);
                    } catch (error) {
                      console.log("login faild. try catch error");
                      this.setState({
                        displayButton: true,
                        showProg: false,
                        displayFollowup: true,
                      });
                      console.log(error);
                      nonAuthRej(
                        this.props.t(
                          "Your email and password combination is not correct"
                        )
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("error ->>>>>>>>>>>>>>>.");
                    console.log(error.response.data.message);
                    this.setState({
                      displayButton: true,
                      showProg: false,
                      displayFollowup: true,
                    });

                    for (var key in error.response.data.message) {
                      if (error.response.data.message.hasOwnProperty(key)) {
                        if (
                          "email" in error.response.data.message &&
                          error.response.data.message.email ===
                            "validation.email.already_used"
                        ) {
                          this.props.errorNotification({
                            code: "validation_error",
                            message: this.props.t("Email already used."),
                          });
                        } else {
                          this.props.errorNotification({
                            code: "validation_error",
                            message: this.props.t(
                              error.response.data.message[key]
                            ),
                          });
                        }
                      }
                    }
                  });
              }
              this.setState(
                {
                  displayButton: true,
                  showProg: false,
                  reload: true,
                  consentChecked: false,
                  // displayFollowup: true,
                },
                () => {
                  return this.forceUpdate();
                }
              );

              currentEmail = this.state.authUserInfos.email;
            } else {
              try {
                const response = await axios.post(API_URL_SIGN_IN, {
                  organization: this.props.Organization.id,
                  username: this.state.authUserInfos.username,
                  password: this.state.authUserInfos.password,
                  isThirdPartyUser: this.state.isThirdPartyUser,
                  "2fa": false,
                });
                nonAuthRes(response.data.data.token);
              } catch (error) {
                // console.log("login faild. try catch error");
                this.setState({
                  displayButton: true,
                  showProg: false,
                  displayFollowup: true,
                  consentChecked: false,
                });
                // console.log(error);
                nonAuthRej(
                  this.props.t(
                    "Your email and password combination is not correct"
                  )
                );
              }
            }
          } catch (error) {
            console.log("Err", error);
            this.setState({
              displayButton: true,
              showProg: false,
              displayFollowup: true,
              consentChecked: false,
            });
            if (!this.state.tabs[3].active) {
              nonAuthRej(this.props.t("Username already used"));
            }
          }
        });
      } catch (error) {
        console.log("CATCH ERROR");
        this.setState({
          displayButton: true,
          showProg: false,
          displayFollowup: true,
          consentChecked: false,
        });
        this.props.errorNotification({
          code: "fatal_error",
          message: error,
        });
        return;
      }
    } else {
      userToken = this.props.token;
    }
    this.props.setUserAccessToken(userToken);
    return localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, userToken);
  };

  makeAuth = async () => {
    await this.getToken();

    if (this.props.token) {
      await this.props.authCurrentUser(this.state.authUserInfos);

      await this.props.loginUserSuccessful(this.state.authUserInfos);
      await this.statusLogin();
    } else {
      this.setState(
        {
          showProg: false,
          displayButton: true,
          displayFollowup: true,
        },
        () => console.log(this.state)
      );
    }
  };

  handleFollowUpForm(e, values) {
    e.preventDefault();
    if (!this.props.user) {
      if (
        values.auth_type === "register" &&
        values.password !== values.repeat_pasword
      ) {
        this.props.errorNotification({
          code: "password_not_equal",
          message: this.props.t(
            "Password and repeat password are not the same"
          ),
        });

        this.setState({ showProg: false });
        return;
      }
      if (values.password.length < 10) {
        this.props.errorNotification({
          code: "invalid_password",
          message: this.props.t(
            "Password should be longer than 10 characters and contain lowercase, uppercase, number and special character"
          ),
        });
        this.setState({ showProg: false });
        return;
      }
      this.setState({
        ...this.state.authUserInfos,
        authUserInfos: values,
      });
    }
    this.setState(
      {
        submitData: {
          ...this.state.submitData,
          email: values.email,
        },
      },
      () => this.finalizeReport()
      // () => this.goToNextTab()
    );
  }

  async finalizeReport() {
    this.setState({ displayFollowup: false, showFollowUpProg: true });
    await this.makeAuth();
    localStorage.setItem("org", this.urlUtils.getOrganizationName());
    await this.setState({
      // ...this.state,
      //   displayFollowup: false,
      showSuccessForm: false,
      progressValue: "75",
    });
    if (
      this.state.submitData.private_email &&
      this.state.submitData.private_email !== this.state.defaultPrivateEmail
    ) {
      this.setState({
        privateEmailChanged: true,
      });
    }

    await new Promise(async (res, reject) => {
      let userToken = this.props.token;
      await axios
        .post(this.context.selectedModule === 'wb' ?API_URL_CREATE_REPORT:CM_API_URL_CREATE_REPORT, this.state.submitData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (this.state.tabs[3]) {
              this.state.tabs[3].active = true;
              this.state.tabs[2].active = false;
            }
            this.setState({
              // ...this.state,
              showSuccessForm: true,
              showFollowUpProg: false,
              progressValue: "100",
              displayFollowup: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            displayButton: true,
            showProg: false,
            statusCreateReport: false,
            displayFollowUp: true,
            showFollowUpProg: false,
          });
          this.goToPrevTab();
          this.goToPrevTab();
          for (var key in error.response.data.message) {
						switch (key) {
							case 'email': {
								if (error.response.data.message.email === 'validation.email.already_used')
									this.props.errorNotification({
										code: 'validation_error',
										message: this.props.t('Email already used.'),
									});
								break;
							}
							case 'description': {
								if (error.response.data.message.description === 'This value is too long. It should have 1000 characters or less.')
									this.props.errorNotification({
										code: 'validation_error',
										message: this.props.t('Description must have 1000 characters or less.'),
									});
								break;
							}
							default: {
								this.props.errorNotification({
									code: 'validation_error',
									message: this.props.t(error.response.data.message[key]),
								});
								break;
							}
						}
					}
        });
    });
  }

  calculateProgressValue() {
    return (
      (100 / this.state.tabs.length) *
      (this.state.tabs.findIndex((item) => item.active) + 1)
    );
  }

  findSubQuestion(questionId, subId) {
    const questions = this.state.questions;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].id === questionId) {
        for (let j = 0; j < questions[i].sub_question.length; j++) {
          if (typeof subId === "object" || typeof subId === "array") {
            if (questions[i].sub_question[j].id === subId[0].id) {
              return [questions[i].sub_question[j], subId[0]];
            }
          }
          if (questions[i].sub_question[j].id === subId) {
            return questions[i].sub_question[j];
          }
        }
      }
    }
    return null;
  }

  handleChangeRadioBox(e) {
    let input = document.getElementById("displayDateID-" + e.target.value);
    if (e.target.checked == true) {
      var rates = document.getElementsByName(e.target.name);
      // var rate_value;
      for (var i = 0; i < rates.length; i++) {
        if (rates[i].checked == false) {
          document.getElementById(
            "displayDateID-" + rates[i].value
          ).style.display = "none";
        }
      }
      input.style.display = "block";
    } else {
      input.style.display = "none";
    }

    this.setState({
      modalForCalendar: false,
    });
  }

  handleChangeCalendar(e) {
    this.setState({
      calendarValue: e,
    });
  }

  uploadAttachmentFile(e, { questionId }) {
    const formData = new FormData();
    const headers =
      this.props.user && this.props.token
        ? {
            headers: {
              Authorization: `Bearer ${this.props.token}`,
            },
          }
        : {};
    formData.append("report_type", 1);
    formData.append("file", e.target.files[0]);
    axios
      .post(this.context.selectedModule === 'wb' ? API_URL_REPORT_UPLOAD_FILE : CM_API_URL_REPORT_UPLOAD_FILE, formData, headers)
      .then((response) => {
        const file = response.data.data;
        this.setState({
          uploadedFiles: [
            ...this.state.uploadedFiles,
            {
              questionId: questionId,
              id: file.id,
              size: file.size,
              file: e.target.files[0],
            },
          ],
        });
      });
  }

  getUserHome() {
    return this.userUtils.getUserHomeRoute(this.props.user);
  }

  findActiveTabProgress() {
    return this.state.tabs.length !== 0
      ? this.state.tabs.findIndex((item) => item.active) + 1
      : 1;
  }

  async goToNextTab() {
    const { needsPrivateEmailFollowup, displayPrivateEmailFollowUp } =
      this.state;
    this.setState({ showProg: false });

    if (displayPrivateEmailFollowUp) {
      this.setState({ displayPrivateEmailFollowUp: false }, () =>
        this.finalizeReport()
      );
    }

    if (
      !this.state.isThirdPartyUser &&
      this.state.tabs.length &&
      this.state.tabs[2].active === true
    ) {
      return;
    }

    if (
      this.state.isThirdPartyUser &&
      this.props.user &&
      typeof this.props.user.email !== "undefined" &&
      this.state.tabs.length &&
      this.state.tabs[2].active === true
    ) {
      return;
    }

    if (
      this.state.isThirdPartyUser &&
      this.props.user &&
      typeof this.props.user.email === "undefined" &&
      this.state.tabs.length &&
      this.state.tabs[3].active === true
    ) {
      return;
    }
    // console.log("4");
    this.setState({
      showSuccessForm: this.state.displayFollowup ? false : true,
    });

    const activeTabIndex = this.state.tabs.findIndex((item) => item.active);
    const lastIndex = this.state.tabs.length - 1;
    // console.log("ActivEIndex", activeTabIndex, "lastIndex", lastIndex);
    if (needsPrivateEmailFollowup && activeTabIndex === 1 && lastIndex === 3) {
      // console.log("private_email");
      this.setState({ displayPrivateEmailFollowUp: true });
    }
    new Promise((res, rej) => {
      if (lastIndex - 1 === activeTabIndex) {
        // we should finalize
        // console.log("we should finalize");
        // const nex = this.state.tabs.length === 3 ? this.finalizeReport() : null;
        // console.log("Active Tab", activeTabIndex, "Last Index", lastIndex);
        this.finalizeReport(); // Active Tab 1 Last Index 2
        if (activeTabIndex === 1 && lastIndex === 2) {
          res();
        }
        // res();
        // if (this.state.isThirdPartyUser) {
        // 	if (!this.state.loginStatus) {
        // 		console.log("we should finalize");
        // this.finalizeReport();
        // 		res();
        // 	}
        // } else {
        // 	this.finalizeReport();
        // 	res();
        // }
      } else {
        res();
      }
    }).then((res) => {
      const newTabs = this.state.tabs.map((item, index) => {
        return {
          ...item,
          active: index === activeTabIndex + 1 ? true : false,
        };
      });
      this.setState(
        {
          tabs: newTabs,
        },
        function () {
          this.setState({
            progressValue: this.calculateProgressValue(),
          });
        }
      );
    });
  }

  goToPrevTab() {
    const activeTabIndex = this.state.tabs.findIndex((item) => item.active);
    if (activeTabIndex > 0) {
      const newTabs = this.state.tabs.map((item, index) => {
        return {
          ...item,
          active: index === activeTabIndex - 1 ? true : false,
        };
      });
      this.setState(
        {
          tabs: newTabs,
        },
        function () {
          this.setState({
            progressValue: this.calculateProgressValue(),
          });
        }
      );
    }
  }

  generateRandomUsername = async () => {
    this.setState({ username: null });
    const api =
      API_URL_GENERATE_RANDOM_USERNAME + localStorage.getItem("i18nextLng");
    axios.get(api).then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        this.setState({
          randomGeneratedUsername: data,
          randomGenerated: true,
          authUserInfos: {
            ...this.state.authUserInfos,
            username: data,
          },
        });
      }
    });
  };

  // scrollToInvalidField(fieldRef) {
  //   fieldRef.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // }

  scrollToInvalidField(fieldRef) {
    const fieldRect = fieldRef.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const targetTop = fieldRect.top + scrollTop - 100; // Adjust the scroll offset as needed

    window.scrollTo({
      top: targetTop,
      behavior: "smooth",
    });
  }

  // handleInvalidSubmit = (e) => {
  //   const formElements = Array.from(this.formRef.current.elements);
  //   const invalidFields = formElements.filter(
  //     (field) => field.nodeName !== "BUTTON" && !field.checkValidity()
  //   );

  //   if (invalidFields.length > 0) {
  //     const firstInvalidField = invalidFields[0];
  //     this.scrollToInvalidField(firstInvalidField);
  //   }
  // };

  handleClickViewFiles = (type) => {
    var xhr = new XMLHttpRequest();
    const selectedLang = localStorage.getItem("i18nextLng");
    const orgId = Number(localStorage.getItem("orgId"));
    console.log(this.props.Organization);
    const languageId = Number(
      this.props?.Organization?.languages.filter(
        (lang) => lang.language_code === selectedLang
      )[0]?.language?.id
    );

    const queryParams = {
      language: languageId,
      organization: orgId,
      type: type,
    };

    // Serialize the query parameters into a string
    const queryString = Object.keys(queryParams)
      .map((key) => key + "=" + queryParams[key])
      .join("&");

    const token = `Bearer ${this.props.token}`;
    const url = `${AUTH_API_DOMAIN_URL}user_manual/download?${queryString}`;
    xhr.open("GET", url, true);
    // xhr.setRequestHeader("Authorization", token);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        // link.download = name;
        // link.click();
        window.open(link);
      }
    };
    xhr.send();
  };

  render() {
    // const tests = this.state.locations;

    const {
      displayFollowup,
      displayPrivateEmailFollowUp,
      needsPrivateEmailFollowup,
    } = this.state;
    let tabId = 1;
    // console.log('(this.state.isThirdPartyUser && this.props.user && typeof this.props.user.email === "undefined" ) && this.state.tabs[3].active === true');

    // this.state.tabs.length && console.log( this.state.tabs[3].active)
    // console.log((this.state.isThirdPartyUser && this.props.user && typeof this.props.user.email === "undefined" ) && this.state.tabs[3].active === true);

    // console.log(this.state.submitData)
    // console.log(this.state.tabs);

    // if(this.props.user && (typeof this.props.user.email !== "undefined")) {
    // 	console.log( "emptyyyyyyyyyyyyyyyyyyyy")
    // 	console.log(this.props.user.email)
    // } else {
    // 	console.log( "filllllllll")
    // }
    return (
      <React.Fragment>
        {/* <Modal isOpen={this.state.modalForCalendar} toggle={this.handleChangeRadioBox} className={this.props.className}>
					<ModalHeader toggle={this.handleChangeRadioBox}>Modal title</ModalHeader>
					<ModalBody>
						<Calendar onChange={this.handleChangeCalendar} value={this.state.calendarValue} />
					</ModalBody>
				</Modal> */}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Report anonymously")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* {this.sortedNames().map((location) => {
											return (
												<p key={location.id} value={location.id}>
													{this.props.t(location.name)}
												</p>
											);
										})} */}
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        {this.state.tabs.map((tab) => {
                          return (
                            <NavItem key={tab.tabId}>
                              <NavLink
                                className={classnames({ active: tab.active })}
                              >
                                <span className="step-number">{tab.tabId}</span>
                                <span className="step-title">{tab.title}</span>
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                      </div>

                      <TabContent
                        activeTab={this.findActiveTabProgress()}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={tabId++}>
                          {/* <div
                            ref={(el) => {
                              this.formContainer = el;
                            }}
                          > */}
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.handleSubmit}
                            onInvalidSubmit={(e) => {
                              const result = [];
                              const invalidFields = Object.keys(
                                e.target.elements
                              ).map((fieldName) => {
                                if (
                                  e.target.elements[fieldName].validity &&
                                  !e.target.elements[fieldName].validity.valid
                                ) {
                                  result.push(fieldName);
                                  return (
                                    e.target.elements[fieldName].validity
                                      .valid === false
                                  );
                                }
                              });
                              // invalidFields.map((item) => {
                              //   console.log(
                              //     item,
                              //     e.target.elements[item].validity.valid
                              //   );
                              // });
                              // console.log(
                              //   "Invalid",
                              //   invalidFields,
                              //   e.target.elements
                              // );
                              //
                              if (result.length > 0) {
                                const firstInvalidField =
                                  e.target.elements[result[0]];
                                console.log(firstInvalidField);
                                this.scrollToInvalidField(firstInvalidField);
                              }
                            }}
                            ref={(formRef) => {
                              this.formRef = formRef;
                            }}
                          >
                            <Row>
                              <Col sm="12" lg="9">
                                {
                                  <Row>
                                    <Col sm="12">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="description"
                                        >
                                          {this.context.selectedModule === 'wb' ? this.props.t(
                                            "Brief Description of Incident"
                                          ) : this.props.t(
                                            "Title of your complaint"
                                          )}
                                        </Label>
                                        <AvField
                                          name="description"
                                          placeholder=""
                                          type="text"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="description"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                }
                                {this.urlUtils.getUrlParamThirdParty() !==
                                  "third-party" &&
                                  this.userUtils.isEmployee(this.props.user) ===
                                    false && (
                                    <Row>
                                      <Col sm="12" lg="12">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label"
                                            htmlFor="source"
                                          >
                                            {this.props.t(
                                              "Choose the channel of information"
                                            )}
                                          </Label>
                                          <AvField
                                            id="source"
                                            type="select"
                                            placeholder={this.props.t(
                                              "Choose the channel of information"
                                            )}
                                            name="source"
                                            errorMessage={this.props.t(
                                              "This field cannot be blank"
                                            )}
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={"email"}
                                          >
                                            <option value="web">
                                              {this.props.t("Web")}
                                            </option>
                                            <option value="email">
                                              {this.props.t("Email")}
                                            </option>
                                            <option value="letter">
                                              {this.props.t("Letter")}
                                            </option>
                                            <option value="phone">
                                              {this.props.t("Phone")}
                                            </option>
                                            <option value="meeting">
                                              {this.props.t("Meeting")}
                                            </option>
                                            <option value="live_chat">
                                              {this.props.t("Live Chat")}
                                            </option>
                                            <option value="other">
                                              {this.props.t(
                                                `${this.props.t("Other")}`
                                              )}
                                            </option>
                                          </AvField>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}

                                {
                                  <Row>
                                    <Col sm="12">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor={`question-category`}
                                        >
                                          {this.props.t(
                                            "In your opinion, the facts of the case fall into the following category:"
                                          )}
                                        </Label>
                                        <AvCheckboxGroup
                                          name="Question_Category"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          validate={{
                                            required: { value: true },
                                          }}
                                        >
                                          <Row>
                                            {this.state.categories.map(
                                              (category, catIndex) => {
                                                return (
                                                  <Col
                                                    sm="12"
                                                    md="6"
                                                    key={category.id + catIndex}
                                                  >
                                                    <AvCheckbox
                                                      label={this.props.t(
                                                        category.title
                                                      )}
                                                      value={category.id}
                                                    />
                                                  </Col>
                                                );
                                              }
                                            )}
                                          </Row>
                                        </AvCheckboxGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                }

                                {
                                  <Row>
                                    <Col sm="12">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="locations"
                                        >
                                          {this.props.t(
                                            "Which location(s) is / are affected?"
                                          )}
                                        </Label>
                                        <AvField
                                          id="locations"
                                          type="select"
                                          name="locations"
                                          errorMessage={this.props.t(
                                            "This field cannot be blank"
                                          )}
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          defaultValue={
                                            this.state.organizationCountry
                                          }
                                          value={this.state.organizationCountry}
                                        >
                                          {this.sortLocationsByLanguage(
                                            localStorage.getItem("i18nextLng"),
                                            this.state.locations
                                          ).map((location) => {
                                            return (
                                              <option
                                                key={location.id}
                                                value={location.id}
                                                hidden={!location?.name}
                                              >
                                                {this.props.t(location.name)}
                                              </option>
                                            );
                                          })}
                                        </AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                }
                                {this.state.questions.map((question, index) => {
                                  // console.log(question)
                                  const id = question.id;
                                  const title = question.title || "";
                                  const type = question.type;
                                  const key = id + index;
                                  const afterLogin = question.after_login;

                                  if (
                                    type === "textarea" ||
                                    type === "text" ||
                                    type === "date"
                                  ) {
                                    if (afterLogin === "none") {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvField
                                                name={"Questien[" + id + "]"}
                                                placeholder=""
                                                type={type}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: question?.isMandatory,
                                                  },
                                                }}
                                                id={`question-${id}`}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === true &&
                                      afterLogin === "true"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvField
                                                name={"Questien[" + id + "]"}
                                                placeholder=""
                                                type={type}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                id={`question-${id}`}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === false &&
                                      afterLogin === "false"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvField
                                                name={"Questien[" + id + "]"}
                                                placeholder=""
                                                type={type}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                id={`question-${id}`}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  } else if (type === "file") {
                                    return (
                                      <Row key={key}>
                                        <Col sm="12">
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor={`question-${id}`}
                                            >
                                              {`${this.props.t(
                                                "Attachments"
                                              )} ${this.props.t("(Optional)")}`}
                                            </Label>
                                            <div
                                              style={{
                                                position: "relative",
                                              }}
                                            >
                                              <Dropzone
                                                onDrop={(acceptedFiles) =>
                                                  this.handleAcceptedFiles(
                                                    acceptedFiles,
                                                    id
                                                  )
                                                }
                                                accept={
                                                  this.dropzoneAcceptedFiles
                                                    .accepted
                                                }
                                              >
                                                {({
                                                  getRootProps,
                                                  getInputProps,
                                                }) => (
                                                  <div className="dropzone">
                                                    <div
                                                      className="dz-message needsclick"
                                                      {...getRootProps()}
                                                    >
                                                      <input
                                                        {...getInputProps()}
                                                      />
                                                      <div className="mb-3">
                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                      </div>
                                                      <h4>
                                                        {this.props.t(
                                                          "Drop files here or click to upload"
                                                        )}
                                                      </h4>
                                                    </div>
                                                  </div>
                                                )}
                                              </Dropzone>
                                              <Button
                                                id="dropzone-popover"
                                                className="button"
                                                type="button"
                                                color="link"
                                                style={{
                                                  position: "absolute",
                                                  right: 8,
                                                  bottom: 6,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.setState({
                                                    popover: {
                                                      id: "dropzone-popover",
                                                      active:
                                                        !this.state.popover
                                                          .active,
                                                    },
                                                  })
                                                }
                                              >
                                                <i
                                                  className="ri-information-line"
                                                  style={{ fontSize: 18 }}
                                                ></i>
                                              </Button>
                                              <Popover
                                                placement="bottom"
                                                target="dropzone-popover"
                                                isOpen={
                                                  this.state.popover.id ===
                                                    "dropzone-popover" &&
                                                  this.state.popover.active
                                                }
                                                toggle={() =>
                                                  this.setState({
                                                    popover: {
                                                      id: "dropzone-popover",
                                                      active:
                                                        !this.state.popover
                                                          .active,
                                                    },
                                                  })
                                                }
                                              >
                                                <PopoverHeader>
                                                  {this.props.t(
                                                    "Allowed Formats"
                                                  )}
                                                </PopoverHeader>
                                                <PopoverBody>
                                                  <span>
                                                    {this.props.t(
                                                      "You are allowed to upload limited file types. for more information, visit"
                                                    )}{" "}
                                                  </span>
                                                  <a
                                                    href="https://diss-co.tech/faq-diss-co/"
                                                    target="_blank"
                                                  >
                                                    {this.props.t("FAQ page")}
                                                  </a>
                                                </PopoverBody>
                                              </Popover>
                                            </div>
                                            <br />
                                            <Progress
                                              color="info"
                                              striped
                                              animated
                                              value={100}
                                              hidden={!this.state.showProg}
                                            >
                                              {this.props.t(
                                                "Please wait while the file is uploaded"
                                              )}
                                            </Progress>
                                            <div
                                              className="dropzone-previews mt-3"
                                              id="file-previews"
                                            >
                                              {this.state.uploadedFiles.map(
                                                (f, i) => {
                                                  return (
                                                    <Card
                                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                      key={i + "-file"}
                                                    >
                                                      <div className="p-2">
                                                        <Row className="align-items-center">
                                                          <Col>
                                                            <Link
                                                              to="#"
                                                              className="text-muted fw-bold"
                                                            >
                                                              {f.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                              <strong>
                                                                {
                                                                  f.formattedSize
                                                                }
                                                              </strong>
                                                            </p>
                                                          </Col>
                                                          <Col
                                                            xs={2}
                                                            style={{
                                                              textAlign:
                                                                "right",
                                                            }}
                                                          >
                                                            <Button
                                                              color="danger"
                                                              size="sm"
                                                              onClick={() =>
                                                                this.handleClickDeleteFiles(
                                                                  f
                                                                )
                                                              }
                                                            >
                                                              X
                                                            </Button>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </Card>
                                                  );
                                                }
                                              )}
                                              {false && (
                                                <p
                                                  style={{
                                                    color: "red",
                                                    fontSize: 14,
                                                  }}
                                                  className="mt-3"
                                                >
                                                  {this.props.t(
                                                    "The file type is not supported by our system that removes meta data of your file to ensure your anonymity. Please see the FAQ to learn more about the supported file types"
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                            {/* <FileUploader 
                                                                                                name="temp"
                                                                                                placeholder=""
                                                                                                errorMessage={this.props.t("This field cannot be blank")}
                                                                                                validate={{ required: { value: false } }}
                                                                                                id={`question-${id}`}
                                                                                                callbackProps={{questionId: id}}
                                                                                                translates={{ 
                                                                                                    btnTitle: this.props.t("Choose File"),
                                                                                                    noFileChoosen: this.props.t("No file chosen") 
                                                                                                }}
                                                                                                onChangeEvent={this.uploadAttachmentFile}
                                                                                            /> */}
                                          </div>
                                        </Col>
                                      </Row>
                                    );
                                  } else if (type === "checkbox") {
                                    return (
                                      <Row key={key}>
                                        <Col sm="12">
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor={`question-${id}`}
                                            >
                                              {this.props.t(title)}
                                            </Label>
                                            <AvCheckboxGroup
                                              name={"Questien[" + id + "]"}
                                              errorMessage={this.props.t(
                                                "This field cannot be blank"
                                              )}
                                              validate={{
                                                required: { value: question?.isMandatory, },
                                              }}
                                            >
                                              <Row>
                                                {question.sub_question.map(
                                                  (subQuestion, subIndex) => {
                                                    return (
                                                      <Col
                                                        sm="12"
                                                        md="6"
                                                        key={
                                                          subQuestion.id +
                                                          subIndex
                                                        }
                                                      >
                                                        <AvCheckbox
                                                          label={this.props.t(
                                                            subQuestion.label
                                                          )}
                                                          value={subQuestion.id}
                                                        />
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </Row>
                                            </AvCheckboxGroup>
                                          </div>
                                        </Col>
                                      </Row>
                                    );
                                  } else if (type === "radiobox") {
                                    if (afterLogin === "none") {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvRadioGroup
                                                name={"Questien[" + id + "]"}
                                                validate={{
                                                  required: { value: question?.isMandatory, },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvRadio
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeRadioBox
                                                            }
                                                          />
                                                          <div
                                                            id={
                                                              "displayDateID-" +
                                                              subQuestion.id
                                                            }
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {subQuestion.type ===
                                                              "date" && (
                                                              <DateInputBefor
                                                                name="date"
                                                                placeholder=""
                                                                id={
                                                                  "date-" +
                                                                  subQuestion.id
                                                                }
                                                                value={
                                                                  this.state
                                                                    .selectedDay
                                                                }
                                                                onValueChanged={(
                                                                  value
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      selectedDay:
                                                                        value,
                                                                    }
                                                                  )
                                                                }
                                                                startFrom={
                                                                  this.state
                                                                    .avToDateStartFrom
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === true &&
                                      afterLogin === "true"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvRadioGroup
                                                name={"Questien[" + id + "]"}
                                                validate={{
                                                  required: { value: question?.isMandatory, },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvRadio
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeRadioBox
                                                            }
                                                          />
                                                          <div
                                                            id={
                                                              "displayDateID-" +
                                                              subQuestion.id
                                                            }
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {subQuestion.type ===
                                                              "date" && (
                                                              <DateInputBefor
                                                                name="date"
                                                                placeholder=""
                                                                id={
                                                                  "date-" +
                                                                  subQuestion.id
                                                                }
                                                                value={
                                                                  this.state
                                                                    .selectedDay
                                                                }
                                                                onValueChanged={(
                                                                  value
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      selectedDay:
                                                                        value,
                                                                    }
                                                                  )
                                                                }
                                                                startFrom={
                                                                  this.state
                                                                    .avToDateStartFrom
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    if (
                                      this.state.statusLogin === false &&
                                      afterLogin === "false"
                                    ) {
                                      return (
                                        <Row key={key}>
                                          <Col sm="12">
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor={`question-${id}`}
                                              >
                                                {this.props.t(title)}
                                              </Label>
                                              <AvRadioGroup
                                                name={"Questien[" + id + "]"}
                                                validate={{
                                                  required: { value: question?.isMandatory, },
                                                }}
                                                errorMessage={this.props.t(
                                                  "This field cannot be blank"
                                                )}
                                              >
                                                <Row>
                                                  {question.sub_question.map(
                                                    (subQuestion, subIndex) => {
                                                      return (
                                                        <Col
                                                          sm="12"
                                                          md="6"
                                                          key={
                                                            subQuestion.id +
                                                            subIndex
                                                          }
                                                        >
                                                          <AvRadio
                                                            label={this.props.t(
                                                              subQuestion.label
                                                            )}
                                                            value={
                                                              subQuestion.id
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeRadioBox
                                                            }
                                                          />
                                                          <div
                                                            id={
                                                              "displayDateID-" +
                                                              subQuestion.id
                                                            }
                                                            style={{
                                                              display: "none",
                                                            }}
                                                          >
                                                            {subQuestion.type ===
                                                              "date" && (
                                                              <DateInputBefor
                                                                name="date"
                                                                placeholder=""
                                                                id={
                                                                  "date-" +
                                                                  subQuestion.id
                                                                }
                                                                value={
                                                                  this.state
                                                                    .selectedDay
                                                                }
                                                                onValueChanged={(
                                                                  value
                                                                ) =>
                                                                  this.setState(
                                                                    {
                                                                      selectedDay:
                                                                        value,
                                                                    }
                                                                  )
                                                                }
                                                                startFrom={
                                                                  this.state
                                                                    .avToDateStartFrom
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        </Col>
                                                      );
                                                    }
                                                  )}
                                                </Row>
                                              </AvRadioGroup>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  } else {
                                    return null;
                                  }
                                })}

                                {/* <Row className="mt-3 mb-3" hidden={this.userUtils.isAnalystOrAnalystAdmin(this.props.user)}>
                                  <FormGroup
                                    row
                                    display="flex"
                                    flexDirection="row"
                                  >
                                    <Col
                                      sm="12"
                                      md="6"
                                      lg="1"
                                      className="consCol"
                                    >
                                      <Input
                                        type="checkbox"
                                        name="consent"
                                        id="consent"
                                        onChange={() =>
                                          this.setState(
                                            {
                                              consentChecked:
                                                !this.state.consentChecked,
                                            }
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col sm="12" md="6" lg="11">
                                      <Label
                                        className="consLabel"
                                        // for="consent"
                                      >
                                        <span>
                                          {this.props.t("I consent")}{" "}
                                        </span>
                                        <span
                                          className="text-primary"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.handleClickViewFiles(
                                              "user_policy"
                                            )
                                          }
                                        >
                                          {this.props.t("User policy")}{" "}
                                        </span>
                                        <span>{this.props.t("and")} </span>
                                        <span
                                          className="text-primary"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.handleClickViewFiles(
                                              "privacy_policy"
                                            )
                                          }
                                        >
                                          {this.props.t("Privacy policy")}{" "}
                                        </span>
                                      </Label>
                                    </Col>
                                  </FormGroup>
                                </Row> */}

                                <Row>
                                  <Col sm="12 m-t-15">
                                    <Button
                                      color="btn btn-primary"
                                      type="submit"
                                      disabled={
                                        this.state.showProg ||
                                        (!this.state.consentChecked &&
                                          !this.userUtils.isAnalystOrAnalystAdmin(
                                            this.props.user
                                          ))
                                      }
                                      onClick={() =>
                                        this.setState({
                                          popover: {
                                            id: "dropzone-popover",
                                            active: false,
                                          },
                                        })
                                      }
                                    >
                                      {this.props.t("Next")}
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                          {/* </div> */}
                        </TabPane>
                        <TabPane tabId={tabId++} title="review-report">
                          {this.state.submitData ? (
                            <div>
                              <Row>
                                <Col sm="12">
                                  <div>
                                    <Label className="form-label">
                                      {this.context.selectedModule === 'wb' ? this.props.t(
                                        "Brief Description of Incident"
                                        ) : this.props.t(
                                          "Title of your complaint"
                                      )}
                                    </Label>
                                    <p className="text-answers">
                                      {this.state.submitData.description}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="12">
                                  <Label className="form-label">
                                    {this.props.t("Source")}
                                  </Label>
                                  <p className="text-answers">
                                    {this.state.submitData.source
                                      ? this.props.t(
                                          this.state.submitData.source
                                        )
                                      : this.props.t("Web")}
                                  </p>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="12">
                                  <div>
                                    <Label className="form-label">
                                      {this.props.t(
                                        "In your opinion, the facts of the case fall into the following category:"
                                      )}
                                    </Label>
                                    <Row>
                                      {this.state.submitData.category.map(
                                        (categoryId, catIndex) => {
                                          const category =
                                            this.state.categories[
                                              this.state.categories.findIndex(
                                                (item) => item.id === categoryId
                                              )
                                            ];
                                          return (
                                            <Col
                                              sm="12"
                                              md="6"
                                              key={categoryId + catIndex}
                                            >
                                              <p className="text-dark">
                                                <i className="fa fa-check"></i>
                                                {" " +
                                                  this.props.t(category.title)}
                                              </p>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              {this.displayEnteredWhitchLocations()}

                              <Row>
                                <Col sm="12">
                                  {this.state.questions.map(
                                    (question, index) => {
                                      const id = question.id;
                                      const title = question.title || "";
                                      const type = question.type;
                                      const key = id + index;
                                      const userAnswers =
                                        this.findUserAnswer(question);
                                      const afterLogin = question.after_login;

                                      if (
                                        type === "textarea" ||
                                        type === "text"
                                      ) {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {userAnswers}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "file") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.state.uploadedFiles
                                                      .length > 0 &&
                                                      this.state.uploadedFiles.map(
                                                        (f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={
                                                                        f.name
                                                                      }
                                                                      src={
                                                                        f.preview
                                                                      }
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted fw-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>
                                                                        {
                                                                          f.formattedSize
                                                                        }
                                                                      </strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "date") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <p className="text-answers">
                                                    {this.dateUtils.convertDateToDate(
                                                      userAnswers,
                                                      INTERNATIONAL_DATE_FORMAT
                                                    )}
                                                  </p>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "checkbox") {
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    {userAnswers.map(
                                                      (
                                                        subQuestionAnswer,
                                                        subAnswerIndex
                                                      ) => {
                                                        const subQuestionInfos =
                                                          this.findSubQuestion(
                                                            id,
                                                            subQuestionAnswer
                                                          );
                                                        return (
                                                          <Col
                                                            sm="12"
                                                            md="6"
                                                            key={subAnswerIndex}
                                                          >
                                                            <p className="text-dark">
                                                              <i className="fa fa-check"></i>
                                                              {" " +
                                                                this.props.t(
                                                                  subQuestionInfos.label
                                                                )}
                                                            </p>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else if (type === "radiobox") {
                                        const subQuestionInfos =
                                          this.findSubQuestion(id, userAnswers);
                                        if (afterLogin === "none") {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <Label
                                                        className="form-label"
                                                        style={{
                                                          marginLeft: "15px",
                                                        }}
                                                      >
                                                        <i
                                                          className="fa fa-check"
                                                          style={{
                                                            marginRight: "8px",
                                                          }}
                                                        ></i>
                                                        {this.props.t(
                                                          subQuestionInfos.label
                                                            ? subQuestionInfos.label
                                                            : subQuestionInfos[0]
                                                                .label
                                                        )}
                                                      </Label>
                                                      <p
                                                        className="text-dark"
                                                        style={{
                                                          marginLeft: "30px",
                                                        }}
                                                      >
                                                        <span>
                                                          {subQuestionInfos.label
                                                            ? ""
                                                            : subQuestionInfos[1]
                                                                .date}
                                                        </span>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === true &&
                                          afterLogin === "true"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <Label
                                                        className="form-label"
                                                        style={{
                                                          marginLeft: "15px",
                                                        }}
                                                      >
                                                        <i
                                                          className="fa fa-check"
                                                          style={{
                                                            marginRight: "8px",
                                                          }}
                                                        ></i>
                                                        {this.props.t(
                                                          subQuestionInfos.label
                                                            ? subQuestionInfos.label
                                                            : subQuestionInfos[0]
                                                                .label
                                                        )}
                                                      </Label>
                                                      <p
                                                        className="text-dark"
                                                        style={{
                                                          marginLeft: "30px",
                                                        }}
                                                      >
                                                        <span>
                                                          {subQuestionInfos.label
                                                            ? ""
                                                            : subQuestionInfos[1]
                                                                .date}
                                                        </span>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                        if (
                                          this.state.statusLogin === false &&
                                          afterLogin === "false"
                                        ) {
                                          return (
                                            <Row key={key}>
                                              <Col sm="12" lg="6">
                                                <div>
                                                  <Label className="form-label">
                                                    {this.props.t(title)}
                                                  </Label>
                                                  <Row>
                                                    <Col sm="12" md="6">
                                                      <Label
                                                        className="form-label"
                                                        style={{
                                                          marginLeft: "15px",
                                                        }}
                                                      >
                                                        <i
                                                          className="fa fa-check"
                                                          style={{
                                                            marginRight: "8px",
                                                          }}
                                                        ></i>
                                                        {this.props.t(
                                                          subQuestionInfos.label
                                                            ? subQuestionInfos.label
                                                            : subQuestionInfos[0]
                                                                .label
                                                        )}
                                                      </Label>
                                                      <p
                                                        className="text-dark"
                                                        style={{
                                                          marginLeft: "30px",
                                                        }}
                                                      >
                                                        <span>
                                                          {subQuestionInfos.label
                                                            ? ""
                                                            : subQuestionInfos[1]
                                                                .date}
                                                        </span>
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="12 m-t-15">
                                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li className="previous">
                                      <Button
                                        color="light"
                                        outline
                                        onClick={() => this.goToPrevTab()}
                                      >
                                        {this.props.t("Back")}
                                      </Button>
                                    </li>
                                    <li className="next">
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          this.goToNextTab();
                                        }}
                                        className="btn btn-primary"
                                      >
                                        {displayFollowup ||
                                        displayPrivateEmailFollowUp
                                          ? this.props.t("Next")
                                          : this.props.t("Submit")}
                                      </Link>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                        </TabPane>
                        {/* {console.log(displayPrivateEmailFollowUp)} */}
                        {needsPrivateEmailFollowup &&
                        displayPrivateEmailFollowUp ? (
                          <TabPane tabId={tabId++}>
                            <AvForm
                              className="needs-validation"
                              // onValidSubmit={this.handleFollowUpForm}
                              onValidSubmit={this.goToNextTab}
                              onInvalidSubmit={() =>
                                this.setState({ showProg: false })
                              }
                            >
                              <Row>
                                <Col sm="12" md="6" lg="4">
                                  <h5>{this.props.t("Follow-Up")}</h5>
                                  <p>
                                    {this.props.t(
                                      "To get notified about your report, we would need your email."
                                    )}
                                    {this.props.t(
                                      "If you do not provide your email you will have to log back into the system for any updates about your report with the username and password you provided"
                                    )}
                                  </p>
                                  <p style={{ marginTop: 33 }}>
                                    {this.props.t(
                                      "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                                    )}
                                  </p>
                                </Col>
                                <Col sm="12" md="6" lg="8">
                                  {this.renderPrivateEmailField()}
                                </Col>
                              </Row>
                              {/* email field: SPIK-1471 */}
                              {/* {typeof this.props.user?.email ===
                                "undefined" ? (
                                  <div>
                                    <span
                                      style={{
                                        width: "100%",
                                        height: 1,
                                        display: "block",
                                        backgroundColor: "#eee",
                                        margin: "12px 0 20px",
                                      }}
                                    />
                                    <Row>
                                      <Col sm="12" md="6" lg="4">
                                        <h5>{this.props.t("Follow-Up")}</h5>
                                        <p style={{ marginTop: 33 }}>
                                          {this.props.t(
                                            "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                                          )}
                                        </p>
                                        <p>
                                          {this.props.t(
                                            "To get notified about your report, we would need your email."
                                          )}
                                          {this.props.t(
                                            "If you do not provide your email you will have to log back into the system for any updates about your report with the username and password you provided"
                                          )}
                                        </p>
                                      </Col>
                                      <Col sm="12" md="6" lg="8">
                                        <div>
                                          <Label
                                            className="form-label"
                                            htmlFor="email"
                                          >
                                            {`${this.props.t(
                                              "Email"
                                            )} ${this.props.t("(Optional)")}`}
                                            <span className="ms-2">
                                              <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                                id="email-infobox-icon"
                                              />
                                              <Tooltip
                                                placement="top"
                                                isOpen={
                                                  this.state.emailTooltipOpen
                                                }
                                                target="email-infobox-icon"
                                                toggle={() =>
                                                  this.setState({
                                                    emailTooltipOpen:
                                                      !this.state
                                                        .emailTooltipOpen,
                                                  })
                                                }
                                              >
                                                {this.props.t(
                                                  "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                                                )}
                                                .
                                              </Tooltip>
                                            </span>
                                          </Label>
                                          <AvField
                                            name="email"
                                            placeholder=""
                                            type="email"
                                            className="form-control"
                                            errorMessage={this.props.t(
                                              "This field is invalid"
                                            )}
                                            validate={{
                                              required: { value: false },
                                              email: true,
                                            }}
                                            id="email"
                                            onChange={(e) =>
                                              this.setState({
                                                authUserInfos: {
                                                  ...this.state.authUserInfos,
                                                  email: e.target.value,
                                                },
                                                submitData: {
                                                  ...this.state.submitData,
                                                  email: e.target.value,
                                                },
                                              })
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ) : (
                                  ""
                                )} */}

                              <br />
                              <Progress
                                bar
                                animated
                                color="info"
                                value="100"
                                hidden={!this.state.showProg}
                              >
                                {this.props.t(
                                  "Please wait. We are processing your request."
                                )}
                              </Progress>

                              <Row>
                                <Col sm="12 m-t-15">
                                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li className="previous">
                                      <Button
                                        color="light"
                                        outline
                                        onClick={() => this.goToPrevTab()}
                                      >
                                        {this.props.t("Back")}
                                      </Button>
                                    </li>
                                    <li className="next">
                                      {this.state.isThirdPartyUser &&
                                      this.props.user &&
                                      typeof this.props.user.email ===
                                        "undefined" &&
                                      this.state.tabs.length &&
                                      this.state.tabs[2].active === true ? (
                                        <Button
                                          color="btn btn-primary"
                                          type="button"
                                          disabled={!this.state.displayButton}
                                          onClick={() => {
                                            // this.setState({ showProg: true });
                                            this.goToNextTab();
                                          }}
                                        >
                                          {this.props.t("Submit")}
                                        </Button>
                                      ) : (
                                        <Button
                                          color="btn btn-primary"
                                          disabled={!this.state.displayButton}
                                          type="submit"
                                          // onClick={() => {
                                          //   this.goToNextTab();
                                          //   this.setState({
                                          //     showProg: true,
                                          //     displayButton: false,
                                          //   });
                                          // }}
                                        >
                                          {this.props.t("Submit")}
                                        </Button>
                                      )}
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </AvForm>
                          </TabPane>
                        ) : null}
                        {
                          displayFollowup ? (
                            <TabPane tabId={tabId++}>
                              <AvForm
                                className="needs-validation"
                                // onValidSubmit={this.handleFollowUpForm}
                                onValidSubmit={this.goToNextTab}
                                onInvalidSubmit={() =>
                                  this.setState({ showProg: false })
                                }
                              >
                                <Row>
                                  <Col sm="12" md="6" lg="4"></Col>
                                  <Col sm="12" md="6" lg="8">
                                    {this.renderRegisterForm()}
                                  </Col>
                                </Row>

                                {/* email field: SPIK-1471 */}
                                {typeof this.props.user?.email ===
                                "undefined" ? (
                                  <div>
                                    <span
                                      style={{
                                        width: "100%",
                                        height: 1,
                                        display: "block",
                                        backgroundColor: "#eee",
                                        margin: "12px 0 20px",
                                      }}
                                    />
                                    <Row>
                                      <Col sm="12" md="6" lg="4">
                                        <h5>{this.props.t("Follow-Up")}</h5>

                                        <p>
                                          {this.props.t(
                                            "To get notified about your report, we would need your email."
                                          )}
                                          {this.props.t(
                                            "If you do not provide your email you will have to log back into the system for any updates about your report with the username and password you provided"
                                          )}
                                        </p>
                                        <p style={{ marginTop: 33 }}>
                                          {this.props.t(
                                            "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                                          )}
                                        </p>
                                      </Col>
                                      <Col sm="12" md="6" lg="8">
                                        <div>
                                          <Label
                                            className="form-label"
                                            htmlFor="email"
                                          >
                                            {`${this.props.t(
                                              "Email"
                                            )} ${this.props.t("(Optional)")}`}
                                            <span className="ms-2">
                                              <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                                id="email-infobox-icon"
                                              />
                                              <Tooltip
                                                placement="top"
                                                isOpen={
                                                  this.state.emailTooltipOpen
                                                }
                                                target="email-infobox-icon"
                                                toggle={() =>
                                                  this.setState({
                                                    emailTooltipOpen:
                                                      !this.state
                                                        .emailTooltipOpen,
                                                  })
                                                }
                                              >
                                                {this.props.t(
                                                  "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                                                )}
                                                .
                                              </Tooltip>
                                            </span>
                                          </Label>
                                          <AvField
                                            name="email"
                                            placeholder=""
                                            type="email"
                                            className="form-control"
                                            errorMessage={this.props.t(
                                              "This field is invalid"
                                            )}
                                            validate={{
                                              required: { value: false },
                                              email: true,
                                            }}
                                            id="email"
                                            onChange={(e) =>
                                              this.setState({
                                                authUserInfos: {
                                                  ...this.state.authUserInfos,
                                                  email: e.target.value,
                                                },
                                                submitData: {
                                                  ...this.state.submitData,
                                                  email: e.target.value,
                                                },
                                              })
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <br />
                                <Progress
                                  bar
                                  animated
                                  color="info"
                                  value="100"
                                  hidden={!this.state.showProg}
                                >
                                  {this.props.t(
                                    "Please wait. We are processing your request."
                                  )}
                                </Progress>

                                <Row>
                                  <Col sm="12 m-t-15">
                                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                                      <li className="previous">
                                        <Button
                                          color="light"
                                          outline
                                          onClick={() => this.goToPrevTab()}
                                        >
                                          {this.props.t("Back")}
                                        </Button>
                                      </li>
                                      <li className="next">
                                        {this.state.isThirdPartyUser &&
                                        this.props.user &&
                                        typeof this.props.user.email ===
                                          "undefined" &&
                                        this.state.tabs.length &&
                                        this.state.tabs[2].active === true ? (
                                          <Button
                                            color="btn btn-primary"
                                            type="button"
                                            disabled={
                                              !this.state.displayButton
                                              //  ||
                                              // !this.state.consentChecked
                                            }
                                            onClick={() => {
                                              // this.setState({ showProg: true });
                                              this.goToNextTab();
                                            }}
                                          >
                                            {this.props.t("Submit")}
                                          </Button>
                                        ) : (
                                          <Button
                                            color="btn btn-primary"
                                            disabled={
                                              !this.state.displayButton
                                              // ||
                                              // !this.state.consentChecked
                                            }
                                            type="submit"
                                            // onClick={() => {
                                            //   this.goToNextTab();
                                            //   this.setState({
                                            //     showProg: true,
                                            //     displayButton: false,
                                            //   });
                                            // }}
                                          >
                                            {this.props.t("Submit")}
                                          </Button>
                                        )}
                                      </li>
                                    </ul>
                                  </Col>
                                </Row>
                              </AvForm>
                            </TabPane>
                          ) : (
                            <p hidden={!this.state.showFollowUpProg}>
                              {this.props.t(
                                "Thank you for your patience while your request is being processed."
                              )}
                            </p>
                          )
                          // <Label
                          //   bar
                          //   // color="info"
                          //   // value="100"
                          //   hidden={
                          //     this.state.showSuccessForm && !displayFollowup
                          //   }
                          // >
                          //   {this.props.t(
                          //     "Please wait. We are processing your request."
                          //   )}
                          // </Label>
                        }

                        <TabPane
                          tabId={this.state.tabs[3] ? 4 : tabId}
                          title={this.props.t("finalize")}
                        >
                          <Alert
                            color="success"
                            className="alert-dismissible fade show"
                            role="alert"
                            hidden={!this.state.showSuccessForm}
                          >
                            <i className="mdi mdi-check-all me-2"></i>
                            <b>{this.props.t("Report Submitted")}</b>
                            {this.state?.submitData?.private_email !==
                              this.props.user?.private_email &&
                            !this.userUtils.isThirdParty(this.props.user) &&
                            this.state.privateEmailChanged ? (
                              <p>
                                {this.props.t(
                                  "You have successfully changed your private email address. This email will be used for further communication regarding anonymous reports."
                                )}
                              </p>
                            ) : null}
                            <p>
                              {this.props.t(
                                "If you have any questions, please contact the technical support by sending an email to support@diss-co.tech."
                              )}
                            </p>
                            <p>
                              {this.props.t(
                                "If you receive error messages or have a technical questions, please make a screenshot of the error message or your view including the URL and send your request by email to support@diss-co.tech"
                              )}
                            </p>
                          </Alert>

                          <Row hidden={!this.state.showSuccessForm}>
                            <Col sm="12 m-t-15">
                              <Link
                                to={
                                  // this.userUtils.isThirdParty(this.props.user)
                                  //   ? null
                                  //   :
                                  this.getUserHome()
                                }
                              >
                                <Button
                                  color="secondary"
                                  // onClick={() =>
                                  //   !this.props.user ||
                                  //   this.userUtils.isThirdParty(this.props.user)
                                  //     ? this.logoutCurrentUser()
                                  //     : null
                                  // }
                                >
                                  {
                                    // !this.props.user ||
                                    // this.userUtils.isThirdParty(this.props.user)
                                    // ? this.props.t("Back To signin")
                                    // :
                                    this.props.t("Back To reports")
                                  }
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  displayEnteredWhitchLocations() {
    const location = this.state.locations.find(
      (location) =>
        parseInt(this.state.submitData.locations) === parseInt(location.id)
    );
    if (location) {
      return (
        <Row>
          <Col sm="12">
            <Label className="form-label">
              {this.props.t("Which location(s) is / are affected?")}
            </Label>
            <p className="text-answers">{this.props.t(location.name)}</p>
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderPrivateEmailField() {
    console.log("re", this.state.privateEmail);
    return (
      <Row>
        <Col sm="12" className="mb-3">
          <Label className="form-label" htmlFor="email">
            {`${this.props.t("Private Email")} ${this.props.t("(Optional)")}`}
            <span className="ms-2">
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                id="email-infobox-icon"
              />
              <Tooltip
                placement="top"
                isOpen={this.state.emailTooltipOpen}
                target="email-infobox-icon"
                toggle={() =>
                  this.setState({
                    emailTooltipOpen: !this.state.emailTooltipOpen,
                  })
                }
              >
                {this.props.t(
                  "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                )}
                .
              </Tooltip>
            </span>
          </Label>
          <AvField
            name="email"
            placeholder=""
            type="email"
            className="form-control"
            validate={{ required: { value: false }, email: true }}
            errorMessage={this.props.t("This field is invalid")}
            id="email"
            value={this.state.privateEmail}
            onChange={(e) =>
              this.setState({
                authUserInfos: {
                  ...this.state.authUserInfos,
                  private_email: e.target.value,
                },
                submitData: {
                  ...this.state.submitData,
                  private_email: e.target.value,
                },
              })
            }
          />
        </Col>
      </Row>
    );
  }

  renderRegisterForm() {
    if (!this.props.user) {
      return (
        <>
          <Row>
            <Col sm="12" className="mb-3">
              <AvRadioGroup
                defaultValue={this.state.selectedAuthType}
                onChange={(e) =>
                  this.setState({
                    selectedAuthType: e.target.value,
                    authUserInfos: {
                      ...this.state.authUserInfos,
                      auth_type: e.target.value,
                    },
                  })
                }
                name="auth_type"
                validate={{ required: { value: true } }}
                errorMessage={this.props.t("This field cannot be blank")}
              >
                <Row>
                  <Col sm="12" md="6">
                    <AvRadio
                      onChange={() =>
                        this.setState({
                          randomGeneratedUsername: "",
                          authUserInfos: {
                            ...this.state.authUserInfos,
                            username: "",
                          },
                        })
                      }
                      label={this.props.t("Log In")}
                      value="login"
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <AvRadio
                      label={this.props.t("Create account")}
                      value="register"
                    />
                  </Col>
                </Row>
              </AvRadioGroup>
            </Col>
            <Col sm="12" className="mb-3">
              <Label className="form-label" htmlFor="email">
                {`${this.props.t("Username")}`}
                <span className="ms-2">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIconU"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.usernameTooltipOpen}
                    target="infoIconU"
                    toggle={() =>
                      this.setState({
                        usernameTooltipOpen: !this.state.usernameTooltipOpen,
                      })
                    }
                  >
                    {this.props.t(
                      "Please choose a username that doesnt contain your name or any kind of email address"
                    )}
                  </Tooltip>
                </span>
                <Button
                  outline
                  hidden={this.state.authUserInfos.auth_type === "login"}
                  className="btn-sm color-primary ms-2"
                  onClick={this.generateRandomUsername}
                >
                  {this.props.t("Random")}
                </Button>
              </Label>
              <div className="position-relative">
                <AvField
                  name="username"
                  placeholder=""
                  type="text"
                  className="form-control"
                  errorMessage={this.props.t("This field cannot be blank")}
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: /^[^@\s]+$/, // regular expression to disallow emails
                      errorMessage: this.props.t(
                        "Please enter a valid username"
                      ),
                    },
                  }}
                  value={
                    this.state.username || this.state.randomGeneratedUsername
                  }
                  id="username"
                  onChange={(e) =>
                    this.setState({
                      username: e.target.value,
                      authUserInfos: {
                        ...this.state.authUserInfos,
                        username: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </Col>
            <Col sm="12" className="mb-3">
              <Label className="form-label" htmlFor="email">
                {`${this.props.t("Password")}`}
                <span className="ms-2">
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    id="infoIcon"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.passwordTooltipOpen}
                    target="infoIcon"
                    toggle={() =>
                      this.setState({
                        passwordTooltipOpen: !this.state.passwordTooltipOpen,
                      })
                    }
                  >
                    {this.props.t(
                      "Please choose a password at least 10 characters contains 1 capital letter,1 digit, and 1 special character contains"
                    ) +
                      ": ! @ # $ % ^ & * ( ) _ [ ]+={ } ' \" ; : / ? . , < > |  -"}
                  </Tooltip>
                </span>
              </Label>
              <Password
                name="password"
                placeholder=""
                type="password"
                errorMessage={this.props.t("This field cannot be blank")}
                validate={{ required: { value: true } }}
                value={this.state.password}
                id="password"
                onChange={(e) =>
                  this.setState({
                    password: e.target.value,
                    authUserInfos: {
                      ...this.state.authUserInfos,
                      password: e.target.value,
                    },
                  })
                }
              />
            </Col>
            {this.state.selectedAuthType === "register" ? (
              <Col sm="12" className="mb-3">
                <Label className="form-label" htmlFor="repeatpasword">
                  {`${this.props.t("Repeat password")}`}
                </Label>
                <Password
                  name="repeat_pasword"
                  placeholder=""
                  type="password"
                  className="form-control"
                  errorMessage={this.props.t("This field cannot be blank")}
                  value={this.state.repeat_pasword}
                  validate={{ required: { value: true } }}
                  id="repeatpasword"
                  onChange={(e) =>
                    this.setState({
                      repeat_pasword: e.target.value,
                      authUserInfos: {
                        ...this.state.authUserInfos,
                        repeat_pasword: e.target.value,
                      },
                    })
                  }
                />
              </Col>
            ) : null}
          </Row>
        </>
      );
    }
    // else if (this.state.isThirdPartyUser && typeof this.props.user.email === "undefined") {
    // 	return (
    // 		<Row>
    // 			<Col sm="12" className="mb-3">
    // 				<Label className="form-label" htmlFor="email">
    // 					{`${this.props.t("Email")} ${this.props.t("(Optional)")}`}3
    // 					<span className="ms-2">
    // 						<i className="fa fa-info-circle" aria-hidden="true" id="email-infobox-icon" />
    // 						<Tooltip
    // 							placement="top"
    // 							isOpen={this.state.emailTooltipOpen}
    // 							target="email-infobox-icon"
    // 							toggle={() =>
    // 								this.setState({
    // 									emailTooltipOpen: !this.state.emailTooltipOpen,
    // 								})
    // 							}
    // 						>
    // 							{this.props.t("Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you")}.
    // 						</Tooltip>
    // 					</span>
    // 				</Label>
    // 				<AvField
    // 					name="email"
    // 					placeholder=""
    // 					type="email"
    // 					className="form-control"
    // 					validate={{ required: { value: false }, email: true }}
    // 					errorMessage={this.props.t("This field is invalid")}
    // 					id="email"
    // 					onChange={(e) =>
    // 						this.setState({
    // 							authUserInfos: {
    // 								...this.state.authUserInfos,
    // 								email: e.target.value,
    // 							},
    // 							submitData: {
    // 								...this.state.submitData,
    // 								email: e.target.value,
    // 							},
    // 						})
    // 					}
    // 				/>
    // 			</Col>
    // 		</Row>
    // 	);
    // }
    return null;
  }

  renderEmailInput() {
    if (
      !this.props.user ||
      (this.userUtils.isThirdParty(this.props.user) && !this.props.user.email)
    ) {
      return (
        <Row>
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="email">
              {`${this.props.t("Email")} ${this.props.t("(Optional)")}`}
              <span className="ms-2">
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  id="email-infobox-icon"
                />
                <Tooltip
                  placement="top"
                  isOpen={this.state.emailTooltipOpen}
                  target="email-infobox-icon"
                  toggle={() =>
                    this.setState({
                      emailTooltipOpen: !this.state.emailTooltipOpen,
                    })
                  }
                >
                  {this.props.t(
                    "Your email will not be transferred to the admin of the company when you report anonymously. It will be only used by the system to notify you"
                  )}
                  .
                </Tooltip>
              </span>
            </Label>
            <AvField
              name="email"
              placeholder=""
              type="email"
              className="form-control"
              validate={{ required: { value: false }, email: true }}
              errorMessage={this.props.t("This field is invalid")}
              id="email"
              onChange={(e) =>
                this.setState({
                  authUserInfos: {
                    ...this.state.authUserInfos,
                    email: e.target.value,
                  },
                  submitData: {
                    ...this.state.submitData,
                    email: e.target.value,
                  },
                })
              }
            />
          </Col>
        </Row>
      );
    }
    return null;
  }

  renderPhoneNumberInput() {
    if (
      !this.props.user ||
      (this.userUtils.isThirdParty(this.props.user) &&
        !this.props.user.phoneNumber)
    ) {
      return (
        <Row>
          <Col sm="12" className="mb-3">
            <Label className="form-label" htmlFor="phone-number">
              {`${this.props.t("Phone number")} ${this.props.t("(Optional)")}`}
            </Label>
            <InputPhoneNumber
              id="phone-number"
              name="phoneNumber"
              required={false}
              className="form-control"
              placeholder=""
              errorMessage=""
              onChange={(phone) => this.setState({ inputPhoneNumber: phone })}
              value={this.props.user ? this.props.user.phone_number : ""}
              validate={{ required: { value: false } }}
            />
          </Col>
        </Row>
      );
    }
    return null;
  }

  fetchQuestions() {
    // console.log("test");
    const url =
			!this.props.user || this.userUtils.isThirdParty(this.props.user)
				? this.context.selectedModule === 'wb'
					? API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION
					: CM_API_URL_QUESTION_THIRD_PARTY_FORM_ORGANIZATION
				: this.context.selectedModule === 'wb'
				? API_URL_QUESTION_FORM_ORGANIZATION
				: CM_API_URL_QUESTION_FORM_ORGANIZATION;

    return axios
      .post(
        url,
        {
          organization: this.urlUtils.getOrganizationId(),
          typeReport: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      )
      .then((response) => {
        const {
          questions,
          location,
          report_category: categories,
          organizationCountry: organizationCountry,
        } = response.data.data;
        this.setState({
          questions: questions && Array.isArray(questions) ? questions : [],
          categories: categories && Array.isArray(categories) ? categories : [],
          locations: location && Array.isArray(location) ? location : [],
          organizationCountry: organizationCountry,
        });
      });
  }

  renderTabs() {
    let tabId = 1;
    const tabsToDisplay = [
      {
        tabId: tabId++,
        title: this.props.t("Report"),
        active: true,
      },
      {
        tabId: tabId++,
        title: this.props.t("Review your report"),
        active: false,
      },
    ];

    if (
      !this.props.user ||
      (this.userUtils.isThirdParty(this.props.user) &&
        typeof this.props.user.email === "undefined") ||
      (this.props.user &&
        this.userUtils.isEmployee(this.props.user) &&
        // this.props.user?.is_ad_user &&
        true)
    ) {
      if (
        this.props.user &&
        this.userUtils.isEmployee(this.props.user) &&
        // this.props.user?.is_ad_user &&
        // !this.props.user?.private_email
        true
      ) {
        this.setState({ needsPrivateEmailFollowup: true });
      }
      tabsToDisplay.push({
        tabId: tabId++,
        title: this.props.t("Follow-Up"),
        active: false,
      });
    }

    // If an employee user logins using AD interface
    // if (
    // this.props.user &&
    // this.userUtils.isEmployee(this.props.user) &&
    // this.props.user?.is_ad_user &&
    // !this.props.user?.private_email
    // ) {
    //   this.setState({
    //     needsPrivateEmailFollowup: true,
    //   });
    //   tabsToDisplay.push({
    //     tabId: tabId++,
    //     title: this.props.t("Follow-Up"),
    //     active: false,
    //   });
    // }

    tabsToDisplay.push({
      tabId: tabId,
      title: this.props.t("Finalize"),
      active: false,
    });

    this.setState(
      {
        tabs: tabsToDisplay,
      },
      function () {
        this.setState({
          progressValue: this.calculateProgressValue(),
        });
      }
    );
  }

  sortedNames = () => {
    // const sortedNames = [];

    const language = localStorage.getItem("i18nextLng");
    const collator = new Intl.Collator("de", { sensitivity: "base" });

    // console.log("language: ", language);

    const sortedNames = this.state.locations.sort((a, b) => {
      return collator.compare(a.name, b.name);

      // return collator.compare(a.name, b.name);

      // if (language === 'en') {
      //   // For English, use the default sort order (alphabetical)
      //   return a["name"].localeCompare(b["name"]);
      // } else if (language === 'fr') {
      //   // For French, use the French sort order
      //   return a["name"].localeCompare(b["name"], 'fr');
      // } else {
      //   // For other languages, use the default sort order
      //   return a["name"].localeCompare(b["name"], language);
      // }
    });

    // console.log("sortedNames: ", sortedNames);

    return sortedNames;
  };

  sortCountryList() {
    let selected = localStorage.getItem("i18nextLng");

    let newLocations = this.state.locations;
    const collator = new Intl.Collator(selected, { sensitivity: "base" });

    newLocations = this.state.locations.sort((a, b) => {
      return collator.compare(a.name, b.name);
    });

    // console.log("location");
    // console.log(this.state.locations);

    // console.log("newLocations");
    // console.log(newLocations);
    //   newLocations.map(item => {
    // 	return <h1>{item.name}</h1>
    //   })

    this.setState((prevState) => ({
      locations: [...prevState.locations, newLocations],
    }));
  }

  sortLocationsByLanguage(language, locations) {
    // const locations = this.state.locations;

    // console.log("locations: ", locations);
    // console.log("language: ", language);

    if (locations && Array.isArray(locations)) {
      // console.log("sortLocationsByLanguage");

      const collator = new Intl.Collator(language, {
        sensitivity: "base",
      });
      return locations.sort((a, b) =>
        collator.compare(this.props.t(a.name), this.props.t(b.name))
      );
    } else {
      return [];
    }
  }

  componentDidUpdate(prevProps) {
    // if (
    //   this.props.user?.private_email &&
    //   this.props.user.private_email !== this.state.privateEmail
    // ) {
    //   this.setState({
    //     privateEmail: this.props.user.private_email,
    //   });
    // }

    if (this.state.reload) {
      this.setState({ displayButton: true, showProg: false, reload: false });
    }

    if (!prevProps.App.loaded && this.props.App.loaded) {
      this.fetchQuestions();
      this.renderTabs();
      this.statusLogin();
    }

    if (this.props.t !== prevProps.t) {
      this.renderTabs();
      this.sortCountryList();
      this.setState({
        popover: {
          id: null,
          active: false,
        },
      });
      // if (this.state.tabs.length >= 4) {
      //   this.setState({
      //     ...this.state,
      //     displayFollowup: true,
      //     displayFollowupFound: true,
      //   });
      // }
    }

    if (this.props.t !== prevProps.t) {
      this.sortCountryList();
      this.renderTabs();
    }
    if (this.state.tabs.length >= 4 && !this.state.displayFollowupFound) {
      this.setState({
        // ...this.state,
        displayFollowup: true,
        displayFollowupFound: true,
      });
    }
  }

  fetchUserData() {
    return axios
      .get(API_URL_GET_USER_DETAIL, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        this.setState({
          privateEmail: response.data?.data?.private_email
            ? response.data.data.private_email
            : "",
        });
      });
  }

  componentWillMount() {
    this.renderTabs();
    this.statusLogin();
    this.setState({
      tabsCount: 3,
      activeTabProgress: 1,
      tabs: [],
    });
  }

  componentWillUnmount() {
    this.renderTabs();
    this.statusLogin();
    this.setState({
      tabsCount: 3,
      activeTabProgress: 1,
      tabs: [],
      privateEmail: "",
    });
  }
  
  componentDidMount() {
		if (this.props.App.loaded) {
			if (this.state.tabs.length >= 4 && !this.state.displayFollowupFound) {
				this.setState({
					// ...this.state,
					displayFollowup: true,
					displayFollowupFound: true,
					showSuccessForm: false,
				});
			}
			this.renderTabs();
			this.fetchQuestions();
			this.statusLogin();
			this.sortCountryList();
		} else {
			this.fetchQuestions();
			this.renderTabs();
		}

		if (this.props.token) this.fetchUserData();

		console.log('didMuount');

		if (!this.state.defaultPrivateEmail && this.props.user?.private_email) {
			this.setState({
				defaultPrivateEmail: this.props.user?.private_email,
			});
		}
	}
}

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization, App } = state;
  return { token, Organization, user, App };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      logoutUser,
      errorNotification,
      successNotification,
      checkLogin,
      loginUserSuccessful,
      authCurrentUser,
      changeWorkspace,
      appError,
      appLoadStatusChanged,
      setUserAccessToken,
    })(ReportAnonymously)
  )
);
