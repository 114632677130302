import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import UrlUtils from "../../../services/utils/UrlUtils";
import { authCurrentUser } from "../../../store/auth/login/actions";
import { Link } from "react-router-dom";
import UserUtils from "../../../services/utils/UserUtils";

class FAQDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.urlUtils = new UrlUtils();
    this.state = {
      disscoSupportEmail: "support@diss-co.tech",
    };
    this.toggle = this.toggle.bind(this);
    this.userUtils = new UserUtils();
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }


  render() {
    
    return (
      <React.Fragment>
          <Dropdown
            isOpen={this.state.menu}
            toggle={this.toggle}
            className="d-sm-inline-block"
          >
            <DropdownToggle tag='button' className='btn header-item noti-icon waves-effect'>
                <i className='ri-question-line' style={{ fontSize: 26 }}></i>
			      </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
                    <Link to={ this.userUtils.isAnalystOrAnalystAdmin(this.props.user) ? "/admin/user_manual" : this.userUtils.isEmployee(this.props.user)? "/employee/user_manual" : "/third-party/user_manual"} className='text-reset notification-item' >
                      <DropdownItem className="notify-item">
                        <a>{this.props.t("User Manual")}</a>
                      </DropdownItem>
                    </Link>

                    <Link to={ this.userUtils.isAnalystOrAnalystAdmin(this.props.user) ? "/admin/faq" : this.userUtils.isEmployee(this.props.user)? "/employee/faq" : "/third-party/faq"}  className='text-reset notification-item' >
                      <DropdownItem className="notify-item">
                          <a>{this.props.t("FAQ")}</a>
                      </DropdownItem>
                    </Link>

                    {/* <DropdownItem className="notify-item">
                        <a href={"https://diss-co.tech/faq-whistleblower-protection-act-and-whistleblowing-system/"} target="_blank">
                            {this.props.t("Legal Whistleblower Protection Act FAQ")}
                        </a>
                </DropdownItem> */}

                <DropdownItem className="notify-item">
                        <a href={`mailto:${this.state.disscoSupportEmail}`} title={`${this.props.t('Need Support')}?`}>
                            {this.props.t('Need Support')}
                        </a>
                </DropdownItem>
            </DropdownMenu> 
          </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, token } = state.Login;
  const { App } = state;
  const organization = state.Organization;
  return { user, token, App, organization };
};

export default connect(mapStatetoProps, {
  authCurrentUser,
})(withNamespaces()(FAQDropdown));
