import React, { Component } from "react";
import axios from "axios";
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { Row, Col, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import UrlUtils from "../../services/utils/UrlUtils";
import UserUtils from "../../services/utils/UserUtils";
import { getFeatureStatusByTitle } from "../../helpers/feature_helper";
import NotificationUtils from "../../services/utils/NotificationUtils";
import { API_URL_VERIFY_OTP, API_URL_RESEND_OTP, API_URL_AZURE_LOGIN, API_URL_SIGN_IN } from "../../common/constants";
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from "../../services/constants/LocalStorage";
import { checkLogin, setUserAccessToken, changeWorkspace, errorNotification, successNotification, loginErrorFlag, loginUserSuccessful } from "../../store/actions";

import Password from "../../components/Fields/Password";
import OrganizationHeader from "./components/OrganizationHeader";
import ModuleContext from "../../contexts/ModuleContext";
import { ROLE_EMPLOYEE } from "../../components/constants";
import { withRouter } from "react-router-dom";
//import ClearCache from "react-clear-cache";

class Login extends Component {
	static contextType = ModuleContext;

	constructor(props) {
		super(props);

		this.interval = null;
		this.initialTime = 60;
		this.urlUtils = new UrlUtils();
		this.userUtils = new UserUtils();

		this.state = {
			otp: {
				error: false,
				message: '',
				code: null,
			},
			countDown: this.initialTime,
			isThirdPartyUrl: false,
			isThirdPartyUser: false,
			organization: this.urlUtils.getOrganizationId(),
			buttonClicked: false,

			spinner: {
				id: '',
				active: false,
			},
		};

		this.resendOTP = this.resendOTP.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.verifyOTPCode = this.verifyOTPCode.bind(this);
		this.onOTPChange = this.onOTPChange.bind(this);
		this.notificationUtils = new NotificationUtils();
	}
	//   clearCacheRef = React.createRef();

	clearCache = () => {
		if ('caches' in window) {
			caches.keys().then(function (cacheNames) {
				cacheNames.forEach(function (cacheName) {
					caches.delete(cacheName);
				});
				console.log('Browser cache cleared!');
			});
		}

		if ('caches' in window) {
			caches.keys().then(function (cacheNames) {
				if (cacheNames.length === 0) {
					console.log('Browser cache was cleared successfully!');
				} else {
					console.log('Browser cache was not cleared!');
				}
			});
		}
	};

	clearCoockies = () => {
		// Clear the cookies
		document.cookie.split(';').forEach(function (cookie) {
			var eqPos = cookie.indexOf('=');
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		});
		console.log('Cookies cleared!');

		if (document.cookie === '') {
			console.log('Cookies were cleared successfully!');
		} else {
			console.log('Cookies were not cleared!');
		}
	};

	loginUser = (user) => {
		axios
			.post(API_URL_SIGN_IN, {
				'2fa': true,
				username: user.username,
				password: user.password,
				organization: user.organization,
				isThirdPartyUser: user.isThirdPartyUser,
			})
			.then((res) => {
				if (res.data && res.data.ok) {
					const { data } = res.data;

					if (res.data.data.profile.roles.includes(ROLE_EMPLOYEE)) this.context.setSelectedModule('wb')
					
					this.props.loginUserSuccessful({
						...data.profile,
						needExchange: !!data.needExchange,
						tempToken: data.token,
					});
				} else {
					const { error, message } = res.response.data;
					this.props.errorNotification({
						code: error,
						message: this.props.t(message),
					});
				}
			})
			.catch((err) => {
				if (err && err.response && err.response.data) {
					const { error, message } = err.response.data;

					if (error && message) {
						this.props.errorNotification({
							code: error,
							message: this.props.t(message),
						});

						/*
							const { title, errors } = err.response.data;
							const errorTitle = this.props.t(title);
					
							if (title && errors) {
								Object.entries(errors).forEach(([key, value]) => {
									this.props.errorNotification({
									code: errorTitle,
									message: `${key}: ${value}`,
									});
								});
							}
						*/
					}
				}
			});
	};

	handleSubmit(event, values) {
		// return if requeset is on pending status.
		if (this.state.spinner.active) {
			return;
		}

		this.setState({
			spinner: {
				id: 'submit-button',
				active: true,
			},
		});

		setTimeout(() => {
			// Testing
			// Code to execute after N seconds
			// You can perform any action here

			this.setState({ buttonClicked: true });

			this.clearCoockies();
			this.clearCache();
			// window.location.reload(true);

			if (typeof this.urlUtils.getOrganizationId() !== 'undefined') {
				values.organization = this.urlUtils.getOrganizationId();
			}

			if (this.urlUtils.getUrlParamThirdParty() === 'third-party') {
				values.signin = this.urlUtils.getUrlParamThirdParty();
				values.isThirdPartyUser = true;
			} else {
				values.signin = 'others';
				values.isThirdPartyUser = false;
			}

			localStorage.setItem('org', this.urlUtils.getOrganizationName());

			// this.props.checkLogin(values, this.props.history);
			this.loginUser(values);

			// this.props.checkLogin = async (values, history) => {
			// 	const { error } = await this.props.checkLogin(values, history);
			// 	if (error) {
			// 		// Display the error message to the user or handle the error as needed
			// 		console.log(error);
			// 	}
			// 	// Continue with the login flow
			// };

			this.setState({
				spinner: {
					id: '',
					active: false,
				},
			});
		}, 1000);
	}

	checkUrlParamThirdParty() {
		if (this.urlUtils.getUrlParamThirdParty() === 'third-party') {
			this.setState({
				isThirdPartyUrl: true,
				isThirdPartyUser: true,
			});
		} else {
			this.setState({
				isThirdPartyUrl: false,
			});
		}
	}

	setWorkspace() {
		const workspace = this.userUtils.getWorkspace(this.props.user);
		this.props.changeWorkspace(workspace);
	}

	getAfterLoginRedirectRoute() {
		let redirect = new URLSearchParams(this.props.location.search).get('redirect');
		let redirectUrl = new URLSearchParams(this.props.location.search).get('redirectUrl');
		if (redirectUrl) {
			console.log(redirectUrl);
			if (redirectUrl.split('/').filter((i) => i).length === 2) {
				if (redirectUrl[redirectUrl.length - 1] === '/') {
					redirectUrl = `${redirectUrl}admin`;
				} else {
					redirectUrl = `${redirectUrl}/admin`;
				}
			}
			return {
				url: redirectUrl,
			};
		}

		switch (redirect) {
			// case 'livechat':
			// 	return this.userUtils.getUserTypeBaseRoute(this.props.user) + '/livechat';
			default:
				return this.userUtils.getUserHomeRoute(this.props.user);
		}
	}

	verifyOTPCode() {
		const otpCode = this.state.otp.code.replaceAll('-', '');
		if (otpCode === '') {
			this.props.errorNotification({
				code: 'invalid_otp',
				message: this.props.t('Invalid OTP'),
			});

			return;
		}

		axios
			.post(
				API_URL_VERIFY_OTP,
				{ otp: otpCode },
				{
					headers: {
						Authorization: `Bearer ${this.props.user.tempToken}`,
					},
				},
			)
			.then((response) => {
				// console.log("verifyOTPCode response: ", response);

				const data = response.data;

				if (data.ok === false) {
					return this.props.errorNotification({
						code: 'invalid_otp',
						message: this.props.t('Invalid OTP'),
					});
				}

				localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, this.props.user.tempToken);
				this.props.setUserAccessToken(this.props.user.tempToken);
				this.setWorkspace();
				const redirectRoute = this.getAfterLoginRedirectRoute();

				if (typeof redirectRoute === 'object') {
					window.location.href = window.location.origin + redirectRoute.url;
				}

				if (this.props.user && this.props.user.roles.includes('ROLE_ANALYST_ADMIN')) {
					return this.props.history.push('/admin/start');
				} else {
					this.props.history.push(redirectRoute);
				}
			})
			.catch((err) => {
				console.log('err: ', err);

				this.props.errorNotification({
					code: 'invalid_otp',
					message: this.props.t('Invalid OTP'),
				});
			});
	}

	resendOTP() {
		const url = API_URL_RESEND_OTP;
		const data = {
			token: this.props.user && this.props.user.tempToken,
		};
		const header = {
			headers: {
				Authorization: `Bearer ${this.props.user.tempToken}`,
			},
		};

		this.setState({
			countDown: this.initialTime,
		});

		axios
			.post(url, data, header)
			.then((response) => {
				const data = response.data;

				if (data.ok === false) {
					this.props.errorNotification({
						code: 'invalid_token',
						message: this.props.t(data.error[0]),
					});
				} else {
					this.setState(
						{
							countDown: this.initialTime,
						},
						() => {
							console.log(this.state.countDown);
							this.startCountDown();
						},
					);
				}
			})
			.catch((error) => {
				console.log(error, ' --------');

				if (error.response) {
					const data = error.response.data;

					this.props.errorNotification({
						code: data.error || 'unknown_error',
						message: this.props.t(data.message || data.title),
					});
				} else {
					this.props.errorNotification({
						code: 'invalid_token',
						message: this.props.t(error.error[0]),
					});
				}
			});
	}

	timer = () => {
		this.interval = setInterval(() => {
			// console.log(this.state.countDown - 1);
			this.setState(
				(prevState) => ({
					countDown: prevState.countDown - 1,
				}),
				() => {
					if (this.state.countDown < 1) {
						this.stopCountDown();
					}
				},
			);
		}, 1000);
	};

	stopCountDown = () => {
		clearInterval(this.interval);
	};

	startCountDown = () => {
		this.timer();
	};

	componentDidMount() {
		// const clearCache = this.clearCacheRef.current;
		// if (clearCache) {
		//   clearCache.clearCacheStorage();
		//   console.log("Cache cleared successfully!");
		// }

		document.body.classList.add('auth-body-bg');
		this.checkUrlParamThirdParty();

		if (this.props.user && this.props.user.tempToken) {
			console.log('ok. please redirect page');

			this.props.setUserAccessToken(this.props.user.tempToken);
			localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, this.props.user.tempToken);
			this.setWorkspace();
			this.props.history.push(this.userUtils.getUserHomeRoute(this.props.user));
			this.checkUrlParamThirdParty();
		}
	}

	componentWillReceiveProps(nextProps) {
		// console.log("componentWillReceiveProps", ne  xtProps);
	}

	componentDidUpdate(prevProps) {
		if (this.props.user && this.props.token) {
		}

		if (prevProps.loginErrFlag !== this.props.loginErrFlag) {
			console.log(this.props.loginErrFlag);
			if (this.props.loginErrFlag) {
				this.setState({ buttonClicked: true });
			}
		}

		if (!prevProps.user && this.props.user && !this.props.needExchange) {
			console.log('user changed. this.props.needExchange');
			this.startCountDown();
		}

		if (prevProps.user !== this.props.user && prevProps.token !== this.props.token) {
			console.log('prevProps.user !== this.props.user && prevProps.token !== this.props.token');

			this.props.setUserAccessToken(this.props.token);
			localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, this.props.token);
			this.setWorkspace();
			this.props.history.push(this.userUtils.getUserHomeRoute(this.props.user));
			this.checkUrlParamThirdParty();
		}

		if (prevProps.user !== this.props.user) {
			console.log('prevProps.user !== this.props.user');
		}

		if (prevProps.token !== this.props.token) {
			console.log('prevProps.token !== this.props.token');
		}

		// if (this.props.token !== prevProps.token) {
		// 	console.log("this.props.token !== prevProps.token")
		// }

		// if (this.props.user !== prevProps.user) {
		// 	console.log("this.props.user !== prevProps.user")
		// }

		// if (!prevProps.needExchange && this.props.needExchange) {
		// 	console.log("needExchange changed");
		// }

		// if (!prevProps.user && this.props.user && this.props.needExchange) {
		// 	console.log("OK must be redirect to home");
		// }

		// if (!prevProps.user && this.props.user && this.props.user.tempToken && !this.props.user.needExchange) {
		// 	console.log("!prevProps.user && this.props.user && this.props.user.tempToken && !this.props.user.needExchange")
		// }

		if (this.props.user && this.props.user.tempToken && !this.props.user.needExchange) {
			console.log('ok. please redirect page');

			this.props.setUserAccessToken(this.props.user.tempToken);
			localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, this.props.user.tempToken);
			this.setWorkspace();
			this.props.history.push(this.userUtils.getUserHomeRoute(this.props.user));
			this.checkUrlParamThirdParty();
		}

		// if (localStorage.getItem("azure") && this.props.token) {
		// 	console.log("azure is true");

		// 	localStorage.setItem("reload", true);

		// 	this.props.setUserAccessToken(this.props.user.tempToken);
		// 	localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, this.props.user.tempToken);
		// 	this.setWorkspace();
		// 	this.props.history.push(this.userUtils.getUserHomeRoute(this.props.user));
		// 	this.checkUrlParamThirdParty();
		// }
	}

	componentWillUnmount() {
		document.body.classList.remove('auth-body-bg');
	}

	onOTPChange(e) {
		this.setState({
			otp: {
				...this.state.otp,
				code: e.target.value,
			},
		});
	}

	renderOTPForm() {
		return (
			<div>
				<div className='p-2 mt-5'>
					<AvForm className='form-horizontal' onValidSubmit={this.verifyOTPCode}>
						<div className='mb-3'>
							<Label className='form-label'>{this.props.t('Enter code')}</Label>
							<InputMask autoFocus mask='9-9-9-9-9-9' className='form-control input-color' value={this.state.otpCode} onChange={(e) => this.onOTPChange(e)}>
								{(inputProps) => <MaterialInput {...inputProps} disableUnderline />}
							</InputMask>
						</div>

						<div className='mt-4 text-center'>
							<Button
								color='primary'
								className={`w-md waves-effect waves-light ${
									this.state.countDown < 1
									//   && "d-none"
								}`}
								type='submit'
							>
								{this.props.t('Submit')}
							</Button>
						</div>
						<div className='mt-4 text-center'>
							<Button
								color='link'
								className='w-md waves-effect waves-light'
								type='button'
								disabled={this.state.countDown < 1 ? false : true}
								onClick={this.resendOTP}
							>
								{this.state.countDown < 1 ? this.props.t('Resend') : `${this.props.t('Resend OTP in')} ${this.state.countDown}`}
							</Button>
						</div>
					</AvForm>
				</div>
			</div>
		);
	}

	onClickAzureLogin = (e) => {
		let organizationId = this.state.organization;

		axios
			.post(API_URL_AZURE_LOGIN, { organizationId }, { withCredentials: true })
			.then((res) => {
				const url = new URL(res.data.data);

				// const params = new URLSearchParams(url.search);
				// params.set('redirect_uri', "http://localhost:3000/api/azure_callback");

				// window.location.replace(res.data.data.split('?')[0] + '?' + params.toString());

				window.location.replace(url);
			})
			.catch((err) => {
				console.log('err: ', err);
			});
	};

	render() {
		// console.log("---------------- this.props.user: ", this.props.user);
		// console.log("---------------- this.props.token: ", this.props.token);

		// console.log("isThirdPartyUrl : ", this.state.isThirdPartyUrl)

		return (
			<React.Fragment>
				{/* <div>
          <ClearCache ref={this.clearCacheRef}>
            {({ isLatestVersion, emptyCacheStorage }) => {
              this.handleClearCache();
            }}
          </ClearCache>
        </div> */}
				{/* <button onClick={this.handleClearCache}>Clear Cache</button> */}
				<div>
					<Row className='g-0'>
						<Col lg={4}>
							<div className='authentication-page-content p-4 d-flex align-items-center min-vh-100'>
								<div className='w-100'>
									<Row className='justify-content-center'>
										<Col lg={9}>
											<div>
												<OrganizationHeader />
												{this.props.user && this.props.user.needExchange ? (
													this.renderOTPForm()
												) : (
													<div>
														<div className='p-2 mt-5'>
															<AvForm className='form-horizontal' onValidSubmit={this.handleSubmit}>
																<div className='auth-form-group-custom mb-4'>
																	<i className='ri-user-2-line auti-custom-input-icon'></i>
																	<Label htmlFor='username'>{this.state.isThirdPartyUrl ? this.props.t('Username') : this.props.t('Email')}</Label>
																	<AvField
																		name='username'
																		value={this.state.username}
																		type='text'
																		className='form-control'
																		id='username'
																		onChange={() => {
																			// this.setState({
																			// 	buttonClicked: false,
																			// });
																			this.setState({
																				spinner: {
																					id: '',
																					active: false,
																				},
																			});
																			return this.props.loginErrorFlag(false);
																		}}
																		errorMessage={this.props.t('This field cannot be blank')}
																		validate={{
																			required: true,
																		}}
																		placeholder=''
																	/>
																</div>

																<div className='auth-form-group-custom mb-4'>
																	<i className='ri-lock-2-line auti-custom-input-icon'></i>
																	<Label htmlFor='userpassword'>{this.props.t('Password')}</Label>
																	<Password
																		name='password'
																		id='userpassword'
																		placeholder=''
																		errorMessage={this.props.t('This field cannot be blank')}
																		onChange={() => {
																			// this.setState({
																			// 	buttonClicked: false,
																			// });
																			this.setState({
																				spinner: {
																					id: '',
																					active: false,
																				},
																			});
																			return this.props.loginErrorFlag(false);
																		}}
																		validate={{
																			required: true,
																		}}
																	/>
																</div>

																<div className='mt-4 text-center'>
																	<Button
																		color='primary'
																		type='submit'
																		disabled={this.state.spinner.id === 'submit-button' && this.state.spinner.active}
																		tabIndex={0}
																	>
																		<span>{this.props.t(this.state.spinner.id === 'submit-button' && this.state.spinner.active ? 'Log In' : 'Log In')}</span>
																		{/* {this.state.spinner.id === "submit-button" && this.state.spinner.active && <Spinner size="sm" color="light" style={{ top: 1, position: "relative", marginLeft: 16 }} />} */}
																	</Button>

																	{/* <Button disabled={this.state.buttonClicked} color="primary" className="w-md waves-effect waves-light" type="submit" tabIndex={0}>
																		{this.props.t("Log In")}
																	</Button> */}
																</div>
																{this.state.isThirdPartyUrl ? (
																	<div className='mt-4 text-center'>
																		<Link to='/third-party/register' className='text-muted'>
																			<span>{`${this.props.t('Need an account')}?`}</span>
																			<span
																				style={{
																					fontWeight: 'bold',
																					textDecoration: 'underline',
																					paddingLeft: 6,
																				}}
																			>
																				{this.props.t('Register now')}!
																			</span>
																		</Link>
																	</div>
																) : (
																	<div className='mt-4 text-center'>
																		<Link to='/reset-password' className='text-muted'>
																			<i className='mdi mdi-lock me-1'></i>
																			{this.props.t('I forgot my password')}
																		</Link>
																	</div>
																)}
															</AvForm>
														</div>

														{getFeatureStatusByTitle(this.props.Feature, 'ad-settings') &&
														!this.state.isThirdPartyUrl &&
														this.props.organization &&
														this.props.organization.azureLogin ? (
															<div className='d-block p-2'>
																<Button
																	className='w-md waves-effect waves-light'
																	type='button'
																	onClick={this.onClickAzureLogin}
																	style={{
																		width: '100%',
																		height: 57,
																		textAlign: 'center',
																		backgroundColor: '#fff',
																		border: '1px solid #ced4da',
																		marginTop: 9,
																	}}
																>
																	<div
																		className='d-flex align-items-center justify-content-center'
																		style={{
																			position: 'relative',
																		}}
																	>
																		<div
																			style={{
																				left: 6,
																				position: 'absolute',
																			}}
																		>
																			<svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'>
																				<rect x='1' y='1' width='9' height='9' fill='#f25022' />
																				<rect x='1' y='11' width='9' height='9' fill='#00a4ef' />
																				<rect x='11' y='1' width='9' height='9' fill='#7fba00' />
																				<rect x='11' y='11' width='9' height='9' fill='#ffb900' />
																			</svg>
																		</div>
																		<span
																			style={{
																				color: '#666',
																				fontWeight: 'bold',
																			}}
																		>
																			{this.props.t('Continue with Microsoft')}
																		</span>
																	</div>
																</Button>
															</div>
														) : (
															''
														)}
														<div className='mt-5 text-center'>
															<p>{this.props.t('© 2023 SMART INTEGRITY PLATFORM. All rights reserved.')}</p>
														</div>
													</div>
												)}
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Col>
						<Col lg={8}>
							<div className='authentication-bg'>
								<div className='bg-overlay'></div>
							</div>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { Feature } = state;

	return {
		Feature,
		user: state.Login.user,
		token: state.Login.token,
		organization: state.Organization,
		loginErrFlag: state.Login.loginErrFlag,
	};
};

export default withNamespaces()(
	withRouter(
		connect(mapStatetoProps, {
			checkLogin,
			setUserAccessToken,
			loginErrorFlag,
			changeWorkspace,
			errorNotification,
			successNotification,
			loginUserSuccessful,
		})(Login)
	)
);
