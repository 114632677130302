import { ROLE_TASK_OWNER } from "../../components/constants";
import { useId } from "react";
import {
  ROUTE_PATH_ADMIN_HOME,
  ROUTE_PATH_THIRD_PARTY_DOCUMENTS,
  ROUTE_PATH_ADMIN_ADMINISTRATION,
  // ROUTE_PATH_ADMIN_CHAT,
  ROUTE_PATH_ADMIN_USER_LIST,
  ROUTE_PATH_ADMIN_REPORT_ANONYMOUSLY,
  ROUTE_PATH_ADMIN_REPORT_CONFIDENTLY,
  ROUTE_PATH_ADMIN_SETTINGS,
  ROUTE_PATH_ADMIN_CUSTOMIZATION,
  ROUTE_PATH_ADMIN_PRIVACY_POLICY,
  ROUTE_PATH_ADMIN_DOCUMENTS,
  ROUTE_PATH_EMPLOYEE_DOCUMENTS,
  ROUTE_PATH_ADMIN_KANBAN_BOARD,
  ROUTE_PATH_ADMIN_QUESTION_GENERATOR,
  ROUTE_PATH_ADMIN_REPORT_LIST,
  ROUTE_PATH_ADMIN_E_LEARNING,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_DASHBOARD,
  ROUTE_PATH_ADMIN_REPORTING_CHANNEL,
  ROUTE_PATH_ADMIN_TUTORIAL,
  ROUTE_PATH_REPORT_LIST,
  ROUTE_PATH_REPORT_ANONYMOUSLY,
  ROUTE_PATH_REPORT_CONFIDENTLY,
  ROUTE_PATH_THIRD_PARTY_HOME,
  ROUTE_PATH_THIRD_PARTY_REPORT_ANONYMOUSLY,
  ROUTE_PATH_THIRD_PARTY_REPORT_CONFIDENTLY,
  ROUTE_PATH_THIRD_PARTY_ARRANGE_MEETING,
  ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_REPORTS_LIST,
  ROUTE_PATH_ARRANGE_MEETING,
  ROUTE_PATH_EMPLOYEE_HOME,
  ROUTE_PATH_EMPLOYEE_E_LEARNING,
  ROUTE_PATH_THIRD_PARTY_E_LEARNING,
  ROUTE_PATH_EMPLOYEE_TUTORIAL,
  ROUTE_PATH_THIRD_PARTY_TUTORIAL,
  ROUTE_PATH_ADMIN_HISTORY_LOG,
  ROUTE_PATH_THIRD_PARTY_VOICE_REPORT,
  ROUTE_PATH_VOICE_REPORT,
  ROUTE_PATH_EMPLOYEE_TASKS_LIST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_SOFTWARES_LIST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_HARDWARES_LIST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_REPORTS_LIST,
  ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_DATA_DELETION_REQUEST,
  ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_DATA_SUBJECT_REQUEST,
  ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_BREACH_INCIDENT_REQUEST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_PROCESSING_DIRECTORIES_LIST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_ADMINISTRATION_QUESTIONAIRE,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_BREACH_INCIDENT_REQUEST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_ADMINISTRATION_SETTINGS,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_ADMINISTRATION_CUSTOMIZATION,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_LATEST_NEWS_LIST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_DATA_SUBJRCT_REQUEST,
  ROUTE_PATH_ADMIN_DATA_RPTECTION_KANBAN_BOARD_REQUEST
} from "../constants";

const MenuListItems = {
  admin_workspace: [
    {
      id: 0,
      titleKey: "Dashboard",
      path: ROUTE_PATH_ADMIN_DATA_RPTECTION_DASHBOARD,
      icon: "ri-home-4-line",
    },
    // {
    //   id: 1,
    //   titleKey: "Cases",
    //   path: ROUTE_PATH_ADMIN_DATA_RPTECTION_REPORTS_LIST,
    //   icon: " ri-archive-fill", 
    // },
    {
      id: 2,
      titleKey: "Kanban Board",
      path: window.location.href?.includes('data-protection') ? ROUTE_PATH_ADMIN_DATA_RPTECTION_KANBAN_BOARD_REQUEST :  ROUTE_PATH_ADMIN_KANBAN_BOARD,
      icon: "ri-bar-chart-2-line",
    },
    {
      id: 10001,
      titleKey: "Incident Register",
      path: ROUTE_PATH_ADMIN_DATA_RPTECTION_BREACH_INCIDENT_REQUEST,
      icon: "ri-file-list-3-fill",
    },
    {
      id: 10002,
      titleKey: "Data Subject Request",
      path: ROUTE_PATH_ADMIN_DATA_RPTECTION_DATA_SUBJRCT_REQUEST,
      icon: " ri-question-answer-line",
    },
    {
      id: 100,
      titleKey: "Assets Register",
      path: "#",
      icon: "ri-computer-line",
      items: [
        {
          id: 101,
          titleKey: "Hardware Assets List",
          path: ROUTE_PATH_ADMIN_DATA_RPTECTION_HARDWARES_LIST,
        },
        {
          id: 102,
          titleKey: "Software Assets List",
          path: ROUTE_PATH_ADMIN_DATA_RPTECTION_SOFTWARES_LIST,
        },
      ],
    },
    {
      id: 3,
      titleKey: "Processing Directories",
      path: ROUTE_PATH_ADMIN_DATA_RPTECTION_PROCESSING_DIRECTORIES_LIST,
      icon: "ri-bar-chart-2-line",
    },
    {
      id: 4,
      titleKey: "Latest News",
      path: ROUTE_PATH_ADMIN_DATA_RPTECTION_LATEST_NEWS_LIST,
      icon: "ri-newspaper-line",
    },
    {
        id: 1000001,
        titleKey: "Risk Management",
        path: '/admin/risk-management',
        icon: "ri-error-warning-line",
      },
    {
      id: 200,
      titleKey: "Administration",
      path: "#",
      icon: "ri-admin-line",
      items: [
        {
          id: 201,
          titleKey: "Questionnaire Designer",
          path: ROUTE_PATH_ADMIN_DATA_RPTECTION_ADMINISTRATION_QUESTIONAIRE,
        },
        {
          id: 202,
          titleKey: "Settings",
          path: ROUTE_PATH_ADMIN_DATA_RPTECTION_ADMINISTRATION_SETTINGS,
        },
        {
          id: 202,
          titleKey: "Customization",
          path: ROUTE_PATH_ADMIN_DATA_RPTECTION_ADMINISTRATION_CUSTOMIZATION,
        },
        {
          id: 203,
          titleKey: "Documents",
          path: ROUTE_PATH_ADMIN_DOCUMENTS,
        },
        {
          id: 204,
          titleKey: "User Management",
          path: ROUTE_PATH_ADMIN_USER_LIST,
        },
        // {
        //   id: 203,
        //   titleKey: "Default Owner Form",
        //   path: ROUTE_PATH_ADMIN_DATA_RPTECTION_ADMINISTRATION_DEFAULT_OWNER_FORM,
        // }
      ],
    },
  ],
  analyst_workspace: [
    // {
    //   id: 0,
    //   titleKey: "Dashboard",
    //   path: ROUTE_PATH_ADMIN_DATA_RPTECTION_DASHBOARD,
    //   icon: "ri-home-4-line",
    // },
    {
      id: 1,
      titleKey: "Cases",
      path: ROUTE_PATH_ADMIN_HOME,
      icon: "ri-home-4-line",
    },
    {
      id: 11,
      titleKey: "Documents",
      path: ROUTE_PATH_ADMIN_DOCUMENTS,
      icon: "ri-book-open-line",
    },
    {
      id: 22,
      titleKey: "Kanban Board",
      path: ROUTE_PATH_ADMIN_KANBAN_BOARD,
      icon: "ri-bar-chart-2-line",
    },
    // SPIK-1313
    // {
    // 	id: 2,
    // 	titleKey: "My reports",
    // 	path: ROUTE_PATH_ADMIN_REPORT_LIST,
    // 	icon: "ri-mail-line",
    // },
    {
      id: 3,
      titleKey: "Add a new Case",
      path: "#",
      icon: "ri-mail-send-line",
      items: [
        {
          id: 31,
          titleKey: "Report anonymously",
          path: ROUTE_PATH_ADMIN_REPORT_ANONYMOUSLY,
        },
        {
          id: 32,
          titleKey: "Report confidentially",
          path: ROUTE_PATH_ADMIN_REPORT_CONFIDENTLY,
        },
      ],
    },
    // {
    //     id: 40,
    //     titleKey : "E-Learning",
    //     path : ROUTE_PATH_ADMIN_E_LEARNING,
    //     icon : "ri-video-line"
    // },
    // {
    //     id: 4,
    //     titleKey : "Tutorial",
    //     path : ROUTE_PATH_ADMIN_TUTORIAL,
    //     icon : "ri-video-line"
    // },
    {
      id: 6,
      titleKey: "FAQ",
      path: "#",
      icon: "ri-questionnaire-line",
    },
  ],
  employee_workspace: [
    {
      id: 1,
      titleKey: "Home",
      path: ROUTE_PATH_EMPLOYEE_HOME,
      icon: "ri-home-4-line",
    },
    {
      id: 2,
      titleKey: "My reports",
      path: ROUTE_PATH_REPORT_LIST,
      icon: "ri-mail-line",
    },
    {
      id: 2,
      titleKey: "My Tasks",
      path: ROUTE_PATH_EMPLOYEE_TASKS_LIST,
      icon: "ri-pencil-line",
      role: ROLE_TASK_OWNER
    },
    {
      id: 3,
      titleKey: "Add A New Case",
      path: "#",
      icon: "ri-mail-send-line",
      items: [
        {
          id: 31,
          titleKey: "Report anonymously",
          path: ROUTE_PATH_REPORT_ANONYMOUSLY,
        },
        {
          id: 32,
          titleKey: "Report confidentially",
          path: ROUTE_PATH_REPORT_CONFIDENTLY,
        },
        {
          id: 33,
          titleKey: "Arrange a meeting",
          path: ROUTE_PATH_ARRANGE_MEETING,
        },
        {
          id: 34,
          titleKey: "Voice report",
          path: ROUTE_PATH_VOICE_REPORT,
        },
      ],
    },
    // {
    //     id: 40,
    //     titleKey : "E-Learning",
    //     path : ROUTE_PATH_EMPLOYEE_E_LEARNING,
    //     icon : "ri-video-line"
    // },
    // {
    //     id: 4,
    //     titleKey : "Tutorial",
    //     path : "#",
    //     icon : "ri-video-line"
    // },
    {
      id: 11,
      titleKey: "Documents",
      path: ROUTE_PATH_EMPLOYEE_DOCUMENTS,
      icon: "ri-book-open-line",
    },
    {
      id: 5,
      titleKey: "FAQ",
      path: "#",
      icon: "ri-questionnaire-line",
    },
    {
      id: 6,
      titleKey: "E-Learning",
      path: ROUTE_PATH_EMPLOYEE_E_LEARNING,
      icon: "ri-video-line",
    },
    {
      id: 7,
      titleKey: "Tutorial",
      path: ROUTE_PATH_EMPLOYEE_TUTORIAL,
      icon: "ri-video-line",
    },
  ],
  third_party_workspace: [
    {
      id: 0,
      titleKey: "Home",
      path: ROUTE_PATH_THIRD_PARTY_HOME,
      icon: "ri-home-4-line",
    },
    {
      id: 1,
      titleKey: "My reports",
      path: ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_REPORTS_LIST,
      icon: "ri-mail-line",
    },
    {
      id: 2,
      titleKey: "Add a new Case",
      path: "#",
      icon: "ri-mail-send-line",
      items: [
        // {
        //   id: 21,
        //   titleKey: "Data Deletion Request",
        //   path: ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_DATA_DELETION_REQUEST,
        //   modules: ['wb', 'cm', 'data-protection'],
        // },
        {
          id: 22,
          titleKey: "Data Breach Incident",
          path: ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_BREACH_INCIDENT_REQUEST,
          modules: ['wb', 'cm', 'data-protection'],
        },
        {
          id: 23,
          titleKey: "Other Requests",
          path: ROUTE_PATH_THIRD_PARTY_DATA_PROTECTION_DATA_SUBJECT_REQUEST,
          modules: ['wb', 'cm', 'data-protection'],
        },
        // {
        //   id: 23,
        //   titleKey: "Arrange a meeting",
        //   path: ROUTE_PATH_THIRD_PARTY_ARRANGE_MEETING,
        //   modules: ['wb'],
        // },
        // {
        //   id: 26,
        //   titleKey: "Voice report",
        //   path: ROUTE_PATH_THIRD_PARTY_VOICE_REPORT,
        //   modules: ['wb', 'cm'],
        // },
      ],
    },
    {
      id: 22,
      titleKey: "Documents",
      path: ROUTE_PATH_THIRD_PARTY_DOCUMENTS,
      icon: "ri-book-open-line",
    },
    {
      id: 3,
      titleKey: "FAQ",
      path: "#",
      icon: "ri-questionnaire-line",
    },
    {
      id: 44,
      titleKey: "E-Learning",
      path: ROUTE_PATH_THIRD_PARTY_E_LEARNING,
      icon: "ri-video-line",
    },
    {
      id: 45,
      titleKey: "Tutorial",
      path: ROUTE_PATH_THIRD_PARTY_TUTORIAL,
      icon: "ri-video-line",
    },
  ],
};

export default MenuListItems;
