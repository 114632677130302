import React from "react";
import { withNamespaces } from "react-i18next";
import DefaultAvatar from "src/assets/images/users/def.png";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import DateUtils from "src/services/utils/DateUtils";
import { STANDARD_DATE_FORMAT } from "src/components/constants";
import { TasksStatuses } from "src/modules/data-protection/constants";
import {formatTaskAndRiskId } from "src/modules/data-protection/constants/Common"
import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiMenuAlt4,
} from "react-icons/hi";
import { handleAddEllipsis } from "src/utils/utils";

const TasksPriorities = {
  low: {
    title: "Low",
    color: "#1CBB8C",
    icon: <HiChevronDoubleDown />,
  },
  medium: {
    title: "Medium",
    color: "#FCC34B",
    icon: <HiMenuAlt4 />,
  },
  high: {
    title: "High",
    color: "#DC3545",
    icon: <HiChevronDoubleUp />,
  },
};

const TaskCardItem = ({ t, data }) => {
  const dateUtils = new DateUtils();

  const renderTaskStatus = () => {
    const statusData = TasksStatuses[data.status];

    return (
      <span
        className="me-1 badge text-dark"
        style={{
          backgroundColor: `${statusData?.color}`,
        }}
      >
        {t(statusData?.title)}
      </span>
    );
  };

  const renderTaskPriority = () => {
    const priorityData = TasksPriorities[data.priority];

    return (
      <span
        className="me-1 badge text-capitalize text-dark"
        style={{
          backgroundColor: `${priorityData?.color}`,
        }}
      >
        <span>{priorityData?.icon}</span>
        {t(priorityData?.title)}
      </span>
    );
  };


  const isIncident =data?.questionnaire === "data_breach_incident" ? true : null ;
  const isRequest =data?.questionnaire === "data_subject_request" ? true : null ;
  const isSoftware = data?.questionnaire === "software" ? true : null ;

  return (
    <React.Fragment>
    <Card className="task-box" >
      <div class="progress-container">
        <div class="progress-bar"></div>
      </div>
      <CardBody className="borad-width">
        <div className="float-end ms-2">
          <span>
            {`${t("Deadline")}: ${dateUtils.convertTimeStampToDate(
              data["endDate"],
              STANDARD_DATE_FORMAT
            )}`}
          </span>
        </div>
          
        <div className="mb-3">
          <Link
           to={isRequest ? `/admin/data-protection/data-subject-request/details/${data?.reportCase?.caseId}?tab=task-management&taskId=${data?.id}` 
           : isIncident ? `/admin/data-protection/incident-register/details/${data?.reportCase?.caseId}?tab=task-management&taskId=${data?.id}`
           : isSoftware ? `/admin/data-protection/softwares/${data?.secondaryId}?tab=task-management&taskId=${data?.id}`
           : `/admin/report/task/${data["id"]}`}
          >
            {/* <span>#2T{data["secondaryId"]} */}
            <span>#{formatTaskAndRiskId('task',data?.questionnaire === "software" ? data?.asset_id : data?.caseSecondaryId, data?.secondaryId, data?.is_mitigation, data.questionnaire, data?.task_secondary_id )}</span>
          </Link>
        </div>

        <div>
          {data["title"] && (
            <h5 className="mb-4">
              <Link
                to={isRequest ? `/admin/data-protection/data-subject-request/details/${data?.reportCase?.caseId}?tab=task-management&taskId=${data?.id}` 
                  : isIncident ? `/admin/data-protection/incident-register/details/${data?.reportCase?.caseId}?tab=task-management&taskId=${data?.id}`
                  : isSoftware ? `/admin/data-protection/softwares/${data?.secondaryId}?tab=task-management&taskId=${data?.id}`
                  : `/admin/report/task/${data["id"]}`}
              >
                <span className="text-datk">{data["title"]}</span>
              </Link>
            </h5>
          )}
          {console.log(data)}

            {data["subtitle"] && (
              <div
                className="mb-4"
                style={{
                  fontSize: 12,
                  borderLeft: "4px solid #eee",
                  paddingLeft: 20,
                  color: "#999",
                }}
              >
                <p className="mb-1">
                {isRequest ? t('Request ID') :
                     isIncident ? t('Incident ID') :
                     isSoftware ? t('Software ID') :
                     t('Case ID')
                    }
                  : {data["caseId"]}
                </p>
                <p className="mb-1">
                  {t("Title")}: {handleAddEllipsis(data["caseTitle"])}
                </p>
              </div>
            )}
        </div>

        <div className="mb-1">
          <div className="flex justify-content-between">
            <div className="d-inline-flex align-items-center mb-0"  style={{ width: "50%" }}>
              <span className="me-2">{`${t("Team")} : `}</span>

              {data["team"].map((member, key) => (
                <div className="team-member me-1" key={key}>
                  <Link
                    to="#"
                    className="team-member d-inline-block"
                    id={`owner-${member.id}`}
                  >
                    <div className="avatar-xs">
                      <span
                        className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize"
                        style={{ overflow: "hidden" }}
                      >
                        <img
                          alt="member's avatar"
                          className="w-100 h-100 d-block"
                          src={member.avatar ? member.avatar : DefaultAvatar}
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      </span>
                    </div>
                  </Link>
                  <UncontrolledTooltip
                    target={`owner-${member.id}`}
                    placement="top"
                  >
                    <div className="text-capitalize text-left">
                      {t(member.type)}:
                      <br />
                      {member.name}
                    </div>
                  </UncontrolledTooltip>
                </div>
              ))}
            </div>

            <div className="d-inline-flex mb-0 w-50 text-right flex-row-reverse">
              {data.status && <>{renderTaskStatus()}</>}

              {data.priority && <>{renderTaskPriority()}</>}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
    </React.Fragment>
  );
};

export default withNamespaces()(TaskCardItem);
