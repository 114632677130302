import React, { useEffect, useState , useRef} from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { getCurrentLanguage } from "src/i18n";
import backgroundImage from "src/modules/data-protection/assets/images/3rd-party-home-Background2.svg";
 import DataBreach from "src/modules/data-protection/assets/images/thirdPartyDataBreachIncident.svg";
// import DataDeletion from "../../../assets/images/data-deletion.png";
 import OtherRequests from "src/modules/data-protection/assets/images/thirdPartyOtherRequest.svg";
// import {
//   ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE
// } from "src/modules/3rd-party-management/constants";

const ThirdpartyHome = ({ App, Organization, t }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    getCurrentLanguage()
  );
  const prevProps = useRef({ App, t });
  const [introTranslations, setIntroTranslations] = useState(
    {}
  );

  const clearCache = () => {
    if ("caches" in window) {
      // Clear all caches
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
        console.log("Browser cache cleared!");
      });
    }

    if ("caches" in window) {
      caches.keys().then(function (cacheNames) {
        if (cacheNames.length === 0) {
          console.log("Browser cache was cleared successfully!");
        } else {
          console.log("Browser cache was not cleared!");
        }
      });
    }

    //   // Reload the page to ensure all cached resources are cleared
    //   // window.location.reload(true);
  };

  const clearCoockies = () => {
    // Clear the cookies
    document.cookie.split(";").forEach(function (cookie) {
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    });
    console.log("Cookies cleared!");

    if (document.cookie === "") {
      console.log("Cookies were cleared successfully!");
    } else {
      console.log("Cookies were not cleared!");
    }
  };

  useEffect(() => {
    clearCache();
    clearCoockies();
  }, []);

  useEffect(() => {
    if (!prevProps.current.App.loaded && App.loaded) {
      setSelectedLanguage(getCurrentLanguage());
    }
    prevProps.current = { App, t };
  }, [App.loaded, t]);


  useEffect(() => {
    if (Organization.introTranslations && Organization.introTranslations.length > 0) {
      const currentIntro = Organization.introTranslations.find(item => item.lang === selectedLanguage);
      setIntroTranslations(currentIntro || {});
    }
  }, [selectedLanguage, Organization.introTranslations]);


  return (
    <React.Fragment>
      <div className="third-party-home-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <h1>{introTranslations?.title}</h1>
        <div className="main-container">
          <div className="new-questionnaire-box">
            <img src={DataBreach} alt="" />
            <p className="font-size-20 fw-bold m-0">{t("Data Breach Incident")}</p>
            <div className="flex flex-column text-center">
              {/* <p className="font-size-14 mb-3" style={{ color: '#293487' }}>{t("Please start a new questionnaire")}</p> */}
              <Link to={ "/third-party/data-protection/data-breach-incident" }>
                <Button color="btn btn-primary">{t("Report")}</Button>
              </Link>
            </div>
          </div>
          <div className="existing-questionnaire-box">
            <img src={OtherRequests} alt="" />
            <p className="font-size-20 fw-bold m-0">{t("Other Requests")}</p>
            <div className="flex flex-column text-center">
              {/* <p className="font-size-14 mb-3" style={{ color: '#293487' }}>{t("Move on with your existing questionnaire")}</p> */}
              <Link to={ "/third-party/data-protection/data-subject-request" }>
                <Button color="btn btn-primary">{t("Send")}</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: "1432px", padding: "53px", marginInline: "auto", marginBottom: '53px', textAlign: 'justify' }}>
        {/* Use the dangerouslySetInnerHTML prop to render intro from backend based on the current language */}
        <div dangerouslySetInnerHTML={{ __html: introTranslations?.intro || "" }} />
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { App,Organization } = state;

  return {
    App,
    Organization,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, null)(ThirdpartyHome))
);



// import React, { Component } from "react";
// import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
// import { connect } from "react-redux";
// import { withNamespaces } from "react-i18next";
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import { checkExistModule } from "../../../helpers/module_helper";
// import ModuleContext from "../../../contexts/ModuleContext";
// import { withRouter } from "react-router-dom";
// import MainPageCards from "../../../components/Common/MainPageCards";
// import DataBreach from "../../../assets/images/security-breach.png";
// import DataDeletion from "../../../assets/images/data-deletion.png";
// import OtherRequests from "../../../assets/images/OtherRequests.png";
// import { Organization } from "src/store/organization/reducer";

// class ThirdpartyHome extends Component {
//   static contextType = ModuleContext;

//   constructor(props) {
//     super(props);
//     this.state = {
//       breadcrumbItems: [
//         { title: "SIP", link: "/" },
//         { title: "Home", link: "#" },
//       ],
//       selectedLanguage: localStorage.getItem("i18nextLng"),
//     };
//   }

//   render() {
//     const isVoice =
//       this.context.selectedModule &&
//       this.context.settings[this.context.selectedModule].isVoice;
//     // const selectedModule = window.location.href.includes("data-protection")
//     //   ? "gdpr"
//     //   : this.context.selectedModule;

// 	const selectedModule = "gd";
//     return (
//       <React.Fragment>
//         <div className="page-content">
//           <Container fluid>
//             {selectedModule === "gdpr"
//               ? this.props.gdprOrganization.translations
//                   .filter((t) => t.module === selectedModule)
//                   .map((item, index) => {
//                     return (
//                       <div
//                         className={
//                           item.lang === this.state.selectedLanguage
//                             ? ""
//                             : "d-none"
//                         }
//                         key={index}
//                       >
//                         <Breadcrumbs
//                           key={index}
//                           title={item.title}
//                           breadcrumbItems={this.state.breadcrumbItems}
//                         />
//                       </div>
//                     );
//                   })
//               : this.context.selectedModule
//               ? this.props.Organization.translations
//                   .filter((t) => t.module === this.context.selectedModule)
//                   .map((item, index) => {
//                     return (
//                       <div
//                         className={
//                           item.lang === this.state.selectedLanguage
//                             ? ""
//                             : "d-none"
//                         }
//                         key={index}
//                       >
//                         <Breadcrumbs
//                           key={index}
//                           title={item.title}
//                           breadcrumbItems={this.state.breadcrumbItems}
//                         />
//                       </div>
//                     );
//                   })
//               : null}

//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "center",
//                 gap: "3rem",
//                 margin: "2rem auto 0.5rem auto",
//                 flexWrap: "wrap",
//               }}
//             >
//               {/* <MainPageCards
//                 title={this.props.t("Data Deletion Request")}
//                 image={DataBreach}
//                 link={"/third-party/data-protection/data-deletion-request"}
//                 linkTitle="Report"
//               /> */}

//               <MainPageCards
//                 title={this.props.t("Data Breach Incident")}
//                 image={DataDeletion}
//                 link={"/third-party/data-protection/data-breach-incident"}
//                 linkTitle="Report"
//               />

//               <MainPageCards
//                 title={this.props.t("Other Requests")}
//                 image={OtherRequests}
//                 link={"/third-party/data-protection/data-subject-request"}
//                 linkTitle="Send"
//               />
//             </div>
//             {
// 			selectedModule === "gd" ? 
// 			this.props.gdprOrganization.translations
//                   .filter((t) => t.module === selectedModule)
//                   .map((item, index) => {
//                     return (
//                       <Row
//                         key={index}
//                         className={
//                           item.lang === this.state.selectedLanguage
//                             ? ""
//                             : "d-none"
//                         }
//                       >
//                         <Col lg="12">
//                           <Card>
//                             <CardBody>
//                               {
//                                 <div
//                                   className="introText"
//                                   dangerouslySetInnerHTML={{
//                                     __html: item.intro,
//                                   }}
//                                 />
//                               }
//                             </CardBody>
//                           </Card>
//                         </Col>
//                       </Row>
//                     );
//                   })
				
// 			:selectedModule
//               ? this.props.Organization.translations
//                   .filter((t) => t.module === this.context.selectedModule)
//                   .map((item, index) => {
//                     return (
//                       <Row
//                         key={index}
//                         className={
//                           item.lang === this.state.selectedLanguage
//                             ? ""
//                             : "d-none"
//                         }
//                       >
//                         <Col lg="12" className="mt-5 pt-5">
//                           <Card>
//                             <CardBody>
//                               {
//                                 <div
//                                   className="introText"
//                                   dangerouslySetInnerHTML={{
//                                     __html: item.intro,
//                                   }}
//                                 />
//                               }
//                             </CardBody>
//                           </Card>
//                         </Col>
//                       </Row>
//                     );
//                   })
//               : null}
//           </Container>
//         </div>
//       </React.Fragment>
//     );
//   }

//   clearCache = () => {
//     if ("caches" in window) {
//       // Clear all caches
//       caches.keys().then(function (cacheNames) {
//         cacheNames.forEach(function (cacheName) {
//           caches.delete(cacheName);
//         });
//         console.log("Browser cache cleared!");
//       });
//     }

//     if ("caches" in window) {
//       caches.keys().then(function (cacheNames) {
//         if (cacheNames.length === 0) {
//           console.log("Browser cache was cleared successfully!");
//         } else {
//           console.log("Browser cache was not cleared!");
//         }
//       });
//     }

//     //   // Reload the page to ensure all cached resources are cleared
//     //   // window.location.reload(true);
//   };

//   clearCoockies = () => {
//     // Clear the cookies
//     document.cookie.split(";").forEach(function (cookie) {
//       var eqPos = cookie.indexOf("=");
//       var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//       document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//     });
//     console.log("Cookies cleared!");

//     if (document.cookie === "") {
//       console.log("Cookies were cleared successfully!");
//     } else {
//       console.log("Cookies were not cleared!");
//     }
//   };

//   componentDidMount() {
//     checkExistModule(this);

//     this.clearCache();
//     this.clearCoockies();
//   }

//   componentDidUpdate(prevProps) {
//     if (
//       (!prevProps.App.loaded && this.props.App.loaded) ||
//       this.props.t !== prevProps.t
//     ) {
//       this.setState({
//         selectedLanguage: localStorage.getItem("i18nextLng"),
//       });
//     }
//   }
		
// }

// const mapStatetoProps = (state) => {
//   const { App, Feature, CMFeature, Organization } = state;
//   const gdprOrganization = state.GDPROrganization;

//   const activeModule = localStorage.getItem("module");

//   return {
//     App,
//     Feature:
//       activeModule === "wb"
//         ? Feature
//         : activeModule === "cm"
//         ? CMFeature
//         : null,
//     Organization,
//     gdprOrganization,
//   };
// };

// export default withNamespaces()(
//   withRouter(connect(mapStatetoProps, null)(ThirdpartyHome))
// );
