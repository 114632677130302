import { React, useState, useEffect, memo } from 'react';
import { Spinner } from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN } from '../services/constants/LocalStorage';
import { API_URL_GET_USER_DETAIL } from '../common/constants';
import { apiError, loginUserSuccessful, logoutUser, logoutUserSuccess } from '../store/actions';
import { connect } from 'react-redux';
import axios from 'axios';
import UrlUtils from '../services/utils/UrlUtils';
import { ROLE_ANALYST, ROLE_ANALYST_ADMIN, ROLE_EMPLOYEE, ROLE_THIRD_PARTY } from '../components/constants';
import store from '../store';

const BeforeEachRoute = (props) => {
	const urlUtils = new UrlUtils();
	const orgId = urlUtils.getOrganizationId();
	const token = localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);

	const [user, setUser] = useState(props.user);
	const [completed, setCompleted] = useState(false);

	const featuresSettings = props.settings;
	const selectedModule = props.selectedModule;

	const handleFetchAuthUser = async (token, orgId) => {
		try {
			const response = await axios.post(
				API_URL_GET_USER_DETAIL,
				{ organizationId: orgId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (response.status === 200) {
				loginUserSuccessful({
					...response.data.data,
				});
				setUser(response.data.data);
				return response.data.data;
			}
		} catch (err) {
			return false;
		}

		return false;
	};

	const handleValidateSelectedModule = () => {
		// avoid access to the route if the selected module was not equal to the route's modules.
		if (props.modules && Array.isArray(props.modules) && props.modules.length > 0) {
			if (!props.modules.includes(selectedModule)) {
				const urlSegments = window.location.pathname.split('/');

				if (urlSegments.includes('third-party')) {
					if (!window.location.pathname.includes('/third-party/start')) {
						props.history.push(`/third-party/start`);
					}
					return false;
				} else if (urlSegments.includes('employee') || urlSegments.includes('admin')) {
					if (!window.location.pathname.includes('/admin/start')) {
						props.history.push(`/admin/start`);
					}
					return false;
				}
			}
			else{
				const path = props.history.location.pathname;

				if(
					(path.includes('/voice-report') && !featuresSettings[selectedModule]?.isVoice) ||
					(path.includes('/arrange-meeting') && !featuresSettings[selectedModule]?.isMeeting) || 
					(path.includes('/report-confidentially') && !featuresSettings[selectedModule]?.isConfidentially) ||
					(path.includes('/report-anonymously') && !featuresSettings[selectedModule]?.isAnonymously) ||
					(path.includes('/e-learning') && !featuresSettings[selectedModule]?.isElearning)
				){
					props.history.push(`/fatal-error`);
					return false;
				}
			}
		}

		return true;
	};

	useEffect(() => {
		if (handleValidateSelectedModule()) {
			if (props.isAuthProtected) {
				let afterLoginRedirectUrl = null;
				if (window.location.href.split('/').filter((i) => i.indexOf('signin') > -1).length === 0) {
					afterLoginRedirectUrl = `?redirectUrl=${window.location.pathname}${window.location.search}`;
				}

				if (!token) {
					console.log('redirected by rule 8');
					props.history.push(`/signin${afterLoginRedirectUrl || ''}`);
					return null;
				}
			}
			setCompleted(true);
		}

		setCompleted(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInit = async () => {
		if (handleValidateSelectedModule()) {
			let currentUser = user;

			if (props.isAuthProtected) {
				if (!token) {
					const routeObject = {
						pathname: '/signin',
						state: { from: props.location },
					};

					if (window.location.href.split('/').filter((i) => i.indexOf('signin') > -1).length === 0) {
						routeObject['search'] = `redirectUrl=${window.location.pathname}${window.location.search}`;
					}
					console.log('redirected by rule 7');
					return <Redirect from='/' to={routeObject} />;
				}

				if (!user) {
					const fetchedUser = await handleFetchAuthUser(token, orgId);
					if (fetchedUser) currentUser = fetchedUser;
					else {
						console.log('redirected by rule 6');
						store.dispatch(logoutUser());
						return null;
					}
				}

				switch (props.type) {
					case 'employee': {
						if (currentUser?.roles?.includes(ROLE_EMPLOYEE)) {
							// localStorage.setItem('module', 'wb');
						}
						else {
							console.log('redirected by rule 5');
							store.dispatch(logoutUser());
							return null;
						}
						break;
					}
					case 'admin': {
						if (!currentUser?.roles?.includes(ROLE_ANALYST_ADMIN) && !currentUser?.roles?.includes(ROLE_ANALYST)) {
							console.log('redirected by rule 4');
							store.dispatch(logoutUser());
							return null;
						}
						break;
					}
					case 'third-party': {
						if (currentUser && !currentUser?.roles?.includes(ROLE_THIRD_PARTY)) {
							console.log('redirected by rule 3');
							store.dispatch(logoutUser());
							return null;
						}
						break;
					}
					default: {
						console.log('redirected by rule 2');
						store.dispatch(logoutUser());
						break;
					}
				}
			} else if (props.type === 'third-party' && token && currentUser && !currentUser?.roles?.includes(ROLE_THIRD_PARTY)) {
				console.log('redirected by rule 1');
				store.dispatch(logoutUser());
				return (
					<Redirect
						from='/'
						to={{
							pathname: '/third-party/signin',
							state: { from: props.location },
						}}
					/>
				);
			}
		}
	};

	handleInit();

	return (
		<props.layout authProtected={props.isAuthProtected}>
			{!completed || !props.workspaceLayout.isPreloader ? (
				<div className='card-loading-container'>
					<Spinner className='chat-messages-spinner-loading' color='info' />
				</div>
			) : (
				<props.component {...props.componentProps} />
			)}
		</props.layout>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.Login.user,
		workspaceLayout: state.Layout,
	};
};

const mapDispachToProps = (dispach) => {
	return {
		logoutUserSuccess: () => dispach(logoutUserSuccess()),
		loginUserSuccessful: (user) => dispach(loginUserSuccessful(user)),
		apiError: (error) => dispach(apiError(error)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispachToProps)(memo(BeforeEachRoute)));
