import {
  ACTION_UPDATE_NOTIFICATION_LIST,
  ACTION_NOTIFICATION_MARK_AS_READ,
} from "./actionTypes";

const initialState = [];

export const AppNotification = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_UPDATE_NOTIFICATION_LIST:
      return action.payload;
    case ACTION_NOTIFICATION_MARK_AS_READ:
      return state.filter((item) => item.id !== action.payload);
    default:
      return state;
  }
};
