import { withNamespaces } from "react-i18next";
import { memo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Col,
  Button,
  Row,
} from "reactstrap";
import { userRoll } from "../../../../../pages/Admin/administration/history-logs/constants";
import ClockOutlineIcon from "../../../../../assets/images/clock-outline.svg";

const ViewProcessingDirectoryModal = ({ t, modal, setModal, activity }) => {
  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "700px" }}>
        <ModalHeader toggle={toggle}>{t("Activity")}</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("Activities")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6 text-capitalize fw-bolder">{`${activity?.action} ${activity?.section}`}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("Event ID")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{activity?.id}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("User")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{activity?.user_name}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("User Role")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{userRoll[activity?.user_role]}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("Date")}
                </p>
              </Col>
              <Col sm={9}>
                <p
                  style={{ color: "#8A8DA3" }}
                  lassName="d-flex align-items-center fs-6"
                >
                  <span className="pe-2">
                    <img src={ClockOutlineIcon} width={24} height={24} />
                  </span>
                  {activity?.created_at}
                </p>
              </Col>
            </Row>
            {/* <Row>
              <Col sm={3}></Col>
              <Col sm={9}>
                <Button size="sm" className="mt-1" color="primary">
                  {t("View Full Detail")}
                </Button>
              </Col>
            </Row> */}
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withNamespaces()(memo(ViewProcessingDirectoryModal));
