const actionDefinitions = {
  case: {
    create: "New case created",
    update_analyst: "Update Analyst",
    update_status: "Update Status",
    mark_as_delete: "Marked as delete",
    delete: "Deleted",
    upload_attachment: "Uploaded attachment",
    download_user_attachment: "Download attachment",
    download_analyst_attachment: "Download attachment",
    delete_attachment: "Delete Attachment",
    add_comment: "Add Comment",
    add_internal_comment: "Add Internal Comment",
    update_analyst_readonly: "Read-Only Analysts",
    update_risk: "Risk updated",
    link_case: "Linked case updated",
    change_admin_description: "Admin description updated",
    change_admin_category: "Admin category updated",
    toggle_test_case: "Status of test case changed",
  },

  risk: {
    upload_files: "Uploaded files",
    upload_file: "Uploaded file",
    download_files: "Download files",
    download_file: "Download file",
    update_file: "Upload file",
    delete_file: "Delete file",
    create_comment: "New comment created",
    create: "New risk created",
    update_description: "Description updated",
    delete: "Deleted",
    assign_analyst: "Assigned to analyst",
    update_damage: "Damage updated",
    update_likelihood: "Likelihood updated",
    update_category: "Category updated",
    change_description: "Description updated by admin",
    change_category: "Category updated by admin",
  },
  task: {
    upload_files: "Uploaded files",
    upload_file: "Uploaded file",
    download_files: "Download files",
    download_file: "Download file",
    update_file: "Upload file",
    delete_file: "Delete file",
    create_comment: "New comment created",
    create: "New task created",
    assign_analyst: "Assigned to analyst",
    update_description: "Description updated",
    update_status: "Status updated",
    update_priority: "Priority updated",
    update_deadline: "Deadline updated",
    update_category: "Category updated",
    delete: "Deleted",
  },
  admin: {
    change_active_directory_settings: "Active directory settings",
    edit_risk_matrix: "Update matrix",
    restore_risk_matrix: "Restore matrix",
    edit_user: "Edit user",
    create_user: "Created user",
    delete_user: "Deleted user",
    change_user_status: "Changed user status",
    edit_organization: "Edited organization",
    update_question: "Updated question",
    remove_question: "Removed question",
    remove_category: "Removed category",
  },

  document: { create: "Created", remove: "Removed" },

  hardware: {
    create: "Created",
    download: "Download",
    delete: "Deleted",
    toggle_flag: "Change flag",
    change_status: "Change Status",
    handover: "Deliver the hardware to the new user",
    upload_csv: "Upload hardware data from Excel file",
    change_priority: "Change Priority",
    change_analyst: "Change Analyst Assigned",
    edit: "Edit detail",
  },

  software: {
    create: "Created",
    download: "Download",
    delete: "Deleted",
    toggle_flag: "Change flag",
    change_status: "Change Status",
    upload_csv: "Upload software data from Excel file",
    change_priority: "Change Priority",
    change_analyst: "Change Analyst Assigned",
    edit: "Edit detail",
    dpia: "",
    add_owner: "Added a new owner",
    delete_owner: "Deleted an owner",
  },
};

const sectionDefinitions = {
  risk: "Risk Management",
  task: "Task Management",
  admin: "Administration",
  case: "Case Management",
  document: "Document Management",
  hardware: "Hardware Management",
  software: "Software Management",
};

const questionnaireDefinitions = {
  data_breach_incident: "Incidents",
  data_subject_request: "Data Subjects Request",
  processing_activity: "Processing Activites"
}

const userRoll = {
  ROLE_ANALYST_ADMIN: "Analyst admin",
  ROLE_ANALYST: "Analyst",
  ROLE_TASK_OWNER: "Task Owner",
  ROLE_THIRD_PARTY: "Third Patry",
};
export { actionDefinitions, sectionDefinitions, userRoll, questionnaireDefinitions };
