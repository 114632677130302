import React, { useState, useMemo } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { successNotification, errorNotification } from "../../../store/actions";
import ListUsers from "./ListUsers";
import { withRouter } from "react-router-dom";
import DefaultOwnerForm from "./DefaultOwnerForm";

const USERS = "USERS_TAB";
const DEFAULT_OWNER = "DEFAULT_OWNER_TAB";

const UserManagement = (props) => {
  const { t } = props;

  const [breadcrumbItems, _] = useState([
    { title: t("SIP"), link: "/" },
    { title: t("User Management"), link: "#" },
  ]);

  const tabs = {
    [USERS]: USERS,
    [DEFAULT_OWNER]: DEFAULT_OWNER,
  };

  const [activeTab, setActiveTab] = useState(USERS);

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Users Management")}
          breadcrumbItems={breadcrumbItems}
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === USERS ? "active" : ""}
                      onClick={() => handleActiveTabChange(tabs[USERS])}
                    >
                      {t("Users")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === DEFAULT_OWNER ? "active" : ""}
                      onClick={() => handleActiveTabChange(tabs[DEFAULT_OWNER])}
                    >
                      {t("Set Default Owner")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={USERS}>
                    <ListUsers />
                  </TabPane>
                  <TabPane tabId={DEFAULT_OWNER}>
                    <DefaultOwnerForm />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { App, Feature, Organization } = state;
  return { token, App, Feature, Organization };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(UserManagement)
  )
);
