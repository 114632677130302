import axios from 'axios';
import {
    GDPR_API_URL_REPORT_CATEGORY_LIST_CASE,
  } from "../../../common/constants";

export default class ReportService {
    static instance;

    static getInstance() {
        if (!ReportService.instance) {
            ReportService.instance = new ReportService();
        }
        return ReportService.instance;
    }

    async FetchCategories() {
        const accessToken = localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN");
        const response = await axios.post(GDPR_API_URL_REPORT_CATEGORY_LIST_CASE,{} ,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        return response.data?.data || []; 
    }
}
