import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button, Label, ModalFooter, Modal, ModalHeader, ModalBody, Alert } from "reactstrap";

import axios from "axios";

import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import InputPhoneNumber from "../../components/Fields/InputPhoneNumber";

import {
  API_URL_ADMIN_UPDATE_USER_MANAGEMENT,
  API_URL_GET_USER_DETAIL,
} from "../../common/constants";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { authCurrentUser } from "../../store/auth/login/actions";


import { successNotification, errorNotification } from "../../store/actions";
import UserUtils from "../../services/utils/UserUtils";
import { withRouter } from "react-router-dom";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      inputPhoneNumber: null,
      breadcrumbItems: [
        { title: "SIP", link: "/" },
        { title: this.props.t("Profile"), link: "#" },
      ],
      lang: localStorage.getItem("i18nextLng"),
      phoneNum:
        this.props.user && this.props.user.phone_number
          ? this.props.user.phone_number
          : "",
      organization_name: "",
      usersLoaded: false,
      isAd: this.props.user?.is_ad_user,
      private_email: "",
      showPrivateApprovalModal: false,
      values: null,
      submitData: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.authCurrentUser = this.props.authCurrentUser.bind(this);
    this.successNotification = this.props.successNotification.bind(this);
  }
  userUtils = new UserUtils();

  // componentDidUpdate(){
  //   this.setState({
	// 		isAd: this.props.user?.is_ad_user,
	// 	});
  // }
  
  fetchUserData() {
    return axios
      .get(API_URL_GET_USER_DETAIL, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((response) => {
        this.setState({
          inputPhoneNumber: response.data.data.phone_number,
        });

        if (response.data.data.settings?.direction) {
          localStorage.setItem(
            "lang_dir",
            response.data.data.settings.direction
          );
        }

        this.setState({
          userData: response.data.data,
          private_email: response.data?.data?.private_email ? response.data.data.private_email : '',
          usersLoaded: true,
        });
      });
  }

  componentDidMount() {
    this.fetchUserData();
  }

  componentDidUpdate() {
    if (!this.state.usersLoaded) {
      this.fetchUserData();
    }

    if (localStorage.getItem("i18nextLng") !== this.state.lang) {
      this.setState({
        lang: localStorage.getItem("i18nextLng"),
        usersLoaded: false,
      });
    }
  }

  handleSubmit = (e, values) => {
    console.log(values, this.state.inputPhoneNumber);
    const formData = new FormData();
    formData.append("first_name", values.first_name || "");
    formData.append("last_name", values.last_name || "");
    formData.append("position", values.position || "");
    formData.append(
      "phone_number",
      values.phone || this.state.inputPhoneNumber
    );
    formData.append("gender", values.gender || "");
    formData.append("user_id", this.props.user.id || "");
    formData.append("roles[0]", this.props.user.roles || "");
    formData.append("organization_name", this.state.organization_name || "");
    formData.append("private_email", this.state.private_email || "");
    if (e.target.avatar?.files[0]) {
      formData.append("avatar", e.target.avatar?.files[0]);
    }
    if (!this.props.user || this.userUtils.isThirdParty(this.props.user)) {
      formData.append("email", values.email || "");
    }

    axios
      .post(API_URL_ADMIN_UPDATE_USER_MANAGEMENT, formData, {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        this.successNotification({
          code: "saved",
          message: this.props.t("Data was saved successfully"),
        });
        this.authCurrentUser();
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          for (const err in error.response.data.message) {
            this.props.errorNotification({
              code: err,
              message: this.props.t(error.response.data.message[err]),
            });
          }
        }
      });

      this.setState({
        showPrivateApprovalModal: false
      })
  }
  
  render() {
    const { isAd, userData } = this.state;
    return (
      <React.Fragment>
        <Modal size='lg' scrollable={true} isOpen={this.state.showPrivateApprovalModal} backdrop='static'>
					<ModalHeader>{this.props.t('Private email removal')}</ModalHeader>
					<ModalBody>
						<AvForm className='needs-validation'>
							<Alert color='warning' className='alert-dismissible fade show'>
								<p>
									{this.props.t('Attention')}!
                  <br/>
                  {this.props.t('Please note that removing your private email address disables the platform to send you notifications regarding your anonymous reports. You will not receive further notifications and have to log in to the platform.')}
								</p>
							</Alert>
							<ModalFooter>
								<Button color='primary' className='waves-effect waves-light' type='button' onClick={()=>{
                  this.handleSubmit(this.state.submitData, this.state.values);
                }}>
									{this.props.t('Approve')}
								</Button>
								<Button color='primary' className='waves-effect waves-light' type='button'
                  onClick={() => this.setState({
                    showPrivateApprovalModal: false,
                  })}
                >
									{this.props.t('Cancel')}
								</Button>
							</ModalFooter>
						</AvForm>
					</ModalBody>
				</Modal>


        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Profile"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                  <AvForm
											className='needs-validation'
											onValidSubmit={(e, values) =>
												this.userUtils.isEmployee(this.props.user)
													? this.state.private_email
														? this.handleSubmit(e, values)
														: this.setState({
																showPrivateApprovalModal: true,
																values: values,
																submitData: e,
														  })
													: this.handleSubmit(e, values)
											}
										>
                      <Row>
                        <Col sm="12" md="6">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="gender">
                              {this.props.t("Title")}
                            </Label>
                            <AvField
                              id="gender"
                              type="select"
                              name="gender"
                              errorMessage={this.props.t(
                                "This field cannot be blank"
                              )}
                              className="form-control"
                              value={
                                this.props.user && this.props.user.gender
                                  ? this.props.user.gender
                                  : this.state.userData.gender
                              }
                              defaultValue={"Mr."}
                              validate={{ required: { value: true } }}
                            >
                              <option value={"Mr."}>
                                {this.props.t("Mr.")}
                              </option>
                              <option value={"Mrs."}>
                                {this.props.t("Mrs.")}
                              </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="first-name">
                              {this.props.t("First name")}
                            </Label>
                            <AvField
                              name="first_name"
                              placeholder=""
                              type="text"
                              errorMessage={this.props.t("Enter first name")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              value={
                                this.props.user && this.props.user.first_name
                                  ? this.props.user.first_name
                                  : this.state.userData.first_name
                              }
                              id="first-name"
                            />
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="lastname"
                            >{`${this.props.t("Last name")} ${this.props.t(
                              "(Optional)"
                            )}`}</Label>
                            <AvField
                              name="last_name"
                              placeholder=""
                              type="text"
                              errorMessage={this.props.t("Enter last name")}
                              className="form-control"
                              value={
                                this.props.user && this.props.user.last_name
                                  ? this.props.user.last_name
                                  : this.state.userData.last_name
                              }
                              validate={{ required: { value: false } }}
                              id="lastname"
                            />
                          </div>
                        </Col>

                        <Col sm="12" md="6">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="email">
                              {this.props.t("Email")}
                            </Label>
                            <AvField
                              disabled={
                                !this.props.user
                                  ? false
                                  : this.userUtils.isThirdParty(this.props.user)
                                  ? false
                                  : true
                              }
                              name="email"
                              placeholder=""
                              type="text"
                              errorMessage={this.props.t("Enter user email")}
                              className="form-control"
                              value={
                                this.props.user && this.props.user.email
                                  ? this.props.user.email
                                  : this.state.userData.email
                              }
                              validate={{
                                required: { 
                                  value:  !this.userUtils.isThirdParty(this.props.user)  
                                },
                                email: true,
                              }}
                              id="email"
                            />
                          </div>
                        </Col>
                        {/* COMPANY */}
                        {/* <Col sm="12" md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="company">{`${this.props.t('Company')} ${this.props.t('(Optional)')}`} </Label>
                                                    <AvField
                                                        value={ !this.props.user ? '' : this.props.user.position }
                                                        name="position"
                                                        placeholder=""
                                                        type="text"
                                                        className="form-control"
                                                        id="company-position"/>
                                                </div>
                                            </Col> */}

                        <Col sm="12" md="6">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="company-position"
                            >
                              {`${this.props.t(
                                "Company Position"
                              )} ${this.props.t("(Optional)")}`}{" "}
                            </Label>
                            <AvField
                              value={
                                this.props.user && this.props.user.position
                                  ? this.props.user.position
                                  : this.state.userData.position
                              }
                              name="position"
                              placeholder=""
                              type="text"
                              className="form-control"
                              id="company-position"
                            />
                          </div>
                        </Col>

                        <Col sm="12" md="6" className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="phone-number"
                          >{`${this.props.t("Phone number")} ${this.props.t(
                            "(Optional)"
                          )}`}</Label>
                          <InputPhoneNumber
                            id="phone-number"
                            name="phone"
                            required={false}
                            className="form-control"
                            placeholder=""
                            errorMessage=""
                            onChange={(phone) =>
                              this.setState({ inputPhoneNumber: phone })
                            }
                            value={
                              this.props.user && this.props.user.phone_number
                                ? this.props.user.phone_number
                                : this.state.userData.phone_number
                            }
                            validate={{ required: { value: false } }}
                          />
                        </Col>

                        <Col
                          sm="12"
                          md="6"
                          className="mb-3"
                          hidden={
                            this.props.user &&
                            !this.userUtils.isThirdParty(this.props.user)
                          }
                        >
                          <Label
                            className="form-label"
                            htmlFor="organization"
                          >{`${this.props.t("Organization")}`}</Label>
                          <AvField
                            name="organization"
                            placeholder=""
                            type="text"
                            errorMessage={this.props.t("Enter organization")}
                            className="form-control"
                            value={
                              this.props.user &&
                              this.props.user.third_party_organization
                                ? this.props.user.third_party_organization
                                : this.state.userData.third_party_organization
                            }
                            validate={{ required: { value: false } }}
                            onChange={(event) =>
                              this.setState({
                                organization_name: event.target.value,
                              })
                            }
                            id="lastname"
                          />
                        </Col>

                        <Col sm="12" md="6" className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="avatar"
                          >{`${this.props.t("Avatar")}`}</Label>
                          <AvField
                            name="avatar"
                            placeholder=""
                            type="file"
                            accept="image/png, image/jpeg"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="avatar"
                          />
                        </Col>
                        {
                          // isAd &&
                          this.userUtils.isEmployee(this.props.user) && (
                            <Col sm="12" md="6">
                              <div className="mb-3">
                                <Label className="form-label" htmlFor="pEmail">
                                  {this.props.t("Private Email")}
                                </Label>
                                <AvField
                                  // disabled={
                                  //   !this.props.user
                                  //     ? false
                                  //     : this.userUtils.isThirdParty(
                                  //         this.props.user
                                  //       )
                                  //     ? false
                                  //     : true
                                  // }
                                  name="pEmail"
                                  placeholder=""
                                  type="text"
                                  errorMessage={this.props.t(
                                    "Enter user private email"
                                  )}
                                  className="form-control"
                                  value={
                                    userData.private_email
                                      ? userData.private_email
                                      : ""
                                  }
                                  validate={{
                                    // required: { value: true },
                                    email: true,
                                  }}
                                  onChange={(event) =>
                                    this.setState({
                                      private_email: event.target.value,
                                    })
                                  }
                                  id="pEmail"
                                />
                              </div>
                            </Col>
                          )
                        }
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Button color="primary" type="submit">
                            {this.props.t("Update")}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { token, user, user: currentUser } = state.Login;
  // const { user: currentUser } = state.Login.user;
  return { token, user, currentUser };
};

const mapDispachToProps = (dispach) => {
  return {
    successNotification: (message) => dispach(successNotification(message)),
    errorNotification: (message) => dispach(errorNotification(message)),
    authCurrentUser: () => dispach(authCurrentUser()),
  };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispachToProps)(Profile))
);
