import moment from "moment";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const handleAddEllipsis = (sentence, maxLength = 70) => {
  if (sentence) {
    return sentence.length > maxLength
      ? sentence.slice(0, maxLength) + "..."
      : sentence;
  } else return "";
};
