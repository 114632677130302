import Request from '../services/Request';
import Config from '../services/Config';
import {API_URL_VERIFY_ORGANIZATION, API_URL_EDIT_INFO_ORGANIZATION, API_URL_GET_INFO_ORGANIZATION,API_URL_QUESTION_FORM_ORGANIZATION, API_URL_GDPR_VERIFY_ORGANIZATION, API_URL_GDPR_EDIT_INFO_ORGANIZATION, API_URL_GDPR_GET_INFO_ORGANIZATION, GDPR_API_URL_QUESTION_FORM_ORGANIZATION} from '../common/constants/';

export default class OrganizationApiProvider {
    constructor() {
        this.request = new Request();
        this.config = new Config();
    }

    getVerifyOrganizationUrl() {
        return API_URL_VERIFY_ORGANIZATION;
    }

    getVerifyGDPROrganizationUrl() {
        return API_URL_GDPR_VERIFY_ORGANIZATION;
    }

    getGetInfoOrganizationUrl() {
        return API_URL_GET_INFO_ORGANIZATION;
    }

    getGetGDPRInfoOrganizationUrl() {
        return API_URL_GDPR_GET_INFO_ORGANIZATION;
    }

    getEditInfoOrganizationUrl() {
        return API_URL_EDIT_INFO_ORGANIZATION;
    }

    getEditInfoGDPROrganizationUrl() {
        return API_URL_GDPR_EDIT_INFO_ORGANIZATION;
    }

    getQuestionFormOrganizationUrl() {
        return API_URL_QUESTION_FORM_ORGANIZATION;
    }

    getQuestionFormGDPROrganizationUrl() {
        return GDPR_API_URL_QUESTION_FORM_ORGANIZATION;
    }

    verifyOrganization(data) {
        const url = this.getVerifyOrganizationUrl();
        return this.request.postJSON(url, data);
    }

    verifyGDPROrganization(data) {
        const url = this.getVerifyGDPROrganizationUrl();
        return this.request.postJSON(url, data);
    }

    editInfoOrganization(data, accessToken) {
        const url = this.getEditInfoOrganizationUrl();
        return this.request.upload(url, data, accessToken);
    }

    editInfoGDPROrganization(data, accessToken) {
        const url = this.getEditInfoGDPROrganizationUrl();
        return this.request.upload(url, data, accessToken);
    }

    getInfoOrganization(accessToken) {
        const url = this.getGetInfoOrganizationUrl();
        return this.request.get(url, accessToken);
    }

    getInfoGDPROrganization(accessToken) {
        const url = this.getGetGDPRInfoOrganizationUrl();
        return this.request.get(url, accessToken);
    }

    getQuestionFormOrganization(organization_id ,typeReport , accessToken){
        const url = this.getQuestionFormOrganizationUrl();
        return this.request.postJSON(url ,{organization_id,typeReport}, accessToken);
    }

    getQuestionFormGDPROrganization(organization_id ,typeReport , accessToken){
        const url = this.getQuestionFormGDPROrganizationUrl();
        return this.request.postJSON(url ,{organization_id,typeReport}, accessToken);
    }
    
}
