import { useEffect, useRef, useState } from 'react';
import { uniqueKey } from './utils';

const CustomTableRow = ({ rowData, bold }) => {
	const [rowHeight, setRowHeight] = useState('auto'); // Default row height
	const rowRef = useRef(null); 

	const boldStyle = {
        fontWeight: 'bold', // Makes the text bold
        backgroundColor: '#f0f0f5', // A light background color to highlight the row
        color: '#000', // Optional: change text color to make it stand out
    };

	useEffect(() => {
        // Function to update row height based on its content
        const updateRowHeight = () => {
            if (rowRef.current) {
                // Measure the height of the content
                const height = rowRef.current.offsetHeight;
                setRowHeight(`${height}px`); // Update the state with the new height
            }
        };

        updateRowHeight();

        // Optionally, listen for window resize events to recalculate heights as necessary
        window.addEventListener('resize', updateRowHeight);
        return () => window.removeEventListener('resize', updateRowHeight);
    }, [rowData]); // Rerun when rowData changes
	
	return (
		<div className='row-container' ref={rowRef} style={{ height: rowHeight }}>
			{rowData.map((col, index) => {
				const itemStyle = col.isRecentlyAdded ? boldStyle : {};
				return (<div
					key={uniqueKey(`${col.value}-${index}`)}
					className='item-container'
					style={{
						flexGrow: 1,
						width: `${col.width}`,
						...itemStyle,
					}}>
					{col.value}
				</div>)
			}
			)}
		</div>
	);
};

export default CustomTableRow;
